import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';

export const getIndicatorState = state => get(state, 'indicator');

export const getIndicatorName = createSelector([getIndicatorState], indicator =>
  get(indicator, 'name'),
);

export const getIndicatorOrganization = createSelector(
  [getIndicatorState],
  indicator => get(indicator, 'organization'),
);

export const getIndicatorArea = createSelector([getIndicatorState], indicator =>
  get(indicator, 'area'),
);

export const getIndicatorDatasource = createSelector(
  [getIndicatorState],
  indicator => get(indicator, 'dataSource'),
);

export const isIndicatorHaveStructure = createSelector(
  [getIndicatorName, getIndicatorOrganization, getIndicatorArea],
  (name, organization, area) =>
    !isEmpty(name) && !isEmpty(organization) && !isEmpty(area),
);

export const getIndicatorSteps = createSelector(
  [getIndicatorState],
  indicator => get(indicator, 'steps'),
);

export const getIndicatorDictionary = createSelector(
  [getIndicatorState],
  indicator => get(indicator, 'dictionary'),
);

export const getIndicatorActiveStep = createSelector(
  [getIndicatorSteps],
  steps => steps.length - 1,
);

export const isIndicatorTested = createSelector(
  [getIndicatorState],
  indicator => get(indicator, 'tested'),
);

export const isIndicatorSaved = createSelector([getIndicatorState], indicator =>
  get(indicator, 'saved'),
);

export const isLastFunctionIndicatorValid = createSelector(
  [getIndicatorState],
  indicator => get(indicator, 'isLastFunctionValid'),
);

export const isIndicatorSending = createSelector(
  [getIndicatorState],
  indicator => get(indicator, 'sending'),
);

export const isEditLoading = createSelector([getIndicatorState], indicator =>
  get(indicator, 'isEditLoading'),
);

export const isDeleting = createSelector([getIndicatorState], indicator =>
  get(indicator, 'deleting'),
);

export const getNoexistIdsIndicator = createSelector(
  [getIndicatorState],
  indicator => get(indicator, 'noExistingIds'),
);
