import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';

import {
  SET_INDICATOR_STRUCTURE,
  SET_INDICATOR_NAME,
  SET_INDICATOR_AREA,
  SET_INDICATOR_ORGANIZATION,
  SET_INDICATOR_DATASOURCE,
  CREATE_INDICATOR_STEP,
  PUSH_ELEMENT_INTICATOR_STEP,
  PUSH_ELEMENT_INDICATOR_DICTIONARY,
  SET_TEST_INDICATOR,
  SET_SAVE_INDICATOR,
  RESET_INDICATOR_DATA,
  PUSH_FUNCTION_ELEMENT_INDICATOR,
  DICTIONARY,
  SET_LAST_FUNCTION_VALIDATION,
  SET_INDICATOR_SENDING,
  POP_INDICATOR_ELEMENT,
  POP_DICTIONARY_ELEMENT_INDICATOR,
  REPLACE_INDICATOR_ELEMENT,
  DELETE_INDICATOR_STEP,
  POP_FUNCTION_ARGUMENT_ELEMENT_INDICATOR,
  POP_ALL_FUNCTION_ARGUMENTS_INDICATOR_DICTIONARY,
  REHYDRATE_INDICATOR,
  RESET_INDICATOR_DATASET,
  SET_DELETING_INDICATOR,
  SET_LOADING_EDIT,
  REPLACE_FUNCTION_ARGUMENT_ELEMENT,
  SET_NO_EXIST_IDS_INDICATOR,
} from './constants';

const setStructure = (
  state,
  { payload: { name, organization, area, dataSource } },
) => ({
  ...state,
  name: name,
  organization: organization,
  area: area,
  dataSource: dataSource,
});

const setName = (state, action) => ({
  ...state,
  name: action.payload,
});

const setOrganization = (state, action) => ({
  ...state,
  organization: action.payload,
});

const setArea = (state, action) => ({
  ...state,
  area: action.payload,
});

const setIndcatorDatasource = (state, action) => ({
  ...state,
  dataSource: action.payload,
});

const createIndicatorStep = (state, payload) => ({
  ...state,
  steps: [...state.steps, []],
});

const deleteIndicatorStep = (state, payload) => ({
  ...state,
  steps: state.steps.slice(0, -1),
});

const pushElementIndicatorStep = (state, action) => {
  const steps = [...state.steps];
  steps[action.payload.step] = [
    ...state.steps[action.payload.step],
    action.payload.object,
  ];
  return { ...state, steps };
};

const pushFunctionElementIndicator = (state, action) => {
  const stepIndex = action.payload.stepIndex;
  const elementFunction = action.payload.object;
  const steps = [...state.steps];
  const stepElements = [...steps[action.payload.step]];

  stepElements.map((element, index) => {
    if (index === stepIndex) {
      return element.functionArguments.push(elementFunction);
    }
    return null;
  });

  return { ...state, stepElements };
};

const pushElementIndicatorDictionary = (state, action) => ({
  ...state,
  [DICTIONARY]: {
    ...state.dictionary,
    [action.payload.type]: [
      ...state.dictionary[action.payload.type],
      action.payload.value,
    ],
  },
});

const setTestResult = (state, action) => ({
  ...state,
  tested: action.payload,
});

const setSavedIndicator = (state, action) => ({
  ...state,
  saved: action.payload,
});

const setLastFunctionValidation = (state, action) => ({
  ...state,
  isLastFunctionValid: action.payload,
});

const setIndicatorSending = (state, action) => ({
  ...state,
  sending: action.payload,
});

const popIndicatorElement = (state, action) => {
  const steps = [...state.steps];
  steps[action.payload.step].splice(action.payload.index, 1);
  return { ...state, steps };
};

const popDictionaryElementIndicator = (state, action) => {
  const dictionary = state.dictionary;
  const dictionaryElements = [...dictionary[action.payload.type]];
  const newDictionaryElements = dictionaryElements.filter(
    e => e.compiler_tag !== action.payload.name,
  );
  dictionary[action.payload.type] = newDictionaryElements;
  return { ...state, dictionary };
};

const replaceElementIndicator = (state, action) => {
  const element = action.payload.element.object;
  const stepNumber = action.payload.editData.step;
  const stepIndex = action.payload.editData.index;
  const steps = [...state.steps];
  steps[stepNumber][stepIndex] = element;
  return { ...state, steps };
};

const popFunctionArgument = (state, action) => {
  const steps = [...state.steps];
  steps[action.payload.step][action.payload.index].functionArguments.splice(
    action.payload.functionElementIndex,
    1,
  );
  return { ...state, steps };
};

const popAllFunctionArgumentsDictionary = (state, action) => {
  let dictionaryElements;
  let type;
  let newDictionaryElements;

  const dictionary = state.dictionary;
  const stepNumber = action.payload.step;
  const stepIndex = action.payload.index;
  const steps = state.steps;
  const element = steps[stepNumber][stepIndex];

  element.functionArguments.forEach(element => {
    switch (element.value.selected) {
      case 'parameter':
        type = 'parameters';
        break;
      case 'variable':
        type = 'variables';
        break;
      case 'function':
        type = 'functions';
        break;
      default:
        type = '';
        break;
    }
    dictionaryElements = [...dictionary[type]];
    newDictionaryElements = dictionaryElements.filter(
      e => e.compiler_tag !== element.name,
    );
    dictionary[type] = newDictionaryElements;
  });
  return { ...state, dictionary };
};

const resetIndicatorData = () => INITIAL_STATE;

const rehydrateIndicator = (state, action) => ({
  ...state,
  name: action.payload.name,
  steps: action.payload.steps,
  area: action.payload.area,
  organization: action.payload.organization,
  dictionary: {
    parameters: action.payload.parameters,
    variables: action.payload.variables,
    functions: action.payload.functions,
  },
});

const resetIndicatorSteps = (state, action) => ({
  ...state,
  steps: [[]],
  dictionary: { parameters: [], variables: [], functions: [] },
  noExistingIds: [],
});

const setDeletingIndicator = (state, action) => ({
  ...state,
  deleting: action.payload,
});

const setLoadingEdit = (state, action) => ({
  ...state,
  isEditLoading: action.payload,
});

const replaceFunctionArgumentElementIndicator = (state, action) => {
  const element = action.payload.elementFunction.object;
  const stepNumber = action.payload.editData.step;
  const stepIndex = action.payload.editData.index;
  const functionElementIndex = action.payload.editData.functionElementIndex;
  const steps = [...state.steps];
  const functionObj = steps[stepNumber][stepIndex];

  functionObj.functionArguments[functionElementIndex] = element;
  return { ...state, steps };
};

const setNoExisingIds = (state, action) => ({
  ...state,
  noExistingIds: action.payload,
});

export const reducer = handleActions(
  {
    [SET_INDICATOR_STRUCTURE]: setStructure,
    [SET_INDICATOR_NAME]: setName,
    [SET_INDICATOR_ORGANIZATION]: setOrganization,
    [SET_INDICATOR_AREA]: setArea,
    [SET_INDICATOR_DATASOURCE]: setIndcatorDatasource,
    [CREATE_INDICATOR_STEP]: createIndicatorStep,
    [PUSH_ELEMENT_INTICATOR_STEP]: pushElementIndicatorStep,
    [PUSH_FUNCTION_ELEMENT_INDICATOR]: pushFunctionElementIndicator,
    [PUSH_ELEMENT_INDICATOR_DICTIONARY]: pushElementIndicatorDictionary,
    [SET_TEST_INDICATOR]: setTestResult,
    [SET_SAVE_INDICATOR]: setSavedIndicator,
    [RESET_INDICATOR_DATA]: resetIndicatorData,
    [SET_LAST_FUNCTION_VALIDATION]: setLastFunctionValidation,
    [SET_INDICATOR_SENDING]: setIndicatorSending,
    [POP_INDICATOR_ELEMENT]: popIndicatorElement,
    [POP_DICTIONARY_ELEMENT_INDICATOR]: popDictionaryElementIndicator,
    [REPLACE_INDICATOR_ELEMENT]: replaceElementIndicator,
    [DELETE_INDICATOR_STEP]: deleteIndicatorStep,
    [POP_FUNCTION_ARGUMENT_ELEMENT_INDICATOR]: popFunctionArgument,
    [POP_ALL_FUNCTION_ARGUMENTS_INDICATOR_DICTIONARY]: popAllFunctionArgumentsDictionary,
    [REHYDRATE_INDICATOR]: rehydrateIndicator,
    [RESET_INDICATOR_DATASET]: resetIndicatorSteps,
    [SET_DELETING_INDICATOR]: setDeletingIndicator,
    [SET_LOADING_EDIT]: setLoadingEdit,
    [REPLACE_FUNCTION_ARGUMENT_ELEMENT]: replaceFunctionArgumentElementIndicator,
    [SET_NO_EXIST_IDS_INDICATOR]: setNoExisingIds,
  },
  INITIAL_STATE,
);
