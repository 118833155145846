import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import {
  FETCH_PARAMETERS,
  SET_PARAMETERS,
  SET_PARAMETERS_ERROR,
} from './constants';

const fetchParameters = (state, payload) => ({
  ...state,
  loading: true,
  error: false,
});

const setParameters = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  data: action.payload,
});

const setParametersError = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

export const reducer = handleActions(
  {
    [FETCH_PARAMETERS]: fetchParameters,
    [SET_PARAMETERS]: setParameters,
    [SET_PARAMETERS_ERROR]: setParametersError,
  },
  INITIAL_STATE,
);
