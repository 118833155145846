export const createCustomerLite = `mutation CreateCustomer(
  $input: CreateCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  createCustomer(input: $input, condition: $condition) {
    id
    customerId
    customerName
    phones {
      phoneType
      phone
    }
    emails
    address
    location {
      coordinates
    }
    taxpayer {
      identification
      taxpayerName
      keyTax
      detailTax
    }
    sellBanned
    accountRepresentative {
      id
      accountRepresentativeId
      customer {
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    zone {
      id
      zoneId
      zoneName
      zoneType
      points {
        coordinates
      }
      customers {
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    customerType {
      id
      customerTypeName
      customers {
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    _version
    _deleted
    _lastChangedAt
  }
}
`;

export const getAuditFull = `query GetAudit($id: ID!) {
  getAudit(id: $id) {
    id
    auditType
    startAudit
    endAudit
    promoter
    auditor
    blacklist
    result
    customer {
        id
        customerId
        customerName
        emails
        address
        sellBanned
        preregistrationCode
        _version
        _deleted
        _lastChangedAt
    }
    _version
    _deleted
    _lastChangedAt
  }
}
`;

export const listCustomersAudit = `query ListCustomers(
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      customerId
      customerName
      phones {
        phoneType
        phone
      }
      emails
      address
      location {
        coordinates
      }
      taxpayer {
        identification
        taxpayerName
        keyTax
        detailTax
      }
      sellBanned
      sales {
        nextToken
        startedAt
      }
      accountRepresentative {
        id
        accountRepresentativeId
        _version
        _deleted
        _lastChangedAt
      }
      zone {
        id
        zoneId
        zoneName
        zoneType
        _version
        _deleted
        _lastChangedAt
      }
      customerType {
        id
        customerTypeName
        _version
        _deleted
        _lastChangedAt
      }
      legals {
        legalType
        datetime
      }
      files {
        fileName
        fileType
      }
      preregistrationCode
      fingers {
        fingerType
        fingerTemplate
      }
      audits {
        items {
          id
          auditType
          startAudit
          endAudit
          promoter
          auditor
          blacklist
          result
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;

export const onUpdateRemoteRequest = /* GraphQL */ `
  subscription OnUpdateRemoteRequest {
    onUpdateRemoteRequest {
      id
      source
      startDate
      endDate
      status
      sync
      request
      response
      _version
      _deleted
      _lastChangedAt      
    }
  }
`;

export const listAttendantsLite = `
  query ListAttendants(
    $filter: ModelAttendantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

// Envío de Comandos remotos - select Enviar kit.
export const listProductKitsCustom = /* GraphQL */ `
  query ListProductKits(
    $filter: ModelProductKitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductKits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productKitId
        productKitName
      }
      nextToken
      startedAt
    }
  }
`;