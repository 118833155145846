import { createAction } from 'redux-actions';
import {
  FETCH_EMPLOYEE_CATALOG,
  SET_EMPLOYEE_CATALOG,
  SET_EMPLOYEE_CATALOG_ERROR,
} from './constants';

export const fetchEmployeeCatalog = createAction(FETCH_EMPLOYEE_CATALOG);
export const setEmployeeCatalog = createAction(SET_EMPLOYEE_CATALOG);
export const setEmployeeCatalogError = createAction(SET_EMPLOYEE_CATALOG_ERROR);
