import React, { Component } from 'react';

export class StepItemOperator extends Component {
  render() {
    const {
      index,
      step,
      screen,
      elementName,
      item: { selected },
      openPopover,
    } = this.props;

    let operator = '';
    switch (selected) {
      case 'addition':
        operator = '+';
        break;
      case 'subtraction':
        operator = '-';
        break;
      case 'multiplication':
        operator = 'x';
        break;
      case 'division':
        operator = '/';
        break;
      case 'percentage':
        operator = '%';
        break;
      default:
        operator = '';
        break;
    }
    return (
      <div
        className={`step-item step-operator`}
        aria-describedby={index}
        data-step={step}
        data-screen={screen}
        data-name={elementName}
        data-type={'operator'}
        onClick={e => openPopover(e)}
      >
        <span className={'MuiButton-label'}>{operator}</span>
      </div>
    );
  }
}

export default StepItemOperator;
