import { compact } from 'lodash';

export function createBase64Expression(steps) {
  let expression = '\n';
  steps.forEach((step, index) => {
    expression += 'Resultado_Paso_' + (index + 1) + ' : ';
    step.forEach(object => {
      if (object.type === 'element') {
        if (object.value.selected === 'variable') {
          expression += '$remotevar:' + object.name;
        } else if (object.value.selected === 'parameter') {
          expression += '$remoteparam:' + object.name;
        } else if (object.value.selected === 'function') {
          expression += '$hfunction:' + object.name;
          expression += '{';
          object.functionArguments.forEach((element, index) => {
            if (element.value.selected === 'variable') {
              expression += '$remotevar:' + element.name;
            } else if (element.value.selected === 'parameter') {
              expression += '$remoteparam:' + element.name;
            } else if (element.value.selected === 'step') {
              expression += '$var:' + element.name + ' ';
            } else if (element.value.selected === 'areaIndicators') {
              expression += '$indicator:' + element.name + ' ';
            }
            if (index !== object.functionArguments.length - 1) {
              expression += ',';
            }
          });
          expression += '} ';
        } else if (object.value.selected === 'areaIndicators') {
          expression += '$indicator:' + object.name;
        } else if (object.value.selected === 'parameterFunction') {
          expression += '$hfunctionvar:[' + object.name + '] ';
        }
      } else if (object.type === 'operator') {
        expression += ' ' + object.value.value + ' ';
      } else if (object.type === 'step') {
        expression += '$var:' + object.name + ' ';
      }
    });
    expression += '\n';
  });
  expression += 'return $var:Resultado_Paso_' + steps.length;
  expression += '\n';
  expression = window.btoa(expression);
  return expression;
}

export function convertExpressionToArray(expression, log) {
  let steps = [];
  let logValues = [];
  let codeLines = compact(expression.split('\n'));

  codeLines.pop(); // Remove lastline of result

  log.forEach(e => {
    logValues[e.details.name] = e.details.value;
  });

  codeLines.forEach(line => {
    var step = [];
    var elements = compact(line.split(' '));

    elements.forEach(element => {
      let fist_char = element.substring(0, 15);
      let name = element.split(':').pop();

      if (fist_char.indexOf('$indicator:') > -1) {
        step.push({ type: 'indicator', name: name, value: logValues[name] });
      } else if (fist_char.indexOf('$var:') > -1) {
        step.push({ type: 'variable', name: name, value: logValues[name] });
      } else if (fist_char.indexOf('$remoteparam:') > -1) {
        step.push({ type: 'parameter', name: name, value: logValues[name] });
      } else if (fist_char.indexOf('$hfunction:') > -1) {
        let functionSplit = element.split('{');
        let functionParams = functionSplit[1].split(',');
        let objParams = [];
        let type = '';

        name = functionSplit[0].split(':').pop();

        functionParams.forEach(parameter => {
          var paramName = parameter
            .split(':')
            .pop()
            .replace('}', '');

          if (parameter.indexOf('$remoteparam:') > -1) {
            type = 'parameter';
          } else if (fist_char.indexOf('$indicator:') > -1) {
            type = 'indicator';
          } else if (fist_char.indexOf('$var:') > -1) {
            type = 'variable';
          }

          objParams.push({
            name: paramName,
            type: type,
            value: logValues[paramName],
          });
        });

        step.push({
          type: 'function',
          name: name,
          value: logValues[name],
          params: objParams,
        });
      } else if (fist_char.indexOf('$var:Resultado_Paso_:') > -1) {
        step.push(element);
      } else if (
        element === '+' ||
        element === '-' ||
        element === '*' ||
        element === '/' ||
        element === '%'
      ) {
        if (element === '*') {
          element = 'x';
        }
        step.push({ type: 'operator', value: element });
      }
    });

    steps.push(step);
  });

  return steps;
}

export function reportConstruct(html, log) {
  let steps = JSON.parse(html);
  let logValues = [];

  log.forEach(e => {
    logValues[e.details.name] = e.details.value;
  });

  steps.map(step => {
    // step.forEach(element => element.type !== 'operator' && console.log(`${element.type}`, element.value))
    step.map(element =>
      element.type !== 'operator' && element.type !== 'step'
        ? (element.value.value.name =
          element.value.value.name +
          ' <span class="report-value"> Valor : ' +
          logValues[element.name] +
          '</span>')
        : null
    )
  }
  );

  return steps;
}

export function convertExpressionToBase64(expression) {
  let response = window.btoa(expression);
  return response;
}

export const tableESP = {
  header: {
    actions: ' ',
  },
  body: {
    emptyDataSourceMessage: 'No se encontraron resultados',
    addTooltip: 'Agregar',
    editTooltip: 'Editar',
    deleteTooltip: 'Eliminar',
    editRow: {
      saveTooltip: 'Guardar',
      cancelTooltip: 'Cancelar',
      deleteText: '¿Estás seguro de eliminar este elemento?',
    },
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
    exportTitle: 'Exportar',
    exportName: 'Exportar a CSV',
  },
  pagination: {
    labelRowsSelect: 'Resultados',
    labelDisplayedRows: ' {from}-{to} de {count}',
    firstTooltip: 'Primer Página',
    previousTooltip: 'Página Anterior',
    nextTooltip: 'Siguiente Página',
    lastTooltip: 'Última Página',
  },
};

export function errorMessage(errors) {
  let ids = [];
  let type = '';
  if (typeof errors['HelperFunction'] !== 'undefined') {
    type = 'function';
    ids.push(errors['HelperFunction'].id);
  } else if (typeof errors['Parameter'] !== 'undefined') {
    type = 'parameter';
    ids.push(errors['Parameter'].id);
  } else if (typeof errors['Indicator'] !== 'undefined') {
    type = 'indicator';
    ids.push(errors['Indicator'].id);
  } else if (typeof errors['Variable'] !== 'undefined') {
    type = 'variable';
    ids.push(errors['Variable'].id);
  }

  return [ids, type];
}

export const search = [
  { value: "warehouseId", label: "Clave Sucursal"},
  { value: "warehouseName", label: "Nombre Sucursal"},
  { value: "vacants", label: "Número de Vacantes"},
  { value: "statusName", label: "Estatus"},
  { value: "workTeamTemplateName", label: "Tipo Sucursal"},    
] 

export const status = [
  {value: "high" , label: "Alto"},
  {value: "medium" , label: "Medio"},
  {value: "low" , label: "Bajo"}
]

export const columns = [
  { id: 'warehouseId', label: 'Clave Sucursal' },
  { id: 'warehouseName', label: 'Sucursal'},
  { id: 'wareHouseType', label: 'Tipo'},
  { id: 'vacants', label: 'Vacante'},
  { id: 'status', label: 'Estatus'},
  { id: 'acciones', label: ''},
];


export const columnsDrawer = [
  {label: 'Con Vacantes desde'},
  {label: 'Fecha de Corte' },
  {label: 'Estatus'},
  {label: 'Número de Vacantes'},
  {label: 'Obsevación'},
  {label: 'Plan de Acción '}
];