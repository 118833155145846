import React , { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import { FormControl , FormGroup, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import { CircularProgress } from '@material-ui/core';


export class ModalParameter extends Component {


    render(){

    const { 
            open, 
            handleClose , 
            changeName,
            changeValue,
            dateParameter,
            deleteRow,
            loading,
            saveParameter,
            valorParameter,
            changeDate,
            nameParameter,
            valoresParamaters,
            addValueParameter
    } = this.props;


    const List = () => {
        
        return (
            <div>
                <TableContainer className="w300" component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Fecha</TableCell>
                            <TableCell align="left">Valor</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {valoresParamaters.map( (row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {row.uploaded_at}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.value}
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                                <Button
                                    onClick={() => deleteRow(row)}
                                >
                                    <Icon className="fa fa-trash blue"/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
        );
    }
 
    return (
        <div>
            <Dialog fullScreen open={open}  closeAfterTransition>
                <Fade in={open}>
                <div className={'dialog-content'}>
                    <Grid className={'btn-wrapper'} container spacing={3}>
                        <Grid item xs={12}>
                            <h2 id="transition-modal-title">Registrar Parámetro Nuevo</h2>
                            <h3 id="transition-modal-title">Captura el nombre y el valor de los parámetros</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormGroup>
                                    <TextField 
                                        className="inputSelect"
                                        type="text"
                                        name="name"
                                        variant="outlined"
                                        label="Nombre"
                                        onChange={e => changeName(e.target.value)}
                                        value= {nameParameter}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid className={'btn-wrapper'} item xs={12} lg={12}>
                            <h3>Valores</h3>
                            <Grid className={'btn-wrapper'} container spacing={1}>
                                <Grid item xs={5}>
                                    <FormControl>
                                        <FormGroup>
                                            <TextField 
                                                className=""
                                                type="date"
                                                name="dateParameter"
                                                variant="outlined"
                                                onChange={e => changeDate(e.target.value)}
                                                value= {dateParameter}
                                            />

                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl>
                                        <FormGroup>
                                            <TextField 
                                                className=""
                                                type="number"
                                                name="valorParameter"
                                                variant="outlined"
                                                label="Valor"
                                                onChange={e => changeValue(e.target.value)}
                                                value= {valorParameter}
                                            />

                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        className={'accept-btn'}
                                        variant="contained"
                                        onClick={() => addValueParameter()}
                                        disabled={ dateParameter.length > 0 && valorParameter.length > 0 ? false : true}
                                    >+</Button>
                                </Grid>
                            </Grid>
                            <List></List>
                        </Grid>
                        <Grid item xs={12}>
                            {loading ? 
                            (
                                <CircularProgress />
                            ) :( 
                                <Button
                                    className={'btn accept-btn'}
                                    variant="contained" 
                                    onClick={saveParameter}
                                    disabled={ (valoresParamaters.length > 0)  && (nameParameter !== '') ? false : true}
                                >
                                    Guardar
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={'btn cancel-btn'} onClick={handleClose}>
                            Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                </Fade>
            </Dialog>
        </div>
    );
    }
  }

  export default ModalParameter;
