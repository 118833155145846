/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      productId
      productName
      sku
      description
      averageCost
      currentSellPrice
      maxSellingPrice
      inStock
      active
      marketReleaseDate
      marketFinalDate
      sellable
      purchable
      s3keyImage
      category {
        id
        categoryId
        categoryName
        parentCategoryId
        s3keyImage
        isNew
        categoryDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      brand {
        id
        brandId
        brandName
        parentBrandId
        s3keyImage
        brandDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleUnit {
        id
        saleUnitId
        saleUnitName
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sourcing {
        id
        sourcingId
        sourcingName
        parentSourcingId
        address
        location {
          coordinates
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productKitItems {
        items {
          id
          productOptions
          quantity
          currentSellPrice
          percentDiscount
          percentOfer
          amountDiscount
          amountOfer
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnables {
        items {
          id
          startValidity
          cancellationValidity
          returnableStatus
          received
          quantity
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnableContainerProductId
      serviceConfig {
        serviceIntegrationId
        params {
          param
          value
        }
      }
      mtcprops {
        code
        amount
        verification_code
        serviceIntegrationId
        operation
        params
        reference {
          minLength
          maxLength
          name
          s3keyImage
        }
      }
      barcodes
      taxes {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discounts {
        items {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      quantityPerBox
      pricings {
        items {
          id
          marketReleaseDate
          marketFinalDate
          amount
          pricingType
          pricingName
          priceId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      organizationId
      salesHours {
        startTime
        endTime
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        categoryId
        categoryName
        parentCategoryId
        s3keyImage
        isNew
        categoryDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      categoryId
      categoryName
      parentCategoryId
      s3keyImage
      isNew
      categoryDescription
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        categoryName
        parentCategoryId
        s3keyImage
        isNew
        categoryDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBrands = /* GraphQL */ `
  query SyncBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBrands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        brandId
        brandName
        parentBrandId
        s3keyImage
        brandDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      brandId
      brandName
      parentBrandId
      s3keyImage
      brandDescription
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brandId
        brandName
        parentBrandId
        s3keyImage
        brandDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSaleUnits = /* GraphQL */ `
  query SyncSaleUnits(
    $filter: ModelSaleUnitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSaleUnits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        saleUnitId
        saleUnitName
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSaleUnit = /* GraphQL */ `
  query GetSaleUnit($id: ID!) {
    getSaleUnit(id: $id) {
      id
      saleUnitId
      saleUnitName
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSaleUnits = /* GraphQL */ `
  query ListSaleUnits(
    $filter: ModelSaleUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaleUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        saleUnitId
        saleUnitName
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProviders = /* GraphQL */ `
  query SyncProviders(
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        providerId
        providerName
        cronVisits
        phones {
          phoneType
          phone
        }
        address
        email
        giveCredit
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProvider = /* GraphQL */ `
  query GetProvider($id: ID!) {
    getProvider(id: $id) {
      id
      providerId
      providerName
      cronVisits
      phones {
        phoneType
        phone
      }
      address
      email
      giveCredit
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listProviders = /* GraphQL */ `
  query ListProviders(
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        providerId
        providerName
        cronVisits
        phones {
          phoneType
          phone
        }
        address
        email
        giveCredit
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSourcings = /* GraphQL */ `
  query SyncSourcings(
    $filter: ModelSourcingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSourcings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sourcingId
        sourcingName
        parentSourcingId
        address
        location {
          coordinates
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSourcing = /* GraphQL */ `
  query GetSourcing($id: ID!) {
    getSourcing(id: $id) {
      id
      sourcingId
      sourcingName
      parentSourcingId
      address
      location {
        coordinates
      }
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSourcings = /* GraphQL */ `
  query ListSourcings(
    $filter: ModelSourcingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSourcings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sourcingId
        sourcingName
        parentSourcingId
        address
        location {
          coordinates
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSchedules = /* GraphQL */ `
  query SyncSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($id: ID!) {
    getSchedule(id: $id) {
      id
      scheduleId
      entry
      exit
      scheduleName
      tolerance
      roles {
        items {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      scheduleType {
        id
        scheduleTypeName
        schedules {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncScheduleTypes = /* GraphQL */ `
  query SyncScheduleTypes(
    $filter: ModelScheduleTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScheduleTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        scheduleTypeName
        schedules {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getScheduleType = /* GraphQL */ `
  query GetScheduleType($id: ID!) {
    getScheduleType(id: $id) {
      id
      scheduleTypeName
      schedules {
        items {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listScheduleTypes = /* GraphQL */ `
  query ListScheduleTypes(
    $filter: ModelScheduleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduleTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        scheduleTypeName
        schedules {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttendanceRecords = /* GraphQL */ `
  query SyncAttendanceRecords(
    $filter: ModelAttendanceRecordFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttendanceRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        datetime
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        records {
          datetime
          attendanceRecordType
        }
        role {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttendanceRecord = /* GraphQL */ `
  query GetAttendanceRecord($id: ID!) {
    getAttendanceRecord(id: $id) {
      id
      datetime
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      records {
        datetime
        attendanceRecordType
      }
      role {
        id
        roleId
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        day
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAttendanceRecords = /* GraphQL */ `
  query ListAttendanceRecords(
    $filter: ModelAttendanceRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendanceRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datetime
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        records {
          datetime
          attendanceRecordType
        }
        role {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttendants = /* GraphQL */ `
  query SyncAttendants(
    $filter: ModelAttendantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttendants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttendant = /* GraphQL */ `
  query GetAttendant($id: ID!) {
    getAttendant(id: $id) {
      id
      attendantId
      fullName
      userName
      hashPassword
      nipPassword
      tokenRequest
      profilePhoto
      limitEpoch
      managerAttendantId
      active
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      roles {
        items {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      phones {
        phoneType
        phone
      }
      emails {
        emailType
        email
      }
      fingers {
        fingerType
        fingerTemplate
      }
      position {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashRegisterShifts {
        items {
          id
          openCashRegisterShift
          closeCashRegisterShift
          cashRegisterShiftType
          cashRegisterShiftStatusType
          registerId
          sync
          sales
          cashRegisterShifts
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      cashDrawers {
        items {
          id
          datetime
          cashDrawerType
          cashFlowType
          amount
          registerId
          controlId
          sync
          file
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        id
        datetime
        day
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouseId
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentType {
          id
          incidentTypeId
          incidentTypeName
          parentIncidentTypeId
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        description
        headcountCount
        vacantLog {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discounts {
        items {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attendanceRecords {
        items {
          id
          datetime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      xadisEmptyContainers {
        items {
          id
          startDate
          endDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplanExecution {
        items {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      color
      organizationId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAttendants = /* GraphQL */ `
  query ListAttendants(
    $filter: ModelAttendantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSaleStatuses = /* GraphQL */ `
  query SyncSaleStatuses(
    $filter: ModelSaleStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSaleStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        saleStatusName
        parentSaleStatusId
        sales {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSaleStatus = /* GraphQL */ `
  query GetSaleStatus($id: ID!) {
    getSaleStatus(id: $id) {
      id
      saleStatusName
      parentSaleStatusId
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSaleStatuss = /* GraphQL */ `
  query ListSaleStatuss(
    $filter: ModelSaleStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaleStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        saleStatusName
        parentSaleStatusId
        sales {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSales = /* GraphQL */ `
  query SyncSales(
    $filter: ModelSaleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSales(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSale = /* GraphQL */ `
  query GetSale($id: ID!) {
    getSale(id: $id) {
      id
      createdDate
      paidDate
      amount
      amountPaid
      amountReturned
      cardAmount
      taxAmount
      discountAmount
      source
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      status {
        id
        saleStatusName
        parentSaleStatusId
        sales {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      customer {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleCanceledItems {
        productId
        quantity
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      serviceTransaction {
        items {
          id
          datetime
          sync
          startServiceTransaction
          sendServiceTransaction
          endServiceTransaction
          attendantId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnables {
        items {
          id
          startValidity
          cancellationValidity
          returnableStatus
          received
          quantity
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouseId
      registerId
      payment {
        paymentType
        amount
        cardTransactionId
      }
      sync
      token
      invoice
      files {
        fileName
        fileType
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSales = /* GraphQL */ `
  query ListSales(
    $filter: ModelSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncZones = /* GraphQL */ `
  query SyncZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncZones(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      id
      zoneId
      zoneName
      zoneType
      points {
        coordinates
      }
      customers {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      customerId
      customerName
      birthdate
      phones {
        phoneType
        phone
      }
      emails
      address
      location {
        coordinates
      }
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      sellBanned
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      accountRepresentative {
        id
        accountRepresentativeId
        customer {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      zone {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      customerType {
        id
        customerTypeId
        customerTypeName
        customers {
          nextToken
          startedAt
        }
        defaultType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      legals {
        legalType
        datetime
      }
      files {
        fileName
        fileType
      }
      preregistrationCode
      fingers {
        fingerType
        fingerTemplate
      }
      audits {
        items {
          id
          auditType
          startAudit
          endAudit
          promoter
          auditor
          blacklist
          result
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      commercialBusiness
      salesHours {
        startTime
        endTime
      }
      enrrollmentWarehouse
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomerTypes = /* GraphQL */ `
  query SyncCustomerTypes(
    $filter: ModelCustomerTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomerTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customerTypeId
        customerTypeName
        customers {
          nextToken
          startedAt
        }
        defaultType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomerType = /* GraphQL */ `
  query GetCustomerType($id: ID!) {
    getCustomerType(id: $id) {
      id
      customerTypeId
      customerTypeName
      customers {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      defaultType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerTypes = /* GraphQL */ `
  query ListCustomerTypes(
    $filter: ModelCustomerTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerTypeId
        customerTypeName
        customers {
          nextToken
          startedAt
        }
        defaultType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomerTypeProductKits = /* GraphQL */ `
  query SyncCustomerTypeProductKits(
    $filter: ModelCustomerTypeProductKitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomerTypeProductKits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        productKitId
        customerTypeId
        marketReleaseDate
        marketFinalDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomerTypeProductKit = /* GraphQL */ `
  query GetCustomerTypeProductKit($productKitId: ID!, $customerTypeId: ID!) {
    getCustomerTypeProductKit(
      productKitId: $productKitId
      customerTypeId: $customerTypeId
    ) {
      productKitId
      customerTypeId
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerTypeProductKits = /* GraphQL */ `
  query ListCustomerTypeProductKits(
    $productKitId: ID
    $customerTypeId: ModelIDKeyConditionInput
    $filter: ModelCustomerTypeProductKitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerTypeProductKits(
      productKitId: $productKitId
      customerTypeId: $customerTypeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productKitId
        customerTypeId
        marketReleaseDate
        marketFinalDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWarehouseCustomers = /* GraphQL */ `
  query SyncWarehouseCustomers(
    $filter: ModelWarehouseCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWarehouseCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        customerId
        warehouseId
        marketReleaseDate
        marketFinalDate
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWarehouseCustomer = /* GraphQL */ `
  query GetWarehouseCustomer($customerId: ID!, $warehouseId: ID!) {
    getWarehouseCustomer(customerId: $customerId, warehouseId: $warehouseId) {
      customerId
      warehouseId
      marketReleaseDate
      marketFinalDate
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWarehouseCustomers = /* GraphQL */ `
  query ListWarehouseCustomers(
    $customerId: ID
    $warehouseId: ModelIDKeyConditionInput
    $filter: ModelWarehouseCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWarehouseCustomers(
      customerId: $customerId
      warehouseId: $warehouseId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        customerId
        warehouseId
        marketReleaseDate
        marketFinalDate
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWarehouseAttendants = /* GraphQL */ `
  query SyncWarehouseAttendants(
    $filter: ModelWarehouseAttendantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWarehouseAttendants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        attendantId
        warehouseId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWarehouseAttendant = /* GraphQL */ `
  query GetWarehouseAttendant($attendantId: ID!, $warehouseId: ID!) {
    getWarehouseAttendant(
      attendantId: $attendantId
      warehouseId: $warehouseId
    ) {
      attendantId
      warehouseId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWarehouseAttendants = /* GraphQL */ `
  query ListWarehouseAttendants(
    $attendantId: ID
    $warehouseId: ModelIDKeyConditionInput
    $filter: ModelWarehouseAttendantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWarehouseAttendants(
      attendantId: $attendantId
      warehouseId: $warehouseId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        attendantId
        warehouseId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccountRepresentatives = /* GraphQL */ `
  query SyncAccountRepresentatives(
    $filter: ModelAccountRepresentativeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountRepresentatives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountRepresentativeId
        customer {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccountRepresentative = /* GraphQL */ `
  query GetAccountRepresentative($id: ID!) {
    getAccountRepresentative(id: $id) {
      id
      accountRepresentativeId
      customer {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAccountRepresentatives = /* GraphQL */ `
  query ListAccountRepresentatives(
    $filter: ModelAccountRepresentativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountRepresentatives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountRepresentativeId
        customer {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSaleItems = /* GraphQL */ `
  query SyncSaleItems(
    $filter: ModelSaleItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSaleItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productId
        quantity
        pricePerUnit
        price
        taxAmount
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardex {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSaleItem = /* GraphQL */ `
  query GetSaleItem($id: ID!) {
    getSaleItem(id: $id) {
      id
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productId
      quantity
      pricePerUnit
      price
      taxAmount
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      kardex {
        id
        kardexId
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        datetime
        kardexOperationType
        kardexFlowType
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItem {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        quantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSaleItems = /* GraphQL */ `
  query ListSaleItems(
    $filter: ModelSaleItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaleItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productId
        quantity
        pricePerUnit
        price
        taxAmount
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardex {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEquivalenceProducts = /* GraphQL */ `
  query SyncEquivalenceProducts(
    $filter: ModelEquivalenceProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEquivalenceProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        productBaseId
        productEquivalenceId
        quantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEquivalenceProduct = /* GraphQL */ `
  query GetEquivalenceProduct($productBaseId: ID!, $productEquivalenceId: ID!) {
    getEquivalenceProduct(
      productBaseId: $productBaseId
      productEquivalenceId: $productEquivalenceId
    ) {
      productBaseId
      productEquivalenceId
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listEquivalenceProducts = /* GraphQL */ `
  query ListEquivalenceProducts(
    $productBaseId: ID
    $productEquivalenceId: ModelIDKeyConditionInput
    $filter: ModelEquivalenceProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEquivalenceProducts(
      productBaseId: $productBaseId
      productEquivalenceId: $productEquivalenceId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productBaseId
        productEquivalenceId
        quantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWarehouseProductKits = /* GraphQL */ `
  query SyncWarehouseProductKits(
    $filter: ModelWarehouseProductKitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWarehouseProductKits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        productKitId
        warehouseId
        marketReleaseDate
        marketFinalDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWarehouseProductKit = /* GraphQL */ `
  query GetWarehouseProductKit($productKitId: ID!, $warehouseId: ID!) {
    getWarehouseProductKit(
      productKitId: $productKitId
      warehouseId: $warehouseId
    ) {
      productKitId
      warehouseId
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWarehouseProductKits = /* GraphQL */ `
  query ListWarehouseProductKits(
    $productKitId: ID
    $warehouseId: ModelIDKeyConditionInput
    $filter: ModelWarehouseProductKitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWarehouseProductKits(
      productKitId: $productKitId
      warehouseId: $warehouseId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productKitId
        warehouseId
        marketReleaseDate
        marketFinalDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProductKits = /* GraphQL */ `
  query SyncProductKits(
    $filter: ModelProductKitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductKits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProductKit = /* GraphQL */ `
  query GetProductKit($id: ID!) {
    getProductKit(id: $id) {
      id
      productKitId
      productKitName
      productKitType
      currentSellPrice
      marketReleaseDate
      marketFinalDate
      s3keyImage
      limitPerSale
      limitPerDay
      limitPerPos
      kitFormulaId
      productKitItems {
        items {
          id
          productOptions
          quantity
          currentSellPrice
          percentDiscount
          percentOfer
          amountDiscount
          amountOfer
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouseId {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      organizationId
      salesHours {
        startTime
        endTime
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listProductKits = /* GraphQL */ `
  query ListProductKits(
    $filter: ModelProductKitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductKits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProductKitItems = /* GraphQL */ `
  query SyncProductKitItems(
    $filter: ModelProductKitItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductKitItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productOptions
        quantity
        currentSellPrice
        percentDiscount
        percentOfer
        amountDiscount
        amountOfer
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProductKitItem = /* GraphQL */ `
  query GetProductKitItem($id: ID!) {
    getProductKitItem(id: $id) {
      id
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productOptions
      quantity
      currentSellPrice
      percentDiscount
      percentOfer
      amountDiscount
      amountOfer
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listProductKitItems = /* GraphQL */ `
  query ListProductKitItems(
    $filter: ModelProductKitItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductKitItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productOptions
        quantity
        currentSellPrice
        percentDiscount
        percentOfer
        amountDiscount
        amountOfer
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrderStatuses = /* GraphQL */ `
  query SyncOrderStatuses(
    $filter: ModelOrderStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderStatusName
        parentOrderStatusId
        orders {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrderStatus = /* GraphQL */ `
  query GetOrderStatus($id: ID!) {
    getOrderStatus(id: $id) {
      id
      orderStatusName
      parentOrderStatusId
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrderStatuss = /* GraphQL */ `
  query ListOrderStatuss(
    $filter: ModelOrderStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderStatusName
        parentOrderStatusId
        orders {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      createdDate
      requestedDate
      recivedDate
      provider {
        id
        providerId
        providerName
        cronVisits
        phones {
          phoneType
          phone
        }
        address
        email
        giveCredit
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      orderStatus {
        id
        orderStatusName
        parentOrderStatusId
        orders {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          comment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      scheduledDate
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sync
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        comment
        order {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      comment
      order {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comment
        order {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrderItems = /* GraphQL */ `
  query SyncOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        order {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        requestedQuantity
        pricePerUnit
        price
        taxAmount
        receivedQuantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrderItem = /* GraphQL */ `
  query GetOrderItem($id: ID!) {
    getOrderItem(id: $id) {
      id
      order {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      requestedQuantity
      pricePerUnit
      price
      taxAmount
      receivedQuantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrderItems = /* GraphQL */ `
  query ListOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        requestedQuantity
        pricePerUnit
        price
        taxAmount
        receivedQuantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWarehouses = /* GraphQL */ `
  query SyncWarehouses(
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWarehouses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWarehouse = /* GraphQL */ `
  query GetWarehouse($id: ID!) {
    getWarehouse(id: $id) {
      id
      warehouseId
      warehouseRelatedId
      warehouseName
      warehouseType
      location {
        coordinates
      }
      address
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      phones {
        phoneType
        phone
      }
      settings {
        param
        value
      }
      attendants {
        items {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      productKits {
        items {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashRegisterShifts {
        items {
          id
          openCashRegisterShift
          closeCashRegisterShift
          cashRegisterShiftType
          cashRegisterShiftStatusType
          registerId
          sync
          sales
          cashRegisterShifts
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      zone {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      headcountReport {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      cashDrawers {
        items {
          id
          datetime
          cashDrawerType
          cashFlowType
          amount
          registerId
          controlId
          sync
          file
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWarehouses = /* GraphQL */ `
  query ListWarehouses(
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarehouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInventories = /* GraphQL */ `
  query SyncInventories(
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInventories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        productId
        warehouseId
        inventoryAmount
        minExistence
        maxExistence
        erpAmount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($productId: ID!, $warehouseId: ID!) {
    getInventory(productId: $productId, warehouseId: $warehouseId) {
      productId
      warehouseId
      inventoryAmount
      minExistence
      maxExistence
      erpAmount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listInventorys = /* GraphQL */ `
  query ListInventorys(
    $productId: ID
    $warehouseId: ModelIDKeyConditionInput
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInventorys(
      productId: $productId
      warehouseId: $warehouseId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productId
        warehouseId
        inventoryAmount
        minExistence
        maxExistence
        erpAmount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTaxes = /* GraphQL */ `
  query SyncTaxes(
    $filter: ModelTaxFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTaxes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTax = /* GraphQL */ `
  query GetTax($id: ID!) {
    getTax(id: $id) {
      id
      taxId
      taxName
      rate
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTaxs = /* GraphQL */ `
  query ListTaxs(
    $filter: ModelTaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaxs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRoles = /* GraphQL */ `
  query SyncRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        roleId
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        day
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      roleId
      schedule {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendanceRecords {
        items {
          id
          datetime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      day
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleId
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        day
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCashDrawers = /* GraphQL */ `
  query SyncCashDrawers(
    $filter: ModelCashDrawerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCashDrawers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        datetime
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashDrawerType
        cashFlowType
        amount
        registerId
        controlId
        sync
        file
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCashDrawer = /* GraphQL */ `
  query GetCashDrawer($id: ID!) {
    getCashDrawer(id: $id) {
      id
      datetime
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashDrawerType
      cashFlowType
      amount
      registerId
      controlId
      sync
      file
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCashDrawers = /* GraphQL */ `
  query ListCashDrawers(
    $filter: ModelCashDrawerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashDrawers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        datetime
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashDrawerType
        cashFlowType
        amount
        registerId
        controlId
        sync
        file
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKardexes = /* GraphQL */ `
  query SyncKardexes(
    $filter: ModelKardexFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKardexes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        kardexId
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        datetime
        kardexOperationType
        kardexFlowType
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItem {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        quantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getKardex = /* GraphQL */ `
  query GetKardex($id: ID!) {
    getKardex(id: $id) {
      id
      kardexId
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      datetime
      kardexOperationType
      kardexFlowType
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleItem {
        id
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productId
        quantity
        pricePerUnit
        price
        taxAmount
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardex {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listKardexs = /* GraphQL */ `
  query ListKardexs(
    $filter: ModelKardexFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKardexs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        kardexId
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        datetime
        kardexOperationType
        kardexFlowType
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItem {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        quantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAuthorizations = /* GraphQL */ `
  query SyncAuthorizations(
    $filter: ModelAuthorizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuthorizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        datetime
        applicantAttendantId
        authorizerAttendantId
        authorizationType
        authorized
        registerId
        discount {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAuthorization = /* GraphQL */ `
  query GetAuthorization($id: ID!) {
    getAuthorization(id: $id) {
      id
      datetime
      applicantAttendantId
      authorizerAttendantId
      authorizationType
      authorized
      registerId
      discount {
        id
        startDate
        endDate
        authorizations {
          nextToken
          startedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discountType
        quantity
        amount
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAuthorizations = /* GraphQL */ `
  query ListAuthorizations(
    $filter: ModelAuthorizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthorizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        datetime
        applicantAttendantId
        authorizerAttendantId
        authorizationType
        authorized
        registerId
        discount {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCardTransactions = /* GraphQL */ `
  query SyncCardTransactions(
    $filter: ModelCardTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCardTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        datetime
        request
        response
        receipe
        receipeS3
        saleId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCardTransaction = /* GraphQL */ `
  query GetCardTransaction($id: ID!) {
    getCardTransaction(id: $id) {
      id
      datetime
      request
      response
      receipe
      receipeS3
      saleId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCardTransactions = /* GraphQL */ `
  query ListCardTransactions(
    $filter: ModelCardTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCardTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datetime
        request
        response
        receipe
        receipeS3
        saleId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIncidents = /* GraphQL */ `
  query SyncIncidents(
    $filter: ModelIncidentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIncidents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        datetime
        day
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouseId
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentType {
          id
          incidentTypeId
          incidentTypeName
          parentIncidentTypeId
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        description
        headcountCount
        vacantLog {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncident = /* GraphQL */ `
  query GetIncident($id: ID!) {
    getIncident(id: $id) {
      id
      datetime
      day
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouseId
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentType {
        id
        incidentTypeId
        incidentTypeName
        parentIncidentTypeId
        headcountCount
        incidents {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentObservation {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      schedule {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      description
      headcountCount
      vacantLog {
        id
        vacantCounter
        sinceDate
        calculationDate
        statusName
        statusId
        incidents {
          nextToken
          startedAt
        }
        reportId
        report {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail {
          count
          id
          positionId
          positionName
          date
        }
        incidentPlanAction {
          id
          incidentPlanActionName
          parentIncidentPlanActionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIncidents = /* GraphQL */ `
  query ListIncidents(
    $filter: ModelIncidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        datetime
        day
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouseId
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentType {
          id
          incidentTypeId
          incidentTypeName
          parentIncidentTypeId
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        description
        headcountCount
        vacantLog {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIncidentTypes = /* GraphQL */ `
  query SyncIncidentTypes(
    $filter: ModelIncidentTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIncidentTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        incidentTypeId
        incidentTypeName
        parentIncidentTypeId
        headcountCount
        incidents {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentType = /* GraphQL */ `
  query GetIncidentType($id: ID!) {
    getIncidentType(id: $id) {
      id
      incidentTypeId
      incidentTypeName
      parentIncidentTypeId
      headcountCount
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIncidentTypes = /* GraphQL */ `
  query ListIncidentTypes(
    $filter: ModelIncidentTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncidentTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        incidentTypeId
        incidentTypeName
        parentIncidentTypeId
        headcountCount
        incidents {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIncidentObservations = /* GraphQL */ `
  query SyncIncidentObservations(
    $filter: ModelIncidentObservationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIncidentObservations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentObservation = /* GraphQL */ `
  query GetIncidentObservation($id: ID!) {
    getIncidentObservation(id: $id) {
      id
      incidentObservationName
      parentIncidentObservationId
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      headcountVacantLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIncidentObservations = /* GraphQL */ `
  query ListIncidentObservations(
    $filter: ModelIncidentObservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncidentObservations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIncidentPlanActions = /* GraphQL */ `
  query SyncIncidentPlanActions(
    $filter: ModelIncidentPlanActionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIncidentPlanActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        incidentPlanActionName
        parentIncidentPlanActionId
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentPlanAction = /* GraphQL */ `
  query GetIncidentPlanAction($id: ID!) {
    getIncidentPlanAction(id: $id) {
      id
      incidentPlanActionName
      parentIncidentPlanActionId
      headcountVacantLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIncidentPlanActions = /* GraphQL */ `
  query ListIncidentPlanActions(
    $filter: ModelIncidentPlanActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncidentPlanActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        incidentPlanActionName
        parentIncidentPlanActionId
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReturnables = /* GraphQL */ `
  query SyncReturnables(
    $filter: ModelReturnableFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReturnables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        startValidity
        cancellationValidity
        returnableStatus
        received
        quantity
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        observation
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReturnable = /* GraphQL */ `
  query GetReturnable($id: ID!) {
    getReturnable(id: $id) {
      id
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      startValidity
      cancellationValidity
      returnableStatus
      received
      quantity
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      observation
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listReturnables = /* GraphQL */ `
  query ListReturnables(
    $filter: ModelReturnableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReturnables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        startValidity
        cancellationValidity
        returnableStatus
        received
        quantity
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        observation
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSystemRoles = /* GraphQL */ `
  query SyncSystemRoles(
    $filter: ModelSystemRoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSystemRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSystemRole = /* GraphQL */ `
  query GetSystemRole($id: ID!) {
    getSystemRole(id: $id) {
      id
      systemRoleName
      parentSystemRoleId
      positions {
        items {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          systemMenuName
          menuType
          path
          component
          active
          icon
          platformType
          parentSystemMenuId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSystemRoles = /* GraphQL */ `
  query ListSystemRoles(
    $filter: ModelSystemRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSystemMenus = /* GraphQL */ `
  query SyncSystemMenus(
    $filter: ModelSystemMenuFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSystemMenus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        systemMenuName
        menuType
        path
        component
        active
        icon
        role {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        defaultScopes {
          scopeName
          active
        }
        platformType
        parentSystemMenuId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSystemMenu = /* GraphQL */ `
  query GetSystemMenu($id: ID!) {
    getSystemMenu(id: $id) {
      id
      systemMenuName
      menuType
      path
      component
      active
      icon
      role {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      defaultScopes {
        scopeName
        active
      }
      platformType
      parentSystemMenuId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSystemMenus = /* GraphQL */ `
  query ListSystemMenus(
    $filter: ModelSystemMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        systemMenuName
        menuType
        path
        component
        active
        icon
        role {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        defaultScopes {
          scopeName
          active
        }
        platformType
        parentSystemMenuId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSystemAttendantMenuScopes = /* GraphQL */ `
  query SyncSystemAttendantMenuScopes(
    $filter: ModelSystemAttendantMenuScopeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSystemAttendantMenuScopes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        systemMenuId
        attendantId
        scopes {
          scopeName
          active
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSystemAttendantMenuScope = /* GraphQL */ `
  query GetSystemAttendantMenuScope($systemMenuId: ID!, $attendantId: ID!) {
    getSystemAttendantMenuScope(
      systemMenuId: $systemMenuId
      attendantId: $attendantId
    ) {
      systemMenuId
      attendantId
      scopes {
        scopeName
        active
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSystemAttendantMenuScopes = /* GraphQL */ `
  query ListSystemAttendantMenuScopes(
    $systemMenuId: ID
    $attendantId: ModelIDKeyConditionInput
    $filter: ModelSystemAttendantMenuScopeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemAttendantMenuScopes(
      systemMenuId: $systemMenuId
      attendantId: $attendantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        systemMenuId
        attendantId
        scopes {
          scopeName
          active
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPositions = /* GraphQL */ `
  query SyncPositions(
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPositions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPosition = /* GraphQL */ `
  query GetPosition($id: ID!) {
    getPosition(id: $id) {
      id
      positionId
      positionName
      parentPositionId
      attendants {
        items {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      teamPositions {
        items {
          id
          quantity
          required
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      systemRole {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPositions = /* GraphQL */ `
  query ListPositions(
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      organizationId
      organizationName
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      address
      positions {
        items {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      providers {
        items {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCashRegisterShifts = /* GraphQL */ `
  query SyncCashRegisterShifts(
    $filter: ModelCashRegisterShiftFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCashRegisterShifts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        openCashRegisterShift
        closeCashRegisterShift
        openStocks {
          typeStock
          amount
        }
        arching {
          cash
          virtual
          cashDifference
          virtualDifference
          cashBalance
          virtualBalance
          startDate
          endDate
        }
        closeStocks {
          typeStock
          amount
        }
        openInventory {
          productId
          inventoryAmount
        }
        closeInventory {
          productId
          inventoryAmount
        }
        openReturnable {
          productId
          inventoryAmount
        }
        closeReturnable {
          productId
          inventoryAmount
        }
        cashRegisterShiftType
        cashRegisterShiftStatusType
        registerId
        sync
        sales
        cashRegisterShifts
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCashRegisterShift = /* GraphQL */ `
  query GetCashRegisterShift($id: ID!) {
    getCashRegisterShift(id: $id) {
      id
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      openCashRegisterShift
      closeCashRegisterShift
      openStocks {
        typeStock
        amount
      }
      arching {
        cash
        virtual
        cashDifference
        virtualDifference
        cashBalance
        virtualBalance
        startDate
        endDate
      }
      closeStocks {
        typeStock
        amount
      }
      openInventory {
        productId
        inventoryAmount
      }
      closeInventory {
        productId
        inventoryAmount
      }
      openReturnable {
        productId
        inventoryAmount
      }
      closeReturnable {
        productId
        inventoryAmount
      }
      cashRegisterShiftType
      cashRegisterShiftStatusType
      registerId
      sync
      sales
      cashRegisterShifts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCashRegisterShifts = /* GraphQL */ `
  query ListCashRegisterShifts(
    $filter: ModelCashRegisterShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashRegisterShifts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        openCashRegisterShift
        closeCashRegisterShift
        openStocks {
          typeStock
          amount
        }
        arching {
          cash
          virtual
          cashDifference
          virtualDifference
          cashBalance
          virtualBalance
          startDate
          endDate
        }
        closeStocks {
          typeStock
          amount
        }
        openInventory {
          productId
          inventoryAmount
        }
        closeInventory {
          productId
          inventoryAmount
        }
        openReturnable {
          productId
          inventoryAmount
        }
        closeReturnable {
          productId
          inventoryAmount
        }
        cashRegisterShiftType
        cashRegisterShiftStatusType
        registerId
        sync
        sales
        cashRegisterShifts
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServiceIntegrations = /* GraphQL */ `
  query SyncServiceIntegrations(
    $filter: ModelServiceIntegrationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceIntegrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        serviceName
        configurations {
          param
          value
        }
        serviceTransactions {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getServiceIntegration = /* GraphQL */ `
  query GetServiceIntegration($id: ID!) {
    getServiceIntegration(id: $id) {
      id
      serviceName
      configurations {
        param
        value
      }
      serviceTransactions {
        items {
          id
          datetime
          sync
          startServiceTransaction
          sendServiceTransaction
          endServiceTransaction
          attendantId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listServiceIntegrations = /* GraphQL */ `
  query ListServiceIntegrations(
    $filter: ModelServiceIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceIntegrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceName
        configurations {
          param
          value
        }
        serviceTransactions {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServiceTransactions = /* GraphQL */ `
  query SyncServiceTransactions(
    $filter: ModelServiceTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        serviceIntegration {
          id
          serviceName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        datetime
        params {
          param
          value
        }
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        startServiceTransaction
        sendServiceTransaction
        endServiceTransaction
        attendantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getServiceTransaction = /* GraphQL */ `
  query GetServiceTransaction($id: ID!) {
    getServiceTransaction(id: $id) {
      id
      serviceIntegration {
        id
        serviceName
        configurations {
          param
          value
        }
        serviceTransactions {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      datetime
      params {
        param
        value
      }
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sync
      startServiceTransaction
      sendServiceTransaction
      endServiceTransaction
      attendantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listServiceTransactions = /* GraphQL */ `
  query ListServiceTransactions(
    $filter: ModelServiceTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceIntegration {
          id
          serviceName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        datetime
        params {
          param
          value
        }
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        startServiceTransaction
        sendServiceTransaction
        endServiceTransaction
        attendantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDiscounts = /* GraphQL */ `
  query SyncDiscounts(
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        startDate
        endDate
        authorizations {
          nextToken
          startedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discountType
        quantity
        amount
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDiscount = /* GraphQL */ `
  query GetDiscount($id: ID!) {
    getDiscount(id: $id) {
      id
      startDate
      endDate
      authorizations {
        items {
          id
          datetime
          applicantAttendantId
          authorizerAttendantId
          authorizationType
          authorized
          registerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discountType
      quantity
      amount
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDiscounts = /* GraphQL */ `
  query ListDiscounts(
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startDate
        endDate
        authorizations {
          nextToken
          startedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discountType
        quantity
        amount
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncParams = /* GraphQL */ `
  query SyncParams(
    $filter: ModelParamFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncParams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        param
        value
        startDate
        endDate
        paramType
        keyFilter
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getParam = /* GraphQL */ `
  query GetParam($id: ID!) {
    getParam(id: $id) {
      id
      param
      value
      startDate
      endDate
      paramType
      keyFilter
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listParams = /* GraphQL */ `
  query ListParams(
    $filter: ModelParamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        param
        value
        startDate
        endDate
        paramType
        keyFilter
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmailSuscriptions = /* GraphQL */ `
  query SyncEmailSuscriptions(
    $filter: ModelEmailSuscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmailSuscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        active
        origin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmailSuscription = /* GraphQL */ `
  query GetEmailSuscription($id: ID!) {
    getEmailSuscription(id: $id) {
      id
      email
      active
      origin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listEmailSuscriptions = /* GraphQL */ `
  query ListEmailSuscriptions(
    $filter: ModelEmailSuscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailSuscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        active
        origin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPricings = /* GraphQL */ `
  query SyncPricings(
    $filter: ModelPricingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPricings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        marketReleaseDate
        marketFinalDate
        amount
        pricingType
        pricingName
        priceId
        salesRank {
          saleRankType
          quantity
          amount
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPricing = /* GraphQL */ `
  query GetPricing($id: ID!) {
    getPricing(id: $id) {
      id
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      marketReleaseDate
      marketFinalDate
      amount
      pricingType
      pricingName
      priceId
      salesRank {
        saleRankType
        quantity
        amount
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPricings = /* GraphQL */ `
  query ListPricings(
    $filter: ModelPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        marketReleaseDate
        marketFinalDate
        amount
        pricingType
        pricingName
        priceId
        salesRank {
          saleRankType
          quantity
          amount
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAudits = /* GraphQL */ `
  query SyncAudits(
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAudits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        auditType
        startAudit
        endAudit
        promoter
        auditor
        blacklist
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        result
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAudit = /* GraphQL */ `
  query GetAudit($id: ID!) {
    getAudit(id: $id) {
      id
      auditType
      startAudit
      endAudit
      promoter
      auditor
      blacklist
      customer {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      result
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAudits = /* GraphQL */ `
  query ListAudits(
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        auditType
        startAudit
        endAudit
        promoter
        auditor
        blacklist
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        result
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncXadisEmptyContainers = /* GraphQL */ `
  query SyncXadisEmptyContainers(
    $filter: ModelXadisEmptyContainerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncXadisEmptyContainers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        startDate
        endDate
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        emptyContainers {
          sku
          sap
          quantity
          stockType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getXadisEmptyContainer = /* GraphQL */ `
  query GetXadisEmptyContainer($id: ID!) {
    getXadisEmptyContainer(id: $id) {
      id
      startDate
      endDate
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      emptyContainers {
        sku
        sap
        quantity
        stockType
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listXadisEmptyContainers = /* GraphQL */ `
  query ListXadisEmptyContainers(
    $filter: ModelXadisEmptyContainerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listXadisEmptyContainers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        emptyContainers {
          sku
          sap
          quantity
          stockType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRemoteRequests = /* GraphQL */ `
  query SyncRemoteRequests(
    $filter: ModelRemoteRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRemoteRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        source
        startDate
        endDate
        status
        sync
        request
        response
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRemoteRequest = /* GraphQL */ `
  query GetRemoteRequest($id: ID!) {
    getRemoteRequest(id: $id) {
      id
      source
      startDate
      endDate
      status
      sync
      request
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listRemoteRequests = /* GraphQL */ `
  query ListRemoteRequests(
    $filter: ModelRemoteRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRemoteRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source
        startDate
        endDate
        status
        sync
        request
        response
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        notificationDates {
          datetime
          dateType
        }
        active
        title
        message
        chanel
        request
        response
        from
        to
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      notificationDates {
        datetime
        dateType
      }
      active
      title
      message
      chanel
      request
      response
      from
      to
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notificationDates {
          datetime
          dateType
        }
        active
        title
        message
        chanel
        request
        response
        from
        to
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncForecasts = /* GraphQL */ `
  query SyncForecasts(
    $filter: ModelForecastFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncForecasts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        project
        method
        startDate
        endDate
        bucketName
        awsRegion
        domain
        datasetFrequency
        timestampFormat
        forecastHorizon
        autoML
        datasets {
          schema
          datasetType
          files
        }
        forecastQuerys {
          nextToken
          startedAt
        }
        datasetGroupArn
        datasetArns
        datasetImportJobArns
        predictorArns
        forecastArn
        datasource {
          historyStartDate
          frequency
          query
          server
          database
        }
        update
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getForecast = /* GraphQL */ `
  query GetForecast($id: ID!) {
    getForecast(id: $id) {
      id
      project
      method
      startDate
      endDate
      bucketName
      awsRegion
      domain
      datasetFrequency
      timestampFormat
      forecastHorizon
      autoML
      datasets {
        schema
        datasetType
        files
      }
      forecastQuerys {
        items {
          id
          method
          startDate
          endDate
          filters
          request
          response
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      datasetGroupArn
      datasetArns
      datasetImportJobArns
      predictorArns
      forecastArn
      datasource {
        historyStartDate
        frequency
        query
        server
        database
        mapping {
          column
          propertie
          cast
        }
      }
      update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listForecasts = /* GraphQL */ `
  query ListForecasts(
    $filter: ModelForecastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForecasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        project
        method
        startDate
        endDate
        bucketName
        awsRegion
        domain
        datasetFrequency
        timestampFormat
        forecastHorizon
        autoML
        datasets {
          schema
          datasetType
          files
        }
        forecastQuerys {
          nextToken
          startedAt
        }
        datasetGroupArn
        datasetArns
        datasetImportJobArns
        predictorArns
        forecastArn
        datasource {
          historyStartDate
          frequency
          query
          server
          database
        }
        update
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncForecastQueries = /* GraphQL */ `
  query SyncForecastQueries(
    $filter: ModelForecastQueryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncForecastQueries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        method
        startDate
        endDate
        filters
        forecast {
          id
          project
          method
          startDate
          endDate
          bucketName
          awsRegion
          domain
          datasetFrequency
          timestampFormat
          forecastHorizon
          autoML
          datasetGroupArn
          datasetArns
          datasetImportJobArns
          predictorArns
          forecastArn
          update
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        request
        response
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getForecastQuery = /* GraphQL */ `
  query GetForecastQuery($id: ID!) {
    getForecastQuery(id: $id) {
      id
      method
      startDate
      endDate
      filters
      forecast {
        id
        project
        method
        startDate
        endDate
        bucketName
        awsRegion
        domain
        datasetFrequency
        timestampFormat
        forecastHorizon
        autoML
        datasets {
          schema
          datasetType
          files
        }
        forecastQuerys {
          nextToken
          startedAt
        }
        datasetGroupArn
        datasetArns
        datasetImportJobArns
        predictorArns
        forecastArn
        datasource {
          historyStartDate
          frequency
          query
          server
          database
        }
        update
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      request
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listForecastQuerys = /* GraphQL */ `
  query ListForecastQuerys(
    $filter: ModelForecastQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForecastQuerys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        method
        startDate
        endDate
        filters
        forecast {
          id
          project
          method
          startDate
          endDate
          bucketName
          awsRegion
          domain
          datasetFrequency
          timestampFormat
          forecastHorizon
          autoML
          datasetGroupArn
          datasetArns
          datasetImportJobArns
          predictorArns
          forecastArn
          update
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        request
        response
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkTeamTemplates = /* GraphQL */ `
  query SyncWorkTeamTemplates(
    $filter: ModelWorkTeamTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkTeamTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkTeamTemplate = /* GraphQL */ `
  query GetWorkTeamTemplate($id: ID!) {
    getWorkTeamTemplate(id: $id) {
      id
      workTeamTemplate
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      teamPositions {
        items {
          id
          quantity
          required
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      active
      minSalesAmount
      maxSalesAmount
      frecuencySales
      periodSales
      headcountReport {
        items {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      headcountStatus {
        items {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWorkTeamTemplates = /* GraphQL */ `
  query ListWorkTeamTemplates(
    $filter: ModelWorkTeamTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkTeamTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeamPositions = /* GraphQL */ `
  query SyncTeamPositions(
    $filter: ModelTeamPositionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamPositions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        quantity
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        required
        observation
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeamPosition = /* GraphQL */ `
  query GetTeamPosition($id: ID!) {
    getTeamPosition(id: $id) {
      id
      quantity
      position {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      required
      observation
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTeamPositions = /* GraphQL */ `
  query ListTeamPositions(
    $filter: ModelTeamPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quantity
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        required
        observation
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProviderProducts = /* GraphQL */ `
  query SyncProviderProducts(
    $filter: ModelProviderProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProviderProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        providerId
        productId
        purchasePrice
        unitsPerPackage
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProviderProduct = /* GraphQL */ `
  query GetProviderProduct($providerId: ID!, $productId: ID!) {
    getProviderProduct(providerId: $providerId, productId: $productId) {
      providerId
      productId
      purchasePrice
      unitsPerPackage
      taxes {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listProviderProducts = /* GraphQL */ `
  query ListProviderProducts(
    $providerId: ID
    $productId: ModelIDKeyConditionInput
    $filter: ModelProviderProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProviderProducts(
      providerId: $providerId
      productId: $productId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        providerId
        productId
        purchasePrice
        unitsPerPackage
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHeadcountStatuses = /* GraphQL */ `
  query SyncHeadcountStatuses(
    $filter: ModelHeadcountStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHeadcountStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vacantNumber
        assignedDays
        statusName
        workTeamTemplateID
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReports {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHeadcountStatus = /* GraphQL */ `
  query GetHeadcountStatus($id: ID!) {
    getHeadcountStatus(id: $id) {
      id
      vacantNumber
      assignedDays
      statusName
      workTeamTemplateID
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      headcountReports {
        items {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listHeadcountStatuss = /* GraphQL */ `
  query ListHeadcountStatuss(
    $filter: ModelHeadcountStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeadcountStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vacantNumber
        assignedDays
        statusName
        workTeamTemplateID
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReports {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHeadcountVacantLogs = /* GraphQL */ `
  query SyncHeadcountVacantLogs(
    $filter: ModelHeadcountVacantLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHeadcountVacantLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vacantCounter
        sinceDate
        calculationDate
        statusName
        statusId
        incidents {
          nextToken
          startedAt
        }
        reportId
        report {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail {
          count
          id
          positionId
          positionName
          date
        }
        incidentPlanAction {
          id
          incidentPlanActionName
          parentIncidentPlanActionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHeadcountVacantLog = /* GraphQL */ `
  query GetHeadcountVacantLog($id: ID!) {
    getHeadcountVacantLog(id: $id) {
      id
      vacantCounter
      sinceDate
      calculationDate
      statusName
      statusId
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      reportId
      report {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      detail {
        count
        id
        positionId
        positionName
        date
      }
      incidentPlanAction {
        id
        incidentPlanActionName
        parentIncidentPlanActionId
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentObservation {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listHeadcountVacantLogs = /* GraphQL */ `
  query ListHeadcountVacantLogs(
    $filter: ModelHeadcountVacantLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeadcountVacantLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vacantCounter
        sinceDate
        calculationDate
        statusName
        statusId
        incidents {
          nextToken
          startedAt
        }
        reportId
        report {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail {
          count
          id
          positionId
          positionName
          date
        }
        incidentPlanAction {
          id
          incidentPlanActionName
          parentIncidentPlanActionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHeadcountReports = /* GraphQL */ `
  query SyncHeadcountReports(
    $filter: ModelHeadcountReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHeadcountReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHeadcountReport = /* GraphQL */ `
  query GetHeadcountReport($id: ID!) {
    getHeadcountReport(id: $id) {
      id
      managerAttendantId
      vacants
      vacantsChangedAt
      status {
        id
        vacantNumber
        assignedDays
        statusName
        workTeamTemplateID
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReports {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplateName
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      statusId
      statusName
      warehouseId
      warehouseRelatedId
      warehouseName
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      vacantsLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listHeadcountReports = /* GraphQL */ `
  query ListHeadcountReports(
    $filter: ModelHeadcountReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeadcountReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkplans = /* GraphQL */ `
  query SyncWorkplans(
    $filter: ModelWorkplanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkplans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        workplanName
        description
        workplanSection {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkplan = /* GraphQL */ `
  query GetWorkplan($id: ID!) {
    getWorkplan(id: $id) {
      id
      workplanName
      description
      workplanSection {
        items {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWorkplans = /* GraphQL */ `
  query ListWorkplans(
    $filter: ModelWorkplanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkplans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workplanName
        description
        workplanSection {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkplanSections = /* GraphQL */ `
  query SyncWorkplanSections(
    $filter: ModelWorkplanSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkplanSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sectionName
        description
        workplanActivity {
          nextToken
          startedAt
        }
        workplan {
          id
          workplanName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkplanSection = /* GraphQL */ `
  query GetWorkplanSection($id: ID!) {
    getWorkplanSection(id: $id) {
      id
      sectionName
      description
      workplanActivity {
        items {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplan {
        id
        workplanName
        description
        workplanSection {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWorkplanSections = /* GraphQL */ `
  query ListWorkplanSections(
    $filter: ModelWorkplanSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkplanSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sectionName
        description
        workplanActivity {
          nextToken
          startedAt
        }
        workplan {
          id
          workplanName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkplanActivities = /* GraphQL */ `
  query SyncWorkplanActivities(
    $filter: ModelWorkplanActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkplanActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        activityName
        description
        marketReleaseDate
        marketFinalDate
        temporality {
          cron
          frecuency
          frecuencyType
        }
        score
        workplanComponents {
          nextToken
          startedAt
        }
        workplanSection {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkplanActivity = /* GraphQL */ `
  query GetWorkplanActivity($id: ID!) {
    getWorkplanActivity(id: $id) {
      id
      activityName
      description
      marketReleaseDate
      marketFinalDate
      temporality {
        cron
        frecuency
        frecuencyType
      }
      score
      workplanComponents {
        items {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplanSection {
        id
        sectionName
        description
        workplanActivity {
          nextToken
          startedAt
        }
        workplan {
          id
          workplanName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWorkplanActivitys = /* GraphQL */ `
  query ListWorkplanActivitys(
    $filter: ModelWorkplanActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkplanActivitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityName
        description
        marketReleaseDate
        marketFinalDate
        temporality {
          cron
          frecuency
          frecuencyType
        }
        score
        workplanComponents {
          nextToken
          startedAt
        }
        workplanSection {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkplanComponents = /* GraphQL */ `
  query SyncWorkplanComponents(
    $filter: ModelWorkplanComponentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkplanComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        label
        order
        required
        visible
        enable
        componentType
        properties
        quantificationType
        workplanActivity {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetail {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkplanComponent = /* GraphQL */ `
  query GetWorkplanComponent($id: ID!) {
    getWorkplanComponent(id: $id) {
      id
      label
      order
      required
      visible
      enable
      componentType
      properties
      quantificationType
      workplanActivity {
        id
        activityName
        description
        marketReleaseDate
        marketFinalDate
        temporality {
          cron
          frecuency
          frecuencyType
        }
        score
        workplanComponents {
          nextToken
          startedAt
        }
        workplanSection {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanExecutionDetail {
        id
        workplanExecution {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanComponent {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWorkplanComponents = /* GraphQL */ `
  query ListWorkplanComponents(
    $filter: ModelWorkplanComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkplanComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        order
        required
        visible
        enable
        componentType
        properties
        quantificationType
        workplanActivity {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetail {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkplanAssignments = /* GraphQL */ `
  query SyncWorkplanAssignments(
    $filter: ModelWorkplanAssignmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkplanAssignments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        workplanId
        assignmentId
        workplanAssignmentType
        marketReleaseDate
        marketFinalDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkplanAssignment = /* GraphQL */ `
  query GetWorkplanAssignment($workplanId: ID!, $assignmentId: ID!) {
    getWorkplanAssignment(
      workplanId: $workplanId
      assignmentId: $assignmentId
    ) {
      workplanId
      assignmentId
      workplanAssignmentType
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWorkplanAssignments = /* GraphQL */ `
  query ListWorkplanAssignments(
    $workplanId: ID
    $assignmentId: ModelIDKeyConditionInput
    $filter: ModelWorkplanAssignmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkplanAssignments(
      workplanId: $workplanId
      assignmentId: $assignmentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        workplanId
        assignmentId
        workplanAssignmentType
        marketReleaseDate
        marketFinalDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkplanExecutions = /* GraphQL */ `
  query SyncWorkplanExecutions(
    $filter: ModelWorkplanExecutionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkplanExecutions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        startDate
        endDate
        score
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetails {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkplanExecution = /* GraphQL */ `
  query GetWorkplanExecution($id: ID!) {
    getWorkplanExecution(id: $id) {
      id
      startDate
      endDate
      score
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanExecutionDetails {
        items {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWorkplanExecutions = /* GraphQL */ `
  query ListWorkplanExecutions(
    $filter: ModelWorkplanExecutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkplanExecutions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        score
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetails {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkplanExecutionDetails = /* GraphQL */ `
  query SyncWorkplanExecutionDetails(
    $filter: ModelWorkplanExecutionDetailFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkplanExecutionDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        workplanExecution {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanComponent {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkplanExecutionDetail = /* GraphQL */ `
  query GetWorkplanExecutionDetail($id: ID!) {
    getWorkplanExecutionDetail(id: $id) {
      id
      workplanExecution {
        id
        startDate
        endDate
        score
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetails {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanComponent {
        id
        label
        order
        required
        visible
        enable
        componentType
        properties
        quantificationType
        workplanActivity {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetail {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      detail
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWorkplanExecutionDetails = /* GraphQL */ `
  query ListWorkplanExecutionDetails(
    $filter: ModelWorkplanExecutionDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkplanExecutionDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workplanExecution {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanComponent {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRemoteCommands = /* GraphQL */ `
  query SyncRemoteCommands(
    $filter: ModelRemoteCommandFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRemoteCommands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        remoteCommandName
        remoteCommandDescription
        command
        enable
        remoteControl {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRemoteCommand = /* GraphQL */ `
  query GetRemoteCommand($id: ID!) {
    getRemoteCommand(id: $id) {
      id
      remoteCommandName
      remoteCommandDescription
      command
      enable
      remoteControl {
        items {
          id
          warehouseId
          params
          response
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listRemoteCommands = /* GraphQL */ `
  query ListRemoteCommands(
    $filter: ModelRemoteCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRemoteCommands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        remoteCommandName
        remoteCommandDescription
        command
        enable
        remoteControl {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRemoteControls = /* GraphQL */ `
  query SyncRemoteControls(
    $filter: ModelRemoteControlFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRemoteControls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        remoteCommand {
          id
          remoteCommandName
          remoteCommandDescription
          command
          enable
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouseId
        params
        remoteControlStatus {
          startDatetime
          endDatetime
          remoteControlStatusType
          attendantId
        }
        response
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRemoteControl = /* GraphQL */ `
  query GetRemoteControl($id: ID!) {
    getRemoteControl(id: $id) {
      id
      remoteCommand {
        id
        remoteCommandName
        remoteCommandDescription
        command
        enable
        remoteControl {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouseId
      params
      remoteControlStatus {
        startDatetime
        endDatetime
        remoteControlStatusType
        attendantId
      }
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listRemoteControls = /* GraphQL */ `
  query ListRemoteControls(
    $filter: ModelRemoteControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRemoteControls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        remoteCommand {
          id
          remoteCommandName
          remoteCommandDescription
          command
          enable
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouseId
        params
        remoteControlStatus {
          startDatetime
          endDatetime
          remoteControlStatusType
          attendantId
        }
        response
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const productsByOrganization = /* GraphQL */ `
  query ProductsByOrganization(
    $organizationId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByOrganization(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const saleByWarehouseId = /* GraphQL */ `
  query SaleByWarehouseId(
    $warehouseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saleByWarehouseId(
      warehouseId: $warehouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customerTypesByProductKitId = /* GraphQL */ `
  query CustomerTypesByProductKitId(
    $customerTypeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerTypeProductKitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerTypesByProductKitId(
      customerTypeId: $customerTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        productKitId
        customerTypeId
        marketReleaseDate
        marketFinalDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customersByWarehouseId = /* GraphQL */ `
  query CustomersByWarehouseId(
    $warehouseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWarehouseCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByWarehouseId(
      warehouseId: $warehouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        customerId
        warehouseId
        marketReleaseDate
        marketFinalDate
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const attendantsByWarehouseId = /* GraphQL */ `
  query AttendantsByWarehouseId(
    $warehouseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWarehouseAttendantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendantsByWarehouseId(
      warehouseId: $warehouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        attendantId
        warehouseId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const itemsByProductId = /* GraphQL */ `
  query ItemsByProductId(
    $productId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSaleItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productId
        quantity
        pricePerUnit
        price
        taxAmount
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardex {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const productKitsByWarehouseId = /* GraphQL */ `
  query ProductKitsByWarehouseId(
    $warehouseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWarehouseProductKitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productKitsByWarehouseId(
      warehouseId: $warehouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        productKitId
        warehouseId
        marketReleaseDate
        marketFinalDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const inventoryByWarehouseId = /* GraphQL */ `
  query InventoryByWarehouseId(
    $warehouseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryByWarehouseId(
      warehouseId: $warehouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        productId
        warehouseId
        inventoryAmount
        minExistence
        maxExistence
        erpAmount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const warehouseIncidentsByDay = /* GraphQL */ `
  query WarehouseIncidentsByDay(
    $warehouseId: ID
    $day: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    warehouseIncidentsByDay(
      warehouseId: $warehouseId
      day: $day
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datetime
        day
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouseId
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentType {
          id
          incidentTypeId
          incidentTypeName
          parentIncidentTypeId
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        description
        headcountCount
        vacantLog {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const menuScopesByAttendantId = /* GraphQL */ `
  query MenuScopesByAttendantId(
    $attendantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSystemAttendantMenuScopeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuScopesByAttendantId(
      attendantId: $attendantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        systemMenuId
        attendantId
        scopes {
          scopeName
          active
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const productByProvider = /* GraphQL */ `
  query ProductByProvider(
    $productId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProviderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByProvider(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        providerId
        productId
        purchasePrice
        unitsPerPackage
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const workTeamTemplateStatusByAssignedDays = /* GraphQL */ `
  query WorkTeamTemplateStatusByAssignedDays(
    $workTeamTemplateID: ID
    $assignedDays: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHeadcountStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workTeamTemplateStatusByAssignedDays(
      workTeamTemplateID: $workTeamTemplateID
      assignedDays: $assignedDays
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vacantNumber
        assignedDays
        statusName
        workTeamTemplateID
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReports {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const reportLogsByCalculationDate = /* GraphQL */ `
  query ReportLogsByCalculationDate(
    $reportId: ID
    $calculationDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHeadcountVacantLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportLogsByCalculationDate(
      reportId: $reportId
      calculationDate: $calculationDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vacantCounter
        sinceDate
        calculationDate
        statusName
        statusId
        incidents {
          nextToken
          startedAt
        }
        reportId
        report {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail {
          count
          id
          positionId
          positionName
          date
        }
        incidentPlanAction {
          id
          incidentPlanActionName
          parentIncidentPlanActionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const reportStatusByVacantsChangedAt = /* GraphQL */ `
  query ReportStatusByVacantsChangedAt(
    $statusId: ID
    $vacantsChangedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHeadcountReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportStatusByVacantsChangedAt(
      statusId: $statusId
      vacantsChangedAt: $vacantsChangedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const workplanByAssignment = /* GraphQL */ `
  query WorkplanByAssignment(
    $assignmentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWorkplanAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workplanByAssignment(
      assignmentId: $assignmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        workplanId
        assignmentId
        workplanAssignmentType
        marketReleaseDate
        marketFinalDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
