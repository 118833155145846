import { takeLatest, all, put, select, call } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import {
  ADD_INDICATOR_ELEMENT,
  TEST_INDICATOR,
  SAVE_INDICATOR,
  ADD_INDICATOR_FUNCTION_ELEMENT,
  DELETE_INDICATOR_ELEMENT,
  EDIT_INDICATOR_ELEMENT,
  EDIT_INDICATOR,
  SAVE_EDIT_INDICATOR,
  DELETE_INDICATOR,
  EDIT_INDICATOR_FUNCTION_ARGUMENT_ELEMENT,
} from './constants';
import {
  pushElementIndicatorStep,
  pushElementDictionaryIndicator,
  pushFunctionElementIndicator,
  setTestIndicator,
  setSaveIndicator,
  resetIndicatorData,
  setIndicatorSending,
  popElementIndicator,
  popElementDictionaryIndicator,
  replaceElementIndicator,
  popFunctionArgumentElementIndicator,
  popAllFunctionArgumentsIndicatorDictionary,
  rehydrateIndicator,
  resetIndicatorDataSet,
  isLoadingEdit,
  setIndicatorDeleting,
  replaceFunctionElementIndicator,
  setNoExistIdsIndicator,
} from './actions';
import { setNotificationMessage } from '../notification/actions';
import {
  getIndicatorSteps,
  getIndicatorDictionary,
  getIndicatorName,
  getIndicatorArea,
} from './selectors';
import API from '../../services/api';
import { createBase64Expression, errorMessage } from '../../helpers';

export function* addElementToDictionary(element) {
  const {
    object: {
      name,
      value: { selected, value },
    },
  } = element;

  switch (selected) {
    case 'variable':
      yield put(
        pushElementDictionaryIndicator({
          type: 'variables',
          value: { compiler_tag: name, id: value.id, variable_tag: value.tag },
        }),
      );
      break;

    case 'parameter':
      yield put(
        pushElementDictionaryIndicator({
          type: 'parameters',
          value: { compiler_tag: name, id: value.id },
        }),
      );
      break;

    case 'function':
      yield put(
        pushElementDictionaryIndicator({
          type: 'functions',
          value: { compiler_tag: name, id: value.id },
        }),
      );
      break;

    default:
      break;
  }
}

export function* addIndicatorElement(action) {
  const {
    payload: { step, type, object, name },
  } = action;
  const element = {
    step: step,
    object: {
      name: isEmpty(name) ? object.selected + '_item_' + Date.now() : name,
      type: type,
      value: object,
    },
  };

  yield put(pushElementIndicatorStep(element));

  if (element.object.type === 'element') {
    if (element.object.value.selected === 'function') {
      element.object.functionArguments = [];
    }
    yield addElementToDictionary(element);
  }
}

export function* addIndicatorFunctionElement(action) {
  const {
    payload: { payload, isFunction },
  } = action;

  const elementName = payload.object.selected + '_item_' + Date.now();
  const element = {
    step: payload.step,
    object: {
      name: isEmpty(payload.name) ? elementName : payload.name,
      type: payload.type,
      value: payload.object,
    },
  };

  const elementFunction = {
    step: payload.step,
    stepIndex: isFunction.stepPosition,
    object: {
      name: isEmpty(payload.name) ? elementName : payload.name,
      type: payload.type,
      value: payload.object,
    },
  };

  yield put(pushFunctionElementIndicator(elementFunction));
  yield addElementToDictionary(element);
}

export function* editIndicatorFunctionElement(action) {
  const {
    payload: { payload, editData },
  } = action;

  switch (editData.type) {
    case 'parameter':
      editData.type = 'parameters';
      break;
    case 'variable':
      editData.type = 'variables';
      break;
    case 'function':
      editData.type = 'functions';
      break;
    default:
      editData.type = '';
      break;
  }

  if (!isEmpty(editData.type)) {
    yield put(popElementDictionaryIndicator(editData));
  }

  const elementName = payload.object.selected + '_item_' + Date.now();
  const elementFunction = {
    step: editData.step,
    stepIndex: editData.index,
    object: {
      name: isEmpty(payload.name) ? elementName : payload.name,
      type: payload.type,
      value: payload.object,
    },
  };
  console.log({ elementFunction, editData });
  yield put(replaceFunctionElementIndicator({ elementFunction, editData }));
  yield addElementToDictionary(elementFunction);
}

export function* createIndicatorCompletePayload() {
  const name = yield select(getIndicatorName);
  const dictionary = yield select(getIndicatorDictionary);
  const steps = yield select(getIndicatorSteps);
  const expression = yield createBase64Expression(steps);

  return {
    name: name,
    parameters: dictionary.parameters,
    variables: dictionary.variables,
    functions: dictionary.functions,
    expression: expression,
    html_reference: JSON.stringify(steps),
  };
}

export function* testIndicator() {
  const steps = yield select(getIndicatorSteps);
  const expression = yield createBase64Expression(steps);
  try {
    const response = yield call(API.compilerCodeScan, {
      expression: expression,
    });

    const { data: evaluation_result } = response;

    if (evaluation_result) {
      yield put(setTestIndicator(true));
      yield put(
        setNotificationMessage({
          type: 'success',
          message: `La construcción de tu Indicador es correcta`,
        }),
      );
    } else {
      yield put(setTestIndicator(false));
      yield put(
        setNotificationMessage({
          type: 'error',
          message: `Verifica la construcción de tu Indicador`,
        }),
      );
    }
  } catch (error) {
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `Verifica la construcción de tus pasos`,
      }),
    );
    yield put(setTestIndicator(false));
  }
}

export function* saveIndicator() {
  const name = yield select(getIndicatorName);
  const area = yield select(getIndicatorArea);
  const payload = yield createIndicatorCompletePayload();

  yield put(setIndicatorSending(true));
  try {
    yield call(API.createIndicator, area.id, payload);
    yield put(setSaveIndicator(true));
    yield put(resetIndicatorData());
    yield put(`/indicadores/lista/${area.id}`);
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se guardo con éxito el Indicador ${name}`,
      }),
    );
  } catch (error) {
    yield put(setSaveIndicator(false));
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se pudo guardar el Indicador, intenta más tarde`,
      }),
    );
  } finally {
    yield put(setIndicatorSending(false));
  }
}

export function* deleteIndicatorElement(action) {
  const { payload } = action;

  switch (payload.type) {
    case 'parameter':
      payload.type = 'parameters';
      break;
    case 'variable':
      payload.type = 'variables';
      break;
    case 'function':
      payload.type = 'functions';
      break;
    default:
      payload.type = '';
      break;
  }

  if (!isEmpty(payload.type)) {
    yield put(popElementDictionaryIndicator(payload));
    if (payload.type === 'functions') {
      //let functionName = payload.name;
      yield put(popAllFunctionArgumentsIndicatorDictionary(payload));
    }
  }

  if (payload.functionName === null) {
    yield put(popElementIndicator(payload));
  } else {
    yield put(popFunctionArgumentElementIndicator(payload));
  }
}

export function* editIndicatorElement(action) {
  const {
    payload: { payload, editData },
  } = action;

  const element = {
    step: payload.step,
    object: {
      name: isEmpty(payload.name)
        ? payload.object.selected + '_item_' + Date.now()
        : payload.name,
      type: payload.type,
      value: payload.object,
    },
  };

  yield put(replaceElementIndicator({ element, editData }));
  if (element.object.type === 'element') {
    if (element.object.value.selected === 'function') {
      element.object.functionArguments = [];
    }
    switch (editData.type) {
      case 'parameter':
        editData.type = 'parameters';
        break;
      case 'variable':
        editData.type = 'variables';
        break;
      case 'function':
        editData.type = 'functions';
        break;
      default:
        editData.type = '';
        break;
    }
    if (!isEmpty(editData.type)) {
      yield put(popElementDictionaryIndicator(editData));
    }
    yield addElementToDictionary(element);
  }
}

export function* editIndicator(action) {
  const { payload } = action;
  yield put(isLoadingEdit(true));
  try {
    const response = yield call(API.getSingleIndicator, payload);
    const { data } = response;
    const {
      name,
      html_reference,
      parameter_id_list,
      variable_id_tag_list,
      hfunction_id_list,
      hierarchical_node,
    } = data;
    yield put(
      rehydrateIndicator({
        name,
        steps: JSON.parse(html_reference),
        parameters: parameter_id_list,
        variables: variable_id_tag_list,
        functions: hfunction_id_list,
        area: { id: hierarchical_node.id },
        organization: { id: hierarchical_node.organization_id },
      }),
    );
  } catch (error) {
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se pudo cargar la información de tu Indicador, intenta más tarde`,
      }),
    );
  } finally {
    yield put(isLoadingEdit(false));
  }
}

export function* editSaveIndicator(action) {
  const indicatorId = action.payload;
  const name = yield select(getIndicatorName);
  const area = yield select(getIndicatorArea);
  const payload = yield createIndicatorCompletePayload();

  payload.hierarchical_node_id = area.id;

  yield put(setIndicatorSending(true));
  try {
    yield call(API.updateIndicator, indicatorId, payload);
    yield put(setSaveIndicator(true));
    yield put(resetIndicatorData());
    yield put(`/indicadores/lista/${area.id}`);
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se edito con éxito el Indicador ${name}`,
      }),
    );
  } catch (error) {
    let errorTxt;
    const {
      data: { errors },
    } = error.response;

    const [noExistingIds, errorType] = errorMessage(errors);

    yield put(setNoExistIdsIndicator(noExistingIds));
    yield put(setSaveIndicator(false));

    switch (errorType) {
      case 'function':
        errorTxt =
          'No se pudo guardar tu Indicador, Está función ya no existe.';
        break;

      case 'parameter':
        errorTxt =
          'No se pudo guardar tu Indicador, Este parametro ya no existe.';
        break;

      case 'variable':
        errorTxt =
          'No se pudo guardar tu Indicador, Está variable ya no existe.';
        break;

      default:
        errorTxt = 'No se pudo guardar tu Indicador, intenta más tarde';
        break;
    }

    yield put(
      setNotificationMessage({
        type: 'error',
        message: errorTxt,
      }),
    );
  } finally {
    yield put(setIndicatorSending(false));
  }
}

export function* deleteIndicator(action) {
  const indicatorId = action.payload;
  const name = yield select(getIndicatorName);
  const area = yield select(getIndicatorArea);

  yield put(setIndicatorDeleting(true));
  try {
    yield call(API.deleteIndicator, indicatorId);
    yield put(resetIndicatorDataSet());
    yield put(`/indicadores/lista/${area.id}`);
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se elimino con éxito tu Indicador ${name}`,
      }),
    );
  } catch (error) {
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se puedo eliminar tu Indicador, intenta más tarde`,
      }),
    );
  } finally {
    yield put(setIndicatorDeleting(false));
  }
}

export default function* indicatorsSaga() {
  yield all([
    takeLatest(ADD_INDICATOR_ELEMENT, addIndicatorElement),
    takeLatest(TEST_INDICATOR, testIndicator),
    takeLatest(SAVE_INDICATOR, saveIndicator),
    takeLatest(ADD_INDICATOR_FUNCTION_ELEMENT, addIndicatorFunctionElement),
    takeLatest(DELETE_INDICATOR_ELEMENT, deleteIndicatorElement),
    takeLatest(EDIT_INDICATOR_ELEMENT, editIndicatorElement),
    takeLatest(EDIT_INDICATOR, editIndicator),
    takeLatest(SAVE_EDIT_INDICATOR, editSaveIndicator),
    takeLatest(DELETE_INDICATOR, deleteIndicator),
    takeLatest(
      EDIT_INDICATOR_FUNCTION_ARGUMENT_ELEMENT,
      editIndicatorFunctionElement,
    ),
  ]);
}
