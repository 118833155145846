import axios from 'axios';
import { APIBAMASTAGE } from '../env/constants';

const api = axios.create({
  baseURL: APIBAMASTAGE,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
});

const getOrganizations = () => api.get(`/organization`);
const getAreas = organizationId =>
  api.get(`/organization/${organizationId}/area`);
const getDataSource = organizationId =>
  api.get(`/organization/${organizationId}/data-source`);
const getVariables = dataSourceId =>
  api.get(`/data-source/${dataSourceId}/variable`);
const getEmployeeCatalog = organizationId =>
  api.get(`/organization/${organizationId}/employee`);
const getAreaIndicators = areaId => api.get(`/area/${areaId}/indicator`);
const getParameters = areaId => api.get(`/area/${areaId}/parameter`);
const getFunctions = () => api.get(`/helper-function`);
const getEmployees = areaId => api.get(`/area/${areaId}/employee`);

const searchEmployeeReport = payload => api.post(`/report/search`, payload);

const getEmployeeReportEvaluation = reportId => api.get(`/report/${reportId}`);

const compilerCodeScan = payload => api.post('/compiler/code-scan', payload);

const createFunction = payload => api.post('/helper-function', payload);

const getIndicators = areaId => api.get(`/area/${areaId}/indicator`);

const getKPIS = areaId => api.get(`/area/${areaId}/variable-commission`);

const getVariableCommission = commisionId =>
  api.get(`/variable-commission/${commisionId}`);

const createComission = (areaId, payload) =>
  api.post(`/area/${areaId}/variable-commission`, payload);

const updateComission = (commisionId, payload) =>
  api.patch(`/variable-commission/${commisionId}/`, payload);

const deleteComission = commisionId =>
  api.delete(`/variable-commission/${commisionId}/`);

const createIndicator = (areaId, payload) =>
  api.post(`/area/${areaId}/indicator`, payload);

const getSingleIndicator = indicatorId => api.get(`/indicator/${indicatorId}`);

const updateIndicator = (indicatorId, payload) =>
  api.patch(`/indicator/${indicatorId}/`, payload);

const deleteIndicator = commisionId => api.delete(`/indicator/${commisionId}/`);

const getHelperFunction = helperFunctionId =>
  api.get(`/helper-function/${helperFunctionId}`);

const updateFunction = (helperFunctionId, payload) =>
  api.patch(`/helper-function/${helperFunctionId}/`, payload);

const deleteFunction = functionHelperId =>
  api.delete(`/helper-function/${functionHelperId}/`);

const runCommission = payload =>
  api.post(`/variable-commission/calculate`, payload);

const searchOrganizationReport = payload => api.post(`/report/search`, payload);

export default {
  getOrganizations,
  getAreas,
  getDataSource,
  getVariables,
  getEmployeeCatalog,
  getAreaIndicators,
  getParameters,
  compilerCodeScan,
  getFunctions,
  getEmployees,
  searchEmployeeReport,
  getEmployeeReportEvaluation,
  createFunction,
  getIndicators,
  getKPIS,
  createIndicator,
  getVariableCommission,
  createComission,
  updateComission,
  deleteComission,
  getSingleIndicator,
  updateIndicator,
  deleteIndicator,
  getHelperFunction,
  updateFunction,
  deleteFunction,
  runCommission,
  searchOrganizationReport
};
