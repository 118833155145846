import React, { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

export const localization = (props) => {
    var loc = {
        body: {
            emptyDataSourceMessage: props.translate('No records to display'),
            editRow: {
                deleteText: props.translate('Are you sure you want to delete this row?'),
                cancelTooltip: props.translate('Cancel'),
                saveTooltip: props.translate('Save')
            },
            addTooltip: props.translate('Add'),
            deleteTooltip: props.translate('Delete'),
            editTooltip: props.translate('Edit'),
            filterRow: {
                filterTooltip: props.translate('Filter'),
            },

        },
        toolbar: {
            addRemoveColumns: props.translate('Add or remove columns'),
            nRowsSelected: '{0} ' + props.translate('row(s) selected'),
            showColumnsTitle: props.translate('Show Columns'),
            showColumnsAriaLabel: props.translate('Show Columns'),
            exportTitle: props.translate('Export'),
            exportAriaLabel: props.translate('Export'),
            exportName: props.translate('Export as CSV'),
            searchTooltip: props.translate('Search'),
            searchPlaceholder: props.translate('Search')
        },
        pagination: {
            labelRowsSelect: props.translate('Rows'),
            labelDisplayedRows: ' {from}-{to} ' + props.translate('of') + ' {count}',
            labelRowsPerPage: props.translate('Rows per page:') + ':',
            firstAriaLabel: props.translate('First Page'),
            firstTooltip: props.translate('First Page'),
            previousAriaLabel: props.translate('Previous Page'),
            previousTooltip: props.translate('Previous Page'),
            nextAriaLabel: props.translate('Next Page'),
            nextTooltip: props.translate('Next Page'),
            lastAriaLabel: props.translate('Last Page'),
            lastTooltip: props.translate('Last Page')
        },
        header: {
            actions: props.translate('Actions')
        },
        grouping: {
            placeholder: props.translate('Drag headers')
        }
    }

    return loc
}