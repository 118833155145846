import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isReportsHaveStructure } from '../../store/reports/selectors';

import InitReport from './InitReport';
import ListReports from './ListReports';

export class Reports extends Component {
  render() {
    const { structure } = this.props;

    if (!structure) {
      return <InitReport {...this.props} />;
    }
    return <ListReports {...this.props} />;
  }
}

const mapStateToProps = state => ({
  structure: isReportsHaveStructure(state),
});

export default connect(mapStateToProps, null)(Reports);
