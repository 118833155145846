import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import { listWarehouses } from "../../../graphql/queries";
import { updateWarehouseCustomer, createWarehouseCustomer, deleteWarehouseCustomer } from "../../../graphql/mutations";
import { SNACKBAR_ERROR, SNACKBAR_INFO } from "../../../utils/SessionControl";
import { api, apiPartial } from "../../../api/api";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {
  const classes = useStyles();
  const firstRender = useRef(true);
  const [WarehouseList, setWarehouseList] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false; // it's no longer the first render
      loadWarehouses();
      return; // skip the code below
    }
  });

  const loadWarehouses = () => {
    var _WarehousesFilter = {
      limit: 300,
    };

    api(listWarehouses, _WarehousesFilter)
      .then((res) => {
        res.forEach((items) => {
          if (items.warehouseType === "_10") {
            setLeft((left) => [...left, items.warehouseName]);
            setWarehouseList((WarehouseList) => [...WarehouseList, items]);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateWareHauseCustomer = () => new Promise((resolve, reject) => {
    props.customers.forEach((itemCustomer) => {
      // console.log(itemCustomer)
      WarehouseList.forEach((items) => {
        // console.log(items)
        let paramsUpdCustomer = {
          customerId: itemCustomer.id,
          warehouseId: items.id,
          marketFinalDate: parseInt(new Date().getTime() / 1000)
        }
        UpdateWarehouse(paramsUpdCustomer, resolve, reject).then(wc => {
          right.map((itemsRight) => {
            if (items.warehouseName === itemsRight && items.id === wc.warehouseId) {
              console.log(wc)
              let paramsUpdCustomer = {
                customerId: wc.customerId,
                warehouseId: wc.warehouseId,
                marketFinalDate: 0
              }
              UpdateWarehouse(paramsUpdCustomer, resolve, reject)
            }
          });
        });
      });
    });
  })

  const UpdateWarehouse = (input_params) => new Promise((resolve, reject) => {
    api(updateWarehouseCustomer, { input: input_params })
      .then((res) => {
        // console.log(res)
        resolve(res);
      })
      .catch((err) => {
        // console.log(err)
        createWarehouse(input_params, resolve, reject);
        // reject(err);
      });
  });

  const createWarehouse = (input_params, resolve, reject) => {
    let paramsUpdCustomer = {
      customerId: input_params.customerId,
      warehouseId: input_params.warehouseId,
      marketReleaseDate: parseInt(new Date().getTime() / 1000),
      marketFinalDate: input_params.marketFinalDate ? input_params.marketFinalDate : 0
    }
    api(createWarehouseCustomer, { input: paramsUpdCustomer })
      .then((res) => {
        // console.log(res)
        resolve(res);
      })
      .catch((err) => {
        // console.log(err)
        reject(err);
      });
  };


  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items seleccionados" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} seleccionados`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );


  function Listcomplete() {
    return (
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>{customList("Selecciona", left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
          </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
          </Button>
          </Grid>
        </Grid>
        <Grid item>{customList("Seleccionados", right)}</Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      <Grid item xs={12}>
        <Listcomplete ></Listcomplete>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          alignItems="center"
        >
          <Grid item>
            <Button variant="contained"
              color="primary"
              onClick={event => handleUpdateWareHauseCustomer(event)}>
              Guardar selección
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
