import React from 'react';
import SessionControl from '../../utils/SessionControl';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';

class LoginForm extends SessionControl {

    constructor(props) {
        super(props);
        this.state = {
            username: props.user && props.user.username ? props.user.username : '',
            password: props.user && props.user.password ? props.user.password : '',
            isReady: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getDisabled() {
        let disabled = false;
        if (!this.state.username || this.state.username.length <= 4)
            disabled = true;
        if (!this.state.password || this.state.password.length <= 3)
            disabled = true;
        return disabled;
    }

    handleSubmit(event) {
        const { username, password } = this.state;
        this.props.handleSubmit({ username, password });
        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Box
                    py={2}>
                    <Box
                        p={1}>
                        <TextField
                            name="username"
                            label={this.translate('User')}
                            variant="outlined"
                            required
                            fullWidth
                            type="text"
                            onChange={(username) => this.setState({ username: username.target.value })}
                            value={this.state.username}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon color="action" />
                                    </InputAdornment>
                                ),
                            }} />
                    </Box>
                    <Box
                        p={1}>
                        <TextField
                            name="password"
                            label={this.translate('Password')}
                            variant="outlined"
                            required
                            fullWidth
                            type="password"
                            onChange={(password) => this.setState({ password: password.target.value })}
                            value={this.state.password}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon color="action" />
                                    </InputAdornment>
                                ),
                            }} />
                    </Box>
                    <Box
                        m={2}
                        pt={1}
                        display="flex"
                        justifyContent='center'
                        alignItems='stretch'>
                        <Button
                            type="submit"
                            disabled={this.getDisabled()}
                            variant="contained"
                            color="primary">
                            {this.translate("Sign In")}
                        </Button>
                    </Box>
                </Box>
            </form>
        );
    }
}

export default LoginForm;