import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';

import { withSnackbar } from 'notistack';

function Settings(props) {
    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render
            return // skip the code below
        }

        return function cleanup() {

        };
    }, []) // Only re-run the effect if name or email change

    return <Box></Box>
}
export default withSnackbar(Settings);