import React from 'react';
import { Grid, Link, Box } from "@material-ui/core"

/*import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';*/

function Footer() {
    return (
        <Box mt={4} pb={7}>
            <Grid container
                spacing={1}
                alignItems="center"
                justify="space-between">
                <Grid item>
                    <Link
                        /*href="https://bama-products-images.s3.amazonaws.com/public/documents/AVISO+DE+PRIVACIDAD+TIENDAS+BAMA.PDF"*/
                        href="/noticePrivacy"
                        target="_blank">
                        {"Aviso de Privacidad"}
                    </Link>
                </Grid>
                <Grid item>
                    {"Tiendas BAMA SA de CV - Todos los derechos reservados - "} {new Date().getFullYear()}
                </Grid>
                {/*<Grid item>
                    <Grid container
                        spacing={1}
                        alignItems="center"
                        justify="center">
                        <Grid item>
                            <Link
                                href="https://twitter.com/tiendasbama"
                                target="_blank">
                                <TwitterIcon />
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                href="https://www.facebook.com/tiendasbama"
                                target="_blank">
                                <FacebookIcon />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>*/}
            </Grid>
        </Box>
    )
}

export default Footer