import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import {
    Grid,
    Typography,
    MenuItem,
    TextField,
  } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
    },
    Button: {
       margin: theme.spacing(2),
       align:"center"
    },
  }));

const TypeEnumCustomer = {ACTIVO: "PRINCIPAL",INACTIVO: "INACTIVO",}

export default function RowCustomerSetting(props) {
  const classes = useStyles();
  const [typeCustomer, settypeCustomer] = React.useState(props.itemTypeCustomer); 
  const { customerTypeId,customerTypeName,defaultType } = props;
  const firstRender = useRef(true);


  useEffect(() => {
    if (firstRender.current) {
      if (firstRender.current) {
        firstRender.current = false; // it's no longer the first render

        return; // skip the code below
      }
    }

  }, []); // Only re-run the effect if name or email change

  const handleChangeType = TypeId => event => {
    settypeCustomer({ ...typeCustomer, ["defaultType"]: !TypeId.defaultType }); 
    props.handleChangeType("defaultType",TypeId,!TypeId.defaultType);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={4}>
        <Typography variant="subtitle2" align="center">
          {customerTypeId}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" align="center">
          {customerTypeName}
        </Typography>
      </Grid>
      <Grid item xs={4} align="center">
        {defaultType === true ? (
          <Button
            className={classes.Button}
            variant="contained"
            color="primary"
            onClick= {handleChangeType(typeCustomer)}
          >
            {TypeEnumCustomer.ACTIVO}
          </Button>
        ) : (
          <Button className={classes.Button} 
            variant="contained"
            onClick= {handleChangeType(typeCustomer)}
           >
            {TypeEnumCustomer.INACTIVO}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
