import React, { useState, useEffect } from 'react';
import { sha256, sha224 } from 'js-sha256';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import CloseIcon from '@material-ui/icons/Close';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DialpadIcon from '@material-ui/icons/Dialpad';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { Box, Container, Slide, Dialog, AppBar, Toolbar, IconButton, Grid, TextField, InputAdornment } from '@material-ui/core';

import { PhoneType } from '../../../schema/enums/schemaEnums'
import { tableIcons, localization } from '../../materialTable/MaterialTable';

import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Account(props) {

    const [accountState, setAccountState] = useState({
        user: props.user,
        userSignIn: props.userSignIn,
        modalOpen: false,
        columns: [
            {
                title: props.translate('Phone Type'),
                field: 'phoneType',
                lookup: Object.keys(PhoneType).reduce((obj, item) => {
                    return {
                        ...obj,
                        [PhoneType[item]]: props.translate(PhoneType[item]),
                    };
                }, {})
            },
            {
                title: props.translate('Telephone Number'),
                field: 'phone',
                editCommponent: _props => (
                    <input
                        pattern="[0-9]{2} [0-9]{3} [0-9]{3} [0-9]{4}"
                        type="tel"
                        value={_props.value}
                        onChange={e => _props.onChange(e.target.value)} />
                )
            },
        ],
    })

    useEffect(() => {
        if (accountState.user !== props.user
            || accountState.userSignIn !== props.userSignIn)
            setAccountState({
                ...accountState,
                user: props.user,
                userSignIn: props.userSignIn
            })
    }, [accountState, props.user, props.userSignIn]);

    const classes = useStyles();

    const handleOpenModal = () => {
        setAccountState({
            ...accountState,
            modalOpen: true
        });
    };

    const handleCloseModal = () => {
        setAccountState({
            ...accountState,
            modalOpen: false
        });
    };

    const handleSubmit = (event) => {
        console.log("handleSubmit", event, accountState);

        var phone = "", _phones = []

        if (accountState.user.phones)
            accountState.user.phones.map(p => {
                if (phone.phoneType === "mobile")
                    phone = p

                _phones.push({
                    phone: "+52" + p.phone,
                    phoneType: p.phoneType
                })
            })



        const update_user = {

            attendant: {
                nipPassword: accountState.new_nip,
                phones: _phones,
                password: accountState.new_password
            }
        }

        if (phone !== "") {
            update_user.cognito = {
                'custom:login_name': accountState.new_nickname,
                phone_number: phone
            }
        }

        props.handleSaveProfile(update_user)
            .then(user => console.log(user))
            .catch(err => console.log(err));

        handleCloseModal()

        event.preventDefault();
    }

    const getPosition = (id) => {
        var position = ""

        props.positions && props.positions.map(p => {
            if (p.id === id)
                position = props.translate(p.positionName)
        })

        return position
    }

    const getDisabled = () => {
        if (accountState.new_nickname
            || accountState.new_phones
            || accountState.new_nip
            || accountState.new_password)
            return false
        else
            return true
    }

    return (
        <Box>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center">
                <Grid item>
                    <Typography
                        color="textSecondary"
                        component="h3">
                        {props.translate("Account Detail")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        onClick={handleOpenModal}>
                        {props.translate("Edit")}
                    </Button>
                </Grid>
            </Grid>
            <Card>
                <CardContent>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            {accountState.user && accountState.user.active ? (accountState.user.active ?
                                <ToggleOnIcon fontSize="large" color="primary" />
                                : <ToggleOffIcon fontSize="large" color="error" />)
                                : <ToggleOffIcon fontSize="large" color="disabled" />}
                        </Grid>
                        <Grid item xs>
                            {accountState.user && accountState.user.active ? (accountState.user.active ?
                                props.translate("Active")
                                : props.translate("Inactive"))
                                : props.translate("No Account")}
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <AssignmentIndIcon color="action" />
                                </Grid>
                                <Grid item xs>
                                    {accountState.user && accountState.user.attendantPositionId ? getPosition(accountState.user.attendantPositionId)
                                        : <Skeleton variant="text" />}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs>
                            {accountState.user && accountState.user.phones ? accountState.user.phones.map(p => {
                                return <Grid key={`${p.phoneType}-${p.phone}`} container spacing={1} alignItems="center">
                                    <Grid item>
                                        <PhoneAndroidIcon color="action" />
                                    </Grid>
                                    <Grid item xs>
                                        {p.phoneType ? props.translate(p.phoneType)
                                            : <Skeleton variant="text" />}
                                    </Grid>
                                    <Grid item xs>
                                        {p.phone ? p.phone
                                            : <Skeleton variant="text" />}
                                    </Grid>
                                </Grid>
                            }) : ''}
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <DialpadIcon color="action" />
                                </Grid>
                                <Grid item xs>
                                    {accountState.user && accountState.user.nipPassword ? accountState.user.nipPassword
                                        : <Skeleton variant="text" />}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <VpnKeyIcon color="action" />
                                </Grid>
                                <Grid item xs={11}>
                                    {accountState.user && accountState.user.hashPassword ? <Typography
                                        noWrap
                                        color="textSecondary">
                                        {accountState.user.hashPassword}
                                    </Typography>
                                        : <Skeleton variant="rect" />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Dialog fullScreen
                open={accountState.modalOpen}
                onClose={handleCloseModal}
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit}>
                    <AppBar
                        color="secondary"
                        className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseModal}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {props.translate("Update your Profile")}
                            </Typography>
                            <Button
                                type="submit"
                                disabled={getDisabled()}
                                autoFocus
                                color="inherit">
                                {props.translate("Save")}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Container fixed>
                        {accountState.user ?
                            <Grid container spacing={2} alignItems="center" mt={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        noWrap
                                        color="textSecondary">
                                        {props.translate("Account Status")}
                                    </Typography>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            {accountState.user && accountState.user.active ? (accountState.user.active ?
                                                <ToggleOnIcon fontSize="large" color="primary" />
                                                : <ToggleOffIcon fontSize="large" color="disabled" />)
                                                : <ToggleOffIcon fontSize="large" color="error" />}
                                        </Grid>
                                        <Grid item xs>
                                            {accountState.user && accountState.user.active ? (accountState.user.active ?
                                                props.translate("Active")
                                                : props.translate("Inactive"))
                                                : props.translate("No Account")}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {/*<TextField
                                        name="nickname"
                                        label={props.translate('Nick Name')}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        type="text"
                                        value={props.userSignIn && props.userSignIn.attributes ? props.userSignIn.attributes['custom:login_name'] : ''}
                                        onChange={(nickname) => {
                                            if (!props.userSignIn.attributes)
                                                props.userSignIn.attributes = {}
                                            props.userSignIn.attributes['custom:login_name'] = nickname.target.value
                                            setAccountState({
                                                ...accountState,
                                                new_nickname: nickname.target.value
                                            })
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AssignmentIndIcon fontSize="large" color="action" />
                                                </InputAdornment>
                                            ),
                                        }} />*/}
                                </Grid>
                                <Grid item xs={12}>
                                    {accountState.user && accountState.user.phones?
                                        <MaterialTable
                                            icons={tableIcons}
                                            localization={localization(props)}
                                            options={{
                                                search: false,
                                                exportButton: true
                                            }}
                                            title={props.translate("Phone Numbers")}
                                            columns={accountState.columns}
                                            data={accountState.user.phones}
                                            editable={{
                                                onRowAdd: newData =>
                                                    new Promise(resolve => {
                                                        // console.log("onRowAdd", newData)
                                                        setAccountState(prevState => {
                                                            prevState.user.phones = prevState.user.phones ? prevState.user.phones : []
                                                            const data = [...prevState.user.phones];
                                                            data.push({ phoneType: newData.phoneType, phone: newData.phone });
                                                            prevState.user.phones = data
                                                            prevState.new_phones = true
                                                            return { ...prevState };
                                                        })
                                                        resolve();
                                                    }),
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            if (oldData) {
                                                                console.log("onRowUpdate", newData, oldData)
                                                                setAccountState(prevState => {
                                                                    prevState.user.phones = prevState.user.phones ? prevState.user.phones : []
                                                                    const data = [...prevState.user.phones];
                                                                    data[data.indexOf(oldData)] = { phoneType: newData.phoneType, phone: newData.phone };
                                                                    prevState.user.phones = data
                                                                    prevState.new_phones = true
                                                                    return { ...prevState };
                                                                })
                                                            }
                                                        }, 600);
                                                    }),
                                                onRowDelete: oldData =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            // console.log("onRowDelete", oldData)
                                                            setAccountState(prevState => {
                                                                prevState.user.phones = prevState.user.phones ? prevState.user.phones : []
                                                                const data = [...prevState.user.phones];
                                                                data.splice(data.indexOf(oldData), 1);
                                                                prevState.user.phones = data
                                                                prevState.new_phones = true
                                                                return { ...prevState };
                                                            })
                                                        }, 600);
                                                    }),
                                            }}
                                        /> : null}
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        name="nip"
                                        label={props.translate('Nip Password')}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        type="number"
                                        value={props.user.nipPassword ? props.user.nipPassword : ""}
                                        onChange={(nip) => {
                                            props.user.nipPassword = nip.target.value
                                            setAccountState({
                                                ...accountState,
                                                new_nip: nip.target.value
                                            })
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <DialpadIcon fontSize="large" color="action" />
                                                </InputAdornment>
                                            )
                                        }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        noWrap
                                        color="textSecondary">
                                        {props.translate("Reset Password")}
                                    </Typography>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField
                                                name="password"
                                                label={props.translate('Put new Password')}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                type="password"
                                                defaultValue={accountState.user.hashPassword}
                                                onChange={(new_password) => {
                                                    setAccountState({
                                                        ...accountState,
                                                        change_password: new_password.target.value
                                                    })
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <VpnKeyIcon fontSize="large" color="action" />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                name="confirm_password"
                                                label={props.translate('Put new Password')}
                                                variant="outlined"
                                                error={!accountState.new_password && accountState.new_password !== accountState.change_password}
                                                fullWidth
                                                type="password"
                                                onChange={(new_password) => {
                                                    if (new_password.target.value.trim() === accountState.change_password.trim())
                                                        setAccountState({
                                                            ...accountState,
                                                            new_password: sha256.hex(new_password.target.value.trim())
                                                        })
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <VpnKeyIcon fontSize="large" color="action" />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null}
                    </Container>
                </form>
            </Dialog>
        </Box>
    )
}

export default Account;