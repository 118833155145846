import React, { useEffect, useRef, useState } from 'react'
// import PropTypes from 'prop-types'
import { Box, Button, Container, Divider, FormControl, Grid, InputLabel, LinearProgress, makeStyles, MenuItem, Paper, Select, TextField, Typography, Tooltip, InputAdornment } from '@material-ui/core';
import { api, apiPartial } from '../../api/api';
import { getWarehouseCustomer, listCustomerTypes , listOrganizations, listWarehouses, listRemoteCommands, listRemoteControls } from '../../graphql/queries';
import { AddCircle, Send } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { listCustomersAudit } from '../../graphql/bamaQueries';
import { Autocomplete } from '@material-ui/lab';
import { SNACKBAR_ERROR, SNACKBAR_INFO, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from '../../utils/SessionControl';
import ShowListKits from './ShowListKits';
import { withSnackbar } from 'notistack';
import { createRemoteCommand, createRemoteControl, updateRemoteCommand } from '../../graphql/mutations';
import moment from 'moment';
import AddCommand from './AddCommand';
import { IconButton } from '@material-ui/core';

const styles = makeStyles(theme => ({
    formControlHalf: {
        margin: theme.spacing(2),
        minWidth: '46%'
    },
    formControl3: {
        margin: theme.spacing(1),
        minWidth: '40%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttons: {
        marginTop: theme.spacing(2)
    }
}))

const Commands = (props) => {
    const firstrender = useRef(true);
    const classes = styles();

    /* handlers */
    const [orgLoader, setOrgLoader] = useState(false);
    const [sucLoader, setSucLoader] = useState(false);
    const [ctesLoader, setCtesLoader] = useState(false);
    const [addCommLoader, setAddCommLoader] = useState(false);
    const [tblLoaderData, setTblLoaderData] = useState(false);
    const [processComplete, setProcessComplete] = useState(false);

    /* data */
    const [empresas, setEmpresas] = useState([]);
    const [sucursales, SetSucursales] = useState([]);
    const [commands, setCommands] = useState([]);
    const [clients, setClients] = useState([]);
    const [historyComm, setHistoryComm] = useState([]);
    const [commandsDataModal, setCommandsDataModal] = useState({});
    // const [listWareHouseKits, setListWareHouseKits] = useState([]);

    /* modals */
    const [showCommandsDataModal, setShowCommandsDataModal] = useState(false);

    /* Estados */
    const [isNewCommand, setIsNewCommand] = useState(true);
    const [showListsComponents, setShowListsComponents] = useState({ clients: false, kits: false });

    /* selected */
    const [org, setOrg] = useState('');
    const [suc, setSuc] = useState('');
    const [sucId, setSucId] = useState('');
    const [command, setCommand] = useState('');
    const [clte, setClte] = useState('');
    const [kit, setKit] = useState('');
    //const [customerTypes, setcustomerTypes] = useState('');

    const customerTypes = { '4d8e7b38-de1d-47cf-9731-50fb249e0d87': 'CC', 'c32b3f5e-5de3-4954-96e2-a87a29d89fcd': 'CL','b952d4e7-a30a-4c02-84c8-ed2d91ca60e8': 'CJ','839c5a09-1a3f-4794-b3b1-3296b8ac8e42': 'CM','21ebd2b0-f840-4ad4-aa74-1d76f46fd54c': 'CB001'}
    const [customerTypeName, setCustomerTypeName] = useState('');

    const [dataCommandSend, setDataCommandSend] = useState({ complete: true });

    // const [dataKitSend, setDataKitSend] = useState({ complete: true });

    /* tbl LookUps */
    const [lookUpCommands, setLookUpCommands] = useState({});
    const [lookUpWareHouses, setLookUpWareHouses] = useState({});

    /* tabla */
    const columnas = [
        {
            title: 'Comando',
            field: 'remoteCommand.id',
            lookup: lookUpCommands
        },
        {
            title: 'sucursales',
            field: 'warehouseId',
            lookup: lookUpWareHouses
        },
        {
            title: 'Enviado',
            render: (o, i) => o.createdAt && moment(o.createdAt, 'YYYY-MM-DDTHH:mm:ss').add(-5, 'hours').format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Actualizado',
            render: (o, i) => o.updatedAt && moment(o.updatedAt, 'YYYY-MM-DDTHH:mm:ss').add(-5, 'hours').format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'estatus',
            render: (obj, name) => {
                let re = ''
                obj.remoteControlStatus.forEach((el, eId) => {
                    re += `${moment.unix(el.startDatetime).format('YYYY-MM-DD HH:mm:ss')} [${el.remoteControlStatusType}] \n`
                });
                return re
            }
        }
    ]

    const location = {
        header: {
            actions: props.translate('Actions')
        },
        pagination: {
            labelDisplayedRows: `{from}-{to} ${props.translate('of')} {count}`,
            labelRowsSelect: props.translate('Rows'),
            labelRowsPerPage: props.translate('Rows per page'),
            firstAriaLabel: props.translate('First Page'),
            firstTooltip: props.translate('First Page'),
            previousAriaLabel: props.translate('Previous Page'),
            previousTooltip: props.translate('Previous Page'),
            nextAriaLabel: props.translate('Next Page'),
            nextTooltip: props.translate('Next Page'),
            lastAriaLabel: props.translate('Last Page'),
            lastTooltip: props.translate('Last Page')
        }
    }

    useEffect(() => {
        if (firstrender.current) {
            firstrender.current = false;
            getLookUpWareHouses();
            getComandos();
            getCommandHist();
            getEmpresas();
            getCustomerTypes()
            return
        }
        const cleanup = () => {
            // listCommSubscribe.unsubscribe();
        }
        return cleanup();
    }, [clients]);

    useEffect(() => {
        getPartialClients(sucId, listCustomersAudit, {}, []).then(res => { }).catch(err => { }).finally(() => setCtesLoader(false));
    }, [commandsDataModal, sucId]);

    useEffect(() => {
    }, [ctesLoader]);

    const handleSelectOrganization = (id, name) => {
        getSucursales(id)
        setOrg(name)
    }

    const handleSelecSucursal = (el, obj, nombre) => {
        setSuc(`${obj.warehouseId} - ${obj.warehouseName}`);
        setSucId(obj.id);
        setClients([]);
    }

    const handleSelecCommand = comando => {
        console.log(comando)
        switch (comando.id) {
            case '7cd179ce-562b-4dec-a3ea-110a7d2fd7bf':
                /* mostrar lista de clientes */
                setProcessComplete(false);
                setShowListsComponents({ kits: false, clients: true });
                break;
            case '1998e0a0-1cfc-41c1-b897-d0df617fa02b':
                /* mostrar lista de kits */
                setProcessComplete(false);
                setShowListsComponents({ kits: true, clients: false })
                break
            default:
                setShowListsComponents({ kits: false, clients: false })
                setProcessComplete(true);
                break;
        }
        setCommand(comando.remoteCommandName);
        setCommandsDataModal(comando)
        setIsNewCommand(false);
        setCtesLoader(true);
    }

    const handleSelectClient = async ({ target }, newVal) => {
        newVal && console.log('newVal: ', newVal.customerType.id, newVal.customerType.customerTypeName, newVal);
        !newVal && setCustomerTypeName('');
        newVal && setCustomerTypeName(customerTypes[newVal.customerType.id])
        newVal && setClte(newVal.id);
        newVal && setProcessComplete(true);
    }

    const handleSelectKit = (newKit) => {
        console.log('handleSelectKit: ', newKit);
        setKit(newKit);
        setProcessComplete(true);
    }

    const handleSendCommand = async () => {
        setAddCommLoader(true);
        let comando = JSON.parse(commandsDataModal.command)
        // console.log(comando, kit)
        if (comando.data && comando.data.customerId != undefined) {
            comando.data.customerId = clte
        }
        if (comando.oper == 'addKit') {
            comando.data = kit
        }
        // if ( comando.oper == "updateUserMTCenter") {
        //      comando.data = {
        //         listserviceName: ['MTCenter-TAE-TCO'],
        //         password: 38247,
        //         property:"commercial_chain"
        //     }
        // }
        let newComm = {
            input: {
                remoteControlRemoteCommandId: commandsDataModal.id,
                warehouseId: sucId,
                params: JSON.stringify(comando),
                remoteControlStatus: { startDatetime: parseInt(new Date().getTime() / 1000), remoteControlStatusType: 'send' },
                // warehouseName: `${warehouseId} - ${warehouseName}`,
                // commandName: commandsDataModal.remoteCommandName
            }
        }
        console.log(newComm)
        await api(createRemoteControl, newComm).then(nComSaved => {
            // console.log('guardado!...', nComSaved);
            props.showMessage(`El comando ha sido enviado...`, SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            resetForm();
            getCommandHist();
        }).catch(err => {
            console.log('err al guardar comando: ', err)
            props.showMessage(`Comando no enviado!!!...`, SNACKBAR_ERROR, 10000, null, null, null, null, null, null, props);
        }).finally(() => setAddCommLoader(false))
        // console.log('Comando a enviar: ',newComm);
        setAddCommLoader(false)
    }

    const handleToggleCommandsDataModal = () => {
        setShowCommandsDataModal(!showCommandsDataModal);
    }

    const handleSaveCommandsDataModal = async commData => {
        // console.log('datos del modal: ', commData);
        if (isNewCommand) {
            await api(createRemoteCommand, { input: commData }).then(res => {
                // console.log('Comando Guardado: ', res);
                props.showMessage(`Guardado!`, SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            }).catch(err => {
                console.log('comando no guardado: ', err);
                props.showMessage(`Error al intentar agregar el registro...`, SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            })
        } else {
            commData.id = commandsDataModal.id;
            commData._version = commandsDataModal._version;
            await api(updateRemoteCommand, { input: commData }).then(res => {
                // console.log('comando actualizado: ', res);
                props.showMessage(`Actualizado!`, SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            }).catch(err => {
                console.log('comando no actualizado: ', err)
                props.showMessage(`Error al intentar actualizar el registro...`, SNACKBAR_ERROR, null, null, null, null, null, null, null, props);
            })
        }
        await getComandos();
        handleToggleCommandsDataModal();
    }

    const resetForm = () => {
        setDataCommandSend({ complete: false });
    }

    const getEmpresas = async () => {
        setOrgLoader(true);
        await api(listOrganizations, { limit: 300 }).then(org => {
            setEmpresas(org)
        }).catch(err => {
            console.log('load org. err: ', err)
        }).finally(() => setOrgLoader(false))
    }

    const getSucursales = async empId => {
        let sucursales = [];
        setSucLoader(true);
        await api(listWarehouses, { limit: 300 }).then(suc => {
            if (suc.length != undefined && suc.length != 0) {
                sucursales = suc.filter(sucursal => sucursal.organization.id == empId)
                SetSucursales(sucursales);
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => setSucLoader(false))
    }

    const getCustomerTypes = async () => {
        let customerTypes = [];
        await api(listCustomerTypes, { limit: 300 }).then(types => {
            types && types.forEach(_e => {
                customerTypes.push({[_e.id]:_e.customerTypeId})
            });
            console.log("types",...customerTypes)
            //setcustomerTypes(...customerTypes)
        }).catch(err => {
            console.log(err)
        }).finally(() => console.log(""))
    }

    const getLookUpWareHouses = async () => {
        let lookUpWareHousesObj = {}
        await api(listWarehouses, { limit: 300 }).then(wh => {
            wh && wh.forEach(el => {
                lookUpWareHousesObj[el.id] = el.warehouseName
            })
            setLookUpWareHouses(lookUpWareHousesObj);
        })
    }

    const getComandos = async () => {
        let lookupCommandsObj = {}
        await api(listRemoteCommands, { limit: 300 }).then(commands => {
            commands.forEach(cm => {
                lookupCommandsObj[cm.id] = cm.remoteCommandName
            })
            setLookUpCommands(lookupCommandsObj);
            setCommands(commands);
        }).catch(err => {
            console.log('err: ', err)
        })
    }

    // const listCommSubscribe = API.graphql(graphqlOperation(onCreateRemoteControl)).subscribe({
    //     next: ({cmd, value}) => {
    //         console.log('subscription event: ', cmd, value)
    //         getCommandHist();
    //     },
    //     error: error => console.warn('subscription err: ', error)
    // })

    const getCommandHist = async () => {
        setTblLoaderData(true)
        setHistoryComm([]);
        await api(listRemoteControls, { limit: 300 }).then(historic => {
            if (historic.length > 0) {
                // console.log('comandos enviados...', historic)
                setHistoryComm(historic);
            }
        }).catch(err => {
            console.log('historyCatch: ', err)
        }).finally(() => setTblLoaderData(false));
    }

    const getPartialClients = async (sudursalId, query, params, response) => {
        setCtesLoader(true);
        if (commandsDataModal.id == '7cd179ce-562b-4dec-a3ea-110a7d2fd7bf') {
            if (response.length == 0) {
                props.showMessage(`Actualizando lista de Clientes... por favor espere...`, SNACKBAR_WARNING, null, null, null, null, null, null, null, props);
            } else {
                props.showMessage(`Actualizando lista de Clientes: ${response.length}`, SNACKBAR_INFO, 1000, null, null, null, null, null, null, props);
            }
            await apiPartial(query, params).then(res => {
                res.items && res.items.forEach(cte => {
                    if (cte.customerType != null && cte.customerId != null && cte.customerId != 'blacklist') {
                        if (cte.customerType.id != '4d8e7b38-de1d-47cf-9731-50fb249e0d87') {
                            // console.log('buscando tipo de cliente: ', cte.customerType.id, cte.customerType.customerTypeName );
                            response.push(cte);
                        } else {
                            api(getWarehouseCustomer, { customerId: cte.id, warehouseId: sudursalId }).then(wareh => {
                                if (wareh != null) {
                                    // console.log('cte. Conviene: ', cte );
                                    response.push(cte);
                                }
                            }).catch(err => {
                                console.log('getWareH err.: ', err);
                            });
                        }
                    }
                })
                setClients(response);
                if (res.nextToken) {
                    return getPartialClients(sudursalId, query, { ...params, nextToken: res.nextToken }, response);
                } else {
                    props.showMessage('Lista de Clientes completada...', SNACKBAR_SUCCESS, 15000, null, null, null, null, null, null, props);
                }
            }).catch(err => {
                console.log('error en getPartCtes: ', err);
            });
            return response
        }
    }

    return (
        <>
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='row' m={1} minWidth={'99%'} >
                <Container>
                    <Grid container spacing={3} >
                        <Grid item xs={12} >
                            <Paper elevation={2} >
                                <Typography variant='h5' >Enviar Comando</Typography>
                                <Grid item>
                                    <FormControl component='fieldset' className={classes.formControlHalf} >
                                        <InputLabel id='labelEmpresas' >Seleccione Empresa...</InputLabel>
                                        <Select labelId='labelEmpresas' id='empresa' value={org} >
                                            {orgLoader ? <LinearProgress /> : null}
                                            {empresas && empresas.map((emp, key) => <MenuItem key={key} value={emp.organizationName} onClick={() => handleSelectOrganization(emp.id, emp.organizationName)} >{emp.organizationName}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <Autocomplete id='sucursales' options={sucursales} getOptionLabel={opt => `${opt.warehouseId} - ${opt.warehouseName}`} openOnFocus renderInput={prms => sucLoader && sucursales.length == 0 ? <LinearProgress /> : <TextField {...prms} label='Seleccione Sucursal...' variant='standard' />} disabled={org == ''} style={{ display: 'inline-block', width: '45%', marginTop: '1rem' }} onChange={handleSelecSucursal} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component='fieldset' className={classes.formControl3} disabled={suc == ''} >
                                        <InputLabel id='labelCommand' >Acciones...</InputLabel>
                                        <Select labelId='labelCommand' id='command' value={command} >
                                            {commands.map((comm, key) => <MenuItem key={key} value={comm.remoteCommandName} onClick={() => handleSelecCommand(comm)} >{comm.remoteCommandName}</MenuItem>)}
                                        </Select>
                                    </FormControl>

                                    <Tooltip title='agregar/Editar comando' arrow >
                                        <IconButton disabled={suc == ''} color='primary' component='span' aria-label='agregar/editar' onClick={handleToggleCommandsDataModal} >
                                            <AddCircle />
                                        </IconButton>
                                    </Tooltip>

                                    {/* El siguiente componente debería cambiar según el comando elegido y sus necesidades */}
                                    {showListsComponents.clients && <Autocomplete id='Clients' options={clients} getOptionLabel={option => customerTypes[option.customerType.id] + ' ' + option.customerName} openOnFocus renderInput={params => ctesLoader && clients == 0 ? <LinearProgress /> : <TextField {...params} label='Lista de clientes... Seleccione...' variant='standard' margin='none' /* InputProps={{ ...params.InputProps, startAdornment: (<InputAdornment position='start' >{ customerTypeName }</InputAdornment> ) }} */ />} disabled={command == ''} style={{ display: 'inline-block', width: '40%', marginTop: '.5rem' }} onChange={handleSelectClient} />}

                                    {showListsComponents.kits && <ShowListKits warehouseId={sucId} handlerChanges={handleSelectKit} showMessage={props.showMessage} btnState={setProcessComplete} />}

                                    <Button disabled={!processComplete} size='small' color='primary' variant='contained' endIcon={<Send />} style={{ marginLeft: '.5rem', marginTop: '.5rem' }} onClick={handleSendCommand} >enviar</Button>
                                </Grid>
                                <Grid item xs={12} >
                                    <Divider variant='fullWidth' />
                                </Grid>
                                <Grid item xs={12} >
                                    <MaterialTable title='Historial de comandos' columns={columnas} data={historyComm} isLoading={tblLoaderData} options={{ padding: 'dense' }} localization={location} />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <AddCommand open={showCommandsDataModal} onClose={handleToggleCommandsDataModal} onSave={handleSaveCommandsDataModal} data={commandsDataModal} translate={props.translate} />
        </>
    )
}

Commands.propTypes = {

}

export default withSnackbar(Commands)

