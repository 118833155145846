import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import "./styles.scss";
import Grid from '@material-ui/core/Grid';
import HCMaterialTable from '../HCMaterialTable';

import { API, graphqlOperation } from 'aws-amplify';
import {
  getHeadcountVacantLog
} from '../../graphql/queries';


const columns = [
  { title: 'Fecha Incidente', field: 'day' },
  {
    title: 'Fecha Creación', field: 'createdAt',
    render: rowData => {
      const date = new Date(rowData.createdAt);
      const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
      const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date)
      return <div>{`${year}-${month}-${day}`}</div>
    }
  },
  { title: 'Descripción', field: 'description' }
];

const columnsDetail = [
  { title: 'Contador', field: 'count' },
  { title: 'Nombre de la posición', field: 'positionName' },
  { title: 'Fecha', field: 'date' }
];

const HCTablePanel = ({ idVacLog, translate }) => {

  const [loading, setLoading] = useState(false);
  const [dataIncidents, setDataIncidentes] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);

  useEffect(() => {
    setLoading(true);
    API.graphql(graphqlOperation(getHeadcountVacantLog, { id: idVacLog }))
      .then(resp => {
        const { incidents } = resp.data.getHeadcountVacantLog;
        const { detail } = resp.data.getHeadcountVacantLog;
        if (incidents) { setDataIncidentes(incidents.items); }
        if (detail) { setDataDetail(detail); }
        setLoading(false);
      })
      .catch(err => {
        console.log(err)
        setLoading(false);
      });
  }, [idVacLog]);


  return (
    <Grid className="hc__panel" container spacing={6}>
      <Grid item xs={12} lg={6}>
        {
          loading ?
            <h1>Cargando</h1>
            :
            <HCMaterialTable
              title={"Incidentes"}
              columns={columns}
              data={dataIncidents}
              search={false}
              translate={translate}
            >
            </HCMaterialTable>
        }
      </Grid>
      <Grid item xs={12} lg={6}>
        {
          loading ?
            <h1>Cargando</h1>
            :
            <HCMaterialTable
              title={"Detalles"}
              columns={columnsDetail}
              data={dataDetail}
              search={false}
              translate={translate}
            >
            </HCMaterialTable>
        }
      </Grid>
    </Grid>
  )
};

HCTablePanel.propTypes = {
  idVacLog: PropTypes.string.isRequired,
};

HCTablePanel.defaultProps = {
  columns: [],
  rowData: []
};

export default HCTablePanel;