import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// import MapModal from './MapModal';
import StoreIcon from '@material-ui/icons/Store';
import { listWarehouses } from '../../../graphql/queries';
import { api, apiPartial } from "../../../api/api";
// import ValidationTextField from '../../ValidationTextField/ValidationTextField';


function StoreSelection(props) {
    // const [openMap, setOpenMap] = useState(true);
    const [stores, setStores] = useState(null);
    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            loadStores()
            firstRender.current = false  // it's no longer the first render
            return // skip the code below
        }
    }, [])

    // const handleCloseMap = () => {
    //     setOpenMap(false);
    // };

    // const loadStores = () => {
    //     if (!props.stores)
    //         props.API.graphql(props.graphqlOperation(listWarehouses, { limit: 100 }))
    //             .then(allTodos => {
    //                 let items = allTodos.data.listWarehouses.items
    //                 props.setStores(items)
    //                 setStores(items)
    //             })
    //     else
    //         setStores(props.stores)
    // }

    const loadStores = () => {
        if (!props.stores)
            api(listWarehouses, { limit: 300 })
                .then((res) => {
                    // console.log('stores: ',typeof Object.values(res), Object.values(res))
                    props.setStores(res)
                    setStores(res)
                })
                .catch((err) => {
                    console.log(err);
                });
        else
            setStores(props.stores)
    }

    return (<Box>
        {
            stores ?
                <Grid item xs={12}>
                    <Typography gutterBottom>
                        {props.translate("Select closest Store")}
                    </Typography>
                    <Autocomplete fullWidth id='combo-box-stores' options={stores} getOptionLabel={option => option.warehouseName}
                        renderInput={(params) => <TextField {...params} label={props.translate('Choose') + '...'} variant="outlined" />}
                        onChange={(evt, store) => {
                            props.setCustomer([{ ...props.customer, store }]);
                        }}
                    />
                </Grid> : ''
        }
        {

            props.customer.store ?
                <Grid container alignItems="center" justify="center" spacing={2}>
                    <Grid item>
                        <StoreIcon fontSize="large" color="action" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">
                            {'Nombre '}
                            {props.customer.store.warehouseName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {props.customer.store.address}
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="body2">
                            {props.translate("Go to your Store to finish your registration")}
                        </Typography>
                    </Grid> */}
                    <Grid item>
                        <Button
                            disabled={props.customer.store == undefined}
                            variant="contained"
                            color="primary"
                            onClick={() => props.setStoreSetted(true)}
                        >
                            {props.translate("Next")}
                        </Button>
                    </Grid>
                </Grid> : ''
        }
        {/* 
            <MapModal isStoreSelection showMessage={props.showMessage} bamaLogo={props.bamaLogo} translate={props.translate} customer={props.customer} setCustomer={props.setCustomer} title="Store Selection" open={openMap} stores={stores} handleClose={handleCloseMap} />
         */}
    </Box>)
}

export default StoreSelection;