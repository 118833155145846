import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { AddCircle, Receipt } from '@material-ui/icons';
import MaterialTable from 'material-table';
import reactHtmlPrse from 'react-html-parser';
import { api } from '../../api/api';
import { listWorkplanActivitys } from '../../graphql/queries';
import { SNACKBAR_ERROR, SNACKBAR_INFO } from '../../utils/SessionControl';

function ListActivSectionWorkPlan(props) {
    let { onOpen, onClose, section, translate, showMessage, tableLocation } = props;
    const firstRender = useRef(true);
    const [objResponses, setObjResponses] = useState({
        cod: 0,
        echo: true,
        msg: 'Mensaje por defecto...',
        desc: 'Configuración por defecto...',
        data: []
    })
    const [loadingListActivities, setLoadingListActivities] = useState(false);
    const [dataListActivities, setDataListActivities] = useState([]);

    const columnDataListActiv = [
        {
            field: 'activityName',
            title: translate('Activity Name')
        },
        {
            field: 'description',
            title: translate('Description'),
            render: el => {
                let desc = JSON.parse(el.description);
                return reactHtmlPrse(desc.DescrtiptionNuevaActividad);
            }
        },
        {
            field: 'createdAt',
            title: translate('Create date'),
            type: 'date'
        },
        {
            field: 'updatedAt',
            title: translate('Updated'),
            type: 'date'
        }
    ]

    const actionsActivitiSection = [
        {
            icon: 'edit',
            tooltip: translate('Edit'),
            onClick: (evt, row) => console.log('editar: ', row)
        },
        {
            icon: Receipt,
            tooltip: translate('View Datails'),
            onClick: (evt, row) => console.log('detalles: ', row)
        },
        {
            icon: AddCircle,
            tooltip: translate('Add Component'),
            onClick: (evt, row) => console.log('agregar componente: ', row)
        }
    ]

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        loadListActivities().then(res => {
            if (res.echo) {
                setDataListActivities(res.data)
            }
        })
        const cleanup = () => { }
        return cleanup;
    }, [section])

    const addActivity = () => {
        console.log('agregar actividad, seccion', section)
    }

    const loadListActivities = async () => {
        setLoadingListActivities(true);
        let response = { ...objResponses };
        await api(listWorkplanActivitys).then(res => {
            if (res.length != undefined && res.length != 0) {
                response.data = res.filter((itm, idItm) => itm.workplanSection.id == section.id);
            }
            if (response.data.length == 0) {
                showMessage('No se encontraron coincidencias...', SNACKBAR_INFO, null, null, null, null, null, null, null, props);
            }
        }).catch(err => {
            console.log('error al intentar obtener la lista de actividades: ', err);
            showMessage('error al intentar obtener la lista de actividades...', SNACKBAR_ERROR, null, null, null, null, null, null, null, props);
        }).finally(() => {
            setLoadingListActivities(false);
        })
        return response;
    }

    return (
        <>
            <Box display='flex' justifyContent='right' alignItems='right' flexDirection='row' m={1} minWidth={'99%'} >
                <Dialog open={onOpen} onClose={onClose} fullWidth={true} maxWidth={false} >
                    <DialogTitle>Lista de Actividades</DialogTitle>
                    <DialogContent>
                        <MaterialTable isLoading={loadingListActivities} data={dataListActivities} columns={columnDataListActiv} actions={actionsActivitiSection} options={{ actionsColumnIndex: -1 }} localization={tableLocation} />
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" color='primary' variant='contained' onClick={addActivity} >{translate('Add')}</Button>
                        <Button size="small" color='default' variant='contained' onClick={onClose} >{translate('Close')}</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}

ListActivSectionWorkPlan.propTypes = {
    onOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    tableLocation: PropTypes.object.isRequired
}

ListActivSectionWorkPlan.defaultProps = {
    section: {}
}

export default ListActivSectionWorkPlan

