import { takeLatest, all, call, put } from 'redux-saga/effects';
import { FETCH_AREAS } from './constants';
import { setAreas, setAreasError } from './actions';
import API from '../../services/api';

export function* fetchAreas(action) {
  const {
    payload: { id },
  } = action;
  try {
    if (id) {
      const response = yield call(API.getAreas, id);
      const {
        data: { node },
      } = response;
      yield put(setAreas(node));
    }
  } catch (error) {
    yield put(setAreasError(true));
  }
}

export default function* areasSaga() {
  yield all([takeLatest(FETCH_AREAS, fetchAreas)]);
}
