import { fork } from 'redux-saga/effects';
import modalselectorSaga from './modalSelector/sagas';
import organizationsSaga from './organizations/sagas';
import areasSaga from './areas/sagas';
import datasourcesReducer from './datasources/sagas';
import areaIndicatorsSaga from './areaIndicators/sagas';
import indicatorSaga from './indicator/sagas';
import kpiSaga from './kpi/sagas';
import variablesSaga from './variables/sagas';
import parametersSaga from './parameters/sagas';
import functionsSaga from './functions/sagas';
import employeesSaga from './employees/sagas';
import reportsSaga from './reports/sagas';
import createFunctionSaga from './createFunctions/sagas';
import EmployeeCatalogSaga from './employeeCatalog/sagas';

export default function* appSaga() {
  yield fork(modalselectorSaga);
  yield fork(organizationsSaga);
  yield fork(areasSaga);
  yield fork(datasourcesReducer);
  yield fork(areaIndicatorsSaga);
  yield fork(indicatorSaga);
  yield fork(kpiSaga);
  yield fork(variablesSaga);
  yield fork(parametersSaga);
  yield fork(functionsSaga);
  yield fork(employeesSaga);
  yield fork(reportsSaga);
  yield fork(createFunctionSaga);
  yield fork(EmployeeCatalogSaga);
}
