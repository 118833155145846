import React from 'react';
import Box from '@material-ui/core/Box';
import SessionControlSupportMultiLanguaje from '../../utils/SessionControl';

class Home extends SessionControlSupportMultiLanguaje {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.isUserSignedIn();
    }
    
    render() {
        return (
            <Box>
                Home
            </Box>
        );
    }
}

export default Home;