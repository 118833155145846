import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Paper,
  Typography,
  Grid,
  Divider,
  Button,
  LinearProgress,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Slide,
  CardActions
} from "@material-ui/core";
import SessionControl, { SNACKBAR_SUCCESS, SNACKBAR_WARNING, SNACKBAR_ERROR, SNACKBAR_INFO } from '../../../utils/SessionControl';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MaterialTable, { MTableToolbar } from "material-table";
import { tableIcons, localization } from '../../../components/materialTable/MaterialTable';

import { withSnackbar } from "notistack";
import { api, apiPartial } from "../../../api/api";
import { API, graphqlOperation } from 'aws-amplify';
import { getRemoteRequest, listRemoteRequests, listParams  } from "../../../graphql/queries";
import { createRemoteRequest, xadisws, sendEmail ,createParam , deleteParam } from "../../../graphql/mutations"
import { onUpdateRemoteRequest } from "../../../graphql/bamaQueries";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { Flag } from "@material-ui/icons";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperSearch: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperinfo: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(10),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function InfoXadis(props) {
  const classes = useStyles();
  const firstRender = useRef(true);
  const [intervalRemoteRequest, setIntervalRemoteRequest] = useState(null)

  const [Idsesion, setIdSesion] = useState(null)
  const [succesVentasCloud, setsuccesVentasCloud] = useState(false)
  const [succesInventariosCloud, setsuccesInventariosCloud] = useState(false)
  const [listParamsResponse, setlistParamsResponse] = useState(null)
  const [dataVentas, setdataVentas] = useState([])
  const [dataInventario, setdataInventario] = useState([])
  const [dataVentasCloud, setdataVentasCloud] = useState([])
  const [dataInventarioCloud, setdataInventarioCloud] = useState([])
  const [dataVentasSN, setdataVentasSN] = useState([])
  const [dataInventarioSN, setdataInventarioSN] = useState([])

  const [startDate, setStartDate] = useState(null);
  const [startDateCloud, setStartDateCloud] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusDate, setstatusDate] = useState(false)

  const [error, setError] = useState(false)
  const [ErrorDescription, setErrorDescription] = useState("")

  const [loadingVenta, setloadingVenta] = useState(false)
  const [loadingInventario, setloadingInventario] = useState(false)
  const [loadingSession, setloadingSession] = useState(true)
  const [loadingdataCloud, setloadingdataCloud] = useState(false)

  const [stateSession, setstateSession] = useState(true)
  const [endXadis, setendXadis] = useState(false)


  const [countVentas, setcountVentas] = useState(0)
  const [countInventarios, setcountInventarios] = useState(0)

  const [attempsVenta, setattempsVenta] = useState(0)
  const [attempsInventario, setattempsInventario] = useState(0)

  const [onclickEvent, setonclickEvent] = useState(false)
  const [onclickButton, setonclickButton] = useState(false)

  const [totaldataVentaBama, settotaldataVentaBama] = useState(0)
  const [totaldataInventarioBama, settotaldataInventarioBama] = useState(0)

  const [totaldataVentaGC, settotaldataVentaGC] = useState(0)
  const [totaldataInventarioGC, settotaldataInventarioGC] = useState(0)
  
  const [skuVenta, setskuVenta] = useState([])
  const [skuInventario, setskuInventario] = useState([])


  // const listEmail = [
  //   "raul.reyes@bamaglobal.com"]
  let listEmail = [
    "raul.reyes@bamaglobal.com",
    "david.fernandez@bamaglobal.com",
    "notificaciones@coronacoatza.com.mx",
    "suministro@bamaglobal.com",
    "tania.garcia@bamaglobal.com",
    "celia.perez@bamaglobal.com",
    "ynunez@bama.mx"
  ]
  const [stateVentas, setstateVentas] = useState(true)
  const [stateInvetarios, setstateInvetarios] = useState(true)

  const [stateVentasSN, setstateVentasSN] = useState(true)
  const [stateInvetariosSN, setstateInvetariosSN] = useState(true)
  const [stateVentasInventarios, setstateVentasInventarios] = useState(true)
  const [openDialog, setopenDialog] = useState(false)


  const paramsLogin = {
    user: "GOL_ALEJANDRA",
    password: "GOL_ALEJANDRA01",
    rfc: "NGO850425KU3",
    almacen: "100196091"
  }
  const service_config = { // "https://xadis.solutions/wap/wsXadisPMS_gmodelo/services/integracionXadisPMS/?wsdl",// 
    url: "https://xadis.solutions/wap/wsXadisPMS_gmodelo/services/integracionXadisPMS/?wsdl",///"https://xadis.solutions/wap/wsXadisPMS/services/wspms",
    headers: {
      "Content-Type": "text/xml;charset=UTF-8"
    }
  }
  //https://xadis.solutions/wap/wsXadisPMS/services/wspms?wsdl

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false; // it's no longer the first render

      startsesion()

      return; // skip the code below
    }

    if (Idsesion !== null) {
      setloadingSession(false)
      setstateSession(false)
    }


    if (countVentas === dataVentas.length && dataVentas.length > 0) {
      if(onclickButton){
        if(stateVentasInventarios){
          setstateVentasInventarios(false)
          handleClickInventario();
          console.log("handleClickInventario()")
        }
      }else{
        console.log("se han enviado tus ventas")
      }
    }
 
    
    if (dataVentasSN.length > 0 ) {
      if (stateVentasSN){
        setstateVentasSN(false)
        setTimeout(() => {
          setopenDialog(true)
          ///** EJECUTAR EMAIL */
          promiseCallWS("logout", {
            sesion: Idsesion,
            almacen: paramsLogin.almacen,
          }).then((success) => {
            
            deleteParamxadisInventorySales()
            //saveParamxadisInventorySales(dataVentas, false, dataInventario, false)
            handleMessageError()
          })
        }, 60000);
        console.log("loadXADISWS_Venta()")
      }
    }

    if (dataInventarioSN.length > 0 ) {
      if (stateInvetariosSN) {
        setstateInvetariosSN(false)

        setTimeout(() => {
          setopenDialog(true)
          ///** EJECUTAR EMAIL */
          promiseCallWS("logout", {
            sesion: Idsesion,
            almacen: paramsLogin.almacen,
          }).then((success) => {
      
            deleteParamxadisInventorySales()
            //saveParamxadisInventorySales(dataVentas, false, dataInventario, false)
            handleMessageError()

          })
        }, 60000);
        console.log("loadXADISWS_Inventario()")
      }
    }

    if (dataVentas.length > 0 && dataInventario.length > 0 && (parseInt(dataVentasSN.length) + parseInt(dataVentas.length)) === countVentas && (parseInt(dataInventarioSN.length) + parseInt(dataInventario.length)) === countInventarios && totaldataVentaBama > 0 && totaldataInventarioBama > 0 && totaldataVentaGC > 0 && totaldataInventarioGC > 0  ) {
      endsesion();
      console.log("end session" ,totaldataInventarioGC ,totaldataVentaGC ,skuVenta , skuInventario)
      sendMailHTML(`<html>
      <body class="m-4" 
      style="margin: 1rem!important; font-family: Tahoma, sans-serif; font-size: 11pt;">
          <div style="max-width: 508px;">
              <div>
                  <div class="d-flex align-items-center text-center" style="display: flex;">
                      <div style="width: 25%; display: block;">
                          <img src="https://lh3.googleusercontent.com/6_CBvW3p1ZskilvBxXqfJWPufxSVnqSrcMYKP7bAub7jm_TJ4Tk6MlKSyyYpjhik=w300"  height="100"/>
                      </div>
                      <div class="w-100 d-flex-column align-items-center justify-content-center text-center"
                          style="width: 80%; display: block;">
                          <!-- <h3 class="text-center" style="margin: 0;">Notificación de envio de información XADIS</h3> --> 


                       <div style="width: 40%; float:left"> 
          <div style="width: 25%; display: block; margin: 20px;">
            <img src="https://cdn-icons-png.flaticon.com/512/679/679720.png"  height="50"/>
          </div>
          <p class="text-center" style="margin-left: 20px; font-size: 9pt;">¡ VENTAS ENVIADAS <b> ${totaldataVentaGC} </b> !</p>
          <br>
          </div> 
         <div style="width: 40%; float:right"> 
         <div style="width: 25%; display: block; margin: 20px;">
            <img src="https://cdn-icons-png.flaticon.com/512/679/679720.png"  height="50"/>
          </div>
          <p class="text-center" style="margin-left: 20px; font-size: 9pt;">¡ INVENTARIOS ENVIADOS <b> ${totaldataInventarioGC} </b> !</p>
          <br>
          </div>
        
                    </div>
                  </div>
      
       <div class="d-flex align-items-center text-center" style="display: flex;">
                      <div style="width: 40%; display: block;">
                      </div>
                      <div class="w-100 d-flex-column align-items-center justify-content-center text-center"
                          style="width: 100%; display: block;">
          <p class="text-center" style="margin: 20px;">¡SE HA ENVIADO LA INFORMACION XADIS DEL <b>${[
          padLeadingZeros(new Date(startDate).getDate(), 2),
          padLeadingZeros(new Date(startDate).getMonth() + 1, 2),
          new Date(startDate).getFullYear(),
        ].join("/")}</b> !</p>
        <br>
        ${skuVenta && skuVenta.length > 0 || skuInventario && skuInventario.length > 0 ? "<b> Existe una diferencia en el envio de informacion entra a tu portal para obtener detalle del envio </b> " + " <a href='https://bamaglobal.com.mx/prometheus/xadis/infoXadis' > bamaCloud </a> ": "" }

        </div>
                  </div>
              </div>
          </div>
        </body>
      </html>`);
    }

    return function cleanup() { };

  }, [Idsesion,dataVentasSN,dataVentas,dataInventario,dataInventarioSN,totaldataInventarioBama,totaldataVentaBama,totaldataInventarioGC ,totaldataVentaGC]); // Only re-run the effect if name or email change


  const startsesion = () => {
    promiseCallWS("login", paramsLogin).then((success) => {
      setIdSesion(success.response)
      console.log("login",success.response)
    }).catch((err) => {
      console.log(err)
      setError(true)
      setErrorDescription("Hubo un error al intentar comunicarse con el servicios de grupo modelo")
      sendMailHTML(
        "ERROR DE COMUNICACION CON EL SERVICIO " + service_config.url + "AL ENVIAR LA INFORMACION DEL  " +
        [
          padLeadingZeros(new Date(startDate).getDate(), 2),
          padLeadingZeros(new Date(startDate).getMonth() + 1, 2),
          new Date(startDate).getFullYear(),
        ].join("/") +
        " AL " +
        [
          padLeadingZeros(new Date(endDate).getDate(), 2),
          padLeadingZeros(new Date(endDate).getMonth() + 1, 2),
          new Date(endDate).getFullYear(),
        ].join("/") +
        " !!!!!!!!!"
      );
    })
  }

  const loadSpVentas = (startDate, endDate, status) => {
    setloadingVenta(true)
    setstatusDate(true)

    var params = {
      input: {
        request: `{ \"query\": \"exec spXadisVentas '${startDate}','${endDate}'\", \"server\": \"SFORTIA\", \"database\": \"NICANOR_ERP\" }`,
        startDate: parseInt(new Date().getTime() / 1000),
        sync: false,
        source: "fortia_erp"
      }
    }
    console.log(params)
    api(createRemoteRequest, params)
      .then((res) => {
        let countInterval = 0
        var _irr = setInterval(() => {
          api(getRemoteRequest, { id: res.id }).then(_res => {
            //console.log(_res)
            let response = JSON.parse(_res.response);
            var arrayVentas = [] , iterate = 0
            //console.log(response)
            console.log(countInterval)
            if (_res && _res.endDate) {
              //loadXADISWS_Venta([response[0],response[1]])
              // loadXADISWS_Venta(response,status)                     
              // clearInterval(_irr)
              // setIntervalRemoteRequest(null)
              // console.log(response)
              arrayVentas = [...response]
              clearInterval(_irr)
              setIntervalRemoteRequest(null)

              //setTimeout(() => {
                api(listRemoteRequests, {
                  filter: {
                    status: {
                      eq: res.id,
                    },
                  },
                }).then((_res) => {
                  //console.log(_res);
                  _res.forEach(_e => {
                    let response = JSON.parse(_e.response);
                    //console.log(response);
                    arrayVentas = [...arrayVentas, ...response]
                  });

                  //console.log(arrayVentas);
                  if(iterate === 0 && arrayVentas.length && arrayVentas.length > 0){
                    loadXADISWS_Venta(arrayVentas, status)
                    setloadingVenta(false)
                    iterate = iterate + 1
                  }else{
                    handleClickInventario();
                    console.log("handleClickInventario()")
                  }
                });
              //}, 3000);
            }
            countInterval = countInterval + 1
          }).catch(err => console.log(err))
        }, 1000)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSpInventarios = (startDate, endDate) => {
    setstatusDate(true)
    setloadingInventario(true)
    var params = {
      input: {
        request: `{ \"query\": \"exec spXadisInventario '${startDate}','${endDate}'\", \"server\": \"SFORTIA\", \"database\": \"NICANOR_ERP\" }`,
        startDate: parseInt(new Date().getTime() / 1000),
        sync: false,
        source: "fortia_erp"
      }
    }
    api(createRemoteRequest, params)
      .then((res) => {
        var _irr = setInterval(() => {
          api(getRemoteRequest, { id: res.id }).then(_res => {
            let response = JSON.parse(_res.response);
            var arrayInventario = [] , iterate = 0
            if (_res && _res.endDate) {

              //loadXADISWS_Inventario([response[0],response[1]])
              // loadXADISWS_Inventario(response)
              arrayInventario = [...response]
              clearInterval(_irr)
              setIntervalRemoteRequest(null)
              // console.log(response)

              //setTimeout(() => {
                api(listRemoteRequests, {
                  filter: {
                    status: {
                      eq: res.id,
                    },
                  },
                }).then((_res) => {
                  //console.log(_res);
                  _res.forEach(_e => {
                    let response = JSON.parse(_e.response);
                    //console.log(response);
                    arrayInventario = [...arrayInventario, ...response]
                  });

                  console.log(arrayInventario);
                  if(iterate === 0 && arrayInventario.length && arrayInventario.length > 0){
                    loadXADISWS_Inventario(arrayInventario)
                    setloadingInventario(false)
                    iterate = iterate + 1
                  }
                });
              //}, 3000);

            }
          })
        }, 1000)
        // subscribeRemoteRequests(res.id)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadXADISWS_Venta = async (list, status) => {
    if (stateVentas) {
      setstateVentas(false)
      console.log("SE EJECUTO VENTA", list);
      let listData = await sliceListData(await refactoryDataSale(list), 500)
      //setcountVentas(list.length)
      setcountVentas(listData.length)
      listData.forEach(_sale => {
        let params = {
          sesion: Idsesion,
          almacen: paramsLogin.almacen,
          cadenaJSON: _sale
        }
        //console.log(params)
        //console.log(JSON.stringify(params) )
      });
      let dataFull = await Promise.all(listData.map(async (_sale, i) => {
        try {
          const response = await promiseCallWS("ventaJson", {
            sesion: Idsesion,
            almacen: paramsLogin.almacen,
            cadenaJSON: _sale
          })

          if (response && response.send && response.status) {
            setdataVentas(prev => {
              return [...prev, response]
            })
          } else if (response && !response.send && !response.status) {
            setdataVentasSN(prev => {
              return [...prev, { ..._sale, index: i }]
            })
          }
          return response
        } catch (error) {
          console.log(error);
          console.log(i);
          return { index: i, send: false, ..._sale }
        }
      })
      )

      console.log(dataFull)
      let listSKUVenta = []
      dataFull.forEach(_sale => {
        if (_sale && _sale.response && _sale.response.infoSku) {
          listSKUVenta = [...listSKUVenta, ...[..._sale.response.infoSku]]
        }
      });

      settotaldataVentaBama(dataFull.reduce((result, sales) => (result + sales.cadenaJSON.data.length), 0))
      settotaldataVentaGC(dataFull.reduce((result, sales) => (result + sales.response.insert), 0))
      setskuVenta(removeDupliactes(listSKUVenta))

      let listErrors = dataFull.filter(r => Boolean(!r.send))
      if (listErrors.length > 0) {
        console.log("ERROR")
        //deleteParamxadisInventorySales()
        //saveParamxadisInventorySales(dataFull, false, [], false)
        createParamxadisSales(dataFull,true)
        handleMessageError()
      }
    }
  }

  const loadXADISWS_Inventario = async (list) => {
    if (stateInvetarios) {
      setstateInvetarios(false);
      console.log("SE EJECUTO INVENTARIO");
      let listData = await sliceListData(await refactoryDataInventory(list), 500)
      setcountInventarios(listData.length)
      listData.forEach(_inventory => {
        let params = {
          sesion: Idsesion,
          almacen: paramsLogin.almacen,
          cadenaJSON: _inventory
        }
        console.log(params)
      });

      let dataFull = await Promise.all(listData.map(async (_inventory, i) => {
        try {
          const response = await promiseCallWS("inventarioJson", {
            sesion: Idsesion,
            almacen: paramsLogin.almacen,
            cadenaJSON: _inventory
          })
          if (response && response.send && response.status) {
            setdataInventario(prev => {
              return [...prev, response]
            })
          } else if (response && !response.send && !response.status) {
            setdataInventarioSN(prev => {
              return [...prev, { ..._inventory, index: i }]
            })
          }
          return  response
        } catch (error) {
          console.log(error);
          console.log(i);
          return { index: i, send: false, ..._inventory }
        }
      })
      )
    
      console.log(dataFull)
      let listSKUInventario = []
      dataFull.forEach(_inventory => {
        if(_inventory && _inventory.response && _inventory.response.infoSku ){
          listSKUInventario = [...listSKUInventario, ...[..._inventory.response.infoSku]]
        }
      });

      settotaldataInventarioBama(dataFull.reduce((result, inventory ) => ( result + inventory.cadenaJSON.data.length ), 0))
      settotaldataInventarioGC(dataFull.reduce((result, inventory ) => ( result + inventory.response.insert ), 0))
      setskuInventario(removeDupliactes(listSKUInventario))    
    }
  }

  const loadXADISWS_Cloud = async (list) => {
    console.log("SE EJECUTO VENTA", list);
    setcountVentas(list.length)
    let dataFull = await Promise.all(list.map(async (_sale, i) => {
      try {
        const response = await promiseCallWS("ventaJson", {
          sesion: Idsesion,
          almacen: paramsLogin.almacen,
          cadenaJSON: _sale.cadenaJSON
        })

        if (response && response.send && response.status) {
          setdataVentas(prev => {
            return [...prev, response]
          })
        } else if (response && !response.send && !response.status) {
          setdataVentasSN(prev => {
            return [...prev, { ..._sale, index: i }]
          })
        }
        return response
      } catch (error) {
        console.log(error);
        console.log(i);
        return { index: i, send: false, ..._sale }
      }
    })
    )

    console.log(dataFull)
    let listSKUVenta = []
    dataFull.forEach(_sale => {
      if (_sale && _sale.response && _sale.response.infoSku) {
        listSKUVenta = [...listSKUVenta, ...[..._sale.response.infoSku]]
      }
    });

    settotaldataVentaBama(dataFull.reduce((result, sales) => (result + sales.cadenaJSON.data.length), 0))
    settotaldataVentaGC(dataFull.reduce((result, sales) => (result + sales.response.insert), 0))
    setskuVenta(removeDupliactes(listSKUVenta))

    let listErrors = dataFull.filter(r => Boolean(!r.send))
    if (listErrors.length > 0) {
      console.log("ERROR")
      //deleteParamxadisInventorySales()
      //saveParamxadisInventorySales(dataFull, false, [], false)
      createParamxadisSales(dataFull, true)
      handleMessageError()
    } else {
      if (dataInventario.length <= 0 && dataInventarioCloud.length <= 0) {
        handleClickInventario();
        console.log("handleClickInventario()")
      } else {
        endsesion();
        console.log("end session")
      }
    }
  }

  const loadXADISWS_CloudInventory = async (list) => {
    setstateInvetarios(false);
    console.log("SE EJECUTO INVENTARIO");
    let dataFull = await Promise.all(list.map(async (_inventory, i) => {
      try {
        const response = await promiseCallWS("inventarioJson", {
          sesion: Idsesion,
          almacen: paramsLogin.almacen,
          cadenaJSON: _inventory.cadenaJSON
        })
        if (response && response.send && response.status) {
          setdataInventario(prev => {
            return [...prev, response]
          })
        } else if (response && !response.send && !response.status) {
          setdataInventarioSN(prev => {
            return [...prev, { ..._inventory, index: i }]
          })
        }
        return response
      } catch (error) {
        console.log(error);
        console.log(i);
        return { index: i, send: false, ..._inventory }
      }
    })
    )

    console.log(dataFull)
    let listSKUInventario = []
    dataFull.forEach(_inventory => {
      if (_inventory && _inventory.response && _inventory.response.infoSku) {
        listSKUInventario = [...listSKUInventario, ...[..._inventory.response.infoSku]]
      }
    });

    settotaldataInventarioBama(dataFull.reduce((result, inventory) => (result + inventory.cadenaJSON.data.length), 0))
    settotaldataInventarioGC(dataFull.reduce((result, inventory) => (result + inventory.response.insert), 0))
    setskuInventario(removeDupliactes(listSKUInventario))
  }

   const handleClickCloud = () => {
     //loadXADISWS_Cloud(dataVentasCloud, dataInventarioCloud)
     dataVentasCloud && dataVentasCloud.length && loadXADISWS_Cloud(dataVentasCloud) && setonclickButton(false)
     dataInventarioCloud && dataInventarioCloud.length && loadXADISWS_CloudInventory(dataInventarioCloud) && setonclickButton(false)
  };


  const requestInfoXadisInventariosCloud = () => {
    let InitialAccountingCut = new Date(startDate)
    InitialAccountingCut.setHours(6)
    InitialAccountingCut.setMinutes(0)
    InitialAccountingCut.setSeconds(0)
    console.log("startDateCloud ::: => ", parseInt(InitialAccountingCut.getTime() / 1000))
    setStartDateCloud(parseInt(InitialAccountingCut.getTime() / 1000))

    api(listParams, {
      filter: {
        startDate: {
          eq: parseInt(InitialAccountingCut.getTime() / 1000),
        }
        , paramType:
        {
          eq: "cloud"
        }, param:
        {
          eq: "xadisInventorySales"
        }
      }, limit: 1000
    }).then((res) => {

      let listVentas = []
      let listInventarios = []
      let rowVentas = res.filter(r => JSON.parse(r.value).type === 'venta')
      let statusSendVenta = false
      let rowInventarios = res.filter(r => JSON.parse(r.value).type === 'inventario')
      let statusSendInventario = false
      let listSKUVenta = []
      let listSuccessVenta = []
      let listErrorsVenta = []
      let listSKUInventario = []
      let listSuccessInventario = []
      let listErrorsInventario = []
      setlistParamsResponse(res)

      rowVentas && rowVentas.length && rowVentas.forEach(_e => {
        if (_e._deleted == null) {
          statusSendVenta = JSON.parse(_e.value).succesSend
          //console.log(JSON.parse(_e.keyFilter))

          listVentas = [...listVentas, ...[JSON.parse(_e.keyFilter)]]
          listErrorsVenta = listVentas.filter(r => Boolean(!r.send))
          listSuccessVenta = listVentas.filter(r => Boolean(r.send))
        }
      });

      rowInventarios && rowInventarios.length && rowInventarios.forEach(_e => {
        if (_e._deleted == null) {
          statusSendInventario = JSON.parse(_e.value).succesSend
          //console.log(JSON.parse(_e.keyFilter))

          listInventarios = [...listInventarios, ...[JSON.parse(_e.keyFilter)]]
          listErrorsInventario = listInventarios.filter(r => Boolean(!r.send))
          listSuccessInventario = listInventarios.filter(r => Boolean(r.send))
        }
      });

      listVentas.forEach(_sale => {
        if(_sale && _sale.response && _sale.response.infoSku ){
          listSKUVenta = [...listSKUVenta, ...[..._sale.response.infoSku]]
        }
      });

      listInventarios.forEach(_inventory => {
        if(_inventory && _inventory.response && _inventory.response.infoSku ){
          listSKUInventario = [...listSKUInventario, ...[..._inventory.response.infoSku]]
        }
      });

      setsuccesVentasCloud(statusSendVenta)
      setsuccesInventariosCloud(statusSendInventario)
      settotaldataVentaBama(listVentas.reduce((result, sales ) => ( result + sales.cadenaJSON.data.length ), 0))
      settotaldataInventarioBama(listInventarios.reduce((result, inventory ) => ( result + inventory.cadenaJSON.data.length ), 0))
      settotaldataVentaGC(listVentas.reduce((result, sales ) => ( result + sales.response.insert ), 0))
      settotaldataInventarioGC(listInventarios.reduce((result, inventory ) => ( result + inventory.response.insert ), 0))
      setskuVenta(removeDupliactes(listSKUVenta))
      setskuInventario(removeDupliactes(listSKUInventario))


      if(!statusSendVenta){
        setdataVentasCloud(listErrorsVenta)
      }

      if(!statusSendInventario){
        setdataInventarioCloud(listErrorsInventario)
      }

      setloadingdataCloud(false)
      if(statusSendVenta  &&  statusSendInventario){
        setdataVentas(listVentas)
        setdataInventario(listInventarios)
      }

      console.log("listSKUVenta ::: =>", removeDupliactes(listSKUVenta))
      console.log("listSKUInventario ::: =>", removeDupliactes(listSKUInventario))
      console.log("Ventas listErrors ::: =>", listErrorsVenta)
      // console.log("Ventas listSuccess ::: =>", listSuccessVenta)
      console.log("Inventarios listErrors ::: =>", listErrorsInventario)
      // console.log("Inventarios listSuccess ::: =>", listSuccessInventario)

      console.log("Ventas list ::: =>", listVentas)
      console.log("Inventarios list ::: =>", listInventarios)
      console.log("Ventas info ::: =>", statusSendVenta)
      console.log("Inventarios info ::: =>", statusSendInventario)

    }).catch((err) => {
      console.log(err);
    });
  }

  const promiseCallWS = (method, paramws) => new Promise(function (resolve, reject) {
    let param = {
      service_config: {
        ...service_config,
        method: method,
      },
      [method]: paramws,
    };
    api(xadisws, { params: JSON.stringify(param) })
      .then((allTodos) => {
        let response = JSON.parse(allTodos);
        let data_response = response.mensaje._text
        let json_data = {
          infoSku:null,
          insert:0,
          valid:0,
          different:0,
        }

        if (data_response.match(/(?=errorItems)([^*]+})/gm)) {  
          //** CONVERT DE TIPO JSON  */
          try {

            try {
              let pairCountSend = response.mensaje._text.match(/(?=msg)([^*]+})/gm);
              pairCountSend = pairCountSend[0].split(',') //.substr(pairCountSend[0].length - 3, pairCountSend[0].length) //.match(/(msg=Registros validados):([^,]+)/gm);
              pairCountSend.forEach(_v => {
                if(_v.match(/(validados)/gm)){
                 //console.log(_v.replace(/[^\d]/g, "")) 
                 json_data.valid = parseInt(_v.replace(/[^\d]/g, ""))
                }
              });              
            } catch (error) {
            }

            try {
              let pairCountInsert = response.mensaje._text.match(/(?=insert)([^*]+})/gm);
              pairCountInsert = pairCountInsert[0].split(',') //.substr(pairCountInsert[0].length - 3, pairCountInsert[0].length)  // .match(/(msg=insertados):([^,]+)/gm);
              pairCountInsert.forEach(_v => {
                if(_v.match(/(insertados)/gm)){
                 //console.log(_v.replace(/[^\d]/g, "")) 
                 json_data.insert = parseInt(_v.replace(/[^\d]/g, ""))
                }
              }); 
              //console.log(pairCountInsert)
            } catch (error) {
            }


            try {
              let pairStringsError = response.mensaje._text.match(/(?=errorItems)([^*]+})/gm);
              let errorItems = pairStringsError[0].substr(0, pairStringsError[0].length - 1)
              errorItems = errorItems.replace(/^\w+[=]/gm, '')
              errorItems = errorItems.replace(/[=]/gm, ':')
              errorItems = errorItems.replace(/\bsku/gm, '"sku"')
              errorItems = errorItems.replace(/\msg/gm, '"msg"')
              errorItems = errorItems.replace(/\No existe SKU en el catalogo/gm, '"No existe SKU en el catalogo"')
              errorItems = JSON.parse(errorItems)
              json_data.infoSku = removeDupliactes(errorItems)
              json_data.different =  parseInt(json_data.valid) - parseInt(json_data.insert)

              //console.log(json_data)
            } catch (error) {
            }
       
          } catch (error) {
            console.log(error)
          }

    
        }

        if (Boolean(response.error._text)) {
          //console.log(JSON.stringify(response.mensaje._text).split(','))
          //console.log(json_data)
          resolve({
            ...paramws,
            send: true,
            status: response.error._text,
            response: json_data && json_data.insert > 0 ? json_data: response.mensaje._text
          });
        } else {
          //console.log(json_data)
          reject({
            ...paramws,
            send: false,
            status: response.error._text,
            response: json_data && json_data.insert > 0 ? json_data: response.mensaje._text
          });
        }
      })
      .catch((err) => {
        let error = {
          ...err,
          send: false
        }
        reject(error);
      });
  });

  const endsesion = () => {
    promiseCallWS("logout", {
      sesion: Idsesion,
      almacen: paramsLogin.almacen,
    }).then((success) => {

      setstateSession(true)
      
    })
  };

  //** ACTIONS BUTTONS */
  //*******************/

  //** SELECCION DE FECHAS */
  const handleDateChangeStart = (date) => {
    setStartDate(date);
    setError(false)

    setdataVentas([])
    setdataVentasSN([])

    setdataInventario([])
    setdataInventarioSN([])

    setdataInventarioCloud([])
    setsuccesInventariosCloud(false)

    setdataVentasCloud([])
    setsuccesVentasCloud(false)

  }


  const handleDateChangeEnd = (date) => {
    setEndDate(date);
    setError(false)

    setdataVentas([])
    setdataVentasSN([])

    setdataInventario([])
    setdataInventarioSN([])

    setdataInventarioCloud([])
    setsuccesInventariosCloud(false)

    setdataVentasCloud([])
    setsuccesVentasCloud(false)

    // IMPLEMENTACION PARA RECUPERAR INFORMACION DYNAMO
    setloadingdataCloud(true)
    requestInfoXadisInventariosCloud()
  };
  const handleClickVentas = () => {
    callSPVentas(false)
  };

  const callSPVentas = (status) => {
    console.log(status)
    if (startDate !== null && endDate !== null) {

      setonclickEvent(true)

      loadSpVentas([
        padLeadingZeros(new Date(startDate).getDate(), 2),
        padLeadingZeros(new Date(startDate).getMonth() + 1, 2),
        new Date(startDate).getFullYear(),
      ].join("/"), [
        padLeadingZeros(new Date(endDate).getDate(), 2),
        padLeadingZeros(new Date(endDate).getMonth() + 1, 2),
        new Date(endDate).getFullYear(),
      ].join("/"), status)
    } else {
      /** ERROR RANGO DE FECHAS */
      setError(true)
      setErrorDescription(props.translate("date selection"))

    }
  }


  const handleClickInventario = () => {
    if (startDate !== null && endDate !== null) {

      setonclickEvent(true)

      loadSpInventarios(
        [
          padLeadingZeros(new Date(startDate).getDate(), 2),
          padLeadingZeros(new Date(startDate).getMonth() + 1, 2),
          new Date(startDate).getFullYear(),
        ].join("/"),
        [
          padLeadingZeros(new Date(endDate).getDate(), 2),
          padLeadingZeros(new Date(endDate).getMonth() + 1, 2),
          new Date(endDate).getFullYear(),
        ].join("/")
      );
    } else {
      /** ERROR RANGO DE FECHAS */
      setError(true);
      setErrorDescription(props.translate("date selection"));
    }

  };

  const handleClickVentaInventario = () => {

    setonclickButton(true)
    callSPVentas(true)

  };

  const sendMailHTML = (html) => {
    // console.log("SE HA ENVIADO EMAIL")
    listEmail.forEach(email => {
      var param_email = {
        sender: "notificaciones@bamaglobal.com.mx",
        recipient: email,
        subject: dataInventarioSN.length <= 0 && dataVentasSN.length <= 0 && dataInventario.length > 0 && dataVentas.length > 0? "Notificiación de envio de informacion XADIS":"Error de envio de informacion XADIS",
        title: dataInventarioSN.length <= 0 && dataVentasSN.length <= 0 && dataInventario.length > 0 && dataVentas.length > 0? "Notificiación de envio de informacion XADIS":"Error de envio de informacion XADIS",
        content: html,
      };
      //console.log(param_email);
      API.graphql(
        graphqlOperation(sendEmail, { email: JSON.stringify(param_email) })
      ).then((allTodos) => {
        console.log(allTodos);
      });
    })

    if(dataInventarioSN.length <= 0 && dataVentasSN.length <= 0 && dataInventario.length > 0 && dataVentas.length > 0){
      props.showMessage(` ${"Se ha enviado la informacion."}`, SNACKBAR_SUCCESS, 5000, null, null, null, null, null, " ", props);
      setendXadis(true)
      //saveParamxadisInventorySales( dataVentas , true , dataInventario , true )
      console.log("dataVentas ===> :::",dataVentas)
      createParamxadisSales(dataVentas,true)
      createParamxadisInventory(dataInventario,true)

      setsuccesVentasCloud(true)
      setsuccesInventariosCloud(true)
      deleteParamxadisInventorySales()
      
    }
  };

  function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  const removeDupliactes = (values) => {
    let concatArray = values.map(eachValue => {
      return Object.values(eachValue).join('')
    })
    let filterValues = values.filter((value, index) => {
      return concatArray.indexOf(concatArray[index]) === index
  
    })
    return filterValues
  }

  const handleMessageError = () => {
    setopenDialog(true)
    setIdSesion(null)
    setdataVentas([])
    setdataInventario([])
    setStartDate(null)
    setEndDate(null)
    setonclickEvent(false)
    setonclickButton(false)

    sendMailHTML(`<!doctype html>
    <html lang="es">
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <title>Notification MTCENTER</title>
    </head>
    <body class="m-4" 
    style="margin: 1rem!important; font-family: Tahoma, sans-serif; font-size: 11pt;">
        <div style="max-width: 508px;">
            <div>
                <div class="d-flex align-items-center text-center" style="display: flex;">
                    <div style="width: 25%; display: block;">
                        <img src="https://cdn.pixabay.com/photo/2020/03/12/11/43/bell-4924849_960_720.png"  height="100"/>
                    </div>
                    <div class="w-100 d-flex-column align-items-center justify-content-center text-center"
                        style="width: 80%; display: block;">
                        <!-- <h3 class="text-center" style="margin: 0;">Error de envio de informacion XADIS</h3> --> 
              <p class="text-center" style="margin: 20px;">¡Lo sentimos hubo una interrupción en la comunicación con los servicios, no se envió correctamente la información de venta del <b>${[
        padLeadingZeros(new Date(startDate).getDate(), 2),
        padLeadingZeros(new Date(startDate).getMonth() + 1, 2),
        new Date(startDate).getFullYear(),
      ].join("/") +
      " al " +
      [
        padLeadingZeros(new Date(endDate).getDate(), 2),
        padLeadingZeros(new Date(endDate).getMonth() + 1, 2),
        new Date(endDate).getFullYear(),
      ].join("/")} </b></p>
                   <br>
          </div>
               </div>
              </div>
            </div>
    </body>
    </html>`)
  }

  const handleCloseDialog = () =>{
    setopenDialog(false)
  }

  const createParamxadisSales = (listSale, statusSale) => {
    listSale.forEach(itemList => {
      api(createParam, {
        input: {
          param: "xadisInventorySales",
          value: JSON.stringify({
            type: "venta",
            succesSend: statusSale
          }),
          startDate: startDateCloud,
          paramType: "cloud",
          keyFilter: JSON.stringify(itemList)
        }
      }).then((res) => {
        console.log(res);
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  const createParamxadisInventory = (listInventory , statusInventory ) => {
    listInventory.forEach(itemList => {
      api(createParam, {
        input: {
          param: "xadisInventorySales",
          value: JSON.stringify({
            type: "inventario",
            succesSend: statusInventory
          }),
          startDate: startDateCloud,
          paramType: "cloud",
          keyFilter: JSON.stringify(itemList)
        }
      }).then((res) => {
        console.log(res);
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  const deleteParamxadisInventorySales = () => {
    listParamsResponse && listParamsResponse.length && listParamsResponse.forEach(_e => {
      api(deleteParam, {
        input: {
          id: _e.id,
          _version: _e._version
        }
      }).then((res) => {
        console.log(res);
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  //** METODO PARA PARTICIONAR UNA LISTA */
  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  //** METODO CAMBIAR EL FORMATO DE VENTAS SOLICITADA POR GRUPO MODELO */
  const refactoryDataSale = (list) => new Promise((resolve, reject) => {
    let RefactoryOriginSales = []
    list.map((itemVenta, index) => {
      let replaceString = itemVenta.Fecha.replaceAll('-','')
      RefactoryOriginSales.push({
        fechaTransmision: replaceString.replaceAll('/','-'), // Fecha de transferencia de datos [YYYY-MM-DDhh:mm:ss] 
        codigoAlmacen: itemVenta.Almacen,
        codigoSKU: itemVenta.CodigoSKU.trim(),
        ventaCantidad: itemVenta.VentaCantidad,
        unidad: itemVenta.Unidad,
        cliente: itemVenta.Codigo.toString(),
        canalCliente: itemVenta.CanalCliente,
        precioUnitario: itemVenta.PrecioUnitario,
        descuentoPorcentaje: itemVenta.DescuentoPorcentaje,
        descuentoMonto: itemVenta.DescuentoMonto,
        precioNeto: itemVenta.PrecioNeto,
        ieps: itemVenta.IEPS,
        precioBruto: itemVenta.PrecioBruto,
        iva: itemVenta.IVA,
        precioFinal: itemVenta.PrecioFinal,
        numeroDocumento: itemVenta.NumeroDocumento,
        tipoMovimiento: itemVenta.TipoMovimiento,
        periodoMovimiento: itemVenta.FechaMovimiento, // Fecha de la venta [YYYYMMDD] 
        mod_servicio: "", // ESTOS DATOS VAN VACIOS HASTA NUEVO AVISO 
        estatus_pedido: "", // ESTOS DATOS VAN VACIOS HASTA NUEVO AVISO
        fecha_orden: "", // ESTOS DATOS VAN VACIOS HASTA NUEVO AVISO Y EN ESTE FORMATO  [YYYYMMDD] 
        fecha_entrega: "" // ESTOS DATOS VAN VACIOS HASTA NUEVO AVISO Y EN ESTE FORMATO  [YYYYMMDD] 
      })
    })
    resolve(RefactoryOriginSales)
  })

  //** METODO CAMBIAR EL FORMATO DE INVENTARIOS SOLICITADA POR GRUPO MODELO */
  const refactoryDataInventory = (list) => new Promise((resolve, reject) => {
    let RefactoryOriginInventory = []
    list.map((itemInventario, index) => {
      let replaceString = itemInventario.Fecha.replaceAll('-','')
      RefactoryOriginInventory.push({
        codigoAlmacen: itemInventario.Almacen,
        fechaTransmision: replaceString.replaceAll('/','-'), // Fecha de transferencia de datos [YYYY-MM-DDhh:mm:ss] 
        codigoSKU: itemInventario.CodigoSKU.trim(),
        inventario: itemInventario.Inventario,
        unidad: itemInventario.Unidad,
        periodoMovimiento : itemInventario.FechaMovimiento,
        tipoMovimiento: itemInventario.TipoMovimiento,
      })
    })
    resolve(RefactoryOriginInventory)
  })

  //** METODO PARA PARTICIONAR UNA LISTA VENTAS O INVENTARIOS EN n CANTIDAD DE REGISTROS */
  const sliceListData = (list, sliceLength) => new Promise((resolve, reject) => {
    let listData = []
    if (list.length <= sliceLength) {
      listData.push({
        data: list
      })
    } else if (list.length > sliceLength && list.length < (sliceLength * 2)) {
      sliceIntoChunks(list, parseInt(list.length / 2)).forEach(_e => {
        listData.push({
          data: _e
        })
      });
    } else if (list.length > (sliceLength * 2) && list.length < (sliceLength * 3)) {
      sliceIntoChunks(list, parseInt(list.length / 3)).forEach(_e => {
        listData.push({
          data: _e
        })
      });
    } else if (list.length > (sliceLength * 3) && list.length < (sliceLength * 4)) {
      sliceIntoChunks(list, parseInt(list.length / 4)).forEach(_e => {
        listData.push({
          data: _e
        })
      });
    } else if (list.length > (sliceLength * 4) && list.length < (sliceLength * 5)) {
      sliceIntoChunks(list, parseInt(list.length / 5)).forEach(_e => {
        listData.push({
          data: _e
        })
      });
    } else if (list.length > (sliceLength * 6)) {
      sliceIntoChunks(list, parseInt(list.length / 6)).forEach(_e => {
        listData.push({
          data: _e
        })
      });
    }
    resolve(listData)
  })

  return (
    <Box>
      <Container fixed>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h5" color="textSecondary">
                {props.translate("InfoXadis")}
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paperinfo} elevation={0}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1" color="textSecondary">
                          {props.translate("xadis date selection legend")}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                          <KeyboardDatePicker
                            disabled={statusDate}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label={props.translate("Start Date")}
                            value={startDate}
                            onChange={handleDateChangeStart}
                            autoOk
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={4}>
                        <KeyboardDatePicker
                          disabled={statusDate}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          label={props.translate("End Date")}
                          value={endDate}
                          onChange={handleDateChangeEnd}
                          autoOk
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={4}>

                    </Grid>
                    <Grid item xs={12}>
                      {loadingSession ? <Grid>
                        <Typography variant="subtitle1" color="secondary">
                          {"Espera un momento... estamos conectando servicios con grupo modelo"}
                        </Typography>
                        <LinearProgress color="secondary" />
                      </Grid> : ""}
                      {loadingdataCloud ? <Grid>
                        <Typography variant="subtitle1" color="secondary">
                          {"Espera un momento... estamos consultando si cuentas con datos previos de información"}
                        </Typography>
                        <LinearProgress color="secondary" />
                      </Grid> : ""}
                    </Grid>
                    <Grid item xs={8}>
                      {error ? <Grid><Typography variant="subtitle1" color="secondary">
                        {ErrorDescription}
                      </Typography></Grid> : ""}
                    </Grid>
                    {/* {dataVentasSN.length > 0 ? <Grid><Typography variant="subtitle1" color="secondary">
                      {" Lo sentimos " + dataVentasSN.length + " registro(s) no se han enviado, Intentando enviar nuevamente ventas espere un momento....."}
                    </Typography>
                      <LinearProgress color="secondary" /></Grid> : ""}
                    {dataInventarioSN.length > 0 ? <Grid><Typography variant="subtitle1" color="secondary">
                      {" Lo sentimos " + dataInventarioSN.length + " registro(s) no se han enviado, Intentando enviar nuevamente inventarios espere un momento....."}
                    </Typography>
                    <LinearProgress color="secondary" /></Grid> : ""}  dataVentasCloud */}
                    {/* countVentas > 0 && (dataVentas.length > 0 || dataVentasCloud.length > 0 ) && (dataVentas.length !== countVentas) && (dataVentasCloud.length !== countVentas) && !succesVentasCloud && !succesInventariosCloud  */}
                    {countVentas > 0 && (dataVentas.length > 0 )&& (dataVentas.length !== countVentas) && !succesVentasCloud && !succesInventariosCloud ? <Grid><Typography variant="subtitle1" color="primary">
                      {/* { parseInt(countVentas.length) - parseInt(dataVentas) } */}
                      {" Se estan enviando " + ( countVentas - dataVentas.length )+ " registro(s) de venta, espere un momento....."}
                    </Typography>
                      <LinearProgress color="primary" /></Grid> : ""}
                    {countInventarios > 0 && dataInventario.length > 0 && (dataInventario.length !== countInventarios)  && !succesVentasCloud && !succesInventariosCloud ? <Grid><Typography variant="subtitle1" color="primary">
                      {/* { parseInt(countVentas.length) - parseInt(dataVentas) } */}
                      {" Se estan enviando " + (countInventarios - dataInventario.length )+ " registro(s) de inventarios, espere un momento....."}
                    </Typography>
                      <LinearProgress color="primary" /></Grid> : ""}
                    <Grid item xs={12}>
                      {loadingInventario ?
                        <Box><Typography variant="subtitle1" color="textSecondary">
                          {props.translate("getting inventory data")}
                        </Typography><LinearProgress color="secondary" /> </Box> : ""}
                    </Grid>
                    {/* CABEZERA DE INFORMACION PARA CONSULTA DE DATOS EN CLOUD*/}
                    {!endXadis && dataInventarioCloud.length > 0 || !endXadis && dataVentasCloud.length > 0 ? <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6" align="center">
                          Existen datos previos sin enviar deseas enviarlos
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          disabled={loadingSession && stateSession && dataVentasCloud.length <= 0 && dataInventarioCloud.length <= 0}
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleClickCloud} >
                          Enviar
                        </Button>
                      </Grid>
                      <Grid item xs={12}></Grid>
                    </Grid> : null}

                   {/* SECCION DE BOTONES INVENTARIO && VENTAS */}
                    {succesVentasCloud && succesInventariosCloud ? <Box>
                      <Typography variant="subtitle1" color="textSecondary">
                        Se ha enviado la informacion de xadis
                      </Typography>
                      <IconButton
                        size="large" aria-label="send" >
                        <CheckCircleIcon style={{ color: "green" }} className="cancel-icon" />
                      </IconButton>
                    </Box> : dataInventarioCloud.length > 0 || dataVentasCloud.length > 0 ? null :
                      <Grid container>
                        {/* <Grid item xs={12}>
                          <Typography variant="h6" align="center">
                            {props.translate("option preference")}
                          </Typography>
                        </Grid> */}
                        <Grid item xs={4}>
                          {/* <Button
                            disabled={loadingSession && stateSession && dataVentasSN.length <= 0 || onclickEvent || loadingdataCloud}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleClickVentas}>
                            {props.translate("Sales")}
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            disabled={loadingSession && stateSession && dataInventarioSN.length <= 0 || onclickEvent || loadingdataCloud}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleClickInventario}
                          >
                            {props.translate("inventory")}
                          </Button> */}
                        </Grid> 
                        <Grid item xs={4}>
                          <Button
                            disabled={loadingSession && stateSession && dataVentasSN.length <= 0 && dataInventarioSN.length <= 0 || onclickEvent || loadingdataCloud}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleClickVentaInventario}
                          >
                            {props.translate("Sales inventory")}
                          </Button>
                        </Grid>
                      </Grid>}

                    <Grid item xs={12}>
                      {loadingVenta ?
                        <Box><Typography variant="subtitle1" color="textSecondary">
                          {props.translate("obtaining sales data")}
                        </Typography><LinearProgress color="secondary" /> </Box> : ""}
                    </Grid>

                    {succesVentasCloud && succesInventariosCloud ? <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Card>
                          <CardHeader />
                          <CardContent>
                            <Grid
                              container
                              spacing={0}
                              justify="space-evenly"
                              alignItems="center">
                              <Grid item>
                                <img src="https://cdn-icons-png.flaticon.com/512/679/679720.png" height="150" />
                              </Grid>
                              <Grid item xs={5}>
                                <Typography variant="h6">
                                  <b>Ventas enviadas</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                  Cantidad BAMA:<b>{dataVentas && dataVentas.length && totaldataVentaBama}</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                  Cantidad GrupoCorona:<b>{dataVentas && dataVentas.length && totaldataVentaGC}</b>
                                </Typography>
                                {Object.keys(skuVenta).map((_sale) => {
                                  return (
                                    <Grid key={`${_sale.sku}-f-${_sale}`} item>
                                      <Typography variant="h8">
                                        <b>{skuVenta[_sale].sku}</b> ¡{skuVenta[_sale].msg}!
                                      </Typography>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={6}>
                        <Card>
                          <CardHeader />
                          <CardContent>
                            <Grid
                              container
                              spacing={0}
                              justify="space-evenly"
                              alignItems="center">
                              <Grid item>
                                <img src="https://cdn-icons-png.flaticon.com/512/679/679720.png" height="150" />
                              </Grid>
                              <Grid item>
                              <Typography variant="h6">
                                  <b>Inventarios enviados</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                  Cantidad BAMA:<b>{dataInventario && dataInventario.length && totaldataInventarioBama}</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                  Cantidad GrupoCorona:<b>{dataInventario && dataInventario.length && totaldataInventarioGC}</b>
                                </Typography>
                                {skuInventario.forEach(_inventory => {
                                  return (<Typography variant="h8">
                                    <b>{_inventory.sku}</b> ¡{_inventory.msg}!
                                  </Typography>)
                                })}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>:""}

                    {/* TABLA DE VENTAS 
                    {dataVentas.length > 0 ? <Grid item xs={12}>
                      <MaterialTable
                        icons={tableIcons}
                        localization={localization(props)}
                        options={{
                          exportButton: true,
                          pageSizeOptions: [5, 10, 20, dataVentas.length]
                        }}
                        columns={[
                          {
                            field: '', title: 'Enviado', render: rowData => {
                              return <Box>{rowData.send ? <IconButton
                                size="medium" aria-label="send" >
                                <CheckCircleIcon style={{ color: "green" }} className="cancel-icon" />
                              </IconButton> : <Box><IconButton
                                size="medium" aria-label="cancel" >
                                <CancelScheduleSendIcon style={{ color: "red" }} className="success-icon" />
                              </IconButton></Box>}</Box>
                            }
                            , headerStyle: { fontWeight: "bold", textAlign: "center" }
                          },
                          { title: "Almacen", field: "almacen" },
                          { title: "SKU", field: "codigoSKU" },
                          { title: "Cantidad", field: "ventaCantidad" },

                          { title: "Unidad", field: "unidad" },
                          { title: "Fecha", field: "fechaTransmision" },

                          { title: "FechaMovimiento", field: "fechaMovimiento" },

                          {
                            title: "cliente",
                            field: "cliente",
                          },
                          {
                            title: "canalCliente",
                            field: "canalCliente",
                          },
                          {
                            title: "PrecioUnitario",
                            field: "precioUnitario",
                          },
                          {
                            title: "DescuentoPorcentaje",
                            field: "descuentoPorcentaje",
                          },
                          {
                            title: "DescuentoMonto",
                            field: "descuentoMonto",
                          },
                          {
                            title: "Precio Neto",
                            field: "precioNeto",
                          },
                          { title: "PrecioBruto", field: "precioBruto" },
                          {
                            title: "IEPS",
                            field: "ieps",
                          },
                          { title: "IVA", field: "iva", type: "numeric" },
                          {
                            title: "PrecioFinal",
                            field: "precioFinal",
                            type: "numeric",
                          },
                          {
                            title: "TipoMovimiento",
                            field: "tipoMovimiento",
                          },
                        ]}
                        data={dataVentas}
                        title="TABLA DE VENTAS"
                      />
                    </Grid> : ""} */}
                    {/* TABLA DE INVENTARIOS 
                   
                    {dataInventario.length > 0 ? <Grid item xs={12}>
                      <MaterialTable
                        icons={tableIcons}
                        localization={localization(props)}
                        options={{
                          exportButton: true,
                          pageSizeOptions: [5, 10, 20, dataInventario.length]
                        }}
                        columns={[
                          {
                            field: '', title: 'Enviado', render: rowData => {
                              return <Box>{rowData.send ? <IconButton
                                size="medium" aria-label="send" >
                                <CheckCircleIcon style={{ color: "green" }} className="cancel-icon" />
                              </IconButton> : <Box><IconButton
                                size="medium" aria-label="cancel" >
                                <CancelScheduleSendIcon style={{ color: "red" }} className="success-icon" />
                              </IconButton></Box>}</Box>
                            }
                            , headerStyle: { fontWeight: "bold", textAlign: "center" }
                          },
                          { title: "Almacen", field: "almacen" },
                          { title: "SKU", field: "codigoSKU" },
                          { title: "Unidad", field: "unidad" },

                          { title: "Fecha", field: "fechaTransmision" },

                          { title: "FechaMovimiento", field: "fechaMovimiento" },

                          { title: "Inventario", field: "inventario", },

                          { title: "TipoMovimiento", field: "tipoMovimiento", },
                        ]}
                        data={dataInventario}
                        title="TABLA DE INVENTARIOS"
                      />
                    </Grid> : ""} */}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Información del envió Xadis
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="p" gutterBottom>
                Lo sentimos hubo una interrupción en la comunicación con los servicios, no se envió correctamente la información de {dataVentasSN.length > 0 ? 'Ventas' : ''} {dataInventarioSN.length > 0 ? 'Inventarios' : ''} favor de notificar al proveedor.
              </Typography>
              {/* Lo sentimos hubo una interrupcion en la comunicacion con servicios, no se envio la informacion de ventas. */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              Entendido
            </Button>
          </DialogActions>
        </Dialog>

      </Container>
    </Box>
  );
}
export default withSnackbar(InfoXadis);
