import React, { useState, useEffect } from 'react';

import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import bamaLogo from './../../../assets/images/watermark_color.png';
import bamaLogoGray from './../../../assets/images/watermark.png';
import Loader from '../../../components/loader/Loader';

import {
  Drawer, List, ListItem, ListItemIcon, Divider,
  Box,
  Button,
  ListSubheader,
  ListItemText,
  Collapse
} from '@material-ui/core';
import './MenuButton.css';

export default function MenuButton(props) {
  //console.log("MenuButton",JSON.parse(props.user.paramProfile))
  const [menuState, setMenuState] = useState({
    menuListItems: "menuListItems" in props ? props.navigationMenuListItems : [],
    attendant: props.userSignIn,
    left: false,
    open: {}/*,
    redirect: null*/
  })

  const [status, setStatus] = useState(false)
  const [menuProfile, setmenuProfile] = useState({})

  useEffect(() => {
    if (props.navigationMenuListItems !== menuState.menuListItems)
      setMenuState({
        menuListItems: props.navigationMenuListItems ? props.navigationMenuListItems : [],
      })
    if (props && props.paramProfile || props.user && props.user.paramProfile){
      if (props && props.paramProfile !== ""){
        setmenuProfile(JSON.parse(JSON.parse(props.paramProfile)))
      }else{
        setmenuProfile(JSON.parse(JSON.parse(props.user.paramProfile)))
      }
    }
    if (props.status || props.user && props.user.status){
      if (props.status === true){
        setStatus(props.status)
      }else{
        setStatus(props.user.status)
      }
    }

    // console.log("MenuButton",props)
    // console.log("MenuButton",props.status , props.user.status)

  }, [props.navigationMenuListItems, menuState.menuListItems,props]);

  const handleGo = (path, event) => {
    props.go(path);
    setMenuState({
      ...menuState,
      left: false
    });
  }

  const handleClick = (id, path) => {
    var _mOpen = menuState && menuState.open && menuState.open[id]
    setMenuState({
      ...menuState,
      open: {
        ...menuState.open,
        [id]: !_mOpen
      }/*,
      redirect: path ? setTimeout(() => handleGo(path), 3000) : null*/
    });
  };

  const toggleDrawer = (side, open) => event => {
    {menuState && menuState.menuListItems && menuState.menuListItems.map(function (item) {
      return renderMenuItems(item, true)
    })}
    
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMenuState({ ...menuState, [side]: open });
  };

  const renderMenuItems = (menuItem, isRoot) => {    
    //console.log(menuItem);   //
    if (menuItem.hasOwnProperty('active') && menuItem.active) {
      var subMenus = menuState && menuState.menuListItems && menuState.menuListItems.filter(sm => sm.parentSystemMenuId === menuItem.id && sm.active )
      if( props.user.id === "c5866ed1-0809-448d-b950-8b74cad1d05a"){ /// Centralizacion de compras
          if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "Purchases" )
           || !isRoot)
          return (<ListItem button
          key={menuItem.id}
          onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
          {menuItem.icon ?
            <ListItemIcon>
              <Icon fontSize="small">{menuItem.icon}</Icon>
            </ListItemIcon>
            : ''}
          <ListItemText primary={props.translate(menuItem.systemMenuName)} />
          {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
          {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
            < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
              <List component="div" disablePadding>
                {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
              </List>
            </Collapse >
            : ''}
        </ListItem >);
      }else  if(  props.user.id === "64493443-3661-4013-8317-f72e19d280a5" || props.user.id === "39b31843-6271-422f-aa29-d82146e8fff3" || props.user.id === "9c4d8084-a78b-49ab-b7de-e622a3f98e95" ){  // }else  if( props.user.id === "fb8e7956-1fe3-442d-94c5-e1c0f83265ab"){ // HeadCount

       if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "Human Resources")
        || !isRoot)
        return (<Box>{menuItem.systemMenuName !== "templateAssignment"? <ListItem button
        key={menuItem.id}
        onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
        {menuItem.icon ?
          <ListItemIcon>
            <Icon fontSize="small">{menuItem.icon}</Icon>
          </ListItemIcon>
          : ''}
        <ListItemText primary={props.translate(menuItem.systemMenuName)} />
        {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
        {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
          < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
            <List component="div" disablePadding>
              {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
            </List>
          </Collapse >
          : ''}
      </ListItem >: ""}
          </Box>);
    }else  if( props.user.id === "1e7268f5-ee26-49c7-bc56-84ef41098a84"){  // }else  if( props.user.id === "fb8e7956-1fe3-442d-94c5-e1c0f83265ab"){ // HeadCount
    if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "Customers")
     || !isRoot)
     return (<Box> <ListItem button
     key={menuItem.id}
     onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
     {menuItem.icon ?
       <ListItemIcon>
         <Icon fontSize="small">{menuItem.icon}</Icon>
       </ListItemIcon>
       : ''}
     <ListItemText primary={props.translate(menuItem.systemMenuName)} />
     {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
     {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
       < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
         <List component="div" disablePadding>
           {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
         </List>
       </Collapse >
       : ''}
   </ListItem >
       </Box>);
 }else if( props.user.id === "fb8e7956-1fe3-442d-94c5-e1c0f83265ab" ){  // }else  if( props.user.id === "fb8e7956-1fe3-442d-94c5-e1c0f83265ab"){ // HeadCount
   if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "Human Resources" || menuItem.systemMenuName === "Settings")
    || !isRoot)
    return (<Box>{menuItem.systemMenuName !== "templateAssignment" && menuItem.systemMenuName !== "Users" && menuItem.systemMenuName !== "Products"  && menuItem.systemMenuName !== "Roles"? <ListItem button
    key={menuItem.id}
    onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
    {menuItem.icon ?
      <ListItemIcon>
        <Icon fontSize="small">{menuItem.icon}</Icon>
      </ListItemIcon>
      : ''}
    <ListItemText primary={props.translate(menuItem.systemMenuName)} />
    {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
    {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
      < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
        <List component="div" disablePadding>
          {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
        </List>
      </Collapse >
      : ''}
  </ListItem >: ""}
      </Box>);
}else if( props.user.id === "fadd8580-b9cb-4d2b-9863-dbf024aa5498"){  // }else  if( props.user.id === "fb8e7956-1fe3-442d-94c5-e1c0f83265ab"){ // HeadCount
      if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "Human Resources" || menuItem.systemMenuName === "Settings")
        || !isRoot)
          return (<Box>{menuItem.systemMenuName !== "Users" && menuItem.systemMenuName !== "Products"  && menuItem.systemMenuName !== "Roles"? <ListItem button
        key={menuItem.id}
        onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
        {menuItem.icon ?
          <ListItemIcon>
            <Icon fontSize="small">{menuItem.icon}</Icon>
          </ListItemIcon>
        : ''}
      <ListItemText primary={props.translate(menuItem.systemMenuName)} />
      {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
      {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
      < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
     <List component="div" disablePadding>
       {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
     </List>
   </Collapse >
   : ''}
</ListItem >: ""}
   </Box>);
}else if( props.user.id === "33d71abb-86ab-4815-ad65-c17624fa463c"){  // }else  if( props.user.id === "fb8e7956-1fe3-442d-94c5-e1c0f83265ab"){ // HeadCount
if ((isRoot && menuItem.parentSystemMenuId === null &&  menuItem.systemMenuName === "Settings")
  || !isRoot)
    return (<Box>{menuItem.systemMenuName !== "Users" && menuItem.systemMenuName !== "Warehouse"  && menuItem.systemMenuName !== "Roles"? <ListItem button
  key={menuItem.id}
  onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
  {menuItem.icon ?
    <ListItemIcon>
      <Icon fontSize="small">{menuItem.icon}</Icon>
    </ListItemIcon>
  : ''}
<ListItemText primary={props.translate(menuItem.systemMenuName)} />
{menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
{menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
< Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
<List component="div" disablePadding>
 {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
</List>
</Collapse >
: ''}
</ListItem >: ""}
</Box>);
}else if( props.user.id === "c7b9c663-0b97-458d-8e2f-4523d977d465"){  // }else  if( props.user.id === "fb8e7956-1fe3-442d-94c5-e1c0f83265ab"){ // HeadCount
  console.log(menuItem);   //
  console.log(menuItem.systemMenuName);   

  if ((isRoot  && menuItem.systemMenuName === "Remote Commands" ) ///
  || !isRoot)
 return (<ListItem button
 key={menuItem.id}
 onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
 {menuItem.icon ?
   <ListItemIcon>
     <Icon fontSize="small">{menuItem.icon}</Icon>
   </ListItemIcon>
   : ''}
 <ListItemText primary={props.translate(menuItem.systemMenuName)} />
 {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
 {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
   < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
     <List component="div" disablePadding>
       {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
     </List>
   </Collapse >
   : ''}
</ListItem >);
  }else if( props.user.id === "2ebc4299-c48d-4833-b615-916b35ed6d40"){  
  if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "Operation" ) ///
  || !isRoot)
 return (<ListItem button
 key={menuItem.id}
 onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
 {menuItem.icon ?
   <ListItemIcon>
     <Icon fontSize="small">{menuItem.icon}</Icon>
   </ListItemIcon>
   : ''}
 <ListItemText primary={props.translate(menuItem.systemMenuName)} />
 {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
 {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
   < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
     <List component="div" disablePadding>
       {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
     </List>
   </Collapse >
   : ''}
</ListItem >);
  }else  if( props.user.id === "064f91cc-49b1-4249-a2f2-f0866f106283" || props.user.id === "77714555-a35c-47c6-ab23-3bde899f0a3e" || props.user.id === "14f4a1ef-ad05-40e1-9452-3f16a9591acd"  ){  // }else  if( props.user.id === "fb8e7956-1fe3-442d-94c5-e1c0f83265ab"){ // HeadCount
  if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "Customers")
   || !isRoot)
          return (<Box><ListItem button
        key={menuItem.id}
        onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
        {menuItem.icon ?
          <ListItemIcon>
            <Icon fontSize="small">{menuItem.icon}</Icon>
          </ListItemIcon>
        : ''}
      <ListItemText primary={props.translate(menuItem.systemMenuName)} />
      {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
      {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
      < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
     <List component="div" disablePadding>
       {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
     </List>
   </Collapse >
   : ''}
</ListItem >
   </Box>);
}else if( props.user.id === "4937ec63-dd0a-4031-9a78-0aa5208948fb"){  
if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "Customers" || menuItem.systemMenuName === "Settings")
  || !isRoot)
    return (<Box><ListItem button
  key={menuItem.id}
  onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
  {menuItem.icon ?
    <ListItemIcon>
      <Icon fontSize="small">{menuItem.icon}</Icon>
    </ListItemIcon>
  : ''}
<ListItemText primary={props.translate(menuItem.systemMenuName)} />
{menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
{menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
< Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
<List component="div" disablePadding>
 {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
</List>
</Collapse >
: ''}
</ListItem >
</Box>);
}else if( props.user.id === "6699c0bf-1c4d-495a-8a29-ae0246697b99"){  
  if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "License")
    || !isRoot)
      return (<Box><ListItem button
    key={menuItem.id}
    onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
    {menuItem.icon ?
      <ListItemIcon>
        <Icon fontSize="small">{menuItem.icon}</Icon>
      </ListItemIcon>
    : ''}
  <ListItemText primary={props.translate(menuItem.systemMenuName)} />
  {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
  {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
  < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
  <List component="div" disablePadding>
   {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
  </List>
  </Collapse >
  : ''}
  </ListItem >
  </Box>);
  }else if( props.user.id === "6999c1bf-1c4d-495a-8a29-ae0246697b69" || props.user.id === "6999c1bf-1c4d-495a-8a29-ae0246697b99"){  
  console.log(menuItem.systemMenuName)
  if ((isRoot && menuItem.parentSystemMenuId === null &&  menuItem.systemMenuName === "Xadis")
  || !isRoot)
    return (<Box>{menuItem.systemMenuName !== "Empty Container" ? <ListItem button
  key={menuItem.id}
  onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
  {menuItem.icon ?
    <ListItemIcon>
      <Icon fontSize="small">{menuItem.icon}</Icon>
    </ListItemIcon>
  : ''}
<ListItemText primary={props.translate(menuItem.systemMenuName)} />
{menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
{menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
< Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
<List component="div" disablePadding>
 {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
</List>
</Collapse >
: ''}
</ListItem >: ""}
</Box>);
//}else if( props.user.id === "a738fce5-2808-4175-aa79-c3f6ec8b79b5"){ 
}else if(menuProfile && menuProfile.length && menuProfile.filter(r=> r.uuid == props.user.position.id).length > 0){   
 // console.log(menuProfile.filter(r=> r.uuid != props.user.position.id))
 // console.log(menuProfile.find(r=> r.uuid == props.user.position.id).path_permissions.find(r=> r.id != menuItem.id) )
 // console.log(menuProfile.find(r=> r.uuid == props.user.position.id).path_permissions.find(r=> r.id == menuItem.id)  )
//  console.log(menuItem.path)
 // console.log(props.user.position)
 // console.log(props.user.position.id)
 // if(menuProfile. )
  // if ((isRoot && menuItem.parentSystemMenuId === null && menuItem.systemMenuName === "Variable Commissions" )
  // || !isRoot)
  if ((isRoot && menuItem.parentSystemMenuId === null && menuProfile.find(r=> r.uuid === props.user.position.id).path_permissions.find(r=> r.id == menuItem.id) && menuProfile.find(r=> r.uuid == props.user.position.id).path_permissions.find(r=> r.id == menuItem.id).id))
  // if ((isRoot && menuItem.parentSystemMenuId === null &&  menuItem.id === menuProfile.find(r=> r.uuid == props.user.position.id).path_permissions.filter(r=> r.id !== menuItem.id).length > 0)
  // || !isRoot)
 return (<Box>{menuItem.systemMenuName !== "" ? <ListItem button
 key={menuItem.id}
 onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
 {menuItem.icon ?
   <ListItemIcon>
     <Icon fontSize="small">{menuItem.icon}</Icon>
   </ListItemIcon>
   : ''}
 <ListItemText primary={props.translate(menuItem.systemMenuName)} />
 {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
 {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
   < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
     <List component="div" disablePadding>
       {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
     </List>
   </Collapse >
   : ''}
</ListItem >:""}</Box>);
}else{
        if ((isRoot && menuItem.parentSystemMenuId === null )  /// Operation
        || !isRoot)
        return (<ListItem button
          key={menuItem.id}
          onClick={event => subMenus && subMenus.length > 0 ? handleClick(menuItem.id, menuItem.path) : handleGo(`prometheus/${menuItem.path}`)}>
          {menuItem.icon ?
            <ListItemIcon>
              <Icon fontSize="small">{menuItem.icon}</Icon>
            </ListItemIcon>
            : ''}
          <ListItemText primary={props.translate(menuItem.systemMenuName)} />
          {menuItem.parentSystemMenuId === null ? (subMenus && subMenus.length > 0 && menuState.open && menuState.open[menuItem.id] ? <ExpandLess /> : <ExpandMore />) : ''}
          {menuItem.parentSystemMenuId === null && subMenus && subMenus.length > 0 ?
            < Collapse in={menuState.open && menuState.open[menuItem.id]} timeout="auto" unmountOnExit >
              <List component="div" disablePadding>
                {subMenus && subMenus.map(sm => { return renderMenuItems(sm, false) })}
              </List>
            </Collapse >
            : ''}
        </ListItem >);
      }
    }
  }/// 

  ////////////////// FUNCTIONS //////////////////////////
  function dataRoot(menus) {
    var root = []
    menus.forEach(_a => {
        if(_a.parentSystemMenuId === null){

            let Classfamily ={
                parentSystemMenuId: _a.parentSystemMenuId,
                systemMenuName: _a.systemMenuName,
                id: _a.id,
                menu:_a,
                children:dataFamily(_a,menus)
              }
              root.push(Classfamily) 
        }
    })
  
    return root;
  }
  function dataFamily(array,roles) {
    var _rowChildren = [];
      let init=0
      roles.forEach((_r) => {
          if (array.id === _r.parentSystemMenuId) {
            let Classfamily = {
              parentSystemMenuId: _r.parentSystemRoleId,
              systemMenuName: _r.systemMenuName,
              id: _r.id,
              menu:_r,
            };

            _rowChildren.push(Classfamily);

            init += 1;
          }
        });
    return _rowChildren;
  }



  return (
    <Box>
      {status ?<Button
        onClick={toggleDrawer('left', true)}>
        <img
          src={bamaLogo}
          height="75"
          className="d-inline-block logo-bama"
          alt="Bama"
        />
      </Button>:""}
      <Drawer anchor="left" open={menuState.left} onClose={toggleDrawer('left', false)}>
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <Box my={2}>
                <Grid
                  container
                  justify="flex-start"
                  alignItems="flex-end">
                  <Grid item>
                    <img
                      src={bamaLogo}
                      height="75"
                      className="d-inline-block"
                      alt="Bama" />
                  </Grid>
                  <Grid item>
                    {props.translate("Menu")}
                  </Grid>
                </Grid>
              </Box>
            </ListSubheader>
          }>
          <Divider variant="middle" />
          {menuState && menuState.menuListItems && menuState.menuListItems.map(function (item) {
            // let menuFull = [];
            // if (props.user.id === "c5866ed1-0809-448d-b950-8b74cad1d05a") {
            //   dataRoot(menuState.menuListItems).forEach((_e) => {
            //     if (_e.systemMenuName === "Purchases") {
            //       //console.log(_e.menu)
            //       menuFull.push(_e.menu);
            //       _e.children.forEach((_a) => {
            //         // console.log(_a.menu)
            //         menuFull.push(_a.menu);
            //       });
            //     }
            //   });

            //   menuFull.forEach((element) => {
            //     //return renderMenuItems(element, true)
            //   });
            // }
            return renderMenuItems(item, true)
          })}
        </List>
      </Drawer>
      <Loader
          showLoader={!status}
          imageSource={bamaLogoGray}
          loaderSource={null}
          backgroundStyle={null}
          textStyle={null}
          textMessage={"Espera un momento cargando datos de perfil"}
        />
    </Box>
  );
}