import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField } from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddSectionWorPlan = ({ onOpen, onClose, onSave, data, translate }) => {

    const firstRender = useRef(true);
    const [newSectionData, setNewSectionData] = useState({
        id: '',
        sectionName: '',
        description: '',
        workplan: {},
        _version: 0
    })

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return
        }

        setNewSectionData({
            id: data.id,
            sectionName: data.sectionName,
            description: data.description,
            workplan: data.workplan,
            _version: data._version
        })
        const cleanup = () => { }

        return cleanup()
    }, [data])

    const handleSaveSection = () => {
        onSave(newSectionData)
    }
    const handleCancelSection = () => {
        onClose();
    }

    return (
        <Box display='flex' justifyContent='right' alignItems='right' flexDirection='row' m={1} minWidth={'99%'} >
            <Dialog open={onOpen} onClose={onClose} fullWidth={true} maxWidth={false} disableBackdropClick disableEscapeKeyDown >
                <DialogTitle>{translate('WorkPlan Section')}</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <form autoComplete='off' >
                        <Container>
                            <Grid item xs={12} m={1} >
                                <FormControl component='fieldset' error={true} align='center' fullWidth >
                                    <TextField id="planName" label={translate('Section Name')} defaultValue={data.sectionName} helperText={translate('Required')} margin="dense" size="small" required onBlur={(ev) => setNewSectionData({ ...newSectionData, sectionName: ev.target.value })} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} m={1}>
                                <FormControl component='fieldset' error={true} align='center' fullWidth >
                                    <CKEditor data={data.description != '' ? JSON.parse(data.description).DescrtiptionNuevaSeccion : ''} editor={ClassicEditor} config={{ ckfinder: { uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json' } }} onBlur={(event, editor) => setNewSectionData({ ...newSectionData, description: JSON.stringify({ DescrtiptionNuevaSeccion: editor.getData() }) })} ></CKEditor>
                                </FormControl>
                            </Grid>
                        </Container>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button size="small" color='primary' variant='contained' onClick={handleSaveSection} >{translate('Save')}</Button>
                    <Button size="small" color='default' variant='contained' autoFocus onClick={handleCancelSection} >{translate('Cancel')}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

AddSectionWorPlan.propTypes = {
    onOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
}

AddSectionWorPlan.defaultProps = {
    data: {}
}

export default AddSectionWorPlan

