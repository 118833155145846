import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getReportState = state => get(state, 'reports');

export const getReportsName = createSelector([getReportState], reports =>
  get(reports, 'name'),
);

export const getReportsOrganization = createSelector(
  [getReportState],
  reports => get(reports, 'organization'),
);

export const getReportsArea = createSelector([getReportState], reports =>
  get(reports, 'area'),
);

export const getReportsEmployee = createSelector([getReportState], reports =>
  get(reports, 'employee'),
);

export const isReportsHaveStructure = createSelector(
  [getReportState],
  reports => get(reports, 'structure'),
);

export const getReportsEmployeeReports = createSelector(
  [getReportState],
  reports => get(reports, 'reports'),
);

export const isLoadingEmployeereports = createSelector(
  [getReportState],
  reports => get(reports, 'loadingReports'),
);

export const isErrorEmployeereports = createSelector(
  [getReportState],
  reports => get(reports, 'errorLoadingReports'),
);

export const isSendingCommision = createSelector([getReportState], reports =>
  get(reports, 'isSendingCommision'),
);
