import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import { SET_MODALSELECTOR_DATA, SET_MODALSELECTOR_SHOW } from './constants';

const setModalSelectorData = (state, action) => ({
  ...state,
  show: true,
  type: action.payload.type,
  fatherScreen: action.payload.fatherScreen,
});

const setModalSelectorShow = (state, action) => ({
  ...state,
  show: action.payload,
});

export const reducer = handleActions(
  {
    [SET_MODALSELECTOR_DATA]: setModalSelectorData,
    [SET_MODALSELECTOR_SHOW]: setModalSelectorShow,
  },
  INITIAL_STATE,
);
