import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button, Typography, Container } from '@material-ui/core';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { resetKPIData } from '../../../store/kpi/actions';
import { tableESP } from '../../../helpers';
import API from '../../../services/api';
import SessionControlSupportMultiLanguaje from '../../../utils/SessionControl';

import './styles.scss';

export class ListKPIS extends SessionControlSupportMultiLanguaje {
  constructor(props) {
    super(props);
    this.state = {
      kpis: '',
      error: false,
      orgName: this.getSecureStore().organizations.data.find(org => org.id === this.getSecureStore().kpi.organization.id).name,
      areaName: this.getSecureStore().areas.data.find(area => area.id === this.getSecureStore().kpi.area.id).name
    };
  }

  getIndicators = async areaId => {
    try {
      const response = await API.getKPIS(areaId);
      let { data } = response;
      console.log(this.props,response)
      this.setState({ kpis: data, error: false });
    } catch (error) {
      this.setState({ error: true });
    }
  };

  async componentDidMount() {
    const {
      params,
    } = this.props;
    this.getIndicators(params.areaId);
    if(!this.state.orgName && !this.state.areaName){
      this.setState({
        orgName: this.getSecureStore().organizations.data.find(org => org.id === this.getSecureStore().indicator.organization.id).name,
        areaName: this.getSecureStore().areas.data.find(area => area.id === this.getSecureStore().indicator.area.id).name
      })
    }
  }

  createKPI() {
    this.props.resetKPIData();
    this.props.navigate(`/prometheus/variableCommissions/comisiones-variables/crear/`);
  }

  render() {
    const { kpis, error, orgName, areaName } = this.state;
    return (
      <Container fixed>
        <div className={'KPI-list'}>
          <div className="breadcrumbs-container-wrapper">
            <Breadcrumbs {...this.props}
              navigation={[
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                {
                  title: 'Buscar comisiones variables',
                  url: '/prometheus/variableCommissions/comisiones-variables',
                },
                { title: 'Comisiones Variables', current: true },
              ]}
            />
          </div>

          <Button
            className={'small-btn'}
            variant="contained"
            color="primary"
            onClick={() => this.createKPI()}
          >
            Crear comision variable
        </Button>
          <Typography variant="h5" gutterBottom>
            {`${orgName}`}
        </Typography>
          {error === true ? (
            <div className={'error-msg'}>No se encontraron resultados</div>
          ) : isEmpty(kpis) ? (
            <LoadingIndicator />
          ) : (
                <MaterialTable
                  title={`${areaName}`}
                  data={kpis}
                  columns={[
                    { title: 'Nombre', field: 'name' },
                    { title: 'Fecha de ejecución', field: 'run_date' },
                    {
                      title: 'Status',
                      render: rowData => (
                        <div>
                          {rowData.active ? (
                            <span className={'active-txt'}>Activa</span>
                          ) : (
                              <span>Inactiva</span>
                            )}
                        </div>
                      ),
                    },
                    {
                      render: rowData => (
                        <Button
                          className={'small-btn'}
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={`/prometheus/variableCommissions/comisiones-variables/detalle/${rowData.id}`}
                        >
                          Editar
                        </Button>
                      ),
                    },
                  ]}
                  localization={tableESP}
                />
              )}
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetKPIData: () => dispatch(resetKPIData()),
});

export default connect(null, mapDispatchToProps)(ListKPIS);
