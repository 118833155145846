import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';

import {
  isModalselectorShow,
  getModalSelectorType,
} from '../../store/modalSelector/selectors';

import ModalSelectorOrganizations from './ModalSelectorOrganizations';
import ModalSelectorArea from './ModalSelectorArea';
import ModalSelectorTime from './ModalSelectorTime';
import ModalSelectorDataSource from './ModalSelectorDataSource';
import ModalSelectorEmployee from './ModalSelectorEmployee';
import ModalSelectorFunctionParameter from './ModalSelectorFunctionParameter';
import { AppBar, Toolbar, IconButton, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  appBar: {
      position: 'relative',
  },
  fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
  },
  title: {
      marginLeft: theme.spacing(2),
      flex: 1,
  },
}));

export class ModalSelector extends Component {
  render() {
    const { show, type } = this.props;
    // console.log("ModalSelector", this.props)
    return (
      <Dialog
        fullScreen
        open={show}
        closeAfterTransition>
        <Fade in={show}>
          <div className={'dialog-content'}>
            {type === 'organizations' && <ModalSelectorOrganizations />}
            {type === 'area' && <ModalSelectorArea />}
            {type === 'dataSource' && <ModalSelectorDataSource />}
            {type === 'time' && <ModalSelectorTime />}
            {type === 'employee' && <ModalSelectorEmployee />}
            {type === 'functionParameter' && <ModalSelectorFunctionParameter />}
          </div>
        </Fade>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  show: isModalselectorShow(state),
  type: getModalSelectorType(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelector);
