import React, { useState, useEffect, useMemo } from 'react';
import { APIBAMASTAGE } from "../../../env/constants";
import { connect ,useSelector } from 'react-redux';
// import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import { tableESP } from '../../../helpers';

import {
    fetchEmployeeCatalog
} from '../../../store/employeeCatalog/actions';
import {
    setVariableTagEdit
} from '../../../store/variablestag/actions';

import {
    isLoadingEmployeeCatalog,
    getEmployeeCatalog,
} from '../../../store/employeeCatalog/selectors';

import {
    getVariableTagOrganization,
    geteditVariableTag,
    geteditVariableId
} from '../../../store/variablestag/selectors';

import CVTable from '../../../components/CVTable';
import CVTagVariableValue from '../../../components/CVTagVariableValue';
import CVTagVariableForm from '../../../components/CVTagVariableForm';
import zIndex from '@material-ui/core/styles/zIndex';

const DetailValuesVariable = ({
    params,
    fetchEmployeeCatalog,
    employeeCatalog,
    idVariable,
    organizationId,
    editVariableTag,
    setVariableTagEdit
}) => {
    // console.log(params,
    //     fetchEmployeeCatalog,
    //     employeeCatalog,
    //     idVariable,
    //     organizationId,
    //     editVariableTag,
    //     setVariableTagEdit)


    const [nameTagVariable, seNameTagVariable] = useState('');
    const [tagDateVariable, setTagDate] = useState('');
    const [valuesTag, setValuesTag] = useState({});
    const [catalog, setCatalog] = useState([]);
    const [matchCatalog, setMatchCatalog] = useState([]);

    const [employeeTag, setEmployeeTag] = useState('');
    const [valueNumberTag, setValueNumberTag] = useState('');
    const [editTable, setEditTable] = useState([]);
    const [idNewTag, setIdNewTag] = useState('');
    const [loadingNew, setLoadingNew] = useState(false);
    const [loading, setloading] = useState(false);

    const position = useSelector(store => store.UserProfileReducer.user.position)
    const paramsPosition = useSelector(store => JSON.parse(JSON.parse(store.UserProfileReducer.user.paramProfile)))
    const [editPermision, setEditPermision] = useState(false);

    useEffect(() => {
        fetchEmployeeCatalog({ id: organizationId.id });
    }, [organizationId]);

    useEffect(() => {
        if (idVariable) {
            refreshEmployee(idVariable);
        }
    }, []);

    const refreshEmployee = (id) => {
        paramsPosition.filter(r => r.uuid === position.id).forEach(_e => {
            _e.path_permissions.forEach(_p => {
                if (_p.name === "Variables") {
                    setEditPermision( _p.write)
                }
            });

        });
        fetch(`${APIBAMASTAGE}/variable-value/${id}/employees`)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    console.log(data)
                    const { date, tag, values } = data.variable_value;
                    seNameTagVariable(tag);
                    setTagDate(date);
                    setValuesTag(values);
                }
            })
            .catch(error => { console.log(error) });
    }

    const handleChangeNameTagVariable = (name) => {
        seNameTagVariable(name);
    }

    const handleChangeDateTagVariable = (date) => {
        setTagDate(date);
    }

    const handleChangeNameEmployeeTagVariable = (nameEmployee) => {
        setEmployeeTag(nameEmployee);
    }

    const handleChangeValueEmployeeTagVariable = (valueTagDate) => {
        setValueNumberTag(valueTagDate);
    }


    // let history = useHistory();

    const goVarTagEdit = (id) => {
        setVariableTagEdit(true);
        this.props.navigate(`/prometheus/variableCommissions/variables/detalle-valor-tag/${id}`);
        refreshEmployee(id);
        MatchEmployees();
    }

    //Add value table NEW
    const handleAddValeTable = () => {
        setLoadingNew(true);
        setTimeout(() => {
            let arr = editTable;
            const obj = { employeeId: employeeTag.employeeId, name: employeeTag.name, job: employeeTag.job, value: valueNumberTag }
            const index = arr.findIndex(x => x.employeeId == obj.employeeId);
            if (index === -1) {
                arr.push(obj)
            } else {
                arr[index] = obj;
            }
            setLoadingNew(false);
            setEditTable(arr);
            setValueNumberTag('');
        }, 1000)
    }


    const DeleteValueTag = (index) => {
        let arr = [...editTable];
        arr.splice(index, 1);
        setEditTable(arr);
    }

    const handleUpdateValueTag = (newData, index) => {
        let arr = [...editTable];
        arr[index] = newData;
        setEditTable(arr);
    }


    const saveVariableTag = () => {

        setloading(true);

        let objvalue;
        const valuesTagEmployee = editTable.map(data => {
            objvalue = { ...objvalue, ...{ [data.employeeId]: parseFloat(data.value) } }
        })

        let body = {
            variable_values: [
                {
                    tag: `${nameTagVariable}`,
                    date: `${tagDateVariable}`,
                    values: objvalue
                }
            ]
        }

        fetch(`${APIBAMASTAGE}/variable/${idVariable}/values`, { method: 'post', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(data => {

                const errorSaveVariable = data.errors;
                const processed_items = data.processed_items;
                const unprocessed_items = data.unprocessed_items;

                if (typeof errorSaveVariable !== 'undefined') {
                    console.log(data.errors[0].variable_value)
                }

                if (typeof processed_items !== 'undefined') {
                    if (processed_items.length > 0) {
                        console.log(data.processed_items[0].id)
                        setIdNewTag(data.processed_items[0].id);
                        goVarTagEdit(data.processed_items[0].id);
                    }
                }

                if (typeof unprocessed_items !== 'undefined') {
                    if (unprocessed_items.employees_not_found.length > 0) {
                        console.log(data.unprocessed_items.employees_not_found[0])
                    }
                }
                setloading(false);

            })
            .catch(error => {
                console.log(error);
                setloading(false);
            });

    }

    //Add value table EDIT

    const handleAddValueEmployeeValue = () => {

        let body = {
            values: { [employeeTag.employeeId]: parseFloat(valueNumberTag) }
        }
        let arr = matchCatalog;
        setLoadingNew(true);

        fetch(`${APIBAMASTAGE}/variable-value/${idVariable}/employees`, { method: 'post', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(() => {
                setTimeout(() => {
                    const obj = { employeeId: employeeTag.employeeId, value: parseFloat(valueNumberTag), name: employeeTag.name, job: employeeTag.job }
                    const index = arr.findIndex(x => x.employeeId == obj.employeeId);
                    if (index === -1) {
                        arr.push(obj)
                    } else {
                        arr[index] = obj;
                    }
                    setMatchCatalog(arr);
                    setValueNumberTag('');
                    setLoadingNew(false);

                }, 1000)
            })
            .catch(error => { console.log(error) });

    }


    const DeleteValueTagEdit = (index) => {

        let arr = [...matchCatalog];
        const data = arr[index];
        setLoadingNew(true);
        fetch(`${APIBAMASTAGE}/variable-value/${idVariable}/employees/${data.employeeId}`, { method: 'DELETE' })
            .then(response => response.json())
            .then(data => {
                arr.splice(index, 1);
                setMatchCatalog(arr);
                setLoadingNew(false);
            })
            .catch(error => { console.log(error) });

    }

    const handleUpdateValueTagEdit = (newData, index) => {
        setLoadingNew(true);
        let arr = matchCatalog;
        let body = {
            values: { [newData.employeeId]: parseFloat(newData.value) }
        }
        fetch(`${APIBAMASTAGE}/variable-value/${idVariable}/employees`, { method: 'post', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(() => {
                setTimeout(() => {
                    const obj = { employeeId: newData.employeeId, value: parseFloat(newData.value), name: newData.name, job: newData.job }
                    const index = arr.findIndex(x => x.employeeId == obj.employeeId);
                    if (index === -1) {
                        arr.push(obj)
                    } else {
                        arr[index] = obj;
                    }
                    setMatchCatalog(arr);
                    setValueNumberTag('');
                    setLoadingNew(false);

                }, 1000)
            })
            .catch(error => { console.log(error) });
    }


    //MATCH

    const MatchEmployees = () => {

        let count = 0;

        if (Object.keys(valuesTag).length === 0 && employeeCatalog.data.length === 0) {
            return null
        }

        const match2 = employeeCatalog.data.map((key) => {
            return { employeeId: Object.keys(key)[0], name: key[Object.keys(key)[0]].name, job: key[Object.keys(key)[0]].hierarchical_node_name }
        });

        const match = Object.entries(valuesTag).map(([key, value]) => {
            count = count + 1;
            if (match2.length !== 0) {
                const x = match2.find(x => x.employeeId === `${key}`);
                if (x) {
                    return { employeeId: key, value: value, name: x.name, job: x.job }
                }
            }
            return { employeeId: key, value: value }
        });
        setCatalog(match2);
        setMatchCatalog(match)

    }

    const table = useMemo(() => MatchEmployees(), [valuesTag, employeeCatalog.data]);

    return (
        <div>
            {table}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {editVariableTag ? <h3>Editar Tag</h3> : <h3>Crear Tag</h3>}
                </Grid>
                <Grid item xs={12}>
                    {editVariableTag ? <h2>Nombre de la Variable : {nameTagVariable} , Fecha : {tagDateVariable} </h2> :
                        <CVTagVariableForm
                            nameTagVariable={nameTagVariable}
                            dateTagVariable={tagDateVariable}
                            changeNameTagVariable={handleChangeNameTagVariable}
                            changeDateTagVariable={handleChangeDateTagVariable}
                            editTagVariable={editVariableTag}
                            data={editTable}
                            saveTagVariable={saveVariableTag}
                            loading={loading}
                        ></CVTagVariableForm>
                    }
                </Grid>
                <Grid item xs={12}>
                    <CVTagVariableValue
                        valueNumberTag={valueNumberTag}
                        catalog={catalog}
                        changeValueTag={handleChangeValueEmployeeTagVariable}
                        changeEmployeeTag={handleChangeNameEmployeeTagVariable}
                        addValueTable={handleAddValeTable}
                        addValueEmployeeValue={handleAddValueEmployeeValue}
                        editTagVariable={editVariableTag}
                    />
                </Grid>
                <Grid item xs={12}>
                    {
                        editVariableTag ?
                            employeeCatalog.loading ? <div>Cargando</div> :


                                loadingNew ?
                                    <div>Cargand</div> :

                                    <div>
                                        <CVTable
                                            columns={
                                                [
                                                    { title: 'ID', field: 'employeeId', editable: 'never' },
                                                    { title: 'Nombre', field: 'name', editable: 'never' },
                                                    { title: 'Puesto', field: 'job', editable: 'never' },
                                                    { title: 'Valor', field: 'value' }
                                                ]
                                            }
                                            data={matchCatalog}
                                            onEdit={editPermision}
                                            title={`${nameTagVariable} / ${tagDateVariable}`}
                                            onUpdateValue={handleUpdateValueTagEdit}
                                            onDeleteValue={DeleteValueTagEdit}
                                        ></CVTable>
                                    </div>
                            :
                            loadingNew ?
                                <div>Cargando</div> :
                                <div>
                                    <CVTable
                                        columns={
                                            [
                                                { title: 'ID', field: 'employeeId', editable: 'never' },
                                                { title: 'Nombre', field: 'name', editable: 'never' },
                                                { title: 'Puesto', field: 'job', editable: 'never' },
                                                { title: 'Valor', field: 'value' }
                                            ]
                                        }
                                        data={editTable}
                                        onEdit={editPermision}
                                        title={`${nameTagVariable} / ${tagDateVariable}`}
                                        onUpdateValue={handleUpdateValueTag}
                                        onDeleteValue={DeleteValueTag}
                                    ></CVTable>
                                </div>
                    }
                </Grid>
            </Grid>
        </div>
    );
};


const mapStateToProps = state => ({
    employeeCatalog: {
        loading: isLoadingEmployeeCatalog(state),
        data: getEmployeeCatalog(state)
    },
    organizationId: getVariableTagOrganization(state),
    editVariableTag: geteditVariableTag(state),
    idVariable: geteditVariableId(state)
});


const mapDispatchToProps = dispatch => ({
    fetchEmployeeCatalog: payload => dispatch(fetchEmployeeCatalog(payload)),
    setVariableTagEdit: payload => dispatch(setVariableTagEdit(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailValuesVariable);

