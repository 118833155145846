import React , { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import { FormControl , FormGroup, TextField } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';


class ModalAddValueParameter extends Component {

    render(){
        const { 
            open, 
            handleClose , 
            changeValue,
            dateParameter,
            loading,
            saveValue,
            valorParameter,
            changeDate
        } = this.props;

        return (
            <div>
                <Dialog fullScreen open={open} closeAfterTransition>
                <Fade in={open}>
                    <div className={'dialog-content'}>
                        <Grid className={'btn-wrapper'} container spacing={3}>
                            <Grid item xs={12}>
                                <h2 id="transition-modal-title">Registrar valores</h2>
                            </Grid>
                            <Grid className={'btn-wrapper'} item xs={12} lg={12}>
                                <h3>Valores</h3>
                                <Grid className={'btn-wrapper'} container spacing={1}>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <FormGroup>
                                                <TextField 
                                                    className=""
                                                    type="date"
                                                    name="dateParameter"
                                                    variant="outlined"
                                                    onChange={e => changeDate(e.target.value)}
                                                    value= {dateParameter}
                                                />
    
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <FormGroup>
                                                <TextField 
                                                    className=""
                                                    type="number"
                                                    name="valorParameter"
                                                    variant="outlined"
                                                    label="Valor"
                                                    onChange={e => changeValue(e.target.value)}
                                                    value= {valorParameter}
                                                />
    
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {loading ? 
                                (
                                    <CircularProgress />
                                ) :( 
                                    <Button
                                        className={'btn accept-btn'}
                                        variant="contained" 
                                        onClick={saveValue}
                                        disabled={ (dateParameter.length > 0) && (valorParameter.length > 0) ? false : true}
                                    >
                                        Guardar
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Button className={'btn cancel-btn'} onClick={handleClose}>
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    </Fade>
                </Dialog>
            </div>
        );

    }

};

export default ModalAddValueParameter;