import React from 'react';
import { Container, Typography } from '@material-ui/core';

function NoticePrivacy() {
    return (<Container>
        <Typography color="primary" variant="h5" gutterBottom>
            {"AVISO DE PRIVACIDAD"}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"RESPONSABLE DE DATOS PERSONALES"}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"TIENDAS BAMA, S.A. DE C.V., con domicilio en Carretera Transístmica Km. 7.5 #102 BA, Col. Tierra Nueva. - Coatzacoalcos, Ver. - Tel. (921) 2113340 tiene el propósito de proteger la información personal proporcionados por sus usuarios (Datos Personales) y es responsable de su tratamiento, cuando sean recabados a través de este sitio de internet, medios impresos, vía telefónica y/o correo electrónico."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"TRATAMIENTO DE DATOS PERSONALES"}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Podemos recabar sus datos personales de distintas formas: cuando usted nos los proporciona directamente; cuando visita nuestro sitio de Internet o utiliza nuestros servicios en línea, y cuando obtenemos información a través de otras fuentes que están permitidas por la ley."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Datos personales que recabamos de forma directa Recabamos sus datos personales de forma directa cuando usted mismo nos los proporciona por diversos medios, como cuando nos da información con objeto de que le prestemos un servicio en la mayoría de los Casos Tiempo Aire Electrónico. Los datos que obtenemos por este medio pueden ser, entre otros:"}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            <ul>
                <li>{"Números telefónicos proporcionados voluntariamente"}</li>
                <li>{"Compañía Telefónica Preferencial"}</li>
            </ul>
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Datos personales que recabamos cuando visita nuestro sitio de Internet o utiliza nuestros servicios en línea:"}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            <ul>
                <li>{"Nombre"}</li>
                <li>{"Teléfono"}</li>
                <li>{"Email"}</li>
                <li>{"Dirección"}</li>
                <li>{"Localidad"}</li>
            </ul>
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Datos personales que recabamos a través de otras fuentes Podemos obtener información de usted de otras fuentes permitidas por la ley, tales como ejemplo: los directorios telefónicos o laborales, Secretaría del Trabajo y Previsión social. Los datos que obtenemos por estos medios pueden ser, entre otros:"}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            <ul>
                <li>{"Nombre"}</li>
                <li>{"Teléfono"}</li>
                <li>{"Email"}</li>
                <li>{"Dirección"}</li>
                <li>{"Localidad"}</li>
            </ul>
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Es importante informar que cuando se recaban datos que no se perciben por los usuarios, utilizando herramientas como los cookies, web beacons o similares la empresa se deslinda de cualquier responsabilidad de cualquier índole."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"TIENDAS BAMA S.A. DE C.V. podrá solicitar y/o recabar a través del sitio y otros medios datos personales de los usuarios para su uso comercial, divulgación y/o almacenamiento por cualquier medio para los fines señalados en líneas posteriores."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"TIENDAS BAMA, S.A. DE C.V. y/o cualquier tercero que llegue a intervenir en cualquier fase del Tratamiento de Datos personales guardará confidencialidad respecto de los mismos cuando tengan dicho carácter, conforme a las disposiciones aplicables en los Estado Unidos Mexicanos."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"FINALIDAD DE LOS DATOS PERSONALES"}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Los Datos personales serán utilizados para las siguientes finalidades:"}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            <ul>
                <li>{"Informar sobre nuevos productos o servicios que estén relacionados con el contratado o adquirido por los clientes;"}</li>
                <li>{"Informar sobre cambios de nuestros productos o servicios;"}</li>
                <li>{"Reclutamiento de Personal"}</li>
                <li>{"Reclutamiento de nuevos Proveedores"}</li>
                <li>{"Evaluar la calidad del servicio, y"}</li>
                <li>{"Realizar estudios internos sobre hábitos de consumo"}</li>
            </ul>
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"CAMBIO, RECTIFICACIÓN DE LOS DATOS PERSONALES"}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"El cliente tiene el derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Los mecanismos que se han implementado para el ejercicio de dichos derechos son a través de la presentación de la solicitud respectiva en:"}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            <ul>
                <li>
                    {"Nuestro departamento de privacidad al siguiente correo electrónico: "}
                    <a href="mailto:avisodeprivacidad@bama.mx">{"avisodeprivacidad@bama.mx"}</a>
                    {" o haciendo llegar la misma al domicilio referido."}
                </li>
                <li>{"Nuestros establecimientos de servicio pueden ser ubicados a través de la página electrónica "} <a target="_blank" href="http://www.bama.mx/sucursales.html" rel="noopener noreferrer">{"http://www.bama.mx/sucursales.html"}</a></li>
            </ul>
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"CONSENTIMIENTO"}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"El ingreso y/o registro a través del sitio y/o por otros medios implica el consentimiento pleno y sin reservas del usuario para el tratamiento de sus datos personales de acuerdo con el presente aviso de privacidad."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"REVOCACIÓN DEL CONSENTIMIENTO"}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"En todo momento usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos. Para ello, es necesario que presente su petición escrita vía correo electrónico , en el que se señalen los motivos o causas por las cuales desea revocar su consentimiento."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Su petición deberá ir acompañada de la siguiente información:"}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            <ul>
                <li>{"Nombre"}</li>
                <li>{"Edad"}</li>
                <li>{"Nacionalidad"}</li>
                <li>{"Ocupación"}</li>
                <li>{"Folio IFE o Cédula Profesional o Cartilla Militar"}</li>
                <li>{"Correo Electrónico"}</li>
            </ul>
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Información que deberá ser enviada a la siguiente Dirección de Correo Electrónica "}
            <a href="mailto:avisodeprivacidad@bama.mx">{"avisodeprivacidad@bama.mx"}</a>
            {" o haciendo llegar la misma al domicilio referido. Una vez recibida su solicitud se tendrá un plazo de 15 días hábiles para decretar la procedencia o improcedencia de su solicitud."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"DATOS PERSONALES SENSIBLES"}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"De acuerdo con la legislación vigente, se conocen como \"datos personales sensibles\" a \"aquellos que puedan revelar aspectos de origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y/o morales, afiliación sindical, opiniones políticas y preferencias sexual\". En caso de requerir la empresa TIENDAS BAMA, S.A. DE C.V. información sobre Datos Personales Sensibles de los usuarios, estos deberán proporcionar su consentimiento expreso."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS PERSONALES"}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"TIENDAS BAMA S.A. DE C.V. cuenta con medidas físicas, técnicas y administrativas de seguridad para la protección de Datos personales proporcionados por los usuarios. Si usted desea dejar de recibir mensajes promocionales vía correo electrónico y/o de forma impresa y/o vía telefónica por parte de la empresa puede solicitarlo a través de: correo electrónico: "}
            <a href="mailto:avisodeprivacidad@bama.mx">{"avisodeprivacidad@bama.mx"}</a>
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"CAMBIOS AL AVISO DE PRIVACIDAD"}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"TIENDAS BAMA, S.A. DE C.V., se reserva el derecho de modificar en cualquier momento el contenido del presente Aviso de privacidad. Cualquier cambio al aviso de privacidad será informado a los usuarios a través del sitio. Una vez que se publique el aviso de privacidad en el sitio entrará en vigor automáticamente."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"TIENDAS BAMA, S.A. DE C.V. no realiza la transferencia de sus datos personales, por ningún medio de comunicación electrónica."}
        </Typography>
    </Container>)
}

export default NoticePrivacy;