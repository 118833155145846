import React, { useState, useEffect, useRef, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Grow, LinearProgress, Container, Typography, Paper, Box, CssBaseline } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { container } from '@aws-amplify/ui';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MaterialTable from 'material-table'


//====Dialog
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

//====Dialogo confirmción
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


import ImgTicket from './EjemploTicket.png';
import AddIcon from '@material-ui/icons/Add';


const useStylesGrid = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const useStylesAmount = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));



function getSteps() {
    return ['Paso 1: Ingresa tu ticket', 'Paso 2: Ingresa RFC', 'Paso 3: Verifica tus datos'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Paso 1: Ingresa folio de ticket', FolioTicket();
        case 1:
            return 'Paso 2: Ingresa tu RFC', RFC();
        case 2:
            return 'Paso 3: Verifica tus datos', DatosGeneralres();
        default:
            return 'Unknown step';
    }
}
const FolioTicket = () => {
    return <Grid container spacing={4} justify="flex-end" >
        <Grid item xs={6}>
            <Paper>
                <TextField id="standard-basic" label="N° Tikect" />
                <IconButton color="secondary" aria-label="add ticket">
                    <AddIcon />
                </IconButton>
                <MaterialTable
                    title="Tickets agregados"
                    columns={[
                        { title: 'N° Ticket', field: 'Ticket' },
                        { title: 'Descripcion', field: 'Desc' },
                        { title: 'Fecha', field: 'Date' },
                        { title: 'Monto', field: 'Mount' },

                    ]}
                    data={[
                        { Ticket: 'C0130-4356872', Desc: 'Recarga Telcel', Date: '12/12/2020', Mount: '$20.00' },
                        { Ticket: 'C0130-4356872', Desc: 'Coca Cola 600ml', Date: '12/12/2020', Mount: '$12.50' },
                        { Ticket: 'C0121-9845123', Desc: 'Red Bull 415ml', Date: '14/12/2020', Mount: '$40.00' },
                    ]}
                    options={{
                        search: true
                    }}
                />
            </Paper>
        </Grid>
        <Grid item xs={6}>
            Ejemplo de N° Ticket
            <Paper>
                <img src={ImgTicket} width="200" height="200" />
            </Paper>
        </Grid>
    </Grid>;
};
const RFC = () => {
    return <TextField id="standard-basic" label="Ingresa RFC" />;
};
const DatosGeneralres = () => {
    return <Grow in={true} spacing={2}>
        {/* <Container maxWidth="xl"> */}

            <Grid spacing={4} justify="center" >
                <Grid item xs={12}>
                    <div>
                        <Grid spacing={2}>
                            <Paper>
                                <Typography variant="h5" component="h2">Datos Generales</Typography>
                                <TextField id="outlined-basic" variant="outlined" label="Correo Electronico" /> <TextField id="outlined-basic" variant="outlined" label="Confirma Correo" />
                            </Paper>

                        </Grid>
                    </div>
                    {/* <Grid spacing={12}>
                        <Paper>
                            <Typography variant="h5" component="h2">Datos de facturación</Typography>
                            <div>
                                <Grid spacing={2}>
                                    <TextField id="outlined-basic" variant="outlined" label="RFC" /> <TextField id="outlined-basic" variant="outlined" label="Nombre o razon social" />
                                </Grid>
                            </div>
                            <Grid spacing={2}>
                                <TextField id="outlined-basic" variant="outlined" label="Calle y Numero" /> <TextField id="outlined-basic" variant="outlined" label="Delegacion o Municipio" /> <TextField id="outlined-basic" variant="outlined" label=" Colonia" />
                            </Grid>
                            <Grid spacing={2}>
                                <TextField id="outlined-basic" variant="outlined" label="Ciudad" /> <TextField id="outlined-basic" variant="outlined" label="CP" /> <TextField id="outlined-basic" variant="outlined" label=" Pais" />
                            </Grid>
                        </Paper>
                    </Grid> */}
                    <Grid spacing={2}>
                        <Paper>
                            <Typography variant="h5" component="h2">Montos de ticket</Typography>
                            <Grid spacing={2}>
                                <Grid container alignItems="flex-end">
                                    <Grid item>
                                        Subtotal:
                                    </Grid>
                                    <Grid item>
                                        <MonetizationOnIcon />
                                    </Grid>
                                    <Grid item >
                                        <TextField id="input-with-icon-grid" label="Monto" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid spacing={2}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        Impestos trasladados (IVA)
                                </Grid>
                                    <Grid item>
                                        <MonetizationOnIcon />
                                    </Grid>
                                    <Grid item>
                                        <TextField id="input-with-icon-grid" label="Monto" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid spacing={2}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        Impuestos retenidos (ISR)
                                </Grid>
                                    <Grid item>
                                        <MonetizationOnIcon />
                                    </Grid>
                                    <Grid item>
                                        <TextField id="input-with-icon-grid" label="Monto" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid spacing={2}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        Impuestos retenidos (IVA)
                                </Grid>
                                    <Grid item>
                                        <MonetizationOnIcon />
                                    </Grid>
                                    <Grid item>
                                        <TextField id="input-with-icon-grid" label="Monto" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid spacing={2}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        Total
                                </Grid>
                                    <Grid item>
                                        <MonetizationOnIcon />
                                    </Grid>
                                    <Grid item>
                                        <TextField id="input-with-icon-grid" label="Monto" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        {/* </Container> */}
    </Grow>



        ;
};

//======Dialog
const useStylesDialog = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//======end Dailog



function Billing(props) {
    const classes = useStyles();
    const classesAmount = useStylesAmount();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
    const classesGrid = useStylesGrid();


    const totalSteps = () => {
        return getSteps().length;
    };

    const isStepOptional = (step) => {
        return step === 1;
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const skippedSteps = () => {
        return skipped.size;
    };

    const completedSteps = () => {
        return completed.size;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed
                // find the first step that has been completed
                steps.findIndex((step, i) => !completed.has(i))
                : activeStep + 1;
        console.log("Numero", activeStep)
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);

        /**
         * Sigh... it would be much nicer to replace the following if conditional with
         * `if (!this.allStepsComplete())` however state is not set when we do this,
         * thus we have to resort to not being very DRY.
         */
        if (completed.size !== totalSteps() - skippedSteps()) {
            handleNext();
        }
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted(new Set());
        setSkipped(new Set());
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }
    //======Dialog

    const classesDialog = useStylesDialog();
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    //======end dialog

    //======dialogo confirmación=====

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const themeConfirm = useTheme();
    const fullScreen = useMediaQuery(themeConfirm.breakpoints.down('sm'));

    const handleClickOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    //====== end dialogo confirmación=====

    return (

        <div id="AllComponent">
            <div id="btnComponet">
                <Grid container justify="flex-end" >
                    <Paper>

                    </Paper>
                </Grid>
            </div>
            <Grid>
                <React.Fragment>
                    <CssBaseline />
                    <Container fixed>
                        <div className={classesGrid.root}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper className={classesGrid.paper}>
                                        <div className={classes.root}>
                                            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                                                {steps.map((label, index) => {
                                                    const stepProps = {};
                                                    const buttonProps = {};
                                                    if (isStepOptional(index)) {
                                                        buttonProps.optional = <Typography variant="caption">Validación de RFC</Typography>;
                                                    }
                                                    if (isStepSkipped(index)) {
                                                        stepProps.completed = false;
                                                    }
                                                    return (
                                                        <Step key={label} {...stepProps}>
                                                            <StepButton
                                                                onClick={handleStep(index)}
                                                                completed={isStepComplete(index)}
                                                                {...buttonProps}
                                                            >
                                                                {label}
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                            <div>
                                                {allStepsCompleted() ? (
                                                    <div>
                                                        <Typography className={classes.instructions}>
                                                            All steps completed - you&apos;re finished
                                                </Typography>
                                                        <Button onClick={handleReset}>Reset</Button>
                                                    </div>
                                                ) : (
                                                        <div>
                                                            <Box my={2}>

                                                                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>

                                                                {activeStep ? '2' &&
                                                                    <div>
                                                                        {/* <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
                                                                        Registrarse
                                                                    </Button>  */}
                                                                        <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
                                                                            <AppBar className={classesDialog.appBar} color="secondary">
                                                                                <Toolbar>
                                                                                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                                                                        <CloseIcon />
                                                                                    </IconButton>
                                                                                    <Typography variant="h6" className={classesDialog.title}>
                                                                                        Registrar
                                                                                </Typography>
                                                                                    <Button autoFocus color="inherit" onClick={handleClose}>
                                                                                        Guardar
                                                                                </Button>
                                                                                </Toolbar>
                                                                            </AppBar>
                                                                            <Grow in={true} spacing={2}>
                                                                                <Container maxWidth="xl">
                                                                                    <Grid container spacing={4} justify="center" >
                                                                                        <Grid item xs={12}>
                                                                                            <div>
                                                                                                <Grid spacing={2}>
                                                                                                    <Paper>
                                                                                                        <Typography variant="h5" component="h2">Datos Generales</Typography>
                                                                                                        <TextField id="outlined-basic" variant="outlined" label="Correo Electronico" /> <TextField id="outlined-basic" variant="outlined" label="Confirma Correo" />
                                                                                                    </Paper>

                                                                                                </Grid>
                                                                                            </div>
                                                                                            <Grid spacing={12}>
                                                                                                <Paper>
                                                                                                    <Typography variant="h5" component="h2">Datos de facturación</Typography>
                                                                                                    <div>
                                                                                                        <Grid spacing={2}>
                                                                                                            <TextField id="outlined-basic" variant="outlined" label="RFC" /> <TextField id="outlined-basic" variant="outlined" label="Nombre o razon social" />
                                                                                                        </Grid>
                                                                                                    </div>
                                                                                                    <Grid spacing={2}>
                                                                                                        <TextField id="outlined-basic" variant="outlined" label="Calle y Numero" /> <TextField id="outlined-basic" variant="outlined" label="Delegacion o Municipio" /> <TextField id="outlined-basic" variant="outlined" label=" Colonia" />
                                                                                                    </Grid>
                                                                                                    <Grid spacing={2}>
                                                                                                        <TextField id="outlined-basic" variant="outlined" label="Ciudad" /> <TextField id="outlined-basic" variant="outlined" label="CP" /> <TextField id="outlined-basic" variant="outlined" label=" Pais" />
                                                                                                    </Grid>
                                                                                                </Paper>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Container>
                                                                            </Grow>
                                                                        </Dialog>
                                                                    </div>
                                                                    : ''}
                                                            </Box>



                                                            <div>
                                                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                                    Atras
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                    className={classes.button}
                                                                >
                                                                    Siguiente
                                                                </Button>
                                                                {/* {isStepOptional(activeStep) && !completed.has(activeStep) && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleSkip}
                                                                className={classes.button}
                                                            >
                                                                Skip
                                                            </Button>
                                                        )} */}

                                                                {activeStep !== steps.length &&
                                                                    (completed.has(activeStep) ? (
                                                                        <Typography variant="caption" className={classes.completed}>
                                                                            Step {activeStep + 1} already completed
                                                                        </Typography>
                                                                    ) : (
                                                                            <Button variant="contained" color="primary" disabled={true} onClick={handleComplete}>
                                                                                {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Generar Factura'}
                                                                            </Button>
                                                                        ))}
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>


                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </React.Fragment>
            </Grid>
        </div>

    )

}

export default withSnackbar(Billing);