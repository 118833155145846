import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import {
  Box,
  Grid,
  FormHelperText,
  Typography,
  IconButton,
  Fab,
  MenuItem,
  Paper,
  Button,
  Card,
  TextField,
  LinearProgress,
  Zoom,
} from "@material-ui/core";
import Empty from "../xadis/rowEmpty";
import InfoEmptyFortia from "../xadis/rowInfoEmptyFortia";
import { XadisStockType } from '../../schema/enums/schemaEnums';
import Alert from '@material-ui/lab/Alert';
import { SNACKBAR_SUCCESS } from '../../utils/SessionControl';

import { API, graphqlOperation } from 'aws-amplify';
import { api, apiPartial } from "../../api/api";
import { listParams, listXadisEmptyContainers, getRemoteRequest } from "../../graphql/queries";
import { onUpdateRemoteRequest } from "../../graphql/bamaQueries";
import { createRemoteRequest, createXadisEmptyContainer, updateXadisEmptyContainer, deleteRemoteRequest } from "../../graphql/mutations"
import { withSnackbar } from "notistack";
import { store } from '../../App';
import HistoricalEmptyContainer from "../../components/xadis/HistoricalEmptyContainer";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  paperSearch: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperinfo: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(10),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function EmptyContainer(props) {
  const classes = useStyles();
  const firstRender = useRef(true);
  const [productInfoEmpty, setProductInfoEmpty] = useState([])
  const [productEmpty, setProductEmpty] = useState([])
  const [listXadisEmpty, setlistXadisEmpty] = useState([])
  const [ItemXadisEmptyFull, setItemXadisEmptyFull] = useState([])
  const [IdXadisEmpty, setIdXadisEmpty] = useState("")
  const [versionXadisEmpty, setversionXadisEmpty] = useState("")
  const [attributeExist, setAttributeExist] = useState(false)
  const [decrement, setDecrement] = useState("")
  const [enabledbutton, setEnabledbutton] = useState(false)
  const [colorAlert, setcolorAlert] = useState("")
  const [hiddenAlert, sethiddenAlert] = useState("")
  const [messageAlert, setmessageAlert] = useState("")

  const [search, setSearch] = useState("");
  const [filteredproduct, setFilteredProduct] = useState([]);
  const [totalEmptyData, settotalEmptyData] = useState("")

  var subscriptionRemoteRequests = null;
  const [subscriptionId, setSubscriptionId] = useState("");
  const user = store.getState().UserProfileReducer.user
  const [selectedXadisEmptyContainer, setSelectedXadisEmptyContainer] = useState(null)

  const [startDateXadis, setstartDate] = React.useState(new Date());
  const [endDateXadis, setendDate] = React.useState(new Date());
  const [intervalRemoteRequest, setIntervalRemoteRequest] = useState(null)

  const [todoCero, setTodoCero] = useState(-1)

  useEffect(() => {
    if (firstRender.current) {
      if (firstRender.current) {
        firstRender.current = false; // it's no longer the first render

        loadExistensProducts();

        loadProducts();

        return; // skip the code below
      }
    }

    productEmpty && setFilteredProduct(
      productEmpty.filter(
        (product) =>
          product.sku.includes(search) || product.description.includes(search)
      )
    );

    if (todoCero === 0) {
      setcolorAlert("success");
      setmessageAlert(
        "Tu total de envase no contine la cantidad minima de para editar campos, por lo que se generara en 0 "
      );
      console.log(productEmpty)
      productEmpty.forEach((element) => {
        element.boxProduct = 0;
      });
    } else {
      if (totalEmptyData === decrement) {
        setcolorAlert("success");
        setmessageAlert(
          "No hay diferencias en existencias puedes generar tu reporte satisfactoriamente "
        );
      } else if (totalEmptyData < decrement) {
        setcolorAlert("error");
        if (totalEmptyData === "") {
          setmessageAlert(
            "Las existencia y el total de cajas tiene diferencia de: " + decrement
          );
        } else {
          setmessageAlert(
            "Las existencia y el total de cajas tiene diferencia de: " +
            totalEmptyData
          );
        }
      } else if (totalEmptyData > decrement) {
        setcolorAlert("warning");
        var Total = parseFloat(totalEmptyData) - parseFloat(decrement);
        setmessageAlert(
          "Las existencia y el total de cajas tiene diferencia de: " + Total
        );
      }
    }


    if (listXadisEmpty.length > 0) {
      var k = 0;
      const temp = [...productEmpty];
      temp.forEach((a, i) => {
        if (a.sku === listXadisEmpty[i].sku) {
          temp.splice(i, 1, { ...a, ["boxProduct"]: listXadisEmpty[i].quantity });
          let val = parseFloat(listXadisEmpty[i].quantity === "" ? 0 : parseFloat(listXadisEmpty[i].quantity));
          k += val;
        }
      });

      setProductEmpty(temp);
      setlistXadisEmpty([]);
      settotalEmptyData(k)
    }


    return function cleanup() {
      subscriptionRemoteRequests && subscriptionRemoteRequests.unsubscribe();
    };
  }, [search, productEmpty, productInfoEmpty, totalEmptyData, decrement, colorAlert, messageAlert, enabledbutton]); // Only re-run the effect if name or email change



  // const subscribeRemoteRequests = (id) => {
  //   // Subscribe to creation of Todo
  //   subscriptionRemoteRequests = API.graphql(
  //     graphqlOperation(onUpdateRemoteRequest, { id: id })
  //   ).subscribe({
  //     next: (todoData) => {
  //       // Do something with the data
  //       if (todoData && todoData.value && todoData.value.data && todoData.value.data.onUpdateRemoteRequest && todoData.value.data.onUpdateRemoteRequest.response) {
  //         let response = JSON.parse(todoData.value.data.onUpdateRemoteRequest.response);
  //         var filter = response.filter((e) => (e.Existencia !== 0))

  //         let ValuePremium = 0   ///210000;
  //         let ValueNoPremium = 0  //110000;
  //         let caseValue = 0
  //         const temp2 = [...filter];
  //         temp2.forEach((a, i) => {
  //           if (a.ClaArti.trim() === "E0201") { //// PREMIUM
  //             ValuePremium = parseInt(a.Existencia);
  //             //  a.Existencia=ValuePremium
  //           }
  //           if (a.ClaArti.trim() === "E0101") { //NO PREMIUM
  //             ValueNoPremium = parseInt(a.Existencia);
  //             //  a.Existencia=ValueNoPremium
  //             if (ValueNoPremium > 110000) {
  //               caseValue = 1
  //             } else {
  //               let stageTwo = (ValuePremium + ValueNoPremium) - 110000;
  //               if (stageTwo <= 0) {
  //                 caseValue = 3
  //               } else {
  //                 caseValue = 2
  //               }
  //             }
  //           }
  //         });

  //         const temp = [...filter];
  //         temp.forEach((a, i) => {
  //           if (caseValue === 1) {
  //             if (a.ClaArti.trim() === "E0101") {
  //               temp.splice(i, 1, {
  //                 ...a,
  //                 ["Existencia"]: ValueNoPremium - 110000,
  //               });
  //               setTodoCero(1);
  //             }
  //           } else {
  //             if (caseValue === 2) {
  //               if (a.ClaArti.trim() === "E0101") {
  //                 temp.splice(i, 1, { ...a, ["Existencia"]: 0 });
  //               }
  //               if (a.ClaArti.trim() === "E0201") {
  //                 let stageTwo = (ValuePremium + ValueNoPremium) - 110000;
  //                 temp.splice(i, 1, { ...a, ["Existencia"]: stageTwo });
  //               }
  //             }
  //             if (caseValue === 3) {
  //               setTodoCero(0);
  //             }
  //           }
  //         });

  //         setProductInfoEmpty(temp)
  //         if (caseValue !== 3) {
  //           const decrementTotal = temp.reduce(
  //             (totalDecrement, temp) => totalDecrement + temp.Existencia, 0);
  //           setDecrement(decrementTotal);
  //         } else {
  //           setDecrement("");
  //         }
  //         sethiddenAlert("block")

  //         api(deleteRemoteRequest, {
  //           input: {
  //             id: id
  //           }
  //         })
  //       }
  //     }
  //   });
  // }


  const loadProducts = () => {
    // var params = {
    //   filter: {
    //     param: {
    //       eq: "equivalenceEmptyContainer"
    //     }
    //     paramType:{
    //       eq:"xadis"
    //     }
    //   }
    // }
    api(listParams, {limit:1000})
      .then((res) => {

        let filter = res.filter(p => p.param === "equivalenceEmptyContainer")
        JSON.parse(filter[0].value).forEach((itemsProduct) => {
            let ItemEmptyContainer = {
              sap: itemsProduct.sap,
              sku: itemsProduct.sku,
              description: itemsProduct.description,
              stage: todoCero === 0 ? false : true,
              boxProduct: "",//rulo
              stockType: (itemsProduct.description.includes("Agrupador") ? XadisStockType.SELECTABLE : XadisStockType.FREETOUSE),
            }
            setProductEmpty((product) => [...product, ItemEmptyContainer]);
            
        });
        loadProductsEmptyContainer();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadProductsEmptyContainer = () => {
    let customDate =(new Date().getTime() - 1000 * 60 * 60 * 24 )
    let customDateHRS =new Date(customDate).setHours(8)
    let customDateMinutes =new Date(customDateHRS).setMinutes(55)

    if(new Date(customDate).getDate() !== new Date().getDate()  &&  new Date().getHours()  >= 9 ){//// VALIDANDO DIA
      customDate =new Date()
      customDateHRS =new Date(customDate).setHours(9)
      customDateMinutes =new Date(customDateHRS).setMinutes(0)
    }

    let date= new Date(customDateMinutes)
    console.log("DATE",date)
   
    let params = {
      filter: {
        startDate: {
          gt:  parseInt(new Date(date).getTime() / 1000),
        },
        // endDate: {
        //   attributeExists: true,
        // },
      },
    };
    console.log(params)
    api(listXadisEmptyContainers, params)
      .then((res) => {
        setItemXadisEmptyFull(res);
        if (res.length > 0) {
          setstartDate(new Date(res[0].startDate * 1000))
          setversionXadisEmpty(res[0]._version)
          res[0].emptyContainers.forEach((item, index) => {
            setlistXadisEmpty((listXadisEmpty) => [...listXadisEmpty, item]);
          });
          if (res[0].endDate !== null) {
            setEnabledbutton(true);
            setendDate(new Date(res[0].endDate * 1000))
          } else {
            setEnabledbutton(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const UpdateNUllXadisEmptyContainer = (params,UpdateEndDate) => { 
  //   let itEmpty = {
  //     id: ItemXadisEmptyFull[0].id,
  //     emptyContainers: null,
  //     _version:12
  //   };
  //   console.log(itEmpty)
  //   api(updateXadisEmptyContainer, {
  //     input:itEmpty})
  //     .then((res) => {
  //     //  UpdateXadisEmptyContainer(params,UpdateEndDate)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const UpdateXadisEmptyContainer = (params, UpdateEndDate) => {
    let paramsSend = [];
    console.log(params)
    console.log(UpdateEndDate)

    if (UpdateEndDate === false) {
      paramsSend = returUpdXadis(params);
    } else {
      paramsSend = returEndDate(params);
    }
    console.log(paramsSend)
    api(updateXadisEmptyContainer, {
      input: paramsSend
    })
      .then((res) => {
        setversionXadisEmpty(res._version)
        if (UpdateEndDate === true) {
          setEnabledbutton(true)
          setAttributeExist(true);
          props.showMessage(` ${"Se ha generado satisfactoriamente tu reporte."}`, SNACKBAR_SUCCESS, 5000, null, null, null, null, null, " ", props);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const loadExistensProducts = () => {
    let i = 0;
    var params = {
      input: {
        request: "{ \"query\": \"Select t2.NomArti, t2.ClaArti, t3.ClaFam, Existencia = sum(isnull(dbo.InvIniPorFecha(t2.ClaEmp, 'A00', t2.ClaArti,dateadd(day, -1, GETDATE())),0) + isnull(dbo.InvIniPorFecha(t2.ClaEmp, 'B10', t2.ClaArti,dateadd(day, -1, GETDATE())),0)) From Articulo t2 inner join SubFam t3 on t3.ClaSubFam=t2.ClaSubFam and t3.ClaEmp=t2.ClaEmp inner join Marca t7 on t7.ClaMarca=t2.ClaMarca and t7.ClaEmp=t2.ClaEmp inner join corona.dbo.CodigosSAP cs on cs.ClaArti = t2.ClaArti where t2.ClaEmp = 2 and cs.ClaArti like 'E%' Group by t2.NomArti,t2.ClaArti,t3.ClaFam \", \"server\": \"SFORTIA\", \"database\": \"NICANOR_ERP\" }",
        startDate: parseInt(new Date().getTime() / 1000),
        sync: false,
        source: "fortia_erp"

      }
    }
    api(createRemoteRequest, params)
      .then((res) => {
      //  subscribeRemoteRequests(res.id);
        setSubscriptionId(res.id)
      
        var _irr = setInterval(() => {
          api(getRemoteRequest, { id: res.id }).then(_res => {
            console.log(_res)
            if (_res && _res.endDate) {

              let response = JSON.parse(_res.response);
              let filter = response.filter((e) => (e.Existencia !== 0))

              let ValuePremium = 0   ///210000;
              let ValueNoPremium = 0  //110000;
              let caseValue = 0
              const temp2 = [...filter];
              temp2.forEach((a, i) => {
                if (a.ClaArti.trim() === "E0201") { //// PREMIUM
                  ValuePremium = parseInt(a.Existencia);
                  //  a.Existencia=ValuePremium
                }
                if (a.ClaArti.trim() === "E0101") { //NO PREMIUM
                  ValueNoPremium = parseInt(a.Existencia);
                  //  a.Existencia=ValueNoPremium
                  if (ValueNoPremium > 110000) {
                    caseValue = 1
                  } else {
                    let stageTwo = (ValuePremium + ValueNoPremium) - 110000;
                    if (stageTwo <= 0) {
                      caseValue = 3
                    } else {
                      caseValue = 2
                    }
                  }
                }
              });
    
              const temp = [...filter];
              temp.forEach((a, i) => {
                //// CASO UNO ENVASE NO PREMIUM ES MAYOR A 110000
                if (caseValue === 1) {
                  if (a.ClaArti.trim() === "E0101") {
                    temp.splice(i, 1, {
                      ...a,
                      ["Existencia"]: ValueNoPremium - 110000,
                    });
                    setTodoCero(1);
                  }
                } else {
                //// CASO DOS ENVASE NO PREMIUM SEA MENOR A 110000 E0101 = 0
                //// Y ENVASE PREMIUM SE SUMA CON ENVASE NO PREMIUM - 110000 
                  if (caseValue === 2) {
                    if (a.ClaArti.trim() === "E0101") {
                      temp.splice(i, 1, { ...a, ["Existencia"]: 0 });
                    }
                    if (a.ClaArti.trim() === "E0201") {
                      let stageTwo = (ValuePremium + ValueNoPremium) - 110000;
                      temp.splice(i, 1, { ...a, ["Existencia"]: stageTwo });
                    }
                  }
                ////// CASO TRES SI LA SUMA DE ENVASE PREMIUM SE SUMA CON ENVASE NO PREMIUM - 110000 
                ////// ES IGUAL A 0 TODO SE ENVIE EN CERO
                  if (caseValue === 3) {
                    setTodoCero(0);
                  }
                }
              });
            
             const uniqueTemp = [...new Map(temp.map(item => [JSON.stringify(item), item])).values()];
             console.log(uniqueTemp)
             setProductInfoEmpty(uniqueTemp)


            if (caseValue !== 3) {
              const decrementTotal = uniqueTemp.reduce(
                (totalDecrement, temp) => totalDecrement + temp.Existencia, 0);
              setDecrement(decrementTotal);
            } else {
              setDecrement("");
            }
            sethiddenAlert("block")
            
            api(deleteRemoteRequest, {
              input: {
                id: res.id
              }
            })
          
              clearInterval(_irr)
              console.log(_irr, intervalRemoteRequest)
              setIntervalRemoteRequest(null)
            }
          })
        }, 1000)

       // console.log(_irr)
        setIntervalRemoteRequest(_irr)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeBox = (productId, value, productSelect) => {
    const temp = [...productEmpty];
    temp.forEach((a, i) => {
      if (a.sku === productSelect.sku) {
        temp.splice(i, 1, { ...a, [productId]: value });
      }
    });
    setProductEmpty(temp);

    var j = 0;
    temp.forEach((item) => {
      let val = parseFloat(item.boxProduct === "" ? 0 : parseFloat(item.boxProduct));
      j += val;
    })

    if (j < decrement) {
      settotalEmptyData(j - decrement);
    } else {
      if (j === decrement) {
        if (ItemXadisEmptyFull.length > 0) {
          UpdateXadisEmptyContainer(temp, false);
          //UpdateNUllXadisEmptyContainer(temp,false);
        } else {
          CreateXadisEmptyContainer(returStartDate(temp))
        }
      }
      settotalEmptyData(j);
    }
  };


  const handleChangeStock = (productId, value, productSelect) => {
    const temp = [...productEmpty];
    temp.forEach((a, i) => {
      if (a.sku === productSelect.sku) {
        temp.splice(i, 1, { ...a, [productId]: value });
      }
    });
    setProductEmpty(temp);
  };

  const handleSend = () => {
    if (totalEmptyData === decrement) {
      if (todoCero === 0) {
        CreateXadisEmptyContainer(returStartDate(productEmpty))
      } else {
        UpdateXadisEmptyContainer(productEmpty, true);
      }
      // UpdateNUllXadisEmptyContainer(productEmpty,true);
    } else {
      if (todoCero === 0) {
        UpdateXadisEmptyContainer(productEmpty, true);
      } else {
        window.scrollTo(0, 0)
      }
    }
  };


  const CreateXadisEmptyContainer = (params) => {
    setEnabledbutton(true)

    api(createXadisEmptyContainer, { input: params })
      .then((res) => {
        setIdXadisEmpty(res.id)
        setversionXadisEmpty(res._version)
        loadProductsEmptyContainer();
        if (todoCero === 0) {
          props.showMessage(` ${"Se ha generado satisfactoriamente tu reporte."}`, SNACKBAR_SUCCESS, 5000, null, null, null, null, null, " ", props);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const loadHistorical = (date, endDate) => {
    date = new Date(date.toDateString())
    endDate = new Date(endDate.toDateString())

    var params = {
      filter: {
        startDate: {
          between: [parseInt(date.getTime() / 1000), parseInt((endDate.getTime() + 1000 * 60 * 60 * 24) / 1000)]
        },
        endDate: {
          attributeExists: true
        }
      }
    }

    console.log(params)
    api(listXadisEmptyContainers, params)
      .then((res) => {
        setSelectedXadisEmptyContainer(res)
      })
      .catch((err) => {
        console.log(err);
      });
  }



  const handleDateChangeStart = (date) => {
    setstartDate(date);
  };

  const handleDateChangeEnd = (date) => {
    setendDate(date);
  };

   ////////////////// FUNCTIONS //////////////////////////
   function returStartDate(objectXadisProduct) {
    let itemProductXadisEmpty = [];
    objectXadisProduct.forEach((item) => {
      let itEmpty = {
        sku: item.sku,
        quantity: item.boxProduct === "" ? 0 : parseFloat(item.boxProduct),
        stockType: item.stockType,
        sap: item.sap,
      };
      itemProductXadisEmpty = [...itemProductXadisEmpty, itEmpty];
    });
    
    let itEmpty = null
    if (todoCero === 0) {//if (listXadisEmpty.length > 0) {
       itEmpty = {
        emptyContainers: itemProductXadisEmpty,
        startDate: parseInt(new Date().getTime() / 1000),  //// startDate del dia de hoy
        endDate: parseInt(endDateXadis.getTime() / 1000),  ///parseInt(new Date().getTime() / 1000),  //// endDate del dia de hoy
        xadisEmptyContainerAttendantId: user.id
      };
    }else{
       itEmpty = {
        emptyContainers: itemProductXadisEmpty,
        startDate: parseInt(new Date().getTime() / 1000),  //// startDate del dia de hoy
        xadisEmptyContainerAttendantId: user.id
      };
    }
    return (itEmpty)
  }
  function returEndDate(objectXadisProduct) {
    let itemProductXadisEmpty = [];
    objectXadisProduct.forEach((item) => {
      let itEmpty = {
        sku: item.sku,
        quantity: item.boxProduct === "" ? 0 : parseFloat(item.boxProduct),
        stockType: item.stockType,
        sap: item.sap,
      };
      itemProductXadisEmpty = [...itemProductXadisEmpty, itEmpty];
    });
    let itEmpty = {
      id: ItemXadisEmptyFull[0].id,
      _version: versionXadisEmpty,
      emptyContainers: itemProductXadisEmpty,
      xadisEmptyContainerAttendantId: user.id,
      endDate: parseInt(endDateXadis.getTime() / 1000)  ///parseInt(new Date().getTime() / 1000),  //// endDate del dia de hoy
    };
    return (itEmpty)
  }

  function returUpdXadis(objectXadisProduct) {
    let itemProductXadisEmpty = [];
    objectXadisProduct.forEach((item) => {
      let itEmpty = {
        sku: item.sku,
        quantity: item.boxProduct === "" ? 0 : parseFloat(item.boxProduct),
        stockType: item.stockType,
        sap: item.sap,
      };
      itemProductXadisEmpty = [...itemProductXadisEmpty, itEmpty];
    });
    let itEmpty = {
      id: ItemXadisEmptyFull[0].id,
      emptyContainers: itemProductXadisEmpty,
      _version: versionXadisEmpty,
      xadisEmptyContainerAttendantId: user.id,
    };
    return (itEmpty)
  }


  return (
    <Box>
      <Container fixed>
        <Paper className={classes.paper}>
          <Typography variant="h5" color="textSecondary">
            {props.translate("Empty Container")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paperinfo} elevation={0}>
                {/* Container info FORTIA */}
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justify="lefth"
                  style={{ minHeight: "3vh" }}
                >
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" align="center">
                      {props.translate("ClavArt")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" align="center">
                      {props.translate("NomArti")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" align="center">
                      {props.translate("ClavFam")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" align="center">
                      {props.translate("Existence")}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ height: "1px", background: "orange" }} />
                {productInfoEmpty.length <= 0 ? (
                  <LinearProgress color="secondary" />
                ) : (
                    productInfoEmpty.map((productInfo, idx) => (
                      <InfoEmptyFortia key={idx} {...productInfo} />
                    ))
                  )}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3} direction="column">
                <Grid item xs={12}>
                  <Paper className={classes.paperSearch} elevation={0}>
                    {/* Search Box  */}
                    <TextField
                      type="text"
                      fullWidth
                      id="standard-basic"
                      label={props.translate("Search")}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Box display={hiddenAlert === "" ? "none" : "block"}>
                    <Alert variant="filled" severity={colorAlert}>
                      <Typography>
                        {todoCero === 0 ? "" : "Total de existencias FORTIA:" + decrement}
                      </Typography>
                      <Typography> {messageAlert} </Typography>
                    </Alert>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={0}>
                {/* Container header Product*/}
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: "3vh" }}
                >
                  <Grid item xs={2}>
                    <Typography variant="subtitle2" align="center">
                      {props.translate("Sku")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2" align="center">
                      {props.translate("Description")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2" align="center">
                      {props.translate("Sap")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2" align="center">
                      {props.translate("BoxEmpty")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2" align="center">
                      {props.translate("TypeStock")}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ height: "3px", background: "orange" }} />

                {filteredproduct.map((productFilter, idx) => (
                  <Empty
                    key={idx}
                    {...productFilter}
                    {...props}
                    itemProduct={productFilter}
                    handleChangeBox={handleChangeBox}
                    handleChangeStock={handleChangeStock}
                  />
                ))}
              </Paper>
            </Grid>
          </Grid>
          {/* Container Footer */}
          <Grid
            container
            spacing={5}
            direction="row"
            alignItems="center"
            justify="flex-end"
            style={{ minHeight: "4vh", marginTop: "20px" }}
          >
            <Grid item xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    disabled
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Fecha de captura"
                    value={startDateXadis}
                    onChange={handleDateChangeStart}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  {enabledbutton ? (
                    <KeyboardDatePicker
                      disableToolbar
                      disabled
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de envio"
                      value={endDateXadis}
                      onChange={handleDateChangeEnd}
                      autoOk
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  ) : (
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Fecha de envio"
                        value={endDateXadis}
                        onChange={handleDateChangeEnd}
                        autoOk
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    )}
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                disabled={todoCero === 0 ? false : enabledbutton}
                onClick={handleSend}
              >
                Enviar datos
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <HistoricalEmptyContainer
          {...props}
          loadHistorical={loadHistorical}
          productEmpty={productEmpty}
          selectedXadisEmptyContainers={selectedXadisEmptyContainer}
        />
      </Container>
    </Box>
  );
}
export default withSnackbar(EmptyContainer);
