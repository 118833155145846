import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import API from '../../../services/api';
import { Button, Grid, Typography, CircularProgress, Container } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { isEmpty } from 'lodash';
import { reportConstruct } from '../../../helpers';
import Breadcrumbs from '../../../components/Breadcrumbs';
import StepItem from '../../../components/Steps/StepsStack/StepItem/';

import { isSendingCommision } from '../../../store/reports/selectors';
import { runCommission } from '../../../store/reports/actions';
import {
  EXCEPTIONS,
  ZERO_DIVISION,
  DICTIONARY_INDEX_NOT_FOUND,
  INVALID_INDICATOR_VARIABLE_KEY,
  INDICATOR_NOT_FOUND,
  HELPER_FUNCTION_REMOTE_ID_NOT_FOUND,
  INVALID_SYNTAX,
} from '../exceptions';

import './styles.scss';

export class ReportDetail extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      evaluation: '',
      error: false,
      showLog: false
    };
  }

  getEvaluation = async reportId => {
    try {
      const response = await API.getEmployeeReportEvaluation(reportId);
      let { data } = response;
      this.setState({ evaluation: data.report, error: false });
    } catch (error) {
      this.setState({ error: true });
    }
  };

  async componentDidMount() {
    const {
      params,
    } = this.props;
    this.getEvaluation(params.reportId);
  }

  render() {
    const { evaluation, error } = this.state;
    let steps;
    let exception;
    let exception_description;
    if (typeof evaluation.details !== 'undefined') {
      if (evaluation.exception === null) {
        exception = null;
        steps = reportConstruct(
          evaluation.details.html_reference,
          evaluation.details.evaluation_log,
        );
      } else {
        exception = evaluation.exception;
        exception_description = this.getExceptionDescription();
      }
    }
    return (
      <Container fixed>
        <div className={'report-detail-wrapper'}>
          <div className="breadcrumbs-container-wrapper">
            <Breadcrumbs {...this.props}
              navigation={[
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                {
                  title: 'Buscar reportes',
                  url: '/prometheus/variableCommissions/reportes',
                },
                {
                  title: 'Lista de reportes',
                  url: `/prometheus/variableCommissions/reportes/${evaluation.employee_id}`,
                },
                {
                  title: 'Reporte',
                  current: true,
                },
              ]}
            />
          </div>
          {error === true ? (
            <div className={'error-msg'}>No se encontro el reporte</div>
          ) : isEmpty(evaluation) ? (
            <LoadingIndicator />
          ) : exception !== null ? (
            <div className={'report-exeption'}>
              <Typography variant="h5" gutterBottom>
                Se detectó el siguiente error al ejecutar el cálculo de la comisión variable:
            </Typography>
              <Typography variant="h6" gutterBottom>
                Tipo: <strong>{exception_description.description}</strong>
              </Typography>
              <Typography variant="h6" gutterBottom>
                Descripción:
            </Typography>

              <div className={'errror-details'}>{exception_description.reason}</div>
              {/* <Typography variant="h6" gutterBottom>
              Log de error:
            </Typography>
            <div>{exception.details}</div> */}
              {/* handleChange('panel1') */}
              <ExpansionPanel className={'collapsable'} expanded={this.state.showLog} onChange={event => this.setState({ showLog: !this.state.showLog })}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography >Log de error</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div>{exception.details}</div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <Grid className={'btn-wrapper'} container spacing={0}>
                <Grid item xs={6}>
                  <Button
                    className={'btn cancel-btn'}
                    component={Link}
                    to={`/prometheus/variableCommissions/reportes/${evaluation.employee_id}`}
                  >
                    Cancelar
                </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={'btn accept-btn'}
                    variant="contained"
                    component={Link}
                    to={`/prometheus/variableCommissions/comisiones-variables/detalle/${evaluation.variable_commission_id}`}
                  >
                    Ir a la comisión variable
                </Button>
                  {this.props.isSendingCommision ? (
                    <Button
                      className={'btn run-btn'}
                      variant="contained"
                      disabled={true}
                    >
                      <CircularProgress className={'loading'} />
                    </Button>
                  ) : (
                      <Button
                        className={'btn run-btn'}
                        variant="outlined"
                        onClick={() =>
                          this.props.runCommission({
                            start_date: evaluation.start_date,
                            end_date: evaluation.end_date,
                            employee_id: evaluation.employee_id,
                            variable_commission_id:
                              evaluation.variable_commission_id,
                          })
                        }
                      >
                        Reintentar comisión variable
                      </Button>
                    )}
                </Grid>
              </Grid>
            </div>
          ) : (
                  <Grid container spacing={3} className={'report-detail'}>
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom>
                        Reporte {evaluation.variable_commission_name} - Creado el{' '}
                        {evaluation.start_date}
                      </Typography>
                      <div className={'report-container'}>
                        <div className={'steps-container'}>
                          {steps !== '' &&
                            steps.map((step, index) => (
                              <StepItem
                                key={index}
                                step={index}
                                elements={step}
                                report={true}
                              />
                            ))}
                        </div>
                        <div className={'evaluation-result-container'}>
                          <div className={'evaluation-result'}>
                            <label>
                              Perido seleccionado:
                      <br />
                              {evaluation.start_date} - {evaluation.end_date}
                            </label>
                            <label id={'result-number'}>
                              {Number(evaluation.evaluation_result).toFixed(2)}
                            </label>
                          </div>
                        </div>
                      </div>
                      <Grid className={'btn-wrapper'} container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                            className={'btn accept-btn'}
                            variant="contained"
                            onClick={() => window.print()}
                          >
                            Imprimir
                  </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            className={'btn cancel-btn'}
                            component={Link}
                            to={`/prometheus/variableCommissions/reportes/${evaluation.employee_id}`}
                          >
                            Cancelar
                  </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
        </div>
      </Container>
    );
  }

  getExceptionDescription = () => {
    const { evaluation } = this.state;
    let { name } = evaluation.exception
    switch (name) {
      case ZERO_DIVISION:
        return EXCEPTIONS.ZERO_DIVISION
      case DICTIONARY_INDEX_NOT_FOUND:
        return EXCEPTIONS.DICTIONARY_INDEX_NOT_FOUND
      case INVALID_INDICATOR_VARIABLE_KEY:
        return EXCEPTIONS.INVALID_INDICATOR_VARIABLE_KEY
      case INDICATOR_NOT_FOUND:
        return EXCEPTIONS.INDICATOR_NOT_FOUND
      case HELPER_FUNCTION_REMOTE_ID_NOT_FOUND:
        return EXCEPTIONS.HELPER_FUNCTION_REMOTE_ID_NOT_FOUND
      case INVALID_SYNTAX:
        return EXCEPTIONS.INVALID_SYNTAX
      default:
        return null
    }
  }
}

const mapStateToProps = state => ({
  isSendingCommision: isSendingCommision(state),
});

const mapDispatchToProps = dispatch => ({
  runCommission: payload => dispatch(runCommission(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);
