import React , { Component } from 'react';
import { APIBAMASTAGE } from "../../../env/constants";
import { CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SessionControl from '../../../utils/SessionControl';


export default class  InitSearchDataSource extends SessionControl  {

    constructor(props) {
        super(props);
        this.state = {
            open: true,
            value: '',
            organizationId: '',
            organizations:[],
            user: this.getSecureStore().UserProfileReducer &&
            this.getSecureStore().UserProfileReducer.user
            ? this.getSecureStore().UserProfileReducer.user
            : [],

            readyOrg: false
        };
    }

    componentDidMount = () => {
        // fetch(`${APIBAMASTAGE}/organization`)
        // .then(response => response.json())
        // .then(data => {      
        //     this.setState({
        //         organizations: data.organization,
        //         readyOrg: true
        //     });
        // })
        const { paramProfile, position } = this.state.user;
        let listPermisionOrganization = JSON.parse(JSON.parse(paramProfile))
        fetch(`${APIBAMASTAGE}/organization`)
          .then(response => response.json())
          .then(data => {
            let listOrganization =[]
            /// ** FILTRO POR EMPRESA QUE NO PUEDA ACCEDER
            data && data.organization.forEach(organization => {
              listPermisionOrganization.filter(r=> r.uuid === position.id).forEach(_e => {

                _e.organization.forEach(_a => {
                  if(_a.uuid === organization.id){
                    listOrganization.push(organization)
                  }
                });
                
              });
            });
    
            this.setState({
              organizations: listOrganization,
              readyOrg: true
            });
          })
          .catch(error => { console.log(error) });
    };

    handleSelectVariable = (value) => {
        this.props.VariableCommissions_onOrganization(value)
        this.setState({
            organizationId: value.id
        });
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }


    render (){

        const { open , organizations , readyOrg , organizationId } = this.state;

        return (
            <Dialog fullScreen open={open}  closeAfterTransition>
                <Fade in={open}>
                <div className={'dialog-content'}>
                    <h2 id="transition-modal-title">Selecciona una empresa</h2>
                    <Grid className={'btn-wrapper'} container spacing={3}>
                        <Grid item xs={12} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <Box>
                                    { readyOrg  ? ( 
                                        organizations.length > 0 ? (
                                            <div className={'select-wrapper'}>
                                                <Autocomplete
                                                    id="combo-e1"
                                                    onChange={(event , value) => this.handleSelectVariable(value) } 
                                                    options={organizations}
                                                    getOptionLabel={option => option.name}
                                                    style={{ width: 300 }}
                                                    renderInput={params => <TextField {...params} label="Empresas" variant="outlined" style={{ width: 300 }} />}
                                                />
                                            </div>
                                            ) : ( 
                                                <span className={'error-txt'}>
                                                    Error: No se encotraron resultados
                                                </span>
                                            ) 
                                    ) : ( <CircularProgress/> ) }
                                    </Box>
                                </Box> 
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                className={'btn accept-btn'}
                                component={Link}
                                to={`lista/${organizationId}`}
                                variant="contained"
                                disabled={ organizationId.length > 0  ? false : true}
                            >
                                ACEPTAR
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/prometheus/variableCommissions">
                                <Button className={'btn cancel-btn'} onClick={this.handleClose}>
                                    Cancelar
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                </Fade>
            </Dialog>
        );
    }

}
