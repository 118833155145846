import React from 'react';
import { Box, Container, Typography, InputAdornment, IconButton, LinearProgress, Grid, Button, Dialog, AppBar, Toolbar, Slide, Link, TextField, Card, CardContent } from "@material-ui/core";

import FileCopyIcon from '@material-ui/icons/FileCopy';
import EmailIcon from '@material-ui/icons/Email';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PhoneIcon from '@material-ui/icons/Phone';
import GavelIcon from '@material-ui/icons/Gavel';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import ImageFormS3 from '../image/ImageFormS3';
import { FileType } from '../../schema/enums/schemaEnums';

export default function CardProfile(props) {
    return (
        <Grid container
            key={props.customer.id}
            spacing={3}
            justify="center">
            {/* Grid del picture profile */}
            {/* <Grid item xs={12} sm={3}>
                {props.customer.files && props.customer.files.map((f, i) => {
                    if (f.fileType === FileType.PICTURE_PROFILE)
                        return <ImageFormS3
                            key={`file-${i}`}
                            heigth={'20em'}
                            s3Key={f.fileName}
                        />
                })}
            </Grid> */}
            <Grid item xs>
                <Card>
                    <CardContent>
                        <Grid container
                            spacing={1}
                            alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {props.customer.customerName}
                                </Typography>
                            </Grid>
                            {/* Enrollment Date */}
                            <Grid item xs={1}>
                                <ScheduleIcon color="action" />
                            </Grid>
                            <Grid item xs={3}>
                                <b>{`${props.translate("Enrollment Date")}: `}</b>
                            </Grid>
                            <Grid item xs={8}>
                                {new Date(props.customer._lastChangedAt).toLocaleString()}
                            </Grid>
                            {/* Address */}
                            {props.customer.address != null ?
                                <Grid container>
                                    <Grid item xs={1}>
                                        <HomeIcon color="action" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <b>{`${props.translate("Address")}: `}</b>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {props.customer.address}
                                    </Grid>
                                </Grid>
                                : ''}
                            {/* Preregistration Code */}
                            <Grid item xs={1}>
                                <CodeIcon color="action" />
                            </Grid>
                            <Grid item xs={3}>
                                <b>{`${props.translate("Preregistration Code")}: `}</b>
                            </Grid>
                            <Grid item xs={8}>
                                {props.customer.preregistrationCode}
                            </Grid>
                            {/* Activation */}
                            <Grid item xs={1}>
                                <LocalOfferIcon color="action" />
                            </Grid>
                            <Grid item xs={3}>
                                <b>{`${props.translate("Activation")}: `}</b>
                            </Grid>
                            <Grid item xs={8}>
                                {props.customer.sellBanned ?
                                    props.translate("Sell Banned")
                                    : props.translate("Ready to Sale")}
                            </Grid>
                            {/* Taxpayer Info */}
                            {props.customer.taxpayer != null ?
                                <Grid container>
                                    <Grid item xs={1}>
                                        <AccountBalanceWalletIcon color="action" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <b>{`${props.translate("Taxpayer Info")}: `}</b>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {props.customer.taxpayer ? <Box>
                                            {`${props.customer.taxpayer.identification} ${props.customer.taxpayer.taxpayerName}`}
                                        </Box>
                                            : ''}
                                    </Grid>
                                </Grid>
                                : ''}
                            {/* Email */}
                            {props.customer.emails.length !== 0 ?
                                <Grid container>
                                    <Grid item xs={1}>
                                        <EmailIcon color="action" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <b>{`${props.translate("Email")}: `}</b>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {props.customer.emails && props.customer.emails.map((e, i) => {
                                            return <Box key={`email-${i}`}>
                                                {e}
                                            </Box>
                                        })}
                                    </Grid>
                                </Grid>
                                : ''}
                            {/* Phone */}
                            <Grid item xs={1}>
                                <PhoneIcon color="action" />
                            </Grid>
                            <Grid item xs={3}>
                                {props.customer.phones && props.customer.phones.map((e, i) => {
                                    return <Box key={`phoneType-${i}`}>
                                        <b> {props.translate(e.phoneType)}</b>
                                    </Box>
                                })}
                            </Grid>
                            <Grid item xs={8}>
                                {props.customer.phones && props.customer.phones.map((e, i) => {
                                    return <Box key={`phone-${i}`}>
                                        {e.phone}
                                    </Box>
                                })}
                            </Grid>
                            <Grid item xs={1}>
                                <GavelIcon color="action" />
                            </Grid>
                            <Grid item xs={3}>
                                {props.customer.legals && props.customer.legals.map((l, i) => {
                                    return <Box key={`legal-${i}`}>
                                        <b>{props.translate(l.legalType)}</b>
                                    </Box>
                                })}
                            </Grid>
                            <Grid item xs={8}>
                                {props.customer.legals && props.customer.legals.map((l, i) => {
                                    return <Box key={`legal-${i}`}>
                                        {`${props.translate("Acceptd at")} ${new Date(l.datetime * 1000).toLocaleString()}`}
                                    </Box>
                                })}
                            </Grid>
                            {/* Poner en true para que se muestre la identificación */}
                            {false ? <Grid container><Grid item xs={1}>
                                <FileCopyIcon color="action" />
                            </Grid>
                                <Grid item xs={3}>
                                    {props.customer.files && props.customer.files.map((f, i) => {
                                        if (f.fileType !== FileType.PICTURE_PROFILE)
                                            return <Box key={`file-${i}`}>
                                                <b>{props.translate(f.fileType)}</b>
                                            </Box>
                                    })}
                                </Grid>
                                <Grid item xs={8}>
                                    {props.customer.files && props.customer.files.map((f, i) => {
                                        if (f.fileType !== FileType.PICTURE_PROFILE)
                                            return <Box key={`file-${i}`}>
                                                <ImageFormS3
                                                    heigth={'5em'}
                                                    style={{ maxWidth: "100px" }}
                                                    s3Key={f.fileName}
                                                />
                                            </Box>
                                    })}
                                </Grid></Grid> : ""}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    )
}