import { createAction } from 'redux-actions';
import {
  SET_REPORTS_ORGANIZATION,
  SET_REPORTS_AREA,
  SET_REPORTS_EMPLOYEE,
  FETCH_REPORTS_EMPLOYEE,
  SET_EMPLOYEE_REPORTS,
  SET_EMPLOYEE_REPORTS_ERROR,
  RUN_COMMISSION,
  SET_COMMISION_SENDING,
  RESET_EMPLOYEE_REPORTS_DATA,
  SET_REPORTS_BY_ORGANIZATION,
  FETCH_REPORTS_BY_ORGANIZATION,
} from './constants';

export const setReportsOrganization = createAction(SET_REPORTS_ORGANIZATION);
export const setReportsArea = createAction(SET_REPORTS_AREA);
export const setReportsEmployee = createAction(SET_REPORTS_EMPLOYEE);
export const fetchReportsEmployee = createAction(FETCH_REPORTS_EMPLOYEE);
export const setEmployeeReports = createAction(SET_EMPLOYEE_REPORTS);
export const setEmployeeReportsError = createAction(SET_EMPLOYEE_REPORTS_ERROR);
export const runCommission = createAction(RUN_COMMISSION);
export const setCommisionsending = createAction(SET_COMMISION_SENDING);
export const resetReporsEmployeeData = createAction(
  RESET_EMPLOYEE_REPORTS_DATA,
);
export const fetchReportsOrganization = createAction(FETCH_REPORTS_BY_ORGANIZATION);
export const setReportsByOrganization = createAction(SET_REPORTS_BY_ORGANIZATION);