import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AddFunction(props) {
  return (
    <div className={'select-wrapper'}>
      {props.functions.length > 0 && (
        <div className={'select-wrapper'}>
          <Autocomplete
            className={'selector'}
            id="combo-box-demo"
            options={props.functions}
            getOptionLabel={data => data.name}
            renderInput={params => (
              <TextField
                {...params}
                label="Función"
                variant="outlined"
                className={'selector'}
              />
            )}
            onChange={(event, newValue) => props.setValue(newValue)}
          />
        </div>
      )}
    </div>
  );
}
