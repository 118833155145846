import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';

import {
  SET_KPI_NAME,
  SET_KPI_ORGANIZATION,
  SET_KPI_AREA,
  SET_KPI_TIME,
  CREATE_KPI_STEP,
  PUSH_ELEMENT_KPI_STEP,
  PUSH_ELEMENT_KPI_DICTIONARY,
  SET_TEST_KPI,
  SET_SAVE_KPI,
  RESET_KPI_DATA,
  PUSH_FUNCTION_ELEMENT_KPI,
  SET_LAST_FUNCTION_KPI_VALIDATION,
  DICTIONARY,
  SET_KPI_SENDING,
  POP_ELEMENT_DICTIONARY_KPI,
  POP_ALL_FUNCTION_ARGUMENTS_KPI_DICTIONARY,
  POP_ELEMENT_KPI,
  POP_FUNCTION_ARGUMENT_ELEMENT_KPI,
  REPLACE_KPI_ELEMENT,
  REHYDRATE_KPI,
  RESET_STEPS_KPI,
  SET_ACTIVE_KPI,
  SET_KPI_DELETING,
  REPLACE_FUNCTION_ARGUMENT_ELEMENT_KPI,
  SET_NO_EXIST_IDS_KPI,
} from './constants';

const setName = (state, action) => ({
  ...state,
  name: action.payload,
});

const setOrganization = (state, action) => ({
  ...state,
  organization: action.payload,
});

const setArea = (state, action) => ({
  ...state,
  area: action.payload,
});

const setTime = (state, action) => ({
  ...state,
  time: action.payload,
});

const createKPIStep = (state, payload) => ({
  ...state,
  steps: [...state.steps, []],
});

const pushElementKPIStep = (state, action) => {
  const steps = [...state.steps];
  steps[action.payload.step] = [
    ...state.steps[action.payload.step],
    action.payload.object,
  ];
  return { ...state, steps };
};

const pushFunctionElementKPI = (state, action) => {
  const stepIndex = action.payload.stepIndex;
  const elementFunction = action.payload.object;
  const steps = [...state.steps];
  const stepElements = [...steps[action.payload.step]];

  stepElements.map((element, index) => {
    if (index === stepIndex) {
      return element.functionArguments.push(elementFunction);
    }
    return null;
  });

  return { ...state, stepElements };
};

const pushElementKPIDictionary = (state, action) => ({
  ...state,
  [DICTIONARY]: {
    ...state.dictionary,
    [action.payload.type]: [
      ...state.dictionary[action.payload.type],
      action.payload.value,
    ],
  },
});

const setTestKPIResult = (state, action) => ({
  ...state,
  tested: action.payload,
});

const setSavedKPI = (state, action) => ({
  ...state,
  saved: action.payload,
});

const setLastFunctionValidation = (state, action) => ({
  ...state,
  isLastFunctionValid: action.payload,
});

const setKPISending = (state, action) => ({
  ...state,
  sending: action.payload,
});

const popElementDictionary = (state, action) => {
  const dictionary = state.dictionary;
  const dictionaryElements = [...dictionary[action.payload.type]];
  const newDictionaryElements = dictionaryElements.filter(
    e => e.compiler_tag !== action.payload.name,
  );
  dictionary[action.payload.type] = newDictionaryElements;
  return { ...state, dictionary };
};

const popAllFuctionArgumentsDictionary = (state, action) => {
  let dictionaryElements;
  let type;
  let newDictionaryElements;

  const dictionary = state.dictionary;
  const stepNumber = action.payload.step;
  const stepIndex = action.payload.index;
  const steps = state.steps;
  const element = steps[stepNumber][stepIndex];

  element.functionArguments.forEach(element => {
    switch (element.value.selected) {
      case 'parameter':
        type = 'parameters';
        break;
      case 'areaIndicators':
        type = 'indicators';
        break;
      case 'function':
        type = 'functions';
        break;
      default:
        type = '';
        break;
    }
    dictionaryElements = [...dictionary[type]];
    newDictionaryElements = dictionaryElements.filter(
      e => e.compiler_tag !== element.name,
    );
    dictionary[type] = newDictionaryElements;
  });
  return { ...state, dictionary };
};

const popElement = (state, action) => {
  const steps = [...state.steps];
  steps[action.payload.step].splice(action.payload.index, 1);
  return { ...state, steps };
};

const popFunctionArgument = (state, action) => {
  const steps = [...state.steps];
  steps[action.payload.step][action.payload.index].functionArguments.splice(
    action.payload.functionElementIndex,
    1,
  );
  return { ...state, steps };
};

const replaceElement = (state, action) => {
  const element = action.payload.element.object;
  const stepNumber = action.payload.editData.step;
  const stepIndex = action.payload.editData.index;
  const steps = [...state.steps];
  steps[stepNumber][stepIndex] = element;
  return { ...state, steps };
};

const replaceFunctionArgumentElementKPI = (state, action) => {
  const element = action.payload.elementFunction.object;
  const stepNumber = action.payload.editData.step;
  const stepIndex = action.payload.editData.index;
  const functionElementIndex = action.payload.editData.functionElementIndex;
  const steps = [...state.steps];
  const functionObj = steps[stepNumber][stepIndex];

  functionObj.functionArguments[functionElementIndex] = element;
  return { ...state, steps };
};

const rehydrateKPI = (state, action) => ({
  ...state,
  name: action.payload.name,
  steps: action.payload.steps,
  area: action.payload.area,
  time: action.payload.time,
  dictionary: {
    parameters: action.payload.parameters,
    indicators: action.payload.indicators,
    functions: action.payload.functions,
  },
});

const resetStepsKPI = (state, action) => ({
  ...state,
  steps: [[]],
  dictionary: { parameters: [], indicators: [], functions: [] },
  noExistingIds: [],
});

const setActiveKPI = (state, action) => ({
  ...state,
  active: action.payload,
});

const setDeleting = (state, action) => ({
  ...state,
  isDeleting: action.payload,
});

const setNoExisingIds = (state, action) => ({
  ...state,
  noExistingIds: action.payload,
});

const resetKPIData = () => INITIAL_STATE;

export const reducer = handleActions(
  {
    [SET_KPI_NAME]: setName,
    [SET_KPI_ORGANIZATION]: setOrganization,
    [SET_KPI_AREA]: setArea,
    [SET_KPI_TIME]: setTime,
    [CREATE_KPI_STEP]: createKPIStep,
    [PUSH_ELEMENT_KPI_STEP]: pushElementKPIStep,
    [PUSH_FUNCTION_ELEMENT_KPI]: pushFunctionElementKPI,
    [PUSH_ELEMENT_KPI_DICTIONARY]: pushElementKPIDictionary,
    [SET_TEST_KPI]: setTestKPIResult,
    [SET_SAVE_KPI]: setSavedKPI,
    [RESET_KPI_DATA]: resetKPIData,
    [SET_LAST_FUNCTION_KPI_VALIDATION]: setLastFunctionValidation,
    [SET_KPI_SENDING]: setKPISending,
    [POP_ELEMENT_DICTIONARY_KPI]: popElementDictionary,
    [POP_ALL_FUNCTION_ARGUMENTS_KPI_DICTIONARY]: popAllFuctionArgumentsDictionary,
    [POP_ELEMENT_KPI]: popElement,
    [POP_FUNCTION_ARGUMENT_ELEMENT_KPI]: popFunctionArgument,
    [REPLACE_KPI_ELEMENT]: replaceElement,
    [REHYDRATE_KPI]: rehydrateKPI,
    [RESET_STEPS_KPI]: resetStepsKPI,
    [SET_ACTIVE_KPI]: setActiveKPI,
    [SET_KPI_DELETING]: setDeleting,
    [REPLACE_FUNCTION_ARGUMENT_ELEMENT_KPI]: replaceFunctionArgumentElementKPI,
    [SET_NO_EXIST_IDS_KPI]: setNoExisingIds,
  },
  INITIAL_STATE,
);
