import React, { useState, useEffect, useRef } from 'react';
import { withSnackbar } from 'notistack';
import { api } from '../../api/api';
import { listWorkplans, listWorkplanSections } from '../../graphql/queries';
import MaterialTable from 'material-table';
import reactHtmlParse from 'react-html-parser';
import AddWorkPlan from './AddWorkPlan';
import ShowSections from './ShowSections';
import { Button, Box, Grid, Container, LinearProgress, Paper, Fab, makeStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import { SNACKBAR_ERROR, SNACKBAR_INFO, SNACKBAR_SUCCESS } from '../../utils/SessionControl';
import { createWorkplan, updateWorkplan } from '../../graphql/mutations';

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const WorkPlans = (props) => {
    const firstRender = useRef(true);
    const clasess = useStyles();
    const [showLoading, setShowLoading] = useState(false);
    const [showSection, setShowSection] = useState(false);
    const [showAddWorkPlan, setShowAddWorkPlan] = useState(false);
    const [isNewWorkPlan, setIsNewWorkPlan] = useState(false)
    const [listActiv, setListActiv] = useState([]);
    const [currentLWP, setCurrentLWP] = useState({
        cod: 0,
        echo: true,
        desc: 'Descripción por defecto...',
        msg: 'Mensaje por defecto...',
        data: []
    });
    const [currentSection, setCurrentSection] = useState([]);
    const [currentWorkPlanId, setCurrentWorkPlanId] = useState({});
    const [editWorkPlan, setEditWorkPlan] = useState({
        workplanName: '',
        description: ''
    })

    const columns = [
        {
            field: 'workplanName',
            title: props.translate('workplan'),
        },
        {
            field: 'description',
            title: props.translate('Description'),
            render: (el => {
                let desc = JSON.parse(el.description)
                return reactHtmlParse(desc.DescrtiptionWorkplan)
            }),
        },
        {
            field: 'createdAt',
            title: props.translate('Create date'),
            type: 'date',
        },
        {
            field: 'updatedAt',
            title: props.translate('Updated'),
            type: 'date'
        }
    ];
    const actions = [
        {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (evt, rowData) => {
                setEditWorkPlan(rowData);
                setIsNewWorkPlan(false);
                setShowAddWorkPlan(true);
            }
        },
        {
            icon: VisibilityIcon,
            tooltip: 'Ver Secciones',
            onClick: (evt, rowData) => {
                getSectionInfo(rowData.id).then(res => {
                    setCurrentSection(res.data)
                    setCurrentWorkPlanId(rowData)
                }).finally(() => {
                    setShowSection(true)
                });
            }
        }
    ];
    const location = {
        header: {
            actions: props.translate('Actions')
        },
        pagination: {
            labelDisplayedRows: `{from}-{to} ${props.translate('of')} {count}`,
            labelRowsSelect: props.translate('Rows'),
            labelRowsPerPage: props.translate('Rows per page'),
            firstAriaLabel: props.translate('First Page'),
            firstTooltip: props.translate('First Page'),
            previousAriaLabel: props.translate('Previous Page'),
            previousTooltip: props.translate('Previous Page'),
            nextAriaLabel: props.translate('Next Page'),
            nextTooltip: props.translate('Next Page'),
            lastAriaLabel: props.translate('Last Page'),
            lastTooltip: props.translate('Last Page')
        }
    }

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            loadWorkPlans().then(dat => {
                setCurrentLWP(dat);
            })
            return
        }
        const cleanup = () => { }
        return cleanup
    }, [currentLWP, currentSection, editWorkPlan, currentWorkPlanId]);

    const loadWorkPlans = async () => {
        setShowLoading(true);
        let data = {
            cod: 0,
            desc: 'success',
            msg: 'operación realizada con éxito',
            data: []
        }
        await api(listWorkplans, { limit: 300 }).then(lwp => {
            data.data = lwp
        }).catch(err => {
            console.log('Error al intentar optener la lista de actividades...', err)
            props.showMessage('Error al consultar los planes de trabajo...', SNACKBAR_ERROR, null, null, null, null, null, null, null, props);
            data.cod = 1
            data.desc = 'Error'
            data.msg = 'Error al intentar realizar la consulta'
            data.data = []
        }).finally(() => {
            setShowLoading(false)
        })
        return data
    }

    const handleAgregarPlan = (evt) => {
        setIsNewWorkPlan(true);
        setShowAddWorkPlan(true);
    }

    const handleCloseAddWorkPlan = () => {
        setEditWorkPlan({
            workplanName: '',
            description: ''
        })
        setShowAddWorkPlan(false)
    }

    const handleCloseShowSection = () => {
        setShowSection(false);
        setCurrentSection([]);
    }

    const getSectionInfo = async (idSection) => {
        setShowLoading(true)
        let data = {...currentLWP}
        await api(listWorkplanSections, { limit: 1 },).then(sect => {
            if (sect.length == undefined || sect.length == 0) {
                data.echo = 1;
                data.msg = 'Sin Datos';
                data.desc = 'La consulta se realizó con éxito pero no devolvió resultados...';
            }
            else {
                data.data = sect.filter((el, ide) => el.workplan.id == idSection);
                data.msg = 'Éxito!';
                data.desc = 'La consulta se realizó con éxito...'
            }
        }).catch(err => {
            console.log('Error al intentar optener la lista de actividades...', err)
            props.showMessage('Error al consultar la lista de actividades...', SNACKBAR_ERROR, null, null, null, null, null, null, null, props)
            data.cod = 2
            data.desc = err
            data.msg = 'Error al intentar realizar la consulta'
            data.data = []
        }).finally(() => {
            setShowLoading(false);
        })
        return data
    }

    const handleSaveWorkPlan = async (newData = { id: '', description: '', workplanName: '', _version: 0 }) => {
        setShowLoading(true);
        let data = {
            cod: 0,
            desc: 'success',
            msg: 'operación realizada con éxito',
            data: []
        }
        if (isNewWorkPlan) {
            // agregar workPlan
            let setParams = {
                workplanName: newData.workplanName,
                description: JSON.stringify(newData.description)
            }
            await api(createWorkplan, { input: setParams }).then(res => {
                // console.log('res add WP', res)
                props.showMessage('WP agregado!...', SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props)
            }).catch(err => {
                console.log('error al intentar agregar WP: ', err)
                props.showMessage('error al intentar agregar WP...', SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props)
            }).finally(() => {
                setEditWorkPlan({
                    workplanName: '',
                    description: ''
                })
                setShowAddWorkPlan(false)
                loadWorkPlans().then(dat => {
                    setCurrentLWP(dat);
                })
                setShowLoading(false);
            })
        } else {
            // cambios al workplan
            let setParams = {
                id: newData.id,
                workplanName: newData.workplanName,
                description: JSON.stringify(newData.description),
                _version: newData._version
            }
            setParams._version = newData._version
            await api(updateWorkplan, { input: setParams }).then(res => {
                // console.log('update: ', res);
                props.showMessage('WP modificado!...', SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props)
            }).catch(err => {
                console.log('error update: ', err)
                props.showMessage('Error al intentar actualizar el WP!...', SNACKBAR_ERROR, null, null, null, null, null, null, null, props)
            }).finally(() => {
                setEditWorkPlan({
                    workplanName: '',
                    description: '',
                    _version: 0
                })
                setShowAddWorkPlan(false);
                loadWorkPlans().then(dat => {
                    setCurrentLWP(dat);
                })
                setShowLoading(false);
            })
        }
    }

    return (
        <>
            {/* showLoading ? <LinearProgress color='secondary' /> : '' */}
            <Box display='flex' justifyContent='right' alignItems='right' flexDirection='row' m={1} minWidth={'99%'} >
                <Container>
                    <Grid container spacing={2} >
                        <Grid item xs={12} >
                            <Paper elevation={3} variant='outlined' >
                                <MaterialTable title='Planes de Trabajo' isLoading={showLoading} data={currentLWP.data} columns={columns} actions={actions} options={{ actionsColumnIndex: -1 }} localization={location} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <AddWorkPlan open={showAddWorkPlan} onClose={handleCloseAddWorkPlan} onSave={handleSaveWorkPlan} data={editWorkPlan} translate={props.translate} />
            <ShowSections open={showSection} onClose={handleCloseShowSection} sections={currentSection} workplanId={currentWorkPlanId} translate={props.translate} showMessage={props.showMessage} tableLocation={location} />

            <Fab color='primary' size='small' aria-label='add' className={clasess.fab} >
                <AddIcon onClick={handleAgregarPlan} />
            </Fab>
        </>
    )
}

export default withSnackbar(WorkPlans)