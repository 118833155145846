import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  FormControl,
  FormGroup,
  TextField,
} from '@material-ui/core';
import { getModalSelectorFatherScreen } from '../../../store/modalSelector/selectors';
import { setModalselectorShow } from '../../../store/modalSelector/actions';

import { setCreateFunctionParameter } from '../../../store/createFunctions/actions';

export class ModalSelectorFunctionParameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onFormChange = e => {
    this.setState({
      ...this.state,
      value: `${e.target.value}`,
    });
  };

  setValue() {
    const { screen, modalasSelectorShow, setParameter } = this.props;
    const { value } = this.state;

    switch (screen) {
      case 'createFunctions':
        setParameter(value);
        break;

      default:
        break;
    }
    modalasSelectorShow(false);
  }

  onSubmit = event => {
    event.preventDefault();
  };

  render() {
    const { modalasSelectorShow } = this.props;
    return (
      <div>
        <h2 id="transition-modal-title">Define el nombre del párametro:</h2>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form
              className=""
              onChange={this.onFormChange}
              onSubmit={this.onSubmit}
            >
              <FormControl>
                <FormGroup>
                  <TextField
                    className=""
                    type="text"
                    name="item"
                    variant="outlined"
                    placeholder="Nombre"
                  />
                </FormGroup>
              </FormControl>
            </form>
          </Grid>
          <Grid className={'btn-wrapper'} container spacing={3}>
            <Grid item xs={12}>
              <Button
                className={'btn accept-btn'}
                variant="contained"
                onClick={() => this.setValue()}
              >
                Aceptar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={'btn cancel-btn'}
                onClick={() => modalasSelectorShow(false)}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  screen: getModalSelectorFatherScreen(state),
});

const mapDispatchToProps = dispatch => ({
  setParameter: payload => dispatch(setCreateFunctionParameter(payload)),
  modalasSelectorShow: payload => dispatch(setModalselectorShow(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalSelectorFunctionParameter);
