export const SET_CREATEFUNCTION_NAME = 'createFunction/SET_CREATEFUNCTION_NAME';
export const SET_CREATEFUNCTION_DESC = 'createFunction/SET_CREATEFUNCTION_DESC';
export const SET_CREATEFUNCTION_PARAMETER =
  'createFunction/SET_CREATEFUNCTION_PARAMETER';
export const ADD_CREATEFUNCTION_ELEMENT =
  'createFunction/ADD_CREATEFUNCTION_ELEMENT';
export const CREATE_CREATEFUNCTION_STEP =
  'createFunction/CREATE_CREATEFUNCTION_STEP';
export const PUSH_ELEMENT_CREATEFUNCTION_STEP =
  'createFunction/PUSH_ELEMENT_CREATEFUNCTION_STEP';
export const TEST_CREATEFUNCTION = 'createFunction/TEST_CREATEFUNCTION';
export const SAVE_CREATEFUNCTION = 'createFunction/SAVE_CREATEFUNCTION';
export const SET_TEST_CREATEFUNCTION = 'createFunction/SET_TEST_CREATEFUNCTION';
export const SET_CREATEFUNCTION_SENDING =
  'createFunction/SET_CREATEFUNCTION_SENDING';
export const SET_SAVE_CREATEFUNCTION = 'createFunction/SET_SAVE_CREATEFUNCTION';
export const RESET_CREATEFUNCTION_DATA =
  'createFunction/RESET_CREATEFUNCTION_DATA';
export const EDIT_CREATEFUNCTION = 'createFunction/EDIT_CREATEFUNCTION';
export const REHYDRATE_CREATEFUNCTION =
  'createFunction/REHYDRATE_CREATEFUNCTION';
export const DELETE_CREATEFUNCTION_ELEMENT =
  'createFunction/DELETE_CREATEFUNCTION_ELEMENT';
export const POP_CREATEFUNCTION_ELEMENT =
  'createFunction/POP_CREATEFUNCTION_ELEMENT';
export const EDIT_CREATEFUNCTION_ELEMENT =
  'createFunction/EDIT_CREATEFUNCTION_ELEMENT';
export const REPLACE_ELEMENT_CREATEFUNCTION =
  'createFunction/REPLACE_ELEMENT_CREATEFUNCTION';
export const SAVE_EDIT_CREATEFUNCTION =
  'createFunction/SAVE_EDIT_CREATEFUNCTION';
export const DELETE_CREATEFUNCTION = 'createFunction/DELETE_CREATEFUNCTION';
export const DELETE_CREATEFUNCTION_PARAMETER =
  'createFunction/DELETE_CREATEFUNCTION_PARAMETER';
export const SET_EXPRESSION_CREATEFUNCTION =
  'createFunction/SET_EXPRESSION_CREATEFUNCTION';
export const TEST_ADVACED_CREATEFUNCTION_EXPRESSION =
  'createFunction/TEST_ADVACED_CREATEFUNCTION_EXPRESSION';
export const SAVE_ADVANCED_CREATEFUNCTION =
  'createFunction/SAVE_ADVANCED_CREATEFUNCTION';
export const SAVE_EDIT_ADVANCED_CREATEFUNCTION =
  'createFunction/SAVE_EDIT_ADVANCED_CREATEFUNCTION';
export const SET_DELETING_CREATEFUNCTION =
  'createFunction/SET_DELETING_CREATEFUNCTION';
