import { createAction } from 'redux-actions';
import {
  FETCH_AREA_INDICATORS,
  SET_AREA_INDICATORS,
  SET_AREA_INDICATORS_ERROR,
} from './constants';

export const fetchAreaIndicators = createAction(FETCH_AREA_INDICATORS);
export const setAreaIndicators = createAction(SET_AREA_INDICATORS);
export const setAreaIndicatorsError = createAction(SET_AREA_INDICATORS_ERROR);
