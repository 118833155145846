export const SET_INDICATOR_STRUCTURE = 'indicator/SET_INDICATOR_STRUCTURE';
export const SET_INDICATOR_NAME = 'indicator/SET_INDICATOR_NAME';
export const SET_INDICATOR_AREA = 'indicator/SET_INDICATOR_AREA';
export const SET_INDICATOR_ORGANIZATION =
  'indicator/SET_INDICATOR_ORGANIZATION';
export const SET_INDICATOR_DATASOURCE = 'indicator/SET_INDICATOR_DATASOURCE';
export const FETCH_INDICATOR_VARIABLES = 'indicator/FETCH_INDICATOR_VARIABLES';
export const CREATE_INDICATOR_STEP = 'indicator/CREATE_INDICATOR_STEP';
export const ADD_INDICATOR_ELEMENT = 'indicator/ADD_INDICATOR_ELEMENT';
export const PUSH_ELEMENT_INTICATOR_STEP =
  'indicator/PUSH_ELEMENT_INTICATOR_STEP';
export const PUSH_ELEMENT_INDICATOR_DICTIONARY =
  'indicator/PUSH_ELEMENT_INDICATOR_DICTIONARY';
export const TEST_INDICATOR = 'indicator/TEST_INDICATOR';
export const SAVE_INDICATOR = 'indicator/SAVE_INDICATOR';
export const SET_TEST_INDICATOR = 'indicator/SET_TEST_INDICATOR';
export const SET_SAVE_INDICATOR = 'indicator/SET_SAVE_INDICATOR';
export const RESET_INDICATOR_DATA = 'indicator/RESET_INDICATOR_DATA';
export const ADD_INDICATOR_FUNCTION_ELEMENT =
  'indicator/ADD_INDICATOR_FUNCTION_ELEMENT';
export const PUSH_FUNCTION_ELEMENT_INDICATOR =
  'indicator/PUSH_FUNCTION_ELEMENT_INDICATOR';
export const DICTIONARY = 'dictionary';
export const SET_LAST_FUNCTION_VALIDATION =
  'indicator/SET_LAST_FUNCTION_VALIDATION';
export const SET_INDICATOR_SENDING = 'indicator/SET_INDICATOR_SENDING';
export const DELETE_INDICATOR_ELEMENT = 'indicator/DELETE_INDICATOR_ELEMENT';
export const POP_INDICATOR_ELEMENT = 'indicator/POP_INDICATOR_ELEMENT';
export const POP_DICTIONARY_ELEMENT_INDICATOR =
  'indicator/POP_DICTIONARY_ELEMENT_INDICATOR';
export const EDIT_INDICATOR_ELEMENT = 'indicator/EDIT_INDICATOR_ELEMENT';
export const REPLACE_INDICATOR_ELEMENT = 'indicator/REPLACE_INDICATOR_ELEMENT';
export const DELETE_INDICATOR_STEP = 'indicator/DELETE_INDICATOR_STEP';
export const POP_FUNCTION_ARGUMENT_ELEMENT_INDICATOR =
  'indicator/POP_FUNCTION_ARGUMENT_ELEMENT_INDICATOR';
export const POP_ALL_FUNCTION_ARGUMENTS_INDICATOR_DICTIONARY =
  'indicator/POP_ALL_FUNCTION_ARGUMENTS_INDICATOR_DICTIONARY';
export const EDIT_INDICATOR = 'indicator/EDIT_INDICATOR';
export const REHYDRATE_INDICATOR = 'indicator/REHYDRATE_INDICATOR';
export const SAVE_EDIT_INDICATOR = 'indicator/SAVE_EDIT_INDICATOR';
export const DELETE_INDICATOR = 'indicator/DELETE_INDICATOR';
export const RESET_INDICATOR_DATASET = 'indicator/RESET_INDICATOR_DATASET';
export const SET_LOADING_EDIT = 'indicator/SET_LOADING_EDIT';
export const SET_DELETING_INDICATOR = 'indicator/SET_DELETING_INDICATOR';
export const EDIT_INDICATOR_FUNCTION_ARGUMENT_ELEMENT =
  'indicator/EDIT_INDICATOR_FUNCTION_ARGUMENT_ELEMENT';
export const REPLACE_FUNCTION_ARGUMENT_ELEMENT =
  'indicator/REPLACE_FUNCTION_ARGUMENT_ELEMENT';
export const SET_NO_EXIST_IDS_INDICATOR =
  'indicator/SET_NO_EXIST_IDS_INDICATOR';
