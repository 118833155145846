import React, { useEffect } from 'react';

import { Dialog, Toolbar, Typography, Slide, makeStyles, Box, Fab, DialogContentText, Button, Grid, IconButton, Container } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function NewForecastModal(props) {
    const classes = useStyles();

    useEffect(() => {
        return function cleanup() {

        };
    }, [
    ])

    return (<Dialog fullScreen
        open={props.open}
        onClose={props.handleCloseModal}
        TransitionComponent={Transition}
    >
        <AppBar
            color="secondary"
            className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.handleCloseModal}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {`${props.translate("New")} ${props.translate("Forecast")}`}
                </Typography>
            </Toolbar>
        </AppBar>
        {props.progress ?
            <LinearProgress value={(props.progress.loaded * 100) / props.progress.total} />
            : ''}
        <Container fixed>
            <Box my={2}>

            </Box>
        </Container>
    </Dialog>)
}

export default NewForecastModal;
