import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, makeStyles, Select, Input, MenuItem, Chip, Checkbox, ListItemText, FormControl } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import CardCustomer from './dashboard/CardCustomer';
import EditIcon from '@material-ui/icons/Edit';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

function DashboardCustomer(props) {
    const classes = useStyles();
    const [active, setActive] = useState([])
    const [inactive, setInactive] = useState([])
    const [registred, setRegistred] = useState([])
    const [incomplete, setIncomplete] = useState([])
    const [audit, setAudit] = useState([])
    const [authorization, setAuthorization] = useState([])

    useEffect(() => {
        updateCustomerCards()

        return function cleanup() {

        };
    }, [props.customers]) // Only re-run the effect if name or email change

    const updateCustomerCards = () => {
        var _active = []
        var _inactive = []
        var _registred = []
        var _incomplete = []
        var _audit = []
        var _authorization = []

        props.customers.forEach(customer => {
            if (Boolean(customer)) {
                if (Boolean(customer.customerId)) {
                    (customer.sellBanned || customer.customerId === 'blacklist') ? _inactive.push(customer) : _active.push(customer)
                } else {
                    if (customer.audits && customer.audits.items.length > 0 && customer.audits.items.filter(currentValue => !currentValue.endAudit).length > 0)
                        _audit.push(customer)
                    else if (customer.preregistrationCode
                        && customer.sellBanned === null
                        && customer.fingers
                        && customer.fingers.length > 0)
                        _registred.push(customer)
                    else if (customer.preregistrationCode
                        && customer.sellBanned === null
                        && !customer.fingers)
                        _incomplete.push(customer)
                    else if (customer.preregistrationCode
                        && customer.sellBanned === false
                        && customer.fingers
                        && customer.fingers.length > 0)
                        _authorization.push(customer)
                }
            }
        })

        if (_active.length !== active.length)
            setActive(_active)
        if (_inactive.length !== inactive.length)
            setInactive(_inactive)
        if (_registred.length !== registred.length)
            setRegistred(_registred)
        if (_incomplete.length !== incomplete.length)
            setIncomplete(_incomplete)
        if (_audit.length !== audit.length)
            setAudit(_audit)
        if (_authorization.length !== authorization.length)
            setAuthorization(_authorization)
    }

    return <Box my={4}>
        {props.rowsLength === 0 || (props.rowsLength === props.customers.length) ?
            <Grid
                container
                justify="center"
                alignItems="center"
                spacing={5}>
                <Grid item xs={4}>
                    <CardCustomer
                        changeSubSet={props.handleInAudit}
                        customers={active}
                        total={props.customers.length}
                        translate={props.translate}
                        title={props.translate("Active Rows")} />
                </Grid>
                <Grid item xs={4}>
                    <CardCustomer
                        changeSubSet={props.changeSubSet}
                        customers={inactive}
                        total={props.customers.length}
                        translate={props.translate}
                        title={props.translate("Inactive Rows")} />
                </Grid>
                <Grid item xs>
                    <CardCustomer
                        changeSubSet={props.changeSubSet}
                        customers={incomplete}
                        total={props.customers.length}
                        translate={props.translate}
                        title={props.translate("Incomplete Rows")} />
                </Grid>
                <Grid item xs={4}>
                    <CardCustomer
                        changeSubSet={props.changeSubSet}
                        customers={registred}
                        total={props.customers.length}
                        translate={props.translate}
                        title={props.translate("Registered Rows")} />
                </Grid>
                <Grid item xs>
                    <CardCustomer
                        changeSubSet={props.handleInAudit}
                        customers={audit}
                        total={props.customers.length}
                        translate={props.translate}
                        title={props.translate("Auditable Rows")} />
                </Grid>
                <Grid item xs>
                    <CardCustomer
                        changeSubSet={props.changeSubSet}
                        customers={authorization}
                        total={props.customers.length}
                        translate={props.translate}
                        title={props.translate("Authorization Rows")} />
                </Grid>
            </Grid> : ''}
        <Box my={2}>
            <Grid
                container
                justify="flex-end"
                alignItems="center"
                spacing={5}>
                <Grid item>
                    {props.inAudit && props.selectedCustomers ?
                        <Button
                            disabled={!(props.selectedCustomers && props.selectedCustomers.length > 0)}
                            variant="contained"
                            color="secondary"
                            startIcon={<EditIcon />}
                            onClick={event => props.handleEditRows(event)}>
                            {`${props.translate("Edit")} ${props.selectedCustomers.length} ${props.translate("Rows")}`}
                        </Button>
                        : ''}
                </Grid>
                <Grid item>
                    <Button
                        onClick={event => props.changeSubSet(props.customers, "")}
                        size="small">
                        {`${props.translate("View")} ${props.customers.length} ${props.translate("Rows")}`}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    </Box>
}

export default withSnackbar(DashboardCustomer);