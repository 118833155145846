import React, { useState } from 'react';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import { tableESP } from '../../../helpers';

export default function Lista(props) {
  const [state] = useState({
    columns: [
      /*{ title: 'ID', field: 'id' , editable: 'never' },*/
      { title: 'Nombre', field: 'name'},
    ]
  });

  const { data, edit, onUpdateValue, onDeleteValue, user } = props;
  const { paramProfile, position } = user;
  let listPermisionOrganization = JSON.parse(JSON.parse(paramProfile))
  let listOrganization = []
  /// ** FILTRO POR EMPRESA QUE NO PUEDA ACCEDER
  data && data.length && data.forEach(organization => {
    listPermisionOrganization.filter(r => r.uuid === position.id).forEach(_e => {

      _e.organization.forEach(_a => {
        if (_a.uuid === organization.id) {
          listOrganization.push(organization)
        }
      });

    });
  });

  //data = listOrganization
  //console.log( listOrganization)

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {edit ? <MaterialTable
            title='EMPRESAS'
            columns={state.columns}
            data={data}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      onUpdateValue(oldData, newData, newData.name);
                    }
                  }, 600);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    onDeleteValue(oldData, oldData.id);
                  }, 600);
                }),
            }}
            options={{
              actionsColumnIndex: -1
            }}
            localization={tableESP}
          /> : <MaterialTable
            title='EMPRESAS'
            columns={state.columns}
            data={data}
            options={{
              actionsColumnIndex: -1
            }}
            localization={tableESP}
          />}
        </Grid>
      </Grid>
    </div>
  );
}
