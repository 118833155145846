import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getFunctionsState = state => get(state, 'functions');

export const isLoadingFunctions = createSelector(
  [getFunctionsState],
  functions => get(functions, 'loading'),
);

export const geFunctions = createSelector([getFunctionsState], functions =>
  get(functions, 'data'),
);

export const isErrorGetFunctions = createSelector(
  [getFunctionsState],
  functions => get(functions, 'error'),
);
