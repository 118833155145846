import React from 'react';
import { Box } from '@material-ui/core';

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            style={{ width: "90%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
}