import React, { useEffect, useState, useRef } from 'react';

import { Box, Container, Typography, InputAdornment, IconButton, LinearProgress, Grid, Button, Dialog, AppBar, Toolbar, Slide, Link, TextField, makeStyles, Tooltip } from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import SaveIcon from '@material-ui/icons/Save';

import { SNACKBAR_ERROR, SNACKBAR_INFO } from '../../../utils/SessionControl';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FingerType } from '../../../schema/enums/schemaEnums';

// const { ipcRenderer } = window.require("electron");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '0',
        padding: '0',
        minWidth: '0px',
        '& .MuiButton-startIcon': {
            margin: '0'
        },
        '& .MuiButton-root': {
            margin: '0',
            padding: '0',
            minWidth: '0px'
        },
        '& ,MuiButtonLabel-root': {
            display: 'block'
        }
    },
    appBar: {
        position: 'relative',
    },
}));

export default function FingerprintDialog(props) {
    const firstRender = useRef(true)
    const [searching, setSearching] = useState(false)
    const [error, setError] = useState(null)
    const [fingers, setFingers] = useState(props.customer.fingers ? props.customer.fingers : null)
    const [fingerMatrix, setFingerMatrix] = useState(null)
    const [LEFT_PINKY, setLEFT_PINKY] = useState(false)
    const [LEFT_RING, setLEFT_RING] = useState(false)
    const [LEFT_MIDDLE, setLEFT_MIDDLE] = useState(false)
    const [LEFT_INDEX, setLEFT_INDEX] = useState(false)
    const [LEFT_THUMB, setLEFT_THUMB] = useState(false)
    const [RIGHT_PINKY, setRIGHT_PINKY] = useState(false)
    const [RIGHT_RING, setRIGHT_RING] = useState(false)
    const [RIGHT_MIDDLE, setRIGHT_MIDDLE] = useState(false)
    const [RIGHT_INDEX, setRIGHT_INDEX] = useState(false)
    const [RIGHT_THUMB, setRIGHT_THUMB] = useState(false)

    const classes = useStyles();

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render
            return // skip the code below
        }

        validateFingers()
        buildUI()

        return function cleanup() {
            // ipcRenderer.send("fingerprint-enrollment-kill");
        };
    }, [fingers, props.open]) // Only re-run the effect if name or email change

    // const enrollFingerPrint = () => {
    //     setSearching(true)
    //     ipcRenderer.send("fingerprint-enrollment", {
    //         option: "enrollment"
    //     });

    //     ipcRenderer.on("fingerprint-enrollment-data-reply", (event, resp) => {
    //         console.log("fingerprint-enrollment-data-reply", resp)
    //         let _fingers = JSON.parse(resp);
    //         console.log("fingers", _fingers)
    //         if (!fingers){
    //             setFingers(_fingers.fingers)
    //         }else{
    //             setFingers(fingers.concat(_fingers.fingers))
    //         }
    //     })
    //     ipcRenderer.on("fingerprint-enrollment-err-reply", (event, resp) => {
    //         console.log("fingerprint-enrollment-err-reply", resp)
    //     })
    //     ipcRenderer.on("fingerprint-enrollment-close-reply", (event, resp) => {
    //         console.log("fingerprint-enrollment-close-reply", resp)
    //         ipcRenderer.send("fingerprint-enrollment-kill", {});
    //         ipcRenderer.removeAllListeners("fingerprint-enrollment-data-reply")
    //         ipcRenderer.removeAllListeners("fingerprint-enrollment-err-reply")
    //         ipcRenderer.removeAllListeners("fingerprint-enrollment-close-reply")
    //         setSearching(false)
    //     })

    //     return
    // }

    const validateFingers = () => {
        //console.log("validateFingers", props && props.customer.fingers)
        if (props && props.typeScreen === "CUSTOMER") {
            try {
                props.customer.fingers !== null && props.customer.fingers.forEach(finger => {
                    // setFingers(props.customer.fingers)
                     if (FingerType.LEFT_INDEX === finger.fingerType){
                         setLEFT_INDEX(true)
                     }else if (FingerType.LEFT_MIDDLE === finger.fingerType){
                         setLEFT_MIDDLE(true)
                     }else if (FingerType.LEFT_PINKY === finger.fingerType){
                         setLEFT_PINKY(true)
                     }else if (FingerType.LEFT_RING === finger.fingerType){
                         setLEFT_RING(true)
                     }else if (FingerType.LEFT_THUMB === finger.fingerType){
                         setLEFT_THUMB(true)
                     }else if (FingerType.RIGHT_INDEX === finger.fingerType){
                         setRIGHT_INDEX(true)
                     }else if (FingerType.RIGHT_MIDDLE === finger.fingerType){
                         setRIGHT_MIDDLE(true)
                     }else if (FingerType.RIGHT_PINKY === finger.fingerType){
                         setRIGHT_PINKY(true)
                     }else if (FingerType.RIGHT_RING === finger.fingerType){
                         setRIGHT_RING(true)
                     }else if (FingerType.RIGHT_THUMB === finger.fingerType){
                         setRIGHT_THUMB(true)
                     }
                 }); 
            } catch (error) {
                
            }

            if (!fingers || (fingers && fingers.length < 2))
                setError(props.translate("At least 2 fingerprints to complete registration"))
            else setError(null)

        } else {
            if (!fingers || (fingers && fingers.length < 2))
                setError(props.translate("At least 2 fingerprints to complete registration"))
            else setError(null)
        }
        return
    }

    const buildUI = () => {
        var matrix = {}
        fingers && fingers.forEach(f => {
            matrix[f.fingerType] = f.fingerTemplate
        })
        setFingerMatrix({
            ...fingerMatrix,
            ...matrix
        })
    }

    const handleUpdateCustomer = event => {
        setSearching(true)

        props.handleUpdateCustomer({
            id: props.customer.id,
            fingers: null
        }).then(res => {
            props.handleUpdateCustomer({
                id: props.customer.id,
                fingers: fingers
            }).then(res => {
                props.confirmSuccessEnrollment(res).then(res => {
                    setSearching(false)
                    props.resetCustomer()
                    props.handleClose()
                })
            }).catch(err => {
                console.log(err)
                setSearching(false)
            })
        }).catch(err => {
            console.log(err)
            setSearching(false)
        })

        return
    }

    const handleCustomUpdateCustomer = event => {
        setSearching(true)

        props.handleUpdateCustomer({
            id: props.customer.id,
            fingers: fingers
        }).then(res => {
            // props.handleUpdateCustomer({
            //     id: props.customer.id,
            //     fingers: fingers
            // }).then(res => {
                 props.confirmSuccessEnrollment(res).then(res => {
                //     setSearching(false)
                     props.resetCustomer()
                     props.handleClose()
                 })
            // }).catch(err => {
            //     console.log(err)
            //     setSearching(false)
            // })
        }).catch(err => {
            console.log(err)
            setSearching(false)
        })

        return
    }

    return (<Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}
        PaperProps={{
            style: {
                backgroundColor: 'whitesmoke'
            },
        }}>
        <AppBar color="secondary" className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" >
                    {props.translate("Fingers")}
                </Typography>
            </Toolbar>
        </AppBar>
        {searching && <LinearProgress color="primary" />}
        <Container fixed>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                style={{ minHeight: '80vh' }}>
                {error ?
                    <Alert
                        variant="filled"
                        severity={SNACKBAR_ERROR}>
                        <AlertTitle>{props.translate("Error")}</AlertTitle>
                        {error}
                    </Alert>
                    : ''}
                <Grid container
                    spacing={7}
                    justify="center"
                    alignItems="center">
                    <Grid item>
                        <Alert
                            variant="filled"
                            severity={SNACKBAR_INFO}>
                            <AlertTitle>{props.translate("Instructions")}</AlertTitle>
                            {props.translate("fingerprintInstructionsLegend")}
                        </Alert>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item>
                        <Grid container
                            spacing={2}
                            justify="center"
                            alignItems="center">
                            <Grid item xs={10}>
                                <Grid container
                                    spacing={2}
                                    justify="center"
                                    alignItems="flex-end">
                                    <Grid item xs>
                                        <Tooltip title={props.translate(FingerType.LEFT_PINKY)} interactive arrow>
                                            <Button
                                                color={fingerMatrix && fingerMatrix[FingerType.LEFT_PINKY] ? "primary" : "default"}
                                                style={{ height: '4em' }}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<FingerprintIcon />}
                                                disabled={LEFT_PINKY}
                                            ></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs>
                                        <Tooltip title={props.translate(FingerType.LEFT_RING)} interactive arrow>
                                            <Button
                                                color={fingerMatrix && fingerMatrix[FingerType.LEFT_RING] ? "primary" : "default"}
                                                style={{ height: '8em' }}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<FingerprintIcon />}
                                                disabled={LEFT_RING}
                                            ></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs>
                                        <Tooltip title={props.translate(FingerType.LEFT_MIDDLE)} interactive arrow>
                                            <Button
                                                color={fingerMatrix && fingerMatrix[FingerType.LEFT_MIDDLE] ? "primary" : "default"}
                                                style={{ height: '10em' }}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<FingerprintIcon />}
                                                disabled={LEFT_MIDDLE}
                                            ></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs>
                                        <Tooltip title={props.translate(FingerType.LEFT_INDEX)} interactive arrow>
                                            <Button
                                                color={fingerMatrix && fingerMatrix[FingerType.LEFT_INDEX] ? "primary" : "default"}
                                                style={{ height: '6em' }}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<FingerprintIcon />}
                                                disabled={LEFT_INDEX}
                                            ></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    {/* <Grid item xs>
                                        <Button
                                            onClick={event => enrollFingerPrint()}
                                            style={{ height: '6em', width: '100%' }}
                                            variant="contained"
                                            className={classes.button}>
                                            {"Izquierda"}
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title={props.translate(FingerType.LEFT_THUMB)} interactive arrow>
                                    <Button
                                        color={fingerMatrix && fingerMatrix[FingerType.LEFT_THUMB] ? "primary" : "default"}
                                        style={{ height: '5em', marginTop: "8em" }}
                                        variant="contained"
                                        className={classes.button}
                                        startIcon={<FingerprintIcon />}
                                        disabled={LEFT_THUMB}>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container
                            spacing={2}
                            justify="center"
                            alignItems="center">
                            <Grid item xs={2}>
                                <Tooltip title={props.translate(FingerType.RIGHT_THUMB)} interactive arrow>
                                    <Button
                                        color={fingerMatrix && fingerMatrix[FingerType.RIGHT_THUMB] ? "primary" : "default"}
                                        style={{ height: '5em', marginTop: "8em" }}
                                        variant="contained"
                                        className={classes.button}
                                        startIcon={<FingerprintIcon />}
                                        disabled={RIGHT_THUMB}
                                    ></Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={10}>
                                <Grid container
                                    spacing={2}
                                    justify="center"
                                    alignItems="flex-end">
                                    <Grid item xs>
                                        <Tooltip title={props.translate(FingerType.RIGHT_INDEX)} interactive arrow>
                                            <Button
                                                color={fingerMatrix && fingerMatrix[FingerType.RIGHT_INDEX] ? "primary" : "default"}
                                                style={{ height: '6em' }}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<FingerprintIcon />}
                                                disabled={RIGHT_INDEX}
                                            ></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs>
                                        <Tooltip title={props.translate(FingerType.RIGHT_MIDDLE)} interactive arrow>
                                            <Button
                                                color={fingerMatrix && fingerMatrix[FingerType.RIGHT_MIDDLE] ? "primary" : "default"}
                                                style={{ height: '10em' }}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<FingerprintIcon />}
                                                disabled={RIGHT_MIDDLE}
                                            ></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs>
                                        <Tooltip title={props.translate(FingerType.RIGHT_RING)} interactive arrow>
                                            <Button
                                                color={fingerMatrix && fingerMatrix[FingerType.RIGHT_RING] ? "primary" : "default"}
                                                style={{ height: '8em' }}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<FingerprintIcon />}
                                                disabled={RIGHT_RING}
                                            ></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs>
                                        <Tooltip title={props.translate(FingerType.RIGHT_PINKY)} interactive arrow>
                                            <Button
                                                color={fingerMatrix && fingerMatrix[FingerType.RIGHT_PINKY] ? "primary" : "default"}
                                                style={{ height: '4em' }}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<FingerprintIcon />}
                                                disabled={RIGHT_PINKY}
                                            ></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    {/* <Grid item xs>
                                        <Button
                                            onClick={event => enrollFingerPrint()}
                                            style={{ height: '6em', width: '100%' }}
                                            variant="contained"
                                            className={classes.button}>
                                            {"Derecha"}
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} />
                    {fingers && fingers.length >= 2 ?
                        <Grid item>
                            <Button
                                onClick={props && props.typeScreen === "CUSTOMER" ? handleCustomUpdateCustomer: handleUpdateCustomer}
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}>
                                {props.translate("Enroll Fingerprints")}
                            </Button>
                        </Grid>
                        : ''}
                </Grid>
            </Box>
        </Container>
    </Dialog>)
}