import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getOrganizationsState = state => get(state, 'organizations');

export const isOrganizationsloading = createSelector(
  [getOrganizationsState],
  organizations => get(organizations, 'loading'),
);

export const getOrganizationsdata = createSelector(
  [getOrganizationsState],
  organizations => get(organizations, 'data'),
);
