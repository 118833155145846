import {
    red,
    blue,
    green,
    brown,
    yellow,
    orange,
    purple,
    pink
} from '@material-ui/core/colors';

export const EmailType = {
    PRINCIPAL: "principal",
    PERSONAL: "personal",
    OTHER: "other"
}

export const PhoneType = {
    HOME: "home",
    WORK: "work",
    OFFICE: "office",
    MOBILE: "mobile",
    OTHER: "other"
}

export const FingerType = [
    "left_pinky",
    "left_ring",
    "left_middle",
    "left_index",
    "left_thumb",
    "right_pinky",
    "right_ring",
    "right_middle",
    "right_index",
    "right_thumb"
]

export const ProductKitType = {
    LOYALTY: "loyalty",
    COMBO: "combo",
    KIT: "kit",
    PROMOTION: "promotion"
}

export const WarehouseType = [
    "_10",
    "_20"
]

export const ZoneType = [
    "customer",
    "warehouse"
]

export const MenuType = {
    PROFILE: "profile",
    SYSTEM: "system",
    NAVIGATION: "navigation"
}

export const StockType = {
    PETTY_CASH: "petty_cash",
    MONEY_CASH: "money_cash",
    VIRTUAL_CASH: "virtual_cash"
}

export const XadisStockType = {
    FREETOUSE: "freeToUse",
    SELECTABLE: "selectable",
}

export const CashRegisterShiftType = {
    SALE_REGISTER: "saleRegister",
    SCHEDULE_SHIFT: "scheduleShift",
    RECEPTION: "reception",
    DELIVERY: "delivery",
    Z_CUT: "z_cut",
    X_CUT: "x_cut"
}

export const CashRegisterShiftStatusType = {
    OPEN: "open",
    IDLE: "idle",
    CLOSED: "closed"
}

export const CashDrawerType = [
    "entry",
    "exit"
]

export const CashFlowType = {
    SALE: "sale",
    RETURNED: "returned",
    RETURNABLE: "returnable",
    INITIALIZATION: "initialization",
    OPENING: "opening",
    ADJUSTMEN: "adjustmen",
    AUDIT: "audit",
    SAFEKEEPING: "safekeeping",
    CONTROL: "control"
}

export const KardexOperationType = [
    "entry",
    "exit"
]

export const KardexFlowType = [
    "sale",
    "purchase_order",
    "initialization",
    "opening",
    "adjustmen",
    "audit"
]

export const AutorizationType = [
    "cash_register_shift",
    "shift_change",
    "discount_application",
    "audit_result",
    "workflow",
    "manager",
    "supervisor",
    "corporate"
]

export const PaymentType = [
    "cash",
    "card",
    "electronic"
]

export const ReturnableType = {
    DELIVERY: "delivery",
    RECEPTION: "reception",
    EXPIRATION: "expiration"
}

export const DiscountType = {
    STOCK_PETTY_CASH: "stock_petty_cash",
    STOCK_MONEY_CASH: "stock_money_cash",
    STOCK_VIRTUAL_CASH: "stock_virtual_cash",
    RETURNABLE: "returnable",
    INVENTORY: "inventory"
}

export const AttendanceRecordType = {
    ON_TIME: "onTime",
    EARLY: "early",
    DELAYED: "delayed",
    NO_ROLE: "noRole",
    CHECK: "check",
    EXIT: "exit"
}


export const ParamType = {
    POS: "pos",
    USER: "user",
    CLOUD: "cloud",
    WAREHOUSE: "warehouse"
}

export const PricingType = {
    CUSTOMER: "customer",
    CUSTOMER_TYPE: "customerType",
    WAREHOUSE: "warehouse",
    ZONE: "zone"
}

export const LegalType = {
    TERMS_AND_CONDITIONS: "termsAndConditions",
    NOTICE_PRIVACY: "noticePrivacy"
}

export const FileType = {
    INVOICE: "invoice",
    VOUCHER: "voucher",
    TELEPHONIC_RECHARGE: "telephonic_recharge",
    SERVICE_PAYMENT: "service_payment",
    MOVIE_TICKET: "movie_ticket",
    IDENTIFICATION: "identification",
    PICTURE_PROFILE: "picture_profile"
}

export const PlatformType = {
    POS: "pos",
    CLOUD: "cloud",
    SUSCRIPTOR: "suscriptor",
    USER: "user"
}

export const AuditType = {
    CUSTOMER: "customer",
    ATTENDANT: "attendant",
    WAREHOUSE: "warehouse",
    INVENTORY: "inventory",
    SALES: "sales",
    PRODUCTS: "products"
}

export const SourceType = {
    FORTIA_ERP: "fortia_erp",
    FORTIA_PRIME: "fortia_prime",
    POWER_BI: "power_bi"
}

export const DateType = {
    SEND: "send",
    RECEIVED: "received",
    READ: "read",
    SKIPPED: "skipped",
    ACTION: "action",
    CONFIRM: "confirm"
}

export const ChannelType = {
    PUSH: "push",
    SMS: "sms",
    EMAIL: "email"
}

export const ForecastMethodType = {
    CREATE: "create",
    DELETE: "delete",
    QUERY: "query",
    UPDATE: "update",
    EXPORT: "export"
}

export const FrequencyType = {
    Y: "Y",
    M: "M",
    W: "W",
    D: "D",
    H: "H",
    MIN: "min"
}

export const DatasetType = {
    TARGET_TIME_SERIES: "TARGET_TIME_SERIES",
    RELATED_TIME_SERIES: "RELATED_TIME_SERIES",
    ITEM_METADATA: "ITEM_METADATA"
}

export const ForecastDomainType = {
    RETAIL: "RETAIL",
    CUSTOM: "CUSTOM",
    INVENTORY_PLANNING: "INVENTORY_PLANNING",
    EC2_CAPACITY: "EC2_CAPACITY",
    WORK_FORCE: "WORK_FORCE",
    WEB_TRAFFIC: "WEB_TRAFFIC",
    METRICS: "METRICS"
}

export const HeadcountStatusType = {
    HIGH: "high",
    MEDIUM: "medium",
    LOW: "low"
}

export const STATUS = {
    REQUESTED: "REQUESTED",
    COMPLETED: "COMPLETED"
}

export const ColorsCatalog = {
    RED: red,
    BLUE: blue,
    GREEN: green,
    BROWN: brown,
    YELLOW: yellow,
    ORANGE: orange,
    PURPLE: purple,
    PINK: pink
}

export const AddressComponents = {
    STREET_NUMBER: 'street_number',
    STREET_ADDRESS: 'street_address',
    ROUTE: 'route',
    INTERSECTION: 'intersection',
    POLITICAL: 'political',
    COUNTRY: 'country',
    ADMINISTRATIVE_AREA_1: 'administrative_area_level_1',
    ADMINISTRATIVE_AREA_2: 'administrative_area_level_2',
    ADMINISTRATIVE_AREA_3: 'administrative_area_level_3',
    ADMINISTRATIVE_AREA_4: 'administrative_area_level_4',
    ADMINISTRATIVE_AREA_5: 'administrative_area_level_5',
    COLLOQUIAL_AREA: 'colloquial_area',
    LOCALITY: 'locality',
    SUBLOCALITY: 'sublocality',
    SUBLOCALITY_LEVEL_1: 'sublocality_level_1',
    SUBLOCALITY_LEVEL_2: 'sublocality_level_2',
    SUBLOCALITY_LEVEL_3: 'sublocality_level_3',
    SUBLOCALITY_LEVEL_4: 'sublocality_level_4',
    SUBLOCALITY_LEVEL_5: 'sublocality_level_5',
    NEIGHBORHOOD: 'neighborhood',
    PREMISE: 'premise',
    SUBPREMISE: 'subpremise',
    PLUS_CODE: 'plus_code',
    POSTAL_CODE: 'postal_code',
    NATURAL_FEATURE: 'natural_feature',
    AIRPORT: 'airport',
    PARK: 'park',
    POINT_OF_INTEREST: 'point_of_interest'
}

