import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Select } from '../../../components/Modal';
import { Button } from '@material-ui/core';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Breadcrumbs from '../../../components/Breadcrumbs';
import MaterialTable from 'material-table';

import Switch from '@material-ui/core/Switch';


import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


import {
  setReportsOrganization,
  setReportsArea
} from '../../../store/reports/actions';
import { fetchAreas, setAreas } from '../../../store/areas/actions';


import {
  getReportsOrganization,
  getReportsEmployeeReports,
  isLoadingEmployeereports,
  isErrorEmployeereports,
  getReportsArea
} from '../../../store/reports/selectors';

import { isAreasloading, getAreasdata } from '../../../store/areas/selectors';

import {
  isOrganizationsloading,
  getOrganizationsdata,
} from '../../../store/organizations/selectors';

import { fetchOrganizations } from '../../../store/organizations/actions';
import { fetchReportsOrganization } from '../../../store/reports/actions';

import { tableESP } from '../../../helpers';
import './styles.scss';


export class ListReports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dateInit: new Date(),
      dateFinal: new Date(),
      activeDate: true,
      activeHierarchical: false,
      loading: false
    }
  }


  componentDidMount() {
    const {
      params,
      fetchReportsOrganization,
      fetchAreas,
    } = this.props;

    let dateStart = new Date();
    const dateEnd = new Date();

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayWeek = days[dateStart.getDay()];

    switch (dayWeek) {
      case 'Monday':
        dateStart.setDate(dateStart.getDate() - 1);
        break;
      case 'Tuesday':
        dateStart.setDate(dateStart.getDate() - 2);
        break;
      case 'Wednesday':
        dateStart.setDate(dateStart.getDate() - 3);
        break;
      case 'Thursday':
        dateStart.setDate(dateStart.getDate() - 4);
        break;
      case 'Friday':
        dateStart.setDate(dateStart.getDate() - 5);
        break;
      case 'Saturday':
        dateStart.setDate(dateStart.getDate() - 6);
        break;
      default:
        break;
    }

    if (params.organizationId) {
      fetchReportsOrganization({
        type: "organization_dates",
        organization_id: params.organizationId,
        start_date: `${dateStart.getFullYear()}-${("0" + (dateStart.getMonth() + 1)).slice(-2)}-${("0" + dateStart.getDate()).slice(-2)}`,
        end_date: `${dateEnd.getFullYear()}-${("0" + (dateEnd.getMonth() + 1)).slice(-2)}-${("0" + dateEnd.getDate()).slice(-2)}`,
      });
      const value = { id: params.organizationId }
      fetchAreas(value)
    }
  }

  searchReports() {
    const {
      dateFinal,
      dateInit,
      activeDate,
    } = this.state;

    const {
      params,
      fetchReportsOrganization,
      area
    } = this.props

    if (activeDate) {
      if (dateInit > dateFinal) {
        alert("Fechas Inicio debe ser menos que la fecha Final");
        return null;
      }
    }

    if (params.organizationId && activeDate && area) {
      fetchReportsOrganization({
        type: "organization_hierarchical_dates",
        organization_id: params.organizationId,
        hierarchical_node_id: area.id,
        start_date: `${dateInit.getFullYear()}-${("0" + (dateInit.getMonth() + 1)).slice(-2)}-${("0" + dateInit.getDate()).slice(-2)}`,
        end_date: `${dateFinal.getFullYear()}-${("0" + (dateFinal.getMonth() + 1)).slice(-2)}-${("0" + dateFinal.getDate()).slice(-2)}`,
      });
      return null;
    }

    if (params.organizationId && activeDate) {
      fetchReportsOrganization({
        type: "organization_dates",
        organization_id: params.organizationId,
        start_date: `${dateInit.getFullYear()}-${("0" + (dateInit.getMonth() + 1)).slice(-2)}-${("0" + dateInit.getDate()).slice(-2)}`,
        end_date: `${dateFinal.getFullYear()}-${("0" + (dateFinal.getMonth() + 1)).slice(-2)}-${("0" + dateFinal.getDate()).slice(-2)}`,
      });
    }

    if (params.organizationId && area) {
      fetchReportsOrganization({
        type: "organization_hierarchical",
        organization_id: params.organizationId,
        hierarchical_node_id: area.id
      });
    }

  }

  handleDateChange = (date) => {
    this.setState({ dateInit: date })
  };

  handleDateEndChange = (date) => {
    this.setState({ dateFinal: date })
  }

  handleSwithesChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  render() {
    const {
      isLoading,
      params,
      areas
    } = this.props;

    const {
      dateFinal,
      dateInit } = this.state;

    return (

      <div className={'list-reports'}>
        <div className="breadcrumbs-container-wrapper">
          <Breadcrumbs
            navigation={[
              { title: 'Dashboard', url: '/prometheus/variableCommissions/' },
              {
                title: 'Buscar reportes',
                url: '/prometheus/variableCommissions/reportes',
              },
              {
                title: 'Lista de reportes',
                current: true,
              },
            ]}
          />
        </div>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-inline"
                  label="Fecha Inicio"
                  value={dateInit}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker"
                  label="Fecha Final"
                  value={dateFinal}
                  onChange={this.handleDateEndChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid className="fullInput" item xs={8}>
            <Select
              className={"w100"}
              loading={areas.loading}
              label="Área"
              elements={areas.data}
              onChange={value => {
                this.props.setReportsArea(value)
              }} s
            />
          </Grid>
          <Grid item xs={2}>
            {isLoading ?
              <Button
                style={{ marginTop: "20px" }}
                className={'small-btn'}
                variant="contained"
                color="primary"
                disabled={true}
              >
                CARGANDO
              </Button> :
              <Button
                style={{ marginTop: "20px" }}
                className={'small-btn'}
                variant="contained"
                color="primary"
                onClick={() => this.searchReports()}
              >
                BUSCAR REPORTES
              </Button>
            }
          </Grid>


          <Grid item xs={12}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <MaterialTable
                title={"Reportes"}
                data={this.getData()}
                columns={[
                  //{ title: 'Id de empleado', field: 'employee_id' },
                  { title: 'Empresa', field: 'empresa' },
                  { title: 'NoNomina', field: 'nomina' },
                  { title: 'Nombre Empleado', field: 'employee_name' },
                  { title: 'Puesto', field: 'hierarchical_node_name' },
                  { title: 'Comisión Variable', field: 'variable_commission_name' },
                  { title: 'Fecha Inicio', field: 'start_date' },
                  { title: 'Fecha Cálculo', field: 'end_date' },
                  { title: 'Resultado', field: 'resultado' },
                  { title: 'Tíenda', field: 'warehouse'},
                  {
                    header: '',
                    id: 'links',
                    render: rowData => (
                      <Button
                        className={'small-btn'}
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/prometheus/variableCommissions/reportes/detalle/${rowData.id}`}
                      >
                        Ver Reporte
                      </Button>
                    ),
                  },
                ]}
                options={{
                  exportButton: true,
                  pageSizeOptions: [5, 25, 50, 100, 200, 300 , 400, 500, 600, this.getData().length],
                  exportAllData: true
                }}
                localization={tableESP}
              />
            )}
          </Grid>
        </Grid>

      </div>
    );
  }

  getData = () => {

    let { reports } = this.props
    return reports.map(report => {
      /*let data = { ...report }
      if (data.exception !== null)
        return { ...data, ...{ evaluation_result: (<span style={{ color: "red" }}>Error</span>) } }
      return data
    })*/
      console.log(reports)
      let data = { ...report }
      if (data.employee_id !== null) {
        if (data.employee_id.split("-")[0] == 12) {
          data = { ...data, empresa: data.employee_id.split("-")[0], nomina: data.employee_id.split("-")[1], resultado: data.evaluation_result }
          console.log(data)
        } else {
          data = { ...data, empresa: data.employee_id.split("-")[0], nomina: data.employee_id.split("-")[1], resultado: Math.round(data.evaluation_result) }
          console.log(data)
        }
      } else {
        data = { ...data }
      }
      console.log(data)
      if (data.exception !== null)
        return { ...data, ...{ evaluation_result: (<span style={{ color: "red" }}>Error</span>) } }
      return { ...data, ...{ evaluation_result: (<span style={{ color: "red" }}>Error</span>) } }
      return data
    })
  }

}

const mapStateToProps = state => ({
  reports: getReportsEmployeeReports(state),
  isLoading: isLoadingEmployeereports(state),
  error: isErrorEmployeereports(state),

  area: getReportsArea(state),
  organization: getReportsOrganization(state),
  organizations: {
    loading: isOrganizationsloading(state),
    data: getOrganizationsdata(state)
  },
  areas: {
    loading: isAreasloading(state),
    data: getAreasdata(state)
  }

});

const mapDispatchToProps = dispatch => ({
  fetchOrganizations: () => dispatch(fetchOrganizations()),
  fetchAreas: payload => dispatch(fetchAreas(payload)),
  fetchReportsOrganization: payload => dispatch(fetchReportsOrganization(payload)),
  setReportsOrganization: payload => dispatch(setReportsOrganization(payload)),
  setReportsArea: payload => dispatch(setReportsArea(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListReports);
