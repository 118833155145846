import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popover, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';

import { deleteIndicatorElement } from '../../../../store/indicator/actions';
import { deleteKPIElement } from '../../../../store/kpi/actions';
import { deleteCreateFunctionElement } from '../../../../store/createFunctions/actions';

import './styles.scss';

export class StepItemEdit extends Component {
  deleteElement(element) {
    const step = element.getAttribute('data-step');
    const index = element.getAttribute('aria-describedby');
    const screen = element.getAttribute('data-screen');
    const name = element.getAttribute('data-name');
    const type = element.getAttribute('data-type');

    const functionName = element.getAttribute('data-function-name');
    const functionElementIndex = element.getAttribute('data-function-eind');
    const functionArguments = element.getAttribute('data-function-arguments');

    switch (screen) {
      case 'indicator':
        this.props.deleteIndicatorElement({
          step,
          index,
          name,
          type,
          functionArguments,
          functionName,
          functionElementIndex,
        });
        break;
      case 'KPI':
        this.props.deleteKPIElement({
          step,
          index,
          name,
          type,
          functionArguments,
          functionName,
          functionElementIndex,
        });
        break;
      case 'createFunction':
        this.props.deleteCreateFunctionElement({
          step,
          index,
          name,
          type,
          functionArguments,
          functionName,
          functionElementIndex,
        });
        break;

      default:
        break;
    }
    this.props.closePopover();
  }

  editElement(element) {
    const step = element.getAttribute('data-step');
    const index = element.getAttribute('aria-describedby');
    const screen = element.getAttribute('data-screen');
    const name = element.getAttribute('data-name');
    const type = element.getAttribute('data-type');

    const functionName = element.getAttribute('data-function-name');
    const functionElementIndex = element.getAttribute('data-function-eind');
    const functionArguments = element.getAttribute('data-function-arguments');
    const activeStepElement = element.getAttribute('data-element-active-step');

    if (type === 'operator') {
      this.props.editOperator({ step, index, screen, name, type });
    } else {
      this.props.editElement({
        step,
        index,
        screen,
        name,
        type,
        functionName,
        functionElementIndex,
        functionArguments,
        activeStepElement,
      });
    }

    this.props.closePopover();
  }

  render() {
    let nodeID;
    let isFunction = false;
    const { index, open, anchorEl, closePopover } = this.props;

    if (anchorEl) {
      const functionName = anchorEl.getAttribute('data-function-name');
      const elementType = anchorEl.getAttribute('data-type');

      if (elementType === 'indicators') {
        nodeID = anchorEl.getAttribute('data-element-nodeid');
      }

      if (functionName) {
        isFunction = true;
      }
    }

    return (
      <Popover
        key={index}
        id={index}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={'popover-content'}>
          {nodeID && (
            <Button
              variant="contained"
              className={'btn'}
              component={Link}
              to={`/prometheus/variableCommissions/indicadores/detalle/${nodeID}`}
            >
              <VisibilityIcon className={'icon'} />
            </Button>
          )}
          {!isFunction && (
            <Button
              variant="contained"
              className={'btn'}
              onClick={() => this.deleteElement(anchorEl)}
            >
              <DeleteIcon className={'icon'} />
            </Button>
          )}
          <Button
            variant="contained"
            className={'btn'}
            onClick={() => this.editElement(anchorEl)}
          >
            <EditIcon className={'icon'} />
          </Button>
        </div>
      </Popover>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  deleteIndicatorElement: payload => dispatch(deleteIndicatorElement(payload)),
  deleteKPIElement: payload => dispatch(deleteKPIElement(payload)),
  deleteCreateFunctionElement: payload =>
    dispatch(deleteCreateFunctionElement(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepItemEdit);
