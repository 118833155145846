export const SET_VARIABLE_NAME = 'variablestag/SET_VARIABLE_NAME';
export const SET_VARIABLE_ID = 'variablestag/SET_VARIABLE_ID';
export const SET_VARIABLE_ORGANIZATION = 'variablestag/SET_VARIABLE_ORGANIZATION';
export const SET_VARIABLE_DATASOURCE = 'variablestag/SET_VARIABLE_DATASOURCE';
export const SET_VARIABLE_TAG_NAME = 'variablestag/SET_VARIABLE_TAG_NAME';
export const SET_VARIABLE_TAG_DATE = 'variablestag/SET_VARIABLE_TAG_DATE';
export const SET_VARIABLE_TAG_ESTATUS_EDIT = 'variablestag/SET_VARIABLE_TAG_ESTATUS_EDIT';
export const RESET_VARIABLE_TAG = 'variablestag/RESET_VARIABLE_TAG';


