import { takeLatest, all, call, put } from 'redux-saga/effects';
import { FETCH_EMPLOYEE_CATALOG } from './constants';
import { setEmployeeCatalog, setEmployeeCatalogError } from './actions';
import API from '../../services/api';

export function* fetchEmployeeCatalog(action) {
  const {
    payload: { id },
  } = action;
  try {
    if (id) {
      const response = yield call(API.getEmployeeCatalog, id);
      const {
        data: { employees },
      } = response;
      yield put(setEmployeeCatalog(employees));
    }
  } catch (error) {
    yield put(setEmployeeCatalogError(true));
  }
}

export default function* EmployeeCatalogSaga() {
  yield all([takeLatest(FETCH_EMPLOYEE_CATALOG, fetchEmployeeCatalog)]);
}
