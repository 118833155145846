import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import "./styles.scss";
import HCStatus from '../HCStatus';
import HCTablePanel from '../HCTablePanel';

import MaterialTable from 'material-table';

//HELPERS SEARCH FILTER FORM 
import { columnsDrawer } from '../../helpers';
import { ButtonGroup, Button, Popper, Grow, ClickAwayListener, MenuList, MenuItem, Select } from "@material-ui/core";
import { tableIcons, localization } from "../materialTable/MaterialTable";
import HCHistoryChart from "../HCHistoryChart";

const HCDrawer = ({
  open,
  toggleDrawer,
  detailReport,
  loading,
  planActions,
  observations,
  updateHeadcountVacantLogs,
  translate,
  history,
  historyDataKey
}) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    return
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    return
  };

  const handleCloseObservation = (row) => {
    row.openObservation = false
    return
  };

  const handleOpenObservation = (row) => {
    row.openObservation = true
    return
  }

  const handleMenuItemClickObservation = (event, observation, row) => {
    row.headcountVacantLogIncidentObservationId = observation.id
    updateHeadcountVacantLogs(row)
    return
  };

  const handleClosePlanAction = (row) => {
    row.openPlanAction = false
    return
  };

  const handleOpenPlanAction = (row) => {
    row.openPlanAction = true
    return
  }

  const handleMenuItemClickPlanAction = (event, planAction, row) => {
    row.headcountVacantLogIncidentPlanActionId = planAction.id
    updateHeadcountVacantLogs(row)
    return
  };


  // console.log(detailReport)

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
        <Box p={10} bgcolor="grey.300">
          {loading ?
            <CircularProgress /> :
            (
              <div className="hc_drawer_status">
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <h2>{detailReport.warehouseName}</h2>
                    {/*<p><strong>ID:</strong> {detailReport.id}</p>*/}
                    <p><strong>Plantilla:</strong> {detailReport.warehouse && detailReport.warehouse.workTeamTemplate ? detailReport.warehouse.workTeamTemplate.workTeamTemplate : '- not work team template selected'} </p>
                    <p className="hcds_p"><strong>Estatus: </strong></p> {detailReport.status ? <HCStatus status={detailReport.status.statusName} /> : <span>Status not ready yet</span>}
                    <p><strong>Vacantes:</strong> {detailReport.status ? detailReport.status.vacantNumber : '- not ready'} </p>
                  </Grid>
                  <Grid item xs={8}>
                    <HCHistoryChart
                      history={history}
                      dataKey={historyDataKey} />
                  </Grid>
                  <Grid item xs={12}>
                    <Paper style={{ width: "100%" }}>
                      <MaterialTable
                        icons={tableIcons}
                        localization={localization({ translate: translate })}
                        columns={[
                          { title: 'Con Vacantes desde', field: 'sinceDate' },
                          { title: 'Fecha de Corte', field: 'calculationDate', defaultSort: 'desc' },
                          {
                            title: 'Estatus',
                            field: 'statusName',
                            render: rowData => <div>{rowData.statusName ? <HCStatus status={rowData.statusName} /> : <span>Status not ready yet</span>}</div>,
                          },
                          { title: 'Número de Vacantes', field: 'vacantCounter' },
                          {
                            title: 'Observación', field: 'vacantCounter',
                            render: row => {
                              if (row.vacantCounter > 0)
                                return <Select
                                  labelId={`select-label-${row.id}`}
                                  id={`select-label-${row.id}`}
                                  open={row.openObservation}
                                  onClose={(event) =>
                                    handleCloseObservation(row, event)
                                  }
                                  onOpen={(event) =>
                                    handleOpenObservation(row, event)
                                  }
                                  value={row.incidentObservation ? row.incidentObservation.id : row.headcountVacantLogIncidentObservationId}
                                >
                                  {observations.map(
                                    (input, index) => (
                                      <MenuItem
                                        key={`${row.id}-${input.id}`}
                                        onClick={(event) =>
                                          handleMenuItemClickObservation(
                                            event,
                                            input,
                                            row
                                          )
                                        }
                                        value={input.id}
                                      >
                                        {input.incidentObservationName}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                            }
                          },
                          {
                            title: 'Plan de Acción', field: 'vacantCounter',
                            render: row => {
                              if (row.vacantCounter > 0)
                                return <Select
                                  labelId={`select-label-${row.id}`}
                                  id={`select-label-${row.id}`}
                                  open={row.openPlanAction}
                                  onClose={(event) =>
                                    handleClosePlanAction(row, event)
                                  }
                                  onOpen={(event) =>
                                    handleOpenPlanAction(row, event)
                                  }
                                  value={row.incidentPlanAction ? row.incidentPlanAction.id : row.headcountVacantLogIncidentPlanActionId}
                                >
                                  {planActions.map(
                                    (input, index) => (
                                      <MenuItem
                                        key={`${row.id}-${input.id}`}
                                        onClick={(event) =>
                                          handleMenuItemClickPlanAction(
                                            event,
                                            input,
                                            row
                                          )
                                        }
                                        value={input.id}
                                      >
                                        {input.incidentPlanActionName}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                            }
                          },
                        ]}
                        data={detailReport.vacantsLogs.items}
                        title="Historial de Evaluaciones"
                        detailPanel={[
                          {
                            tooltip: 'Detalle',
                            render: rowData => {
                              return (
                                <div>
                                  <HCTablePanel idVacLog={rowData.id} translate={translate}></HCTablePanel>
                                </div>
                              )
                            },
                          },
                        ]}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            )
          }
        </Box>
      </Box>
    </Drawer >
  );
};

HCDrawer.propTypes = {
  detailReport: PropTypes.object.isRequired,
};

HCDrawer.defaultProps = {
  detailReport: {
    id: "",
    createdAt: "",
    status: {
      statusName: "",
      vacantNumber: ""
    },
    vacantsLogs: {
      items: []
    }
  }
};

export default HCDrawer;
