import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import {
  FETCH_AREA_INDICATORS,
  SET_AREA_INDICATORS,
  SET_AREA_INDICATORS_ERROR,
} from './constants';

const fetchAreaIndicators = (state, payload) => ({
  ...state,
  loading: true,
  error: false,
});

const setAreaIndicators = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  data: action.payload,
});

const setAreaIndicatorsError = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

export const reducer = handleActions(
  {
    [FETCH_AREA_INDICATORS]: fetchAreaIndicators,
    [SET_AREA_INDICATORS]: setAreaIndicators,
    [SET_AREA_INDICATORS_ERROR]: setAreaIndicatorsError,
  },
  INITIAL_STATE,
);
