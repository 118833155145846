import React from 'react';
import SessionControl from '../../utils/SessionControl';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import { store } from '../../App';

import { withSnackbar } from 'notistack';

class Support extends SessionControl {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.isUserSignedIn();
    }

    render() {
        return (
            <Grow in={true}>
                <Container maxWidth="xl">
                    <h1>
                        {"Support"}
                    </h1>
                </Container>
            </Grow>
        );
    }
}

export default withSnackbar(Support);