import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Container } from '@material-ui/core';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { getKPIOrganization, getKPIArea } from '../../../store/kpi/selectors';
import { resetKPIData, setKPIarea, setKPIorganization } from '../../../store/kpi/actions';
import Modal, { Select } from '../../../components/Modal';
import { fetchOrganizations, setOrganizations } from '../../../store/organizations/actions';
import { fetchAreas, setAreas } from '../../../store/areas/actions';
import {
  isOrganizationsloading,
  getOrganizationsdata,
} from '../../../store/organizations/selectors';
import {
  isAreasloading,
  getAreasdata,
} from '../../../store/areas/selectors';
import SessionControl from '../../../utils/SessionControl';


export class SearchKPIS extends SessionControl {
  constructor(props) {
    super(props);
    this.state = {
      showAreaSelect: false,
      user: this.getSecureStore().UserProfileReducer &&
      this.getSecureStore().UserProfileReducer.user
      ? this.getSecureStore().UserProfileReducer.user
      : [],
    }
    props.resetKPIData();
    props.fetchOrganizations();
  }
  hasKpiData = () => {
    let { organization, area } = this.props;
    return organization && area;
  }
  componentDidMount = () => {
    console.log(this.props);
  }
  render() {
    const { organizations, areas } = this.props;
    const { paramProfile, position } = this.state.user;
    let listPermisionOrganization = JSON.parse(JSON.parse(paramProfile))

    let listOrganization = []
    /// ** FILTRO POR EMPRESA QUE NO PUEDA ACCEDER
    organizations && organizations.data.forEach(organization => {
      listPermisionOrganization.filter(r => r.uuid === position.id).forEach(_e => {
        _e.organization.forEach(_a => {
          if (_a.uuid === organization.id) {
            listOrganization.push(organization)
          }
        });
      });
    });

    organizations.data = listOrganization



    return (
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs {...this.props}
              navigation={[
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                { title: 'Buscar comisiones variables', current: true },
              ]}
            />
          </Grid>

          <Modal
            open={true}
            isContinueDisabled={!this.hasKpiData()}
            title="Selecciona la estructura para filtrar comisiones variables"
            onContinue={event => {
              let { area } = this.props;
              this.props.navigate(`/prometheus/variableCommissions/comisiones-variables/lista/${area.id}`)
            }}
            onCancel={event => this.props.navigate('/prometheus/variableCommissions')}
          >
            <Select
              loading={organizations.loading}
              label="Empresa"
              elements={organizations.data}
              onChange={value => {
                this.props.setKPIorganization(value)
                if (value) {
                  this.props.fetchAreas(value);
                  this.setState({ showAreaSelect: true });
                } else {
                  this.props.resetIndicatorData();
                  this.setState({ showAreaSelect: false });
                  this.props.setAreas([]);
                }

              }}
            />
            {this.state.showAreaSelect &&
              <Select
                loading={areas.loading}
                label="Puesto"
                elements={areas.data}
                onChange={value => this.props.setKPIarea(value)}
              />
            }
          </Modal>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  organization: getKPIOrganization(state),
  area: getKPIArea(state),
  organizations: {
    loading: isOrganizationsloading(state),
    data: getOrganizationsdata(state)
  },
  areas: {
    loading: isAreasloading(state),
    data: getAreasdata(state)
  }
});

const mapDispatchToProps = dispatch => ({
  resetKPIData: payload => dispatch(resetKPIData(payload)),
  fetchOrganizations: () => dispatch(fetchOrganizations()),
  fetchAreas: payload => dispatch(fetchAreas(payload)),
  setAreas: payload => dispatch(setAreas(payload)),
  setOrganizations: payload => dispatch(setOrganizations(payload)),
  setKPIarea: payload => dispatch(setKPIarea(payload)),
  setKPIorganization: payload => dispatch(setKPIorganization(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchKPIS);
