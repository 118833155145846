export const INITIAL_STATE = {
  name: '',
  organization: '',
  area: '',
  steps: [[]],
  dictionary: { parameters: [], variables: [], functions: [] },
  expression: '',
  tested: false,
  saved: false,
  sending: false,
  isEditLoading: false,
  isLastFunctionValid: false,
  deleting: false,
  noExistingIds: [],
};
