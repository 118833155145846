import React, { Component } from 'react';
import { connect } from 'react-redux';
import { last } from 'lodash';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  getKPIName,
  getKPISteps,
  getKPIActiveStep,
  isKPITested,
  isLastFunctionKPIValid,
  isKPIsending,
  isKPIdeleting,
  getKPIArea,
  isKPIHaveStructure,
  getNoexistIds,
  isKPIactive,
} from '../../../store/kpi/selectors';
import {
  createKPIStep,
  addKPIElement,
  testKPIexpression,
  saveKPI,
  setLastFunctionKPIValidation,
  editKPI,
  resetSteps,
  saveEditKPI,
  deleteKPI,
  setActiveKPI,
} from '../../../store/kpi/actions';

import StepsHeader from '../../../components/Steps/StepsHeader';
import StepsStack from '../../../components/Steps/StepsStack';
import StepsFooter from '../../../components/Steps/StepsFooter';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { Container } from '@material-ui/core';
import SessionControlSupportMultiLanguaje from '../../../utils/SessionControl';

export class StepsKPI extends SessionControlSupportMultiLanguaje {
  componentDidMount() {
    const {
      params,
      editKPI,
      isKPIHaveStructure,
    } = this.props;

    this.setState({
      orgName: this.getSecureStore().organizations.data.find(org => org.id === this.getSecureStore().kpi.organization.id).name,
      areaName: this.getSecureStore().areas.data.find(area => area.id === this.getSecureStore().kpi.area.id).name,
      activeKPI:false
    })

    if (params.comisionId) {
      editKPI(params.comisionId);
    } else {
      if (!isKPIHaveStructure) {
        this.props.navigate(`/prometheus/variableCommissions/comisiones-variables/crear/`);
      }
    }
  }
  componentWillUnmount = () => {this.props.resetSteps()};

  setKPIActive(active){
    console.log("setKPIActive",active)
    this.setState({
      activeKPI:active
    })

  }


  lastStepItem(steps, activeStep) {
    let item = '';
    const lastElement = last(steps[activeStep]);

    if (typeof lastElement === 'undefined') {
      item = '';
    } else {
      switch (lastElement.type) {
        case 'element':
          item = 'element';
          break;
        case 'step':
          item = 'element';
          break;
        case 'operator':
          item = 'operator';
          break;
        default:
          item = '';
          break;
      }
    }

    return item;
  }
  render() {
    let breadcrumbsLinks;
    const {
      name,
      steps,
      activeStep,
      addElement,
      createStep,
      testKPI,
      testedResult,
      saveKPI,
      setLastFunctionValidation,
      isLastFunctionValid,
      isSending,
      saveEditKPI,
      deleteKPI,
      isDeleting,
      area,
      noExistElements,
      params,
      active,
    } = this.props;

    const {
      orgName,
      areaName
    } = this.state;

    if (params.comisionId) {
      breadcrumbsLinks = [
        { title: "Dashboard", url: "/prometheus/variableCommissions" },
        { title: 'Buscar Comisiones Variables', url: '/prometheus/variableCommissions/comisiones-variables' },
        {
          title: 'Lista de Comisiones Variables',
          url: `/prometheus/variableCommissions/comisiones-variables/lista/${area.id}`,
        },
        {
          title: 'Editar pasos',
          current: true,
        },
      ];
    } else {
      breadcrumbsLinks = [
        { title: "Dashboard", url: "/prometheus/variableCommissions" },
        { title: 'Buscar Comisiones Variables', url: '/prometheus/variableCommissions/comisiones-variables' },
        {
          title: 'Crear Comision Variable',
          url: '/prometheus/variableCommissions/comisiones-variables/crear',
        },
        {
          title: 'Crear pasos',
          current: true,
        },
      ];
    }

    console.log(this.props)
    return (
      <Container fixed>
        <div className="steps-creator">
          <div className="breadcrumbs-container-wrapper">
            <Breadcrumbs {...this.props} navigation={breadcrumbsLinks} />
          </div>
          {params.comisionId && name === '' ? (
            <LoadingIndicator />
          ) : (
              <div>
                <StepsHeader
                  orgName={orgName}
                  areaName={areaName}
                  name={name}
                  type={'KPI'}
                  isEditing={params.comisionId ? true : false}
                />
                <StepsStack
                  type={'KPI'}
                  steps={steps}
                  addElement={payload => addElement(payload)}
                  createStep={payload => createStep(payload)}
                  activeStep={activeStep}
                  lastStepItem={this.lastStepItem(steps, activeStep)}
                  isLastFunctionValid={payload =>
                    setLastFunctionValidation(payload)
                  }
                  noExistElements={noExistElements}
                />
                <StepsFooter
                  type={'KPI'}
                  testIndicator={payload => testKPI(payload)}
                  lastStep={steps[activeStep]}
                  save={() => saveKPI()}
                  edit={payload => saveEditKPI({id:payload,active:!active})}
                  delete={payload => deleteKPI(payload)}
                  activeKPI ={active}
                  setKPIActive ={(active)=>this.setKPIActive(active)}
                  testResult={testedResult}
                  isLastFunctionValid={isLastFunctionValid}
                  isSending={isSending}
                  isEditing={params.comisionId ? true : false}
                  editID={params.comisionId}
                  isDeleting={isDeleting}
                />
              </div>
            )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  activeStep: getKPIActiveStep(state),
  name: getKPIName(state),
  steps: getKPISteps(state),
  testedResult: isKPITested(state),
  isLastFunctionValid: isLastFunctionKPIValid(state),
  isSending: isKPIsending(state),
  isDeleting: isKPIdeleting(state),
  area: getKPIArea(state),
  isKPIHaveStructure: isKPIHaveStructure(state),
  noExistElements: getNoexistIds(state),
  active: isKPIactive(state),
});

const mapDispatchToProps = dispatch => ({
  addElement: payload => dispatch(addKPIElement(payload)),
  createStep: payload => dispatch(createKPIStep(payload)),
  testKPI: payload => dispatch(testKPIexpression(payload)),
  saveKPI: payload => dispatch(saveKPI(payload)),
  setLastFunctionValidation: payload =>
    dispatch(setLastFunctionKPIValidation(payload)),
  editKPI: payload => dispatch(editKPI(payload)),
  saveEditKPI: payload => dispatch(saveEditKPI(payload)),
  deleteKPI: payload => dispatch(deleteKPI(payload)),
  resetSteps: () => dispatch(resetSteps()),
  setActiveKPI: payload => dispatch(setActiveKPI(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StepsKPI);
