import React, { Component } from 'react';
import { connect } from 'react-redux';
import { last } from 'lodash';
import { Button, Container } from '@material-ui/core';
import {
  getCreateFunctionName,
  getCreateFunctionSteps,
  getCreateFunctionActiveStep,
  isCreateFunctionTested,
  isCreateFunctionSending,
  getCreateFunctionExpression,
  isDeletingCreateFunction,
  getCreateFunctionParameters,
} from '../../../store/createFunctions/selectors';
import {
  addCreateFunctionlelement,
  createCreateFunctionStep,
  testCreateFunction,
  saveCreateFunction,
  editCreateFunction,
  saveEditCreateFunction,
  deleteCreateFunction,
  testAdvancedExpression,
  saveAvancedCreateFunction,
  setExpressionCreateFunction,
  saveEditAdvancedCreateFunction,
  resetCreateFunctionData,
} from '../../../store/createFunctions/actions';
import LoadingIndicator from '../../../components/LoadingIndicator';
import StepsHeader from '../../../components/Steps/StepsHeader';
import StepsStack from '../../../components/Steps/StepsStack';
import StepsAdvancedMode from '../../../components/Steps/StepsAdvancedMode';
import StepsFooter from '../../../components/Steps/StepsFooter';
import Breadcrumbs from '../../../components/Breadcrumbs';

import './styles.scss';

export class StepsCreateFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advancedMode: false,
      showBtn: true,
    };
  }

  componentDidMount() {
    const {
      params,
      editCreateFunction,
      parameters,
      name,
    } = this.props;

    if (params.functionId) {
      editCreateFunction(params.functionId);
    } else {
      if (parameters.length === 0 || name === '') {
        this.props.navigate(`/funciones/crear/`);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { steps } = this.props;
    if (prevProps.steps !== steps) {
      if (steps[0].length === 0) {
        this.setState({
          ...this.state,
          advancedMode: true,
          showBtn: false,
        });
      }
    }
  }

  componentWillUnmount = () => this.props.resetCreateFunctionData();

  changeMode() {
    const { advancedMode } = this.state;
    this.setState({
      ...this.state,
      advancedMode: !advancedMode,
    });
  }

  lastStepItem(steps, activeStep) {
    let item = '';
    const lastElement = last(steps[activeStep]);

    if (typeof lastElement === 'undefined') {
      item = '';
    } else {
      switch (lastElement.type) {
        case 'element':
          item = 'element';
          break;
        case 'step':
          item = 'element';
          break;
        case 'operator':
          item = 'operator';
          break;
        default:
          item = '';
          break;
      }
    }

    return item;
  }

  render() {
    let breadcrumbsLinks;
    const {
      name,
      steps,
      activeStep,
      addElement,
      createStep,
      testedResult,
      testExpression,
      saveCreateFunction,
      isSending,
      saveEditCreateFunction,
      deleteCreateFunction,
      expression,
      testAdvancedExpression,
      setExpressionCreateFunction,
      saveEditAdvancedCreateFunction,
      saveAvancedCreateFunction,
      isDeleting,
      params,
    } = this.props;
    const { advancedMode, showBtn } = this.state;

    if (params.functionId) {
      breadcrumbsLinks = [
        { title: "Dashboard", url: "/prometheus/variableCommissions" },
        { title: 'Lista de funciones', url: '/prometheus/variableCommissions/funciones' },
        {
          title: 'Editar pasos',
          current: true,
        },
      ];
    } else {
      breadcrumbsLinks = [
        { title: "Dashboard", url: "/prometheus/variableCommissions" },
        { title: 'Lista de funciones', url: '/prometheus/variableCommissions/funciones' },
        {
          title: 'Crear función',
          url: '/prometheus/variableCommissions/funciones/crear',
        },
        {
          title: 'Crear pasos',
          current: true,
        },
      ];
    }
    return (
      <Container fixed>
        <div className="steps-creator">
          <div className="breadcrumbs-container-wrapper">
            <Breadcrumbs {...this.props} navigation={breadcrumbsLinks} />
          </div>
          {params.functionId && name === '' ? (
            <LoadingIndicator />
          ) : (
              <div>
                <div className="steps-creator-header">
                  {showBtn && (
                    <Button
                      variant="contained"
                      className={'advanced-btn'}
                      onClick={() => this.changeMode()}
                    >
                      {advancedMode ? (
                        <span>Cambiar a Modo Pasos</span>
                      ) : (
                          <span>Cambiar a Modo Avanzado</span>
                        )}
                    </Button>
                  )}
                </div>
                <StepsHeader name={name} type={'createFunction'} />
                {advancedMode ? (
                  <StepsAdvancedMode
                    expression={expression}
                    isEditing={params.functionId ? true : false}
                    testIndicator={payload => testAdvancedExpression(payload)}
                    testResult={testedResult}
                    isSending={isSending}
                    editID={params.functionId}
                    save={() => saveAvancedCreateFunction()}
                    edit={payload => saveEditAdvancedCreateFunction(payload)}
                    delete={payload => deleteCreateFunction(payload)}
                    setExpression={payload => setExpressionCreateFunction(payload)}
                    isDeleting={isDeleting}
                  />
                ) : (
                    <div>
                      <StepsStack
                        type={'createFunction'}
                        steps={steps}
                        addElement={payload => addElement(payload)}
                        createStep={payload => createStep(payload)}
                        activeStep={activeStep}
                        lastStepItem={this.lastStepItem(steps, activeStep)}
                      />
                      <StepsFooter
                        type={'createFunction'}
                        testIndicator={payload => testExpression(payload)}
                        lastStep={steps[activeStep]}
                        save={() => saveCreateFunction()}
                        edit={payload => saveEditCreateFunction(payload)}
                        delete={payload => deleteCreateFunction(payload)}
                        testResult={testedResult}
                        isSending={isSending}
                        isLastFunctionValid={true}
                        isEditing={params.functionId ? true : false}
                        editID={params.functionId}
                        expression={expression}
                        isDeleting={isDeleting}
                      />
                    </div>
                  )}
              </div>
            )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  name: getCreateFunctionName(state),
  steps: getCreateFunctionSteps(state),
  activeStep: getCreateFunctionActiveStep(state),
  testedResult: isCreateFunctionTested(state),
  isSending: isCreateFunctionSending(state),
  expression: getCreateFunctionExpression(state),
  isDeleting: isDeletingCreateFunction(state),
  parameters: getCreateFunctionParameters(state),
});

const mapDispatchToProps = dispatch => ({
  addElement: payload => dispatch(addCreateFunctionlelement(payload)),
  createStep: payload => dispatch(createCreateFunctionStep(payload)),
  testExpression: payload => dispatch(testCreateFunction(payload)),
  saveCreateFunction: payload => dispatch(saveCreateFunction(payload)),
  editCreateFunction: payload => dispatch(editCreateFunction(payload)),
  saveEditCreateFunction: payload => dispatch(saveEditCreateFunction(payload)),
  deleteCreateFunction: payload => dispatch(deleteCreateFunction(payload)),
  testAdvancedExpression: payload => dispatch(testAdvancedExpression(payload)),
  saveAvancedCreateFunction: payload =>
    dispatch(saveAvancedCreateFunction(payload)),
  setExpressionCreateFunction: payload =>
    dispatch(setExpressionCreateFunction(payload)),
  saveEditAdvancedCreateFunction: payload =>
    dispatch(saveEditAdvancedCreateFunction(payload)),
  resetCreateFunctionData: () => dispatch(resetCreateFunctionData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepsCreateFunction);
