import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { Fab,Paper,Typography,Box, Grow, LinearProgress,Grid,Button,Container, Card, CardContent } from '@material-ui/core';
import { tableIcons, localization } from '../../components/materialTable/MaterialTable';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Refresh from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';


import { listProducts } from '../../graphql/queries'
import { updateProduct } from '../../graphql/mutations'

import { api, apiPartial } from "../../api/api";
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify';
import awsconfig from '../../aws-exports';

import { withSnackbar } from 'notistack';



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      zindex: 1,
    },
  
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  
    Box: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    Button: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    Typography:{
        fontSize:"5",
    },
    alignCenter: {
      '& input': {
        textAlign: 'center'
      },
    }
  }));

function Products(props) {
    const classes = useStyles();
    const firstRender = useRef(true)
    const [Products, setProducts] = useState([]);
    const [loading, setloading] = React.useState(true);
    
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render

            loadProducts()
            return // skip the code below
        }
        return function cleanup() {
        };
    }, [Products]) // Only re-run the effect if name or email change

   

    const handleInputChange = (event) =>{
         Storage.configure({
            AWSS3: {
              bucket: "bama-products-images",
              region: awsconfig.aws_user_files_s3_bucket_region  
            }
          });
       
          try {
            
          let target = event.target;

          const temp = [...Products]
           temp.forEach((e,i) => {
             if (e.id === target.id) {
               e.loadingImage = true;
             }
           });
           setProducts(temp)


           
           // setloadingImage(true)
            Storage.put(event.target.files[0].name, event.target.files[0])
              .then((result) =>{
                 UpdProducts(target.id,result.key)
               // console.log(result)
               })
              .catch((err) => console.log(err));
          } catch (error) {
            // ...
          }
        
        
        // Products.forEach(e => {
        //     let renderImage = "";
        //     const file = target.files[0];
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //       renderImage = reader.result;
        //     };
        //     if (file) {
        //       reader.readAsDataURL(file);
        //       renderImage = reader.result;
        //     } 
        //     if (e.id === target.name){
        //         e.s3keyImage =renderImage
        //     }
        // });
        
    }


    const loadProducts = () => {      
         api(listProducts, { limit: 300 })
           .then((res) => {
             console.log(res);
             if (firstRender) {
               //  const newList = list.concat({ name });
               const temp = [];
               res.forEach((e, i) => {
                  let itemProduct = {
                    barcodes: e.barcodes,
                    brand: e.brand,
                    category: e.category,
                    currentSellPrice: e.currentSellPrice,
                    description: e.description,
                    discounts: e.discounts,
                    id: e.id,
                    inStock: e.inStock,
                    kardexs: e.kardexs,
                    saleUnit:e.saleUnit,
                    productBrandId: e.productBrandId,
                    productCategoryId: e.productCategoryId,
                    productId: e.productId,
                    productName: e.productName,
                    productOrganizationId: e.productOrganizationId,
                    productSaleUnitId: e.productSaleUnitId,
                    s3keyImage: e.s3keyImage,
                    sellable: e.sellable,
                    loadingImage: false,
                    sku: e.sku,
                    taxes: e.taxes,
                    _version: e._version,
                    organization:e.organization,
                    pricings:e.pricings,
                    marketReleaseDate:e.marketReleaseDate,
                    maxSellingPrice:e.maxSellingPrice,
                    mtcprops:e.mtcprops,
                    orderItems:e.orderItems,
                    saleItems:e.saleItems,
                    returnables:e.returnables,
                    productKitItems:e.productKitItems,
                    quantityPerBox:e.quantityPerBox,
                    returnableContainerProductId:e.returnableContainerProductId,
                    serviceConfig:e.serviceConfig,
                    sourcing:e.sourcing
                  };
                  temp.push(itemProduct);
               });
               setProducts(temp);
               setloading(false);
           ///    setloadingImage(false);
             }
           })
           .catch((err) => {
             console.log(err);
           });
      };


  

      const UpdProducts = (IdProd,fileName) => {
       console.log(fileName)
       let ItemFilter = Products.filter((prod) => prod.id === IdProd);
       let item = {
         id: ItemFilter[0].id,
         s3keyImage: "public/" + fileName,
         _version: ItemFilter[0]._version,
       };
       console.log(item)
        api(updateProduct, { input: item })
          .then((res) => {
           const temp = [...Products]
           temp.forEach((e,i) => {
             if (e.id === res.id && e.productId === res.productId ) {
               e.s3keyImage = res.s3keyImage;
               e.loadingImage = false;
               //console.log(res.s3keyImage)
             }
           });
           setProducts(temp)
           setloading(false);
         //  setloadingImage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      };


      return (
        <Grow in={true} className={classes.root}>
          <Container maxWidth="xl">
            <Typography variant="h5" color="textSecondary">
              {props.translate("Products")}
            </Typography>
            {loading ? (
              <LinearProgress variant="query" color="secondary" />
            ) : (
              <Grid container spacing={4} justify="center">
                <Grid item xs={12}>
                  <Paper>
                    <MaterialTable
                      icons={tableIcons}
                      options={{
                        exportButton: true,
                      }}
                      columns={[
                      
                        {
                          field: "productName",
                          title: props.translate("Product Name"),
                        },
                      
                        { field: "sku", title: props.translate("SKU") },
                       
                        {
                          field: "s3keyImage",
                          title: props.translate("Image"),
                          render: (rowData) => {
                            var srcImg =
                              "https://bama-products-images.s3.amazonaws.com/" +
                              String(rowData.s3keyImage);

                            return (
                              <Grid
                                container
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                justify="center"
                                className={classes.root}
                              >
                                <Grid item xs={12}>
                                  {rowData.loadingImage ? (
                                    <CircularProgress color="secondary" />
                                  ) : (
                                    <img
                                      src={rowData.s3keyImage ? srcImg : ""}
                                      style={{
                                        maxHeight: "50px",
                                      }}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="label"
                                    endIcon={
                                      rowData.s3keyImage ? (
                                        <Refresh />
                                      ) : (
                                        <CloudUploadIcon />
                                      )
                                    }
                                  >
                                    <Typography
                                      variant="body2"
                                      className={classes.Typography}
                                    >
                                      {rowData.s3keyImage
                                        ? props.translate("Refresh")
                                        : props.translate("SubirImagen")}
                                    </Typography>

                                    <input
                                      id={rowData.id}
                                      type="file"
                                      onChange={handleInputChange}
                                      style={{ display: "none" }}
                                    />
                                  </Button>
                                </Grid>
                              </Grid>
                            );
                            // }
                          },
                        },
                      ]}
                      data={Products}
                      title={props.translate("Products")} //
                      detailPanel={(rowData) => {
                        return (
                          <Grid item xs>
                            <Card elevation={0}>
                              <CardContent>
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item xs={2}>
                                    {props.translate("Id")}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {props.translate("Brand")}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {props.translate("Category")}
                                  </Grid>
                                  <Grid item xs={2}>
                                   {props.translate("Sale Unit")}
                                  </Grid>
                                  <Grid item xs={2}>
                                  {props.translate("Barcode")}
                                  </Grid>
                                  <Grid item xs={2}>
                                   {props.translate("Price")}
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item xs={2}>
                                    {rowData.productId}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {rowData.brand.brandName}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {rowData.category.categoryName}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {rowData.saleUnit
                                      ? props.translate(
                                          rowData.saleUnit.saleUnitName
                                        )
                                      : ""}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {rowData.barcodes.map((bc, index) => (
                                      <Box key={`barcode-${index}`}>{bc}</Box>
                                    ))}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {rowData.currentSellPrice
                                      ? "$ " +
                                        rowData.currentSellPrice.toFixed(2)
                                      : ""}
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Container>
        </Grow>
      );
}
export default withSnackbar(Products);