import React, { useState, useEffect } from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Container, Slide, Dialog, AppBar, Toolbar, IconButton } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { PhotoPicker } from 'aws-amplify-react';

import { Storage } from 'aws-amplify';

import awsconfig from '../../../aws-exports';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UserPicture(props) {
  const [userPictureState, setUserPictureState] = useState({
    user: props.user,
    modalOpen: false,
    base64PhotoImage: null,
    filePhoto: null,
    keyS3: null
  })

  useEffect(() => {
    Storage.configure({
      AWSS3: {
        bucket: awsconfig.aws_user_files_s3_bucket,
        region: awsconfig.aws_user_files_s3_bucket_region
      }
    });

    if (userPictureState.user !== props.user)
      setUserPictureState({
        ...userPictureState,
        user: props.user
      })
  }, [userPictureState, props.user]);

  const classes = useStyles();

  const renderUpdateProfilePhoto = () => {
    return (
      <PhotoCameraIcon
        color="action"
        className={classes.fab} />
    );
  }

  const handleUpdateProfilePhoto = (photo) => {
    console.log(photo)
    setUserPictureState({
      ...userPictureState,
      base64PhotoImage: photo
    });
  }

  const handlePickProfilePhoto = (file) => {
    props.handleUploadProfilePhoto(file).then(res => {
      setUserPictureState({
        ...userPictureState,
        keyS3: res.key
      });
    }).catch(err => console.log(err));;
  }

  const handleSaveProfilePhoto = () => {
    props.handleSaveProfilePhoto(userPictureState.keyS3, userPictureState.base64PhotoImage)
      .then(user => console.log(user))
      .catch(err => console.log(err));

    handleCloseModal()
  }

  const handleOpenModal = () => {
    setUserPictureState({
      ...userPictureState,
      modalOpen: true
    });
  };

  const handleCloseModal = () => {
    setUserPictureState({
      ...userPictureState,
      modalOpen: false
    });
  };

  return (
    <Box>
      <Card>
        <CardActionArea
          aria-controls="photo-menu"
          aria-haspopup="true"
          onClick={event => handleOpenModal(event)}>
          {userPictureState.user && userPictureState.user.profilePhotoUrl ? <CardMedia
            style={{ height: 240 }}
            image={userPictureState.user && userPictureState.user.profilePhotoData ? userPictureState.user.profilePhotoData : userPictureState.user.profilePhotoUrl}
            title={userPictureState.user.username}>
            {renderUpdateProfilePhoto()}
          </CardMedia>
            : <Box>
              <Skeleton variant="rect" height={240} />
              {renderUpdateProfilePhoto()}
            </Box>}
        </CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {userPictureState.user && userPictureState.user.fullName ?
              userPictureState.user.fullName
              : <Skeleton variant="text" />}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="div">
            {userPictureState.user && userPictureState.user.userName ?
              userPictureState.user.userName
              : <Skeleton variant="text" />}
          </Typography>
        </CardContent>
      </Card>
      <Dialog fullScreen
        open={userPictureState.modalOpen}
        onClose={handleCloseModal}
        TransitionComponent={Transition}>
        <AppBar
          color="secondary"
          className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.translate("Update your Photo")}
            </Typography>
            <Button
              disabled={!Boolean(userPictureState.keyS3)}
              autoFocus
              color="inherit"
              onClick={handleSaveProfilePhoto}>
              {props.translate("Save")}
            </Button>
          </Toolbar>
        </AppBar>
        <Container>
          {userPictureState.user ?
            <PhotoPicker
              preview
              previewSrc={userPictureState.user && userPictureState.user.profilePhotoData ? userPictureState.user.profilePhotoData : userPictureState.user.profilePhotoUrl}
              headerText={props.translate("Update your Photo")}
              headerHint={props.translate("Add you photo by clicking below")}
              title={props.translate("Select a Photo")}
              onPick={data => handlePickProfilePhoto(data)}
              onLoad={dataURL => handleUpdateProfilePhoto(dataURL)} />
            : <Box>{"Sin Foto: "} {JSON.stringify(userPictureState)}</Box>}
        </Container>
      </Dialog>
    </Box>
  )
}

export default UserPicture;