import React, { Component } from 'react';
import { APIBAMASTAGE } from "../../../env/constants";
import MaterialTable from 'material-table';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import { tableESP } from '../../../helpers';
import { Button, Container, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalAddValueParameter from '../ModalAddValueParameter';
import Lead from '../../../components/Lead';
import { connect } from 'react-redux';
import SessionControlSupportMultiLanguaje from '../../../utils/SessionControl';

class ListValues extends SessionControlSupportMultiLanguaje {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            idParameter: '',
            breadCrumbs: [
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                { title: "Párametros", url: "/prometheus/variableCommissions/parametros" },
                { title: "Lista", url: "/prometheus/variableCommissions/parametros/lista" },
                { title: "Valores", current: true },
            ],
            valorParameter: '',
            dateParameter: '',
            valuesParameter: [],
            permision_read:false,
            permision_write:false,
            lead: { show: false, type: "success", message: "", close: true }
        }
    }

    componentDidMount() {
        this.setState({
            organization: this.getSecureStore().VariableCommissionsReducer.organization,
            area: this.getSecureStore().VariableCommissionsReducer.area
        })
        const { params, navigation } = this.props;
        let jsonParamProfile = JSON.parse(JSON.parse(this.getSecureStore().UserProfileReducer.user.paramProfile))
        //console.log(jsonParamProfile)
        //console.log(this.getSecureStore().UserProfileReducer.user.position)
        /// ** FILTRO POR PERMISO DE LECTURA Y ESCRITURA
        jsonParamProfile.filter(r => r.uuid === this.getSecureStore().UserProfileReducer.user.position.id).forEach(_e => {
            // console.log(_e.path_permissions)
            _e.path_permissions.forEach(_p => {
                if (_p.name === "Parameters") {
                    this.setState({
                        permision_read: _p.read,
                        permision_write: _p.write
                    });
                }
            });

        });

        if (navigation.length - 2 === -1) {
            fetch(`${APIBAMASTAGE}/parameter/${params.parameterId}/values`)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        valuesParameter: data.parameter.values,
                        parameterName: unescape(params.parameterName),
                        readyOrg: true,
                        loading: false,
                        breadCrumbs: [
                            { title: "Dashboard", url: "/prometheus/variableCommissions" },
                            { title: "Párametros", url: "/prometheus/variableCommissions/parametros" },
                            { title: "Valores", current: true },
                        ],
                        idParameter: params.parameterId
                    });
                })
        } else {
            fetch(`${APIBAMASTAGE}/parameter/${params.parameterId}/values`)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        valuesParameter: data.parameter.values,
                        parameterName: unescape(params.parameterName),
                        readyOrg: true,
                        loading: false,
                        breadCrumbs: [
                            { title: "Dashboard", url: "/prometheus/variableCommissions" },
                            { title: "Párametros", url: "/prometheus/variableCommissions/parametros" },
                            { title: "Lista", url: `${navigation[navigation.length - 2].pathname}` },
                            { title: "Valores", current: true },
                        ],
                        idParameter: params.parameterId
                    });
                })
        }
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }


    handleChangeDateParameter = (e) => {
        this.setState({
            dateParameter: e
        });
    }

    handleChangeValorParameter = (e) => {
        this.setState({
            valorParameter: e
        });
    }


    handleDeleteParameter(oldData, id) {

        const { idParameter, valuesParameter } = this.state;

        this.setState({
            loading: true
        });

        fetch(`${APIBAMASTAGE}/parameter/${idParameter}/uploaded-at/${oldData.uploaded_at}`, { method: 'DELETE' })
            .then(response => response.json())
            .then(data => {
                let prov = valuesParameter;
                prov.splice(prov.indexOf(oldData), 1);
                this.setState({
                    valuesParameter: prov,
                    loading: false,
                    lead: { show: true, type: "success", message: "Se eliminó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });

    }


    handleSaveValueParameter = () => {

        const { dateParameter, valorParameter, idParameter, valuesParameter } = this.state;

        let body = {
            "values": [
                {
                    "uploaded_at": dateParameter,
                    "value": parseFloat(valorParameter)
                }
            ]
        }

        this.setState({ loading: true });
        fetch(`${APIBAMASTAGE}/parameter/${idParameter}/values`, { method: 'post', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(data => {
                let ar = valuesParameter;
                const obj = body.values[0];
                ar.push(obj);
                this.setState({
                    open: false,
                    loading: false,
                    valuesParameter: ar,
                    lead: { show: true, type: "success", message: "Se creó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });

    }


    handleEditParameter(oldData, newData, id, name) {

        const { idParameter, valuesParameter } = this.state;

        this.setState({
            loading: true
        });

        let body = {
            value: Number(newData.value),
        }

        fetch(`${APIBAMASTAGE}/parameter/${idParameter}/uploaded-at/${oldData.uploaded_at}`, { method: 'PATCH', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(data => {
                let prov = valuesParameter;
                prov[prov.indexOf(oldData)] = newData;
                this.setState({
                    valuesParameter: prov,
                    loading: false,
                    lead: { show: true, type: "success", message: "Se modificó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });
    }



    render() {

        const {
            dateParameter,
            lead,
            open,
            breadCrumbs,
            loading,
            valuesParameter,
            valorParameter,
            organization,
            area,
            parameterName } = this.state;

        return (
            <Container fixed>
                <Lead
                    lead={lead}
                    dateParameter={dateParameter}
                    handleClose={() => { this.setState({ lead: { show: false, type: "success", message: "", close: true } }) }}
                />
                <ModalAddValueParameter
                    open={open}
                    loading={loading}
                    dateParameter={dateParameter}
                    changeDate={this.handleChangeDateParameter}
                    valorParameter={valorParameter}
                    changeValue={this.handleChangeValorParameter}
                    saveValue={this.handleSaveValueParameter}
                    handleClose={this.handleClose}
                ></ModalAddValueParameter>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs {...this.props} navigation={breadCrumbs} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom>
                            {organization && organization.name}
                        </Typography>
                    </Grid>
                    <Grid
                        className={'btn-wrapper'}
                        item xs={6}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-start"
                    >
                        {this.state.permision_write ?<Button
                            className={'btn accept-btn'}
                            type="submit"
                            onClick={() => this.setState({ open: true, nameParameter: '', dateParameter: '', valorParameter: '', valoresParamaters: [] })}
                        >
                            AGREGAR VALOR
                        </Button>: ""}
                    </Grid>
                    <Grid className={'btn-wrapper'} item xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : this.state.permision_write ? (
                                <MaterialTable
                                    title={`${area && area.name} / ${parameterName}`}
                                    columns={[
                                        { title: 'Fecha', field: 'uploaded_at', editable: 'never' },
                                        { title: 'Valor', field: 'value' }
                                    ]}
                                    data={valuesParameter}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.handleEditParameter(oldData, newData, oldData.id, newData.name);
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.handleDeleteParameter(oldData, oldData.id);
                                                }, 600);
                                            }),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    localization={tableESP}
                                />
                            ):(
                                <MaterialTable
                                    title={`${area && area.name} / ${parameterName}`}
                                    columns={[
                                        { title: 'Fecha', field: 'uploaded_at', editable: 'never' },
                                        { title: 'Valor', field: 'value' }
                                    ]}
                                    data={valuesParameter}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    localization={tableESP}
                                />
                            )}
                    </Grid>
                </Grid>

            </Container>
        );
    }
};


const mapStateToProps = state => ({
    history: state.router,
    navigation: state.navigation ? state.navigation.navigation : null
});

export default connect(mapStateToProps)(ListValues);