import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  FormGroup,
  TextField,
  Grid,
  Typography,
  Button,
  Container,
} from '@material-ui/core';
import ConstructorSelector from '../../../components/ConstructorSelector';
import Breadcrumbs from '../../../components/Breadcrumbs';

import {
  getIndicatorName,
  getIndicatorOrganization,
  getIndicatorArea,
} from '../../../store/indicator/selectors';

import { setIndicatorName } from '../../../store/indicator/actions';

export class InitIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  onFormChange = e => {
    this.setState({
      ...this.state,
      name: `${e.target.value}`,
    });
  };

  saveKPI() {
    const { name } = this.state;
    this.props.setIndicatorName(name);
    this.props.navigate('/prometheus/variableCommissions/indicadores/crear/pasos');
  }

  componentWillMount() {
    if (this.props.name !== '') {
      this.setState({
        ...this.state,
        name: this.props.name,
      });
    }
  }

  render() {
    const { organization, area } = this.props;
    const { name } = this.state;
    return (
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs {...this.props}
              navigation={[
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                { title: 'Buscar Indicadores', url: '/prometheus/variableCommissions/indicadores' },
                { title: 'Crear Indicador', current: true },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              SELECCIONA LA ESTRUCTURA DE TU INDICADOR
          </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className="" onChange={this.onFormChange}>
              <FormControl>
                <FormGroup>
                  <TextField
                    className=""
                    type="text"
                    name="item"
                    variant="outlined"
                    placeholder="Nombre"
                    value={name}
                  />
                </FormGroup>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12}>
            <ConstructorSelector
              title={'Empresa'}
              modal={{
                fatherScreen: 'indicator',
                type: 'organizations',
              }}
              selected={organization}
              disabled={name ? false : true}
            />
            <ConstructorSelector
              title={'Puesto'}
              modal={{
                fatherScreen: 'indicator',
                type: 'area',
                parameter: organization,
              }}
              disabled={organization ? false : true}
              selected={area}
            />
          </Grid>
          <Grid item xs={12} className={'btn-wrapper'}>
            <Button
              className={'accept-btn'}
              onClick={() => this.saveKPI()}
              disabled={organization ? false : true}
            >
              Continuar
          </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  name: getIndicatorName(state),
  organization: getIndicatorOrganization(state),
  area: getIndicatorArea(state),
});

const mapDispatchToProps = dispatch => ({
  setIndicatorName: payload => dispatch(setIndicatorName(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InitIndicator);
