import { createAction } from 'redux-actions';
import {
  SET_CREATEFUNCTION_NAME,
  SET_CREATEFUNCTION_DESC,
  SET_CREATEFUNCTION_PARAMETER,
  ADD_CREATEFUNCTION_ELEMENT,
  CREATE_CREATEFUNCTION_STEP,
  PUSH_ELEMENT_CREATEFUNCTION_STEP,
  TEST_CREATEFUNCTION,
  SAVE_CREATEFUNCTION,
  SET_TEST_CREATEFUNCTION,
  SET_CREATEFUNCTION_SENDING,
  SET_SAVE_CREATEFUNCTION,
  RESET_CREATEFUNCTION_DATA,
  EDIT_CREATEFUNCTION,
  REHYDRATE_CREATEFUNCTION,
  DELETE_CREATEFUNCTION_ELEMENT,
  POP_CREATEFUNCTION_ELEMENT,
  EDIT_CREATEFUNCTION_ELEMENT,
  REPLACE_ELEMENT_CREATEFUNCTION,
  SAVE_EDIT_CREATEFUNCTION,
  DELETE_CREATEFUNCTION,
  DELETE_CREATEFUNCTION_PARAMETER,
  SET_EXPRESSION_CREATEFUNCTION,
  TEST_ADVACED_CREATEFUNCTION_EXPRESSION,
  SAVE_ADVANCED_CREATEFUNCTION,
  SAVE_EDIT_ADVANCED_CREATEFUNCTION,
  SET_DELETING_CREATEFUNCTION,
} from './constants';

export const setCreateFunctionName = createAction(SET_CREATEFUNCTION_NAME);
export const setCreateFunctionDescription = createAction(
  SET_CREATEFUNCTION_DESC,
);
export const setCreateFunctionParameter = createAction(
  SET_CREATEFUNCTION_PARAMETER,
);
export const addCreateFunctionlelement = createAction(
  ADD_CREATEFUNCTION_ELEMENT,
);
export const createCreateFunctionStep = createAction(
  CREATE_CREATEFUNCTION_STEP,
);
export const pushElementCreateFunctionStep = createAction(
  PUSH_ELEMENT_CREATEFUNCTION_STEP,
);
export const testCreateFunction = createAction(TEST_CREATEFUNCTION);
export const saveCreateFunction = createAction(SAVE_CREATEFUNCTION);
export const setTestCreateFunction = createAction(SET_TEST_CREATEFUNCTION);
export const setCreateFunctioSending = createAction(SET_CREATEFUNCTION_SENDING);
export const setSaveCreateFunction = createAction(SET_SAVE_CREATEFUNCTION);
export const resetCreateFunctionData = createAction(RESET_CREATEFUNCTION_DATA);
export const editCreateFunction = createAction(EDIT_CREATEFUNCTION);
export const rehydrateCreateFunction = createAction(REHYDRATE_CREATEFUNCTION);
export const deleteCreateFunctionElement = createAction(
  DELETE_CREATEFUNCTION_ELEMENT,
);
export const popElementCreateFunction = createAction(
  POP_CREATEFUNCTION_ELEMENT,
);
export const editCreateFunctionElement = createAction(
  EDIT_CREATEFUNCTION_ELEMENT,
);
export const replaceElementCreateFunction = createAction(
  REPLACE_ELEMENT_CREATEFUNCTION,
);
export const saveEditCreateFunction = createAction(SAVE_EDIT_CREATEFUNCTION);
export const deleteCreateFunction = createAction(DELETE_CREATEFUNCTION);
export const deleteParameter = createAction(DELETE_CREATEFUNCTION_PARAMETER);
export const setExpressionCreateFunction = createAction(
  SET_EXPRESSION_CREATEFUNCTION,
);
export const testAdvancedExpression = createAction(
  TEST_ADVACED_CREATEFUNCTION_EXPRESSION,
);
export const saveAvancedCreateFunction = createAction(
  SAVE_ADVANCED_CREATEFUNCTION,
);
export const saveEditAdvancedCreateFunction = createAction(
  SAVE_EDIT_ADVANCED_CREATEFUNCTION,
);
export const setCreateFunctionDeleting = createAction(
  SET_DELETING_CREATEFUNCTION,
);
