import { createAction } from 'redux-actions';
import {
  FETCH_DATASOURCES,
  SET_DATASOURCES,
  SET_DATASOURCES_ERROR,
} from './constants';

export const fetchDatasources = createAction(FETCH_DATASOURCES);
export const setDatasources = createAction(SET_DATASOURCES);
export const setDatasourcesError = createAction(SET_DATASOURCES_ERROR);
