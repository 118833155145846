import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { openModalselector } from '../../store/modalSelector/actions';

import { fetchOrganizations } from '../../store/organizations/actions';
import { fetchAreas } from '../../store/areas/actions';
import { fetchDatasources } from '../../store/datasources/actions';
import { fetchEmployees } from '../../store/employees/actions';

import './styles.scss';

export class ModalSelector extends Component {
  componentDidUpdate(prevProps) {
    const { modal, fetchAreas, fetchDatasources, fetchEmployees } = this.props;

    if (prevProps.modal.parameter !== modal.parameter) {
      switch (modal.type) {
        case 'area':
          if (modal.parameter) {
            fetchAreas(modal.parameter);
          }
          break;

        case 'dataSource':
          if (modal.parameter) {
            fetchDatasources(modal.parameter);
          }
          break;

        case 'employee':
          if (modal.parameter) {
            fetchEmployees(modal.parameter);
          }
          break;

        default:
          break;
      }
    }
  }

  componentDidMount() {
    const { modal, fetchorganizations } = this.props;
    if (modal.type === 'organizations') {
      fetchorganizations();
    }
  }

  render() {
    console.log(this.props)
    const { title, selected, openSelector, modal, disabled } = this.props;

    return (
      <div className={'constructor-selector'}>
        <Button
          className={'btn'}
          onClick={() => openSelector(modal)}
          disabled={disabled}
        >
          {selected ? (
            <div className={'content'}>
              <span className={'selected-title'}>{title}</span>
              <span className={'selected-name'}>
                {modal.type === 'time'
                  ? selected.period + ' días'
                  : selected.name}
              </span>
            </div>
          ) : (
            <div className={'content'}>
              <span className={'plus-icon'}>+</span>
              <span>{title}</span>
            </div>
          )}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  openSelector: payload => dispatch(openModalselector(payload)),
  fetchorganizations: () => dispatch(fetchOrganizations()),
  fetchAreas: payload => dispatch(fetchAreas(payload)),
  fetchDatasources: payload => dispatch(fetchDatasources(payload)),
  fetchEmployees: payload => dispatch(fetchEmployees(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelector);
