import React, { useState, useEffect } from 'react';
import { Box, Grid, FormHelperText, Typography, IconButton, Fab, MenuItem, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
// import RoomIcon from '@material-ui/icons/Room';
// import { arrayIncludes } from '@material-ui/pickers/_helpers/utils';

// import ValidationTextField from '../../../components/ValidationTextField/ValidationTextField';
import ValidationTextField from '../../ValidationTextField/ValidationTextField';

import { SNACKBAR_ERROR } from '../../../utils/SessionControl';

import { PhoneType, AddressComponents } from '../../../schema/enums/schemaEnums';
// import { PhoneType, AddressComponents } from '../../../schema/enums/schemaEnum';
// import MapModal from './MapModal';
// import { haveCorrectLength } from '../../../utils/LengthValidations';
import { haveCorrectLength } from '../../../utils/LengthValidations';
// import { BAMA_LOCATION } from '../../../const/maps';
import { BAMA_LOCATION } from '../../../const/maps'
// import { select } from 'redux-saga/effects';


const PersonalInformation = (props) => {
  // const [openMap, setOpenMap] = useState(false);
  const [position, setPosition] = React.useState(BAMA_LOCATION);
  const [sepomex, setSepomex] = React.useState([])
  const [cp, setCp] = React.useState([])
  const [colony, setColony] = React.useState([])
  // const [state, setState] = React.useState("")
  // const [locality, setlocality] = React.useState("")
  // const [sublocality, setSublocality] = useState("")
  const [mcpios, setMcpios] = useState([])


  useEffect(() => {
    if (sepomex !== props.sepomex) {
      let tempArr = [...props.sepomex]
      tempArr.push({ c_cp: "96458", d_asenta: "23 de Noviembre", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96490", d_asenta: "Adolfo López Mateos", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96499", d_asenta: "Adolfo López Mateos", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96458", d_asenta: "Astilleros de Marina", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96499", d_asenta: "Coatzacoalcos", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96400", d_asenta: "Coatzacoalcos Centro", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96492", d_asenta: "Dos Ríos", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96495", d_asenta: "Ejidal", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96496", d_asenta: "Ejidal", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96497", d_asenta: "Electricistas", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96496", d_asenta: "Elvira Ochoa de Hernández", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96430", d_asenta: "Esfuerzo de los Hermanos Del Trabajo", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96470", d_asenta: "Frutos de La Revolución", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96409", d_asenta: "Gerencia de Petroleos Mexicanos", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96458", d_asenta: "ISSSFA", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96480", d_asenta: "Las Américas", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96460", d_asenta: "Lázaro Cárdenas", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96440", d_asenta: "Lic. Luis Echeverría Álvarez", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96420", d_asenta: "Manuel Avila Camacho", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96410", d_asenta: "Maria de La Piedad", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96496", d_asenta: "Tierra Nueva", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })
      tempArr.push({ c_cp: "96496", d_asenta: "Villas de La Rivera", d_estado: "Veracruz de Ignacio de la Llave", d_mnpio: "Coatzacoalcos", })

      setSepomex(tempArr)
      //setSepomex(props.sepomex)
      let listCP = []
      listCP.push({ c_cp: "96458" })
      listCP.push({ c_cp: "96490" })
      listCP.push({ c_cp: "96499" })
      listCP.push({ c_cp: "96458" })
      listCP.push({ c_cp: "96499" })
      listCP.push({ c_cp: "96400" })
      listCP.push({ c_cp: "96492" })
      listCP.push({ c_cp: "96495" })
      listCP.push({ c_cp: "96496" })
      listCP.push({ c_cp: "96497" })
      listCP.push({ c_cp: "96496" })
      listCP.push({ c_cp: "96430" })
      listCP.push({ c_cp: "96470" })
      listCP.push({ c_cp: "96409" })
      listCP.push({ c_cp: "96458" })
      listCP.push({ c_cp: "96480" })
      listCP.push({ c_cp: "96460" })
      listCP.push({ c_cp: "96440" })
      listCP.push({ c_cp: "96420" })
      listCP.push({ c_cp: "96410" })
      listCP.push({ c_cp: "96496" })
      listCP.push({ c_cp: "96496" })

      props.sepomex.forEach(item_cp => {
        listCP.push({
          c_cp: item_cp.c_cp

        })
      });

      //const uniqueCP = props.sepomex.filter((val,id,listCP) => listCP.indexOf(val) == id);
      const uniqueCP = [...new Map(listCP.map(item => [JSON.stringify(item), item])).values()];
      setCp(uniqueCP)
      //console.log("List CP",uniqueCP)


      let listColonia = []

      tempArr.forEach(item_colonia => {
        listColonia.push({ d_asenta: item_colonia.d_asenta })
      });

      const uniqueColonia = [...new Map(listColonia.map(item => [JSON.stringify(item), item])).values()];
      setColony(uniqueColonia)

      let municipios = []
      tempArr.forEach(itm_municipio => { municipios.push({ d_mnpio: itm_municipio.d_mnpio }) });
      const listMuni = [...new Map(municipios.map(item => [JSON.stringify(item), item])).values()];
      setMcpios(listMuni)

    }
    return function cleanup() {
    };
  }, [props.sepomex, props.customer]);

  const handleAdd = typeItem => {

    switch (typeItem) {
      case "phone":
        let _phones = props.customer.phones
        _phones.push({ phoneType: PhoneType.MOBILE, phone: '' })
        if (_phones.length < 2) {
          props.setCustomer({ ...props.customer, phones: _phones, });
        } else {
          props.customer.phones.splice(1, 1);
        }
        break
      case "email":
        let _emails = props.customer.emails
        _emails.push("")
        if (props.customer.emails.length < 2) {
          props.setCustomer({ ...props.customer, emails: _emails, });
        } else {
          props.customer.emails.splice(1, 1);
        }
        break
      case "address":
        props.setCustomer({
          ...props.customer,
          address: {
            route_street_number: '', // Calle
            sublocality: '', // Col	
            id_locality: '', // ClaCd	
            locality: '', // NomDelegacion	
            political: '', // NomEdo	
            postal_code: '', // CP
            location: {
              coordinates: [position.lat, position.lng]
            },
            references: '', // EntreCalles
          }
        })
        break
      case "taxpayer":
        props.setCustomer({ ...props.customer, taxpayer: {} })
        break
      default:
        break
    }

    return
  };

  const handlePhoneChange = (idx, newValue, field, isDelete) => {
    let _phones = props.customer.phones
    if (!isDelete) {
      _phones[idx][field] = newValue.target.value
    } else {
      delete _phones[idx]
      _phones = [];
    }
    props.setCustomer({ ...props.customer, phones: _phones });
  };

  const handleTaxpayerChange = (newValue, field, isDelete) => {
    let _taxpayer = props.customer
    if (!isDelete) {
      _taxpayer.taxpayer[field] = newValue
    } else {
      delete _taxpayer.taxpayer
    }

    props.setCustomer({ ..._taxpayer })

    return
  };

  const handleEmailChange = (idx, newValue, isDelete) => {
    let _emails = props.customer.emails
    if (!isDelete) {
      _emails[idx] = newValue
    } else {
      delete _emails[idx]
      _emails = [];
    }

    props.setCustomer({ ...props.customer, emails: _emails })

    return
  };

  const handleClickOpenMap = () => {
    handleAdd("address")
    // setOpenMap(true);
  };

  /* Se comenta por que el mapa y el boton que usa esta función fueron quitados */
  /* 
  const handleCloseMap = () => {
    setOpenMap(false);
  };
  */

  const handleAddressChange = (address) => {
    console.log("handleAddressChange", address)
    let _address = props.customer.address;
    address.address_components && address.address_components.forEach(comp => {
      comp.types.forEach(type => {
        switch (type) {
          case AddressComponents.STREET_NUMBER:
            _address.route_street_number = `${_address.route_street_number} ${comp.long_name}`
            break
          case AddressComponents.ROUTE:
            _address.route_street_number = `${comp.long_name} ${_address.route_street_number}`
            break
          case AddressComponents.SUBLOCALITY:
            _address.sublocality = comp.long_name
            break
          case AddressComponents.LOCALITY:
            _address.locality = comp.long_name
            break
          case AddressComponents.POLITICAL:
            _address.political = comp.long_name
            break
          case AddressComponents.ADMINISTRATIVE_AREA_1:
            _address.country = comp.long_name
            break;
          case AddressComponents.POSTAL_CODE:
            _address.postal_code = comp.long_name
            break
          default: break
        }
      })
    })

    _address.location = {
      coordinates: [address.geometry.location.lat(), address.geometry.location.lng()]
    }

    console.log('new handleAddressChange', _address)
    props.setCustomer({ ...props.customer, address: _address, _address: address })
  };

  return (
    <Box my={2}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/*"Proporciónanos tu Nombre para mandarte toda la Información personlizada"*/}
          <Grid container spacing={1}>
            {/* Nombre/s */}
            <Grid item xs={4}>
              <ValidationTextField fullWidth variant="outlined" id="customer-customerName" label={props.translate("Customer Name")} type="text"
                onChange={(name_customer) =>
                  props.setCustomer({ ...props.customer, customerName: name_customer.target.value })
                }
              />
            </Grid>
            <Grid item xs={4}>
              {/* A. Pat. */}
              <ValidationTextField fullWidth variant="outlined" id="customer-customerSecondName" label={props.translate("Customer Second Name")} type="text"
                onChange={(name_customer) =>
                  props.setCustomer({ ...props.customer, customerSecondName: name_customer.target.value, })
                }
              />
            </Grid>
            <Grid item xs={4}>
              {/* A. Mat. */}
              <ValidationTextField fullWidth variant="outlined" id="customer-customerLastName" label={props.translate("Customer Last Name")} type="text"
                onChange={(name_customer) =>
                  props.setCustomer({ ...props.customer, customerLastName: name_customer.target.value, })
                }
              />
            </Grid>
            <Grid item xs={8}>
              {/* Fecha de Nacimiento. */}
              <ValidationTextField fullWidth variant="outlined" id="customer-customer-birthday" label={props.translate("Birthday")} type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(name_customer) =>
                  props.setCustomer({ ...props.customer, customerBirthday: name_customer.target.value })
                }
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="caption" color="secondary">
                {props.translate("Optional")}
              </Typography>
            </Grid>
            {/* RedAlert */}
            <Grid item xs={12}>
              {/*"ApPaterno	ApMaterno	NomPropio"*/}
              {
                props && props.error && (props.error.customerName || props.error.customerSecondName || props.error.customerLastName || props.error.customerBirthday) ? (
                  <Alert severity={SNACKBAR_ERROR}>
                    <Box>{props.error.customerName}</Box>
                    <Box>{props.error.customerSecondName}</Box>
                    <Box>{props.error.customerLastName}</Box>
                    <Box>{props.error.customerBirthday}</Box>
                  </Alert>
                ) : ("")
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1">
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Fab color="primary" size="small" onClick={(event) => handleAdd("phone")} >
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item>{props.translate("Phone")}</Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {/*"Tel	Fax"*/}
              <Grid container spacing={1} justify="center" alignItems="center" >
                {
                  props.customer.phones && props.customer.phones.map((x, i) => {
                    return (
                      <Grid item xs={12} key={"customerPhone" + i}>
                        <Grid container spacing={1} alignItems="center">
                          {/* <Grid item xs={5}>
                            <ValidationTextField
                              select
                              fullWidth
                              variant="outlined"
                              name="typePhone"
                              id="customer-name"
                              label={props.translate("Phone Type")}
                              value={x.phoneType}
                              onChange={(e) => handlePhoneChange(i, e, "phoneType", false)}
                            >
                              {
                                Object.keys(PhoneType).map((option) => {
                                  if (PhoneType[option] === PhoneType.HOME || PhoneType[option] === PhoneType.MOBILE) {
                                    return (
                                      <MenuItem key={PhoneType[option]} value={PhoneType[option]} >
                                        {props.translate(PhoneType[option])}
                                      </MenuItem>
                                    );
                                  }
                                })
                              }
                            </ValidationTextField>
                          </Grid> */}
                          <Grid item xs>
                            {/*"+91 704-011-2342"*/}
                            <ValidationTextField name="phone" fullWidth variant="outlined" id="customer-phone" label={props.translate("Phone")} type="number" value={x.phone} onChange={(e) => handlePhoneChange(i, e, "phone", false)} 
                            onInput={(e) => {
                              e.target.value = e.target.value.slice(0, 10)
                            }} />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton color="secondary" onClick={() => handlePhoneChange(i, null, null, true)} >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          {
                            props.customer.phones[i].phone.length > 0 && !Boolean(
                              haveCorrectLength(props.customer.phones[i].phone, 10)
                            ) ? (
                              <Grid item xs={12}>
                                <Alert severity={SNACKBAR_ERROR}>
                                  {props.translate("The telephone number don't have a correct length")}
                                </Alert>
                              </Grid>
                            ) : ("")
                          }
                        </Grid>
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1">
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Fab color="secondary" size="small" onClick={(event) => handleAdd("email")} >
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item>{props.translate("Email")}</Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="secondary">
                      {props.translate("Optional")}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {/*"EMail"*/}
              <Grid container spacing={1} justify="center" alignItems="center" >
                {
                  props.customer.emails && props.customer.emails.map((x, i) => {
                    return (
                      <Grid item xs={12} key={"customerEmail" + i}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs>
                            <ValidationTextField name="email" fullWidth variant="outlined" id="customer-email" label={props.translate("Email")} type="email" value={x} onChange={(e) => handleEmailChange(i, e.target.value, false)} />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton color="secondary" onClick={() => handleEmailChange(i, null, true)} >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          {Boolean(
                            props.customer.emails[i].length > 0 &&
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                              props.customer.emails[i]
                            )
                          ) ? (
                            <Grid item xs={12}>
                              <Alert severity={SNACKBAR_ERROR}>
                                {props.translate("Email incorrect Format")}
                              </Alert>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1">
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Fab color="primary" size="small" onClick={handleClickOpenMap} >
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item>{props.translate("Address")}</Grid>
                </Grid>
              </Typography>
            </Grid> */}
            {/* <Grid item xs={12} sm={9}>
              {/* Modal del Mapa desactivado junto con el boton para abrirlo... 
              {/* <MapModal showMessage={props.showMessage} bamaLogo={props.bamaLogo} translate={props.translate} title="Address Selection" open={openMap} handleAddressChange={handleAddressChange} handleClose={handleCloseMap} />
              {/*"Calle	Col	ClaCd	NomDelegacion	NomEdo	CP	EntreCalles"
              {props.customer.address ?
                (
                  <Grid container spacing={1}>
                    {/* Boton para abrir modal de mapa. DESACTIVADO 
                    <Grid item xs={12}>
                      {/*
                        <Button style={{ float: "right" }} onClick={(event) => setOpenMap(true)} variant="contained" color="secondary" startIcon= {<RoomIcon />} >
                          {props.translate("Open Map")}
                        </Button>
                      
                    </Grid>
                    {/* RedAlert 
                    <Grid item xs={12} sm={props && props.customer && props.customer.address && !props.customer.address.route_street_number ? 8 : 12} >
                      <ValidationTextField fullWidth variant="outlined" id="customer-address.route_street_number" label={props.translate("Route and Street Number")} type="text" value={props.customer.address.route_street_number}
                        onChange={(route_street_number) =>
                          props.setCustomer({ ...props.customer, address: { ...props.customer.address, route_street_number: route_street_number.target.value}})
                        }
                      />
                    </Grid>
                    {/* RedAlert 
                    <Grid item xs={12} sm={props && props.customer && props.customer.address && !props.customer.address.route_street_number ? 4 : 12} >
                      {
                        props && props.customer && props.customer.address && !props.customer.address.route_street_number ? (
                          <Alert severity={SNACKBAR_ERROR}>
                            <FormHelperText id="customer-address.route_street_number">
                              {props.translate("Enter your Information")}
                            </FormHelperText>
                          </Alert>
                        ) : ("")
                      }
                    </Grid>
                    {/* Colonia 
                    <Grid item xs={12} sm={6}>
                      <Autocomplete fullWidth id="combo-box-demo" options={colony} getOptionLabel={(option) => option.d_asenta}
                        // inputValue={sublocality === "" ?props.customer.address.sublocality:props.customer.address.sublocality === "" ?sublocality:props.customer.address.sublocality}
                        inputValue={props.customer.address.sublocality} renderInput={(params) => (
                          <ValidationTextField {...params} fullWidth variant="outlined" id="customer-address.sublocality" label={props.translate("Sublocality")} type="text"
                          onChange={(sublocality) =>
                              props.setCustomer({ ...props.customer, address: { ...props.customer.address, sublocality: sublocality.target.value } })
                            }
                          />
                        )}
                        onChange={(sublocality) => {
                          try {
                            //let filterlocaly = sepomex.filter(cp => cp.d_asenta === sublocality.target.innerHTML)
                            // setlocality(filterlocaly[0].d_mnpio)
                            // setState(filterlocaly[0].d_estado)
                            // setSublocality(sublocality.target.innerHTML)

                            // locality: filterlocaly[0].d_mnpio,
                            // country: filterlocaly[0].d_estado,
                            ////////// ESTADO /////////
                            props.setCustomer({ ...props.customer, address: { ...props.customer.address, sublocality: sublocality.target.innerHTML }})

                          } catch (error) {
                            console.log(error)
                          }
                        }
                        }
                      />
                    </Grid>
                    {/* Localidad 
                    <Grid item xs={12} sm={6}>
                      <Autocomplete fullWidth id='customer-address.locality' options={mcpios} getOptionLabel={option => option.d_mnpio} getOptionSelected={selected => selected.d_mnpio} renderInput={(params) => < TextField {...params} label={props.translate("Municipality")} variant="outlined" id='customer-address.locality' />}
                      onChange={(evt, locality) =>
                          props.setCustomer({ ...props.customer, address: { ...props.customer.address, locality: locality?.d_mnpio } })
                        }
                      />
                      {/* 
                      <ValidationTextField
                        fullWidth
                        variant="outlined"
                        id="customer-address.locality"
                        label={props.translate("Municipality")}
                        type="text"
                        //value={locality === "" ?props.customer.address.locality:props.customer.address.locality === "" ?locality:props.customer.address.locality}
                        value={props.customer.address.locality}
                        onChange={(locality) =>
                          props.setCustomer({
                            ...props.customer,
                            address: {
                              ...props.customer.address,
                              locality: locality.target.value,
                            }
                          })
                        }
                      />
                      {/* console.log('mcpios: ', mcpios) 
                    </Grid>
                    {/* RedAlert 
                    <Grid item xs={12}>
                      {
                        props && props.customer && props.customer.address && (!props.customer.address.sublocality || !props.customer.address.locality) ? (
                          <Alert severity={SNACKBAR_ERROR}>
                            <FormHelperText id="customer-address.sublocality">
                              {props.translate("Enter your Information")}
                            </FormHelperText>
                          </Alert>
                        ) : ("")
                      }
                    </Grid>
                    {/* Seleccionar Entidad Federativa (estado) 
                    <Grid item xs={12} sm={6}>
                      <ValidationTextField fullWidth variant="outlined" id="customer-address.country" label={props.translate("Country")} type="text" disabled={true} value={props.customer.address.country = 'Veracruz'}
                      //value={state === "" ?props.customer.address.country:props.customer.address.country === "" ?state:props.customer.address.country}
                      /* Se establece entidad Veracruz por defecto y se desactiva para evitar cambios... 
                      // value={props.customer.address.country}
                      // onChange={(_country) =>
                      //   props.setCustomer({
                      //     ...props.customer,
                      //     address: {
                      //       ...props.customer.address,
                      //       country: _country.target.value,
                      //     },
                      //   })
                      // }
                      />
                    </Grid>
                    {/* value={props.customer.address.country}
                      
                    {/* Código Postal 
                    <Grid item xs={12} sm={6}>
                      <Autocomplete fullWidth freeSolo id="combo-box-demo" options={cp} getOptionLabel={(option) => option.c_cp}
                        renderInput={(params) => (
                          <ValidationTextField {...params} fullWidth variant="outlined" id="customer-address.postal_code" label={props.translate("Postal Code")} type="text"
                          onChange={(postal_code) => {
                              props.setCustomer({ ...props.customer, address: { ...props.customer.address, postal_code: postal_code.target.value}})
                            }
                            }
                          />
                        )}
                        onChange={(postal_code) => {
                          let filterCp = sepomex.filter(cp => cp.c_cp === postal_code.target.innerHTML)
                          setColony(filterCp)
                          //setSublocality("")
                          ///////////////////  Codigo postal
                          props.setCustomer({ ...props.customer, address: { ...props.customer.address, postal_code: postal_code.target.innerHTML, } })
                        }
                        }
                      />
                    </Grid>
                    {/* RedAlert 
                    <Grid item xs={12}>
                      {
                        // props && props.customer && props.customer.address && (!props.customer.address.postal_code || !props.customer.address.country) ? (
                        props && props.customer && props.customer.address && !props.customer.address.postal_code ? (
                          <Alert severity={SNACKBAR_ERROR}>
                            <FormHelperText id="customer-address.postal_code">
                              {props.translate("Enter your Information")}
                            </FormHelperText>
                          </Alert>
                        ) : ("")
                      }
                    </Grid>

                  </Grid>
                ) : ("")
              }
            </Grid> */}
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1">
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Fab color="secondary" size="small" onClick={(event) => handleAdd("taxpayer")} >
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item>{props.translate("Taxpayer Info")}</Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="secondary">
                      {props.translate("Optional")}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {/*"NomConsi    RFC ClaGiroCom	TipoCteSHCP CatUsoCFDI_SAT	DesglosaIEPSSiNo"*/}
              <Grid container spacing={1} justify="center" alignItems="center" >
                {props.customer.taxpayer ? (
                  <Grid item xs={12} key={"customerTaxpayer"}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs>
                        <ValidationTextField name="taxpayerName" fullWidth variant="outlined" id="customer-taxpayerName" label={props.translate("Taxpayer name")} type="text" value={props.customer.taxpayer.taxpayerName}
                          onChange={(e) =>
                            handleTaxpayerChange(e.target.value, "taxpayerName", false)
                          }
                        />
                      </Grid>
                      <Grid item xs>
                        <ValidationTextField name="identification" fullWidth variant="outlined" id="customer-identification" label={props.translate("Taxpayer identification")} type="text" value={props.customer.taxpayer.identification}
                          onChange={(e) =>
                            handleTaxpayerChange(e.target.value, "identification", false)
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            handleTaxpayerChange(null, null, true)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {/*"ClaAgeVta	ClaZona	ClaRutaEmb	ClaGiroCom IdSesion	NoVender	PctDescCon"*/}
          <Grid container>
            <Grid item></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PersonalInformation;