import React, { useEffect, useState } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { getAttendant } from '../../../graphql/queries';
import { api } from '../../../api/api';

export default function AuditResult(props) {

    const [auditor, setAuditor] = useState(null)

    useEffect(() => {
        if (auditor === null && props.audit && props.audit.auditor && props.audit.auditor !== 'customerFraudPrevention')
            renderAuditor()

        return function cleanup() { };
    }, [props.audit]) // Only re-run the effect if name or email change

    const renderAuditor = async () => {
        api(getAttendant, { id: props.audit.auditor }).then(_auditor => {
            console.log(_auditor)
            setAuditor(_auditor)
        })

        return
    }

    return <Grid container
        spacing={1}
        justify="flex-start"
        alignItems="center">
        <Grid item xs={6}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
                <Grid item>
                    <b>{props.translate("Start Audit")}</b>
                </Grid>
                <Grid item>
                    {new Date(props.audit.startAudit).toLocaleString()}
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={6}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
                <Grid item>
                    <b>{props.translate("End Audit")}</b>
                </Grid>
                <Grid item>
                    {props.audit.endAudit ? new Date(props.audit.endAudit * 1000).toLocaleString() : ''}
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
                <Grid item>
                    <b>{props.translate("Promoter")}</b>
                </Grid>
                <Grid item>
                    {props.audit.promoter === "customerFraudPrevention" ? props.translate(props.audit.promoter) : props.audit.promoter}
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={6}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
                <Grid item>
                    <b>{props.translate("Auditor")}</b>
                </Grid>
                <Grid item>
                    {auditor ? auditor.fullName : (props.audit.auditor ? props.audit.auditor : '')}
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
                <Grid item>
                    <b>{props.translate("Blacklist")}</b>
                </Grid>
                <Grid item>
                    <Tooltip title={props.translate(props.audit.blacklist === null || props.audit.blacklist ? "Blacklist" : "No Blacklist")}>
                        <FiberManualRecordIcon color={props.audit.blacklist === null || props.audit.blacklist ? "error" : "primary"} />
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={6}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
                <Grid item>
                    <b>{props.translate("Last Update")}</b>
                </Grid>
                <Grid item>
                    {new Date(props.audit._lastChangedAt).toLocaleString()}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}