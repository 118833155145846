import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import { tableIcons, localization } from '../../components/materialTable/MaterialTable';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import {
  listWarehouses,
  listProviders,
  listProviderProducts,
  listOrganizations,
  listProducts
} from "../../graphql/queries";

import { api, apiPartial } from "../../api/api";
import { store } from "../../App";

import {
  Box,
  Zoom,
  Paper,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Container,
  Slide,
  Grid,
  Chip,
  Avatar,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zindex: 1,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Box: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  alignCenter: {
    '& input': {
      textAlign: 'center'
    },
  },
  customInput: {
    minWidth: 100,
  }
}));

export default function OrderPurchaseRow(props) {
  const classes = useStyles();
  const firstRender = useRef(true);
  const [SelectProviders, setSelectProviders] = useState(null);
  const [ProvidersProduct, setProvidersProduct] = useState([]);
  const [rowdata, setrowdata] = useState({});

  const [forecast, setForecast] = useState(null)
  const [sales, setSales] = useState(null)

  useEffect(() => {
    if (firstRender.current) {
      if (firstRender.current) {
        firstRender.current = false; // it's no longer the first render


        return; // skip the code below
      }
    }

    if (SelectProviders !== props.idProvider) {
      buildWarehause()
      setSelectProviders(props.idProvider)
    }

    if (forecast !== props.forecast)
      buildForecast(props.forecast)

    if (sales !== props.sales)
      buildSales(props.sales)

  }, [props.idProvider, props.forecast, props.rows, props.sales]); // Only re-run the effect if name or email change


  const buildWarehause = () => {
    props.setLoadingProviderProducts(true)
    // setWarehause()
    api(listProviderProducts, {
      limit: 500,
      filter: {
        providerId: {
          eq: props.idProvider,
        },
      },
    })
      .then((res) => {
        setProvidersProduct(res)
        loadProducts(res)
        props.setLoadingProviderProducts(false)
      })
      .catch((err) => {
        console.log(err);
        props.setLoadingProviderProducts(false)
      });
  };



  const loadProducts = (listProductsAutoritations) => {
    props.setLoadingProducts(true)
    var params = {}
    if (listProductsAutoritations.length > 100) {
      params = {
        limit: 500
      }
    } else {
      params = {
        limit: 500,
        filter: {
          or: listProductsAutoritations.map(item => {
            var prod = {
              productId: {
                eq: item.productId
              }
            }
            return prod
          })
        }
      }
    }
    api(listProducts, params)
      .then((res) => {
        let listProductsFull = [];
        listProductsAutoritations.forEach((productItem) => {
          try {
          //  console.log("RULO")
            let itemFilter = res.filter((product) => product.productId === productItem.productId && product.organization.organizationId === props.organizationId);
          //  let itemFilterONE = itemFilter.filter((productItem) => typeof productItem.purchable !== "undefined");
          //  let itemFilterTWO = itemFilter.filter((productItem) => typeof productItem.purchable !== "null");
          //   console.log(itemFilterTWO)
            itemFilter.map((item) => {
             // console.log( checkBool(item.purchable));
              listProductsFull.push(item);
            });
          } catch (er) { }
        });
        let fullDataProducts = [];
        var _wa = []

        if (props.selectedWarehouses && props.selectedWarehouses.length > 0)
          _wa = props.selectedWarehouses
        else
          _wa = props.Warehause
          _wa.forEach((element) => {
            listProductsAutoritations.forEach((product, i) => {
              let itemFilter = listProductsFull.filter(
                (productItem) => productItem.productId === product.productId && productItem.sellable  && productItem.purchable
              );
              itemFilter.map((item) => {
                  let customItem = {
                  Articulo: item.productId,
                  ClaUN: element.warehouseId,
                  warehouseRelatedId: element.warehouseRelatedId,
                  Descripcion: item.productName,
                  Paquetes: returDisplay(item.productId) >= 1 ? returDisplay(item.productId) : 1, //item.unitsPerPackage : 1,
                  Piezas: 0,
                  P10: 0,
                  P50: 0,
                  P90: 0,
                  maxProduct: returMax(item.productId,element.warehouseRelatedId),
                  minProduct: returMin(item.productId,element.warehouseRelatedId), 
                  Tienda: element.warehouseName,
                  Inventary: 0,
                  zoneId: element.zoneId,
                  Precio: product.purchasePrice === null ? 0:product.purchasePrice,
                  Subtotal: "0",
                  initialIVA:product.taxes.reduce((obj, t) => { return obj + (t.taxName.indexOf("IVA") !== -1 ? t.rate : 0.0) }, 0),
                  initialIEPS:product.taxes.reduce((obj, t) => { return obj + (t.taxName.indexOf("IEPS") !== -1 ? t.rate : 0.0) }, 0),
                  IVA: item.taxes.reduce((obj, t) => { return obj + (t.taxName.indexOf("IVA") !== -1 ? t.rate : 0.0) }, 0),
                  IEPS: item.taxes.reduce((obj, t) => { return obj + (t.taxName.indexOf("IEPS") !== -1 ? t.rate : 0.0) }, 0),
                  IVA_DEV:item.taxes.reduce((obj, t) => { return obj + (t.taxId.indexOf("IVA+DEV") !== -1 || t.taxId.indexOf("IVA 0") !== -1  ? t.rate : 0.0) }, 0), //
                  TOTAL: "0",
                  sales: {}
                };
                fullDataProducts.push(customItem);
              })
            });
          });

        console.log(listProductsFull)
        props.handleChangeFilterProducts(listProductsFull)
        props.handleChangeFullData(fullDataProducts, fullDataProducts);
        props.loadForecastPrediction(listProductsFull)
        props.loadSalesHistory(listProductsFull)
        props.setLoadingProducts(false)
      })
      .catch((err) => {
        console.log(err);
        props.setLoadingProducts(false)
      });
  };

  function checkBool(bool) {
    return typeof bool === 'boolean' || 
           (typeof bool === 'object' && 
            bool !== null            &&
           typeof bool.valueOf() === 'boolean');
}

  const handleChangeSendOC = (event) => {
    props.handleOC(rowdata.length > 1 ? rowdata : props.items);
  };

  function returDisplay(id) {
    let itemEmpty = 0;
    props.params.forEach((e,i )=> {
      if (e.keyFilter[0] == id && e.param === "display" ) {
        itemEmpty = e.value
        console.log(itemEmpty)
        console.log(e.keyFilter[0])
        console.log(e.id)
        console.log("Display")
      }
    });
    return (parseFloat(itemEmpty))
  }
  function returMax(id,warehouseRelatedId) {
    let itemEmpty = 0;
    props.params.forEach((e,i )=> {
     // console.log(e.keyFilter[0])
     // console.log(e.keyFilter[2])
      if (e.keyFilter[0] == id && e.keyFilter[2] === warehouseRelatedId) {
        let json = JSON.parse(e.value)
        itemEmpty = json.max
        console.log(itemEmpty)
        console.log(e.id)
      }
    });
    return (parseFloat(itemEmpty))
  }

  function returMin(id,warehouseRelatedId) {
    let itemEmpty = 0;
    props.params.forEach((e,i )=> {

      if (e.keyFilter[0] == id && e.keyFilter[2] === warehouseRelatedId) {
        let json = JSON.parse(e.value)
        itemEmpty = json.min
        console.log(itemEmpty)
        console.log(e.id)
      }
    });
    return (parseFloat(itemEmpty))
  }

  const buildSales = async (_sales) => {
    console.log("buildSales", _sales)
    setSales(_sales)

    let _items = props.items
    _items.forEach(f => {
      let _s = _sales.filter(i => (f.Articulo.trim() === i.claarti.trim() && (f.warehouseRelatedId === i.claun || f.ClaUN === i.claun)))
      // console.log(_s)
      _s.forEach(s => {
        if (!f.sales)
          f.sales = {}

        f.sales[s.year] = s.ctd
      })

      /*_sales.forEach(i => {
        try {
          // console.log(f, i, f['Articulo'], f.Articulo, i.claarti, f.ClaUN, i.claun)
          if (f.Articulo === i.claarti && f.ClaUN.toUpperCase() === i.claun.toUpperCase()) {
            if (!f.sales)
              f.sales = {}

            if (!f.sales[i.year])
              f.sales[i.year] = {}

            f.sales[i.year][i.week] = i.ctd
          }
        } catch (err) { }
      })*/
    })

    props.handleChangeFullData(_items, _items);

    console.log("buildSales.finish")

    return
  }

  const buildForecast = async (forecast) => {
    console.log("forecast", forecast)
    setForecast(forecast)
    let _items = props.items
    _items.forEach(i => {
      forecast.forEach(f => {
        // console.log(f.Articulo, i.Articulo, f.ClaUN.toUpperCase(), i.ClaUN)
        if (f.Articulo === i.Articulo && f.ClaUN.toUpperCase() === i.warehouseRelatedId) {
          i.P10 = parseInt(f.p10) 
          i.P50 = parseInt(f.p50) 
          i.P90 = parseInt(f.p90) 
          i.SelectedProyection = parseInt((f.p50 + f.p90) / 2)
          i.Piezas = props.getPiecesForOrder(i)
          // console.log(i)
        }
      })
    })

    props.handleChangeFullData(_items, _items);
    return
  }

  return (
    <div className={classes.root}>
      {props.items.length > 0 ? (
        <Box m={2}>
          <MaterialTable
            icons={tableIcons}
            localization={localization(props)}
            columns={[
              { title: "UN", field: "ClaUN" },
              { title: "Tienda", field: "Tienda" },
              { title: "SKU", field: "Articulo", },
              { title: "Artículo", field: "Descripcion" },

              { title: "Maximo", field: "maxProduct" },
              { title: "Minimo", field: "minProduct" },
              {
                title: "Histórico de Venta",
                field: "sales",
                render: (rowData) => {
                  return (
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        {Object.keys(rowData.sales).map((s) => {
                          return (
                            <Grid key={`${rowData.Articulo}-${s}`} item>
                              <Chip
                                size="medium"
                                avatar={<Avatar><DateRangeIcon /></Avatar>}
                                label={<Box>{s}</Box>}
                              />
                              <Chip
                                size="medium"
                                avatar={<Avatar><LocalOfferIcon /></Avatar>}
                                label={<Box><strong>{rowData.sales[s]}</strong></Box>}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  );
                },
              },
              {
                title: "Proyección Minima", field: "P10",
                render: (rowData) => {
                  return (
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item>
                          {`${rowData.P10}`}
                        </Grid>
                        {Object.keys(rowData.sales).map((s) => {
                          var growIndex = parseInt(((rowData.P10 - rowData.sales[s]) / rowData.sales[s]) * 100)
                          return (
                            <Grid key={`${rowData.Articulo}-f-${s}`} item>
                              <Chip
                                size="medium"
                                style={{ backgroundColor: growIndex > 0 ? "lightgreen" : (growIndex === 0 ? "" : "lightcoral") }}
                                avatar={<Avatar>{"%"}</Avatar>}
                                label={growIndex}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  );
                },
              },
              {
                title: "Proyección Media", field: "P50"
                ,
                render: (rowData) => {
                  return (
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item>
                          {`${rowData.P50}`}
                        </Grid>
                        {Object.keys(rowData.sales).map((s) => {
                          var growIndex = parseInt(((rowData.P50 - rowData.sales[s]) / rowData.sales[s]) * 100)
                          return (
                            <Grid key={`${rowData.Articulo}-f-${s}`} item>
                              <Chip
                                size="medium"
                                style={{ backgroundColor: growIndex > 0 ? "lightgreen" : (growIndex === 0 ? "" : "lightcoral") }}
                                avatar={<Avatar>{"%"}</Avatar>}
                                label={growIndex}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  );
                },
              },
              {
                title: "Proyección Conservadora", field: "_"
                ,
                render: (rowData) => {
                  return (
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item>
                          {`${parseInt((rowData.P90 + rowData.P50) / 2)}`}
                        </Grid>
                        {Object.keys(rowData.sales).map((s) => {
                          var growIndex = parseInt(((((rowData.P90 + rowData.P50) / 2) - rowData.sales[s]) / rowData.sales[s]) * 100)
                          return (
                            <Grid key={`${rowData.Articulo}-f-${s}`} item>
                              <Chip
                                size="medium"
                                style={{ backgroundColor: growIndex > 0 ? "lightgreen" : (growIndex === 0 ? "" : "lightcoral") }}
                                avatar={<Avatar>{"%"}</Avatar>}
                                label={growIndex}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  );
                },
              },
              {
                title: "Proyección Maxima", field: "P90",
                render: (rowData) => {
                  return (
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item>
                          {`${rowData.P90}`}
                        </Grid>
                        {Object.keys(rowData.sales).map((s) => {
                          var growIndex = parseInt(((rowData.P90 - rowData.sales[s]) / rowData.sales[s]) * 100)
                          return (
                            <Grid key={`${rowData.Articulo}-f-${s}`} item>
                              <Chip
                                size="medium"
                                style={{ backgroundColor: growIndex > 0 ? "lightgreen" : (growIndex === 0 ? "" : "lightcoral") }}
                                avatar={<Avatar>{"%"}</Avatar>}
                                label={growIndex}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  );
                },
              },
              { title: "Existencias", field: "Inventary" },
              {
                title: "Paquetes",
                field: "Piezas",
                render: (rowData) => {
                  return (
                    <TextField
                      required
                      id={`${rowData.Articulo}-${rowData.ClaUN}`}
                      value={rowData.Piezas}
                      className={classes.alignCenter}
                      onChange={(e, value) => {
                        const temp = props.items;
                        temp.forEach((a) => {
                          if (a.Articulo === rowData.Articulo && a.ClaUN === rowData.ClaUN) {
                            if (e.target.value.length > 1) {
                              a.Piezas = e.target.value === "" ? 0 : e.target.value.replace(/^0+/, "");
                            } else {
                              a.Piezas = e.target.value === "" ? 0 : e.target.value;
                            }
                          }
                        });

                        props.handleChangeFullData(temp, null);
                        setrowdata(temp);
                      }}
                    />
                  );
                },
              },
              { title: "Piezas por Paquete", field: "Paquetes" },
              { title: "IVA+DEV", 
              field: "IVA_DEV",
              type: "numeric",
              render: (rowData) => {
                return (<Box>
                  <Grid
                    container
                    spacing={2}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item className={classes.customInput}>
                      {rowData.IVA_DEV}
                    </Grid>
                  </Grid>
                </Box>)
              },
            },
              {
                title: "Precio",
                field: "Precio",
                type: "numeric",
                render: (rowData) => {
                  return (<Box>
                    <Grid
                      container
                      spacing={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item className={classes.customInput}>
                        {"$ " + rowData.Precio.toFixed(4)}
                      </Grid>
                    </Grid>
                  </Box>)
                },
              },
              {
                title: "Subtotal",
                field: "Subtotal",
                type: "numeric",
                render: (rowData) => {
                  return (<Box>
                    <Grid
                      container
                      spacing={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid className={classes.customInput}>
                        {"$ " + rowData.Subtotal}
                      </Grid>
                    </Grid>
                  </Box>)
                },
              },
              {
                title: "IVA",
                field: "IVA",
                type: "numeric",
                render: (rowData) => {
                  return (<Box>
                    <Grid
                      container
                      spacing={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid className={classes.customInput}>
                        {"$ " + rowData.IVA}
                      </Grid>
                    </Grid>
                  </Box>)
                },
              },
              {
                title: "IEPS",
                field: "IEPS",
                type: "numeric",
                render: (rowData) => {
                  return (<Box>
                    <Grid
                      container
                      spacing={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid className={classes.customInput}>
                        {"$ " + rowData.IEPS}
                      </Grid>
                    </Grid>
                  </Box>)
                },
              },
              {
                title: "TOTAL",
                field: "TOTAL",
                type: "numeric",
                render: (rowData) => {
                  return (<Box>
                    <Grid
                      container
                      spacing={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid className={classes.customInput}>
                        {"$ " + rowData.TOTAL}
                      </Grid>
                    </Grid>
                  </Box>)
                },
              },
            ]}
            data={props.items}
            title=""
            options={{
              fixedColumns: {
                left: 4, 
                right: 0
              }
            }}
            components={{
              Toolbar: (props) => (
                <div>
                  <MTableToolbar {...props} />
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {props.enabled ? (
                              ""
                            ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleChangeSendOC}
                                >
                                  Verificar orden de compra
                                </Button>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ),
            }}
          />
        </Box>
      ) : (
          ""
        )}
    </div>
  );
}

