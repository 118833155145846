import React from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab';
import { LinearProgress, TextField } from '@material-ui/core';
import { useRef } from 'react';
import { useState } from 'react';
import { api } from '../../api/api';
import { getProductKit, productKitsByWarehouseId } from '../../graphql/queries';
import { SNACKBAR_INFO, SNACKBAR_WARNING } from '../../utils/SessionControl';
import { withSnackbar } from 'notistack';
import { listProductKitsCustom } from '../../graphql/bamaQueries';

const ShowListKits = (props) => {
    const { warehouseId, handlerChanges, showMessage } = props;
    const firstRender = useRef(true);

    /* loaders */
    const [loaderKits, setLoaderKits] = useState(false)

    /* data*/
    const [listKitOfWareHouse, setListKitOfWareHouse] = useState([]);

    /* selections data */
    const [dataListInfo, setDataListInfo] = useState([]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
        }
        loadKitsByWareHouseId().then(res => {
        }, err => { }).finally(() => setLoaderKits(false))
    }, [props.warehouseId]);

    useEffect(() => {
        // console.log('lista de kits actualizada: ', listKitOfWareHouse)
        loadSelectionInfo();
    }, [listKitOfWareHouse]);

    const loadKitsByWareHouseId = async () => {
        setLoaderKits(true);
        let kits = [];

        await api(productKitsByWarehouseId, { limit: 1000, warehouseId }).then(resp => {
            setListKitOfWareHouse(resp);
            /* 2bafb1d5-8560-4a4f-8f8a-b31fb18f2f8b */
        }, err => {
            console.log('listKits err: ', warehouseId, err)
        });
        return kits
    }

    const loadSelectionInfo = async () => {
        setLoaderKits(true);
        let kitsNames = [];
        if (listKitOfWareHouse.length > 0) {
            await api(listProductKitsCustom, { limit: 3000 }).then(async res => {
                for(let i = 0; i < listKitOfWareHouse.length; i++){
                    let pk = res.filter(k => {
                        if (k.id === listKitOfWareHouse[i].productKitId) {
                            let lenSku = ((k.productKitId).split('_')[1]).length
                            let sku = (k.productKitId)//.split('_')[1]
                            switch (lenSku) {
                                case 4:
                                case 14:
                                case 13:
                                case 11:
                                    k.name = `${k.productKitName} ${sku.substring(1, lenSku)}`
                                    break
                                case 10:
                                case 15:
                                    k.name = `${k.productKitName} ${sku.substring(2, lenSku)}`
                                default:
                                    break;
                            }
                            return k
                        }
                    })
                    pk[0] && kitsNames.push(pk[0])
                }
            }, err => {
                console.log('kitsName err: ', err);
            }).finally(() => {
                setLoaderKits(false);
            });
        }
        kitsNames && setDataListInfo(kitsNames);
    }

    const handleSelectionKit = async ({ target }, evt) => {
        if (evt) {
            await api(getProductKit, { id: evt.id }).then(sendKit => {
                if (sendKit) {
                    if (sendKit.marketFinalDate >= parseInt((+new Date) / 1000)) {
                        sendKit.productKitItems = sendKit.productKitItems.items

                        /** Performance over GraphQL Queries for get ProductKits with all Info */
                        sendKit.productKitItems.forEach(_productKitItem => {
                            if (_productKitItem.product) {
                                _productKitItem.productId = _productKitItem.product.id
                                if (_productKitItem.product.returnableContainerProductId)
                                    _productKitItem.returnableContainerProductId = _productKitItem.product.returnableContainerProductId

                                delete _productKitItem.product
                            }
                        })

                        delete sendKit.saleItems
                        delete sendKit._productKitItems
                        handlerChanges(sendKit);
                    } else {
                        let newDLI = dataListInfo.filter(el => el.id !== evt.id)
                        setDataListInfo(newDLI);
                        showMessage('La promo está vencida... por favor seleccione otra...', SNACKBAR_INFO, 10000, null, null, null, null, null, null, props);
                        props.btnState(false)
                    }
                } else {
                    console.log('vacío: ', evt.productKitId, sendKit)
                    showMessage('La consulta no devolvió datos... por favor verifique que los datos sean correctos', SNACKBAR_WARNING, 10000, null, null, null, null, null, null, props);
                }
            }, err => {
                console.log('err: ', err)
            })
        }
    }

    return (
        <>
            <Autocomplete id='kits' variant='standard' size="small" style={{ display: 'inline-block', width: '40%' }} openOnFocus options={dataListInfo} renderInput={params => loaderKits && dataListInfo.length === 0 ? <LinearProgress /> : <TextField {...params} size="small" label='Lista de Kits... Seleccione...' />} getOptionLabel={option => option.name || ''} getOptionSelected={opt => dataListInfo.find(el => el.name === opt.name)} onChange={handleSelectionKit} />
        </>
    )
}

ShowListKits.propTypes = {
    warehouseId: PropTypes.string.isRequired,
    handlerChanges: PropTypes.func.isRequired
}

export default withSnackbar(ShowListKits)

