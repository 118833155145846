import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';

import Switch from '@material-ui/core/Switch';
import LinearProgress from '@material-ui/core/LinearProgress';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { SNACKBAR_SUCCESS } from '../../utils/SessionControl';


import TagFacesIcon from '@material-ui/icons/TagFaces';
import {
  Box,
  Icon,
  Zoom,
  Paper,
  Popper,
  Select,
  InputLabel,
  MenuItem,
  Dialog,
  AppBar,
  Toolbar,
  MenuList,
  Typography,
  Button,
  Container,
  Slide,
  Grid,
  Grow,
  Menu,
  ListItemIcon,
  ListItemText,
  Chip,
  Input,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { SNACKBAR_ERROR } from "../../utils/SessionControl";
import {
  listOrganizations,
  listProviderProducts,
  listWarehouses,
  listProviders,
  listZones,
  listParams
} from "../../graphql/queries";
import { createRemoteRequest, athenaOperation, sendEmail, sendMailHTML, updateWarehouse, deleteRemoteRequest } from "../../graphql/mutations";
import { onUpdateRemoteRequest } from "../../graphql/bamaQueries";

import { api, apiPartial } from "../../api/api";
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify';

import OrderPurchaseRow from './OrderPurchaseRow'
import { store } from '../../App';

import readXlsxFile from 'read-excel-file'
import ChartModal from "../../components/forecast/ChartModal";
import Forecast from "./Forecast";


import awsconfig from '../../aws-exports';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import html2PDF from "html2pdf-fix-jspdf"
import ConstructorSelector from "../../components/ConstructorSelector";
import { tr } from "react-date-range/dist/locale";


const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button_oc: {
    float: "right",
    position: "absolute",
    right: theme.spacing(10),
  },
  rootChip: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 600,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function OrderPurchase(props) {
  const classes = useStyles();
  const firstRender = useRef(true);
  const anchorRef = React.useRef(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingList, setloadingList] = useState(false);


  const [Organizations, setOrganizations] = useState([]);
  const [Providers, setProviders] = useState([]);
  const [Warehause, setWarehause] = useState([]);

  const [SelectOrganizations, setSelectOrganizations] = useState(0);
  const [SelectProviders, setSelectProviders] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsDefault, setRowsDefault] = useState([]);

  const [ProvidersProduct, setProvidersProduct] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [forecast, setForecast] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openChartForecast, setOpenChartForecast] = useState(false)
  const [globalForecast, setGlobalForecast] = useState(null)
  const [filteredProducts, setFilteredProducts] = useState(null)

  var subscriptionRemoteRequests = null;
  var subscriptionRemoteRequestsFolio = null;
  const [ProductsExistencia, setProductsExistencia] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState("");


  const [open, setOpen] = React.useState(false);
  const [options, setoptions] = useState(["Proyección Conservadora", "Proyección Mínima", "Proyección Media", "Proyección Máxima"]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [zones, setZones] = useState([])
  const [SelectZone, setSelectZone] = useState(0);

  const [cedis, setCedis] = React.useState(false);

  const [loadingValidateOC, setLoadingValidateOC] = React.useState(false);
  const [dataHtml, setdataHtml] = useState({
    nameOrganization: "",
    nameProvider: "",
    date: "",
    emailProvider: "",
    phoneProvider: "",
    addressProvider: "",
    addressOrganization: "",
    rfcOrganization: "",
    tiendas: [],
    cedis: [],
    subtotal: 0,
    IVA: 0,
    IEPS: 0,
    total: 0
  })


  const [openDialogOC, setOpenDialogOC] = React.useState(false);
  const [listFolio, setlistFolio] = React.useState([{
    id: 0,
    folio: "11266"
  }]);

  const [nameTienda, setNameTienda] = React.useState(["Selecciona"]);
  const [listTiendas, setListTiendas] = React.useState([]);
  const [loadTiendas, setLoadTiendas] = React.useState(false);
  const [loadingOc, setLoadingOc] = React.useState(false);

  const [sales, setSales] = useState([])
  const [loadingWarehouses, setLoadingWarehouses] = useState(null)
  const [loadingZones, setLoadingZones] = useState(null)
  const [loadingProviders, setLoadingProviders] = useState(null)
  const [loadingProviderProducts, setLoadingProviderProducts] = useState(null)
  const [loadingProducts, setLoadingProducts] = useState(null)
  const [loadingForecast, setLoadingForecast] = useState(null)
  const [loadingSales, setLoadingSales] = useState(null)
  const [loadingInventory, setLoadingInventory] = useState(null)
  const [selectedWarehouses, setSelectedWarehouses] = useState(null)
  const [params, setParams] = useState(null)
  const [callInventory, setCallInventory] = useState(null)

  const [Taxes, setTaxes] = useState([])

  useEffect(() => {
    if (firstRender.current) {
      if (firstRender.current) {
        firstRender.current = false; // it's no longer the first render

        setDates()
        loadOrganizations()
        return; // skip the code below
      }
    }

    if (filteredProducts !== null && Warehause.length > 0) {
      // subscribeRemoteRequests();
      if (ProductsExistencia.length === 0 && !callInventory && rows.length > 0) {
        setCallInventory(true)
        // loadExistensProductsFromERP()
        loadInventory(filteredProducts)
      }
    }

    Storage.configure({
      AWSS3: {
        bucket: "bama-cloud-order-pruchase",
        region: awsconfig.aws_user_files_s3_bucket_region
      }
    });

    return function cleanup() {
      subscriptionRemoteRequests && subscriptionRemoteRequests.unsubscribe();
      subscriptionRemoteRequestsFolio && subscriptionRemoteRequestsFolio.unsubscribe();
    };
  }, [rows, filteredProducts, Warehause]); // Only re-run the effect if name or email change


  const getPiecesForOrder = (product) => {
    var pieces = 0, order = 0
    try {
      /*
      SI EXISTENCIA < MINIMO : PEDIDO ( PRONOSTICO + MINIMO ) REGLA MODIFICADA JHON // SI EXISTENCIA < MINIMO : PEDIDO ( MINIMO - EXISTENCIA ) 
      SI PRONOSTICO > EXISTENCIA : PEDIDO (( PRONOSTICO - EXISTENCIA ) + MINIMO)
      SI PRONOSTICO <= EXISTENCIA : PEDIDO ( 0 )
       */
      if (product.Inventary < product.minProduct) {
        pieces =  product.minProduct - product.Inventary
       // pieces = product.SelectedProyection + product.minProduct
      } else if (product.SelectedProyection > product.Inventary) {
        pieces = (product.SelectedProyection - product.Inventary) + product.minProduct
      } else if (product.SelectedProyection <= product.Inventary) {
        pieces = 0
      }
      var residuo = pieces % product.Paquetes
      var paquetes = parseInt(pieces / product.Paquetes)
      order = paquetes + (residuo > 0 ? 1 : 0)
    } catch (err) { }

    return order
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index === 0) {
      const temp = rows
      temp.forEach(i => {
        forecast.forEach(f => {
          if (f.Articulo === i.Articulo && f.ClaUN.toUpperCase() === i.warehouseRelatedId) {
            i.SelectedProyection = parseInt((f.p50 + f.p90) / 2)
            i.Piezas = getPiecesForOrder(i)
          }
        })
      })
      //console.log(temp)
      handleChangeFullData(temp, null)
    } else if (index === 1) {
      const temp = rows
      temp.forEach(i => {
        forecast.forEach(f => {
          if (f.Articulo === i.Articulo && f.ClaUN.toUpperCase() === i.warehouseRelatedId) {
            i.SelectedProyection = f.p10
            i.Piezas = getPiecesForOrder(i)
          }
        })
      })
      //console.log(temp)
      handleChangeFullData(temp, null)
    } else if (index === 2) {
      const temp = rows
      temp.forEach(i => {
        forecast.forEach(f => {
          if (f.Articulo === i.Articulo && f.ClaUN.toUpperCase() === i.warehouseRelatedId) {
            i.SelectedProyection = f.p50
            i.Piezas = getPiecesForOrder(i)
          }
        })
      })
      //console.log(temp)
      //setRows(temp)
      handleChangeFullData(temp, null)
    } else if (index === 3) {
      const temp = rows
      rows.forEach(i => {
        forecast.forEach(f => {
          if (f.Articulo === i.Articulo && f.ClaUN.toUpperCase() === i.warehouseRelatedId) {
            i.SelectedProyection = f.p90
            i.Piezas = getPiecesForOrder(i)
          }
        })
      })
      //console.log(temp)
      // setRows(temp)
      handleChangeFullData(temp, null)
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleCloseOC = (event) => {

    setOpenDialogOC(false);
  };

  const handleClick = () => {
    //  console.log(`You clicked ${options[selectedIndex]}`);
  };


  const handleCloseFloatMenu = () => {
    setAnchorEl(null)
    return
  }

  const handleOpenFloatMenu = event => {
    setAnchorEl(event.currentTarget);
    return
  }

  const handleClickMenu = () => {
    handleCloseFloatMenu()
    setOpenChartForecast(true)
    return
  }

  const setDates = () => {
    var _startDate = new Date();
    setStartDate(_startDate)
    setEndDate(new Date(_startDate.getTime() + 1000 * 60 * 60 * 24 * 7))
  }


  const handleCloseDialog = () => {
    setOpenDialog(false)
  };



  const [state, setState] = React.useState({
    checked: true,
  });

  const [stateCedis, setStateCedis] = React.useState({
    checked: false,
  });

  const handleChangeCheked = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeChekedCedis = (event) => {
    setStateCedis({ ...stateCedis, [event.target.name]: event.target.checked });
  };


  const handleChangeOrganizations = (event) => {
    setSelectOrganizations(event.target.value);
    loadWarehause(event.target.value)
    loadZones(event.target.value)
    loadParams()
  };

  const handleChangeProviders = (event) => {
    setCallInventory(false)
    setProductsExistencia([])
    setSelectProviders(event.target.value);
    setloadingList(true)
    setRows([]);
    setTaxes([])
    if (SelectProviders !== null){
      setFilteredProducts([])
    }
  };

  const handleChangeZone = (event) => {
    console.log(event.target.value)
    //setNameTienda([])
    setSelectZone(event.target.value);
    let arrayWareHause = []
    if (event.target.value === 0){
      Warehause.forEach((w) => {
        arrayWareHause.push({
          warehouseRelatedId: w.warehouseRelatedId,
          Tienda: w.warehouseName,
        });
      });
      if (rowsDefault && rowsDefault.length > 0) {
        handleChangeFullData(rowsDefault, null);
      }
    }else{
      if (rowsDefault && rowsDefault.length > 0) {
        rowsDefault.forEach(element => {
          if (element.zoneId === event.target.value) {
            arrayWareHause.push(element)
          }
        });
        handleChangeFullData(arrayWareHause, null)
      }else {
        Warehause.forEach(w => {
          if (w.zoneId === event.target.value) {
            arrayWareHause.push({
              warehouseRelatedId: w.warehouseRelatedId,
              Tienda: w.warehouseName
            })
          }
        })
      }
    }
   
    const unique = [...new Map(arrayWareHause.map(item =>
      [item["warehouseRelatedId"], item])).values()];
    setListTiendas(unique)
  };

  const handleChangeFilterProducts = (data) => {
    setFilteredProducts(data)
  }


  const handleChangeFullData = (data, defaultdata) => {

    const temp = [...data];

    const totalProduct = (subtotal, iva, ieps) => {
      let result = 0
      if (iva > 0 && ieps > 0) {
        result = (parseFloat(subtotal) + (parseFloat(iva) + parseFloat(ieps)))
      } else if (iva > 0) {
        result = (parseFloat(subtotal) + parseFloat(iva))
      } else if (ieps > 0) {
        result = (parseFloat(subtotal) + parseFloat(ieps))
      } else {
        result = parseFloat(subtotal)
      }
      return (result)
    }



    if (defaultdata !== null) {
      setRowsDefault(defaultdata)
    }

    temp.forEach((a, i) => {
      if (a.Piezas >= 0) {
        //  rows.length > 0  && rows.forEach(e => {
        //  if (a.Articulo === e.Articulo  && a.ClaUN === e.ClaUN){
        //temp.splice(i, 1, { ...a, ["Subtotal"]: Subtotal.toFixed(2), });
        let Subtotal = ((parseFloat(a.Piezas) *parseFloat(a.Paquetes))* parseFloat(a.Precio))
        // ///// REGLA IVA EN CASO DE TENER IEPS SUMAR PRECIO + IEPS Y OBTENER EL IVA EN BASE A ESO JHON  PRECIO 
        let IVA_IEPS = ((parseFloat(Subtotal) + parseFloat( Subtotal * (a.initialIEPS))) * parseFloat(a.initialIVA))
        a.Subtotal = Subtotal.toFixed(4)
        let TOTAL_IVA = a.initialIEPS > 0 ? IVA_IEPS : (Subtotal * a.initialIVA)
        let TOTAL_IEPS = Subtotal * (a.initialIEPS)
        a.IVA = a.IVA_DEV > 0 ? (0).toFixed(4) : TOTAL_IVA.toFixed(4)
        a.IEPS = TOTAL_IEPS.toFixed(4)
        a.TOTAL = totalProduct(Subtotal, a.IVA_DEV > 0 ? (0).toFixed(4) : TOTAL_IVA, TOTAL_IEPS).toFixed(4)

        //  }
        // });
      }
    });


    // const temp = [...data];
    // temp.forEach((a, i) => {
    //   if (a.Piezas >= 0) {
    //     let Subtotal = (parseFloat(a.Piezas) * parseFloat(a.Precio))
    //     temp.splice(i, 1, { ...a, ["Subtotal"]: Subtotal.toFixed(2), });
    //   }
    // });

    // temp.forEach((a, i) => {
    //   if (a.Piezas > 0){
    //  // let Subtotal = (parseFloat(a.Piezas) * parseFloat(a.Precio))
    //   let TOTAL_IVA = (a.Subtotal * a.IVA) 
    //   temp.splice(i, 1, { ...a, ["IVA"]: TOTAL_IVA.toFixed(2), });
    //   }
    // });

    // temp.forEach((a, i) => {
    //   if (a.Piezas >= 0){
    //   let Subtotal = (parseFloat(a.Piezas) * parseFloat(a.Precio))
    //   temp.splice(i, 1, { ...a, ["IEPS"]: (parseFloat(Subtotal) * parseFloat(a.IEPS)).toFixed(2), });
    //   }
    // });

    // temp.forEach((a, i) => {
    //   if (a.Piezas > 0) {
    //    // let Subtotal = (parseFloat(a.Piezas) * parseFloat(a.Precio))
    //     let TOTAL_IVA = (a.Subtotal * a.IVA) 
    //     let TOTAL_IEPS = (a.Subtotal * a.IEPS) 
    //     temp.splice(i, 1, { ...a, ["TOTAL"]: totalProduct(a.Subtotal,TOTAL_IVA,TOTAL_IEPS).toFixed(2), });
    //   }
    // });
    setRows(temp);
    setloadingList(false)
  };

  const GeneratePDFOC = (event) => {


    setLoadingOc(true)
    var element = document.getElementById("dashbopard");
    var opt = {
      filename: "OrdenOC_" + listFolio[0].folio + ".pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: { width: 1155, height: element.offsetHeight > 15000 ? element.offsetHeight+ 2450: element.offsetHeight+ 500 },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' }
    };

    // var opt = {
    //   margin: [1,0,1,0],
    //   filename: "OrdenOC_" + listFolio[0].folio + ".pdf",
    //   image: { type: "png", quality: 0.98 },
    //   html2canvas: { width:800,height: 2200 },
    //   jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
    // };

    var string = html2PDF().set(opt).from(element).output('datauristring').then(function (dataUri) {

      var byteCharacters = atob(dataUri.split(';base64,')[1]);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: "application/pdf;base64" });
      // console.log(file);
      Storage.put("OrdenOC_" + listFolio[0].folio + ".pdf", file) //sendMailHTML(GetHtml(rows), "jonathan.garrido@bamaglobal.com")) //
        .then(result =>  sendMailHTML(GetHtml(rows), "lgallegos@bama.mx")) //console.log(result))// ///"lgallegos@bama.mx"  // "gustavo.zarza@bamaglobal.com"
        .catch(err => console.log(err));
    }, function (reason) {
      // rechazo
      console.log(reason)
    });

    //  html2PDF().set(opt).from(element).output('save', 'filename.pdf').then(function(value) {

    //  }, function(reason) {
    //  // rechazo
    //  });
    try {
      sendMailHTML(GetHtml(rows), "jonathan.garrido@bamaglobal.com")
    } catch (error) {
      console.log(error)
    }

    try {
      sendMailHTML(GetHtml(rows), "hugo.hernandez@bamaglobal.com")
    } catch (error) {
      console.log(error)
    }

    /////////////////////////// LINEAS COMENTADA PARA ENVIO DE CORREO A PROVEEDOR
    try {
      let Provider = Providers.filter(prov => prov.providerId === SelectProviders)
      sendMailHTML(GetHtml(rows), Provider[0].email)  
    } catch (error) {
      console.log(error)
    }

    console.log(QuerysOrdenCompra(rows))
  };


  const handleOC = (data) => {

    let itemFilterFull = data.filter(product => product.Piezas !== 0)
    if (itemFilterFull.length > 0) {

      if (!state.checked) {
        //setOpenDialog(true)
      }


      loadFOLIO_OC(itemFilterFull)

      //console.log(data) ////////subscribeRemoteRequestsInsertOC
    }


    //  sendQuerysOrdenCompra(data)

    ////////////////////////  HTML- PDF  //////////////////
    // referencia   https://www.npmjs.com/package/wkhtmltopdf
    // referencia   https://bestofjs.org/projects/jspdf

    // let _myHtml = GetHTMLPDF(data);
    // let _html = new DOMParser().parseFromString(_myHtml, "text/xml")


    // var div = document.createElement("div")
    // div.setAttribute("id", "pdf")

    // console.log(_html.children[0])
    // console.log(_myHtml, _html.children[0].children[1])

    // div.appendChild(_html.children[0].children[1].children[0])
    // div.appendChild(_html.children[0].children[0].children[0])

    // document.body.children[1].appendChild(div)

    // console.log(document.getElementById("pdf"))

    // html2canvas(div).then(canvas => {
    //   console.log(canvas)
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF({
    //     orientation: 'landscape',
    //   });
    //   const imgProps = pdf.getImageProperties(imgData);
    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //   pdf.save('download.pdf');

    //   // document.getElementById("File").remove()
    // })

    // html2canvas(document.getElementById("pdf")).then(canvas => {
    //   console.log(canvas)
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF({
    //     orientation: 'landscape',
    //   });
    //   const imgProps = pdf.getImageProperties(imgData);
    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //   pdf.save('download.pdf');

    //   // document.getElementById("File").remove()
    // })

    // html2canvas(document.body).then(canvas => {
    //   console.log(canvas)
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF({
    //     orientation: 'landscape',
    //   });
    //   const imgProps = pdf.getImageProperties(imgData);
    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //   pdf.save('download.pdf');
    // })

    ///////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////

    //console.log(GetHtml(data))
    // console.log(GetDataPDF(data))
    //sendMailHTML(GetHtml(data))
    //console.log(GetFOLIO_OC(data))
    //console.log(data)

  };

  const handleChangeChip = (event) => {
    //setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    //RULO
    setLoadTiendas(true)
    const temp = [...event.target.value]
    let itemFilterTiendas = temp.filter(row => row !== "Selecciona")
    setNameTienda(itemFilterTiendas);

    let arrayListClaun = []
    event.target.value.forEach(e => {
      let itemFilter = rowsDefault.filter(row => row.Tienda === e)
      itemFilter.map((item) => {
        arrayListClaun.push(item);
      });
    });

    if(event.target.value === "" || event.target.value.length === 0){
      handleChangeFullData(rowsDefault, null)
    }else{
      handleChangeFullData(arrayListClaun, null)
    }

    var sW = []
    event.target.value.forEach(e => {
      let itemFilter = Warehause.filter(row => row.warehouseName === e)
      itemFilter.map((item) => {
        sW.push(item);
      });
    });
    setSelectedWarehouses(sW.sort(compareValues('warehouseName', 'asc')))
    //setSelectedWarehouses(sW)
    // console.log(arrayListClaun)
    setLoadTiendas(false)
  };


  const handleDateChangeStart = (date) => {
    setStartDate(date);
  };

  const handleDateChangeEnd = (date) => {
    setEndDate(date);
    console.log(filteredProducts)
    if (filteredProducts) {
      loadForecastPrediction(filteredProducts)
      loadSalesHistory(filteredProducts)
    }
  };


  const loadParams = () => {
    // let param ={filter:{
    //   paramType:{
    //     eq:"product"
    //   }
    // }}
    api(listParams, { limit: 500 })
      .then((res) => {
        let itemFilter = res.filter((param) => param.paramType === "product");
        setParams(itemFilter)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadProductsProviders = (OrganizationsId) => {
    setLoadingProviderProducts(true)
    let listProvidersAutoritation = [];
    api(listProviderProducts, { limit: 500 })
      .then((res) => {
        res.forEach((element) => {
          listProvidersAutoritation.push(element.providerId)
        });
        var result = [];
        result = listProvidersAutoritation.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        })

        loadProviders(result, OrganizationsId)

        setProvidersProduct(result);
        setLoadingProviderProducts(false)
      })
      .catch((err) => {
        console.log(err);
        setLoadingProviderProducts(false)
      });
  };

  const loadProviders = (listProvidersAutoritations, OrganizationsId) => {
    setLoadingProviders(true)
    api(listProviders, { limit: 500 })
      .then((res) => {
        let listProvidersFull = [];
        let listProviders = [];
        listProvidersAutoritations.forEach((providerId) => {
          let itemFilter = res.filter(
            (provider) => provider.providerId === providerId
          );
          itemFilter.map((item) => {
            listProviders.push(item);
          });
        });

        listProviders.forEach(e => {
          if (e.organization.organizationId === OrganizationsId) {
            listProvidersFull.push(e);
          }
        });
        //listProvidersFull.sort(compareValues('providerId', 'asc'))  
        setProviders(listProvidersFull.sort(compareValues('providerName', 'asc')))
        // setProviders(listProvidersFull.sort((a, b) => {
        //   let keyA = parseInt(a.providerId);
        //   let keyB = parseInt(b.providerId);
        //   if (keyA < keyB) return -1;
        //   if (keyA > keyB) return 1;
        //   return 0;
        // }))

        setLoadingProviders(false)
      })
      .catch((err) => {
        console.log(err);
        setLoadingProviders(false)
      });
  };


  const loadOrganizations = () => {
    api(listOrganizations, { limit: 300 })
      .then((res) => {
        setOrganizations(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const loadZones = (IdOrganizations) => {
    setLoadingZones(true)
    api(listZones, { limit: 300 })
      .then((res) => {
        if (IdOrganizations === 1) {
          let totalFilter = []
          let itemFilter = res.filter(ware => ware.zoneType === "warehouse")
          itemFilter.forEach(element => {
            if (element.zoneId !== "4") {
              totalFilter.push(element)
            }
          });
          setZones(totalFilter)
        } else {
          let itemFilter = res.filter(ware => ware.zoneType === "warehouse")
          itemFilter.forEach(element => {
            if (element.zoneId === "4") {
              setZones([element])
            }
          });
        }
        setLoadingZones(false)
      })
      .catch((err) => {
        console.log(err);
        setLoadingZones(false)
      });
  };

  const loadWarehause = (OrganizationsId) => {
    setLoadingWarehouses(true)
    api(listWarehouses, {
      limit: 300,
    })
      .then((res) => {
        let listWarehauses = [];
        res.forEach((element) => {
            if (element.organization.organizationId === OrganizationsId  && element.id !== "abba6ebb-b0f4-4cd8-b2b3-9c542195d320" && element.id !== "4c53d584-c0de-4d9a-acc5-6645661e9a52") {
              if (OrganizationsId === 1){
                let Item = {
                  warehouseId: element.warehouseId,
                  warehouseName: element.warehouseName,
                  warehouseRelatedId: element.warehouseRelatedId,
                  zoneId: element.zone.zoneId
                };
                listWarehauses.push(Item);
              }else{
                let Item = {
                  warehouseId: element.warehouseId,
                  warehouseName: element.warehouseName,
                  warehouseRelatedId: element.warehouseRelatedId,
                };
                listWarehauses.push(Item);
              }
            }
        });

        setWarehause(listWarehauses.sort((a, b) => {
          let keyA = a.warehouseRelatedId;
          let keyB = b.warehouseRelatedId;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }))
      //  console.log(listWarehauses.sort(compareValues('warehouseRelatedId')))
       // setWarehause(listWarehauses.sort(compareValues('warehouseRelatedId')))
        var unique = []

        listWarehauses.forEach(w => {
          unique.push({
            warehouseRelatedId: w.warehouseRelatedId,
            Tienda: w.warehouseName
          })
        })

        setListTiendas(unique)
        setLoadingWarehouses(false)
        loadProductsProviders(OrganizationsId);
      })
      .catch((err) => {
        console.log(err);
        setLoadingWarehouses(false)
      });

  };

  const loadForecastPrediction = async (listOfProducts) => {
    setLoadingForecast(true)
    setFilteredProducts(listOfProducts)
    var _filterProducts = ""
    listOfProducts.forEach(product => {
      if (_filterProducts !== "")
        _filterProducts = _filterProducts + ","
      _filterProducts = _filterProducts + `'${product.productId}'`
    })

    let _startDate = startDate.toISOString().split('T')[0]
    let _endDate = new Date(endDate.getTime() + 1000 * 60 * 60 * 24).toISOString().split('T')[0]
    console.log("loadForecastPrediction", _startDate, _endDate)

    let FullProducts = [];
    listOfProducts.forEach((product) => {
      FullProducts.push(product.productId);
    });
    const n = 30; //tweak this to add more items per line
    const resultProducts = new Array(Math.ceil(FullProducts.length / n))
      .fill()
      .map((_) => FullProducts.splice(0, n));

    let arrayWareHause = []
    if (!selectedWarehouses || selectedWarehouses.length === 0)
      Warehause.forEach(element => {
        arrayWareHause.push(element.warehouseRelatedId)
      });
    else
      selectedWarehouses.forEach(element => {
        let om = element.warehouseRelatedId
        console.log(element, element.warehouseRelatedId, om)
        arrayWareHause = arrayWareHause.concat(om)
      });

    resultProducts.forEach((element) => {
      var params = {
        database: "forecast",
        table: "daily_sales", // **hist_daily_sales** historico de ventas por dia
        queryString: `SELECT * FROM forecast.daily_sales WHERE date >= '${_startDate}' AND date < '${_endDate}' and trim(item_id) in ('${element.join(
          "', '"
        )}') and not (p10 = 0 and p50 = 0 and p90 = 0) and upper(store) in ('${arrayWareHause.join("', '")}')`,
      };
      api(athenaOperation, { params: JSON.stringify(params) }).then(
        (allTodos) => {
          let _f = JSON.parse(allTodos);
          // console.log(_f.Items.length)
          var groupedForecast = [];

          _f.Items.forEach((f) => {
            var exists = false;

            groupedForecast.forEach((g) => {
              if (g.Articulo === f.item_id && g.ClaUN === f.store) {
                exists = true;
                g.p10 += f.p10;
                g.p50 += f.p50;
                g.p90 += f.p90;
              }
            });

            if (!exists)
              groupedForecast.push({
                Articulo: f.item_id,
                ClaUN: f.store,
                p10: f.p10,
                p50: f.p50,
                p90: f.p90,
              });
          });

          setForecast(forecast.concat(groupedForecast));
          setLoadingForecast(false)
          setGlobalForecast(_f);

        //   if (filteredProducts && rows.length > 0) {
        //       setCallInventory(true)
        //       loadInventory()
        //  }
        }
      );
    });

    return
  }

  const loadSalesHistory = async (listOfProducts) => {
    setFilteredProducts(listOfProducts)
    var _filterProducts = ""
    listOfProducts.forEach(product => {
      if (_filterProducts !== "")
        _filterProducts = _filterProducts + ","
      _filterProducts = _filterProducts + `'${product.productId}'`
    })

    let _startDate = new Date(startDate.getTime() - 1000 * 60 * 60 * 24).toISOString().split('T')[0]
    let _endDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24).toISOString().split('T')[0]
    console.log("loadSalesHistory", _startDate, _endDate)


    let FullProducts = [];
    listOfProducts.forEach((product) => {
      FullProducts.push(product.productId);
    });
    const n = 30; //tweak this to add more items per line
    const resultProducts = new Array(Math.ceil(FullProducts.length / n))
      .fill()
      .map((_) => FullProducts.splice(0, n));

    let arrayWareHause = []
    if (!selectedWarehouses || selectedWarehouses.length === 0)
      Warehause.forEach(element => {
        arrayWareHause.push(element.warehouseRelatedId)
      });
    else
      selectedWarehouses.forEach(element => {
        let om = element.warehouseRelatedId
        console.log(element, element.warehouseRelatedId, om)
        arrayWareHause = arrayWareHause.concat(om)
      });

    resultProducts.forEach((element) => {
      setLoadingSales(true)
      var params = {
        database: "forecast",
        table: "hist_daily_sales", // **hist_daily_sales** historico de ventas por dia
        queryString: `SELECT claarti, EXTRACT(year from feckarinv) as year, sum(ctd) ctd, claun FROM forecast.hist_daily_sales where (
          cast(feckarinv as date) between cast('${_startDate}' as date) and cast('${_endDate}' as date)
          or cast(feckarinv as date) between date_add('YEAR', -1, cast('${_startDate}' as date)) and date_add('YEAR', -1, cast('${_endDate}' as date))
          ) and trim(claarti) in ('${element.join("', '")}') and claun in ('${arrayWareHause.join("', '")}') group by claarti, EXTRACT(year from feckarinv), claun having count(ctd) > 0 `,
      };
      api(athenaOperation, { params: JSON.stringify(params) }).then(
        (allTodos) => {
          let _f = JSON.parse(allTodos);
          // console.log(_f.Items.length)
          let groupedSales = _f.Items;

          setSales(sales.concat(groupedSales));
          setLoadingSales(false)
        }
      );
    });

    return
  }

  const subscribeRemoteRequests = (id) => {
    // Subscribe to creation of Todo
    subscriptionRemoteRequests = API.graphql(
      graphqlOperation(onUpdateRemoteRequest, { id: id })
    ).subscribe({
      next: (todoData) => {
        // Do something with the data
        if (todoData
          && todoData.value
          && todoData.value.data
          && todoData.value.data.onUpdateRemoteRequest
          && todoData.value.data.onUpdateRemoteRequest.response
          && todoData.value.data.onUpdateRemoteRequest.id === id) {
          console.log("subscribeRemoteRequests", todoData)
          let response = JSON.parse(todoData.value.data.onUpdateRemoteRequest.response);

          const temp = rows
          temp.forEach(i => {
            response.forEach(f => {
              if (f.ClaArti === i.Articulo && f.ClaUN.toUpperCase() === i.warehouseRelatedId.toUpperCase()) {
                i.Inventary = f.ExiAct
                /*console.log(f)
                console.log(i)*/
              }
            })
          })
          handleChangeFullData(temp, null)
          setProductsExistencia(ProductsExistencia.concat(temp))

          /*api(deleteRemoteRequest, {
            input: {
              id: id
            }
          })*/

          setLoadingInventory(false)
        }
      }
    });
  }

  const subscribeRemoteRequestsFOLIO = (id, items) => {
    // Subscribe to creation of Todo
    subscriptionRemoteRequestsFolio = API.graphql(
      graphqlOperation(onUpdateRemoteRequest, { id: id })
    ).subscribe({
      next: (todoData) => {
        console.log("subscribeRemoteRequestsFOLIO", todoData)
        // Do something with the data
        if (todoData
          && todoData.value
          && todoData.value.data
          && todoData.value.data.onUpdateRemoteRequest
          && todoData.value.data.onUpdateRemoteRequest.response
          && todoData.value.data.onUpdateRemoteRequest.id === id) {
          let response = JSON.parse(todoData.value.data.onUpdateRemoteRequest.response);

          response.forEach((f) => {
            if (f && f.IdConsolidado) {
              setlistFolio([{
                id: 0,
                folio: f.IdConsolidado,
              }]);

              setLoadingValidateOC(false)
              setOpenDialogOC(true)
              GetDataPDF(items)
            }
          });
          console.log(response)
        }
      }
    });
  }

  const subscribeRemoteRequestsInsertOC = (id) => {
    // Subscribe to creation of Todo
    subscriptionRemoteRequests = API.graphql(
      graphqlOperation(onUpdateRemoteRequest, { id: id })
    ).subscribe({
      next: (todoData) => {
        console.log("subscribeRemoteRequests", todoData)
        // Do something with the data
        if (todoData
          && todoData.value
          && todoData.value.data
          && todoData.value.data.onUpdateRemoteRequest
          && todoData.value.data.onUpdateRemoteRequest.response
          && todoData.value.data.onUpdateRemoteRequest.id === id) {
          let response = JSON.parse(todoData.value.data.onUpdateRemoteRequest.response);


          console.log(response)
        }
      }
    });
  }

  const loadInventory = async (listOfProducts)  => {
    let arrayArticulos = []
    listOfProducts.forEach(element => {
      arrayArticulos.push(element.productId)
    });
    let arrayWareHause = []
    if (!selectedWarehouses || selectedWarehouses.length === 0)
      Warehause.forEach(element => {
        arrayWareHause.push(element.warehouseRelatedId)
      });
    else
      selectedWarehouses.forEach(element => {
        let om = element.warehouseRelatedId
        console.log(element, element.warehouseRelatedId, om)
        arrayWareHause = arrayWareHause.concat(om)
      });

    console.log(arrayArticulos)
    console.log(arrayWareHause)

    const n = 150; //tweak this to add more items per line
    /*const resultWarehouse = new Array(Math.ceil(arrayWareHause.length / n))
      .fill()
      .map((_) => arrayWareHause.splice(0, n));*/

    const resultArticulos = new Array(Math.ceil(arrayArticulos.length / n))
      .fill()
      .map((_) => arrayArticulos.splice(0, n));

    resultArticulos.forEach((element) => {
      setLoadingInventory(true)
      var params = {
        database: "forecast",
        table: "inventory",
        queryString: `SELECT * FROM forecast.inventory where trim(claarti) in ('${element.join("', '")}') and claun in ('${arrayWareHause.join("', '")}') and exiact <> 0`,
      };
      api(athenaOperation, { params: JSON.stringify(params) }).then(
        (allTodos) => {
          let _f = JSON.parse(allTodos);
          console.log(_f)

          const temp = rows
          temp.forEach(i => {
            _f.Items.forEach(f => {
              try {
                if (f.claarti.trim() === i.Articulo.trim() && f.claun.toUpperCase() === i.warehouseRelatedId.toUpperCase()) {
                  i.Inventary = f.exiact
                }
              } catch (error) {

              }
            })
          })
          handleChangeFullData(temp, null)
          setProductsExistencia(ProductsExistencia.concat(temp))
          console.log(temp)
          console.log("Existencias")
          setLoadingInventory(false)
        }
      );
    });

    return
  }

  const loadExistensProductsFromERP = () => {
    if (subscriptionId === "") {
      let arrayArticulos = []
      filteredProducts.forEach(element => {
        arrayArticulos.push(element.productId)
      });
      let arrayWareHause = []
      if (!selectedWarehouses || selectedWarehouses.length === 0)
        Warehause.forEach(element => {
          arrayWareHause.push(element.warehouseRelatedId)
        });
      else
        selectedWarehouses.forEach(element => {
          let om = element.warehouseRelatedId
          console.log(element, element.warehouseRelatedId, om)
          arrayWareHause = arrayWareHause.concat(om)
        });


      const n = 150; //tweak this to add more items per line
      /*const resultWarehouse = new Array(Math.ceil(arrayWareHause.length / n))
        .fill()
        .map((_) => arrayWareHause.splice(0, n));*/

      const resultArticulos = new Array(Math.ceil(arrayArticulos.length / n))
        .fill()
        .map((_) => arrayArticulos.splice(0, n));

      console.log("loadExistensProducts", resultArticulos, arrayWareHause)

      resultArticulos.forEach((itemArti, i) => {
        arrayWareHause.forEach((item, i) => {
          /*console.log(item);
          console.log(item.length);*/
          if (item !== "") {
            var params = {};
            if (SelectOrganizations === 1) {
              params = {
                input: {
                  request:
                    '{ "query": "select ClaArti, ClaUN, ExiAct, ExiActUnidadSec from ArtiExi WITH (NOLOCK) where ExiAct > 0 and ClaEmp = 1 and ClaArti in (' +
                    " '" +
                    itemArti.join("', '") +
                    "' " +
                    ") and ClaUN = '" + item + "' "
                    /*") and ClaUN in (" +
                    " '" +
                    item.join("', '") +
                    "' " +
                    ') "*/ + '", "server": "BAMASVR02", "database": "BAMA_ERP" }',
                  startDate: parseInt(new Date().getTime() / 1000),
                  sync: false,
                  source: "fortia_erp",
                },
              };
            } else {
              params = {
                input: {
                  request:
                    '{ "query": "select ClaArti, ClaUN, ExiAct, ExiActUnidadSec from ArtiExi WITH (NOLOCK) where ExiAct > 0 and ClaEmp = 1 and ClaArti in (' +
                    " '" +
                    itemArti.join("', '") +
                    "' " +
                    ") and ClaUN in (" +
                    " '" +
                    item.join("', '") +
                    "' " +
                    ') ", "server": "BAMASVR02/BAMA02", "database": "BAMA_ISTMO" }',
                  startDate: parseInt(new Date().getTime() / 1000),
                  sync: false,
                  source: "fortia_erp",
                },
              };
            }
            // console.log(params);
            setLoadingInventory(true)
            api(createRemoteRequest, params)
              .then((res) => {
                subscribeRemoteRequests(res.id, true, false);
              })
              .catch((err) => {
                console.log(err);
                setLoadingInventory(false)
              });
          }
        });
      });







      // if (arrayArticulos >= 150) {
      //   // let albums = [];
      //   let limit = 150;
      //   let arrayPartArticulos = []
      //   arrayWareHause.forEach((item, index) => {
      //     // if (index % 2) {
      //     //   albums.push([arrayWareHause[index - 1], arrayWareHause[index]]);
      //     // }
      //   });
      // }

      // if (arrayWareHause >= 150) {

      //   let limit = 150;
      //   let arrayWareHause = []
      //   arrayWareHause.forEach((item, index) => {

      //   });

      // }



      // var params = {}
      // if (SelectOrganizations === 1) {
      //   params = {
      //     input: {
      //       request: "{ \"query\": \"select ClaArti, ClaUN, ExiAct, ExiActUnidadSec from ArtiExi where ExiAct > 0 and ClaEmp = 1 and ClaArti in (" + " '" + arrayArticulos.join("', '") + "' " + ") and ClaUN in (" + " '" + arrayWareHause.join("', '") + "' " + ") \", \"server\": \"BAMASVR02\", \"database\": \"BAMA_ERP\" }",
      //       startDate: parseInt(new Date().getTime() / 1000),
      //       sync: false,
      //       source: "fortia_erp"

      //     }
      //   }
      // } else {
      //   params = {
      //     input: {
      //       request: "{ \"query\": \"select ClaArti, ClaUN, ExiAct, ExiActUnidadSec from ArtiExi where ExiAct > 0 and ClaEmp = 1 and ClaArti in (" + " '" + arrayArticulos.join("', '") + "' " + ") and ClaUN in (" + " '" + arrayWareHause.join("', '") + "' " + ") \", \"server\": \"BAMASVR02/\BAMA02\", \"database\": \"BAMA_ISTMO\" }",
      //       startDate: parseInt(new Date().getTime() / 1000),
      //       sync: false,
      //       source: "fortia_erp"

      //     }
      //   }
      // }

      // console.log(params)
      // api(createRemoteRequest, params)
      //   .then((res) => {
      //     subscribeRemoteRequests(res.id, true, false);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  };

  const loadFOLIO_OC = (items) => {
    var params = {}
    if (SelectOrganizations === 1) {
      var params = {
        input: {
          request: "{ \"query\": \"SELECT isnull(MAX(IdConsolidado),0)+1 IdConsolidado from OCAutomatica where ClaEmp=" + SelectOrganizations + " \", \"server\": \"BAMASVR02\", \"database\": \"BAMA_ERP\" }",
          startDate: parseInt(new Date().getTime() / 1000),
          sync: false,
          source: "fortia_erp"

        }
      }
    } else {
      params = {
        input: {
          request: "{ \"query\": \"SELECT isnull(MAX(IdConsolidado),0)+1 IdConsolidado from OCAutomatica where ClaEmp=" + SelectOrganizations + " \", \"server\": \"BAMASVR02/\BAMA02\", \"database\": \"BAMA_ISTMO\" }",
          startDate: parseInt(new Date().getTime() / 1000),
          sync: false,
          source: "fortia_erp"

        }
      }
    }

    api(createRemoteRequest, params)
      .then((res) => {
        console.log(res)
        //setSubscriptionId(res.id)
        setLoadingValidateOC(true)
        subscribeRemoteRequestsFOLIO(res.id, items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadOrder_OC = (query) => {
    var params = {}
    if (SelectOrganizations === 1) {
      var params = {
        input: {
          request: "{ \"query\": \"" + query + " \",  \"server\": \"BAMASVR02\", \"database\": \"BAMA_ERP\" }",
          startDate: parseInt(new Date().getTime() / 1000),
          sync: false,
          source: "fortia_erp"

        }
      }
    } else {
      params = {
        input: {
          request: "{ \"query\": \"" + query + " \",  \"server\": \"BAMASVR02/\BAMA02\", \"database\": \"BAMA_ISTMO\" }",
          startDate: parseInt(new Date().getTime() / 1000),
          sync: false,
          source: "fortia_erp"

        }
      }
    }

    api(createRemoteRequest, params)
      .then((res) => {
        console.log(res)
        //setSubscriptionId(res.id)
        subscribeRemoteRequestsInsertOC(res.id, true, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].charCodeAt(0) : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].charCodeAt(0) : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  function GetHtml(data) {
    let html = "<p>Estimado proveedor requerimos que surta estas ordenes de compra a la brevedad.</p><br /><br /><br /> " +
    "<table  style='font-family:Calibri;font-size:12px'> <tr><th>SKU</th><th>TIENDA</th><th>ARTICULO</th><th>PIEZAS</th><th>PRECIO</th><th>SUBTOTAL</th><th>IVA</th><th>IEPS</th><th>TOTAL</th></tr> ";

    if (stateCedis.checked){
      
      dataHtml.cedis.forEach((e) => {
        console.log(e)
        html = html + "<tr> " +
        "<td align='left' > " + e.Articulo + "</td>" +
        "<td align='left'> CEDIS </td>" +
        "<td align='left'> " + e.Descripcion + "</td>" +
        "<td align='center'> " + e.Piezas + "</td>" +
        "<td align='center' style='width:60px'> "+ "$ " + e.Precio +" </td>" +
        "<td align='right' style='width:80px'> " + "$ " + e.Subtotal + "</td>" +
        "<td align='right' style='width:80px'> " + "$ " + e.totalIVA + "</td>" +
        "<td align='right' style='width:80px'> " + "$ " + e.totalIEPS + "</td>" +
        "<td align='right' style='width:80px'> " + "$ " + e.total + "</td>";
      });

    }else{
      let itemFilterFull = data.filter(product => product.Piezas !== 0)
      Warehause.forEach(element => {
        var validSubtotal = false;
        var NameClaUN = "";
        var NameTienda = "";
        var IVA_DEV = "";
        var PaquetesCLA=[]
        let itemFilter = itemFilterFull.filter(single => single.ClaUN === element.warehouseId)
        itemFilter.map((item) => {
         
          //let Subtotal = (parseFloat(item.Piezas) * parseFloat(item.Precio))
          //let Subtotal = ((parseFloat(item.Piezas) *parseFloat(item.Paquetes))* parseFloat(item.Precio))
            html = html + "<tr> " +
            "<td align='left' > " + item.Articulo + "</td>" +
            "<td align='left'> " + item.Tienda + "</td>" +
            "<td align='left'> " + item.Descripcion + "</td>" +
            "<td align='center'> " + (parseFloat(item.Piezas) *parseFloat(item.Paquetes)) + "</td>" +
            "<td align='center' style='width:60px'> " + "$ " + item.Precio + "</td>" +
            "<td align='right' style='width:80px'> " + "$ " + item.Subtotal+ "</td>" +
            "<td align='right' style='width:80px'> " + "$ " + (item.IVA_DEV > 0 ? (0).toFixed(4) : item.IVA ) + "</td>" +
            "<td align='right' style='width:80px'> " + "$ " + item.IEPS + "</td>" +
            "<td align='right' style='width:80px'> " + "$ " + item.TOTAL + "</td>";
    
          validSubtotal = true;
          NameClaUN = item.ClaUN;
          NameTienda = item.Tienda;
          IVA_DEV = item.IVA_DEV;
          //PaquetesCLA =item.Paquetes;
          let itemPiezasPaq={
            ...item,
            piezasPaq:(parseFloat(item.Piezas) *parseFloat(item.Paquetes))
          }
          PaquetesCLA.push(itemPiezasPaq)
        });
    
        if (validSubtotal) {
          let detailTotalItem = {
            Descripcion: "TOTAL TIENDA:",
            ClaUN: NameClaUN,
            Tienda: NameTienda,
            Piezas: PaquetesCLA.reduce((result, { piezasPaq }) => result += parseFloat(piezasPaq), 0),
            Subtotal: itemFilter.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(2),
            IVA: itemFilter.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(2),
            IEPS: itemFilter.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(2),
            TOTAL: itemFilter.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(2),
          };
          console.log(itemFilter.reduce((result, { Piezas }) => result += parseFloat(Piezas), 0).toFixed(2))
          console.log(PaquetesCLA)
          console.log (detailTotalItem)
          html = html + "<tr> " +
            "<td align='left' > </td>" +
            "<td align='left'> </td>" +
            "<th align='left'> T O T A L  &nbsp T  I E N D A:</th>" +
            "<th align='center'> " + detailTotalItem.Piezas + "</th>" +
            "<th align='center' style='width:60px'> </td>" +
            "<th align='right' style='width:80px'> " + "$ " + detailTotalItem.Subtotal + "</th>" +
            "<th align='right' style='width:80px'> " + "$ " + detailTotalItem.IVA + "</th>" +
            "<th align='right' style='width:80px'> " + "$ " + detailTotalItem.IEPS + "</th>" +
            "<th align='right' style='width:80px'> " + "$ " + detailTotalItem.TOTAL + "</th>";
    
        }
      });
    }
  

  html = html + "<tr> " +
    "<td align='left' > </td>" +
    "<td align='left'> </td>" +
    "<th align='left'> T O T A L  </th>" +
    "<td align='center'> </td>" +
    "<td align='center' style='width:60px'> </td>" +
    "<th align='right' style='width:80px'> " + "$ " + data.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(4) + "</th>" +
    "<th align='right' style='width:80px'> " + "$ " + data.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(4) + "</th>" +
    "<th align='right' style='width:80px'> " + "$ " + data.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(4) + "</th>" +
    "<th align='right' style='width:80px'> " + "$ " + data.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(4) + "</th>" +
    "</tbody>" +
    "</table>" +
    "<br> <a href='https://bama-cloud-order-pruchase.s3.amazonaws.com/public/OrdenOC_" + listFolio[0].folio + ".pdf'>Descarga tu archivo pdf de orden de compra dando click aqui</a>" +
    "</body>" +
    "</html>";
    return (html)
  }


  ////// Detalle de total de tienda
  function GetDataPDF(data) {

    let Organization = Organizations.filter(org => org.organizationId === SelectOrganizations)
    let Provider = Providers.filter(prov => prov.providerId === SelectProviders)

   // console.log(Provider)
    let emailProvider = ""
    let addressProvider = ""
    let addressOrganization = ""
    let PhoneProvider = ""
    let RFC = ""

    try {
      emailProvider = Provider[0].email
    } catch (error) {

    }
    try {
      addressProvider = Provider[0].address
    } catch (error) {

    }
    try {
      addressOrganization = Organization[0].address
    } catch (error) {

    }
    try {
      RFC = Organization[0].taxpayer.taxpayerName
    } catch (error) {

    }
    try {
      PhoneProvider = Provider[0].phones[0].phone
    } catch (error) {

    }

    var today = new Date(),
      date = today.getFullYear() + '/' + zeroPad((today.getMonth() + 1), 2) + '/' + today.getDate();
    let stringdate = date.toString()


    const tempFull = []
    let itemFullClaUN = []
    let itemFilterFull = data.filter(product => parseInt(product.Piezas) !== 0)
    // console.log(itemFilterFull)

    Warehause.forEach(element => {
      var validSubtotal = false;
      var NameClaUN = "";
      var NameTienda = "";
      var NameArticulo = "";
      var IVA_DEV = "";
      var PaquetesCLA=""
      let itemFilter = itemFilterFull.filter(single => single.ClaUN === element.warehouseId)
      itemFilter.map((item) => {

        //console.log(item)
        //let Subtotal = (parseFloat(item.Piezas) * parseFloat(item.Precio))
       // let IVA_IEPS = ((parseFloat(Subtotal) + parseFloat( Subtotal * (a.initialIEPS))) * parseFloat(a.initialIVA))
       //totalIva: item.IVA_DEV > 0 ? (0).toFixed(4) : ((parseFloat(Subtotal) + parseFloat(item.initialIEPS)) * item.initialIVA).toFixed(4),

       // console.log(item)
       // let Subtotal = ((parseFloat(item.Piezas) *parseFloat(item.Paquetes))* parseFloat(item.Precio))
        let ItemClaUN = {
          ClaUN: item.ClaUN,
          Articulo: item.Articulo,
          Tienda: item.Tienda,
          Descripcion: item.Descripcion,
          Piezas: (parseFloat(item.Piezas) *parseFloat(item.Paquetes)),
          Precio: item.Precio,
          Subtotal: item.Subtotal,
          totalIva:  item.IVA_DEV > 0 ? (0).toFixed(4) : item.IVA,
          totalIeps: item.IEPS,
          total: item.TOTAL
        }
        itemFullClaUN.push(ItemClaUN);

        validSubtotal = true;
        NameClaUN = item.ClaUN;
        NameTienda = item.Tienda;
        NameArticulo = item.Articulo;
        IVA_DEV = item.IVA_DEV
        PaquetesCLA= item.Paquetes
      });

      if (validSubtotal) {
        let itemFilterClaUNFULL = itemFullClaUN.filter(single => single.ClaUN === NameClaUN)
        let detailTotalItem = {
          Descripcion: "TOTAL TIENDA:",
          ClaUN: NameClaUN,
          Tienda: NameTienda,
          Articulo: NameArticulo,
          Detail: itemFullClaUN.filter(single => single.ClaUN === NameClaUN && single.Piezas !== 0),
          Piezas: itemFilterClaUNFULL.reduce((result, { Piezas }) => result += parseFloat(Piezas), 0),
          Subtotal: itemFilterClaUNFULL.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(4),
          IVA: IVA_DEV > 0 ? (0).toFixed(4) :itemFilterClaUNFULL.reduce((result, { totalIva }) => result += parseFloat(totalIva), 0).toFixed(4),
          IEPS: itemFilterClaUNFULL.reduce((result, { totalIeps }) => result += parseFloat(totalIeps), 0).toFixed(4),
          TOTAL: itemFilterClaUNFULL.reduce((result, { total }) => result += parseFloat(total), 0).toFixed(4),
        };
        tempFull.push(detailTotalItem)
      }
    });

    let detailTotalItem = {
      Descripcion: "TOTAL :",
      Piezas: data.reduce((result, { Piezas }) => result += parseFloat(Piezas), 0),
      Subtotal: data.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(4),
      IVA: data.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(4),
      IEPS: data.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(4),
      TOTAL: data.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(4),
    };

    tempFull.push(detailTotalItem)
    let itemFilterPDF = tempFull.filter(single => single.Descripcion === "TOTAL TIENDA:")
    let detailTotalItemPDF = {
      Descripcion: "TOTAL :",
      Subtotal: itemFilterPDF.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(4),
      IVA: itemFilterPDF.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(4),
      IEPS: itemFilterPDF.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(4),
      TOTAL: itemFilterPDF.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(4),
    };

    itemFilterPDF.push(detailTotalItemPDF)

    let itemFilterTOTOTALTIENDA = tempFull.filter(single => single.Descripcion === "TOTAL TIENDA:")

    let listSKU = []
    filteredProducts.forEach(a => {
      let itemFilterSku = itemFilterFull.filter(product => product.Articulo === a.productId)
      itemFilterSku.forEach(_j => {
        let custom = {
          Articulo: _j.Articulo,
          Precio: _j.Precio,
          initialIVA: _j.initialIVA,
          initialIEPS: _j.initialIEPS,
          Paquetes:_j.Paquetes,
          Descripcion: _j.Descripcion,
          listSku: itemFilterSku
        }
        listSKU.push(custom)
      });
    });

    let totalListSKU = []
    let ListArt = listSKU.filter((ele, ind) => ind === listSKU.findIndex(elem => elem.Articulo === ele.Articulo))
    ListArt.forEach(_t => {
      let custom = {
        Articulo: _t.Articulo,
        Descripcion: _t.Descripcion,
        initialIVA: _t.initialIVA,
        initialIEPS: _t.initialIEPS,
        ClaUN:"CO129",
        Precio: _t.Precio,
        Piezas: _t.listSku.reduce((result, { Piezas }) => result += (parseFloat(Piezas) * parseFloat(_t.Paquetes)), 0),
        Subtotal: _t.listSku.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(4),
        totalIVA: _t.listSku.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(4),
        totalIEPS: _t.listSku.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(4),
        total: _t.listSku.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(4),
      }
    //  console.log(custom)
      totalListSKU.push(custom)
    });





    /// RULO
    let detailTotalItemSKU = {
      Descripcion: "TOTAL :",
      Piezas: totalListSKU.reduce((result, { Piezas }) => result += parseFloat(Piezas), 0),
      Subtotal: totalListSKU.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(4),
      IVA: totalListSKU.reduce((result, { totalIVA }) => result += parseFloat(totalIVA), 0).toFixed(4),
      IEPS: totalListSKU.reduce((result, { totalIEPS }) => result += parseFloat(totalIEPS), 0).toFixed(4),
      TOTAL: totalListSKU.reduce((result, { total }) => result += parseFloat(total), 0).toFixed(4),
    };


    let objData = {
      nameOrganization: Organization[0].organizationName,
      nameProvider: Provider[0].providerName,
      date: stringdate,
      emailProvider: emailProvider,
      phoneProvider: PhoneProvider,////Provider[0].phones
      addressProvider: addressProvider,
      addressOrganization: addressOrganization,
      rfcOrganization: RFC,//Organization[0].taxpayer.taxpayerName
      tiendas: tempFull.filter(single => single.Descripcion === "TOTAL TIENDA:"),
      cedis: totalListSKU,
      SUBTOTAL: "$ " + stateCedis ? totalListSKU.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(4) : itemFilterTOTOTALTIENDA.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(4),
      IVA: "$ " + stateCedis ? totalListSKU.reduce((result, { totalIVA }) => result += parseFloat(totalIVA), 0).toFixed(4) : itemFilterTOTOTALTIENDA.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(4),
      IEPS: "$ " + stateCedis ? totalListSKU.reduce((result, { totalIEPS }) => result += parseFloat(totalIEPS), 0).toFixed(4) : itemFilterTOTOTALTIENDA.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(4),
      TOTAL: "$ " + stateCedis ? totalListSKU.reduce((result, { total }) => result += parseFloat(total), 0).toFixed(4) : itemFilterTOTOTALTIENDA.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(4)
    }

    console.log(objData)
    setdataHtml(objData)

    return (itemFilterPDF)
  }

  ////// Detalle de total en general
  // function GetDataPDF(data) {

  //   let Organization = Organizations.filter(org => org.organizationId === SelectOrganizations)
  //   let Provider = Providers.filter(prov => prov.providerId === SelectProviders)

  //   console.log(Provider)
  //   let emailProvider = ""
  //   let addressProvider = ""
  //   let addressOrganization = ""
  //   let PhoneProvider = ""
  //   let RFC = ""

  //   try {
  //     emailProvider = Provider[0].email
  //   } catch (error) {

  //   }
  //   try {
  //     addressProvider = Provider[0].address
  //   } catch (error) {

  //   }
  //   try {
  //     addressOrganization = Organization[0].address
  //   } catch (error) {

  //   }
  //   try {
  //     RFC = Organization[0].taxpayer.taxpayerName
  //   } catch (error) {

  //   }
  //   try {
  //     PhoneProvider = Provider[0].phones[0].phone
  //   } catch (error) {

  //   }

  //   var today = new Date(),
  //     date = today.getFullYear() + '/' + zeroPad((today.getMonth() + 1), 2) + '/' + today.getDate();
  //   let stringdate = date.toString()


  //   const tempFull = []
  //   let itemFilterFull = data.filter(product => product.Piezas !== 0)
  //   Warehause.forEach(element => {
  //     var validSubtotal = false;
  //     var NameClaUN = "";
  //     var NameTienda = "";
  //     let itemFilter = itemFilterFull.filter(single => single.ClaUN === element.warehouseId)
  //     itemFilter.map((item) => {
  //       tempFull.push(item);
  //       validSubtotal = true;
  //       NameClaUN = item.ClaUN;
  //       NameTienda = item.Tienda;
  //     });

  //     if (validSubtotal) {
  //       let detailTotalItem = {
  //         Descripcion: "TOTAL TIENDA:",
  //         ClaUN: NameClaUN,
  //         Tienda: NameTienda,
  //         Piezas: itemFilter.reduce((result, { Piezas }) => result += parseFloat(Piezas), 0),
  //         Subtotal: itemFilter.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(2),
  //         IVA: itemFilter.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(2),
  //         IEPS: itemFilter.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(2),
  //         TOTAL: itemFilter.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(2),
  //       };
  //       tempFull.push(detailTotalItem)
  //     }
  //   });

  //   let detailTotalItem = {
  //     Descripcion: "TOTAL :",
  //     Piezas: data.reduce((result, { Piezas }) => result += parseFloat(Piezas), 0),
  //     Subtotal: data.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(2),
  //     IVA: data.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(2),
  //     IEPS: data.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(2),
  //     TOTAL: data.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(2),
  //   };

  //   tempFull.push(detailTotalItem)
  //   let itemFilterPDF = tempFull.filter(single => single.Descripcion === "TOTAL TIENDA:")
  //   let detailTotalItemPDF = {
  //     Descripcion: "TOTAL :",
  //     Subtotal: itemFilterPDF.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(2),
  //     IVA: itemFilterPDF.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(2),
  //     IEPS: itemFilterPDF.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(2),
  //     TOTAL: itemFilterPDF.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(2),
  //   };

  //   itemFilterPDF.push(detailTotalItemPDF)

  //   let itemFilterTOTOTALTIENDA = tempFull.filter(single => single.Descripcion === "TOTAL TIENDA:")


  //   let objData = {
  //     nameOrganization: Organization[0].organizationName,
  //     nameProvider: Provider[0].providerName,
  //     date: stringdate,
  //     emailProvider: emailProvider,
  //     phoneProvider: PhoneProvider,////Provider[0].phones
  //     addressProvider: addressProvider,
  //     addressOrganization: addressOrganization,
  //     rfcOrganization: RFC,//Organization[0].taxpayer.taxpayerName
  //     tiendas: itemFilterPDF.filter(single => single.Descripcion === "TOTAL TIENDA:"),
  //     SUBTOTAL: "$ " + itemFilterTOTOTALTIENDA.reduce((result, { Subtotal }) => result += parseFloat(Subtotal), 0).toFixed(2),
  //     IVA: "$ " + itemFilterTOTOTALTIENDA.reduce((result, { IVA }) => result += parseFloat(IVA), 0).toFixed(2),
  //     IEPS: "$ " + itemFilterTOTOTALTIENDA.reduce((result, { IEPS }) => result += parseFloat(IEPS), 0).toFixed(2),
  //     TOTAL: "$ " + itemFilterTOTOTALTIENDA.reduce((result, { TOTAL }) => result += parseFloat(TOTAL), 0).toFixed(2)
  //   }

  //   // console.log(objData)
  //   setdataHtml(objData)

  //   return (itemFilterPDF)
  // }

  // function GetHTMLPDF(data) {
  //   let Organization = Organizations.filter(org => org.organizationId === SelectOrganizations)
  //   let Provider = Providers.filter(prov => prov.providerId === SelectProviders)

  //   let resultPhoneProvider = ""
  //   let resultAddressProvider = "PANAMA 183 LAS AMERICAS COATZACOALCOS"
  //   let resultEmailProvider = "adrycamargo2223@gmail.com"
  //   let resultAddressOrganizations = "CARRET TRANSISTMICA KM 7.5 NO. 102 BA TIERRA NUEVA COATZACOALCOS"
  //   let resultRFCOrganizations = "BAM010705DG5"

  //   var today = new Date(),
  //     date = today.getFullYear() + '/' + zeroPad((today.getMonth() + 1), 2) + '/' + today.getDate();
  //   let stringdate = date.toString()

  //   let ObjectOC = GetDataPDF(data)

  //   let HTMLOC = "<!DOCTYPEhtml><html><head>" +
  //     "<style> table {width:100%;font-size:18px;font-family:Times;color:#686f7e;} table,th,td {border:0px solidblack;border-collapse:collapse;} th,td{padding:15px;text-align:left;}#t01tr:nth-child(even){background-color:#eee;}#t01tr:nth-child(odd){background-color:#fff;}#t01th{background-color:black;color:white;}</style>" +
  //     "</head><body id='File'>" +
  //     "<div style='width:100%;height:20px;background-color:#00375b'><table><tr><th style='width:50%;'></th><th style='text-align: right;font-size:30px;'>" + Organization[0].organizationName + "</th></tr></table><br/>"
  //   HTMLOC = HTMLOC + "<div style='background-color:#EFEDEB;padding: 20px;'><div style='width:45%; float:right;'><div style='margin-left:15px;height:3px;background-color:#00375b;'></div><table><tr><td style='width:100%;'>ORDENES DE COMPRA:</td></tr><tr><td>FECHA:</td><td style='width:65%;'>" + stringdate + "</td></tr><tr><td>LUGAR DE ENTREGA:</td><td style='width:65%;'>Sucursales</td></tr></table><div style='margin-left:15px;height:3px;background-color:#00375b;'></div></div><div style='width:55%; background-color:#00375b;'><table><tr><td style='color:white;'>PROVEEDOR:</td><td style='width:65%; color:white;'>" + Provider[0].providerName + "</td></tr><tr><td style='color:white;'>DIRECCION:</td><td style='width:65%; color:white;'>" + resultAddressProvider + "</td></tr><tr><td style='color:white;'>TELEFONO:</td><td style='width:65%; color:white;'>" + resultPhoneProvider + "</td></tr></table></div></div>"
  //   HTMLOC = HTMLOC + "<div style='margin-top:15px;height:3px;background-color:#00375b;'></div><table><tr><td style='color:#00375b;font-weight: bold;'>OC</td><td style='color:#00375b;font-weight: bold;'>TIENDA</td><td style='color:#00375b;font-weight: bold;text-align: right;'>SUBTOTAL</td><td style='color:#00375b;font-weight: bold;text-align: right;'>IVA</td><td style='color:#00375b;font-weight: bold;text-align: right;'>IEPS</td><td style='color:#00375b;font-weight: bold;text-align: right;'>TOTAL</td></tr>"

  //   ObjectOC.forEach((Item) => {
  //     if (Item.Descripcion === "TOTAL TIENDA:") {

  //       HTMLOC = HTMLOC + "<tr><td>" + Item.ClaUN + "</td>" +
  //         "<td>" + Item.Tienda + "</td>" +
  //         "<td style='text-align: right;'>" + Item.Subtotal + "</td>" +
  //         "<td style='text-align: right;'>" + Item.IVA + "</td>" +
  //         "<td style='text-align: right;'>" + Item.IEPS + "</td>" +
  //         "<td style='text-align: right;'>" + Item.TOTAL + "</td></tr>"
  //     }

  //   });

  //   let filter = ObjectOC.filter(
  //     (item) => item.Descripcion === "TOTAL :"
  //   );
  //   HTMLOC = HTMLOC + "</table>" +
  //     "<div style='width:30%; float:right;'><table> <tr>" +
  //     "<td style='color:#00375b;font-weight: bold;text-align: right;'>SUBTOTAL:</td>" +
  //     "<td style='color:#00375b;font-weight: bold;text-align: right;'>" + filter[0].Subtotal + "</td></tr><tr>" +
  //     "<td style='color:#00375b;font-weight: bold; text-align: right;'>I.V.A:</td>" +
  //     "<td style='color:#00375b;font-weight: bold;text-align: right;'>" + filter[0].IVA + "</td></tr><tr>" +
  //     "<td style='color:#00375b;font-weight: bold; text-align: right;'>I.E.P.S :</td>" +
  //     "<td style='color:#00375b;font-weight: bold;text-align: right;'>" + filter[0].IEPS + "</td></tr></table></div>" +
  //     "<div style='width:100%; float:right;position:relative;background-color:#00375b;'><table><tr>" +
  //     "<td style='color:#00375b;font-weight: bold;text-align: right; color:white;'>TOTAL:</td>" +
  //     "<td style='color:#00375b;font-weight: bold;text-align: right;width:15%;color:white;'>" + filter[0].TOTAL + "</td></tr></table></div>" +

  //     "<div style='width:40%; float:right;margin-top:30px'><table><tr><td style='color:#00375b;font-weight: bold;text-align:center;'>______________________________________</td></tr><tr><td style='color:#00375b;font-weight: bold;text-align:center; '>LEOPOLDO GALLEGOS MENDEZ</td></tr></table></div><div style='width:100%; float:right;position:relative;background-color:#EFEDEB;'><table><tr><td style='font-size:12px;'>Politicas:</td></tr><tr><td style='font-size:12px;'>El proveedor no está autorizado para hacer cambios de ningún artículo enesta Orden de Compra sin la aprobación del comprador." +
  //     "Esta orden compra contiene los precios vigentes, de la última lista de precios informada.Tiendas bama podrá rechazar los productos que no cumplan con la calidad previamente negociada.La factura deberá de coincidir con la cantidad y montos recibidos en tienda.Anexar el número de orden de compra a la factura.Sólo se aceptarán facturas de forma electrónica y con verificación fiscal.</td></tr></table></div>" +
  //     "<div style='width:100%; float:right;position:relative;background-color:#00375b;'><table><tr>" +
  //     "<td style='color:#00375b;font-weight: bold; color:white;font-size:13px;'>" + resultAddressOrganizations + "</td>" +
  //     "<td style='color:#00375b;font-weight: bold;width:15%;color:white;font-size:13px;'>" + resultRFCOrganizations + "</td></tr></table></div></div></body></html>"
  //   return (HTMLOC)
  // }

  function QuerysOrdenCompra(data) {
    //////////////////////////// QUERY´S DE INSERCCION OC ////////////// //////////////
    const temp = [...Warehause];
    const tempdata = [...data];
     let itemSelectTOP = []
     let itemFilterFull = (stateCedis.checked ? dataHtml.cedis : tempdata.filter(product => parseInt(product.Piezas)  !== 0))  
    temp.forEach(element => {
      let itemFilter = itemFilterFull.filter(single => single.ClaUN ===  element.warehouseId)
      console.log(itemFilter)
      const unique = [...new Map(itemFilter.map(item =>
        [item["warehouseRelatedId"], item])).values()];
      unique.map((item) => {
         
       // let Subtotal = (parseFloat(item.Piezas) * parseFloat(item.Precio))
        let Subtotal = ((parseFloat(item.Piezas) *parseFloat(item.Paquetes))* parseFloat(item.Precio))
        let PctIVA = Math.max(...itemFilter.map(o => o.initialIVA));
        let PctIEPS = Math.max(...itemFilter.map(o => o.initialIEPS));

        var today = new Date(),
          date = today.getFullYear() + '-' + today.getDate() + '-'+ zeroPad((today.getMonth() + 1), 2) +' 00:00:00';
        let stringdate = date//formatDate(new Date())
        //console.log(stringdate)
        

        let QueryOne = " SET NOCOUNT ON; " +
          " declare @Almacen VARCHAR(250);" +
          " declare @folioOC INT;" +

          " set @Almacen = (select TOP 1 isnull(ClaUN,'') ClaUN from UniNeg where ClaUNContable='" + (stateCedis.checked ? "CO129" :item.ClaUN)  + "' AND TipoUN=20 and ClaEmp=" + 1 + ");" +

          " set @folioOC = ( Select isnull(UltimoFolio,0) Folio from FolioOC where ClaUN = '" + (stateCedis.checked ? "CO129" :item.ClaUN) + "' and ClaEmp = " + 1 + ");" +

          " Update FolioOC set UltimoFolio = @folioOC+1 where ClaUN = '" + (stateCedis.checked ? "CO129" :item.ClaUN) + "' and ClaEmp = " + 1 + ";" +

          " INSERT INTO OC( FolioOC, ClaUNOC, ClaEmp, ClaProv, ClaUNDestino, FecEmiOC, FecVenOC, FecVenOCOri, " +
          " FecSur, BackOrderSiNo, TipoPlazoPago, PlazoPago, TipoFlete, StatusOC, StatusSurtidoOC, ClaMoneda, Paridad, SubTotal, " +
          " DesctoPar, PctDesctoGral, DesctoGral, PctIVA1, PctIVA2, IVA1, IVA2, Total, AnticipoOC, PctRetISR, PctRetIVA, " +
          " ObsOC, ObsRecibir, IdElaboro, IdAutorizo, IdSesion, PctIEPS, IEPS, FecAutoOC, ClaPersonaSol, TipoReqCom, " +
          " ClaComprador, LAB, ViaEmb, PctAnticipo, PctEntrega, TipoPagoProv, TipoCompra, PctDesctoGralStr, ClaContacto, Puesto ) " +
          " VALUES (@folioOC+1,'" + (stateCedis.checked ? "CO129" :item.ClaUN) + "'," + 1 + "," + SelectProviders + ",@Almacen,'" + stringdate + "','" + stringdate + "','" + stringdate + "', " +
          " Null,0,10,0,10,2,1,1,1," + (stateCedis.checked ? item.Subtotal :Subtotal) + ", " +
          " 0,0,0," + PctIVA + ",0," +  (stateCedis.checked ? item.totalIVA :item.IVA) + ",0," + (stateCedis.checked ? item.total :item.TOTAL) + ",0,0,0, " +
          " 'OC FORECAST....',Null,1,1,9999," + PctIEPS + "," + (stateCedis.checked ? item.totalIEPS :item.IEPS) + ",Null,1,10, " +
          "1,Null,Null,0,'0',10,2,'0',Null,Null);" +


          " INSERT INTO  OCAutomatica (IdConsolidado,ClaEmp,ClaUNOC,ClaUNDestino,ClaProv,FolioOC,FecEmi,SubTotal,IVA,IEPS,Total,IdUsuarioEnlace)" +
          " values (" + listFolio[0].folio + "," + 1 + ",'" +  (stateCedis.checked ? "CO129" :item.ClaUN) + "',@Almacen," + SelectProviders + "," + "@folioOC+1" + ",getdate()," + item.Subtotal + "," + (stateCedis.checked ? item.totalIVA :item.IVA) + "," + (stateCedis.checked ? item.totalIEPS :item.IEPS) + "," + (stateCedis.checked ? item.total :item.TOTAL) + ",42);";
          
          if (stateCedis.checked){
      
            dataHtml.cedis.forEach((e) => {

              QueryOne = QueryOne +  " INSERT INTO OCDet(ClaEmp,FolioOC,ClaUNOC,ClaCC,ClaArti,ClaUNGasto,ClaUnidad,CtdPed,CtdSur, " +
              " FecSur,Precio,ImpPartida,PctDescto,PctIVA,PctIEPS,PctMargenPlaneado,ObsPartida,StatusSurtidoOC,IdSesion,IdCotDet1, " +
              " IdCotDet2,IdCotDet3, PctRetIVA)" +
              " VALUES (" + 1 + ",@folioOC+1,'CO129',NULL,'" + e.Articulo + "','CO129','" + "PIEZA" + "'," + parseFloat(e.Piezas) + ",0, " +
              " NULL," + e.Precio + "," + e.Subtotal + ",0," + PctIVA + "," + PctIEPS + ",0,NULL,1,9999,NULL " +
              " ,NULL,NULL,0);" +
              " select @folioOC;";
    
            });
      
          }else{

            itemFilterFull.forEach(_k => {
        
   
              //let Subtotal_k = (parseFloat(_k.Piezas) * parseFloat(_k.Precio))
              let Subtotal_k = ((parseFloat(_k.Piezas) *parseFloat(_k.Paquetes))* parseFloat(_k.Precio))
    
              QueryOne = QueryOne +  " INSERT INTO OCDet(ClaEmp,FolioOC,ClaUNOC,ClaCC,ClaArti,ClaUNGasto,ClaUnidad,CtdPed,CtdSur, " +
              " FecSur,Precio,ImpPartida,PctDescto,PctIVA,PctIEPS,PctMargenPlaneado,ObsPartida,StatusSurtidoOC,IdSesion,IdCotDet1, " +
              " IdCotDet2,IdCotDet3, PctRetIVA)" +
              " VALUES (" + 1 + ",@folioOC+1,'" + (stateCedis.checked ? "CO129" :_k.ClaUN) + "',NULL,'" + _k.Articulo + "','" + (stateCedis.checked ? "CO129" : _k.ClaUN) + "','" + "PIEZA" + "'," + (parseFloat(_k.Piezas) *parseFloat(_k.Paquetes)) + ",0, " +
              " NULL," + _k.Precio + "," + Subtotal_k + ",0," + PctIVA + "," + PctIEPS + ",0,NULL,1,9999,NULL " +
              " ,NULL,NULL,0);" +
              " select @folioOC;";
    
              console.log(QueryOne)
            });

          }
        itemSelectTOP.push(QueryOne)
        loadOrder_OC(QueryOne)

      });
    });
    let itemResult = {
      queryTop: itemSelectTOP
    }
    return (itemResult)
  }

  const zeroPad = (num, places) => String(num).padStart(places, '0')


  //// david.fernandez@bamaglobal.com
  const sendMailHTML = (html, email) => {
    var email = {
      sender: "lgallegos@bamaglobal.com.mx",///"lgallegos@bama.mx"
      recipient: email,
      subject: "Orden de compra",
      title: "Orden de compra",
      content: html,
    }
    console.log(email)
    API.graphql(
      graphqlOperation(sendEmail, { email: JSON.stringify(email) })
    ).then((allTodos) => {
      console.log(allTodos);
      props.showMessage(` ${"Se ha generado satisfactoriamente tu orden de compra."}`, SNACKBAR_SUCCESS, 5000, null, null, null, null, null, " ", props);
      setLoadingOc(false)
      setOpenDialogOC(false)
    });
  };


  return (
    <Grow in={true}>
      <Box>
        {loading ? <LinearProgress color="secondary" /> : ""}
        <Container fixed>
          <Box my={2}>
            <Typography variant="h5" color="textSecondary">
              {props.translate("Purchase Orders")}
            </Typography>
          </Box>
        </Container>
        <Container fixed>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={4}>
                <Typography variant="subtitle1" color="textSecondary">
                  {props.translate("Forecast date selection legend")}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label={props.translate("Start Date")}
                    value={startDate}
                    onChange={handleDateChangeStart}
                    autoOk
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label={props.translate("End Date")}
                  value={endDate}
                  onChange={handleDateChangeEnd}
                  autoOk
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            {/*<Grid item xs={12}>
              <Typography variant="subtitle1" align="center" color="textSecondary">
                {`Elige tu empresa y proveedor`}
              </Typography>
                </Grid>*/}
            <Grid item xs={6}>
              <Box my={3}>
                <InputLabel id="provider-select-label">Empresa</InputLabel>
                <Select
                  labelId="organizations-customized-select-label"
                  id="organizations-customized-select"
                  onChange={handleChangeOrganizations}
                  fullWidth
                >
                  {Organizations.map((element) => {
                    return (
                      <MenuItem
                        key={`organization-${element.id}`}
                        label="Seleciona empresa"
                        value={element.organizationId}
                      >
                        {element.organizationName +
                          "   *  " +
                          element.organizationId}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box my={3}>
                <InputLabel id="zone-select-label">Zona </InputLabel>
                <Select
                  disabled={!Boolean(SelectOrganizations)}
                  labelId="zone-customized-select-label"
                  id="zone-customized-select"
                  onChange={handleChangeZone}
                  defaultValue={SelectZone}
                  fullWidth
                >
                  <MenuItem value={0}>Todas</MenuItem>
                  {zones.map((element) => {
                    return (
                      <MenuItem
                        key={element.id}
                        id={`zone-${element.zoneId}`}
                        label="Seleciona zona"
                        value={element.zoneId}
                      >
                        {element.zoneName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box my={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="demo-mutiple-chip-label"
                    style={{ marginTop: "-15px" }}
                  >
                    Tiendas
                  </InputLabel>
                  <Select
                    disabled={!Boolean(listTiendas.length)}
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={nameTienda}
                    onChange={handleChangeChip}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {listTiendas.map((name) => (
                      <MenuItem
                        key={name.warehouseRelatedId}
                        value={name.Tienda}
                      >
                        {name.warehouseRelatedId} {name.Tienda}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box my={3}>
                <InputLabel id="provider-select-label">Proveedor </InputLabel>
                <Select
                  disabled={!Boolean(Providers.length)}
                  labelId="provider-customized-select-label"
                  id="provider-customized-select"
                  onChange={handleChangeProviders}
                  fullWidth
                >
                  {Providers.map((element) => {
                    return (
                      <MenuItem
                        key={element.providerId}
                        id={`provider-${element.providerId}`}
                        label="Seleciona proveedor"
                        value={element.providerId}
                      >
                        {element.providerName + "   *  " + element.providerId}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Grid>
            {/*<Grid item xs={12}>
              <Typography variant="subtitle1" align="center" color="textSecondary">
                {`También podrás filtrar por Zona y elegir una o más Tiendas`}
              </Typography>
                </Grid>*/}
            <Grid container justify="left" alignItems="center" spacing={2} >
              <Grid item xs={4}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  color="textSecondary"
                >
                  {`Selecciona Pedido a cedis`}
                </Typography>
              </Grid>
              <Grid item xs={4}>

                <FormControlLabel
                  control={
                    <Switch
                      checked={stateCedis.checked}
                      onChange={handleChangeChekedCedis}
                      name="checked"
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
            {loadTiendas ? <LinearProgress color="secondary" /> : ""}
            {forecast && forecast.length > 0 ? (
              <Grid item xs={4}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                >
                  {`Puedes cambiar tu Proyección de Forecast`}
                </Typography>
              </Grid>
            ) : null}
            {forecast && forecast.length > 0 ? (
              <Grid item xs={4}>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  ref={anchorRef}
                  aria-label="split button"
                >
                  <Button onClick={handleClick}>
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  placement="top-start"
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            id="split-button-menu"
                            style={{ zIndex: 10 }}
                          >
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            ) : null}
            {forecast && forecast.length > 0 ? (
              <Grid item xs={4}>
                <Box
                  my={1}
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.checked}
                        onChange={handleChangeCheked}
                        name="checked"
                        color="primary"
                      />
                    }
                    label="Consolidar OC"
                  />
                </Box>
              </Grid>
            ) : null}
            {loadingList ? (
              <Grid item xs={12}>
                <LinearProgress color="secondary" />
              </Grid>
            ) : (
                ""
              )}

            {loadingProviders ? (
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography align="center" color="textSecondary">
                      {`Cargando Proveedores`}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <LinearProgress color="secondary" />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {loadingProviderProducts ? (
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography align="center" color="textSecondary">
                      {`Cargando Productos de Proveedores`}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <LinearProgress color="secondary" />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {loadingProducts ? (
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography align="center" color="textSecondary">
                      {`Cargando Catálogo de Productos`}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <LinearProgress color="secondary" />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {loadingForecast ? (
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography align="center" color="textSecondary">
                      {`Cargando Forecast`}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <LinearProgress color="secondary" />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {loadingSales ? (
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography align="center" color="textSecondary">
                      {`Cargando Histórico de Ventas`}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <LinearProgress color="secondary" />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {loadingInventory ? (
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography align="center" color="textSecondary">
                      {`Cargando Inventario al cierre en Tiendas`}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <LinearProgress color="secondary" />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            <Backdrop
              open={
                loadingInventory ||
                loadingSales ||
                loadingForecast ||
                loadingProducts ||
                loadingProviderProducts ||
                loadingProviders
              }
            ></Backdrop>
            {loadingValidateOC  ? (
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography align="center" color="textSecondary">
                      {`Cargando datos para orden de compra`}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <LinearProgress color="secondary" />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Dialog
              fullWidth={true}
              maxWidth="md"
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogTitle id="max-width-dialog-title">INFORMACION</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  ¿Desea generar las OCs SIN CONSOLIDAR?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Aceptar
                </Button>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Container>
        <OrderPurchaseRow
          {...props}
          items={rows}
          idProvider={SelectProviders}
          organizationId={SelectOrganizations}
          Warehause={Warehause}
          selectedWarehouses={selectedWarehouses}
          ProvidersProduct={ProvidersProduct}
          forecast={forecast}
          sales={sales}
          params={params}
          enabled={openChartForecast}
          handleOC={handleOC}
          setLoadingProviderProducts={setLoadingProviderProducts}
          setLoadingProducts={setLoadingProducts}
          handleChangeFullData={handleChangeFullData}
          loadForecastPrediction={loadForecastPrediction}
          loadSalesHistory={loadSalesHistory}
          handleChangeFilterProducts={handleChangeFilterProducts}
          getPiecesForOrder={getPiecesForOrder}
        />
        {forecast && (
          <Box>
            <Zoom in={true} unmountOnExit>
              <Fab
                className={classes.fab}
                onClick={handleOpenFloatMenu}
                color="secondary"
              >
                <AddIcon />
              </Fab>
            </Zoom>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseFloatMenu}
            >
              <MenuItem onClick={handleClickMenu}>
                <ListItemIcon>
                  <MultilineChartIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${props.translate("Show Forecast Chart")}`}
                />
              </MenuItem>
            </Menu>
          </Box>
        )}
        <ChartModal
          {...props}
          open={openChartForecast}
          handleCloseModal={(event) => setOpenChartForecast(false)}
          products={rows}
          forecast={globalForecast}
        />
        <Dialog
          fullScreen
          open={openDialogOC}
          onClose={handleCloseOC}
          TransitionComponent={Transition}
        >
          <AppBar color="secondary" className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseOC}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                className={classes.button_oc}
                onClick={GeneratePDFOC}
                endIcon={<Icon>send</Icon>}
              >
                Enviar Orden de Compra
              </Button>
            </Toolbar>
          </AppBar>
          {loadingOc ? (
            <Grid>
              {" "}
              <Grid item xs={9}>
                <Typography variant="h6">
                  .......... Espera se esta procesando tu orden de compra
                </Typography>
              </Grid>{" "}
              <LinearProgress color="secondary" />{" "}
            </Grid>
          ) : (
              ""
            )}
          <Box id="dashbopard">
            <Container fixed>
              <Box my={2}>
                <div
                  style={{
                    width: "100%",
                    height: "20px",
                    backgroundColor: "#00375b",
                  }}
                ></div>
                <table>
                  <tr>
                    <th style={{ width: "50%" }}></th>
                    <th style={{ textAlign: "right", fontSize: "30px" }}>
                      {stateCedis.checked ? "Cedis" : dataHtml.nameOrganization}
                    </th>
                  </tr>
                </table>
                <br></br>

                <div style={{ backgroundColor: "#EFEDEB", padding: "20px" }}>
                  <div style={{ width: "45%", float: "right" }}>
                    <div
                      style={{
                        marginLeft: "15px",
                        height: "3px",
                        backgroundColor: "#00375b",
                      }}
                    ></div>
                    <table style={{ paddingLeft: "20px" }}>
                      <tr>
                        <td style={{ width: "60%" }}>ORDENES DE COMPRA:</td>
                        <td style={{ width: "40%" }}>
                          {" "}
                          Folio-{listFolio[0].folio}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>FECHA:</td>
                        <td style={{ width: "65%" }}> {dataHtml.date}</td>
                      </tr>
                      <tr>
                        <td>LUGAR DE ENTREGA:</td>
                        <td style={{ width: "65%" }}> {stateCedis.checked ? "Cedis" : "Sucursales"}</td>
                      </tr>
                    </table>
                    <div
                      style={{
                        marginLeft: "15px",
                        height: "3px",
                        backgroundColor: "#00375b",
                      }}
                    ></div>
                  </div>
                  <div style={{ width: "55%", backgroundColor: "#00375b" }}>
                    <table>
                      <tr>
                        <td style={{ color: "white" }}>PROVEEDOR:</td>
                        <td style={{ width: "65%", color: "white" }}>
                          {dataHtml.nameProvider}
                        </td>
                      </tr>

                      {dataHtml.addressProvider !== "" &&
                        dataHtml.addressProvider !== null ? (
                          <tr>
                            <td style={{ color: "white" }}>DIRECCION:</td>
                            <td style={{ width: "65%", color: "white" }}>
                              {dataHtml.addressProvider}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}

                      {dataHtml.phoneProvider !== "" &&
                        dataHtml.phoneProvider !== null ? (
                          <tr>
                            {" "}
                            <td style={{ color: "white" }}>TELEFONO:</td>
                            <td style={{ width: "65%", color: "white" }}>
                              {dataHtml.phoneProvider}
                            </td>{" "}
                          </tr>
                        ) : (
                          ""
                        )}
                    </table>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "15px",
                    height: "3px",
                    backgroundColor: "#00375b",
                  }}
                ></div>
                <table style={{ width: "100%", padding: "15px" }}>
                  {!stateCedis.checked &&
                    dataHtml.tiendas.map(function (e, i) {
                      return (
                        <Box key={`tbl-${i}`}>
                          {i === 0 ? (
                            <tr>
                              <td
                                style={{
                                  width: "10%",
                                  color: "#00375b",
                                  fontWeight: "bold",
                                }}
                              >
                                SKU
                              </td>

                              <td
                                style={{
                                  width: "20%",
                                  color: "#00375b",
                                  fontWeight: "bold",
                                }}
                              >
                                TIENDA
                              </td>

                              <td
                                style={{
                                  width: "10%",
                                  color: "#00375b",
                                  fontWeight: "bold",
                                }}
                              >
                                ARTICULO
                              </td>

                              <td
                                style={{
                                  width: "10%",
                                  color: "#00375b",
                                  fontWeight: "bold",
                                }}
                              >
                                PIEZAS
                              </td>

                              <td
                                style={{
                                  width: "10%",
                                  color: "#00375b",
                                  fontWeight: "bold",
                                }}
                              >
                                PRECIO
                              </td>

                              <td
                                style={{
                                  width: "10%",
                                  color: "#00375b",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                SUBTOTAL
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                  color: "#00375b",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                IVA
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                  color: "#00375b",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                IEPS
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                  color: "#00375b",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                TOTAL
                              </td>
                            </tr>
                          ) : (
                              <tr>
                                <td
                                  style={{
                                    width: "10%",
                                    color: "#00375b",
                                    fontWeight: "bold",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    width: "20%",
                                    color: "#00375b",
                                    fontWeight: "bold",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    width: "10%",
                                    color: "#00375b",
                                    fontWeight: "bold",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    width: "10%",
                                    color: "#00375b",
                                    fontWeight: "bold",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    width: "10%",
                                    color: "#00375b",
                                    fontWeight: "bold",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    width: "10%",
                                    color: "#00375b",
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    width: "10%",
                                    color: "#00375b",
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    width: "10%",
                                    color: "#00375b",
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    width: "10%",
                                    color: "#00375b",
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                ></td>
                              </tr>
                            )}
                          {e.Detail.map(function (a, i) {
                            return (
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    paddingTop: "20px",
                                  }}
                                >
                                  {a.Articulo}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    paddingTop: "20px",
                                  }}
                                >
                                  {a.Tienda}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    paddingTop: "20px",
                                  }}
                                >
                                  {a.Descripcion}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    paddingTop: "20px",
                                  }}
                                >
                                  {a.Piezas}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    paddingTop: "20px",
                                  }}
                                >
                                  {"$ " + a.Precio}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    paddingTop: "20px",
                                    textAlign: "right",
                                  }}
                                >
                                  {"$ " + a.Subtotal}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    paddingTop: "20px",
                                    textAlign: "right",
                                  }}
                                >
                                  {"$ " + a.totalIva}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    paddingTop: "20px",
                                    textAlign: "right",
                                  }}
                                >
                                  {"$ " + a.totalIeps}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    paddingTop: "20px",
                                    textAlign: "right",
                                  }}
                                >
                                  {"$ " + a.total}
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td
                              style={{ fontWeight: "bold", paddingTop: "20px" }}
                            ></td>
                            <td
                              style={{ fontWeight: "bold", paddingTop: "20px" }}
                            ></td>
                            <td
                              style={{ fontWeight: "bold", paddingTop: "20px" }}
                            >
                              {e.Descripcion}
                            </td>
                            <td
                              style={{ fontWeight: "bold", paddingTop: "20px" }}
                            >
                              {e.Piezas}
                            </td>
                            <td
                              style={{ fontWeight: "bold", paddingTop: "20px" }}
                            ></td>
                            <td
                              style={{
                                fontWeight: "bold",
                                paddingTop: "20px",
                                textAlign: "right",
                              }}
                            >
                              {"$ " + e.Subtotal}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                paddingTop: "20px",
                                textAlign: "right",
                              }}
                            >
                              {"$ " + e.IVA}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                paddingTop: "20px",
                                textAlign: "right",
                              }}
                            >
                              {"$ " + e.IEPS}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                paddingTop: "20px",
                                textAlign: "right",
                              }}
                            >
                              {"$ " + e.TOTAL}
                            </td>
                          </tr>
                        </Box>
                      );
                    })}
                </table>

                {stateCedis.checked ? (
                  <table style={{ width: "100%", padding: "15px" }}>
                    <tr>
                      <td style={{ color: "#00375b", fontWeight: "bold" }}>
                        SKU
                      </td>
                      <td style={{ color: "#00375b", fontWeight: "bold" }}>
                        ARTICULO
                      </td>
                      <td style={{ color: "#00375b", fontWeight: "bold" }}>
                        TIENDA
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        PRECIO
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        PIEZAS
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        SUBTOTAL
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        IVA
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        IEPS
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        TOTAL
                      </td>
                    </tr>
                    {dataHtml.cedis.map(function (e, i) {
                      return (
                        <tr>
                          <td
                            style={{ fontWeight: "bold", paddingTop: "20px" }}
                          >
                            {e.Articulo}
                          </td>
                          <td
                            style={{ fontWeight: "bold", paddingTop: "20px" }}
                          >
                            {e.Descripcion}
                          </td>
                          <td
                            style={{ fontWeight: "bold", paddingTop: "20px" }}
                          >
                            CEDIS
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              paddingTop: "20px",
                              textAlign: "right",
                            }}
                          >
                            {"$ " + e.Precio}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              paddingTop: "20px",
                              textAlign: "right",
                            }}
                          >
                            {e.Piezas}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              paddingTop: "20px",
                              textAlign: "right",
                            }}
                          >
                            {"$ " + e.Subtotal}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              paddingTop: "20px",
                              textAlign: "right",
                            }}
                          >
                            {"$ " + e.totalIVA}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              paddingTop: "20px",
                              textAlign: "right",
                            }}
                          >
                            {"$ " + e.totalIEPS}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              paddingTop: "20px",
                              textAlign: "right",
                            }}
                          >
                            {"$ " + e.total}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                ) : (
                    ""
                  )}

                <div style={{ width: "30%", float: "right", padding: "15px" }}>
                  <table align="right">
                    <tr>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        SUBTOTAL:
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        {"$ " + dataHtml.SUBTOTAL}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        I.V.A:
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        {"$ " + dataHtml.IVA}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        I.E.P.S :
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        {"$ " + dataHtml.IEPS}
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "right",
                    position: "relative",
                    backgroundColor: "#00375b",
                  }}
                >
                  <table style={{ float: "right", paddingRight: "15px" }}>
                    <tr>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                          color: "white",
                        }}
                      >
                        TOTAL:
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "right",
                          color: "white",
                        }}
                      >
                        {"$ " + dataHtml.TOTAL}
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  style={{ width: "40%", float: "right", marginTop: "30px" }}
                >
                  <table>
                    <tr>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        ______________________________________
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        LEOPOLDO GALLEGOS MENDEZ
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "right",
                    position: "relative",
                    backgroundColor: "#EFEDEB",
                  }}
                >
                  <table>
                    <tr>
                      <td style={{ fontSize: "12px" }}>Politicas:</td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "12px" }}>
                        El proveedor no está autorizado para hacer cambios de
                        ningún artículo enesta Orden de Compra sin la aprobación
                        del comprador.Esta orden compra contiene los precios
                        vigentes, de la última lista de precios
                        informada.Tiendas bama podrá rechazar los productos que
                        no cumplan con la calidad previamente negociada.La
                        factura deberá de coincidir con la cantidad y montos
                        recibidos en tienda.Anexar el número de orden de compra
                        a la factura.Sólo se aceptarán facturas de forma
                        electrónica y con verificación fiscal.{" "}
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "right",
                    position: "relative",
                    backgroundColor: "#00375b",
                  }}
                >
                  <table>
                    <tr>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          color: "white",
                          fontSize: "13px",
                        }}
                      >
                        {dataHtml.addressOrganization}
                      </td>
                      <td
                        style={{
                          color: "#00375b",
                          fontWeight: "bold",
                          width: "15%",
                          color: "white",
                          fontSize: "13px",
                        }}
                      >
                        {dataHtml.rfcOrganization}
                      </td>
                    </tr>
                  </table>
                </div>
              </Box>
            </Container>
          </Box>
        </Dialog>
      </Box>
    </Grow>
  );
}

