import * as React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

export default class AppLoading extends React.Component {
    constructor(props) {
        super(props);

        this.defaultLogo = require('../../assets/images/bama_icon.png');
    }

    render() {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                style={{ height: '100vh' }}>
                <Box
                    py={1}>
                    <img src={this.defaultLogo} style={{ "maxHeight": "10em" }} alt="Bama POS" />
                </Box>
                <Box
                    py={1}
                    style={{ width: '30vh' }}>
                    <LinearProgress />
                    <LinearProgress variant="query" color="secondary" />
                </Box>
            </Box>
        );
    }
}