import { createAction } from 'redux-actions';
import {
  FETCH_PARAMETERS,
  SET_PARAMETERS,
  SET_PARAMETERS_ERROR,
} from './constants';

export const fetchParameters = createAction(FETCH_PARAMETERS);
export const setParameters = createAction(SET_PARAMETERS);
export const setParametersError = createAction(SET_PARAMETERS_ERROR);
