import React, { useState, useEffect } from "react";

import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

export default function Loader(props) {
    const [loaderState, setLoaderState] = useState({
        textMessage: props.textMessage ? props.textMessage : "",
        imageSource: props.imageSource ? props.imageSource : "",
        loaderSource: props.loaderSource ? props.loaderSource : "",
        showLoader: props.showLoader
    })

    const defaultLogo = require('../../assets/images/icon.png');
    const defaultLoader = require('../../assets/loaders/loading-industry.gif');

    useEffect(() => {
        setLoaderState({
            textMessage: props.textMessage,
            imageSource: props.imageSource,
            loaderSource: props.loaderSource,
            showLoader: props.showLoader
        })
    }, [props.textMessage, props.imageSource, props.loaderSource, props.showLoader]);

    /*componentWillReceiveProps(nextProps) {
        if (showLoader !== nextProps.showLoader
            || textMessage !== nextProps.textMessage
            || imageSource !== nextProps.imageSource
            || loaderSource !== nextProps.loaderSource) {
            // console.log("componentWillReceiveProps", props, nextProps);
            // Update the properties to show the correct message
            // Force render
            setState({
                showLoader: nextProps.showLoader,
                changed: !changed,
                textMessage: nextProps.textMessage,
                imageSource: nextProps.imageSource,
                loaderSource: nextProps.loaderSource
            });
        }
    }*/

    return (
        <Modal
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            open={loaderState.showLoader}>
            <Fade in={loaderState.showLoader}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    style={{ height: '100vh' }}>
                    <Box
                        py={1}>
                        <img src={loaderState.imageSource ? loaderState.imageSource : defaultLogo} style={{ "maxHeight": "10em" }} alt="Bama POS" />
                    </Box>
                    <Box
                        py={1}>
                        <span
                            style={{ "color": "white" }}>
                            {loaderState.textMessage ?
                                loaderState.textMessage
                                : null}
                        </span>
                    </Box>
                    <Box
                        py={1}>
                        <img src={loaderState.loaderSource ? loaderState.loaderSource : defaultLoader} style={{ "maxHeight": "2em" }} alt="Loading" />
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}
