import { connect } from 'react-redux';
import UserProfile from './UserProfile';
import { UserProfile_onSuccess, UserProfile_onError, UserProfile_inProgress } from '../../redux/actions/UserProfileAction';

const mapDispatchToProps = dispatch => ({
  UserProfile_inProgress: data => {
    dispatch(UserProfile_inProgress(data));
  },
  UserProfile_onSuccess: data => {
    dispatch(UserProfile_onSuccess(data));
  },
  UserProfile_onError: data => {
    dispatch(UserProfile_onError(data));
  }
});

const mapStateToProps = state => ({
  user_profile: state.user_profile
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);