import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getVariablesState = state => get(state, 'variables');

export const isLoadingVariables = createSelector(
  [getVariablesState],
  variables => get(variables, 'loading'),
);

export const getVariables = createSelector([getVariablesState], variables =>
  get(variables, 'data'),
);

export const isErrorGetVariables = createSelector(
  [getVariablesState],
  variables => get(variables, 'error'),
);
