import React , { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import { FormControl , FormGroup, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import { CircularProgress } from '@material-ui/core';


export class ModalCreate extends Component {

    constructor(props){
      super(props);
      this.state = {
        idSubordinate: '', 
        btnDis: true
      }
    }

    changeIdSubordinate(e){
        this.setState({
            idSubordinate: `${e}`
        });
    }

 
    addSubordinate(){
        const { idSubordinate } = this.state;
        const { addSubordinateList} = this.props;
        if( idSubordinate !== '' ){
            addSubordinateList(idSubordinate);
            this.setState({
                idSubordinate: ''
            });
        }     
    }

    deleteSubordinate(row){
        const { deleteSubordinateList} = this.props;
        deleteSubordinateList(row);
    }
    

    render(){
   

    const { 
        open  , 
        loading , 
        btnDis ,
        nameE , 
        fLastName , 
        sLastName , 
        idE , 
        handleClose , 
        changeName , 
        changeFLName , 
        changeIdE  , 
        changeSLName  , 
        subordinates , saveEmploye, editEmployee , sendEditEmployee , errorAddSubordinate} = this.props;
    const { idSubordinate   } = this.state;


    const List = () => {
        
        return (
            <div>
                <TableContainer className="w300" component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                    {subordinates.map( (row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {row}
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                                <Button
                                    onClick={() => this.deleteSubordinate(row)}
                                >
                                    <Icon className="fa fa-trash blue"/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
        );
    }
 
    return (
        <div>
            <Dialog fullScreen open={open}  closeAfterTransition>
                <Fade in={open}>
                <div className={'dialog-content'}>
                    {editEmployee ? 
                        (<div>
                            <h2 id="transition-modal-title">Editar empleado nuevo</h2>
                            <h3 id="transition-modal-title">Captura los datos del empleado y agrega subordinados</h3>
                        </div>) : 
                        (<div>
                            <h2 id="transition-modal-title">Registrar empleado nuevo</h2>
                            <h3 id="transition-modal-title">Captura los datos del empleado y agrega subordinados</h3>
                        </div>) }
                    <Grid className={'btn-wrapper'} container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <h3>Datos del Empleado</h3>
                            <FormControl>
                                <FormGroup>
                                    <TextField 
                                        className="inputSelect marbot20"
                                        type="text"
                                        name="name"
                                        variant="outlined"
                                        label="Nombre"
                                        onChange={e => changeName(e.target.value)}
                                        value= {nameE}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextField 
                                        className="inputSelect marbot20"
                                        type="text"
                                        name="last_name"
                                        variant="outlined"
                                        label="Apellido Paterno"
                                        onChange={e => changeFLName(e.target.value)}
                                        value= {fLastName}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextField 
                                        className="inputSelect marbot20"
                                        type="text"
                                        name="second_last_name"
                                        variant="outlined"
                                        label="Apellido Materno"
                                        onChange={e => changeSLName(e.target.value)}
                                        value= {sLastName}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextField 
                                        className="inputSelect marbot20"
                                        type="text"
                                        name="id_employe"
                                        variant="outlined"
                                        label="ID Empleado"
                                        onChange={e => changeIdE(e.target.value)}
                                        disabled={editEmployee}
                                        value= {idE}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid className={'btn-wrapper'} item xs={12} lg={6}>
                            <h3>Subordinados</h3>
                            <FormControl>
                                <FormGroup>
                                    <TextField 
                                        className="inputSelect"
                                        type="text"
                                        name="id_employe"
                                        variant="outlined"
                                        label="ID Empleado Subordinados"
                                        onChange={e => this.changeIdSubordinate(e.target.value)}
                                        value= {idSubordinate}
                                    />
                                    <Button
                                        className={'btn_more'}
                                        variant="contained"
                                        onClick={() => this.addSubordinate()}
                                    >+</Button>
                                </FormGroup>
                            </FormControl>
                            <List></List>
                        </Grid>
                        <Grid item xs={12}>
                            { errorAddSubordinate ? (<span className={'error-txt pad0'}>Empleado no encontrado</span>) :  null }
                        </Grid>
                        <Grid item xs={12}>
                            {loading ? 
                            (
                                <CircularProgress />
                            ) :( 
                                editEmployee ? (
                                    <Button
                                        className={'btn accept-btn'}
                                        variant="contained" 
                                        onClick={sendEditEmployee}
                                        disabled={ btnDis && (nameE.length > 2) && (fLastName.length > 2) && (sLastName.length > 2) && (idE.length > 2)  ? false : true}
                                    >
                                        Editar
                                    </Button>
                                ) : (
                                    <Button
                                        className={'btn accept-btn'}
                                        variant="contained" 
                                        onClick={saveEmploye}
                                        disabled={ btnDis && (nameE.length > 2) && (fLastName.length > 2) && (sLastName.length > 2) && (idE.length > 2)  ? false : true}
                                    >
                                        Guardar
                                    </Button>
                                ) 
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={'btn cancel-btn'} onClick={handleClose}>
                            Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                </Fade>
            </Dialog>
        </div>
    );
    }
  }

  export default ModalCreate;
