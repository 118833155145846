import React, { useState, useEffect } from 'react';
import { Slide, Dialog, Toolbar, IconButton, Typography, Container, AppBar, makeStyles, Grid, List, ListItem, ListItemAvatar, ListItemText, Collapse, Box, LinearProgress, CircularProgress, Tooltip, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import BlockIcon from '@material-ui/icons/Block';

import CardProfile from '../CardProfile';
import AuditResume from './AuditResume';

import MaterialTable from 'material-table';
import { tableIcons, localization } from '../../materialTable/MaterialTable';
import { SNACKBAR_SUCCESS } from '../../../utils/SessionControl';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function AuditDetailModal(props) {
    const classes = useStyles();
    const [openProfile, setOpenProfile] = useState(false)
    const [openAudit, setOpenAudit] = useState(true)
    const [audit, setAudit] = useState(null)
    const [rows, setRows] = useState(null)
    const [ranking, setRanking] = useState(0)
    const [sending, setSending] = useState(false)

    useEffect(() => {
        if (audit !== props.audit)
            buildAudit(props.audit)
        return function cleanup() {

        };
    }, [props.searching, props.audit]) // Only re-run the effect if name or email change

    const handleClickProfile = event => {
        setOpenProfile(!openProfile)
    }

    const handleClickAudit = event => {
        setOpenAudit(!openAudit)
    }

    const buildAudit = audit => {
        let result = JSON.parse(audit.result)
        setAudit({
            ...audit,
            result: result
        })

        console.log(result)
        var _rows = []
        Object.keys(result).forEach(_a => {
            if (_a !== "ranking")
                _rows.push({
                    coincidenceType: props.translate(_a),
                    coincidence: result[_a][0].item,
                    value: result[_a][0].value,
                    id: result[_a][0].id
                })
            else
                setRanking(result[_a] * 10)
        })

        setRows(_rows)
    }

    const handleUpdateCustomer = (event, isBlacklist) => {
        setSending(true)
        if (!isBlacklist)
            props.handleUpdateCustomer({
                id: props.customer.id,
                sellBanned: false
            }).then(res => {
                showMessage(res.customerName)
                props.handleUpdateAudit({
                    id: audit.id,
                    blacklist: false,
                    endAudit: parseInt(new Date().getTime() / 1000),
                    auditor: props.idUser
                }).then(_res => {
                    // Send Notifications Activation Successfully
                    var sms = {
                        message: `${props.translate("activationSuccessfullyLegend_sms")}`
                    }

                    var email = {
                        sender: "clubconviene@bamaglobal.com.mx",
                        subject: props.translate("Activation Successfully"),
                        title: props.translate("Activation Successfully"),
                        content: `
        <div style="width: 100%">
        <div style="text-align: right;">
        ${new Date(res._lastChangedAt).toLocaleString()}
        </div>
        <div style="margin: 0 auto; text-align: center;">
        <h2>
        ${props.translate("Hello")} ${res.customerName}!
        </h2>
        </div>
        <div style="width: 100%">
        ${props.translate("activationSuccessfullyLegend")}
        </div>
        </div>
        `}
                    props.sendNotifications(res, sms, email).then(res => {
                        setSending(false)
                        props.handleClose()
                    })
                        .catch(err => {
                            setSending(false)
                        })
                }).catch(err => {
                    setSending(false)
                })
            }).catch(err => {
                setSending(false)
            })
        else
            props.handleUpdateCustomer({
                id: props.customer.id,
                customerId: "blacklist",
                sellBanned: true
            }).then(res => {
                showMessage(res.customerName)
                props.handleUpdateAudit({
                    id: audit.id,
                    blacklist: true,
                    endAudit: parseInt(new Date().getTime() / 1000),
                    auditor: props.idUser
                }).then(_res => {
                    // Send Notifications BlackList
                    var sms = {
                        message: `${props.translate("blacklistLegend_sms")}`
                    }

                    var email = {
                        sender: "clubconviene@bamaglobal.com.mx",
                        subject: props.translate("blacklistTitle_email"),
                        title: props.translate("blacklistTitle_email"),
                        content: `
        <div style="width: 100%">
        <div style="text-align: right;">
        ${new Date(res._lastChangedAt).toLocaleString()}
        </div>
        <div style="margin: 0 auto; text-align: center;">
        <h2>
        ${props.translate("Hello")} ${res.customerName}!
        </h2>
        </div>
        <div style="width: 100%">
        ${props.translate("blacklistLegend_email")}
        </div>
        </div>
        `}
                    props.sendNotifications(res, sms, email).then(res => {
                        setSending(false)
                        props.handleClose()
                    })
                        .catch(err => {
                            setSending(false)
                        })
                }).catch(err => {
                    setSending(false)
                })
            }).catch(err => {
                setSending(false)
            })
    }

    const showMessage = name => {
        props.showMessage(`${name} ${props.translate("Updated")}`, SNACKBAR_SUCCESS, 5000, null, null, null, null, null, null, props);
    }

    if (props.customer)
        return <Dialog fullScreen
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}>
            <AppBar
                color="secondary"
                className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            {props.searching || sending ? <LinearProgress color="primary" /> : ''}
            <Container fixed>
                <List>
                    <ListItem
                        button onClick={handleClickProfile}>
                        <ListItemAvatar>
                            <AccountCircleIcon color="action" />
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.customer.customerName}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary">
                                        {props.customer.preregistrationCode}
                                    </Typography>
                                    {props.customer._lastChangedAt ? new Date(props.customer._lastChangedAt).toLocaleString() : ''}
                                </React.Fragment>
                            }
                        />
                        {openProfile ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openProfile} timeout="auto" unmountOnExit>
                        <CardProfile
                            {...props}
                            customer={props.customer}
                        />
                    </Collapse>
                    {audit ?
                        <ListItem
                            button onClick={handleClickAudit}>
                            <ListItemAvatar>
                                <VerifiedUserIcon color="action" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={props.translate("Audit Detail")}
                                secondary={
                                    <React.Fragment>
                                        {new Date(props.audit.startAudit).toLocaleString()}
                                    </React.Fragment>
                                }
                            />
                            {openAudit ? <ExpandLess /> : <ExpandMore />}
                        </ListItem> : ''}
                    {audit ? <Collapse in={openAudit} timeout="auto" unmountOnExit>
                        <Grid container
                            justify="flex-start"
                            alignItems="center">
                            <Grid item xs={3}>
                                <Grid container
                                    direction="column"
                                    justify="center"
                                    alignItems="center">
                                    <Grid item>
                                        <b>{props.translate("Risk Factor")}</b>
                                    </Grid>
                                    <Grid item>
                                        <CircularProgress variant="static" color="secondary" value={ranking} />
                                    </Grid>
                                    <Grid item>
                                        {`${ranking.toFixed(2)} %`}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9}>
                                <AuditResume
                                    translate={props.translate}
                                    showMessage={props.showMessage}
                                    customer={props.customer}
                                    audit={props.audit}
                                />
                            </Grid>
                        </Grid>
                        {audit && audit.result && rows ?
                            <MaterialTable
                                icons={tableIcons}
                                localization={localization(props)}
                                options={{
                                    exportButton: true
                                }}
                                columns={[
                                    { title: props.translate("Coincidence Type"), field: "coincidenceType" },
                                    {
                                        title: "%", field: "value", render: rowData => {
                                            return <Tooltip title={`${(rowData.value * 100).toFixed(2)} %`}>
                                                <CircularProgress variant="static" value={rowData.value * 100} />
                                            </Tooltip>
                                        }
                                    },
                                    { title: props.translate("Coincidence"), field: "coincidence" },
                                ]}
                                data={rows}
                                title={props.translate("Coincidences")}
                            />
                            : ''}
                    </Collapse> : ''}
                </List>
                <Box my={4}>
                    {audit && audit.result && rows ?
                        <Grid container
                            spacing={3}
                            justify="flex-end"
                            alignItems="center">
                            <Grid item>
                                <Button
                                    disabled={sending || (audit && audit.endAudit)}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<VerifiedUserIcon />}
                                    onClick={event => handleUpdateCustomer(event, false)}>
                                    {props.translate("Authorize Registry")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={sending || (audit && audit.endAudit)}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<BlockIcon />}
                                    onClick={event => handleUpdateCustomer(event, true)}>
                                    {props.translate("Send to Blacklist")}
                                </Button>
                            </Grid>
                        </Grid>
                        : ''}
                </Box>
                {/*<Typography variant="body2" color="textSecondary">
                    {JSON.stringify(props.customer)}
                        </Typography>
                <Typography variant="body2" color="textSecondary">
                    {JSON.stringify(audit)}
                </Typography>*/}
            </Container>
        </Dialog>
    else
        return ''
}