import { makeStyles } from '@material-ui/core/styles';

export const containerStyle = makeStyles({
    container: {
        background: '#F1F0F4',
        padding: '1em',
        borderRadius: 5
    }
});

export const breadcrumbsStyle = {
    /*ol: {
        justifyContent: 'center'
    }*/
};

