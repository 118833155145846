import React from 'react';
import { Container, Typography } from '@material-ui/core';

function TermsAndConditions() {
    return (<Container>
        <Typography variant="h6" gutterBottom>
            {"Tiendas Bama, S.A. de C.V."}
        </Typography>
        <Typography color="primary" variant="h5" gutterBottom>
            {"Términos y condiciones de uso de "}
            <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bienvenidos al sitio en internet de Tiendas Bama ("}
            <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
            {") (en adelante el “Portal”)."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Agradecemos su visita y hacemos de su conocimiento que al ingresar a nuestro Portal y navegar en el mismo se entiende que usted está enterado y que acepta los términos y condiciones que se describen más adelante."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Tiendas Bama, S.A. de C.V. (en lo sucesivo “Bama”), con domicilio en Carretera Transístmica Número Km. 7.5, Int. 102 BA, Colonia Tierra Nueva, C.P. 96496, de Coatzacoalcos, Veracruz de Ignacio de la Llave, para ofrecerle un ambiente seguro, ha establecido reglas y términos que delimitan las áreas de responsabilidad y derechos, tanto de Bama como de sus visitantes."}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            {"Usted como Usuario, al momento de visitar o utilizar el Portal, cuyo nombre y dominio es propiedad de Bama, acepta los presentes términos y condiciones y declara expresamente su aceptación, manifestando su voluntad, en términos de la legislación aplicable vigente. La sola utilización del Portal le otorga la condición de Usuario, e implica la aceptación plena e incondicional, de todas y cada una de las condiciones que se establecen en estos términos de uso en el momento mismo en que el Usuario acceda al Portal."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Si usted no acepta estos términos y condiciones deberá abstenerse de visitar y utilizar el Portal."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Cualquier modificación a los presentes términos y condiciones será realizada cuando Bama lo considere apropiado, siendo exclusiva responsabilidad del Usuario revisar las respectivas modificaciones. Bama podrá efectuar en cualquier momento modificaciones o actualizaciones a los presentes términos y condiciones. Usted está de acuerdo y conforme en que la forma de notificar cualquier cambio a los presentes términos y condiciones será a través del Portal. "}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"I. Aviso de privacidad."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama observa los principios de licitud, consentimiento, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, en el tratamiento y resguardo de los datos personales que el Usuario proporciona a través del Portal, los cuales son utilizados para registrarlos en nuestra base de datos para informar sobre nuevos productos o servicios, reclutamiento de personal, captación de proveedores, evaluación de la calidad del servicio que ofrecemos y realización de estudios sobre hábitos de consumo."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Para mayor información sobre el tratamiento de sus datos personales, Bama pone a su disposición el Aviso de Privacidad Integral en su página web: "}
            <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"II. Licencia."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama otorga y concede al Usuario el derecho no exclusivo, revocable e intransferible de ver y utilizar "}
            <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
            {", de conformidad con los términos y condiciones que aquí se establecen."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            <b>{"Usuario: "}</b>
            {"Es toda aquella persona que ingrese a "}
            <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
            {", se registre y/o utilice cualquiera de los servicios que se ofrecen a través del Portal."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama otorga y concede al Usuario el derecho no exclusivo, revocable e intransferible de ver y utilizar el Portal, de conformidad con los términos y condiciones que aquí se establecen."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama se reserva el derecho de negar el acceso a su página de internet ubicada en la dirección "}
            <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
            {", cuando la conducta del Usuario sea contraria a lo establecido en los presentes términos y condiciones."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"La publicación, reimpresión, distribución, asignación, sublicencia, venta, comercialización, reproducción electrónica o por cualquier otro medio de cualquier información, documento, fotografía, imagen o gráfico que aparezca en el Portal, en todo o en parte, para cualquier uso distinto al personal no comercial está prohibido para el Usuario, a menos que Bama le otorgue autorización previa y por escrito."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"III. Derechos de autor y propiedad industrial."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Las marcas registradas "}<b>{"Bama®"}</b>{", "}<b>{"Conviene®"}</b>{", "}<b>{"Bama Express®"}</b>{", su diseño, logotipos y todos los materiales contenidos en la página de internet ubicada en la dirección "}
            <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
            {" son marcas, nombres de dominio, nombres comerciales, propiedad de sus respectivos titulares y están protegidos por los tratados internacionales y las leyes aplicables en materia de propiedad intelectual y de derechos de autor."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, fotografías, imágenes, programas, aplicaciones y en general cualquier información contenida o publicada en el Portal, se encuentran debidamente protegidos a favor de Bama, sus filiales, controladoras y proveedores, de conformidad con la legislación aplicable en materia de propiedad intelectual e industrial."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Se prohíbe expresamente al Usuario modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres comerciales, diseños, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de la información contenida en el Portal."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"IV. Cesión de derechos."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama, en cualquier tiempo y cuando así lo estime conveniente, podrá ceder total o parcialmente sus derechos y obligaciones derivados de los presentes términos y condiciones. En virtud de dicha cesión, Bama quedará liberada de cualquier obligación a favor del Usuario establecida en los presentes términos y condiciones."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"V. Menores de edad."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Los menores de edad, para hacer uso del Portal, deberán obtener previamente permiso de sus padres, tutores o representantes legales, quienes serán considerados como responsables de todos los actos realizados por los menores a su cargo."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"VI. Modificaciones al Portal."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama podrá en cualquier tiempo y cuando así lo estime conveniente, sin necesidad de dar aviso al Usuario, realizar correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, bases de datos y demás elementos del Portal, sin que esto dé lugar o derecho a reclamación alguna o a indemnización."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama se reserva el derecho de modificar los presentes términos y condiciones, en cualquier tiempo, siendo efectivas dichas modificaciones en el momento de su publicación en el Portal; asimismo se reserva el derecho, a su exclusiva discreción, y sin necesidad de aviso o notificación al Usuario de descontinuar o dar de baja el Portal, sin responsabilidad alguna para Bama. El Usuario acepta revisar los presentes términos y condiciones a fin de estar enterado en todo momento de dichas modificaciones."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Cada vez que el Usuario acceda al Portal se considerará aceptación absoluta de las modificaciones a los presentes términos y condiciones."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"VII. Confidencialidad."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama se obliga a mantener confidencial la información que reciba del Usuario que tenga tal carácter conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos. Bama no asume obligación alguna de mantener confidencial cualquier otra información que el Usuario le proporcione, incluyendo aquella información que el Usuario le proporcione a través de boletines, conversaciones en línea (chats) o a través de Cookies, como se describe en el punto número X."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"VIII. Indemnización."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"El Usuario está de acuerdo en indemnizar a Bama de cualquier acción, demanda o reclamación, incluso de honorarios de abogados y costas judiciales, derivadas de cualquier incumplimiento por parte del Usuario a los presentes términos y condiciones, incluyendo sin limitación alguna, las siguientes:"}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            <ul>
                <li>
                    {"De cualquier aspecto relativo al uso de la página de internet ubicada en la dirección "}
                    <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
                </li>
                <li>
                    {"De injurias, difamación o cualquier conducta violatoria de los presentes términos y condiciones por parte del Usuario."}
                </li>
                <li>
                    {"De violación a las leyes aplicables o tratados internacionales."}
                </li>
            </ul>
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"IX. Restricciones de responsabilidad."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Usted usa la página de internet ubicada en la dirección "}
            <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
            {" bajo su propia responsabilidad y riesgo."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama se libera de cualquier responsabilidad y condiciones, tanto expresas como implícitas, con relación a los servicios e información contenida o disponible en o a través del Portal, incluyendo sin limitación alguna:"}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            <ul>
                <li>
                    {"La disponibilidad de uso de la página de internet ubicada en la dirección "}
                    <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
                </li>
                <li>
                    {"La ausencia de virus, errores, desactivadores o cualquier otro material contaminante o con funciones destructivas en la información o programas disponibles en o a través de esta página o en general cualquier falla en dicho sitio."}
                </li>
            </ul>
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"No obstante, Bama podrá actualizar el contenido de su página constantemente, por lo tanto se solicita al Usuario tomar en cuenta que algunas informaciones publicitadas o contenidas en o a través del Portal pueden haber quedado obsoletas y/o contener imprecisiones o errores tipográficos u ortográficos."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Cualquier descarga (“download”) o mecanismo para obtener material o datos de esta página de internet, es realizado bajo su propio riesgo, usted será absolutamente responsable por cualquier daño al sistema de su computadora o al dispositivo de comunicaciones electrónicas o pérdida de datos que resultare de la descarga (“download”) de cualquiera de estos materiales."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Con apego a las leyes aplicables, Bama no será responsable, en ninguno de los casos, por cualquier daño directo, especial, incidental, indirecto, o consecuencia que en cualquier forma se derivan o se relacionen con: "}
        </Typography>
        {/* <Typography color="textSecondary" align="justify" variant="body2" gutterBottom> */}
        <Typography color="textSecondary" align="justify" variant="inherit" gutterBottom>
            <ul>
                <li>
                    {"El uso o ejecución de la página de internet ubicada en la dirección "}
                    <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
                    {", retraso o falta de disponibilidad."}
                </li>
                <li>
                    {"De la actualización o falta de actualización de la página de internet ubicada en la dirección "}
                    <a target="_blank" href="https://bamaglobal.com.mx" rel="noopener noreferrer">{"www.bamaglobal.com.mx"}</a>
                </li>
                <li>
                    {"De la alteración o modificación, total o parcial, de la información después de haber sido incluida en dicho Portal."}
                </li>
                <li>
                    {"Cualquier otro aspecto o característica de la información contenida o publicada en el Portal o a través de las ligas que eventualmente se incluyan en dicha página de internet."}
                </li>
            </ul>
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"X. Cookies."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            <b>{"Cookie: "}</b>
            {"Significa archivo de datos que se almacena en el disco duro de la computadora o del dispositivo de comunicaciones electrónicas del Usuario cuando éste navega en un sitio de internet específico, el cual permite intercambiar información de estado entre dicho sitio y el navegador del Usuario. La información de estado puede revelar medios de identificación de sesión, autenticación o preferencias del Usuario, así como cualquier dato almacenado por el navegador respecto al sitio de internet. Una Cookie no puede leer los datos o información del disco duro del Usuario ni leer las Cookies creadas por otros sitios o páginas."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"El Usuario, por el simple hecho de visitar o utilizar el Portal, conviene en recibir archivos que le transmitan los servidores de Bama. "}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"XI. Términos adicionales."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Bama en cualquier tiempo y cuando así lo estime conveniente, podrá agregar a los presentes términos y condiciones, reglas y términos adicionales relativos a áreas específicas o nuevos productos o servicios, los cuales serán publicados en este mismo Portal, para su lectura y aceptación. El Usuario reconoce y acepta que estos términos adicionales forman parte integrante de los presentes términos y condiciones para todos los efectos legales."}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
            {"XII. Legislación aplicable y jurisdicción."}
        </Typography>
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Los presentes términos y condiciones están sujetos y serán interpretados de acuerdo con la legislación aplicable vigente en la República Mexicana y cualquier controversia derivada de estos deberá ser resuelta ante los tribunales competentes de la ciudad de Coatzacoalcos, Veracruz de Ignacio de la Llave."}
        </Typography>
        <br />
        <br />
        <br />
        <Typography color="textSecondary" align="justify" variant="body2" gutterBottom>
            {"Fecha de última actualización: 29 de junio de 2020"}
        </Typography>
    </Container>)
}

export default TermsAndConditions;