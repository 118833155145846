import React , { Component } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


export default class  ModalSelect extends Component  {

    constructor(props) {
        super(props);
        this.state = {
          value: '',
          disabled: true
        };
      }

    selectVariable(paramater) {
        this.setState({
            ...this.state,
            value: paramater,
            disabled: false
        });
    }


    render (){

        const { open , handleClose  , organizations  , saveElection } = this.props;
        const { value , disabled } = this.state;

        return (
            <Dialog fullScreen open={open} closeAfterTransition>
                <Fade in={open}>
                <div className={'dialog-content'}>
                    <h2 id="transition-modal-title">Selecciona una empresa</h2>
                    <Grid className={'btn-wrapper'} container spacing={3}>
                        <Grid item xs={12} >
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <Box>
                                { organizations.length >= 1 ? (
                                    <div className={'select-wrapper'}>
                                        <Autocomplete
                                            id="combo-1"
                                            onChange={(event , value) => this.selectVariable(value) } 
                                            options={organizations}
                                            getOptionLabel={option => option.name}
                                            style={{ width: 300 }}
                                            renderInput={params => <TextField {...params} label="Empresas" variant="outlined" style={{ width: 300 }} />}
                                        />
                                    </div>
                                    ) : ( <CircularProgress/>) 
                                }
                                </Box>
                            </Box> 
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                            className={'btn accept-btn'}
                            variant="contained"
                            onClick={() => saveElection(value)}
                            disabled={ disabled ? true : false}
                            >
                            Aceptar
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/prometheus/variableCommissions">
                                <Button className={'btn cancel-btn'} onClick={handleClose}>
                                    Cancelar
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                </Fade>
            </Dialog>
        );
    }

}
