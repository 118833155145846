import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { tableIcons, localization } from '../../../components/materialTable/MaterialTable';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';


import { withSnackbar } from "notistack";
import { SNACKBAR_SUCCESS } from '../../../utils/SessionControl';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import SystemWarehouse from "./SystemWarehouse"



import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  Slide,
  Toolbar,
  AppBar,
  Icon,
  ButtonGroup,
  Grow,
  Popper,
  MenuList,
  FormControl,
  DialogTitle,
  DialogContent,
  Input,
  DialogActions,
  NativeSelect,
  Fab,
  CircularProgress
} from "@material-ui/core";
import { listWorkTeamTemplates, listZones } from '../../../graphql/queries'
import { updateWarehouse } from '../../../graphql/mutations'
import ConstructorSelector from "../../../components/ConstructorSelector";
import { api, apiPartial } from "../../../api/api";
import { store } from '../../../App';




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button_save: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(7),

  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Warehousehome(props) {
  const classes = useStyles();
  const firstRender = useRef(true);
  const anchorRefZone = React.useRef(null);
  const [Warehouse, setWarehouse] = useState([]);
  const [editWarehouse, setEditWarehouse] = useState([]);
  const [openDilogEditERP, setOpenDilogEditERP] = useState(false);

  const [selectedZone, setSelectedZone] = React.useState({});
  const [selectedWorkTeam, setSelectedWorkTeam] = React.useState({});

  const [optionsZone, setoptionsZone] = useState([
    { zoneId: "-1", zoneName: "NINGUNA" },
  ]);


  const [optionsTeamTemplates, setoptionsTeamTemplates] = useState([
    { workTeamTemplate: "NINGUNO", id: "-1" },
  ]);

  useEffect(() => {
    if (Warehouse !== props.warehouse) {
      if (firstRender.current) {
        if (firstRender.current) {
          firstRender.current = false; // it's no longer the first render

          if (Warehouse !== null) {
            loadZones();
            loadWorkTeamTemplates(props.warehouse);
          }

          return; // skip the code below
        }
      }

      buildWarehouse();
    }
    return function cleanup() { };
  }, [props.warehouse]); // Only re-run the effect if name or email change


  const loadZones = () => {
    api(listZones, { limit: 300 })
      .then((res) => {
        let totalFilter = [];
        let itemFilter = res.filter((ware) => ware.zoneType === "warehouse");
        totalFilter.push({ zoneId: "-1", zoneName: "NINGUNA" },);
        itemFilter.forEach((element, i) => {
          totalFilter.push(element);
        });
        // console.log(totalFilter)
        setoptionsZone(totalFilter.sort((a, b) => {
          let keyA = parseInt(a.zoneId);
          let keyB = parseInt(b.zoneId);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }))
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadWorkTeamTemplates = (warehouse) => {
    api(listWorkTeamTemplates, { limit: 300 })
      .then((res) => {
        let arrayWorkTeam = []
        arrayWorkTeam.push({ workTeamTemplate: "NINGUNO", id: "-1" },)
        res.forEach(element => {
          arrayWorkTeam.push(element)
        });
        setoptionsTeamTemplates(arrayWorkTeam);
        loadZones();
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleCloseERP = () => {
    setOpenDilogEditERP(false);
  };

  const handleChangeERP = (row) => {
    setOpenDilogEditERP(true)
    setEditWarehouse(row)
  };

  const handleClose = (id, event) => {
    if (anchorRefZone.current && anchorRefZone.current.contains(event.target)) {
      return;
    }
    const temp = [...Warehouse]
    temp.forEach((_e, i) => {
      if (_e.id === id) {
        _e.openZone = false;
      }

    });


    setWarehouse(temp)
  };

  const handleChange = (data, id) => {
    const temp = [...Warehouse]
    temp.forEach((_e, i) => {
      if (_e.id === id) {
        _e.Selectedzone = parseInt(data.zoneId);
        if (_e.initialzone === parseInt(data.zoneId)) {
          _e.stateinitialzone = false
        } else {

          _e.stateinitialzone = true

        }

      }
    });

    let itemFilter = temp.filter((ware) => ware.id === id);
    setSelectedZone(itemFilter[0])
    setWarehouse(temp)
  };

  const handleOpen = (index, e) => {
    const temp = [...Warehouse]
    temp.forEach((_e, i) => {
      if (_e.id === index) {
        _e.openZone = !_e.openZone;
      }
    });
    setWarehouse(temp)
  };

  const handleOpenWorkteam = (index, e) => {
    const temp = [...Warehouse];
    temp.forEach((_e, i) => {
      if (_e.id === index) {
        _e.openWorkTeam = !_e.openWorkTeam;
      }
    });
    setWarehouse(temp);
  };

  const handleCloseWorkteam = (id, event) => {
    const temp = [...Warehouse]
    temp.forEach((_e, i) => {
      if (_e.id === id) {
        _e.openWorkTeam = false;
      }
    });
    setWarehouse(temp)
  };

  const handleChangeWorkteam = (data, id) => {
    let indexType = 0
    optionsTeamTemplates.forEach((_k, i) => {
      if (_k.workTeamTemplate === data.workTeamTemplate) {
        indexType = i
      }
    });
    const temp = [...Warehouse]
    temp.forEach((_e, i) => {
      if (_e.id === id) {
        _e.SelectedworkTeam = indexType;
        if (_e.initialworkTeam === indexType) {
          _e.stateinitialworkTeam = false
        } else {
          _e.stateinitialworkTeam = true
        }
      }
    });

    let itemFilter = temp.filter((ware) => ware.id === id);
    setSelectedWorkTeam(itemFilter[0])

    setWarehouse(temp)
  };


  function getZone(data) {
    let indexType = 0
    optionsZone.forEach((_k, i) => {
      if (data.zone !== null) {
        if (_k.zoneId === data.zone.zoneId) {
          indexType = i;
        }
      } else {
        indexType = -1;
      }
    });
    return (indexType)
  }

  function getTeamTemplates(data) {
    let indexType = 0
    optionsTeamTemplates.forEach((_k, i) => {
      if (data.workTeamTemplate !== null) {
        if (_k.workTeamTemplate === data.workTeamTemplate.workTeamTemplate) {
          indexType = i;
        }
      }
      else {
        indexType = -1;
      }
    });
    return (indexType)
  }

  function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  const buildWarehouse = () => {
    if (props.warehouse !== null) {
      let temp = []
      props.warehouse.forEach(_e => {
        let customWarehouse = {
          address: _e.address,
          id: _e.id,
          location: _e.location,
          organization: _e.organization,
          settings: _e.settings,
          warehouseId: _e.warehouseId,
          warehouseName: _e.warehouseName,
          warehouseRelatedId: _e.warehouseRelatedId,
          warehouseType: _e.warehouseType,
          workTeamTemplate: _e.workTeamTemplate,
          zone: _e.zone,
          openZone: false,
          openWorkTeam: false,
          stateinitialzone: false,
          stateinitialworkTeam: false,
          loadingzone: false,
          loadingworkTeam: false,
          initialzone: getZone(_e),
          initialworkTeam: getTeamTemplates(_e),
          Selectedzone: getZone(_e),
          SelectedworkTeam: getTeamTemplates(_e),
          optionsZone: optionsZone.sort(compareValues('zoneId', 'asc')),
          optionsWorkTeam: optionsTeamTemplates,
          _version: _e._version
        };
        temp.push(customWarehouse)
      });
      setWarehouse(temp);
    }
  };

  const handleSaveZone = (e) => {

    const temp = [...Warehouse];
    temp.forEach((_e, i) => {
      if (_e.id === selectedZone.id) {
        _e.loadingzone = true;
        _e.stateinitialzone = false;
      }
    });
    setWarehouse(temp);

    let customWarehouse = {
      id: selectedZone.id,
      warehouseZoneId: selectedZone.Selectedzone < 0 ? null : optionsZone[selectedZone.Selectedzone].id,
      _version: selectedZone._version
    };

    api(updateWarehouse, { input: customWarehouse })
      .then((res) => {
        props.loadWarehouse()
        // const temp = [...Warehouse];
        // temp.forEach((a, i) => {
        //   if (a.id === res.id) {
        //     temp.splice(i, 1, { ...a, ["zone"]: optionsZone[selectedZone.Selectedzone] });
        //   }
        // });
        // temp.forEach((_e,i) => {
        //   if (_e.id === selectedZone.id) {
        //       _e.loadingzone = false
        //   }
        // });
        // setWarehouse(temp); 
        props.showMessage(` ${"Se ha actualizado tu sucursal satisfactoriamente."}`, SNACKBAR_SUCCESS, 5000, null, null, null, null, null, " ", props);
      })
      .catch((err) => {
        console.log(err);
      });

  };

  const handleSaveWorkTeams = (e) => {
    const temp = [...Warehouse];
    temp.forEach((_e, i) => {
      if (_e.id === selectedWorkTeam.id) {
        _e.loadingworkTeam = true;
        _e.stateinitialworkTeam = false;
      }
    });
    setWarehouse(temp);

    let customWarehouse = {
      id: selectedWorkTeam.id,
      warehouseWorkTeamTemplateId: selectedWorkTeam.SelectedworkTeam < 0 ? "" : optionsTeamTemplates[selectedWorkTeam.SelectedworkTeam].id,
      _version: selectedWorkTeam._version
    };

    api(updateWarehouse, { input: customWarehouse })
      .then((res) => {
        props.loadWarehouse()
        //  const temp = [...Warehouse];
        //  temp.forEach((a, i) => {
        //    if (a.id === res.id) {
        //      temp.splice(i, 1, { ...a, ["workTeamTemplate"]: optionsTeamTemplates[selectedWorkTeam.SelectedworkTeam] });
        //    }
        //  });
        //  temp.forEach((_e,i) => {
        //    if (_e.id === selectedWorkTeam.id) {
        //        _e.loadingworkTeam = false
        //    }
        //  });
        //  setWarehouse(temp); 
        props.showMessage(` ${"Se ha actualizado tu sucursal satisfactoriamente."}`, SNACKBAR_SUCCESS, 5000, null, null, null, null, null, " ", props);
      })
      .catch((err) => {
        console.log(err);
      });


  };

    
  return (
    <Box p={2}>
      <Typography variant="h6" color="textSecondary">
        {props.translate("Store Config")}
      </Typography>
      <Grid item xs={12}>
        {Warehouse !== null && (
          <Grid item xs={12}>
            <Paper>
              <MaterialTable
                icons={tableIcons}
                localization={localization(props)}
                options={{
                  exportButton: true,
                }}
                columns={[
                  {
                    field: "warehouseRelatedId",
                    title: "ClaUN",
                  },
                  {
                    field: "warehouseName",
                    title: props.translate("Branch office"),
                  },

                  {
                    field: "organization.organizationName",
                    title: props.translate("Business"),
                  },
                  {
                    field: "",
                    title: props.translate("config erp"),
                    render: (rowData) => {
                      return (
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          alignItems="center"
                          justify="center"
                          className={classes.root}
                        >
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="secondary"
                              disabled = {store.getState().UserProfileReducer.user.id === "c15dd650-10fa-4bad-bfdd-2041c771a3c1" || store.getState().UserProfileReducer.user.id === "0b49617b-ca79-4edf-a415-ad288c032a65"? false:true}
                              onClick={() => {
                                handleChangeERP(rowData);
                              }}
                            >
                              {props.translate("Edit")}
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    },
                  },
                  {
                    field: "",
                    title: "Zona Sucursal",
                    render: (rowData) => {
                      return (
                        <Grid container item xs={12} spacing={3}>
                          <Grid item xs={6}>
                            <Paper elevation={0} className={classes.paper}>
                              <FormControl className={classes.formControl}>
                                <Select
                                  labelId={`select-label-${rowData.id}`}
                                  id={`select-label-${rowData.id}`}
                                  open={rowData.openZone}
                                  onClose={(event) =>
                                    handleClose(rowData.id, event)
                                  }
                                  onOpen={(event) =>
                                    handleOpen(rowData.id, event)
                                  }
                                  value={rowData.Selectedzone}
                                >

                                  {rowData.optionsZone.map((input, index) => (
                                    <MenuItem
                                      onClick={(event) =>
                                        handleChange(input, rowData.id)
                                      }
                                      value={index < 0 ? "" : index}
                                    >
                                      {input.zoneName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Paper>
                          </Grid>
                          <Grid item xs={6}>
                            <Paper elevation={0} className={classes.paper}>
                              {rowData.stateinitialzone ? (
                                // <Fab
                                //   className={classes.button_save}
                                //   aria-label="save"
                                //   color="primary"
                                //   onClick={handleChange}
                                // >
                                //   <SaveIcon />
                                // </Fab>
                                <IconButton
                                  className={classes.button_save}
                                  onClick={handleSaveZone}
                                  color="primary"
                                  aria-label="save data"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              ) : (
                                  ""
                                )}
                              {rowData.loadingzone ? (
                                <CircularProgress
                                  className={classes.button_save}
                                />
                              ) : (
                                  ""
                                )}
                            </Paper>
                          </Grid>
                        </Grid>
                      );
                    },
                  },
                  {
                    field: "",
                    title: "Tipo Sucursal",
                    render: (rowData) => {
                      return (
                        <Grid container item xs={12} spacing={3}>
                          <Grid item xs={6}>
                            <Paper elevation={0} className={classes.paper}>
                              <FormControl className={classes.formControl}>
                                <Select
                                  labelId={`select-label-${rowData.id}`}
                                  id={`select-label-${rowData.id}`}
                                  open={rowData.openWorkTeam}
                                  onClose={(event) =>
                                    handleCloseWorkteam(rowData.id, event)
                                  }
                                  onOpen={(event) =>
                                    handleOpenWorkteam(rowData.id, event)
                                  }
                                  value={rowData.SelectedworkTeam}
                                >

                                  {rowData.optionsWorkTeam.map(
                                    (input, index) => (
                                      <MenuItem
                                        disabled={input._deleted || !input.active}
                                        onClick={(event) =>
                                          handleChangeWorkteam(
                                            input,
                                            rowData.id
                                          )
                                        }
                                        value={index}
                                      >
                                        {input.workTeamTemplate}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Paper>
                          </Grid>
                          <Grid item xs={6}>
                            <Paper elevation={0} className={classes.paper}>
                              {rowData.stateinitialworkTeam ? (
                                // <Fab
                                //   className={classes.button_save}
                                //   aria-label="save"
                                //   color="primary"
                                //   onClick={handleChange}
                                // >
                                //   <SaveIcon />
                                // </Fab>
                                <IconButton className={classes.button_save}
                                  onClick={handleSaveWorkTeams}
                                  color="primary" aria-label="save data" component="span">
                                  <SaveIcon />
                                </IconButton>
                              ) : (
                                  ""
                                )}
                              {rowData.loadingworkTeam ? <CircularProgress className={classes.button_save} /> : ""}
                            </Paper>
                          </Grid>
                        </Grid>
                      );
                    },
                  },
                ]}
                data={Warehouse}
                title={""} //
              />
            </Paper>
          </Grid>
        )}
      </Grid>
      <Dialog
        fullScreen
        open={openDilogEditERP}
        onClose={handleCloseERP}
        TransitionComponent={Transition}
      >
        <AppBar color="secondary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseERP}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Button
              variant="contained"
              color="primary"
              className={classes.button_save}
              endIcon={<Icon>save</Icon>}
            >
              Guardar configuración 
            </Button> */}
          </Toolbar>
        </AppBar>
        <Box p={2}>
          <SystemWarehouse
            {...props}
            warehouse={editWarehouse}
          ></SystemWarehouse>
        </Box>
      </Dialog>
    </Box>
  );
}
export default withSnackbar(Warehousehome);
