import InitSearchIndicator from './Indicadores/InitSearchIndicator';

import { connect } from 'react-redux';

import {
    VariableCommissions_onOrganization,
    VariableCommissions_onIndicator
} from '../redux/actions/VariableCommissionsAction';

const mapDispatchToProps = dispatch => ({
    VariableCommissions_onOrganization: data => {
        dispatch(VariableCommissions_onOrganization(data));
    },
    VariableCommissions_onIndicator: data => {
        dispatch(VariableCommissions_onIndicator(data));
    }
});

const mapStateToProps = state => ({
    organization: state.organization,
    indicator: state.indicator
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitSearchIndicator);

