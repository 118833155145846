import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';

import {
    LineChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Line
} from 'recharts';

function ForecastChart(props) {
    const [forecast, setForecast] = useState(null)
    const [dataChart, setdataChart] = useState(null)

    useEffect(() => {
        // console.log("ForecastChart", props)
        if (forecast !== props.forecast) {
            buildChart(props.forecast)
        }

    }, [props.products, props.forecast]);

    const buildChart = async (_forecast) => {
        props.showProgress(true)

        var _dataChart = []
        console.log("ForecastChart", _forecast)
        _forecast && _forecast.Items && _forecast.Items.forEach(f => {
            var exists = false

            _dataChart.forEach(dc => {
                if (dc.product === f.item_id) {
                    exists = true

                    var existsDate = false
                    dc.data.forEach(d => {
                        if (d.date === f.date.split('T')[0]) {
                            existsDate = true

                            d.p10 = d.p10 + f.p10
                            d.p50 = d.p50 + f.p50
                            d.p90 = d.p90 + f.p90
                        }
                    })

                    if (!existsDate) {
                        dc.data.push({
                            date: f.date.split('T')[0],
                            p10: f.p10,
                            p50: f.p50,
                            p90: f.p90
                        })
                    }
                }
            })

            if (!exists) {
                _dataChart.push({
                    product: f.item_id,
                    data: [{
                        date: f.date.split('T')[0],
                        p10: f.p10,
                        p50: f.p50,
                        p90: f.p90
                    }],
                    keys: [
                        "p10", "p50", "p90"
                    ]
                })
            }
        })

        setdataChart(_dataChart)
        setForecast(_forecast)

        props.showProgress(false)
        return
    }

    return <Box my={3}>
        <Typography variant="h6" color="textSecondary">
            {`${props.translate("Forecast per Product")}`}
        </Typography>
        {dataChart && dataChart.map(dc => {
            return <Box key={`chart-${dc.product}`}>
                <Typography variant="h6" color="textSecondary">
                    {`${dc.product}`}
                </Typography>
                <Box height="300px">
                    <ResponsiveContainer>
                        <LineChart data={dc.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            {dc.keys.map(dk => {
                                var color = '#' + Math.floor(Math.random() * 16777215).toString(16);
                                return <Line key={`datkey-${dk}`} type="monotone" dataKey={dk} stroke={color} />
                            })}
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
        })}
    </Box>
}

export default ForecastChart;