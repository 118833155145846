import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Box,
  InputLabel,
  Typography
} from '@material-ui/core';

import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { getModalSelectorFatherScreen } from '../../../store/modalSelector/selectors';
import { setModalselectorShow } from '../../../store/modalSelector/actions';
import { setKPItime } from '../../../store/kpi/actions';


export class ModalSelectorTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      period: '',
      periodTimes: '',
      numberPeriod:0
    };
  }

  handleSelect(date) {
    this.setState({
      ...this.state,
      startDate: date,
    });
  }

  //** MODAL DE CALENDARIO */
  setValue() {
    const { screen, modalasSelectorShow, setKPItime } = this.props;
    const { period, periodTimes  } = this.state;
    switch (screen) {
      case 'KPI':
        let endDate = '';
        let starDateString = moment(this.state.startDate).format('YYYY-MM-DD');

        switch (period) {
          case 'diario':
            endDate = moment(starDateString).add({
              days: periodTimes,
            });
            break;
          case 'semana':
            endDate = moment(starDateString).add({
              weeks: periodTimes,
            });
            break;
          case 'quincenal':
            endDate = moment(starDateString).add({
              days: 15 * periodTimes,
            });
            break;
          case 'mensual':
            endDate = moment(starDateString).add({
              months: periodTimes,
            });
            break;

          default:
            break;
        }

        let start = moment(starDateString, 'YYYY-MM-DD');
        let end = moment(endDate, 'YYYY-MM-DD');

        let duration = end.diff(start, 'days');

        setKPItime({ start_date: starDateString, period: duration });
        break;

      default:
        break;
    }

    modalasSelectorShow(false);
  }

  render() {
    const { modalasSelectorShow } = this.props;
    const { period, periodTimes ,numberPeriod } = this.state;
    const periodItems = [];

    //console.log(this.state)
    for (let index = 1; index <= 10; index++) {
      periodItems.push(
        <MenuItem key={index} value={index}>
          {index}
        </MenuItem>,
      );
    }
    return (
      <div>
        <h2 id="transition-modal-title">Selecciona el periodo:</h2>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h4 id="transition-modal-description" >Paso 1 Selecciona la frecuencia de KPI:</h4>
              <div className={'select-wrapper'}>
                <FormControl className={'selector'}>
                  <InputLabel>Frecuencia...</InputLabel>
                  <Select
                    value={period}
                    onChange={event => {
                      if (event.target.value === "diario") {
                        this.setState({
                          ...this.state,
                          period: event.target.value,
                          periodTimes: 1,
                          numberPeriod:1
                        })
                      } else if (event.target.value === "semana") {
                        this.setState({
                          ...this.state,
                          period: event.target.value,
                          periodTimes: 1,
                          numberPeriod:7
                        })
                      } else if (event.target.value === "quincenal") {
                        this.setState({
                          ...this.state,
                          period: event.target.value,
                          periodTimes: 1,
                          numberPeriod:15
                        })
                      } else if (event.target.value === "mensual") {
                        this.setState({
                          ...this.state,
                          period: event.target.value,
                          periodTimes: 1,
                          numberPeriod:new Date(new Date().getFullYear(), new Date().getMonth()+1, 0).getDate()
                        })
                      }
                    }
                    }
                  >
                    <MenuItem value={'diario'}>Diario</MenuItem>
                    <MenuItem value={'semana'}>Semana</MenuItem>
                    <MenuItem value={'quincenal'}>Quincenal</MenuItem>
                    <MenuItem value={'mensual'}>Mensual</MenuItem>
                    );
                  </Select>
                </FormControl>
                {periodTimes ?
                  <Box>
                    <h4 id="transition-modal-description" style={{ padding: "20px" }}>Paso 3 Verifica datos de KPI:</h4>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold' }}> Fecha inicial </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold' }}> {new Date(new Date().setDate(new Date(this.state.startDate).getDate() - numberPeriod)).getDate()}-{new Date(new Date().setDate(new Date().getDate() - numberPeriod)).getMonth()+1}-{new Date(new Date().setDate(new Date().getDate() - numberPeriod)).getFullYear()} </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold' }}> Fecha de ejecución </Typography>
                      </Grid>
                      <Grid item xs={2}>
                      <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold' }}> {new Date(new Date().setDate(new Date(this.state.startDate).getDate())).getDate()}-{new Date(new Date().setDate(new Date().getDate())).getMonth()+1}-{new Date(new Date().setDate(new Date().getDate())).getFullYear()} </Typography>
                      </Grid>
                    </Grid>
                    <Typography mt={1}>Esta comision se ejecuta de manera {period}  </Typography>
                    <Typography mt={1}>La cual comienza a tomar datos apartir del dia {new Date(new Date().setDate(new Date(this.state.startDate).getDate() - numberPeriod)).getDate()}  y ejecutando el calculo KPI el dia {new Date(new Date(this.state.startDate)).getDate()} </Typography>

                    <Typography variant="subtitle2" gutterBottom style={{ padding: "20px", fontWeight: 'bold' }} mt={1}>Observacion: Es importante que se tenga información en el periodo a ejecutar definido, ya que este proceso de carga información debe ejecutarse antes de llegar a la fecha de ejecución. </Typography>
                    {/* <Typography mt={2}>Fecha de ejecucion de KPI inicia {new Date(new Date(this.state.startDate)).getDate()}, la frecuencia {period} indica que debes tener tus datos disponibles para tu KPI desde el dia {new Date(new Date(this.state.startDate)).getDate() - numberPeriod} para el calculo de comision </Typography> */}
                  </Box> : ""}
               
                
                 {/* <FormControl className={'selector'}>
                  <InputLabel>Periodo...</InputLabel>
                  <Select
                    value={periodTimes}
                    onChange={event =>
                      this.setState({
                        ...this.state,
                        periodTimes: event.target.value,
                      })
                    }
                  >
                    {periodItems}
                    );
                  </Select>
                </FormControl>  */}
              </div>
            </Grid>
            <Grid item xs={2} >
              <Grid  ></Grid>
            </Grid>
            <Grid item xs={4} >
              <h4 id="transition-modal-description" style={{ paddingLeft: "20px" }}>Paso 2 Selecciona la fecha en que deseas que se ejecute de KPI:</h4>
              <Calendar
                date={this.state.startDate}
                // minDate={new Date(new Date().setDate(new Date().getDate() - 50))}
                minDate={new Date(new Date().setDate(new Date().getDate()))}
                onChange={date => this.handleSelect(date)}
                months={1}
                direction="horizontal"
                locale={locales.es}
              />
            </Grid>
          </Grid>
          <Grid className={'btn-wrapper'} container spacing={5}>
            <Grid item xs={12}>
              <Button
                className={'btn accept-btn'}
                variant="contained"
                onClick={() => this.setValue()}
                disabled={!periodTimes ? true : false}
              >
                Aceptar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={'btn cancel-btn'}
                onClick={() => modalasSelectorShow(false)}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  screen: getModalSelectorFatherScreen(state),
});

const mapDispatchToProps = dispatch => ({
  modalasSelectorShow: payload => dispatch(setModalselectorShow(payload)),
  setKPItime: payload => dispatch(setKPItime(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectorTime);
