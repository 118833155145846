import { takeLatest, all, call, put } from 'redux-saga/effects';
import { FETCH_VARIABLES } from './constants';
import { setVariables, setVariablesError } from './actions';
import API from '../../services/api';

export function* fetchVariables(action) {
  const { payload } = action;

  try {
    const response = yield call(API.getVariables, payload);
    const {
      data: { variable },
    } = response;

    yield put(setVariables(variable));
  } catch (error) {
    yield put(setVariablesError(true));
  }
}

export default function* variablesSaga() {
  yield all([takeLatest(FETCH_VARIABLES, fetchVariables)]);
}
