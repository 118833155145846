import React, { useState, useEffect, useRef } from 'react';
import { Box, LinearProgress, Container, Grid, Tooltip, Button, FormControl, Select, MenuItem, ListItemText, InputLabel } from "@material-ui/core";

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PhoneIcon from '@material-ui/icons/Phone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';

import MaterialTable from 'material-table';
import { tableIcons, localization } from '../../components/materialTable/MaterialTable';
import { api, apiPartial } from '../../api/api';
import { listCustomerTypes } from '../../graphql/queries';
import { listCustomersAudit } from '../../graphql/bamaQueries';
import { updateCustomer, updateAudit, sendEmail, sendSms } from '../../graphql/mutations';
import { getAuditFull } from '../../graphql/bamaQueries';
import { SNACKBAR_ERROR, SNACKBAR_INFO } from '../../utils/SessionControl';
import DashboardCustomer from '../../components/customers/DashboardCustomer';
import CardProfile from '../../components/customers/CardProfile';
import MassiveUpdateModal from '../../components/customers/audit/MassiveUpdateModal';
import AuditDetailModal from '../../components/customers/audit/AuditDetailModal';
import AuditResume from '../../components/customers/audit/AuditResume';

import { withSnackbar } from 'notistack';
import { PhoneType } from '../../schema/enums/schemaEnums';
import { store } from '../../App';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import esLocale from "date-fns/locale/es";

function CustomerIndex(props) {
    const firstRender = useRef(true)
    const [searching, setSearching] = useState(false)
    const [rows, setRows] = useState([])
    const [customers, setCustomers] = useState([])
    const [customerTypes, setCustomerTypes] = useState([])
    const [inAudit, setInAudit] = useState(false)
    const [openMassiveUpdate, setOpenMassiveUpdate] = useState(false)
    const [openAuditDetail, setOpenAuditDetail] = useState(false)
    const [selectedCustomers, setSelectedCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [audit, setAudit] = useState(null)
    const [customerTypeSelected, setCustomerTypeSelected] = useState(null)
    const idUser = store.getState().UserProfileReducer.user.id
    const [customerTypeFilter, setCustomerTypeFilter] = useState("")
    const [dateFilter, setDateFilter] = useState(null)
    const [filteredCustomers, setFilteredCustomers] = useState([])
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render
            loadCustomers();
            loadCustomerTypes();
            return // skip the code below
        }

        return function cleanup() {

        };
    }, [rows]) // Only re-run the effect if name or email change

    const loadCustomers = () => {
        setSearching(true)

        var _customerFilter = {
            limit: 300,
            filter: {
                preregistrationCode: {
                    attributeType: "string"
                }
            }
        }

        callApi(listCustomersAudit, _customerFilter, [])
            .then(res => {
                setSearching(false)
                props.showMessage(`${res.length} ${props.translate("Rows Loaded")}`, SNACKBAR_INFO, 5000, null, null, null, null, null, null, props);
            }).catch(err => {
                setSearching(false)

                console.log(err);

                err && err.errors && err.errors.forEach(_error => {
                    props.showMessage(_error.message, SNACKBAR_ERROR, 5000, null, null, null, null, null, null, props);
                })
            })
    }

    const loadCustomerTypes = () => {
        var _customerFilter = {
            limit: 300
        }

        api(listCustomerTypes, _customerFilter)
            .then(res => {
                setCustomerTypes(res)
            }).catch(err => {
                console.log(err)
            })
    }

    const handleUpdateCustomer = customer => new Promise((resolve, reject) => {
        api(updateCustomer, { input: customer })
            .then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
    })

    const handleUpdateAudit = audit => new Promise((resolve, reject) => {
        api(updateAudit, { input: audit })
            .then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
    })

    const callApi = (query, params, response) => new Promise((resolve, reject) => {
        apiPartial(query, params)
            .then(res => {
                // console.log(res)
                res.items.map((it, id) => {
                    let telefonos = '';
                    let zonas = '';
                    
                    if(it.phones != undefined && it.phones[0] != null){
                        res.items[id].phones.forEach((el, id) => {
                            id == 0 ? (telefonos = el != null ? el.phone : '') : (telefonos = el != null ? telefonos + ', ' + el.phone : telefonos);
                        });
                    }
                    if(it.zone != undefined && it.zone != null){
                        zonas += zonas != '' ? zonas + ', ' + it.zone.zoneName : it.zone.zoneName;
                    }
                    res.items[id].tel = telefonos;
                    res.items[id].zn = zonas;
                    res.items[id].actualizado = new Date(res.items[id]._lastChangedAt).toLocaleString();
                    // console.log(new Date(res.items[id]._lastChangedAt).toLocaleString());

                });
                if (Array.isArray(response)){
                    response = response.concat(res.items)
                } else {
                    response = res.items
                }
                // setRows(response)
                // setCustomers(response.filter(c => Boolean(c.customerType)))
                setCustomers(response)

                if (res.nextToken){
                    resolve(callApi(query, {
                        ...params,
                        nextToken: res.nextToken
                    }, response))
                } else {
                    resolve(response)
                }
            }).catch(err => {
                reject(err)
            })
    })

    const changeSubSet = (customers, customerTypeSelected) => {
        setRows(customers)
        setInAudit(false)
        setCustomerTypeSelected(customerTypeSelected)
        setSelectedDate(null)
        setCustomerTypeFilter("")
    }

    const handleInAudit = (customers, customerTypeSelected) => {
        changeSubSet(customers, customerTypeSelected)
        setInAudit(true)
    }

    const handleSelectionChange = rows => {
        props.showMessage(`${rows.length} ${props.translate("Selected Rows")}`, SNACKBAR_INFO, 5000, null, null, null, null, null, null, props);
        setSelectedCustomers(rows)
    }

    const handleEditRows = event => {
        setOpenMassiveUpdate(true)
    }

    const handleCloseMassiveUpdate = () => {
        setOpenMassiveUpdate(false)
    }

    const handleViewAuditDetail = (event, customer, auditId) => {
        setSearching(true)
        setSelectedCustomer(customer)
        setOpenAuditDetail(true)

        var _customerFilter = {
            id: auditId
        }

        api(getAuditFull, _customerFilter)
            .then(res => {
                setAudit(res)
                setSearching(false)
            }).catch(err => {
                console.log(err)
                setSearching(false)
            })
    }

    const handleCloseAuditDetail = () => {
        setOpenAuditDetail(false)
    }

    const sendNotifications = (_customer, sms, email) => new Promise((resolve, reject) => {
        var eCt = 0, sCt = 0;
        _customer.emails && _customer.emails.forEach(e => {
            // Send email
            api(sendEmail, { email: JSON.stringify({ ...email, recipient: e }) })
                .then(allTodos => {
                    console.log(allTodos.sendEmail)
                    eCt++
                })
        })

        _customer.phones && _customer.phones.forEach(p => {
            if (p.phoneType === PhoneType.MOBILE) {
                api(sendSms, { sms: JSON.stringify({ ...sms, phone: p.phone }) })
                    .then(allTodos => {
                        console.log(allTodos)
                        sCt++
                    })
            }
        })

        resolve({ emails: eCt, sms: sCt })
    })

    const handleChangeSelectCustomerType = async (event) => {
        filterCustomers(event.target.value)
    }

    const filterCustomers = async (_customerTypeFilter) => {
        setRows(rows.filter(c => c.customerType && c.customerType.id === _customerTypeFilter))
        setCustomerTypeFilter(_customerTypeFilter)
    }

    const handleDateChange = (date) => {
        console.log(date.toLocaleDateString())
        setRows(rows.filter(c => new Date(c._lastChangedAt).toLocaleDateString() === date.toLocaleDateString()))
        setSelectedDate(date);
    }

    return <Box>
        {searching && <LinearProgress color="secondary" />}
        <Container fixed>
            <DashboardCustomer
                {...props}
                customers={customers}
                customerTypes={customerTypes}
                rowsLength={rows.length}
                changeSubSet={changeSubSet}
                handleInAudit={handleInAudit}
                translate={props.translate}
                showMessage={props.showMessage}
                handleEditRows={handleEditRows}
                inAudit={inAudit}
                selectedCustomers={selectedCustomers}
            />

            {rows && rows.length > 0 ?
                <Box my={4}>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={5}>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" style={{ width: "100%" }}>
                                <InputLabel id="customerTypeFilter">
                                    {props.translate("Customer Type")}
                                </InputLabel>
                                <Select
                                    labelId="customerTypeFilter"
                                    value={customerTypeFilter}
                                    onChange={handleChangeSelectCustomerType}
                                >
                                    {customerTypes.map((c, idx) => (
                                        <MenuItem key={c.id} value={c.id}>
                                            <ListItemText primary={c.customerTypeName} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={esLocale}>
                                <KeyboardDatePicker
                                    value={selectedDate}
                                    disableFuture
                                    inputVariant="outlined"
                                    onChange={handleDateChange}
                                    autoOk
                                    format="dd MMM yyyy"
                                    InputAdornmentProps={{ position: "start" }}
                                    minDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365)}
                                    orientation="landscape"
                                    variant="inline" />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Box>
                : null}
            {rows && rows.length > 0 ?
                <MaterialTable
                    icons={tableIcons}
                    localization={localization(props)}
                    options={{
                        exportButton: true,
                        selection: true,
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.audits.items.length > 0 && rowData.audits.items.reduce((accumulator, currentValue) => accumulator || currentValue.blacklist).blacklist) ? 'grey' : ''
                        }),
                        pageSizeOptions: [5, 10, 20, rows.length],
                        exportAllData: true
                    }}
                    onSelectionChange={(rows) => handleSelectionChange(rows)}
                    columns={[
                        {
                            title: props.translate("Customer Name"),
                            field: "customerName"
                        },
                        {
                            title: props.translate("Customer Type"),
                            field: "customerType.customerTypeName"
                        },
                        {
                            title: props.translate("Address"),
                            field: "address"
                        },
                        {
                            title: props.translate("Preregistration Code"),
                            field: "preregistrationCode"
                        },
                        {
                            title: props.translate("SellBanned"),
                            field: "sellBanned",
                            render: rowData => {
                                return <Tooltip title={props.translate(rowData.sellBanned === null || rowData.sellBanned ? "Sell Banned" : "SellBanned")}>
                                    <Box>
                                        <FiberManualRecordIcon color={rowData.sellBanned === null || rowData.sellBanned ? "error" : "primary"} />
                                        {(rowData.audits.items.length > 0 && rowData.audits.items.reduce((accumulator, currentValue) => accumulator || currentValue.blacklist).blacklist) ? props.translate("Blacklist") : ''}
                                    </Box>
                                </Tooltip>
                            }
                        },
                        {
                            title: props.translate("Taxpayer Info"),
                            field: "taxpayer",
                            render: rowData => {
                                return rowData.taxpayer ? `${rowData.taxpayer.identification} ${rowData.taxpayer.taxpayerName}` : ''
                            }
                        },
                        {
                            title: props.translate("Zone"),
                            field: "zn"
                        },
                        {
                            title: props.translate("Last Update"),
                            field: 'actualizado'
                            // field: "_lastChangedAt",
                            // render: rowData => {
                            //     return rowData._lastChangedAt ? new Date(rowData._lastChangedAt).toLocaleString() : ''
                            // }
                        },
                        {
                            title: '',
                            field: "tel",
                            render: rowData => {
                                return ''
                            }
                        },
                        {
                            title: '',
                            field: "emails",
                            render: rowData => {
                                return ''
                            }
                        }
                    ]}
                    data={rows}
                    title={`${props.translate("Customers")} ${customerTypeSelected}`}
                    detailPanel={[
                        {
                            tooltip: props.translate("More Details"),
                            render: rowData => {
                                return (
                                    <Box m={3}>
                                        <CardProfile
                                            {...props}
                                            customer={rowData}
                                        />
                                    </Box>
                                )
                            },
                        },
                        rowData => ({
                            icon: 'fingerprint',
                            tooltip: props.translate("Show Fingerprints"),
                            disabled: !rowData.fingers || (rowData.fingers && rowData.fingers.length === 0),
                            render: rowData => {
                                return (
                                    <Box
                                        m={4}>
                                        <Grid container
                                            spacing={3}
                                            justify="flex-start"
                                            alignItems="center">
                                            {rowData.fingers && rowData.fingers.map((e, i) => {
                                                return (
                                                    <Grid
                                                        key={`${rowData.id}-phone-${i}`}
                                                        container
                                                        spacing={1}
                                                        alignItems="center">
                                                        <Grid item>
                                                            <FingerprintIcon color="action" fontSize="small" />
                                                        </Grid>
                                                        <Grid item>
                                                            {props.translate(e.fingerType)}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Box>
                                )
                            },
                        }),
                        rowData => ({
                            icon: 'phone',
                            tooltip: props.translate("Show Phones"),
                            disabled: !rowData.phones || (rowData.phones && rowData.phones.length === 0),
                            render: rowData => {
                                return (
                                    <Box
                                        m={4}>
                                        <Grid container
                                            spacing={3}
                                            justify="flex-start"
                                            alignItems="center">
                                            {rowData.phones && rowData.phones.map((e, i) => {
                                                return (
                                                    <Grid
                                                        key={`${rowData.id}-phone-${i}`}
                                                        container
                                                        spacing={1}
                                                        alignItems="center">
                                                        <Grid item>
                                                            <PhoneIcon color="action" fontSize="small" />
                                                        </Grid>
                                                        <Grid item>
                                                            {props.translate(e.phoneType)}
                                                        </Grid>
                                                        <Grid item>
                                                            {e.phone}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Box>
                                )
                            },
                        }),
                        rowData => ({
                            icon: 'email',
                            tooltip: props.translate("Show Emails"),
                            disabled: !rowData.emails || (rowData.emails && rowData.emails.length === 0),
                            render: rowData => {
                                return (
                                    <Box
                                        m={4}>
                                        <Grid container
                                            spacing={3}
                                            justify="flex-start"
                                            alignItems="center">
                                            {rowData.emails && rowData.emails.map((e, i) => {
                                                return (
                                                    <Grid
                                                        key={`${rowData.id}-email-${i}`}
                                                        container
                                                        spacing={1}
                                                        alignItems="center">
                                                        <Grid item>
                                                            <EmailIcon color="action" fontSize="small" />
                                                        </Grid>
                                                        <Grid item>
                                                            {e}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Box>
                                )
                            },
                        }),
                        rowData => ({
                            icon: 'verified_user',
                            tooltip: props.translate("Show Audit"),
                            disabled: rowData.audits.items.length <= 0,
                            render: rowData => {
                                return (
                                    <Box
                                        m={4}>
                                        {rowData.audits && rowData.audits.items.length > 0 && rowData.audits.items.map(audit => <Grid container
                                            key={`c-${rowData.id}a-${audit.id}`}
                                            justify="flex-end"
                                            alignItems="center">
                                            <Grid item xs={12}>
                                                <AuditResume
                                                    translate={props.translate}
                                                    showMessage={props.showMessage}
                                                    customer={rowData}
                                                    audit={audit}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<VisibilityIcon />}
                                                    onClick={event => handleViewAuditDetail(event, rowData, audit.id)}>
                                                    {props.translate("Review Audit Details")}
                                                </Button>
                                            </Grid>
                                        </Grid>)}
                                    </Box>
                                )
                            },
                        }),
                        rowData => ({
                            icon: ' ',
                            tooltip: ' ',
                            disabled: true,
                            render: rowData => {
                                return (<Box></Box>)
                            }
                        })
                    ]}
                />
                : ''}

            <MassiveUpdateModal
                translate={props.translate}
                showMessage={props.showMessage}
                title={props.translate("Massive Customer Update")}
                open={openMassiveUpdate}
                customers={selectedCustomers}
                customerTypes={customerTypes}
                idUser={idUser}
                handleUpdateCustomer={handleUpdateCustomer}
                handleUpdateAudit={handleUpdateAudit}
                sendNotifications={sendNotifications}
                handleClose={handleCloseMassiveUpdate} />
            <AuditDetailModal
                searching={searching}
                title={props.translate("Audit Detail")}
                audit={audit}
                open={openAuditDetail}
                customer={selectedCustomer}
                customerTypes={customerTypes}
                translate={props.translate}
                showMessage={props.showMessage}
                idUser={idUser}
                handleUpdateCustomer={handleUpdateCustomer}
                handleUpdateAudit={handleUpdateAudit}
                sendNotifications={sendNotifications}
                handleClose={handleCloseAuditDetail} />
        </Container>
    </Box>
}

export default withSnackbar(CustomerIndex);