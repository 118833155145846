import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import {
  addInicatorElement,
  editIndicatorElement,
} from '../../../../../store/indicator/actions';
import {
  addKPIElement,
  editKPIElement,
} from '../../../../../store/kpi/actions';
import {
  addCreateFunctionlelement,
  editCreateFunctionElement,
} from '../../../../../store/createFunctions/actions';

import './styles.scss';

export class AddOperator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      value: '',
    };
  }

  saveElement() {
    const {
      type,
      step,
      onClose,
      addIndicatorElement,
      addKPIElement,
      savedCallback,
      addCreateFunctionlelement,
      editData,
      editIndicatorElement,
      editKPIElement,
      editCreateFunctionElement,
    } = this.props;

    const payload = {
      step: step,
      type: 'operator',
      object: this.state,
    };

    if (editData) {
      switch (type) {
        case 'indicator':
          editIndicatorElement({ payload, editData });
          break;

        case 'KPI':
          editKPIElement({ payload, editData });
          break;

        case 'createFunction':
          editCreateFunctionElement({ payload, editData });
          break;

        default:
          break;
      }
    } else {
      switch (type) {
        case 'indicator':
          addIndicatorElement(payload);
          break;

        case 'KPI':
          addKPIElement(payload);
          break;

        case 'createFunction':
          addCreateFunctionlelement(payload);
          break;

        default:
          break;
      }
    }

    if (typeof savedCallback === 'undefined') {
      onClose();
    } else {
      savedCallback();
    }

    this.setState({
      ...this.state,
      selected: '',
      value: '',
    });
  }

  render() {
    const { open, onClose } = this.props;
    const { selected, value } = this.state;

    return (
      <Dialog fullScreen open={open} onClose={onClose} closeAfterTransition>
        <Fade in={open}>
          <div className={'dialog-content'}>
            <h2 id="transition-modal-title">Selecciona un operador</h2>
            <Grid
              id="transition-modal-description"
              justify="center"
              container
              spacing={3}
            >
              <Grid item xs>
                <Button
                  className={'operator-btn'}
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      selected: 'addition',
                      value: '+',
                    })
                  }
                  disabled={selected === 'addition'}
                >
                  +
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  className={'operator-btn'}
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      selected: 'subtraction',
                      value: '-',
                    })
                  }
                  disabled={selected === 'subtraction'}
                >
                  -
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  className={'operator-btn'}
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      selected: 'multiplication',
                      value: '*',
                    })
                  }
                  disabled={selected === 'multiplication'}
                >
                  x
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  className={'operator-btn'}
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      selected: 'division',
                      value: '/',
                    })
                  }
                  disabled={selected === 'division'}
                >
                  /
                </Button>
              </Grid>
              {/* <Grid item xs={2}>
                <Button
                  className={'operator-btn'}
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      selected: 'percentage',
                      value: '%',
                    })
                  }
                  disabled={selected === 'percentage'}
                >
                  %
                </Button>
              </Grid> */}
            </Grid>
            <Grid className={'btn-wrapper'} container spacing={3}>
              <Grid item xs={12}>
                <Button
                  className={'btn accept-btn'}
                  variant="contained"
                  onClick={() => this.saveElement()}
                  disabled={value ? false : true}
                >
                  Aceptar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button className={'btn cancel-btn'} onClick={onClose}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addIndicatorElement: payload => dispatch(addInicatorElement(payload)),
  addKPIElement: payload => dispatch(addKPIElement(payload)),
  addCreateFunctionlelement: payload =>
    dispatch(addCreateFunctionlelement(payload)),
  editIndicatorElement: payload => dispatch(editIndicatorElement(payload)),
  editKPIElement: payload => dispatch(editKPIElement(payload)),
  editCreateFunctionElement: payload =>
    dispatch(editCreateFunctionElement(payload)),
});

export default connect(null, mapDispatchToProps)(AddOperator);
