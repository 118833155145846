import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';

export default function SystemScope(props) {
    return (
        <Box p={2}>
            <Typography variant="h6" color="textSecondary">
                {props.translate("System Scopes")}
            </Typography>
        </Box>
    );
}