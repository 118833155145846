import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid, Typography, CssBaseline, Zoom, useScrollTrigger, Fab, Stepper, Step, StepLabel, Button, StepContent, Paper, makeStyles, LinearProgress, Link } from '@material-ui/core';

// import HomeIcon from '@material-ui/icons/Home';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import FileCopy from '@material-ui/icons/FileCopy';
import PrintIcon from '@material-ui/icons/Print';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Alert, AlertTitle } from '@material-ui/lab';

import Barcode from 'react-barcode';

// Amplify 
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import bamaLogo from '../../assets/images/watermark_color.png'
//import PersonalInformation from './PersonalInformation';

import { withSnackbar } from 'notistack';
// import DocumentsUpload from '../../components/customers/registration/DocumentsUpload';
// import StoreSelection from '../../components/customers/registration/StoreSelection';
import RegistryConfirmation from '../../components/customers/registration/RegistryConfirmation';
//import RegistryConfirmation from './RegistryConfirmation'
import Footer from '../landing/Footer';

import { haveCorrectLength } from '../../utils/LengthValidations';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_INFO } from '../../utils/SessionControl';
import { FileType, PhoneType } from '../../schema/enums/schemaEnums';

import { createCustomerLite } from '../../graphql/bamaQueries';
import { sendEmail, sendSms, createWarehouseCustomer, athenaOperation } from '../../graphql/mutations';
import PersonalInformation from '../../components/customers/registration/PersonalInformation';
import { api } from '../../api/api';
//import RegistryConfirmation from '../../components/customers/registration/RegistryConfirmation';

// Amplify init
Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
    backgroundGradient: {
        /*background: 'linear-gradient(45deg, #ffc10a55 30%, #17529C55 90%);',*/
        paddingTop: '1em',
        minHeight: '100vh'
    },
    rootFab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const ScrollTop = (props) => {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (<Zoom in={trigger}><div onClick={handleClick} role="presentation" className={classes.rootFab}>{children}</div></Zoom>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const CustomerRegistration = (props) => {
    const firstRender = useRef(true)
    const barcodeRef = useRef(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [stores, setStores] = useState(null);
    const [sepomex, setSepomex] = useState([])
    const defaultError = {
        /*customerName: '',
        phones: '',
        emails: '',
        address: '',
        taxpayer: ''*/
    };

    const [customer, setCustomer] = useState({
        customerName: '',
        customerSecondName: '',
        customerLastName: '',
        customerBirthday: '',
        phones: [],
        emails: [],
        legals: []
    })
    const [error, setError] = useState(defaultError)
    const [sending, setSending] = useState(false)
    const [validForm, setValidForm] = useState(false)
    // const [state, setState] = useState({expanded: props.expanded})


    const formValidation = () => {
        let isError = false, _error = defaultError
        if (customer) {
            if (activeStep === 0) {
                if (customer.customerName.trim() === "") {
                    _error = { ..._error, customerName: props.translate('Enter Customer Name') }
                    isError = true
                }
                if (customer.customerSecondName.trim() === "") {
                    _error = { ..._error, customerSecondName: props.translate('Enter Customer Second Name') }
                    isError = true
                }
                // if (customer.customerBirthday.trim() === "") {
                //     _error = { ..._error, customerBirthday: props.translate('Enter Customer Birthday') }
                //     isError = true
                // }
                /*if (customer.customerLastName.trim() === "") {
                    _error = { ..._error, customerLastName: props.translate('Enter Customer Last Name') }
                    isError = true
                }*/

                if (customer.emails && customer.emails.length === 0) {
                    // _error = { ..._error, emails: props.translate("Enter at least one email") }
                    // isError = true
                } else {
                    customer.emails.forEach(e => {
                        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.trim())) {
                            _error = { ..._error, emails: props.translate("Email incorrect Format") }
                            isError = true
                        }
                    })
                }

                if (customer.phones && customer.phones.length === 0) {
                    _error = { ..._error, phones: props.translate("Enter at least one phone") }
                    isError = true
                } else {
                    customer.phones.forEach(e => {
                        // console.log(e)
                        if ((e && e.phone && !haveCorrectLength(e.phone, 10)) || e.phone.length === 0) {
                            _error = { ..._error, phones: props.translate("The telephone number don't have a correct length") }
                            isError = true
                        }
                        // if (e && (!e.phoneType || !e.phone)) {
                        //     _error = { ..._error, phones: props.translate("The telephone number don't have a Phone Type") }
                        //     isError = true
                        // }
                    })
                }

                if (customer.taxpayer) {
                    if (!customer.taxpayer.taxpayerName || !customer.taxpayer.identification) {
                        _error = { ..._error, taxpayer: props.translate("Enter Taxpayer") }
                        isError = true
                    }
                }


                // if (!customer.address) {
                //     _error = { ..._error, address: props.translate("Enter Address") }
                //     isError = true
                // } else {
                //     if (!customer.address.route_street_number
                //         || !customer.address.sublocality
                //         || !customer.address.locality
                //         || !customer.address.country
                //         // || !customer.address.political
                //         || !customer.address.postal_code/*
                //         || !customer.address.references*/) {
                //         _error = { ..._error, address: props.translate("Enter Address") }
                //         isError = true
                //     }
                // }
            }

            // if (activeStep === 1) {
            //     if (!customer.inePhotoUrl || !customer.profilePhotoUrl) {
            //     if (!customer.inePhotoUrl) {
            //         _error = {
            //             ..._error,
            //             documents: props.translate("Upload your Documents")
            //         }
            //         isError = true
            //     }
            //     isError = false
            // }

            // if (activeStep === 1) {
            //     if (!customer.store) {
            //         _error = { ..._error, documents: props.translate("Select your Store") }
            //         isError = true
            //     }

            // }

            // if (activeStep === 2) {
            if (activeStep === 1) {
                if (customer.legals.length !== 2) {
                    _error = { ..._error, legals: props.translate("Acept Legals") }
                    isError = true
                }
            }

            setError(_error)
            // console.log(isError, _error)
            return isError
        }

        setError(defaultError)
        // console.log(isError, _error)
        return isError
    };

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render

            loadInfoSepomexVeracuz()


            return // skip the code below
        }

        if (barcodeRef.current) {
            Storage.configure({ AWSS3: { bucket: 'bama-barcodes', region: awsconfig.aws_user_files_s3_bucket_region, correctClockSkew: true } });
            uploadBase64(`${customer.preregistrationCode}.jpg`, barcodeRef.current.refs.renderElement.src)
        }

        setValidForm(!formValidation())
    }, [customer, activeStep, skipped, sending, barcodeRef, sepomex]) // Only re-run the effect if name or email change

    const classes = useStyles();

    const loadInfoSepomexVeracuz = () => {
        let params = {
            database: "forecast",
            table: "sepomex", // **hist_daily_sales** historico de ventas por dia
            queryString: `SELECT * FROM "forecast"."sepomex"`,
        };
        api(athenaOperation, { params: JSON.stringify(params) }).then(
            (allTodos) => {
                let dataSepomex = JSON.parse(allTodos);
                setSepomex(dataSepomex.Items)
                // console.log("RESULTADO ATHENA:",dataSepomex.Items)
            })
    };

    const submitCustomerRegistration = event => {
        let _customer = customer

        _customer.preregistrationCode = generateTokenRegistry(String(new Date().getTime()), 15)

        setCustomer(_customer)
        _customer.phones.forEach(p => p.phone = `+52${p.phone.replace(" ", "")}`)

        setSending(true)

        Storage.put(`${_customer.preregistrationCode}.json`, JSON.stringify(_customer), {
            progressCallback(progress) {
                // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                // console.table('_customer: ', _customer)
            }
        })
            .then(result => {
                // console.log('Result-> ',result)

                let _newCustomer = {
                    customerName: `${_customer.customerName} ${_customer.customerSecondName} ${_customer.customerLastName}`,
                    phones: _customer.phones,
                    birthdate: _customer.customerBirthday ? _customer.customerBirthday : null,
                    emails: _customer.emails ? _customer.emails : "",
                    sellBanned: false,
                    //address: `${_customer.address.route_street_number}, ${_customer.address.sublocality}, ${_customer.address.locality}, ${_customer.address.political}, ${_customer.address.country}, ${_customer.address.postal_code}`,
                    //location: _customer.address.location,
                    // location: _customer._address && _customer._address.location ? _customer._address.location : null,
                    taxpayer: _customer.taxpayer,
                    // sellBanned: true,
                    legals: _customer.legals,
                    files: [
                        { fileName: _customer.inePhotoUrl, fileType: FileType.IDENTIFICATION },
                        // { fileName: _customer.profilePhotoUrl, fileType: FileType.PICTURE_PROFILE }
                    ],
                    preregistrationCode: _customer.preregistrationCode,
                    customerCustomerTypeId: "c32b3f5e-5de3-4954-96e2-a87a29d89fcd"
                }

                API.graphql(graphqlOperation(createCustomerLite, { input: _newCustomer }))
                    .then(allTodos => {
                        console.log(allTodos);
                        let res = allTodos.data.createCustomer;

                        // console.log("createCustomerLite", res)

                        _customer.emails.forEach(e => {
                            // Send email
                            let email = {
                                sender: "clubbama@bamaglobal.com.mx",
                                recipient: e,
                                subject: props.translate("Registry Success"),
                                title: props.translate("Registry Success"),
                                content: `<div style="width: 100%">
                                            <div style="text-align: right;">${new Date(res._lastChangedAt).toLocaleString()}</div>
                                            <div style="margin: 0 auto; text-align: center;"><h2>${res.customerName}</h2></div>
                                            <div style="width: 100%">
                                                ${props.translate("createCustomer_emailLegendStore")}
                                                <b>${_customer.store.warehouseName}</b>
                                                ${props.translate("createCustomer_emailLegendStoreAddress")}
                                                <a href="https://www.google.com/maps/search/?api=1&query=${_customer.store.location.coordinates[0]},${_customer.store.location.coordinates[1]}"><b>${_customer.store.address ? _customer.store.address : "Ver ubicación"}</b></a>
                                                ${props.translate("createCustomer_emailLegend")}
                                            </div>
                                            <div style="width: 100%">
                                                <div style="margin: 0 auto; text-align: center;"><h1>${(_customer.preregistrationCode).toLowerCase()}</h1></div>
                                                <div style="margin: 0 auto; text-align: center;">
                                                    <img src="https://bama-barcodes.s3.amazonaws.com/public/${(_customer.preregistrationCode)}.jpg" style="max-width: 100%;"/>
                                                </div>
                                            </div>
                                        </div>`
                            }

                            API.graphql(graphqlOperation(sendEmail, { email: JSON.stringify(email) }))
                                .then(allTodos => {
                                    // console.log('SendMail: ', allTodos.sendEmail)
                                    showMessage(`${props.translate("createCustomer_emailLegendSended")} ${e}`, SNACKBAR_INFO, 5000);
                                })
                        })

                        _customer.phones.forEach(p => {
                            if (p.phoneType === PhoneType.MOBILE) {
                                let sms = {
                                    message: `${props.translate("createCustomer_smsLegend")} ${(_customer.preregistrationCode).toLowerCase()}`,
                                    phone: p.phone
                                }

                                API.graphql(graphqlOperation(sendSms, { sms: JSON.stringify(sms) }))
                                    .then(allTodos => {
                                        // console.log('SendSMS: ', allTodos)
                                        showMessage(`${props.translate("createCustomer_smsLegendSended")} ${p.phone}`, SNACKBAR_INFO, 5000);
                                    })
                            }
                        })

                        setSending(false)

                        showMessage(props.translate("Registry successfully"), SNACKBAR_SUCCESS, 5000);

                        // stores.forEach(warehouse => {
                        //     // Add Customer to Stores
                        //     let paramsUpdCustomer = {
                        //         customerId: res.id,
                        //         warehouseId: warehouse.id,
                        //         marketReleaseDate: parseInt(new Date().getTime() / 1000)
                        //     }
                        //     api(createWarehouseCustomer, { input: paramsUpdCustomer })
                        //         .then((res) => {
                        //             // console.log('createWarehouseCustomer',res)
                        //         })
                        //         .catch((err) => {
                        //             console.log(err)
                        //         });
                        // })

                    })
                    .catch(_err => {
                        let err = _err;
                        setSending(false)

                        console.log(err, err.errors);

                        err && err.errors && err.errors.forEach(_error => {
                            console.log(_error)
                            showMessage(_error.message, SNACKBAR_ERROR, 5000);
                            if (_error.errorType === "ConditionalCheckFailedException")
                                setError({
                                    ...error,
                                    registry: props.translate("createCustomer_ConditionalCheckFailedException")
                                })
                            else setError({
                                ...error,
                                registry: props.translate("Generic Error Create Customer")
                            })
                            // "Variable 'phone' has an invalid value. Unable to parse `+521234567890` as a valid phone number."
                            let validNumber = "has an invalid value. Unable to parse"
                            if (_error.message.includes(validNumber) && _error.message.includes('phone'))
                                setError({
                                    ...error,
                                    registry: `${props.translate("Variable phone has an invalid value Unable to parse")} ${_error.message.split(validNumber)[1].split(" ")[1].replace("`", "").replace("+52", "").replace("`", "")} ${props.translate("as a valid phone number")}`
                                })
                        })
                    });
            })
            .catch(err => {
                console.log(err)
                // props.showMessage(props.translate(err), SNACKBAR_ERROR, 5000);
            });

        event && event.preventDefault();
    };

    const getSteps = () => {
        return [props.translate('Enter your Information'), /* props.translate('Upload your Documents'),  props.translate('Select closest Store'),*/ props.translate('Terms and Conditions')];
    };

    const showMessage = (message, msg_type, msg_duration, btn_msg, msg_vertical, msg_horizontal, msg_content, msg_persist) => {
        return props.enqueueSnackbar ? props.enqueueSnackbar(message, {
            variant: msg_type,
            autoHideDuration: msg_duration ? msg_duration : 3000,
            anchorOrigin: {
                vertical: msg_vertical ? msg_vertical : 'bottom',
                horizontal: msg_horizontal ? msg_horizontal : 'left',
            },
            persist: msg_persist,
            content: (key) => (msg_content)
        }) : null;
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <PersonalInformation error={error} showMessage={showMessage} translate={props.translate} customer={customer} sepomex={sepomex} setCustomer={setCustomer} bamaLogo={bamaLogo} />;
            // case 1:
            //     return <DocumentsUpload uploadBase64={uploadBase64} showMessage={showMessage} translate={props.translate} customer={customer} setCustomer={setCustomer} isRequired={false} />;
            case 1:
                //return <StoreSelection API={API} graphqlOperation={graphqlOperation} showMessage={showMessage} translate={props.translate} customer={customer} stores={stores} setStores={setStores} setCustomer={setCustomer} bamaLogo={bamaLogo} />;
                return <RegistryConfirmation translate={props.translate} customer={customer} setCustomer={setCustomer} />;
            case 2:
            // return <RegistryConfirmation translate={props.translate} customer={customer} setCustomer={setCustomer} />;
            default:
                return 'Unknown step';
        }
    };

    const isStepOptional = (step) => {
        let optional = false;
        switch (step) {
            case 1:
                optional = false;
                break;
            default:
                optional = false;
                break;
        }
        return optional;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        // if (activeStep === 2)
        if (activeStep === 1)
            submitCustomerRegistration()

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const generateTokenRegistry = (uuid, length) => {
        //edit the token allowed characters
        let a = uuid.replace(/-/gi, "").concat("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890").split("");
        let b = [];
        for (let i = 0; i < length; i++) {
            let j = (Math.random() * (a.length - 1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("").toUpperCase();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const uploadBase64 = (fileName, imageBinary, progressCallback) => new Promise((resolve, reject) => {
        // console.log(imageBinary)
        let buf = new Buffer(imageBinary.replace(/^data:image\/\w+;base64,/, ""), 'base64')
        Storage.put(fileName, buf, { contentType: 'image/jpeg' }, progressCallback)
            .then(result => {
                // console.log(result);
                resolve(result)
            })
            .catch(err => {
                console.log(err)
                reject(err)
            });
    });

    const clipboardCopyCode = (evt, con) => {
        navigator.clipboard.writeText(customer.preregistrationCode, con);
        showMessage(`${props.translate('Code')} ${customer.preregistrationCode} ${props.translate('Copied to Clipboard')}`, SNACKBAR_INFO, 5000, undefined, undefined, 'center');
        //props.change(undefined, true);
        //props.handlePreregistrationCode(customer.preregistrationCode);
    };

    const restartRegister = () => {
        document.getElementById('createClientRegister').reset();
        setTimeout(() => setActiveStep(0), 500);
    }

    const steps = getSteps();

    //console.log("RULO",customer)
    return (
        <React.Fragment>
            <CssBaseline />
            <Box className={classes.backgroundGradient}>
                <Container fixed>
                    <form autoComplete="off" onSubmit={submitCustomerRegistration} id="createClientRegister">
                        <Box id="back-to-top-anchor"></Box>
                        <Grid container>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <img width="90px" src={bamaLogo} alt="Bama Cloud" />
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={1} direction="column" >
                                            <Grid item>
                                                <Typography variant="h6">
                                                    {props.translate("Customer Registration")}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {props.translate("Legend to enrollment")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box my={2}>
                            {
                                activeStep !== steps.length ?
                                    <Paper elevation={3}>
                                        <Stepper activeStep={activeStep} orientation="vertical">
                                            {steps.map((label, index) => {
                                                // console.log('pasos de registro: ',label, index)
                                                const stepProps = {};
                                                const labelProps = {};
                                                if (isStepOptional(index)) {
                                                    labelProps.optional = <Typography variant="caption">{props.translate("Optional")}</Typography>;
                                                }
                                                if (isStepSkipped(index)) {
                                                    stepProps.completed = false;
                                                }
                                                return (
                                                    <Step key={label} {...stepProps} >
                                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                                        <StepContent>{getStepContent(index)}</StepContent>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                {
                                                    (error.customerName || error.phones || error.emails || error.address || error.taxpayer || error.documents || error.legals) ?
                                                        <Alert severity={SNACKBAR_ERROR}>
                                                            <AlertTitle>{"Error"}</AlertTitle>
                                                            <Box>{error.customerName}</Box>
                                                            <Box>{error.phones}</Box>
                                                            <Box>{error.emails}</Box>
                                                            <Box>{error.address}</Box>
                                                            <Box>{error.taxpayer}</Box>
                                                            <Box>{error.documents}</Box>
                                                            <Box>{error.legals}</Box>
                                                        </Alert>
                                                        : ''
                                                }
                                            </Grid>
                                        </Grid>
                                    </Paper> : ''
                            }
                        </Box>
                        {activeStep === steps.length ? (
                            <Box>
                                {sending ?
                                    <Box my={5}>
                                        <Grid container alignItems="center" justify="center" spacing={2}>
                                            <Grid item>
                                                <Typography gutterBottom variant="h5" color="textSecondary">
                                                    {props.translate("Sending data, please waiting for response")}
                                                </Typography>
                                                <LinearProgress />
                                                <LinearProgress variant="query" color="secondary" />
                                            </Grid>
                                        </Grid>
                                    </Box> :
                                    <Box style={{ minHeight: '1vh' }}>
                                        {
                                            error && error.registry ?
                                                <Grid container alignItems="center" justify="center" spacing={4}>
                                                    <Grid item>
                                                        <Alert severity={SNACKBAR_ERROR}>
                                                            <AlertTitle>{props.translate("Error")}</AlertTitle>
                                                            {error.registry}
                                                        </Alert>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button startIcon={<SettingsBackupRestoreIcon />} variant='outlined' color='secondary' onClick={restartRegister} >
                                                            {props.translate('Enrol New Customer')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Container fixed>
                                                    <Grid container alignItems="center" justify="center" spacing={2}>
                                                        <Grid item>
                                                            <Typography gutterBottom variant="h5">
                                                                {props.translate("Congratulations")}
                                                                <b>{` ${customer.customerName} ${customer.customerSecondName}`}</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Alert severity={SNACKBAR_SUCCESS}>
                                                                <AlertTitle>{props.translate("Registry Success")}</AlertTitle>
                                                                {props.translate("Token registration legend")}
                                                            </Alert>
                                                        </Grid>
                                                        <Grid item>
                                                            <Barcode ref={barcodeRef} renderer="img" value={customer.preregistrationCode} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems="center" justify="center" spacing={4}>
                                                        <Grid item>
                                                            <Button startIcon={<FileCopy />} justify='center' spacing={14} variant='contained' color='primary' onClick={clipboardCopyCode} >
                                                                {props.translate('Copy to Clipboard')}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button startIcon={<PrintIcon />} variant="contained" color="primary" onClick={event => window.print()} className={classes.button} >
                                                                {props.translate("Print")}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button startIcon={<SettingsBackupRestoreIcon />} variant='outlined' color='secondary' onClick={restartRegister} >
                                                                {props.translate('Enrol New Customer')}
                                                            </Button>
                                                            {/* <Link href="/">
                                                                <HomeIcon />
                                                            </Link> */}
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                        }
                                    </Box>
                                }
                            </Box>
                        ) : (
                            <Box>
                                {
                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        {props.translate("Back")}
                                    </Button>
                                }
                                {isStepOptional(activeStep) && (
                                    <Button variant="contained" color="primary" onClick={handleSkip} className={classes.button} >
                                        {props.translate("Skip")}
                                    </Button>
                                )}

                                <Button disabled={!validForm} variant="contained" color="primary" onClick={handleNext} className={classes.button} >
                                    {activeStep === steps.length - 1 ? props.translate("Finish") : props.translate("Next")}
                                </Button>
                            </Box>
                        )}
                    </form>
                    <Footer />
                </Container>
            </Box>
            <ScrollTop {...props}>
                <Fab color="secondary" size="small" aria-label={props.translate("Back to top")}>
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </React.Fragment>
    )
}

export default withSnackbar(CustomerRegistration);