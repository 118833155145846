import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getAreaIndicatorsState = state => get(state, 'areaIndicators');

export const isLoadingAreaIndicators = createSelector(
  [getAreaIndicatorsState],
  areaIndicators => get(areaIndicators, 'loading'),
);

export const getAreaIndicators = createSelector(
  [getAreaIndicatorsState],
  areaIndicators => get(areaIndicators, 'data'),
);

export const isErrorGetAreaIndicators = createSelector(
  [getAreaIndicatorsState],
  areaIndicators => get(areaIndicators, 'error'),
);
