import React , { useState, useEffect} from 'react';
import { connect ,useSelector } from 'react-redux';

import { fetchOrganizations, setOrganizations } from '../../../store/organizations/actions';
import { fetchDatasources , setDatasources} from '../../../store/datasources/actions';

import {
    isOrganizationsloading,
    getOrganizationsdata,
} from '../../../store/organizations/selectors';
import {
    isDatasourcesloading,
    getDatasourcesdata,
} from '../../../store/datasources/selectors';
import {
    setVariableOrganization,
    setVariableDataSource,
    resetVariable
} from '../../../store/variablestag/actions';

import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import { Select } from '../../../components/Modal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import Box from '@material-ui/core/Box';

export const InitSearchVariable = ({
    fetchOrganizations,
    fetchDatasources,
    organizations,
    datasource,
    setVariableOrganization,
    resetVariable,
    setDatasources,
    setVariableDataSource,
    }) => {

    const [open , setOpen] = useState(true);
    const [Organizations , setOrganizations] = useState(null);
    const [valueDataSource , setValueDataSource] = useState('');
    const [readyDataSource , setReadyDataSource] = useState(false);
    const position = useSelector(store => store.UserProfileReducer.user.position)
    const params = useSelector(store => JSON.parse(JSON.parse(store.UserProfileReducer.user.paramProfile)))


    useEffect(() => {  
        fetchOrganizations()
        let listOrganization = []
        /// ** FILTRO POR EMPRESA QUE NO PUEDA ACCEDER
        //console.log(organizations)
        organizations && organizations.data.forEach(organization => {
            params.filter(r => r.uuid === position.id).forEach(_e => {

                _e.organization.forEach(_a => {
                    if (_a.uuid === organization.id) {
                        listOrganization.push(organization)
                    }
                });

            });
        });
        setOrganizations(listOrganization)
        resetVariable()
    }, []);


    const handleClose = () => {
        setOpen(false);
    }

    return (
        
        <Dialog fullScreen open={open} closeAfterTransition>
            <Fade in={open}>
            <div className={'dialog-content'}>
                <h2 id="transition-modal-title">Selecciona la Empresa y la Fuente de Información</h2>
                <Grid className={'btn-wrapper'} container spacing={3}>
                    
                    <Grid item xs={12} >
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <Box>

                            <Select
                                loading={organizations.loading}
                                label="Empresa"
                                elements={Organizations}
                                onChange={value => {                                    
                                if (value) {
                                    setVariableOrganization(value)
                                    fetchDatasources(value);
                                    setReadyDataSource(true);
                                } else {
                                    resetVariable();
                                    setReadyDataSource(false);
                                    setDatasources([]);
                                    setValueDataSource('');
                                }
                                }}
                            />

                            { readyDataSource  && 
                                <Select
                                    loading={datasource.loading}
                                    label="Fuente de Información"
                                    elements={datasource.data}
                                    onChange={(value) => { 
                                        if(value){
                                            setVariableDataSource(value); 
                                            setValueDataSource(value.id);
                                        }                                        
                                    }}
                                />
                            }
                            </Box>
                        </Box> 
                    </Grid>


                  
                    <Grid item xs={12}>
                        <Button
                            className={'btn accept-btn'}
                            color="primary"
                            component={Link}
                            to={`/prometheus/variableCommissions/variables/lista/${valueDataSource}`}
                            variant="contained"
                            disabled={ valueDataSource.length > 0  ? false : true}
                        >
                            ACEPTAR
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Link to="/">
                            <Button className={'btn cancel-btn'} onClick={() => handleClose}>
                                Cancelar
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </div>
            </Fade>
        </Dialog>
    )
}


const mapStateToProps = state => ({
    organizations: {
      loading: isOrganizationsloading(state),
      data: getOrganizationsdata(state)
    },
    datasource: {
      loading: isDatasourcesloading(state),
      data: getDatasourcesdata(state)
    }
  });
  
  const mapDispatchToProps = dispatch => ({
    fetchOrganizations: () => dispatch(fetchOrganizations()),
    fetchDatasources: payload => dispatch(fetchDatasources(payload)),
    setVariableOrganization: payload => dispatch(setVariableOrganization(payload)),
    setVariableDataSource: payload => dispatch(setVariableDataSource(payload)),
    setOrganizations: payload => dispatch(setOrganizations(payload)),
    setDatasources: payload => dispatch(setDatasources(payload)),    
    resetVariable: () => dispatch(resetVariable())
  });


export default connect(mapStateToProps, mapDispatchToProps)(InitSearchVariable);
