// React components
import React from 'react';

// Router
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

function WrapContainer(props) {
    let navigate = useNavigate();
    let location = useLocation();
    let params = useParams();

    const newProps = {
        ...navigate,
        ...location,
        params: { ...params }
    }

    return (<Box>
        {React.cloneElement(props.children, newProps)}
    </Box>);
}

export default WrapContainer