export const INITIAL_STATE = {
  name: '',
  organization: '',
  area: '',
  time: '',
  active: true,
  steps: [[]],
  dictionary: { parameters: [], indicators: [], functions: [] },
  expression: '',
  tested: false,
  saved: false,
  isLastFunctionValid: false,
  sending: false,
  isDeleting: false,
  noExistingIds: [],
};
