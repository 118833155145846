import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Switch, TextField } from '@material-ui/core';
import { useEffect, useRef } from 'react';

const AddCommand = props => {
    const firstRender = useRef(true);
    const { open, onClose, onSave, data, translate } = { ...props };
    const objData = {
        command: ``,
        enable: true,
        remoteCommandDescription: "",
        remoteCommandName: ""
    }

    const [newData, setNewData] = useState(objData);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        
        setNewData({
            command: props.data.command,
            enable: props.data.enable,
            remoteCommandDescription: props.data.remoteCommandDescription,
            remoteCommandName: props.data.remoteCommandName
        });
        const cleanup = () => { }
        return cleanup
    }, [data]);

    const handleSave = () => {
        onSave(newData)
    }

    return (
        <Box display='flex' justifyContent='right' alignItems='right' flexDirection='row' m={1} minWidth={'95%'} >
            <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'md'} disableBackdropClick disableEscapeKeyDown >
                <DialogTitle >Agregar/Modificar Comando</DialogTitle>
                <DialogContentText></DialogContentText>
                <DialogContent >
                    <form autoComplete='off'>
                       <Container>
                            <Grid item >
                                <FormControl component='fieldset' error={true} align='center' fullWidth >
                                    <TextField disabled={ data.remoteCommandName != undefined && (data.remoteCommandName).lenght != 0} id="CommandName" label='Nombre' defaultValue={data.remoteCommandName} helperText={translate('Required')} margin="dense" size="small" required onBlur={ ev => setNewData({ ...newData, remoteCommandName: ev.target.value })} />
                                </FormControl>
                            </Grid>
                            <Grid item >
                                <FormControl component='fieldset' error={true} align='center' fullWidth >
                                    <TextField id="description" label='Descripción' defaultValue={data.remoteCommandDescription} helperText={translate('Required')} margin="dense" size="small" required onBlur={ ev => setNewData({ ...newData, remoteCommandDescription: ev.target.value })} />
                                </FormControl>
                            </Grid>
                            <Grid item >
                                <FormControl component='fieldset' error={true} align='center' fullWidth >
                                    <TextField id="command" label='Comando' defaultValue={data.command} helperText={translate('Required')} margin="dense" size="small" required onBlur={ ev => setNewData({ ...newData, command: ev.target.value })} />
                                </FormControl>
                            </Grid>
                            <Grid item >
                                <Switch checked={data.enable} name='enable' onChange={ ev => setNewData({ ...newData, [ev.target.name]: ev.target.checked }) } color='primary' inputProps={{ 'aria-label': 'primary checkbox' }} />
                            </Grid>
                        </Container>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button size="small" color='primary' variant='contained' onClick={handleSave} >{translate('Save')}</Button>
                    <Button size="small" color='default' variant='contained' autoFocus onClick={onClose} >{translate('Cancel')}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

AddCommand.propTypes = {

}

export default AddCommand
