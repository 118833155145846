import React from 'react';
import PropTypes from 'prop-types';
import {TextField, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import "./styles.scss";

const CVTagVariableForm = ({
    nameTagVariable, 
    dateTagVariable, 
    changeNameTagVariable, 
    changeDateTagVariable, 
    editTagVariable,
    saveTagVariable,
    data,
    loading
    }) => {
        
    return (
        <div>  
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField 
                        className="inputSelect pad"
                        type="text"
                        name="tagVariable"
                        variant="outlined"
                        label="Nombre del Tag"
                        onChange={(event) => changeNameTagVariable(event.target.value)}
                        value= {nameTagVariable}
                        disabled={editTagVariable}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField 
                        className="inputSelect pad"
                        type="date"
                        name="dateVaribale"
                        variant="outlined"
                        onChange={(event) => changeDateTagVariable(event.target.value)}
                        value= {dateTagVariable}
                        disabled={editTagVariable}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>                    
                    {editTagVariable ? null : 
                        loading ? 
                        <Button 
                            variant="contained" 
                            color="primary" 
                            className={'h56'}
                        >CARGANDO</Button>
                        :                        
                        <Button 
                            disabled={ (nameTagVariable === '' || dateTagVariable === '') || data.length === 0 }
                            variant="contained" 
                            color="primary" 
                            onClick={saveTagVariable}
                            className={'h56'}
                        >GUARDAR</Button>
                    }
                </Grid>
            </Grid>                        
        </div>
    );
};

CVTagVariableForm.propTypes = {
    nameTagVariable: PropTypes.string.isRequired,
};

export default CVTagVariableForm;