import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, Grow, LinearProgress, Container, Typography, Paper } from '@material-ui/core';

import MaterialTable from 'material-table'
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { api } from "../../api/api";
import { onUpdateRemoteRequest } from "../../graphql/bamaQueries";
import { createRemoteRequest } from "../../graphql/mutations"
import { tableIcons, localization } from '../../components/materialTable/MaterialTable';
import { athenaOperation } from "../../graphql/mutations";

import awsconfig from '../../aws-exports';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function ListPrice(props) {
  const firstRender = useRef(true)
  const [loading, setloading] = React.useState(true);
  const [enabledbutton, setenabledbutton] = React.useState(false);
  const [customerList, setCustomerList] = useState([])
  const [customerArticle, setArticleList] = useState([])
  const [customerNewTable, setNewTable] = useState([])
  var subscriptionRemoteRequests = null;
  const [listSelectCustumer, setListSelectCustumer] = useState([])
  const [listSelectAticle, setListSelectArticle] = useState([])
  const [listSelectNewTable, setListSelectNewTable] = useState([])
  const [listSelectDatosOriginalesArticle, setListSelectDatosOriginalesArticle] = useState([])
  const [listPrice, setListPrice] = useState([])
  const [listPrueba, setListPrueba] = useState([])
  const [openExportar, setOpenExportar] = React.useState(false);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false  // it's no longer the first render
      loadCustumers(0, customerList)
      loadPrice()
      loadPriceAct()
      setloading(true)
      return // skip the code below
    }


    return function cleanup() {

    };
  }, []) // Only re-run the effect if name or email change
  
  const loadCustumers = async (id, listarray) => {
    var params = {
      database: "forecast",
      table: "customers",
      queryString: "select * from customers",
    };
    api(athenaOperation, { params: JSON.stringify(params) }).then(
      (allTodos) => {
        let _f = JSON.parse(allTodos);
        setCustomerList(_f.Items)
      }
    );
  };
  
  const loadPriceAct = async () => {
    var params = {
      database: "forecast",
      table: "pricing",
      queryString: `SELECT claarti, clacte, precioofe FROM "forecast"."pricing"
      where claemp in (3 ,4)
      and ltrim(rtrim(claarti)) in ('0101','0114','0310','0385','1304','1502','1510','1540','3003697','3003707','3006762','3006788','3007096','3007289','3008188','3008247','3008329','3008647','3008618','3008588')

      group by clacte, precioofe, claemp, claarti
      order by claemp, clacte
      limit 3000`,
    };
    api(athenaOperation, { params: JSON.stringify(params) }).then(
      (allTodos) => {
        let _f = JSON.parse(allTodos);
        //setListPrice(listPrice.concat(_f.Items))
        setListPrice(_f.Items);
        setloading(false)
        /* loadPriceParams(_f.Items[_f.Items.length - 1].clacte, 0, _f.Items) */
      }
    ); 
  };


  const loadPriceParams = async (olddata, newdata, list) => {
    var params = {
      database: "forecast",
      table: "pricing",
      queryString: ` SELECT claarti, clacte, precioofe FROM "forecast"."pricing"
      where claemp in (3 ,4)
      and ltrim(rtrim(claarti)) in ('0101','0114','0310','0385','1304','1502','1510','1540','3003697','3003707','3006762','3006788','3007096','3007289','3008188','3008247','3008329','3008647','3008618','3008588')
      and clacle <> ${olddata}
      group by clacte, precioofe, claemp, claarti
      order by claemp, clacte
      limit 3000`,
    };
    api(athenaOperation, { params: JSON.stringify(params) }).then(
      (allTodos) => {
        let _f = JSON.parse(allTodos);
        if (olddata !== newdata) {
          /* setListSelectDatosOriginalesArticle(customerArticle.concat(_f.Items)) */
          let listPricing = [...list, ..._f.Items]
          setListPrice(listPricing);
          loadPriceParams(_f.Items[_f.Items.length - 1].clacte, olddata, listPricing)
          setloading(false)
        } else {
          console.log("TERMINO DE ITERAR")
          setloading(false)
        }

      });
  }

  const loadCustumersxx = async (id, listarray) => {
    let i = 0;
    var params = {
      input: {
        request: "{ \"query\": \" select top 100 ClaCte, lTRIM(RTRIM(NomCte)) NomCte, a.ClaEmp, b.NomEmp, a.ClaZona, c.NomRegion, a.ClaGiroCom, d.NomGiroCom from Cliente a WITH(NOLOCK) join emp b on b.ClaEmp = a.ClaEmp join zona c on c.ClaZona = a.ClaZona and c.ClaEmp = a.ClaEmp join GiroCom d on d.ClaGiroCom = a.ClaGiroCom where a.ClaEmp in (3,4) and a.ClaZona not in (97,98) and ClaCte > " + id + " order by ClaCte \", \"server\": \"SFORTIA\", \"database\": \"NICANOR_ERP\" }",
        startDate: parseInt(new Date().getTime() / 1000),
        sync: false,
        source: "fortia_erp"

      }
    }
    api(createRemoteRequest, params)
      .then((res) => {

        subscribeRemoteRequests(res.id, listarray)

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const subscribeRemoteRequests = async (id, listarray) => {
    // Subscribe to creation of Todo

    subscriptionRemoteRequests = API.graphql(
      graphqlOperation(onUpdateRemoteRequest, { id: id })
    ).subscribe({

      next: (todoData) => {
        // Do something with the data
        if (todoData && todoData.value && todoData.value.data && todoData.value.data.onUpdateRemoteRequest && todoData.value.data.onUpdateRemoteRequest.response && todoData.value.data.onUpdateRemoteRequest.id === id) {
          let response = JSON.parse(todoData.value.data.onUpdateRemoteRequest.response);

          var findmaxid = response[response.length - 1] //regresa el ultimo registro
          let resulttable = [...listarray, ...response]

          loadCustumers(findmaxid.ClaCte, resulttable)
          setCustomerList(resulttable)
          if (resulttable.length > 100) {
            setloading(false)
            console.log("ya llegue")
          }


        }
      }
    });
  }

  const SelectRows = (rows) => {

    setListSelectCustumer(rows)
  }

  const SelectFilterCustomer = async (rows) => {
    let countrows = rows.length
    var listPrueba = []
    rows.forEach(rows => listPrueba.push(rows.clacte))

    const queryStrings = `select * from customers where clacte in (${listPrueba.join(',')}) `
    console.log(queryStrings)


    if (countrows == 0) {
      loadCustumers(0, customerList)
    } else {
      let idcliente = rows[0].clacte
      var params = {
        database: "forecast",
        table: "customers",
        queryString: queryStrings,
      };
      api(athenaOperation, { params: JSON.stringify(params) }).then(
        (allTodos) => {
          let _f = JSON.parse(allTodos);
          console.log(_f)
          console.log("ya entre")
          setCustomerList(_f.Items)

        }
      );
    }


    /* if (idcliente =! "") {
      var params = {
        database: "forecast",
        table: "customers",
        queryString: "select * from customers where clacte = " + idcliente + " ",
      };
      api(athenaOperation, { params: JSON.stringify(params) }).then(
        (allTodos) => {
          let _f = JSON.parse(allTodos);
          console.log(_f)
          console.log("ya entre")
          setCustomerList(_f.Items)
  
        }
      );
    }else{
      loadCustumers(0, customerList)
  
    } */


  }

  //Metodos para segunda tabla
  const loadPrice = async () => {
    let i = 0;
    var params = {
      input: {
        request: "{ \"query\": \"select top 100 lTRIM(RTRIM(a.ClaArti)) ClaArti, a.NomArti, a.ClaUnidad from Articulo a WITH(NOLOCK) where a.bajalogicasino = 0 and a.ClaEmp = 2 and a.ClaDeptoVta = 2 \", \"server\": \"SFORTIA\", \"database\": \"NICANOR_ERP\" }",
        startDate: parseInt(new Date().getTime() / 1000),
        sync: false,
        source: "fortia_erp"

      }
    }
    api(createRemoteRequest, params)
      .then((res) => {

        subscribeRemoteRequestsArticle(res.id)

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const subscribeRemoteRequestsArticle = (id) => {
    // Subscribe to creation of Todo
    subscriptionRemoteRequests = API.graphql(
      graphqlOperation(onUpdateRemoteRequest, { id: id })
    ).subscribe({
      next: (todoData) => {
        // Do something with the data
        if (todoData && todoData.value && todoData.value.data && todoData.value.data.onUpdateRemoteRequest && todoData.value.data.onUpdateRemoteRequest.response && todoData.value.data.onUpdateRemoteRequest.id === id) {
          let response = JSON.parse(todoData.value.data.onUpdateRemoteRequest.response);
          console.log("Hola Mundo")
          console.log(response)
          setListSelectDatosOriginalesArticle(response)
          setArticleList(response);
          console.log("Prueba")
          console.log(setArticleList)
        }
      }
    });
  }

  //Metodos para tercera tabla
  const appendRowsToFile = async () => {
    let cA = customerArticle;
    let cL = listSelectCustumer;

    let articles = cA.filter(a => a.price && a.price > 0)

    /*console.log(cA, cL, articles)*/
    /* setListSelectNewTable([]) */

    if (articles) {
      let table = listSelectNewTable
      cL.forEach(c => {
        articles.forEach(a => {
          table.push({ ...c, ...a })
        })
      })
      /* setListSelectNewTable.find(ClaArti) */
      /* const resultado = setListSelectNewTable.filter */
      /* const resultado = listSelectNewTable.filter(arti => arti.ClaCte !== cL.ClaCte) */
      var resultado = customerList

      table.forEach(customer => {
        resultado = resultado.filter(cus => cus.clacte !== customer.clacte)
      });

      setCustomerList(resultado)
      setArticleList(listSelectDatosOriginalesArticle)
      console.log(table)
      /* console.log(resultado) */
      setListSelectNewTable(table)
    }
  }

  /* Carga de archivos */
  const csvFromArrayOfArrays = async () => {
    console.log("ya entreeeeeeeS")
    const { convertArrayToCSV } = require('convert-array-to-csv');
    const converter = require('convert-array-to-csv');

    const header = ['clacte', 'claemp', 'clacrti', 'TipoPrecioVta', 'PctDescto', 'PrecioOfe', 'FecIniOfe', 'FecFinOfe', 'IdSesion'];
    /* const dataObjects = listSelectNewTable
    const csvFromArrayOfObjects = convertArrayToCSV(dataObjects); */

    let FecIniOfe = new Date()
    var FecFinOfe = new Date(new Date().getFullYear(), 12, 31);
    console.log(FecIniOfe)
    console.log(FecFinOfe)

    let listCSV = []
    listSelectNewTable.forEach(i => {
      listCSV.push({
        ClaCte: i.ClaCte,
        ClaEmp: i.ClaEmp,
        ClaArti: i.ClaArti,
        TipoPrecioVta: 1,
        PctDescto: 0,
        PrecioOfe: i.price,
        FecIniOfe: "22/10/2020",
        FecFinOfe: "31/12/2020",
        IdSesion: 7777777
      })
    })

    const dataArrays = listCSV
    const csvFromArrayOfArraysObj = convertArrayToCSV(dataArrays, {
      header,
      separator: ','
    });
    console.log(dataArrays)
    /*Metodo para subir archivo S3*/
    Storage.configure({
      AWSS3: {
        bucket: "bama-products-images",
        region: awsconfig.aws_user_files_s3_bucket_region
      }
    });

    Storage.put("excel/ListadePrecios.csv", csvFromArrayOfArraysObj)
      .then((result) => {
        console.log(result)
      })
      .catch((err) => console.log(err));
    /*==========================*/
    setenabledbutton(true) 
    setOpenExportar(false);
  }

  const prueba = async () => {
    console.log("Si llegue")
  }

  const handleClickOpenExportar = () => {
    setOpenExportar(true);
     setenabledbutton(true) 
    
  };

  const handleCloseExportar = () => {
    setOpenExportar(false);
  };



  return (
    <Grow in={true} spacing={2}>
      <Container maxWidth="xl">
        <Typography variant="h5" color="textSecondary">
          {props.translate("Pricing")}
        </Typography>
        {loading ? (
          <LinearProgress variant="query" color="secondary" />
        ) : (

            <Grid container spacing={4} justify="flex-end" >
              <Grid item xs={12}>
                <Paper>
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      {
                        field: "clacte",
                        title: props.translate("Key"),
                        /* customFilterAndSearch: (term, rowData) => prueba */
                      },
                      {
                        field: "nomcte",
                        title: props.translate("Customers"),
                      },
                      {
                        field: "nomemp",
                        title: props.translate("Company Key"),
                      },
                      {
                        field: "nomregion",
                        title: props.translate("Name region"),
                      },
                      {
                        field: "nomgirocom",
                        title: props.translate("Commercial Business"),
                        /* lookup: "NomGiroCom", */
                      },
                    ]}
                    data={customerList}
                    title={props.translate("List of customers")} //
                    options={{
                      selection: true,
                      filtering: true,
                      search: false,
                      searchable: false,
                      selectionProps: rowData => ({
                        disabled: rowData.name === 'Mehmet',
                        color: 'primary',

                      })
                    }}
                    onSelectionChange={SelectRows}
                    localization={localization(props)}

                  /></Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      {
                        field: "claarti",
                        title: props.translate("Key Article"),
                      },
                      {
                        field: "clacte",
                        title: props.translate("Key Customers"),
                      },
                      {
                        field: "precioofe",
                        title: props.translate("Offer price"),
                        type: 'currency',
                        cellStyle: {
                          textAlign: "left"
                        }
                      },
                      {
                        field: "pctdescto",
                        title: props.translate("Discount"),
                        type: 'currency',
                        cellStyle: {
                          textAlign: "left"
                        }
                      },
                    ]}
                    data={listPrice}
                    title={props.translate("Updated price")} //
                    options={{
                      filtering: true,
                      search: false,
                      searchable: false,
                      selection: true,
                      selectionProps: rowData => ({
                        disabled: rowData.name === 'Mehmet',
                        color: 'primary',

                      })
                    }}
                    localization={localization(props)}
                    onSelectionChange={SelectFilterCustomer}
                  /></Paper>
                <Paper>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper>
                  <MaterialTable
                    icons={tableIcons}
                    columns={[

                      {
                        field: "ClaArti",
                        title: props.translate("Key Article"),
                        editable: 'never'
                      },
                      {
                        field: "NomArti",
                        title: props.translate("Name Article"),
                        editable: 'never'
                      },
                      {
                        field: "ClaUnidad",
                        title: props.translate("Presentation"),
                        editable: 'never'
                      },
                      {
                        field: "price",
                        title: props.translate("Edit Price"),
                        /*render: rowData => <TextField id="outlined-basic" label="Precio" variant="outlined" defaultValue="0.00" />*/
                      },
                    ]}
                    data={customerArticle}
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            const dataUpdate = [...customerArticle];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            dataUpdate.forEach(d => {
                              if (d.ClaArti.trim() === newData.ClaArti.trim()) {
                                d = newData
                                console.log(d)
                              }
                            })
                            setArticleList([...dataUpdate]);

                            resolve();
                          }, 1000)
                        }),

                    }}
                    title={props.translate("List of articles")} //
                    onSelectionChange={SelectRows}
                    localization={localization(props)}
                  />
                </Paper>
              </Grid>
              <Grid justify="flex-end" alignItems="flex-end">
                <Paper>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={appendRowsToFile}

                  >
                    Agregar Datos
                  </Button>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper><MaterialTable
                  icons={tableIcons}
                  columns={[
                    {
                      field: "clacte",
                      title: props.translate("Key Customers"),
                    },
                    {
                      field: "nomcte",
                      title: props.translate("Name Client"),
                    },
                    {
                      field: "ClaArti",
                      title: props.translate("Key Article"),
                    },
                    {
                      field: "NomArti",
                      title: props.translate("Name Article"),
                    },
                    {
                      field: "ClaUnidad",
                      title: props.translate("Key Unit"),
                    },
                    {
                      field: "price",
                      title: props.translate("Price"),
                      type: 'currency',
                    }

                  ]}
                  data={listSelectNewTable}
                  title={props.translate("List of added files")} //
                  options={{
                    exportButton: true,
                    selectionProps: rowData => ({
                      color: 'primary'
                    })
                  }}
                  /* editable={{
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          resolve();
                        }, 1000);
                      }),
                  }} */
                  onSelectionChange={SelectRows}
                  localization={localization(props)}
                />

                </Paper>
              </Grid>
              <Grid justify="flex-end" alignItems="flex-end">
                <Paper>
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={csvFromArrayOfArrays}
                  >
                    Exportar
                      </Button> */}
                </Paper>
                <Button variant="outlined" color="primary" onClick={handleClickOpenExportar}
                        disabled={false}>
                  Exportar Lista
                </Button>
                <Dialog
                  open={openExportar}
                  onClose={handleCloseExportar}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"AVISO"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Una vez que se cargue el archivo no lo podras generar de nuevo hasta el siguiente dia
                  </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseExportar} color="primary">
                      Cancelar
                  </Button>
                    <Button onClick={csvFromArrayOfArrays} color="primary" autoFocus>
                      Listo
                  </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>

          )}

      </Container>

    </Grow>

    /*  {
       customerList.length > 0 ?
       
       : <LinearProgress color="secondary" />} */

    /*  {listSelectCustumer.length > 0 ?
       <Alert severity="info">Clientes seleccionados: {listSelectCustumer.length}</Alert>
       : null} */


    /*  {customerArticle.length > 0 && listSelectCustumer.length > 0 ?
       
       : null} */

    /* {
  customerList.length > 0 ?
      
      : null
  } */


    /* {
      listSelectNewTable.length > 0 ?
        
        : null
    } */
    /*  {
       listSelectNewTable.length > 0 ?
         
         : null
     } */

  )
}
export default withSnackbar(ListPrice);