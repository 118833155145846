import React, { useState,useEffect,useRef } from 'react';
import {Popper,ButtonGroup, MenuItem,Grow, Paper, Box, Container, Typography, InputAdornment, IconButton, LinearProgress, Grid, Button, Dialog, AppBar, Toolbar, Slide, Link, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider";
import RowCustomerSetting from "../customers/rowCustomerSetting";


import { withSnackbar } from 'notistack';

// Amplify 
import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { listCustomerTypes } from '../../graphql/queries';
import { updateCustomerType } from '../../graphql/mutations';
import { api } from '../../api/api';

// Amplify init
Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
    },
    ButtonGroup: {
       margin: theme.spacing(2),
    },
  }));



function CustomerSetting(props) {
    const classes = useStyles();
    const firstRender = useRef(true);
    const anchorRef = React.useRef(null);

    const [listTypecustomer, setlistTypeCustomer] = useState([])
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);


    useEffect(() => {
        if (firstRender.current) {
          if (firstRender.current) {
            firstRender.current = false; // it's no longer the first render
    
            listTypeCustomer()
            return; // skip the code below
          }
        }
    },[]);    


  const handleChangeType = (textedit, TypeItem,type) => {
    const temp = [...listTypecustomer];
    temp.forEach((a, i) => {
      if (a.customerTypeId === TypeItem.customerTypeId) {
        temp.splice(i, 1, { ...a, [textedit]: TypeItem.defaultType });
      }
    });
 //   setlistTypeCustomer(temp);
    UpdTypeCustomer(TypeItem,type)
  };

  const listTypeCustomer = () => {
    var _customerFilter = {
      limit: 100,
    };

    api(listCustomerTypes, _customerFilter).then((res) => {
      let response =[
        {
            _version: 1,
            customerTypeId: "CL001",
            customerTypeName: "Clientes lealtad",
            defaultType: true,
            id: "c32b3f5e-5de3-4954-96e2-a87a29d89fcd",
        },
        {
            _version: 1,
            customerTypeId: "CL002",
            customerTypeName: "Clientes Consentido",
            defaultType: false,
            id: "c32b3f5e-5de3-4954-96e2-a87a29d89fcd",
        }
    ]
      setlistTypeCustomer(res);
    });
  };


  const UpdTypeCustomer = (Typecustomer,type) => {
    var filter = listTypecustomer.filter((e) => (e.customerTypeId === Typecustomer.customerTypeId))

    let params ={
      _version: filter[0]._version,
      customerTypeId: filter[0].customerTypeId,
      customerTypeName: filter[0].customerTypeName,
      defaultType: type,
      id: filter[0].id,
    }
    api(updateCustomerType,{input:params}).then((res) => { 
      //console.log(res)
      listTypeCustomer()
    });
  };

    return (
      <Box>
        <Container>
          <Typography variant="h5" color="textSecondary">
            Tipo de cliente
          </Typography>
          <Grid item xs={12}>
            <Paper className={classes.paper} >
              {/* Container */}
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
                justify="center"
                style={{ minHeight: "3vh" }}
              >
                <Grid item xs={4}>
                  <Typography variant="subtitle2" align="center">
                    customerTypeId
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" align="center">
                    customerTypeName
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" align="center">
                   defaultType
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              {listTypecustomer.map((typeCustomer, idx) => (
               <RowCustomerSetting
               key={idx}
               {...typeCustomer}
               {...props}
               itemTypeCustomer={typeCustomer}
               handleChangeType={handleChangeType}
               />
              ))}
              {/* */}
            </Paper>
          </Grid>
        </Container>
      </Box>
    );
}

export default withSnackbar(CustomerSetting);