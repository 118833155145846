import { takeLatest, all, call, put } from 'redux-saga/effects';
import { FETCH_PARAMETERS } from './constants';
import { setParameters, setParametersError } from './actions';
import API from '../../services/api';

export function* fetchParameters(action) {
  const { payload } = action;
  try {
    const response = yield call(API.getParameters, payload);
    const {
      data: { parameters },
    } = response;

    yield put(setParameters(parameters));
  } catch (error) {
    yield put(setParametersError(true));
  }
}

export default function* parametersSaga() {
  yield all([takeLatest(FETCH_PARAMETERS, fetchParameters)]);
}
