import { createAction } from 'redux-actions';
import {
  SET_KPI_ORGANIZATION,
  SET_KPI_AREA,
  SET_KPI_TIME,
  SET_KPI_NAME,
  CREATE_KPI_STEP,
  ADD_KPI_ELEMENT,
  PUSH_ELEMENT_KPI_STEP,
  PUSH_ELEMENT_KPI_DICTIONARY,
  TEST_KPI_EXPRESSION,
  SAVE_KPI,
  SET_TEST_KPI,
  SET_SAVE_KPI,
  RESET_KPI_DATA,
  ADD_KPI_FUNCTION_ELEMENT,
  PUSH_FUNCTION_ELEMENT_KPI,
  SET_LAST_FUNCTION_KPI_VALIDATION,
  SET_KPI_SENDING,
  DELETE_KPI_ELEMENT,
  POP_ELEMENT_DICTIONARY_KPI,
  POP_ALL_FUNCTION_ARGUMENTS_KPI_DICTIONARY,
  POP_ELEMENT_KPI,
  POP_FUNCTION_ARGUMENT_ELEMENT_KPI,
  EDIT_KPI_ELEMENT,
  REPLACE_KPI_ELEMENT,
  EDIT_KPI,
  REHYDRATE_KPI,
  RESET_STEPS_KPI,
  EDIT_SAVE_KPI,
  DELETE_KPI,
  SET_ACTIVE_KPI,
  SET_KPI_DELETING,
  EDIT_KPI_FUNCTION_ELEMENT,
  REPLACE_FUNCTION_ARGUMENT_ELEMENT_KPI,
  SET_NO_EXIST_IDS_KPI,
} from './constants';

export const setKPIorganization = createAction(SET_KPI_ORGANIZATION);
export const setKPIarea = createAction(SET_KPI_AREA);
export const setKPItime = createAction(SET_KPI_TIME);
export const setKPIName = createAction(SET_KPI_NAME);
export const createKPIStep = createAction(CREATE_KPI_STEP);
export const addKPIElement = createAction(ADD_KPI_ELEMENT);
export const pushElementKPIStep = createAction(PUSH_ELEMENT_KPI_STEP);
export const pushElementDictionaryKPI = createAction(
  PUSH_ELEMENT_KPI_DICTIONARY,
);
export const testKPIexpression = createAction(TEST_KPI_EXPRESSION);
export const saveKPI = createAction(SAVE_KPI);
export const setTestKPI = createAction(SET_TEST_KPI);
export const setSaveKPI = createAction(SET_SAVE_KPI);
export const resetKPIData = createAction(RESET_KPI_DATA);
export const addKPIElementFunction = createAction(ADD_KPI_FUNCTION_ELEMENT);
export const pushFunctionElementKPI = createAction(PUSH_FUNCTION_ELEMENT_KPI);
export const setLastFunctionKPIValidation = createAction(
  SET_LAST_FUNCTION_KPI_VALIDATION,
);
export const setKPIsending = createAction(SET_KPI_SENDING);
export const deleteKPIElement = createAction(DELETE_KPI_ELEMENT);
export const popElementDictionaryKPI = createAction(POP_ELEMENT_DICTIONARY_KPI);
export const popAllFunctionArgumentsKPIDictionary = createAction(
  POP_ALL_FUNCTION_ARGUMENTS_KPI_DICTIONARY,
);
export const popElementKPI = createAction(POP_ELEMENT_KPI);
export const popFunctionArgumentElementKPI = createAction(
  POP_FUNCTION_ARGUMENT_ELEMENT_KPI,
);
export const editKPIElement = createAction(EDIT_KPI_ELEMENT);
export const replaceElementKPI = createAction(REPLACE_KPI_ELEMENT);
export const editKPI = createAction(EDIT_KPI);
export const rehydrateKPI = createAction(REHYDRATE_KPI);
export const resetSteps = createAction(RESET_STEPS_KPI);
export const saveEditKPI = createAction(EDIT_SAVE_KPI);
export const deleteKPI = createAction(DELETE_KPI);
export const setActiveKPI = createAction(SET_ACTIVE_KPI);
export const setKPIDeleting = createAction(SET_KPI_DELETING);
export const editKPIFunctionArgumentElement = createAction(
  EDIT_KPI_FUNCTION_ELEMENT,
);
export const replaceFunctionElementKPI = createAction(
  REPLACE_FUNCTION_ARGUMENT_ELEMENT_KPI,
);
export const setNoExistIdsKPI = createAction(SET_NO_EXIST_IDS_KPI);
