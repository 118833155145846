import React, { Component } from 'react';
import { APIBAMASTAGE } from "../../../env/constants";
import MaterialTable from 'material-table';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { tableESP } from '../../../helpers';
import { Button, Container, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalParameter from '../ModalParameter';
import Lead from '../../../components/Lead';
import { connect } from 'react-redux';
import SessionControlSupportMultiLanguaje from '../../../utils/SessionControl';

class LisParameter extends SessionControlSupportMultiLanguaje {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            idArea: '',
            parameters: [],
            nameParameter: '',
            breadCrumbs: [
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                { title: "Párametros", url: "/prometheus/variableCommissions/parametros" },
                { title: "Lista", current: true },
            ],
            valorParameter: '',
            dateParameter: '',
            valoresParamaters: [],
            permision_read:false,
            permision_write:false,
            btnDis: true,
            lead: { show: false, type: "success", message: "", close: true }
        }
    }


    componentDidMount() {
        this.setState({
            organization: this.getSecureStore().VariableCommissionsReducer.organization,
            area: this.getSecureStore().VariableCommissionsReducer.area  
        })
        const { params } = this.props;
        let jsonParamProfile = JSON.parse(JSON.parse(this.getSecureStore().UserProfileReducer.user.paramProfile))
        //console.log(jsonParamProfile)
        //console.log(this.getSecureStore().UserProfileReducer.user.position)
        /// ** FILTRO POR PERMISO DE LECTURA Y ESCRITURA
        jsonParamProfile.filter(r => r.uuid === this.getSecureStore().UserProfileReducer.user.position.id).forEach(_e => {
            // console.log(_e.path_permissions)
            _e.path_permissions.forEach(_p => {
                if (_p.name === "Parameters") {
                    this.setState({
                        permision_read: _p.read,
                        permision_write: _p.write
                    });
                }
            });

        });
        fetch(`${APIBAMASTAGE}/area/${params.areaId}/parameter`)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    parameters: data.parameters,
                    readyOrg: true,
                    loading: false,
                    idArea: params.areaId
                });
            })
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleDeleteParameter(oldData, id) {

        const { parameters } = this.state;

        this.setState({
            loading: true
        });

        fetch(`${APIBAMASTAGE}/parameter/${id}`, { method: 'DELETE' })
            .then(response => response.json())
            .then(data => {
                let prov = parameters;
                prov.splice(prov.indexOf(oldData), 1);
                this.setState({
                    parameters: prov,
                    loading: false,
                    lead: { show: true, type: "success", message: "Se eliminó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });

    }


    handleEditParameter(oldData, newData, id, name) {

        const { parameters } = this.state;

        this.setState({
            loading: true
        });

        let body = {
            name: `${name}`,
        }

        fetch(`${APIBAMASTAGE}/parameter/${id}`, { method: 'PATCH', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(data => {
                let prov = parameters;
                prov[prov.indexOf(oldData)] = newData;
                this.setState({
                    parameters: prov,
                    loading: false,
                    lead: { show: true, type: "success", message: "Se modificó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });
    }


    //Modal Create

    handleChangeNameParameter = (e) => {
        this.setState({
            nameParameter: e
        });
    }

    handleChangeValorParameter = (e) => {
        this.setState({
            valorParameter: e
        });
    }

    handleChangeDateParameter = (e) => {
        this.setState({
            dateParameter: e
        });
    }

    handleAddValueParameter = () => {

        const { valorParameter, dateParameter, valoresParamaters } = this.state;

        let obj = {
            "uploaded_at": dateParameter,
            "value": parseFloat(valorParameter)
        }

        let ar = valoresParamaters;
        const resultado = ar.find(date => date.uploaded_at === dateParameter);

        if (resultado) {
            let out = ar.map(e => {
                if (e.date === obj.date)
                    return obj
                else
                    return e
            })
            ar = out;
        } else {
            ar.push(obj);
        }

        this.setState({
            valoresParamaters: ar,
            valorParameter: '',
            dateParameter: ''
        });

    }

    handleSaveParameter = () => {

        const { idArea, nameParameter, valoresParamaters, parameters } = this.state;

        this.setState({
            loading: true,
            btnDis: false
        });

        let body = {
            name: `${nameParameter}`,
            values: valoresParamaters
        }

        fetch(`${APIBAMASTAGE}/area/${idArea}/parameter`, { method: 'post', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(data => {

                let ar = parameters;
                const obj = { id: data.parameter.id, name: data.parameter.name };

                ar.push(obj);

                this.setState({
                    open: false,
                    loading: false,
                    parameters: ar,
                    lead: { show: true, type: "success", message: "Se creó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });
    }


    handleDeleteRow = (row) => {
        const { valoresParamaters } = this.state;
        let ar = valoresParamaters;
        ar.splice(ar.indexOf(row), 1);
        this.setState({
            valoresParamaters: ar
        });
    }

    render() {

        const {
            breadCrumbs,
            btnDis,
            dateParameter,
            lead,
            loading,
            nameParameter,
            open,
            parameters,
            valorParameter,
            valoresParamaters,
            organization,
            area
        } = this.state;


        return (
            <Container fixed>
                <Lead
                    lead={lead}
                    handleClose={() => { this.setState({ lead: { show: false, type: "success", message: "", close: true } }) }}
                />
                <ModalParameter
                    open={open}
                    nameParameter={nameParameter}
                    loading={loading}
                    changeName={this.handleChangeNameParameter}
                    dateParameter={dateParameter}
                    changeDate={this.handleChangeDateParameter}
                    valorParameter={valorParameter}
                    changeValue={this.handleChangeValorParameter}
                    valoresParamaters={valoresParamaters}
                    handleClose={this.handleClose}
                    addValueParameter={this.handleAddValueParameter}
                    saveParameter={this.handleSaveParameter}
                    btnDis={btnDis}
                    deleteRow={this.handleDeleteRow}
                ></ModalParameter>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs {...this.props} navigation={breadCrumbs} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom>
                            {organization && organization.name}
                        </Typography>
                    </Grid>
                    <Grid
                        className={'btn-wrapper'}
                        item xs={6}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-start"
                    >
                        {this.state.permision_write ?<Button
                            className={'btn accept-btn'}
                            type="submit"
                            onClick={() => this.setState({ open: true, nameParameter: '', dateParameter: '', valorParameter: '', valoresParamaters: [] })}
                        >
                            CREAR PÁRAMETRO
                        </Button> : "" }
                    </Grid>
                    <Grid className={'btn-wrapper'} item xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : this.state.permision_write ? (
                                <MaterialTable
                                    title={`${area && area.name}`}
                                    columns={[
                                        /*{ title: 'ID', field: 'id', editable: 'never' },*/
                                        { title: 'Nombre', field: 'name' },
                                        {
                                            render: rowData => (
                                                <Button
                                                    className={'accept-btn'}
                                                    component={Link}
                                                    to={`/prometheus/variableCommissions/parametros/detalle/${rowData.id}/${rowData.name}`}
                                                    variant="contained"
                                                >
                                                    Ver Valores
                                                </Button>
                                            ),
                                            width: 200
                                        },
                                    ]}
                                    data={parameters}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.handleEditParameter(oldData, newData, oldData.id, newData.name);
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.handleDeleteParameter(oldData, oldData.id);
                                                }, 600);
                                            }),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    localization={tableESP}
                                />
                            ):(
                                <MaterialTable
                                    title={`${area && area.name}`}
                                    columns={[
                                        /*{ title: 'ID', field: 'id', editable: 'never' },*/
                                        { title: 'Nombre', field: 'name' },
                                        {
                                            render: rowData => (
                                                <Button
                                                    className={'accept-btn'}
                                                    component={Link}
                                                    to={`/prometheus/variableCommissions/parametros/detalle/${rowData.id}/${rowData.name}`}
                                                    variant="contained"
                                                >
                                                    Ver Valores
                                                </Button>
                                            ),
                                            width: 200
                                        },
                                    ]}
                                    data={parameters}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    localization={tableESP}
                                />
                            )}
                    </Grid>
                </Grid>
            </Container>
        );
    }

};


const mapStateToProps = state => ({
    history: state.router,
    navigation: state.navigation ? state.navigation.navigation : null
});

export default connect(mapStateToProps)(LisParameter);