import { createAction } from 'redux-actions';
import {
  FETCH_ORGANIZATIONS,
  SET_ORGANIZATIONS,
  SET_ORGANIZATIONS_ERROR,
} from './constants';

export const fetchOrganizations = createAction(FETCH_ORGANIZATIONS);
export const setOrganizations = createAction(SET_ORGANIZATIONS);
export const setOrganizationsError = createAction(SET_ORGANIZATIONS_ERROR);
