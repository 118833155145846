import React from 'react';
import SessionControl from '../../utils/SessionControl';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import CallIcon from '@material-ui/icons/Call';
import DialpadIcon from '@material-ui/icons/Dialpad';

class RegisterForm extends SessionControl {

    constructor(props) {
        super(props);
        console.log("RegisterForm", props);
        this.state = {
            username: props.user && props.user.username ? props.user.username : '',
            password: '',
            phonenumber: '',
            isReady: false,
            confirmSignUpRequested: props.confirmRequested,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResendSignUp = this.handleResendSignUp.bind(this);
    }

    getDisabled() {
        let disabled = false;
        if (!this.state.username || this.state.username.length <= 4)
            disabled = true;

        if (!this.state.confirmSignUpRequested) {
            if (!this.state.password || this.state.password.length <= 5)
                disabled = true;
            if (!this.state.phonenumber || this.state.phonenumber.length < 10)
                disabled = true;
        } else {
            if (!this.state.code || this.state.code.length <= 5)
                disabled = true;
        }
        return disabled;
    }

    handleSubmit(event) {
        const { username, password, phonenumber, code } = this.state;
        if (this.state.confirmSignUpRequested) {
            this.props.handleConfirmSignUp({ username, code });
        } else {
            this.props.handleSubmit({ username, password, phonenumber });
            this.setState({ confirmSignUpRequested: true })
        }
        event.preventDefault();
    }

    handleResendSignUp = (event) => {
        const { username, code } = this.state;

        this.props.handleResendSignUp({ username, code });

        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Box
                    py={2}>
                    <Box
                        display="flex"
                        justifyContent='center'
                        flexDirection="column"
                        alignItems="center"
                        py={2}>
                        <Box>
                            {this.translate(this.state.confirmSignUpRequested ? "Confirmating new User" : "Creating a New User")}
                        </Box>
                        {this.state.confirmSignUpRequested ?
                            <Box>
                                <h6>
                                    {this.state.username}
                                </h6>
                            </Box>
                            : null}
                    </Box>
                    {!this.state.confirmSignUpRequested ?
                        <Box>
                            <Box
                                py={2}>
                                <TextField
                                    name="username"
                                    label={this.translate('User')}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type="text"
                                    onChange={(username) => this.setState({ username: username.target.value })}
                                    value={this.state.username}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon color="action" />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Box>
                            <Box
                                py={2}>
                                <TextField
                                    name="password"
                                    label={this.translate('Password')}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type="password"
                                    onChange={(password) => this.setState({ password: password.target.value })}
                                    value={this.state.password}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon color="action" />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Box>
                            <Box
                                py={2}>
                                <TextField
                                    name="phonenumber"
                                    label={this.translate('Telephone Number')}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type="tel"
                                    onChange={(phonenumber) => this.setState({ phonenumber: phonenumber.target.value })}
                                    value={this.state.phonenumber}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CallIcon color="action" />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Box>
                        </Box>
                        :
                        <Box
                            py={2}>
                            <TextField
                                name="code"
                                label={this.translate('Code')}
                                variant="outlined"
                                required
                                fullWidth
                                type="number"
                                onChange={(code) => this.setState({ code: code.target.value })}
                                value={this.state.code}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <DialpadIcon color="action" />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Box>}
                    <Box
                        pt={1}
                        display="flex"
                        justifyContent='center'
                        alignItems='stretch'>
                        <Button
                            type="submit"
                            disabled={this.getDisabled()}
                            variant="contained"
                            color="primary">
                            {this.translate(this.state.confirmSignUpRequested ? "Confirm User" : "Register")}
                        </Button>
                    </Box>
                    {this.state.confirmSignUpRequested ?
                        <Box
                            pt={1}
                            display="flex"
                            justifyContent='center'
                            alignItems='stretch'>
                            <Button
                                onClick={this.handleResendSignUp}
                                type="submit"
                                variant="contained"
                                color="secondary">
                                {this.translate("Resend SignUp code")}
                            </Button>
                        </Box>
                        : null}
                </Box>
            </form>
        );
    }
}

export default RegisterForm;