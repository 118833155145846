import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import {
  SET_CREATEFUNCTION_NAME,
  SET_CREATEFUNCTION_DESC,
  SET_CREATEFUNCTION_PARAMETER,
  PUSH_ELEMENT_CREATEFUNCTION_STEP,
  CREATE_CREATEFUNCTION_STEP,
  SET_TEST_CREATEFUNCTION,
  SET_CREATEFUNCTION_SENDING,
  SET_SAVE_CREATEFUNCTION,
  RESET_CREATEFUNCTION_DATA,
  REHYDRATE_CREATEFUNCTION,
  POP_CREATEFUNCTION_ELEMENT,
  REPLACE_ELEMENT_CREATEFUNCTION,
  DELETE_CREATEFUNCTION_PARAMETER,
  SET_EXPRESSION_CREATEFUNCTION,
  SET_DELETING_CREATEFUNCTION,
} from './constants';

const setName = (state, action) => ({
  ...state,
  name: action.payload,
});

const setDesc = (state, action) => ({
  ...state,
  description: action.payload,
});

const setParameter = (state, action) => ({
  ...state,
  parameters: [...state.parameters, action.payload],
});

const pushElementStep = (state, action) => {
  const steps = [...state.steps];
  steps[action.payload.step] = [
    ...state.steps[action.payload.step],
    action.payload.object,
  ];
  return { ...state, steps };
};

const createStep = (state, payload) => ({
  ...state,
  steps: [...state.steps, []],
});
const setTestResult = (state, action) => ({
  ...state,
  tested: action.payload,
});

const setSending = (state, action) => ({
  ...state,
  sending: action.payload,
});

const setSaved = (state, action) => ({
  ...state,
  saved: action.payload,
});

const resetData = () => INITIAL_STATE;

const rehydrateData = (state, action) => ({
  ...state,
  name: action.payload.name,
  description: action.payload.description,
  steps: action.payload.steps,
  parameters: action.payload.parameters,
  expression: action.payload.expression,
});

const popElement = (state, action) => {
  const steps = [...state.steps];
  steps[action.payload.step].splice(action.payload.index, 1);
  return { ...state, steps };
};

const replaceElement = (state, action) => {
  const element = action.payload.element.object;
  const stepNumber = action.payload.editData.step;
  const stepIndex = action.payload.editData.index;
  const steps = [...state.steps];
  steps[stepNumber][stepIndex] = element;
  return { ...state, steps };
};

const deleteFunctionParameter = (state, action) => {
  const parameters = [...state.parameters];
  parameters.splice(action.payload, 1);
  return { ...state, parameters };
};

const setExpression = (state, action) => ({
  ...state,
  expression: action.payload,
});

const setDeleting = (state, action) => ({
  ...state,
  isDeleting: action.payload,
});

export const reducer = handleActions(
  {
    [SET_CREATEFUNCTION_NAME]: setName,
    [SET_CREATEFUNCTION_DESC]: setDesc,
    [SET_CREATEFUNCTION_PARAMETER]: setParameter,
    [PUSH_ELEMENT_CREATEFUNCTION_STEP]: pushElementStep,
    [CREATE_CREATEFUNCTION_STEP]: createStep,
    [SET_TEST_CREATEFUNCTION]: setTestResult,
    [SET_CREATEFUNCTION_SENDING]: setSending,
    [SET_SAVE_CREATEFUNCTION]: setSaved,
    [RESET_CREATEFUNCTION_DATA]: resetData,
    [REHYDRATE_CREATEFUNCTION]: rehydrateData,
    [POP_CREATEFUNCTION_ELEMENT]: popElement,
    [REPLACE_ELEMENT_CREATEFUNCTION]: replaceElement,
    [DELETE_CREATEFUNCTION_PARAMETER]: deleteFunctionParameter,
    [SET_EXPRESSION_CREATEFUNCTION]: setExpression,
    [SET_DELETING_CREATEFUNCTION]: setDeleting,
  },
  INITIAL_STATE,
);
