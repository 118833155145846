import {
    USER_SIGNIN
} from '../ActionTypes';

export const UserSignIn_onSuccess = data => ({
    type: USER_SIGNIN.SUCCESS,
    data
});

export const UserSignIn_onError = data => ({
    type: USER_SIGNIN.ERROR,
    data
});

export const UserSignIn_logOut = () => ({
    type: USER_SIGNIN.LOGOUT
});

export const UserSignIn_position = data => ({
    type: USER_SIGNIN.POSITION,
    data
})

export const UserSignIn_systemRole = data => ({
    type: USER_SIGNIN.SYSTEM_ROLE,
    data
})