import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import { FETCH_AREAS, SET_AREAS, SET_AREAS_ERROR } from './constants';

const fetchAreas = (state, payload) => ({
  ...state,
  loading: true,
  error: false,
});

const setAreas = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  data: action.payload,
});

const setAreasError = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

export const reducer = handleActions(
  {
    [FETCH_AREAS]: fetchAreas,
    [SET_AREAS]: setAreas,
    [SET_AREAS_ERROR]: setAreasError,
  },
  INITIAL_STATE,
);
