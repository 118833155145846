import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getModalselectorState = state => get(state, 'modalselector');

export const isModalselectorShow = createSelector(
  [getModalselectorState],
  modalSelector => get(modalSelector, 'show'),
);

export const getModalSelectorTitle = createSelector(
  [getModalselectorState],
  modalSelector => get(modalSelector, 'title'),
);

export const getModalSelectorType = createSelector(
  [getModalselectorState],
  modalSelector => get(modalSelector, 'type'),
);

export const getModalSelectorFatherScreen = createSelector(
  [getModalselectorState],
  modalSelector => get(modalSelector, 'fatherScreen'),
);
