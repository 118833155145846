/**
 * Exceptions
 */
export const ZERO_DIVISION = "ZeroDivision"
export const DICTIONARY_INDEX_NOT_FOUND = "DictionaryIndexNotFound"
export const INVALID_INDICATOR_VARIABLE_KEY = "InvalidIndicatorVariableKey"
export const INDICATOR_NOT_FOUND = "IndicatorRidNotFound"
export const HELPER_FUNCTION_REMOTE_ID_NOT_FOUND = "HelperFunctionRidNotFound"
export const INVALID_SYNTAX = "InvalidSyntax"

/**
 * Exception types
 */
export const COMPILER_EXCEPTION = "COMPILER_EXCEPTION"
export const EXTRACTION_EXCEPTION = "EXTRACTION_EXCEPTION"

export const EXCEPTIONS = {
    ZERO_DIVISION: {
        description:  "División por cero",
        reason: `
            Se generó una divisíon por cero en la comisión.
            Verifica los valores de variables y parámetros para resolver la inconsistencia de 
            las operaciones.
        `
    },
    DICTIONARY_INDEX_NOT_FOUND: {
        description: "Comisión variable no procesable",
        reason: `
            La declaración de la expresion que se procesó no pudo relacionar uno o mas
            elementos válidos o existentes (comisiones, funciones, parametros, indicadores, variables).
            Contacta al equipo de tecnología.
        `
    }, 
    
    INVALID_INDICATOR_VARIABLE_KEY: {
        description: "Variables declaradas incorrectamente",
        reason: `
            Es posible que alguna variable no exista en la base de datos.
            Verifica los nombres de los tags de las variables y asegurate que contienen unicamente caracteres alfanuméricos. 
            Contacta al equipo de tecnología.
        `
    },
    INDICATOR_NOT_FOUND: {
        description: "Indicador no encontrado",
        reason: `
            No fue posible encontrar un indicador.
            Verifica la estructura de la comisión y asegurate de que todos los indicadores existan.
            Contacta al equipo de tecnología.
        `
    }, 
    HELPER_FUNCTION_REMOTE_ID_NOT_FOUND: {
        description: "Función no encontrada",
        reason: `
            No fue posible encontrar una función.
            Verifica la estructura de la comisión y asegurate de que todas los funciones existan.
            Contacta al equipo de tecnología.
        `
    },
    INVALID_SYNTAX: {
        description: "Sintaxis inválida",
        reason: `
            La sintaxis de la expresión es incorrecta.
            Verifica las expresiones que conforman la comisión variable (funciones, indicadores y comisión).
            Contacta al equipo de tecnología.
        `
    },
}
