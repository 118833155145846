import React, { useState, useRef, useEffect } from 'react';
import { Box, Container, Typography, InputAdornment, IconButton, LinearProgress, Grid, Button, TextField, CssBaseline } from "@material-ui/core";

import SearchIcon from '@material-ui/icons/Search';
// eslint-disable-next-line
import HomeIcon from '@material-ui/icons/Home';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'

// Amplify 
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import bamaLogo from '../../assets/images/watermark_color.png'
import awsconfig from '../../aws-exports';
import { listCustomers } from '../../graphql/queries';
import { updateCustomer, sendEmail, sendSms } from '../../graphql/mutations';
import { api, callApiPartial } from '../../api/api';
import { Alert, AlertTitle } from '@material-ui/lab';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../utils/SessionControl';

import { PhoneType } from '../../schema/enums/schemaEnums';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import StoreSelection from '../../components/customers/registration/StoreSelection';
import CardProfile from '../../components/customers/CardProfile';
import FingerprintDialog from '../../components/customers/registration/FingerprintDialog';

// const { ipcRenderer } = window.require("electron");

// Amplify init
Amplify.configure(awsconfig);

const useStyles = makeStyles(theme => ({
    backgroundGradient: {
        /*background: 'linear-gradient(45deg, #ffc10a55 30%, #17529C55 90%);',*/
        paddingTop: '1em',
        minHeight: '10vh'
    },
    rootFab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

const CustomerEnrollment = (props) => {
    const firstRender = useRef(true)
    const [searchText, setSearchText] = useState('')
    const [searching, setSearching] = useState(false)
    const [customer, setCustomer] = useState(null)
    const [open, setOpen] = React.useState(false);
    const [warehouse, setWarehouse] = React.useState(null);
    const [stores, setStores] = useState(null);
    const [storeSetted, setStoreSetted] = useState(false);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render

            // findWarehouse()

            return // skip the code below
        }
        const cleanup = () => { };

        if (Boolean(props.preregistrationCode) && !Boolean(searchText)) {
            setSearchText(props.preregistrationCode)
            getCustomer(props.preregistrationCode)
        }

        return cleanup();
    }, [props.preregistrationCode]) // Only re-run the effect if name or email change

    const classes = useStyles();

    // const findWarehouse = () => {
    //     ipcRenderer.send("rethinkdb-getObject-name", {
    //         table: "Warehouse",
    //         db: "bama",
    //         object: {},
    //         limit: null,
    //         orderBy: null
    //     });
    //     ipcRenderer.on("rethinkdb-getObject-Warehouse-reply", (event, arg) => {
    //         if (arg && arg.length) {
    //             console.log(arg[0])
    //             setWarehouse(arg[0]);
    //         }
    //         ipcRenderer.removeAllListeners("rethinkdb-getObject-Warehouse-reply")
    //     });
    //     return
    // }

    const getCustomer = (preregistrationCode) => {
        console.log(preregistrationCode);
        setSearching(true)
        resetCustomer();
        // console.log("getCustomer", preregistrationCode, props, searching)
        let _customerFilter = {
            filter: {
                preregistrationCode: {
                    eq: preregistrationCode.trim().toUpperCase()
                },
            },
            limit: 1000
        }

        let response = []
        callApiPartial(listCustomers, _customerFilter, response, (_response) => {
            // console.log(_response.length, searching, _response)
            if (_response.length > 0) {
                setCustomer(_response)
                setSearching(true);
            }
        }).then(res => {
            console.log("Customer: ",res);
            // console.log('then response', response)
            if (!customer) {
                setCustomer(res)
                setSearching(false)
            }
            setSearching(false)
        }).catch(err => {
            console.log(err);

            err && err.errors && err.errors.forEach(_error => {
                props.showMessage(_error.message, SNACKBAR_ERROR, 5000, null, null, null, null, null, null, props);
            })

            setSearching(false)
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdateCustomer = customer => new Promise((resolve, reject) => {
        api(updateCustomer, {
            input: {
                ...customer,
                customerZoneId: warehouse && warehouse.zone && warehouse.zone.id ? warehouse.zone.id : "618bdda8-a61e-4d91-b647-58acb3bb5d1a"
            }
        })
            .then(res => {
                setCustomer([res])
                resolve(res)
            }).catch(err => {
                reject(err)
            })
    })

    const confirmSuccessEnrollment = customer => new Promise((resolve, reject) => {
        props.showMessage(`${props.translate("Congratulations")}! ${customer.customerName}, ${props.translate("Fingerprint Enrollment Success")}`, SNACKBAR_SUCCESS, 5000, null, null, null, null, null, null, props);
        customer && customer.emails && customer.emails.forEach(e => {
            // Send email
            let email = {
                sender: "clubbama@bamaglobal.com.mx",
                recipient: e,
                subject: props.translate("Fingerprint Enrollment Success"),
                title: props.translate("Fingerprint Enrollment Success"),
                content: `
<div style="width: 100%">
<div style="text-align: right;">
${new Date(customer._lastChangedAt).toLocaleString()}
</div>
<div style="margin: 0 auto; text-align: center;">
<h2>
${customer.customerName}
</h2>
</div>
<div style="width: 100%">
${props.translate("fingerprintEnrollmentSuccess_emailLegend")}
</div>
</div>
`
            }

            api(sendEmail, { email: JSON.stringify(email) })
                .then(allTodos => {
                    console.log(allTodos.sendEmail)
                })
        })

        customer.phones.forEach(p => {
            if (p.phoneType === PhoneType.MOBILE) {
                let sms = {
                    message: `${props.translate("Fingerprint Enrollment Success")}`,
                    phone: p.phone
                }

                api(sendSms, { sms: JSON.stringify(sms) })
                    .then(allTodos => {
                        console.log(allTodos)
                    })
            }
        })

        resolve(true)
    })

    const resetCustomer = () => {
        setStoreSetted(false);
        setSearchText("");
        setCustomer(null)
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" style={{ minHeight: '80vh', minWidth: '-webkit-fill-available' }} className={classes.backgroundGradient}>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        {searching && <LinearProgress color="secondary" />}
                        <Box>
                            {!customer || (customer && customer.length === 0) ?
                                <Box display="flex" justifyContent="center" flexDirection="column" >
                                    <Box my={12}>
                                        <Typography gutterBottom>
                                            {props.translate("To complete regitry of Customer, plese enter the preregistration code")}
                                        </Typography>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            autoFocus
                                            disabled={searching}
                                            variant="outlined"
                                            onKeyDown={e => {
                                                if (e.key === "Enter") {
                                                    getCustomer(searchText);
                                                }
                                            }}
                                            onChange={(event) => {
                                                setSearchText(event.target.value)
                                            }}
                                            label={props.translate("Enter Preregistration Code")}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FontAwesomeIcon icon={faBarcode} />
                                                    </InputAdornment>),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            disabled={searching}
                                                            onClick={() => getCustomer(searchText)}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    {customer && customer.length === 0 ?
                                        <Alert severity={SNACKBAR_ERROR}>
                                            <AlertTitle>{props.translate("Error")}</AlertTitle>
                                            {props.translate("No results for this preregistration code")}
                                        </Alert>
                                        : ''}
                                </Box>
                                : <Box>
                                    {!searching && customer.map(c => {
                                        return <div>
                                            {c && c.store && storeSetted ?
                                                <Grid container
                                                    key={c.id}
                                                    spacing={3}
                                                    justify="center"
                                                >
                                                    <Grid item>
                                                        <CardProfile
                                                            {...props}
                                                            customer={c}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            /* disabled={c && c.fingers} */
                                                            disabled={c.fingers != undefined}
                                                            startIcon={<FingerprintIcon />}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={event => handleClickOpen(event)}
                                                        >
                                                            {props.translate("Add Fingerprint")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            startIcon={<SearchIcon />}
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => resetCustomer()}
                                                        >
                                                            {props.translate("Search New Preregistration Code")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {c && c.fingers && c.fingers.length >= 2 ?
                                                            <Alert severity={SNACKBAR_ERROR}>
                                                                <AlertTitle>{`${c.fingers.length} ${props.translate("Fingerprints registred")}`}</AlertTitle>
                                                                {props.translate("This Customer has already registered their fingerprints")}
                                                            </Alert>
                                                            : ''}
                                                    </Grid>
                                                    <Grid item>
                                                        <FingerprintDialog
                                                            {...props}
                                                            customer={c}
                                                            handleClose={handleClose}
                                                            resetCustomer={resetCustomer}
                                                            open={open}
                                                            confirmSuccessEnrollment={confirmSuccessEnrollment}
                                                            handleUpdateCustomer={handleUpdateCustomer}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                :
                                                <StoreSelection
                                                    API={API}
                                                    graphqlOperation={graphqlOperation}
                                                    showMessage={props.showMessage}
                                                    translate={props.translate}
                                                    customer={c}
                                                    stores={stores}
                                                    setStores={setStores}
                                                    setCustomer={setCustomer}
                                                    setStoreSetted={setStoreSetted}
                                                    bamaLogo={bamaLogo} />
                                            }
                                        </div>
                                    })}
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default withSnackbar(CustomerEnrollment);
