import { USER_PROFILE } from '../ActionTypes.js'

const defaultState = {
  user: {},
  profileFailedReason: ''
};

export default function UserProfileReducer(state = defaultState, action) {
  // console.log("UserProfileReducer", action);
  switch (action.type) {
    case USER_PROFILE.IN_PROGRESS:
      return {
        ...state,
        user: action.data
      };
    case USER_PROFILE.SUCCESS:
      return {
        ...state,
        /*userProfileSession: action.data.ProfileUserSession,*/
        user: action.data
      };
    case USER_PROFILE.ERROR:
      return {
        ...state,
        user: {},
        profileFailedReason: action
      };
    default:
      return state;
  }
}