import { takeLatest, all, call, put } from 'redux-saga/effects';
import { FETCH_EMPLOYEES } from './constants';
import { setEmployees, setEmployeesError } from './actions';
import API from '../../services/api';

export function* fetchEmployees(action) {
  const {
    payload: { id },
  } = action;
  try {
    if (id) {
      const response = yield call(API.getEmployees, id);
      const {
        data: { employee },
      } = response;

      employee.map(
        e =>
          (e.fullname =
            e.last_name + ' ' + e.second_last_name + ' ' + e.first_name),
      );

      yield put(setEmployees(employee));
    }
  } catch (error) {
    yield put(setEmployeesError(true));
  }
}

export default function* employeesSaga() {
  yield all([takeLatest(FETCH_EMPLOYEES, fetchEmployees)]);
}
