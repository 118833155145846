import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography ,Button,IconButton} from '@material-ui/core';
import PropTypes from 'prop-types';
import Label from '@material-ui/icons/Label';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ConstructorSelector from '../../../ConstructorSelector';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createSystemRole } from "../../../../graphql/mutations"
import { api, apiPartial } from "../../../../api/api";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../../../utils/SessionControl';


const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },

    
  }));


   function StyledTreeItem (props){
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;
    
    const handleItemClick = IdRol => event => {
       props.handleItemClick(IdRol)
      };
    
    return (
        <TreeItem
          label={
            <div className={classes.labelRoot}>
              <LabelIcon color="inherit" className={classes.labelIcon} />
              <Typography variant="body2" className={classes.labelText}>
                {labelText}
              </Typography>
              <Typography variant="caption" color="inherit">
                {labelInfo}
              </Typography>
               <Box>
              {props.Expanded?
              <IconButton 
              variant="contained" 
              color="primary" 
              onClick={handleItemClick(props.rol)}
              alignItems="rigth">
                  <AddIcon></AddIcon>
                  </IconButton>:""}
              </Box> 
            </div>
          }
          style={{
            '--tree-view-color': color,
            '--tree-view-bg-color': bgColor,
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            group: classes.group,
            label: classes.label,
          }}
          {...other}
        />
      );
    }
    
    StyledTreeItem.propTypes = {
      bgColor: PropTypes.string,
      color: PropTypes.string,
      labelIcon: PropTypes.elementType.isRequired,
      labelInfo: PropTypes.string,
      labelText: PropTypes.string.isRequired,
    };
    
    const useStyles = makeStyles({
      root: {
        height: 100,
        flexGrow: 1,
        maxWidth: 400,
      },
    });
    
    export default function SystemRole(props) {
      const classes = useStyles();
      const [rol, setRol] = React.useState(null);
      const [menus, setMenus] = React.useState([]);
      const [ItemRoot, setItemRoot] = React.useState({});
      const [itemChildren, setItemChildren] = React.useState([]);
      const [open, setOpen] = React.useState(false);
      const [selectMenu, setSelectMenu] = React.useState(null);
      const [valueNameRol, setValueNameRol] = useState('')

      const defaultProps = {
        options: menus,
        getOptionLabel: (option) => option.systemMenuName,
      };

      
      useEffect(() => {
        if (rol !== props.roles)
            buildRol(props.roles)

        if (menus !== props.menus)
            buildMenu(props.menus)
            
        return function cleanup() {
        };
    }, [props, props.roles,props.menus]) // Only re-run the effect if name or email change


    const buildRol = roles => {
        var root =dataRoot(roles)
        setItemRoot(root)
        setItemChildren(root.children)
    }

    const buildMenu = arraymenus => {
        setMenus(arraymenus)
        // arraymenus.forEach(_a => {
        //     console.log(_a)
        // })
    }

    ////////////////// FUNCTIONS //////////////////////////
    function dataRoot(roles) {
        var root = {}
        roles.forEach(_a => {
            if(_a.parentSystemRoleId === null){

                let Classfamily ={
                    parentSystemRoleId: _a.parentSystemRoleId,
                    systemRoleName: _a.systemRoleName,
                    id: _a.id,
                    children:dataFamily(_a,roles)
                  }
                  root = Classfamily
            }
        })
      
        return root;
      }

    function dataFamily(array,roles) {
      var _rowChildren = [];
        let init=0
        roles.forEach((_r) => {
            if (array.id === _r.parentSystemRoleId) {
              let Classfamily = {
                parentSystemRoleId: _r.parentSystemRoleId,
                systemRoleName: _r.systemRoleName,
                id: _r.id,
                node: init,
                children:dataFamilyChildren(_r,roles)
              };

              _rowChildren.push(Classfamily);

              init += 1;
            }
          });
      return _rowChildren;
    }

    function dataFamilyChildren(array,roles) {
        var _rowChildren = [];
          let init=0
          roles.forEach((_r) => {
              if (array.id === _r.parentSystemRoleId) {
                let Classfamily = {
                  parentSystemRoleId: _r.parentSystemRoleId,
                  systemRoleName: _r.systemRoleName,
                  id: _r.id,
                  node: init,
                  children:dataFamily(_r,roles)
                };
  
                _rowChildren.push(Classfamily);
                init += 1;
              }
            });
        return _rowChildren;
      }


      
      const handleItemClick = (Rol) =>{
         setOpen(true);
         setRol(Rol)
        // console.log(Rol)
       };
     
      const handleClose = () => {
        setOpen(false);
      };

      const handleCreateNewRol = () => {
        let params = {
          systemRoleName: valueNameRol,
          parentSystemRoleId: rol.id,
        };

        api(createSystemRole, { input: params })
          .then((res) => {
            console.log(res)
            setOpen(false);
            props.showMessage(props.translate("Se ha creado un nuevo rol"), SNACKBAR_SUCCESS, 7000);

          })
          .catch((err) => {
            console.log(err);
          });
    };


      return (
        <Box p={2}>
          <Typography variant="h6" color="textSecondary">
            {props.translate("System Roles")}
          </Typography>

          <TreeView
            defaultExpanded={["1"]}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
          >
            <StyledTreeItem
              nodeId="1"
              labelText={ItemRoot.systemRoleName}
              labelIcon={Label}
              Expanded={true}
              rol={ItemRoot}
              handleItemClick={handleItemClick}
            >
              {itemChildren.map((element, idx) => (
                <StyledTreeItem
                  nodeId={idx + 100}
                  labelText={element.systemRoleName}
                  labelIcon={Label}
                  Expanded={idx >= 0 ? true : false}
                  rol={element}
                  handleItemClick={handleItemClick}
                >
                   {element.children.map((element_two, idx_two) => (
                    <StyledTreeItem
                      style={{ paddingLeft: "20px" }}
                      nodeId={ idx_two + 1000}
                      labelText={element_two.systemRoleName}
                      labelIcon={Label}
                      Expanded={idx_two >= 0 ? true : false}
                      rol={element_two}
                      handleItemClick={handleItemClick}
                    >
                      {element_two.children.map((element_tree, idx_tree) => (
                        <StyledTreeItem
                          style={{ paddingLeft: "20px" }}
                          nodeId={idx_tree + 10000}
                          labelText={element_tree.systemRoleName}
                          labelIcon={Label}
                          Expanded={idx_tree >= 0 ? true : false}
                          rol={element_tree}
                          handleItemClick={handleItemClick}
                        >
                          {element_tree.children.map(
                            (element_four, idx_four) => (
                              <StyledTreeItem
                                style={{ paddingLeft: "20px" }}
                                nodeId={idx_four+ 100000}
                                labelText={element_four.systemRoleName}
                                labelIcon={Label}
                                Expanded={idx_four >= 0 ? true : false}
                                rol={element_four}
                                handleItemClick={handleItemClick}
                              ></StyledTreeItem>
                            )
                          )}
                        </StyledTreeItem>
                      ))}
                    </StyledTreeItem>
                  ))} 
                </StyledTreeItem>
              ))}
            </StyledTreeItem>
          </TreeView>

          <Dialog
            open={open}
            maxWidth="lg"
            fullWidth="fullWidth"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Crear nuevo rol</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Ingresa en nuevo nombre de rol y elige el menú que tendra tú
                nuevo rol.
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nombre de rol"
                    type="text"
                    fullWidth
                    onChange={(e) => setValueNameRol(e.target.value)}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <Autocomplete
                    {...defaultProps}
                    id="controlled-menu"
                    value={selectMenu}
                    onChange={(event, newValue) => {
                        setSelectMenu(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nombre de menu"
                        margin="dense"
                      />
                    )}
                  />
                </Grid> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleCreateNewRol} color="primary">
                Generar
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      );
}