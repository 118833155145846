import InitSearchVariable from './Variables/InitSearchVariable';

import { connect } from 'react-redux';

import {
    VariableCommissions_onOrganization,
    VariableCommissions_onVariable,
    VariableCommissions_onDataSource
} from '../redux/actions/VariableCommissionsAction';

const mapDispatchToProps = dispatch => ({
    VariableCommissions_onOrganization: data => {
        dispatch(VariableCommissions_onOrganization(data));
    },
    VariableCommissions_onVariable: data => {
        dispatch(VariableCommissions_onVariable(data));
    },
    VariableCommissions_onDataSource: data => {
        dispatch(VariableCommissions_onDataSource(data));
    },
});

const mapStateToProps = state => ({
    organization: state.organization,
    variable: state.variable,
    data_source: state.data_source
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitSearchVariable);

