import React, { Component } from 'react';
import { APIBAMASTAGE } from "../../../env/constants";
import MaterialTable from 'material-table';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import { tableESP } from '../../../helpers';
import { Button, Container, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalCreate from '../ModalCreate';
import Lead from '../../../components/Lead';
import { connect } from 'react-redux';
import SessionControlSupportMultiLanguaje from '../../../utils/SessionControl';

class ListaDataSource extends SessionControlSupportMultiLanguaje {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            idOrganization: '',
            dataSource: [],
            nameDataSource: '',
            breadCrumbs: [
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                { title: "Fuentes de Información", url: "/prometheus/variableCommissions/fuentes-de-informacion" },
                { title: "Lista", current: true },
            ],
            permision_read:false,
            permision_write:false,
            valorParameter: '',
            dateParameter: '',
            valoresParamaters: [],
            btnDis: true,
            lead: { show: false, type: "success", message: "", close: true }
        }
    }


    componentDidMount() {
        const { params } = this.props;
    
        let jsonParamProfile = JSON.parse(JSON.parse(this.getSecureStore().UserProfileReducer.user.paramProfile))
        //console.log(jsonParamProfile)
        //console.log(this.getSecureStore().UserProfileReducer.user.position)
        /// ** FILTRO POR PERMISO DE LECTURA Y ESCRITURA
        jsonParamProfile.filter(r => r.uuid === this.getSecureStore().UserProfileReducer.user.position.id).forEach(_e => {
            // console.log(_e.path_permissions)
            _e.path_permissions.forEach(_p => {
                if (_p.name === "DataSources") {
                    this.setState({
                        permision_read: _p.read,
                        permision_write: _p.write
                    });
                }
            });

        });

        this.setState({
            organization: this.getSecureStore().VariableCommissionsReducer.organization
        })

        fetch(`${APIBAMASTAGE}/organization/${params.organizationId}/data-source`)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataSource: data.data_source,
                    readyOrg: true,
                    loading: false,
                    idOrganization: params.organizationId
                });
            })
    }

    handleClose = () => {
        this.setState({ open: false });
    }



    handleOnUpdateValue = ( oldData , newData , name ) => {

        const { dataSource } = this.state;

        this.setState({
            ready: false,
            loading: true
        });

        let body = {
            name : `${name}`
        }

        fetch(`${APIBAMASTAGE}/data-source/${oldData.id}` , {method: 'PATCH', body: JSON.stringify(body) })
        .then(response => response.json())
        .then(data => { 
            let prov = dataSource;
            prov[prov.indexOf(oldData)] = newData;
            this.setState({
                dataSource: prov,
                ready: true,
                loading: false,
                lead: { show: true, type: "success", message: "Se modificó correctamente", close: false }
            });
        })
        .catch(error => { console.log(error)})
    }


    handleOnDeleteValue = ( oldData , areaId ) => {

        const { dataSource } = this.state;
    
        this.setState({
            ready: false,
            loading: true
        });

        let prov = dataSource;

        fetch(`${APIBAMASTAGE}/data-source/${areaId}` , {method: 'DELETE'})
        .then(response => response.json())
        .then(data => { 
            prov.splice(prov.indexOf(oldData) , 1);
            this.setState({
                dataSource: prov,
                loading: false,
                ready: true,
                lead: { show: true, type: "success", message: "Se eliminó correctamente", close: false }
            });
        })
        .catch(error => { console.log(error)});
    }




    //Modal Create

    handleChangeNameDataSoource = (e) => {
        this.setState({
            nameDataSource: e
        });
    }


    handleSaveDataSource = () => {

        const { idOrganization, nameDataSource, dataSource } = this.state;

        this.setState({
            loading: true,
            btnDis: false
        });

        let body = {
            name: `${nameDataSource}`,
        }

        fetch(`${APIBAMASTAGE}/organization/${idOrganization}/data-source` , { method: 'post' , body: JSON.stringify(body)})
            .then(response => response.json())
            .then(data => {

                let ar = dataSource;
                const obj = { id: data.data_source.id, name: data.data_source.name };

                ar.push(obj);

                this.setState({
                    open: false,
                    btnDis: true,
                    loading: false,
                    dataSource: ar,
                    lead: { show: true, type: "success", message: "Se creó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });
    }


    render() {

        const {
            breadCrumbs,
            btnDis,
            lead,
            loading,
            nameDataSource,
            open,
            dataSource,
            organization
        } = this.state;


        return (
            <Container fixed>
                <Lead
                    lead={lead}
                    handleClose={() => { this.setState({ lead: { show: false, type: "success", message: "", close: true } }) }}
                />

                <ModalCreate
                    open={open}
                    nameDataSource={nameDataSource}
                    loading={loading}
                    changeName={this.handleChangeNameDataSoource}
                    handleClose={this.handleClose}
                    saveDataSource={this.handleSaveDataSource}
                    btnDis={btnDis}
                    deleteRow={this.handleDeleteRow}
                ></ModalCreate>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs {...this.props}  navigation={breadCrumbs} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom>
                            {organization && organization.name}
                        </Typography>
                    </Grid>
                    <Grid
                        className={'btn-wrapper'}
                        item xs={6}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-start"
                    >
                        {this.state.permision_write ? <Button
                            className={'btn accept-btn'}
                            type="submit"
                            onClick={() => this.setState({ open: true, nameDataSource: '', btnDis: true })}
                        >
                            CREAR FUENTE DE INFORMACIÓN
                        </Button>: ""}
                    </Grid>
                    <Grid className={'btn-wrapper'} item xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : this.state.permision_write ? (
                            <MaterialTable
                                title='Fuentes de Información'
                                columns={[
                                    /*{ title: 'ID', field: 'id', editable: 'never' },*/
                                    { title: 'Nombre', field: 'name' },
                                ]}
                                data={dataSource}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.handleOnUpdateValue(oldData, newData, newData.name);
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.handleOnDeleteValue(oldData, oldData.id);
                                            }, 600);
                                        }),
                                }}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                                localization={tableESP}
                            />
                        ) : (
                            <MaterialTable
                                title='Fuentes de Información'
                                columns={[
                                    /*{ title: 'ID', field: 'id', editable: 'never' },*/
                                    { title: 'Nombre', field: 'name' },
                                ]}
                                data={dataSource}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                                localization={tableESP}
                            />
                        )}
                    </Grid>
                </Grid>
            </Container>
        );
    }

};


const mapStateToProps = state => ({
    history: state.router,
    navigation: state.navigation ? state.navigation.navigation : null
});

export default connect(mapStateToProps)(ListaDataSource);