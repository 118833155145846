import React from 'react';
import { Breadcrumbs as crumbs } from '@material-ui/core';
import { breadcrumbsStyle } from './style';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const UIBreadcrumbs = withStyles(breadcrumbsStyle)(crumbs);


function Breadcrumbs(props) {
    // let containerStyles = containerStyle();
    let { history, navigation } = props;

    /*
         let navigation = [
            { title: "Material-Ui", url: "/empleados" },
            { title: "Core", url: "/" },
            { title: "Breadcrumbs", current: true },
        ];

        <Breadcrumbs {...this.props}  navigation={navigation} />
     */

    let current = navigation.filter(e => e.current);
    let routes = navigation.filter(e => !("current" in e));

    return (
        <Box>
            <UIBreadcrumbs>
                {routes.map((e, i) => (
                    <Link color="inherit" key={i} onClick={evt => navigate(evt, e, history, props.navigate)} href={e.url}>{e.title}</Link>
                ))}
                <Typography color="textPrimary">{current[0].title}</Typography>
            </UIBreadcrumbs>
        </Box>
    )
}

function navigate(event, item, history, navigate) {
    console.log(item)
    event.preventDefault();
    navigate(item.url)
    // history.push(item.url);
}

export default Breadcrumbs;