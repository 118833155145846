import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';

// Bama Cloud Reducers
import UserSignInReducer from '../redux/reducers/UserSignInReducer'
import UserProfileReducer from '../redux/reducers/UserProfileReducer'
import LanguajeReducer from '../redux/reducers/LanguajeReducer';
import NavbarReducer from '../redux/reducers/NavbarReducer';
import VariableCommissionsReducer from '../redux/reducers/VariableCommissionsReducer';

// Variable Commissions 
import { reducer as indicatorReducer } from './indicator/reducers';
import { reducer as modalselectorReducer } from './modalSelector/reducers';
import { reducer as organizationsReducer } from './organizations/reducers';
import { reducer as areasReducer } from './areas/reducers';
import { reducer as datasourcesReducer } from './datasources/reducers';
import { reducer as areaIndicators } from './areaIndicators/reducers';
import { reducer as variablesReducer } from './variables/reducers';
import { reducer as parametersReducer } from './parameters/reducers';
import { reducer as functionsReducer } from './functions/reducers';
import { reducer as notificationReducer } from './notification/reducers';
import { reducer as kpiReducer } from './kpi/reducers';
import { reducer as employeesReducer } from './employees/reducers';
import { reducer as reportsReducer } from './reports/reducers';
import { reducer as createFunctionsReducer } from './createFunctions/reducers';
import { reducer as navigationReducer } from './navigation/reducers';
import { reducer as employeeCatalogReducer } from './employeeCatalog/reducers';
import { reducer as variabletagReducer } from './variablestag/reducers';

const createRootReducer = history =>
  combineReducers({
    router: history,
    modalselector: modalselectorReducer,
    organizations: organizationsReducer,
    areas: areasReducer,
    datasources: datasourcesReducer,
    areaIndicators: areaIndicators,
    indicator: indicatorReducer,
    variables: variablesReducer,
    parameters: parametersReducer,
    functions: functionsReducer,
    notification: notificationReducer,
    kpi: kpiReducer,
    employees: employeesReducer,
    reports: reportsReducer,
    createFunction: createFunctionsReducer,
    navigation: navigationReducer,
    employeeCatalog: employeeCatalogReducer,
    variabletag: variabletagReducer,

    UserSignInReducer,
    UserProfileReducer,
    LanguajeReducer,
    NavbarReducer,
    VariableCommissionsReducer
  });

export default createRootReducer;
