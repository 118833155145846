import { persistReducer } from 'redux-persist'
import { applyMiddleware, compose, createStore } from 'redux'
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

const loggerMiddleware = createLogger({ predicate: () => false })
const initialState = {}

export default (rootReducer, rootSaga) => {
  const persistedReducer = persistReducer({ key: 'root', storage, blacklist: ['filter', 'modals'] }, rootReducer(history))
  const sagaMiddleware = createSagaMiddleware();
  // const historyMiddleware = routerMiddleware(history);

  const middlewares = [sagaMiddleware, thunk, loggerMiddleware/*, historyMiddleware*/];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(persistedReducer, initialState, composedEnhancers)

  sagaMiddleware.run(rootSaga);

  return store;
};