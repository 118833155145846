import React, { Component } from 'react';
import { APIBAMASTAGE } from '../../env/constants';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Lista from './Lista';
import ModalCreate from './ModalCreate';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumbs from '../../components/Breadcrumbs';
import Lead from '../../components/Lead';
import { Container } from '@material-ui/core';
import SessionControl from '../../utils/SessionControl';

export class Organizacion extends SessionControl {

  constructor(props) {
    console.log(APIBAMASTAGE)
    super(props);
    this.state = {
      open: false,
      user: this.getSecureStore().UserProfileReducer &&
            this.getSecureStore().UserProfileReducer.user
            ? this.getSecureStore().UserProfileReducer.user
            : [],
      organizations: [],
      ready: false,
      loading: false,
      permision_read:false,
      permision_write:false,
      btnAct: true,
      breadCrumbs: [
        { title: "Dashboard", url: "/prometheus/variableCommissions" },
        { title: "Empresas", current: true },
      ],
      lead: { show: false, type: "success", message: "", close: true }
    }
  }

  componentDidMount() {
    const { paramProfile, position } = this.state.user;
    let listPermisionOrganization = JSON.parse(JSON.parse(paramProfile))
    fetch(`${APIBAMASTAGE}/organization`)
      .then(response => response.json())
      .then(data => {
        let listOrganization =[]
        /// ** FILTRO POR EMPRESA QUE NO PUEDA ACCEDER
        data && data.organization.forEach(organization => {
          listPermisionOrganization.filter(r=> r.uuid === position.id).forEach(_e => {
           // console.log(_e.path_permissions)
            _e.path_permissions.forEach(_p => {
              if(_p.name === "Business"){
                this.setState({
                  permision_read: _p.read,
                  permision_write: _p.write
                });
              }
            });

            _e.organization.forEach(_a => {
              if(_a.uuid === organization.id){
                listOrganization.push(organization)
              }
            });
            
          });
        });

        this.setState({
          organizations: listOrganization,
          ready: true
        });
      })
      .catch(error => { console.log(error) });

  }


  render() {
    const { open, organizations, ready, btnAct, loading, breadCrumbs, lead } = this.state;
    const handleClose = () => {
      this.setState({
        open: false
      });
    }

    const sendForm = (name) => {

      let body = {
        name: `${name}`
      }
      let orgProv = organizations;

      this.setState({ btnAct: false, loading: true });

      fetch(`${APIBAMASTAGE}/organization`, { method: 'post', body: JSON.stringify(body) })
        .then(response => response.json())
        .then(data => {
          orgProv.push(data.organization);
          this.setState({
            ...this.state,
            open: false,
            organizations: orgProv,
            btnAct: true,
            loading: false,
            lead: { show: true, type: "success", message: "Se creó correctamente", close: false }
          });
        })
        .catch(error => { console.log(error) });

    }

    const handleOnUpdateValue = (oldData, newData, name) => {

      this.setState({
        ready: false
      });

      let body = {
        name: `${name}`
      }

      let prov = organizations;
      fetch(`${APIBAMASTAGE}/organization/${oldData.id}`, { method: 'PATCH', body: JSON.stringify(body) })
        .then(response => response.json())
        .then(() => {
          prov[prov.indexOf(oldData)] = newData;
          this.setState({
            organizations: prov,
            ready: true,
            lead: { show: true, type: "success", message: "Se modificó correctamente", close: false }
          });
        })
        .catch(error => { console.log(error) })
    }


    const handleOnDeleteValue = (oldData, organizationId) => {

      this.setState({
        ready: false
      });

      let prov = organizations;

      fetch(`${APIBAMASTAGE}/organization/${organizationId}`, { method: 'DELETE' })
        .then(response => response.json())
        .then(data => {
          prov.splice(prov.indexOf(oldData), 1);
          this.setState({
            organizations: prov,
            ready: true,
            lead: { show: true, type: "success", message: "Se eliminó correctamente", close: false }
          });
        })
        .catch(error => { console.log(error) });
    }

    const ListaOrg = () => {


      if (ready) {
        return (
          <Lista
            data={organizations}
            edit ={this.state.permision_write}
            user={this.state.user}
            onUpdateValue={handleOnUpdateValue}
            onDeleteValue={handleOnDeleteValue}
          ></Lista>
        );
      } else {
        return (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '50vh' }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>

          </Grid>
        );
      }
    }


    return (
      <Container fixed>
        <Lead
          lead={lead}
          handleClose={() => { this.setState({ lead: { show: false, type: "success", message: "", close: true } }) }}
        />
        <ModalCreate
          open={open}
          loading={loading}
          sendForm={sendForm}
          onCloseModal={handleClose}
          btnAct={btnAct}>
        </ModalCreate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs {...this.props} navigation={breadCrumbs} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {/*<strong>EMPRESAS REGISTRADAS</strong>*/}
            </Typography>
          </Grid>
          <Grid
            className={'btn-wrapper'}
            item xs={6}
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
          >
            {this.state.permision_write ? <Button
              className={'btn accept-btn'}
              type="submit"
              onClick={() => this.setState({ ...this.state, open: true })}
            >
              CREAR NUEVA EMPRESA
            </Button> : ""}
          </Grid>
          <Grid item xs={12}>
            <ListaOrg></ListaOrg>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Organizacion;
