import React from 'react';
import MaterialTable from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import { tableESP } from '../../helpers';

const CVTable = ({columns, data, actions, title, onUpdateValue, onDeleteValue , edit , onEdit }) => {

    return (
        <div>
            {
                edit ?
                    onEdit ? <MaterialTable
                        title={title}
                        columns={columns}
                        data={data}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataUpdate = [...data];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        onUpdateValue(newData, index)
                                        resolve();
                                    }, 1000)
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const index = oldData.tableData.id;
                                        onDeleteValue(index, oldData);
                                        resolve()
                                    }, 1000)
                                }),
                        }}
                        options={{
                            actionsColumnIndex: -1
                        }}
                        actions={actions}
                        localization={tableESP}
                    /> : <MaterialTable
                        title={title}
                        columns={columns}
                        data={data}
                        options={{
                            actionsColumnIndex: -1
                        }}
                        actions={actions}
                        localization={tableESP}
                    /> :
                    onEdit ? <MaterialTable
                        title={title}
                        columns={columns}
                        data={data}
                        editable={{
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const index = oldData.tableData.id;
                                        onDeleteValue(index, oldData);
                                        resolve()
                                    }, 1000)
                                }),
                        }}
                        options={{
                            actionsColumnIndex: -1
                        }}
                        actions={actions}
                        localization={tableESP}
                    /> : <MaterialTable
                        title={title}
                        columns={columns}
                        data={data}
                        options={{
                            actionsColumnIndex: -1
                        }}
                        actions={actions}
                        localization={tableESP}
                    />
            }            
        </div>
    );
};

CVTable.propTypes = {

};

CVTable.defaultProps = {
    columns: [],
    data: [],
    title: '',
    actions:[],
    edit: true
};


export default CVTable;