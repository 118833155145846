export const SET_KPI_ORGANIZATION = 'kpi/SET_KPI_ORGANIZATION';
export const SET_KPI_AREA = 'kpi/SET_KPI_AREA';
export const SET_KPI_TIME = 'kpi/SET_KPI_TIME';
export const SET_KPI_NAME = 'kpi/SET_KPI_NAME';
export const CREATE_KPI_STEP = 'kpi/CREATE_KPI_STEP';
export const ADD_KPI_ELEMENT = 'kpi/ADD_KPI_ELEMENT';
export const PUSH_ELEMENT_KPI_STEP = 'kpi/PUSH_ELEMENT_KPI_STEP';
export const PUSH_ELEMENT_KPI_DICTIONARY = 'kpi/PUSH_ELEMENT_KPI_DICTIONARY';
export const TEST_KPI_EXPRESSION = 'kpi/TEST_KPI_EXPRESSION';
export const SAVE_KPI = 'kpi/SAVE_KPI';
export const SET_TEST_KPI = 'kpi/SET_TEST_KPI';
export const SET_SAVE_KPI = 'kpi/SET_SAVE_KPI';
export const RESET_KPI_DATA = 'kpi/RESET_KPI_DATA';
export const ADD_KPI_FUNCTION_ELEMENT = 'kpi/ADD_KPI_FUNCTION_ELEMENT';
export const PUSH_FUNCTION_ELEMENT_KPI = 'kpi/PUSH_FUNCTION_ELEMENT_KPI';
export const DICTIONARY = 'dictionary';
export const SET_LAST_FUNCTION_KPI_VALIDATION =
  'kpi/SET_LAST_FUNCTION_KPI_VALIDATION';
export const SET_KPI_SENDING = 'kpi/SET_KPI_SENDING';
export const DELETE_KPI_ELEMENT = 'kpi/DELETE_KPI_ELEMENT';
export const POP_ELEMENT_DICTIONARY_KPI = 'kpi/POP_ELEMENT_DICTIONARY_KPI';
export const POP_ALL_FUNCTION_ARGUMENTS_KPI_DICTIONARY =
  'kpi/POP_ALL_FUNCTION_ARGUMENTS_KPI_DICTIONARY';
export const POP_ELEMENT_KPI = 'kpi/POP_ELEMENT_KPI';
export const POP_FUNCTION_ARGUMENT_ELEMENT_KPI =
  'kpi/POP_FUNCTION_ARGUMENT_ELEMENT_KPI';
export const EDIT_KPI_ELEMENT = 'kpi/EDIT_KPI_ELEMENT';
export const REPLACE_KPI_ELEMENT = 'kpi/REPLACE_KPI_ELEMENT';
export const EDIT_KPI = 'kpi/EDIT_KPI';
export const REHYDRATE_KPI = 'kpi/REHYDRATE_KPI';
export const RESET_STEPS_KPI = 'kpi/RESET_STEPS_KPI';
export const EDIT_SAVE_KPI = 'kpi/EDIT_SAVE_KPI';
export const DELETE_KPI = 'kpi/DELETE_KPI';
export const SET_ACTIVE_KPI = 'kpi/SET_ACTIVE_KPI';
export const SET_KPI_DELETING = 'kpi/SET_KPI_DELETING';
export const EDIT_KPI_FUNCTION_ELEMENT = 'kpi/EDIT_KPI_FUNCTION_ELEMENT';
export const REPLACE_FUNCTION_ARGUMENT_ELEMENT_KPI =
  'kpi/REPLACE_FUNCTION_ARGUMENT_ELEMENT_KPI';
export const SET_NO_EXIST_IDS_KPI = 'kpi/SET_NO_EXIST_IDS_KPI';
