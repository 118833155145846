import React, { Component } from 'react';
import { connect , useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Modal, { Select } from '../../../components/Modal';
import { history } from '../../../store/createStore'
import {
  getReportsOrganization,
  getReportsArea,
  getReportsEmployee,
} from '../../../store/reports/selectors';

import {
  setReportsOrganization,
  setReportsArea,
  setReportsEmployee,
} from '../../../store/reports/actions';

import { fetchOrganizations, setOrganizations } from '../../../store/organizations/actions';
import { fetchAreas, setAreas } from '../../../store/areas/actions';
import { fetchEmployees, setEmployees } from '../../../store/employees/actions';
import { fetchReportsEmployee, resetReporsEmployeeData } from '../../../store/reports/actions';
import {
  isOrganizationsloading,
  getOrganizationsdata,
} from '../../../store/organizations/selectors';
import SessionControlSupportMultiLanguaje from '../../../utils/SessionControl';


export class InitReport extends SessionControlSupportMultiLanguaje {
  constructor(props){
    super(props);
    props.resetReporsEmployeeData();
    props.fetchOrganizations();
  }
  componentDidMount = () => {}
  hasReportData = () => {
    let { organization } = this.props;
    return organization 
  }
  render() {
    const { organizations } = this.props;
    const { paramProfile, position } = this.getSecureStore().UserProfileReducer.user;
    let listPermisionOrganization = JSON.parse(JSON.parse(paramProfile))
    let listOrganization = []
    /// ** FILTRO POR EMPRESA QUE NO PUEDA ACCEDER
    organizations && organizations.data.forEach(organization => {
      listPermisionOrganization.filter(r => r.uuid === position.id).forEach(_e => {

        _e.organization.forEach(_a => {
          if (_a.uuid === organization.id) {
            listOrganization.push(organization)
          }
        });

      });
    });

    organizations.data = listOrganization

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs
            navigation={[
              { title: "Dashboard", url: "/prometheus/variableCommissions" },
              {
                title: 'Buscar reportes',
                current: true,
              },
            ]}
          />
        </Grid>
        <Modal
          open={true}
          isContinueDisabled={!this.hasReportData()}
          title="Selecciona la estructura para filtrar reportes"
          onContinue={event => {
            let {organization} = this.props;
            this.props.navigate(`/prometheus/variableCommissions/reportes/${organization.id}`)
          }}
          onCancel={event => history.goBack()}
        >
          <Select
            loading={organizations.loading}
            label="Empresa"
            elements={organizations.data}
            onChange={value => {
              this.props.setReportsOrganization(value)
            }}
          />          
        </Modal>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  organization: getReportsOrganization(state),  
  organizations: {
    loading: isOrganizationsloading(state),
    data: getOrganizationsdata(state)
  }  
});

const mapDispatchToProps = dispatch => ({
  fetchReportsEmployee: payload => dispatch(fetchReportsEmployee(payload)),
  resetReporsEmployeeData: () => dispatch(resetReporsEmployeeData()),
  fetchOrganizations: () => dispatch(fetchOrganizations()),  
  fetchEmployees: payload => dispatch(fetchEmployees(payload)),
  fetchAreas: payload => dispatch(fetchAreas(payload)),
  setAreas: payload => dispatch(setAreas(payload)),
  setOrganizations: payload => dispatch(setOrganizations(payload)),
  setEmployees: payload => dispatch(setEmployees(payload)),
  setReportsArea: payload => dispatch(setReportsArea(payload)),
  setReportsEmployee: payload => dispatch(setReportsEmployee(payload)),
  setReportsOrganization: payload => dispatch(setReportsOrganization(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InitReport);
