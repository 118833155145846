import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Button,
  ButtonGroup,
  Icon,
  MenuItem,
  MenuList,
  InputLabel,
  Select,
  Grow,
  Popper,
  FormControl
} from "@material-ui/core";

import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { api, apiPartial } from "../../../api/api";
import { listWorkTeamTemplates,listZones } from '../../../graphql/queries'
import { updateWarehouse } from '../../../graphql/mutations'
import { SNACKBAR_SUCCESS,SNACKBAR_WARNING } from '../../../utils/SessionControl';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button_add: {
    alignItems: "center",
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

export default function SystemWarehouse(props) {
  const classes = useStyles();
  const firstRender = useRef(true);
  const anchorRefZone = React.useRef(null);
  const anchorRefWorkTeam = React.useRef(null);

  const [inputFields, setInputFields] = useState([{ turnOne: "", turnTwo: "" ,turnTree: "" }]);
  const [inputFieldsFolio, setInputFieldsFolio] = useState([{ FolioOne: ""}]);

  const [editWarehouse, setEditWarehouse] = useState({ error: null });
  const [settingFortia, setSettingFortia] = useState(null);
  const [registers, setRegisters] = useState(null);
  const [settingCluster, setSettingCluster] = useState(null);
  const [settingSync, setSettingSync] = useState({
    param: "sync",
    value: { millisecondsToSync: "1000*60*15" },
  });
  const [settingCash, setSettingCash] = useState(null);
  const [settingHostName, setSettingHostName] = useState(null);
  const [settingAfiliacion, setSettingAfiliacion] = useState(null);
  const [settingSocketConnection, setSettingSocketConnection] = useState(null);
  const [settingTerminal, setSettingTerminal] = useState(null);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openZone, setOpenZone] = React.useState(false);
  const [optionsZone, setoptionsZone] = useState([
    { zoneId: "1", zoneName: "1" },
  ]);

  const [selectedIndexWorkTeam, setSelectedIndexWorkTeam] = React.useState(0);
  const [openWorkTeam, setOpenWorkTeam] = React.useState(false);
  const [optionsTeamTemplates, setoptionsTeamTemplates] = useState([
    { workTeamTemplate: "1", id: "1" },
  ]);


  useEffect(() => {
    if (editWarehouse !== props.warehouse) {
      buildWarehouse(props.warehouse);
      // setSettingFortia({
      //   ...registers,
      //   amex:false,
      //   applicationId: "8c678668c897df37c2ebe29d03cc",
      //   bines: "",
      //   contactless: false,
      //   moto: false,
      //   secretKey:"5921-e11c-4107-9813-4a9e72c0",
      // });
     
      if (firstRender.current) {
        if (firstRender.current) {
          firstRender.current = false; // it's no longer the first render
  
          loadWorkTeamTemplates();
          return; // skip the code below
        }
      }
    }

    return function cleanup() {};
  }, [props.warehouse]); // Only re-run the effect if name or email change

  const loadWorkTeamTemplates = () => {
    api(listWorkTeamTemplates, { limit: 300 })
      .then((res) => {
        setoptionsTeamTemplates(res);
        res.forEach((_e,i )=> {
          if (props.warehouse.workTeamTemplate !== null){
            if (props.warehouse.workTeamTemplate.id === _e.id && _e.workTeamTemplate === props.warehouse.workTeamTemplate.workTeamTemplate){
               setSelectedIndexWorkTeam(i)
            }
          }
        });
        loadZones();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadZones = () => {
    api(listZones, { limit: 300 })
      .then((res) => {
        if (props.warehouse.organization.organizationId === 1) {
          let totalFilter = [];
          let itemFilter = res.filter((ware) => ware.zoneType === "warehouse");
          itemFilter.forEach((element) => {
            if (element.zoneId !== "4") {
              totalFilter.push(element);
            }
          });
          setoptionsZone(totalFilter);
        } else {
          let itemFilter = res.filter((ware) => ware.zoneType === "warehouse");
          itemFilter.forEach((element) => {
            if (element.zoneId === "4") {
              setoptionsZone([element]);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const buildWarehouse = (w) => {
    // setEditWarehouse(warehouse)
    setEditWarehouse({
      ...editWarehouse,
      warehouse: w,
      error: false,
    });

    if (w.settings !== null) {
      w.settings.forEach((_e) => {
        if (_e.param === "fortia_erp") {
          setSettingFortia(JSON.parse(_e.value));
        } else if (_e.param === "cluster") {
          setSettingCluster(JSON.parse(_e.value));
        } else if (_e.param === "sync") {
          setSettingSync(JSON.parse(_e.value));
        } else if (_e.param === "cash") {
          setSettingCash(JSON.parse(_e.value));
        }
      });
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpenZone(false);
  };

  const handleToggle = () => {
    setOpenZone((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRefZone.current && anchorRefZone.current.contains(event.target)) {
      return;
    }
    setOpenZone(false);
  };

  const handleMenuItemClickWorkTeam = (event, index) => {
    setSelectedIndexWorkTeam(index);
    setOpenWorkTeam(false);
  };

  const handleToggleWorkTeam = () => {
    setOpenWorkTeam((prevOpen) => !prevOpen);
  };

  const handleCloseWorkTeam = (event) => {
    if (anchorRefWorkTeam.current && anchorRefWorkTeam.current.contains(event.target)) {
      return;
    }
    setOpenWorkTeam(false);
  };

  const handleClick = () => {
    //  console.log(`You clicked ${options[selectedIndex]}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

   // if (settingFortia && settingSocketConnection && settingFortia.FolMovto && settingSocketConnection.timeout){

      try {
        const options = inputFields.map(function (row) {
          return { 1: row.turnOne, 2: row.turnTwo, 3: row.turnTree };
        });
    
        let Folio = {};
        if (options.length === 1) {
          Folio = {
            101: options[0],
          };
        } else if (options.length === 2) {
          Folio = {
            101: options[0],
            102: options[1],
          };
        } else if (options.length === 3) {
          Folio = {
            101: options[0],
            102: options[1],
            103: options[2],
          };
        }

        let FolioDocCte = {};
        if (inputFieldsFolio.length === 1) {
          FolioDocCte = {
            101: inputFieldsFolio[0].FolioOne,
          };
        } else if (inputFieldsFolio.length === 2) {
          FolioDocCte = {
            101: inputFieldsFolio[0].FolioOne,
            102: inputFieldsFolio[1].FolioOne,
          };
        } else if (inputFieldsFolio.length === 3) {
          FolioDocCte = {
            101: inputFieldsFolio[0].FolioOne,
            102: inputFieldsFolio[1].FolioOne,
            103: inputFieldsFolio[2].FolioOne,
          };
        }
    
        let customFortia = {
          identificators: {
            FolCorteCaja: Folio,
            FolMovto: settingFortia.FolMovto,
            FolioDocCte: FolioDocCte,//settingFortia.FolioDocCte,
            FolioKarInv: settingFortia.FolioKarInv,
            FolioPed: settingFortia.FolioPed,
            IdKarInv: settingFortia.IdKarInv,
            IdPedido: settingFortia.IdPedido,
            IdPedidoDet: settingFortia.IdPedidoDet,
          },
          organizationId: settingFortia.organizationId,
          state_code: settingFortia.state_code,
          params: {
            SerieFiscal: [
              {
                Factura: settingFortia.Factura,
                TipoSerie: 10,
              },
              {
                ["Nota de Crédito"]: settingFortia.Credito,
                TipoSerie: 20,
              },
              {
                Pago: settingFortia.Pago,
                TipoSerie: 30,
              },
            ],
          },
          register:[{
            hostnameId:settingHostName.hostnameId,
            pinpad:{
              afiliacion:settingAfiliacion
            },
            terminal:settingTerminal,
            socket_connection:settingSocketConnection,
            amex:false,
            applicationId: "8c678668c897df37c2ebe29d03cc",
            bines: "",
            contactless: false,
            moto: false,
            secretKey:"5921-e11c-4107-9813-4a9e72c0",
            warranty: true
          }]
        };
    
       console.log(customFortia)
        
       // let item =optionsZone[selectedIndex]
    
    
        let customWarehouse = {
          // address: editWarehouse.warehouse.address,
          // headcountReport: editWarehouse.warehouse.headcountReport,
          id: editWarehouse.warehouse.id,
          // incidents: editWarehouse.warehouse.incidents,
          // kardexs: editWarehouse.warehouse.kardexs,
          // location: editWarehouse.warehouse.location,
          // orders: editWarehouse.warehouse.orders,
          // organization: editWarehouse.warehouse.organization,
          // phones: editWarehouse.warehouse.phones,
          // productKits: editWarehouse.warehouse.productKits,
          // sales: editWarehouse.warehouse.sales,
          settings: [
            { param: "fortia_erp", value: JSON.stringify(customFortia) },
            { param: "cluster", value: JSON.stringify(settingCluster) },
            { param: "sync", value: JSON.stringify(settingSync) },
            { param: "cash", value: JSON.stringify(settingCash) },
          ],
          // tableData: editWarehouse.warehouse.tableData,
          // taxpayer: editWarehouse.warehouse.taxpayer,
          // warehouseId: editWarehouse.warehouse.warehouseId,
          // warehouseName: editWarehouse.warehouse.warehouseName,
          // warehouseRelatedId: editWarehouse.warehouse.warehouseRelatedId,
          // warehouseType: editWarehouse.warehouse.warehouseType,
          // workTeamTemplate: editWarehouse.warehouse.workTeamTemplate,
          // zone: optionsZone[selectedIndex],
        };
        console.log(customWarehouse);
        api(updateWarehouse, { input: customWarehouse })
        .then((res) => {
         console.log(res)
        })
        .catch((err) => {
          console.log(err);
        });
     // }
        
      } catch (error) {
        console.log(error)
        props.showMessage(` ${"Verifica campos requeridos."}`, SNACKBAR_WARNING, 5000, null, null, null, null, null, " ", props);
      }


     
  
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { turnOne: "", turnTwo: "" ,turnTree: "" }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };
//////////////////////// BOX  FOLIO ////////
  const handleChangeInputFolio = (index, event) => {
    const values = [...inputFieldsFolio];
    values[index][event.target.name] = event.target.value;
    setInputFieldsFolio(values);
  };

  const handleAddFieldsFolio = () => {
    setInputFieldsFolio([...inputFieldsFolio, { FolioOne: ""}]);
  };

  const handleRemoveFieldsFolio = (index) => {
    const values = [...inputFieldsFolio];
    values.splice(index, 1);
    setInputFieldsFolio(values);
  };

 // console.log(editWarehouse);

  return (
    <Box p={8}>
      <Typography variant="h6" color="textSecondary">
        {props.translate("Store Config")}
      </Typography>

      {editWarehouse.warehouse &&
      editWarehouse.warehouse.settings &&
      !editWarehouse.error ? (
        <Box>
          {editWarehouse.warehouse.settings.map((s) => {
            if (s.param === "fortia_erp")
              return (
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      <TextField
                        autoFocus
                        key="organizationId"
                        name="organizationId"
                        label={"Id de Organización según ERP"}
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        defaultValue={parseInt(settingFortia.organizationId)}
                        error="Id de Organización debe ser mayor a 0"
                        onChange={(organizationId) => {
                          setSettingFortia({
                            ...settingFortia,
                            organizationId: parseInt(
                              organizationId.target.value
                            ),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      <TextField
                        autoFocus
                        key="state_code"
                        name="state_code"
                        label={"Código de Estado (Veracruz = 30)"}
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        defaultValue={settingFortia.state_code}
                        error
                        onChange={(state_code) => {
                          setSettingFortia({
                            ...settingFortia,
                            state_code: parseInt(state_code.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      <TextField
                        autoFocus
                        key="zona"
                        name="zona"
                        label={"Código de Zona"}
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        defaultValue={settingFortia.zona}
                        error
                        onChange={(zona) => {
                          setSettingFortia({
                            ...settingFortia,
                            zona: parseInt(zona.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}>
                      {Object.keys(
                        settingFortia.identificators.FolCorteCaja
                      ).map((t, i) => {
                        return Object.keys(t).map((r) => {
                          return (
                            <Box p={1} key={i}>
                              <TextField
                                mt={3}
                                autoFocus
                                key="FolCorteCaja"
                                name="FolCorteCaja"
                                label={`FolCorte Caja - ${i + 1} Turno - ${
                                  Number(r) + 1
                                }`}
                                variant="outlined"
                                required
                                defaultValue={
                                  settingFortia.identificators.FolCorteCaja[t][
                                    Number(r) + 1
                                  ]
                                }
                                fullWidth
                                type="number"
                                error
                                onChange={(FolCorteCaja) => {
                                  settingFortia.identificators.FolCorteCaja[t][
                                    Number(r) + 1
                                  ] = parseInt(FolCorteCaja.target.value);
                                }}
                              />
                              {/* {i > 0 ? (
                            <Button
                              color="primary"
                              onClick={() => handleRemoveFields(i)}
                            >
                              <RemoveIcon />
                            </Button>
                          ) : (
                            ""
                          )}
                          <IconButton onClick={() => handleAddFields()}>
                            <AddIcon color="primary" />
                          </IconButton> */}
                            </Box>
                          );
                        });
                      })}
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper elevation={0} className={classes.paper}>
                      {" "}
                      <TextField
                        error
                        name="FolMovto"
                        label={`FolMovto`}
                        variant="outlined"
                        defaultValue={settingFortia.identificators.FolMovto}
                        onChange={(FolMovto) => {
                          setSettingFortia({
                            ...settingFortia,
                            FolMovto: parseInt(FolMovto.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper elevation={0} className={classes.paper}>
                      <TextField
                        error
                        name="FolioDocCte"
                        label={`FolioDocCte`}
                        variant="outlined"
                        defaultValue={settingFortia.identificators.FolioDocCte}
                        onChange={(FolioDocCte) => {
                          setSettingFortia({
                            ...settingFortia,
                            FolioDocCte: parseInt(FolioDocCte.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper elevation={0} className={classes.paper}>
                      {" "}
                      <TextField
                        error
                        name="FolioKarInv"
                        label={`FolioKarInv`}
                        variant="outlined"
                        defaultValue={settingFortia.identificators.FolioKarInv}
                        onChange={(FolioKarInv) => {
                          setSettingFortia({
                            ...settingFortia,
                            FolioKarInv: parseInt(FolioKarInv.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper elevation={0} className={classes.paper}>
                      {" "}
                      <TextField
                        error
                        name="FolioPed"
                        label={`FolioPed`}
                        variant="outlined"
                        defaultValue={settingFortia.identificators.FolioPed}
                        onChange={(FolioPed) => {
                          setSettingFortia({
                            ...settingFortia,
                            FolioPed: parseInt(FolioPed.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      {" "}
                      <TextField
                        error
                        name="IdKarInv"
                        label={`IdKarInv`}
                        variant="outlined"
                        defaultValue={settingFortia.identificators.IdKarInv}
                        onChange={(IdKarInv) => {
                          setSettingFortia({
                            ...settingFortia,
                            IdKarInv: parseInt(IdKarInv.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      {" "}
                      <TextField
                        error
                        name="IdPedido"
                        label={`IdPedido`}
                        variant="outlined"
                        defaultValue={settingFortia.identificators.IdPedido}
                        onChange={(IdPedido) => {
                          setSettingFortia({
                            ...settingFortia,
                            IdPedido: parseInt(IdPedido.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      {" "}
                      <TextField
                        error
                        name="IdPedidoDet"
                        label={`IdPedidoDet`}
                        variant="outlined"
                        defaultValue={settingFortia.identificators.IdPedidoDet}
                        onChange={(IdPedidoDet) => {
                          setSettingFortia({
                            ...settingFortia,
                            IdPedidoDet: parseInt(IdPedidoDet.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      {" "}
                      <TextField
                        autoFocus
                        key="Factura"
                        name="Factura"
                        label={"Factura"}
                        variant="outlined"
                        defaultValue={
                          settingFortia.params.SerieFiscal[0].Factura
                        }
                        required
                        fullWidth
                        error
                        onChange={(Factura) => {
                          setSettingFortia({
                            ...settingFortia,
                            Factura: parseInt(Factura.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      {" "}
                      <TextField
                        autoFocus
                        key="Credito"
                        name="Nota de Crédito"
                        label={"Nota de Crédito"}
                        variant="outlined"
                        defaultValue={
                          settingFortia.params.SerieFiscal[1]["Nota de Crédito"]
                        }
                        required
                        fullWidth
                        error
                        onChange={(Credito) => {
                          setSettingFortia({
                            ...settingFortia,
                            Credito: parseInt(Credito.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      {" "}
                      <TextField
                        autoFocus
                        key="Pago"
                        name="Pago"
                        label={"Pago"}
                        variant="outlined"
                        defaultValue={settingFortia.params.SerieFiscal[2].Pago}
                        required
                        fullWidth
                        error
                        onChange={(Pago) => {
                          setSettingFortia({
                            ...settingFortia,
                            Pago: parseInt(Pago.target.value),
                          });
                        }}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              );
          })}
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              <TextField
                autoFocus
                key="organizationId"
                name="organizationId"
                label={"Id de Organización según ERP"}
                variant="outlined"
                required
                fullWidth
                type="number"
                error="Id de Organización debe ser mayor a 0"
                onChange={(organizationId) => {
                  setSettingFortia({
                    ...settingFortia,
                    organizationId: parseInt(organizationId.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              <TextField
                key="state_code"
                name="state_code"
                label={"Código de Estado (Veracruz = 30)"}
                variant="outlined"
                required
                fullWidth
                type="number"
                error
                onChange={(state_code) => {
                  setSettingFortia({
                    ...settingFortia,
                    state_code: parseInt(state_code.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              {inputFields.map((inputField, index) => (
                <Box p={1} key={index}>
                  <Box p={1}>
                  <TextField
                    error
                    name="turnOne"
                    value={inputField[index]}
                    label={`FolCorte Caja - ${index+1} Turno - ${1}`}
                    variant="outlined"
                    required
                    type="number"
                    onChange={(event) => handleChangeInput(index, event)}
                  />
                  </Box>
                  <Box p={1}>
                  <TextField
                    error
                    name="turnTwo"
                    value={inputField[index]}
                    label={`FolCorte Caja - ${index+1} Turno - ${2}`}
                    variant="outlined"
                    required
                    type="number"
                    onChange={(event) => handleChangeInput(index, event)}
                  />
                  </Box>
                  <Box p={1}>
                  <TextField
                    error
                    name="turnTree"
                    value={inputField[index]}
                    label={`FolCorte Caja - ${index+1} Turno - ${3}`}
                    variant="outlined"
                    required
                    type="number"
                    onChange={(event) => handleChangeInput(index, event)}
                  />
                  </Box>
                  {index > 0 ? (
                    <Button
                      color="primary"
                      onClick={() => handleRemoveFields(index)}
                    >
                      <RemoveIcon />
                    </Button>
                  ) : (
                    ""
                  )}
                  <IconButton onClick={() => handleAddFields()}>
                    <AddIcon color="primary" />
                  </IconButton>
                </Box>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              {inputFieldsFolio.map((inputField, index) => (
                <Box p={1} key={index}>
                  <Box p={1}>
                  <TextField
                    error
                    name="FolioOne"
                    value={inputField[index]}
                    label={`FolioDocCte Caja - ${index+1}`}
                    variant="outlined"
                    required
                    type="number"
                    onChange={(event) => handleChangeInputFolio(index, event)}
                  />
                  </Box>
                  {index > 0 ? (
                    <Button
                      color="primary"
                      onClick={() => handleRemoveFieldsFolio(index)}
                    >
                      <RemoveIcon />
                    </Button>
                  ) : (
                    ""
                  )}
                  <IconButton onClick={() => handleAddFieldsFolio()}>
                    <AddIcon color="primary" />
                  </IconButton>
                </Box>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                error
                name="FolMovto"
                label={`FolMovto`}
                variant="outlined"
                type="number"
                onChange={(FolMovto) => {
                  setSettingFortia({
                    ...settingFortia,
                    FolMovto: parseInt(FolMovto.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          {/* <Grid item xs={3}>
            <Paper elevation={0} className={classes.paper}>
              <TextField
                error
                name="FolioDocCte"
                label={`FolioDocCte`}
                variant="outlined"
                type="number"
                onChange={(FolioDocCte) => {
                  setSettingFortia({
                    ...settingFortia,
                    FolioDocCte: parseInt(FolioDocCte.target.value),
                  });
                }}
              />
            </Paper>
          </Grid> */}
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                error
                name="FolioKarInv"
                label={`FolioKarInv`}
                variant="outlined"
                fullWidth
                type="number"
                onChange={(FolioKarInv) => {
                  setSettingFortia({
                    ...settingFortia,
                    FolioKarInv: parseInt(FolioKarInv.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                error
                name="FolioPed"
                label={`FolioPed`}
                variant="outlined"
                fullWidth
                type="number"
                onChange={(FolioPed) => {
                  setSettingFortia({
                    ...settingFortia,
                    FolioPed: parseInt(FolioPed.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                error
                name="IdKarInv"
                label={`IdKarInv`}
                variant="outlined"
                fullWidth
                type="number"
                onChange={(IdKarInv) => {
                  setSettingFortia({
                    ...settingFortia,
                    IdKarInv: parseInt(IdKarInv.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                error
                name="IdPedido"
                label={`IdPedido`}
                variant="outlined"
                fullWidth
                type="number"
                onChange={(IdPedido) => {
                  setSettingFortia({
                    ...settingFortia,
                    IdPedido: parseInt(IdPedido.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                error
                name="IdPedidoDet"
                label={`IdPedidoDet`}
                variant="outlined"
                fullWidth
                type="number"
                onChange={(IdPedidoDet) => {
                  setSettingFortia({
                    ...settingFortia,
                    IdPedidoDet: parseInt(IdPedidoDet.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                
                key="Factura"
                name="Factura"
                label={"Factura"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(Factura) => {
                  setSettingFortia({
                    ...settingFortia,
                    Factura: parseInt(Factura.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                
                key="Credito"
                name="Nota de Crédito"
                label={"Nota de Crédito"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(Credito) => {
                  setSettingFortia({
                    ...settingFortia,
                    Credito: parseInt(Credito.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                
                key="Pago"
                name="Pago"
                label={"Pago"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(Pago) => {
                  setSettingFortia({
                    ...settingFortia,
                    Pago: parseInt(Pago.target.value),
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="ip_cluster"
                name="ip_cluster"
                label={"ip_cluster"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(ip_cluster) => {
                  setSettingCluster({
                    ...settingCluster,
                    ip_cluster: ip_cluster.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="primary_replica"
                name="primary_replica"
                label={"primary_replica"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(primary_replica) => {
                  setSettingCluster({
                    ...settingCluster,
                    primary_replica: primary_replica.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="minMoneyCashRetirement"
                name="minMoneyCashRetirement"
                label={"minMoneyCashRetirement"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(minMoneyCashRetirement) => {
                  setSettingCash({
                    ...settingCash,
                    minMoneyCashRetirement: minMoneyCashRetirement.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                
                key="minPettyCash"
                name="minPettyCash"
                label={"minPettyCash"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(minPettyCash) => {
                  setSettingCash({
                    ...settingCash,
                    minPettyCash: minPettyCash.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box p={1}>
              <Typography variant="h6" color="textSecondary">
                Configuracion de PINPAD
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="hostnameId"
                name="hostnameId"
                label={"hostnameId"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(hostnameId) => {
                  setSettingHostName({
                    ...settingHostName,
                    hostnameId: hostnameId.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box p={1}>
              <Typography variant="h6" color="textSecondary">
                Afiliacion
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="currency"
                name="currency"
                label={"moneda"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(currency) => {
                  setSettingAfiliacion({
                    ...settingAfiliacion,
                    currency: currency.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="number"
                name="number"
                type="number"
                label={"cantidad"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(number) => {
                  setSettingAfiliacion({
                    ...settingAfiliacion,
                    number: number.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box p={1}>
              <Typography variant="h6" color="textSecondary">
                Conexión
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="ip"
                name="ip"
                label={"ip"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(ip) => {
                  setSettingSocketConnection({
                    ...settingSocketConnection,
                    ip: ip.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="port"
                name="port"
                label={"puerto"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(port) => {
                  setSettingSocketConnection({
                    ...settingSocketConnection,
                    port: port.target.value,
                  });
                }}
              />
            </Paper>
          </Grid> */}
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="timeout"
                name="timeout"
                label={"timeout"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(timeout) => {
                  setSettingSocketConnection({
                    ...settingSocketConnection,
                    timeout: timeout.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="url"
                name="url"
                label={"url"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(url) => {
                  setSettingSocketConnection({
                    ...settingSocketConnection,
                    url: url.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box p={1}>
              <Typography variant="h6" color="textSecondary">
                Terminal
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="number"
                name="number"
                label={"numero"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(number) => {
                  setSettingTerminal({
                    ...settingTerminal,
                    number: number.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                
                key="port"
                name="port"
                label={"puerto"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(port) => {
                  setSettingTerminal({
                    ...settingTerminal,
                    port: port.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              {" "}
              <TextField
                key="timeout"
                name="timeout"
                label={"timeout"}
                variant="outlined"
                required
                fullWidth
                error
                onChange={(timeout) => {
                  setSettingSocketConnection({
                    ...settingTerminal,
                    timeout: timeout.target.value,
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button_save}
                endIcon={<Icon>save</Icon>}
                onClick={handleSubmit}
              >
                {props.translate("Save Config")}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
