import React, { useState, useEffect } from 'react';
import { CardContent, Card, Typography, CardActions, Button, Slide, CardHeader, Grid, CircularProgress } from '@material-ui/core';

function CardCustomer(props) {
    const [customers, setCustomers] = useState([])
    const [refresh, setRefresh] = useState(true)

    useEffect(() => {
        if (customers.length !== props.customers) {
            setRefresh(false)
            setCustomers(props.customers)
            setTimeout(() => setRefresh(true), 500)
        }

        return function cleanup() {

        };
    }, [
        props.customers,
        customers.length]) // Only re-run the effect if name or email change


    return <Card
        onClick={event => props.changeSubSet(customers, props.title)}>
        <CardHeader
            title={props.title} />
        <CardContent>
            <Grid
                container
                spacing={3}
                justify="space-evenly"
                alignItems="center">
                <Grid item>
                    {customers.length > 0 ?
                        <CircularProgress variant="static" value={(customers.length * 100) / props.total} />
                        : <CircularProgress variant="static" color="secondary" value={100} />}
                </Grid>
                <Grid item>
                    <Slide direction="up" in={refresh} mountOnEnter unmountOnExit>
                        <Typography variant="h3">
                            {customers.length}
                        </Typography>
                    </Slide>
                </Grid>
            </Grid>
        </CardContent>
        <CardActions>
            <Button
                onClick={event => props.changeSubSet(customers, props.title)}
                size="small">
                {props.translate("More Details")}
            </Button>
        </CardActions>
    </Card>
}


export default CardCustomer;