import React, { Component } from 'react';
import { connect  } from 'react-redux';
import { Grid, Container } from '@material-ui/core';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Modal, { Select } from '../../../components/Modal';
import { fetchOrganizations, setOrganizations } from '../../../store/organizations/actions';
import { fetchAreas, setAreas } from '../../../store/areas/actions';
import { fetchEmployees } from '../../../store/employees/actions';
import {
  isOrganizationsloading,
  getOrganizationsdata,
} from '../../../store/organizations/selectors';
import {
  isAreasloading,
  getAreasdata,
} from '../../../store/areas/selectors';

import {
  getIndicatorOrganization,
  getIndicatorArea
} from '../../../store/indicator/selectors';

import {
  setIndicatorArea,
  setIndicatorOrganization
} from '../../../store/indicator/actions';
import { resetIndicatorData } from '../../../store/indicator/actions';
import SessionControl from '../../../utils/SessionControl';

export class ListIndicators extends SessionControl {

  constructor(props) {
    super(props);
    console.log(props)
    props.resetIndicatorData();
    props.fetchOrganizations();
    this.state = {
      showAreaSelect: false,
      user: this.getSecureStore().UserProfileReducer &&
      this.getSecureStore().UserProfileReducer.user
      ? this.getSecureStore().UserProfileReducer.user
      : [],
    }
  }

  componentDidMount = () => { }

  hasIndicatorData = () => {
    let { organization, area } = this.props;
    return organization && area;
  }

  render() {
    let { organizations, areas } = this.props;
    const { paramProfile, position } = this.state.user;
    let listPermisionOrganization = JSON.parse(JSON.parse(paramProfile))
    let listOrganization = []
    /// ** FILTRO POR EMPRESA QUE NO PUEDA ACCEDER
    organizations && organizations.data.forEach(organization => {
      listPermisionOrganization.filter(r => r.uuid === position.id).forEach(_e => {

        _e.organization.forEach(_a => {
          if (_a.uuid === organization.id) {
            listOrganization.push(organization)
          }
        });

      });
    });

    organizations.data = listOrganization

    return (
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs {...this.props}
              navigation={[
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                { title: 'Buscar Indicadores', current: true },
              ]}
            />
          </Grid>
          <Modal
            open={true}
            isContinueDisabled={!this.hasIndicatorData()}
            title="Selecciona la estructura para filtrar indicadores"
            onContinue={event => {
              let { area } = this.props;
              this.props.navigate(`/prometheus/variableCommissions/indicadores/lista/${area.id}`)
            }}
            onCancel={event => this.props.navigate('/prometheus/variableCommissions')}
          >
            <Select
              loading={organizations.loading}
              label="Empresa"
              elements={organizations.data}
              onChange={value => {
                this.props.setIndicatorOrganization(value)
                if (value) {
                  this.props.fetchAreas(value);
                  this.setState({ showAreaSelect: true });
                } else {
                  this.props.resetIndicatorData();
                  this.setState({ showAreaSelect: false });
                  this.props.setAreas([]);
                }

              }}
            />
            {this.state.showAreaSelect &&
              <Select
                loading={areas.loading}
                label="Puesto"
                elements={areas.data}
                onChange={value => this.props.setIndicatorArea(value)}
              />
            }
          </Modal>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  organization: getIndicatorOrganization(state),
  area: getIndicatorArea(state),
  organizations: {
    loading: isOrganizationsloading(state),
    data: getOrganizationsdata(state)
  },
  areas: {
    loading: isAreasloading(state),
    data: getAreasdata(state)
  }
});

const mapDispatchToProps = dispatch => ({
  fetchOrganizations: () => dispatch(fetchOrganizations()),
  fetchAreas: payload => dispatch(fetchAreas(payload)),
  fetchEmployees: payload => dispatch(fetchEmployees(payload)),
  resetIndicatorData: payload => dispatch(resetIndicatorData(payload)),
  setIndicatorArea: payload => dispatch(setIndicatorArea(payload)),
  setIndicatorOrganization: payload => dispatch(setIndicatorOrganization(payload)),
  setAreas: payload => dispatch(setAreas(payload)),
  setOrganizations: payload => dispatch(setOrganizations(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListIndicators);
