import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export class Lead extends Component {
  render() {
    const { lead , handleClose } = this.props;

    return (
      <Snackbar
        open={lead.show}
        autoHideDuration={60000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={lead.type}>{lead.message}</Alert>
      </Snackbar>
    );
  }
}

export default Lead;