import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getVariableTagState = state => get(state, 'variabletag');

export const getVariableTagOrganization = createSelector(
    [getVariableTagState],
    variabletag => get(variabletag, 'organization'),
  );
  
export const getVariableTagName = createSelector([getVariableTagState], variabletag =>
  get(variabletag, 'name'),
);

export const getVariableTagDatasource = createSelector(
  [getVariableTagState],
  variabletag => get(variabletag, 'dataSource'),
);

export const geteditVariableTag = createSelector([getVariableTagState], variabletag =>
  get(variabletag, 'edit'),
);

export const geteditVariableId = createSelector([getVariableTagState], variabletag =>
  get(variabletag, 'id'),
);