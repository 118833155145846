import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  FormGroup,
  TextField,
  Grid,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ConstructorSelector from '../../../components/ConstructorSelector';
import Breadcrumbs from '../../../components/Breadcrumbs';

import { getCreateFunctionParameters } from '../../../store/createFunctions/selectors';
import {
  setCreateFunctionName,
  setCreateFunctionDescription,
  deleteParameter,
} from '../../../store/createFunctions/actions';

import './styles.scss';

export class InitCreateFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
    };
  }

  handleChange = name => event => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  onSubmit = event => {
    event.preventDefault();
  };

  saveNameFunction() {
    const { name, description } = this.state;
    this.props.setName(name);
    this.props.setDescription(description);
    this.props.navigate('/prometheus/variableCommissions/funciones/crear/pasos');
  }

  render() {
    const { parameters, deleteParameter } = this.props;
    const { name, description } = this.state;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs {...this.props} 
            navigation={[
              { title: "Dashboard", url: "/prometheus/variableCommissions" },
              {
                title: 'Buscar Funciones',
                url: '/prometheus/variableCommissions/funciones',
              },
              { title: 'Crear función', current: true },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            SELECCIONA LA ESTRUCTURA DE TU FUNCIÓN
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form className="" onSubmit={this.onSubmit}>
            <FormControl>
              <FormGroup>
                <TextField
                  className=""
                  type="text"
                  name="name"
                  variant="outlined"
                  placeholder="Nombre"
                  value={name}
                  onChange={this.handleChange('name')}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  className=""
                  type="text"
                  name="description"
                  variant="outlined"
                  placeholder="Descripción"
                  value={description}
                  onChange={this.handleChange('description')}
                />
              </FormGroup>
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={12}>
          {parameters.map((e, index) => (
            <div className={'parameter-element'} key={index}>
              <ConstructorSelector
                title={'Párametro'}
                modal={{
                  fatherScreen: 'createFunctions',
                  type: 'functionParameter',
                }}
                selected={{ name: e }}
                disabled={true}
              />
              <IconButton
                aria-label="delete"
                className={'delete-btn'}
                onClick={() => deleteParameter(index)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <ConstructorSelector
            title={'Agregar Párametro de función'}
            modal={{
              fatherScreen: 'createFunctions',
              type: 'functionParameter',
            }}
            disabled={
              name.length >= 1 && description.length >= 1 ? false : true
            }
          />
        </Grid>
        <Grid item xs={12} className={'btn-wrapper'}>
          <Button
            className={'accept-btn'}
            onClick={() => this.saveNameFunction()}
            disabled={parameters.length >= 1 ? false : true}
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  parameters: getCreateFunctionParameters(state),
});

const mapDispatchToProps = dispatch => ({
  setName: payload => dispatch(setCreateFunctionName(payload)),
  setDescription: payload => dispatch(setCreateFunctionDescription(payload)),
  deleteParameter: payload => dispatch(deleteParameter(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InitCreateFunction);
