import React, { useRef, useEffect, useState } from 'react';

import { withSnackbar } from 'notistack';
import { Paper, Typography, Grid, Grow, Container, Button, Box, Tabs, Tab, makeStyles, LinearProgress } from '@material-ui/core';
import TabPanel from '../../components/tabPanel/TabPanel';
import { callApiPartial } from '../../api/api';
import { listSystemAttendantMenuScopes, listAttendants } from '../../graphql/queries';
import { SNACKBAR_ERROR, SNACKBAR_INFO } from '../../utils/SessionControl';
import SystemUser from '../../components/system/users/user/SystemUser';
import SystemScope from '../../components/system/users/scope/SystemScope';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        overflow: "inherit"
    },
    progress: {
        width: `100%`
    }
}));

function Users(props) {
    const classes = useStyles();
    const firstRender = useRef(true);
    const [searching, setSearching] = useState({
        users: false,
        scopes: false
    })
    const [selectedTab, setSelectedTab] = useState(0);
    const [users, setUsers] = useState(null);
    const [scopes, setScopes] = useState(null);

    useEffect(() => {
        if (firstRender.current) {
            if (firstRender.current) {
                firstRender.current = false; // it's no longer the first render

                loadSystemUsers({ limit: 300 });
                loadSystemScopes({ limit: 300 });

                return; // skip the code below
            }
        }

        return function cleanup() {

        };
    }, [users, scopes]);

    const loadSystemUsers = async (params, users = []) => {
        console.log(users && users.length)
        setSearching({
            ...searching,
            users: true
        })
        callApiPartial(listAttendants, params, users, setUsers).then(res => {
            console.log(res.length)
            props.showMessage(`${res.length} ${props.translate("Users")} ${props.translate("Loaded")}`, SNACKBAR_INFO, 5000, null, null, null, null, null, null, props);
            setSearching({
                ...searching,
                users: false
            })
        }).catch(err => {
            console.log(err);
            if (err.data && err.data.listAttendants && err.data.listAttendants.items) {
                err.data.listAttendants.items.forEach(u => {
                    if (u)
                        users.push(u)
                })

                setUsers(users)

                if (err.data.listAttendants.nextToken)
                    loadSystemUsers({
                        ...params,
                        nextToken: err.data.listAttendants.nextToken
                    }, users)
            } else
                err && err.errors && err.errors.forEach(_error => {
                    props.showMessage(_error.message, SNACKBAR_ERROR, 5000, null, null, null, null, null, null, props);
                })
            setSearching({
                ...searching,
                users: false
            })
        })
    }

    const loadSystemScopes = async (params) => {
        setSearching({
            ...searching,
            scopes: true
        })
        callApiPartial(listSystemAttendantMenuScopes, params, [], setScopes).then(res => {
            setSearching({
                ...searching,
                scopes: false
            })
            props.showMessage(`${res.length} ${props.translate("System Scopes")} ${props.translate("Loaded")}`, SNACKBAR_INFO, 5000, null, null, null, null, null, null, props);
        }).catch(err => {
            setSearching({
                ...searching,
                scopes: false
            })

            console.log(err);

            err && err.errors && err.errors.forEach(_error => {
                props.showMessage(_error.message, SNACKBAR_ERROR, 5000, null, null, null, null, null, null, props);
            })
        })
    }

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Grow in={true}>
            <Container fixed>
                <Box my={2}>
                    <Grid
                        container
                        justify="space-between"
                        alignItems="center">
                        <Grid item>
                            <Typography variant="h5" color="textSecondary">
                                {props.translate("System Settings")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Paper>
                    <Box className={classes.root}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={selectedTab}
                            onChange={handleChangeTab}
                            className={classes.tabs}
                        >
                            <Tab
                                icon={searching.users ? <LinearProgress color="secondary" className={classes.progress} /> : ''}
                                label={props.translate("Users")}
                                disabled={!Boolean(users)} />
                            <Tab
                                icon={searching.scopes ? <LinearProgress color="secondary" className={classes.progress} /> : ''}
                                label={props.translate("System Scopes")}
                                disabled={!Boolean(scopes)} />
                        </Tabs>
                        <TabPanel value={selectedTab} index={0}>
                            <SystemUser
                                {...props}
                                users={users} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1}>
                            <SystemScope
                                {...props}
                                scopes={scopes} />
                        </TabPanel>
                    </Box>
                </Paper>
            </Container>
        </Grow >
    );
}

export default withSnackbar(Users);