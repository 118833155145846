import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography ,Button,IconButton, Menu} from '@material-ui/core';
import PropTypes from 'prop-types';
import Label from '@material-ui/icons/Label';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ConstructorSelector from '../../../ConstructorSelector';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MenuType,PlatformType } from '../../../../schema/enums/schemaEnums';
import ChipInput from 'material-ui-chip-input';
import { createSystemMenu } from "../../../../graphql/mutations"
import { api, apiPartial } from "../../../../api/api";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../../../utils/SessionControl';


const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },

    
  }));

  function StyledTreeItem (props){
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;
    
    const handleItemClick = IdMenu => event => {
        props.handleItemClick(IdMenu)
      };
    
    return (
        <TreeItem
          label={
            <div className={classes.labelRoot}>
              <LabelIcon color="inherit" className={classes.labelIcon} />
              <Typography variant="body2" className={classes.labelText}>
                {labelText}
              </Typography>
              <Typography variant="caption" color="inherit">
                {labelInfo}
              </Typography>
              <Box>
              {props.Expanded?
              <IconButton 
              variant="contained" 
              color="primary" 
              onClick={handleItemClick(props.rol)}
              alignItems="rigth">
                  <AddIcon></AddIcon>
                  </IconButton>:""}
              </Box>
            </div>
          }
          style={{
            '--tree-view-color': color,
            '--tree-view-bg-color': bgColor,
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            group: classes.group,
            label: classes.label,
          }}
          {...other}
        />
      );
    }
    
    StyledTreeItem.propTypes = {
      bgColor: PropTypes.string,
      color: PropTypes.string,
      labelIcon: PropTypes.elementType.isRequired,
      labelInfo: PropTypes.string,
      labelText: PropTypes.string.isRequired,
    };
    
    const useStyles = makeStyles({
      root: {
        height: 100,
        flexGrow: 1,
        maxWidth: 400,
      },
    });
    
const  icons = [
    {
      name: "stroller",
    },
    {
      name: "subdirectory_arrow_left",
    },
    {
      name: "subdirectory_arrow_right",
    },
    {
      name: "subject",
    },
    {
      name: "subscript",
    },
    {
      name: "subscriptions",
    },
    {
      name: "subtitles",
    },
    {
      name: "subtitles_off",
    },
    {
      name: "subway",
    },
    {
      name: "superscript",
    },
    {
      name: "supervised_user_circle",
    },
    {
      name: "supervisor_account",
    },
    {
      name: "support",
    },
    {
      name: "support_agent",
    },
    {
      name: "surround_sound",
    },
    {
      name: "swap_calls",
    },
    {
      name: "swap_horiz",
    },
    {
      name: "swap_horizontal_circle",
    },
    {
      name: "swap_vert",
    },
    {
      name: "swap_vertical_circle",
    },
    {
      name: "switch_camera",
    },
    {
      name: "switch_left",
    },
    {
      name: "switch_right",
    },
    {
      name: "switch_video",
    },
    {
      name: "sync",
    },
    {
      name: "sync_alt",
    },
    {
      name: "sync_disabled",
    },
    {
      name: "sync_problem",
    },
    {
      name: "system_update",
    },
    {
      name: "system_update_alt",
    },
    {
      name: "tab",
    },
    {
      name: "tab_unselected",
    },
    {
      name: "table_chart",
    },
    {
      name: "table_rows",
    },
    {
      name: "table_view",
    },
    {
      name: "tablet",
    },
    {
      name: "tablet_android",
    },
    {
      name: "tablet_mac",
    },
    {
      name: "tag_faces",
    },
    {
      name: "tap_and_play",
    },
    {
      name: "tapas",
    },
    {
      name: "terrain",
    },
    {
      name: "text_fields",
    },
    {
      name: "text_format",
    },
    {
      name: "text_rotate_up",
    },
    {
      name: "text_rotate_vertical",
    },
    {
      name: "text_rotation_angledown",
    },
    {
      name: "text_rotation_angleup",
    },
    {
      name: "text_rotation_down",
    },
    {
      name: "text_rotation_none",
    },
    {
      name: "text_snippet",
    },
    {
      name: "textsms",
    },
    {
      name: "texture",
    },
    {
      name: "theaters",
    },
    {
      name: "thumb_down",
    },
    {
      name: "thumb_down_alt",
    },
    {
      name: "thumb_up",
    },
    {
      name: "thumb_up_alt",
    },
    {
      name: "thumbs_up_down",
    },
    {
      name: "time_to_leave",
    },
    {
      name: "timelapse",
    },
    {
      name: "timeline",
    },
    {
      name: "timer",
    },
    {
      name: "timer_10",
    },
    {
      name: "timer_3",
    },
    {
      name: "timer_off",
    },
   
  ]

export default function SystemMenu(props) {
    const classes = useTreeItemStyles();
      const [Menus, setMenus] = React.useState(null);
      const [ItemRoot, setItemRoot] = React.useState([]);
      const [rol, setRol] = React.useState([]);
      const [open, setOpen] = React.useState(false);
      const [selectIcon, setSelectIcon] = React.useState(null);
      const [selectMenu, setSelectMenu] = React.useState(null);
      const [selectPlatform, setSelectPlatform] = React.useState(null);
      const [selectRol, setSelectRol] = React.useState(null);
      const [valueNameComponent, setValueNameComponent] = useState('')
      const [valueNameMenu, setValueNameMenu] = useState('')
      const [valueNamePath, setValueNamePath] = useState('')
      const [scope,setScope]=useState(['leer', 'editar','ejecutar'])
      const [status,setStatus]=useState(true)

      const optionsMenu = [
        {name:MenuType.NAVIGATION},
        {name:MenuType.PROFILE},
        {name:MenuType.SYSTEM},
    ];

    const optionsPlatform = [
        {name:PlatformType.CLOUD},
        {name:PlatformType.POS},
    ];


    const optionsStatus= [
        {name:"ACTIVO"},
        {name:"INACTIVO"},
    ];

      const defaultPropsMenu = {
        options: optionsMenu,
        getOptionLabel: (option) => option.name,
      };

      const defaultProps = {
        options: optionsPlatform,
        getOptionLabel: (option) => option.name,
      };

      const defaultPropsRol = {
        options: rol,
        getOptionLabel: (option) => option.systemRoleName,
      };

      const defaultPropsIcons = {
        options: icons,
        getOptionLabel: (option) => option.name,
      };

      useEffect(() => {

        if (Menus !== props.menus)
            buildMenu(props.menus)

         if (rol !== props.roles)
            buildRol(props.roles)

        return function cleanup() {
        };
    },  [props, props.roles,props.menus]) // Only re-run the effect if name or email change


    const buildMenu = menus => {
        var root =dataRoot(menus)
        setItemRoot(root)
        //setItemChildren(root.children)
    }

    const buildRol = roles => {
        setRol(roles)
        console.log(roles)
    }
    ////////////////// FUNCTIONS //////////////////////////
    function dataRoot(menus) {
        var root = []
        menus.forEach(_a => {
            if(_a.parentSystemMenuId === null){

                let Classfamily ={
                    parentSystemMenuId: _a.parentSystemMenuId,
                    systemMenuName: _a.systemMenuName,
                    id: _a.id,
                    children:dataFamily(_a,menus)
                  }
                  root.push(Classfamily) 
            }
        })
      
        return root;
      }

    function dataFamily(array,roles) {
      var _rowChildren = [];
        let init=0
        roles.forEach((_r) => {
            if (array.id === _r.parentSystemMenuId) {
              let Classfamily = {
                parentSystemMenuId: _r.parentSystemRoleId,
                systemMenuName: _r.systemMenuName,
                id: _r.id,
                node: init,
              };

              _rowChildren.push(Classfamily);

              init += 1;
            }
          });
      return _rowChildren;
    }

      const handleItemClick = (Menu) =>{
        setOpen(true);
        setMenus(Menu)
      };

      const handleClose = () => {
        setOpen(false);
      };

      const handleCreateNewMenu = () => {
        let object = [];
        scope.forEach((_v) => {
          let item = {
            scopeName: _v,
            active: status,
          };
          object.push(item)
        });

        if(valueNameComponent !== "" && selectMenu !==  null 
          && valueNamePath !== "" && selectPlatform !==  null && selectMenu !==  null){

            let menu_root=Menus.id;
            let params = {
              component: valueNameComponent,
              icon: selectIcon ===  null? "":selectIcon.name,
              menuType: selectMenu ===  null?  "":selectMenu.name,
              path: valueNamePath,
              platformType: selectPlatform ===  null? "":selectPlatform.name,
              systemMenuName: valueNameMenu,
              systemMenuRoleId: selectRol ===  null? "": selectRol.id,
              parentSystemMenuId: menu_root ===  undefined ? "":Menus.id,
              active: status,
              defaultScopes: object,
            };
            console.log(params)
             api(createSystemMenu, { input: params })
               .then((res) => {
                   console.log(res)
                   setOpen(false);
                   props.showMessage(props.translate("Se ha creado un nuevo menu"), SNACKBAR_SUCCESS, 7000);
   
               })
               .catch((err) => {
                 console.log(err);
               });
          }
       
      };
      return (
        <Box p={2}>
          <Grid container spacing={12}>
            <Grid item xs={6}>
              <Typography style={{marginTop:"15px"}} variant="body2" color="textSecondary" className={classes.labelRoot}>
                 Agregar un nuevo menu raíz
              </Typography>
            </Grid>

            <Grid item xs={6}  className={classes.labelRoot}>
            <IconButton 
              color="primary" 
              onClick={handleItemClick}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>

          <TreeView
            defaultExpanded={["1"]}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
          >
            {ItemRoot.map((element_root, idx_root) => (
              <StyledTreeItem
                nodeId={idx_root}
                labelText={element_root.systemMenuName}
                labelIcon={Label}
                Expanded={true}
                rol={element_root}
                handleItemClick={handleItemClick}
              >
                {element_root.children.map((element, idx) => (
                  <StyledTreeItem
                    style={{ paddingLeft: "20px" }}
                    nodeId={idx + element_root.children.length}
                    labelText={element.systemMenuName}
                    labelIcon={Label}
                    Expanded={idx >= 0 ? true : false}
                    rol={element}
                    handleItemClick={handleItemClick}
                  ></StyledTreeItem>
                ))}
              </StyledTreeItem>
            ))}
          </TreeView>

          <Dialog
            open={open}
            maxWidth="lg"
            fullWidth="fullWidth"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Crear nuevo menu</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Ingresa datos para crear el nuevo Menú
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nombre de menu"
                    type="text"
                    fullWidth
                    onChange={(e) => setValueNameMenu(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nombre de componente"
                    type="text"
                    fullWidth
                    onChange={(e) => setValueNameComponent(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Ruta de componente"
                    type="text"
                    fullWidth
                    onChange={(e) => setValueNamePath(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    {...defaultPropsIcons}
                    id="controlled-icono"
                    value={selectIcon}
                    onChange={(event, newValue) => {
                      setSelectIcon(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Icono" margin="dense" />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    {...defaultPropsMenu}
                    id="controlled-menu"
                    value={selectMenu}
                    onChange={(event, newValue) => {
                      setSelectMenu(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Tipo Menu" margin="dense" />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    {...defaultProps}
                    id="controlled-plataform"
                    value={selectPlatform}
                    onChange={(event, newValue) => {
                      setSelectPlatform(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de plataforma"
                        margin="dense"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    {...defaultPropsRol}
                    id="controlled-rol"
                    value={selectRol}
                    onChange={(event, newValue) => {
                      setSelectRol(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Rol" margin="dense" />
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  {/* <Autocomplete
                    {...defaultPropsScope}
                    id="controlled-scope"
                    value={scope}
                    onChange={(event, newValue) => {
                        setScope(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Alcance"
                        margin="dense"
                      />
                    )}
                  /> */}
                  <ChipInput
                    margin="dense"
                    fullWidth
                    helperText="Agrega o elimina una funcionalidad"
                    defaultValue={scope}
                    onChange={(chips) => setScope(chips)}
                  />
                </Grid>
                {/* <Grid item xs={4}>
                  <Autocomplete
                    {...defaultPropsStatus}
                    id="controlled-status"
                    value={status}
                    onChange={(event, newValue) => {
                        setStatus(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Estado"
                        margin="dense"
                      />
                    )}
                  />
                </Grid> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleCreateNewMenu} color="primary">
                Generar
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      );
}