import React, { useState ,useEffect ,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import icons from '../assets/icons';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '1.5em'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  icon: {
    maxWidth: "79px"
  },
  noDecoration: {
    textDecoration: 'none'
  }
}));

export default function VariableCommissionsHome() {
  const classes = useStyles();
  const position = useSelector(store => store.UserProfileReducer.user.position)
  const params = useSelector(store => JSON.parse(JSON.parse(store.UserProfileReducer.user.paramProfile)))

  const [ViewOrganization, setViewOrganization] = useState(false)
  const [ViewPosition, setViewPosition] = useState(false)
  const [ViewReports, setViewReports] = useState(false)
  const [ViewEmployees, setViewEmployees] = useState(false)
  const [ViewParameters, setViewParameters] = useState(false)
  const [ViewIndicators, setViewIndicators] = useState(false)
  const [ViewDataSources, setViewDataSources] = useState(false)
  const [ViewVariables, setViewVariables] = useState(false)
  const [ViewManual, setViewManual] = useState(false)
  const [ViewFunctions, setViewFunctions] = useState(false)
  const [ViewVariableCommissions, setViewVariableCommissions] = useState(false)


  useEffect(() => {

    if (params && params) {
      params.forEach(item => {
        if (item.uuid === position.id) {
          item.path_permissions.filter(r=> r.id !== "c3e6a65e-7da3-472f-974b-35a558f796d6").forEach(item_permision => {
            if (item_permision.name === "Business") {
              setViewOrganization(true)
            }
            // if (item_permision.name === "Functions") {
            //   setViewPosition(true)
            // }
            if (item_permision.name === "Reports") {
              setViewReports(true)
            }
            if (item_permision.name === "Employees") {
              setViewPosition(true)
              setViewEmployees(true)
            }
            if (item_permision.name === "Parameters") {
              setViewParameters(true)
            }
            if (item_permision.name === "Indicators") {
              setViewIndicators(true)
            }
            if (item_permision.name === "DataSources") {
              setViewDataSources(true)
            }
            if (item_permision.name === "Functions") {
              setViewFunctions(true)
            }
            if (item_permision.name === "VariableCommissions") {
              setViewVariableCommissions(true)
            }
            if (item_permision.name === "Variables") {
              setViewVariables(true)
            }
            if (item_permision.name === "Manual") {
              setViewManual(true)
            }
          })
        }
      })
    }

    console.log(position)
    console.log(params)

  }, [position,params]); // Only re-run the effect if name or email change

   return (
    <Container fixed>
      <div className={classes.root}>
        <Grid container spacing={3}>
          {ViewOrganization ? <Grid item xs={4}>
            <Link className={classes.noDecoration} to="empresas">
              <Paper className={classes.paper}>
                <img src={icons.empresas} alt="Organizaciones" />
                <h3>Empresas</h3>
              </Paper>
            </Link>
          </Grid> : ""}
          {ViewPosition ? <Grid item xs={4}>
            <Link className={classes.noDecoration} to="areas">
              <Paper className={classes.paper}>
                <img src={icons.areas} alt="Puestos" />
                <h3>Puestos</h3>
              </Paper>
            </Link>
          </Grid> : ""}
          {ViewEmployees?<Grid item xs={4}>
            <Link className={classes.noDecoration} to="empleados">
              <Paper className={classes.paper}>
                <img src={icons.empleados} alt="Empleados" />
                <h3>Empleados</h3>
              </Paper>
            </Link>
          </Grid>:""}
          {ViewDataSources?<Grid item xs={4}>
            <Link className={classes.noDecoration} to="fuentes-de-informacion">
              <Paper className={classes.paper}>
                <img src={icons.fuentes} alt="Fuentes de información" />
                <h3>Fuentes de información</h3>
              </Paper>
            </Link>
          </Grid>:""}
          {ViewVariables?<Grid item xs={4}>
            <Link className={classes.noDecoration} to="variables">
              <Paper className={classes.paper}>
                <img src={icons.variables} alt="Variables" />
                <h3>Variables</h3>
              </Paper>
            </Link>
          </Grid>:""}
          {ViewParameters?<Grid item xs={4}>
            <Link className={classes.noDecoration} to="parametros">
              <Paper className={classes.paper}>
                <img src={icons.parametros} alt="Parámetros" />
                <h3>Parámetros</h3>
              </Paper>
            </Link>
          </Grid>:""}
          {ViewFunctions?<Grid item xs={4}>
            <Link className={classes.noDecoration} to="funciones">
              <Paper className={classes.paper}>
                <img src={icons.funciones} alt="Funciones" className={classes.icon} />
                <h3>Funciones</h3>
              </Paper>
            </Link>
          </Grid>:""}
          {ViewIndicators?<Grid item xs={4}>
            <Link className={classes.noDecoration} to="indicadores">
              <Paper className={classes.paper}>
                <img src={icons.indicadores} alt="Indicadores" />
                <h3>Indicadores</h3>
              </Paper>
            </Link>
          </Grid>:""}
          {ViewVariableCommissions? <Grid item xs={4}>
            <Link className={classes.noDecoration} to="comisiones-variables">
              <Paper className={classes.paper}>
                <img src={icons.kpis} alt="Comisiones Variables" />
                <h3>Comisiones Variables</h3>
              </Paper>
            </Link>
          </Grid>:""}
          {ViewReports ? <Grid item xs={4}>
            <Link className={classes.noDecoration} to="reportes">
              <Paper className={classes.paper}>
                <img src={icons.reportes} alt="Reportes" />
                <h3>Reportes</h3>
              </Paper>
            </Link>
          </Grid>:""}
          {ViewManual?<Grid item xs={4}>
            <a href={process.env.PUBLIC_URL + '/VariableCommissionsManual.pdf'} className={classes.noDecoration}>
              <Paper className={classes.paper}>
                <img src={icons.documentacion} alt="Funciones" />
                <h3>Documentación</h3>
              </Paper>
            </a>
          </Grid>:""}
        </Grid>
      </div>
    </Container>
  );
}
