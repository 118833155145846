import { takeLatest, all, call, put } from 'redux-saga/effects';
import { FETCH_FUNCTIONS } from './constants';
import { setFunctions, setFunctionsError } from './actions';
import API from '../../services/api';

export function* fetchFunctionsSaga() {
  try {
    const response = yield call(API.getFunctions);
    const {
      data: { helper_function },
    } = response;
    yield put(setFunctions(helper_function));
  } catch (error) {
    yield put(setFunctionsError(true));
  }
}

export default function* functionsSaga() {
  yield all([takeLatest(FETCH_FUNCTIONS, fetchFunctionsSaga)]);
}
