import React, { useState } from 'react';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { tableESP } from '../../../helpers';

export default function Lista(props) {

  const [state] = useState({
    columns: [
      /*{ title: 'ID', field: 'id' , editable: 'never' },*/
      { title: 'Nombre', field: 'name'}
    ]
  });

  const { data , ready , onUpdateValue , onDeleteValue ,edit } = props;
  
  return (

    <div>
        <Grid container spacing={3}>
            <Grid item xs={12}>
            { ready? edit ? (
                  <MaterialTable
                  title={`Puestos`}
                  columns={state.columns}
                  data={data}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        resolve();
                        if (oldData) {
                          onUpdateValue( oldData , newData  , newData.name);
                        }
                      }, 600);
                    }),
                    onRowDelete: oldData =>
                    new Promise(resolve => {
                        setTimeout(() => {
                        resolve();
                        onDeleteValue(oldData , oldData.id);
                        }, 600);
                    }),
                  }}
                  options={{
                    actionsColumnIndex: -1
                  }}
                  localization={tableESP}
                  />
              ):(
                <MaterialTable
                title={`Puestos`}
                columns={state.columns}
                data={data}
                options={{
                  actionsColumnIndex: -1
                }}
                localization={tableESP}
                />
            ) : (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: '50vh' }}
                >
                  <Grid item xs={3}>
                    <CircularProgress />
                  </Grid>   

                </Grid>
              ) }
            </Grid>
        </Grid>
    </div>
  );
}
