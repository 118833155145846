import React from 'react';
import PropTypes from 'prop-types';

import SessionControl from '../utils/SessionControl';
import CustomNavbar from '../components/customNavbar/CustomNavbar';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';

import { withSnackbar } from "notistack";

import { API, graphqlOperation } from 'aws-amplify';
import { listSystemMenus, listAttendants, getSystemRole, getPosition ,getParam } from '../graphql/queries';

import { MenuType, PlatformType } from "../schema/enums/schemaEnums";
import { api, apiPartial } from '../api/api';

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


class Navbar extends SessionControl {
    constructor(props) {
        super(props);

        // console.log("constructor", props, this.getSecureStore())

        this.state = {
            userSignIn: this.getSecureStore().UserSignInReducer.userSignIn,
            profileMenuListItems:
                this.getSecureStore().NavbarReducer &&
                    this.getSecureStore().NavbarReducer.systemProfileMenuList
                    ? this.getSecureStore().NavbarReducer.systemProfileMenuList
                    : [],
            navigationMenuListItems:
                this.getSecureStore().NavbarReducer &&
                    this.getSecureStore().NavbarReducer.systemNavigationMenuList
                    ? this.getSecureStore().NavbarReducer.systemNavigationMenuList
                    : [],
            systemMenuListItems:
                this.getSecureStore().NavbarReducer &&
                    this.getSecureStore().NavbarReducer.systemMenuList
                    ? this.getSecureStore().NavbarReducer.systemMenuList
                    : [],
            user: this.getSecureStore().UserProfileReducer &&
            this.getSecureStore().UserProfileReducer.user
            ? this.getSecureStore().UserProfileReducer.user
            : [],
            paramProfile:"",
            stateProfile:false
        }

        this.handleSignOut = this.handleSignOut.bind(this); // this.getSecureStore().UserProfileReducer.user
        this.graphqlQuery = this.graphqlQuery.bind(this);
    }

    componentDidMount() {
        // this.isUserSignedIn();
        // console.log("componentDidMount", this.state, this.getSecureStore())
        /*if (this.state.userSignIn && Object.keys(this.state.userSignIn).length) {
            this.isUserSignedIn();
        }*/
    }

    componentDidUpdate(prevProps, previousState) {
        const userSignIn = this.getSecureStore().UserSignInReducer.userSignIn;

        if (userSignIn && Object.keys(userSignIn).length &&
            ((userSignIn && !previousState.userSignIn) ||
                (userSignIn &&
                    previousState.userSignIn &&
                    previousState.userSignIn.username === undefined) ||
                (userSignIn &&
                    previousState.userSignIn &&
                    previousState.userSignIn.username !== userSignIn.username))
        ) {
            // console.log(userSignIn, previousState.userSignIn, this.getSecureStore())
            this.setState({ userSignIn: userSignIn });

            // Get Profile Menu's
            var params = {
                filter: {
                    menuType: {
                        eq: MenuType.PROFILE
                    },
                    platformType: {
                        eq: PlatformType.CLOUD
                    }
                },
                limit: 100
            };

            this.graphqlQuery(listSystemMenus, params, MenuType.PROFILE);

            // Get System Menu's
            params = {
                filter: {
                    menuType: {
                        eq: MenuType.SYSTEM
                    },
                    platformType: {
                        eq: PlatformType.CLOUD
                    }
                },
                limit: 100
            };

            this.graphqlQuery(listSystemMenus, params, MenuType.SYSTEM);

            // Get Navigation Menu
            params = {
                filter: {
                    menuType: {
                        eq: MenuType.NAVIGATION
                    },
                    platformType: {
                        eq: PlatformType.CLOUD
                    },
                },
                limit: 100
            };

            this.graphqlQuery(listSystemMenus, params, MenuType.NAVIGATION);

            // Get Attendant
            params = {
                filter: {
                    userName: {
                        eq: userSignIn.username
                    }
                },
                limit: 100
            };

            const _call = (params) => new Promise((resolve, reject) => {
                this.graphqlQuery(listAttendants, params).then(res => {
                    if (res.nextToken && res.items.length <= 0) {
                        resolve(_call({
                            ...params,
                            nextToken: res.nextToken
                        }))
                    }
                    else
                        resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
            _call(params).then(res => {

                //this.props.UserProfile_onSuccess(res.items[0])
                console.log("Reducer UserProfile_onSuccess")
                this.graphqlQuery(getParam, { id: "e3c16d22-c7a8-41c3-b833-1fcf965bde48" }).then(param => {
                    console.log("UserProfile_onSuccess", JSON.parse(JSON.parse(param.value)))
                    this.props.UserProfile_onSuccess({...res.items[0],paramProfile:param.value,status:true})
                    this.setState({
                        user: res.items[0],
                        paramProfile: param.value,
                        stateProfile :true
                    })

                    //this.props.Navbar_onProfileMenu(allTodos.data.listSystemMenus.items)
                    this.setState({ profileMenuListItems: this.state.profileMenuListItems })

                    //this.props.Navbar_onNavigationMenu(allTodos.data.listSystemMenus.items)
                    this.setState({ navigationMenuListItems: this.state.navigationMenuListItems })

                    //this.props.Navbar_onProfileMenu(allTodos.data.listSystemMenus.items)
                    this.setState({ systemMenuListItems: this.state.systemMenuListItems })

                    //this.getSecureStore().UserSignInReducer.userSignIn = {...this.getSecureStore().UserSignInReducer.userSignIn, paramProfile:JSON.parse(param.value)}
                    // Get System Role
                    if (res.items[0].position && res.items[0].position.id)
                        api(getPosition, {
                            id: res.items[0].position.id
                        }).then(p => {
                            // console.log("getPosition", p)
                            this.props.UserSignIn_position(p)

                            if (p.systemRole && p.systemRole.id)
                                api(getSystemRole, {
                                    id: p.systemRole.id
                                }).then(r => {
                                    // console.log("getSystemRole", r)
                                    this.props.UserSignIn_systemRole(r)
                                    this.setState({
                                        user_rolId: r
                                    })
                                })
                        })
                }).catch(err => {
                    err.errors.map(error => console.log(error.message))
                });

               
            })
        }

        if (!userSignIn) {
            // console.log(userSignIn, previousState.userSignIn, prevProps.userSignIn)
            if (previousState.userSignIn)
                this.setState({
                    userSignIn: null,
                    profileMenuListItems: [],
                    navigationMenuListItems: [],
                    systemMenuListItems: [],
                });
        }
    }

    graphqlQuery = async (query, params, menuType) => API.graphql(graphqlOperation(query, params)).then(allTodos => {
        // console.log("NavBar - graphqlOperation", allTodos)
        switch (query) {
            case listSystemMenus:
                switch (menuType) {
                    case MenuType.PROFILE:
                        this.props.Navbar_onProfileMenu(allTodos.data.listSystemMenus.items)
                        this.setState({ profileMenuListItems: allTodos.data.listSystemMenus.items })
                        break;
                    case MenuType.NAVIGATION:
                        this.props.Navbar_onNavigationMenu(allTodos.data.listSystemMenus.items)
                        this.setState({ navigationMenuListItems: allTodos.data.listSystemMenus.items })
                        break;
                    case MenuType.SYSTEM:
                        this.props.Navbar_onProfileMenu(allTodos.data.listSystemMenus.items)
                        this.setState({ systemMenuListItems: allTodos.data.listSystemMenus.items })
                        break;
                    default:
                        break;
                }
                break;
            default:
                const _key = Object.keys(allTodos.data)
                return allTodos.data[_key]
        }
    });

    handleSignOut = (event) => this.signOut().then(data => {
        this.props.UserSignIn_logOut({});
        this.props.UserProfile_onSuccess({})
        this.setState({ userSignIn: null });
        this.signOut()
            .then(data => {
                this.props.UserSignIn_logOut({});
                this.setState({ userSignIn: null });
                return data;
            })
            .catch(err => {
                throw err;
            });
        this.go("/userSignIn");
    }).catch(err => {
        throw err;
    })

    render() {
        const { userSignIn, profileMenuListItems, navigationMenuListItems, systemMenuListItems, user,user_rolId , paramProfile ,stateProfile} = this.state;
        // console.log(profileMenuListItems)
        if (userSignIn && Object.keys(userSignIn).length && this.props && this.props.location && this.props.location.pathname.includes('prometheus')) {
            return (
                <HideOnScroll {...this.props}>
                    <AppBar position="sticky">
                        <Toolbar>
                            <CustomNavbar
                                userSignIn={userSignIn}
                                user={user}
                                paramProfile={paramProfile}
                                profileMenuListItems={profileMenuListItems}
                                navigationMenuListItems={navigationMenuListItems}
                                systemMenuListItems={systemMenuListItems}
                                userRole={user_rolId}
                                status={stateProfile}
                                go={this.go}
                                translate={this.translate}
                                handleSignOut={this.handleSignOut} />
                        </Toolbar>
                    </AppBar>
                </HideOnScroll >
            );
        } else {
            return <Box />;
        }
    }
}

export default withSnackbar(Navbar);