import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  getIndicatorDatasource,
  getIndicatorArea,
  getIndicatorOrganization,
} from '../../../../../store/indicator/selectors';
import {
  addInicatorElement,
  addIndicatorElementFunction,
  editIndicatorElement,
  editIndicatorFunctionArgumentElement,
} from '../../../../../store/indicator/actions';

import { getKPIArea } from '../../../../../store/kpi/selectors';
import {
  addKPIElement,
  addKPIElementFunction,
  editKPIElement,
  editKPIFunctionArgumentElement,
} from '../../../../../store/kpi/actions';

import {
  isLoadingParameters,
  getParameters,
  isErrorGetParameters,
} from '../../../../../store/parameters/selectors';
import { fetchParameters } from '../../../../../store/parameters/actions';

import {
  isLoadingFunctions,
  geFunctions,
  isErrorGetFunctions,
} from '../../../../../store/functions/selectors';
import { fetchFunctions } from '../../../../../store/functions/actions';

import {
  isLoadingAreaIndicators,
  getAreaIndicators,
  isErrorGetAreaIndicators,
} from '../../../../../store/areaIndicators/selectors';
import { fetchAreaIndicators } from '../../../../../store/areaIndicators/actions';

import { getCreateFunctionParameters } from '../../../../../store/createFunctions/selectors';
import {
  addCreateFunctionlelement,
  editCreateFunctionElement,
} from '../../../../../store/createFunctions/actions';

import {
  isDatasourcesloading,
  getDatasourcesdata,
  isDatasourcesError,
} from '../../../../../store/datasources/selectors';
import { fetchDatasources } from '../../../../../store/datasources/actions';

import AddVariable from './AddVariable';
import AddParameter from './AddParameter';
import AddFunction from './AddFunction';
import AddAreaIndicator from './AddAreaIndicator';
import AddPreviousStep from './AddPreviousStep';
import AddParameterFunction from './AddParameterFunction';

import './styles.scss';

export class AddElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      value: '',
    };
  }

  saveElement() {
    const {
      step,
      onClose,
      type,
      addIndicatorElement,
      addKPIElement,
      isFunction,
      addIndicatorElementFunction,
      addKPIElementFunction,
      addCreateFunctionlelement,
      editData,
      editIndicatorElement,
      editKPIElement,
      editCreateFunctionElement,
      editKPIFunctionArgumentElement,
      editIndicatorFunctionArgumentElement,
    } = this.props;

    const { selected, value } = this.state;
    let payload = '';

    if (selected === 'step') {
      payload = {
        step: step,
        type: 'step',
        name: value,
        object: this.state,
      };
    } else {
      payload = {
        step: step,
        type: 'element',
        object: this.state,
      };
    }

    if (editData) {
      switch (type) {
        case 'indicator':
          if (editData.functionElementIndex === null) {
            editIndicatorElement({ payload, editData });
          } else {
            editIndicatorFunctionArgumentElement({ payload, editData });
          }
          break;

        case 'KPI':
          if (editData.functionElementIndex === null) {
            editKPIElement({ payload, editData });
          } else {
            editKPIFunctionArgumentElement({ payload, editData });
          }
          break;

        case 'createFunction':
          editCreateFunctionElement({ payload, editData });
          break;

        default:
          break;
      }
    } else {
      switch (type) {
        case 'indicator':
          if (isFunction) {
            addIndicatorElementFunction({ isFunction, payload });
          } else {
            addIndicatorElement(payload);
          }
          break;

        case 'KPI':
          if (isFunction) {
            addKPIElementFunction({ isFunction, payload });
          } else {
            addKPIElement(payload);
          }
          break;

        case 'createFunction':
          addCreateFunctionlelement(payload);
          break;

        default:
          break;
      }
    }

    onClose();

    this.setState({
      ...this.state,
      selected: '',
      value: '',
    });
  }

  selectElement(elementType) {
    const {
      type,
      fetchParameters,
      indicatorArea,
      kpiArea,
      fetchFunctions,
      fetchAreaIndicators,
      fetchDatasources,
      indicatorOrganization,
    } = this.props;

    this.setState({
      ...this.state,
      selected: elementType,
      value: '',
    });

    if (type === 'indicator') {
      switch (elementType) {
        case 'variable':
          fetchDatasources(indicatorOrganization);
          break;

        case 'parameter':
          fetchParameters(indicatorArea.id);
          break;

        case 'function':
          fetchFunctions();
          break;

        default:
          break;
      }
    } else if (type === 'KPI') {
      switch (elementType) {
        case 'parameter':
          fetchParameters(kpiArea.id);
          break;

        case 'function':
          fetchFunctions();
          break;

        case 'areaIndicators':
          fetchAreaIndicators(kpiArea.id);
          break;

        default:
          break;
      }
    }
  }

  setValue(payload) {
    this.setState({
      ...this.state,
      value: payload,
    });
  }

  render() {
    let { showFunctions, showSteps, activeStep } = this.props;
    const {
      type,
      open,
      onClose,
      loadingParameters,
      parameters,
      errorParameters,
      loadingFunctions,
      functions,
      errorFunctions,
      loadingAreaIndicators,
      areaIndicators,
      errorAreaIndicators,
      step,
      functionParameters,
      editData,
      isDatasourcesloading,
      datasourcesData,
      isDatasourcesError,
    } = this.props;
    const { selected, value } = this.state;

    if (typeof editData !== 'undefined' && editData !== null) {
      if (editData.type === 'step') {
        showSteps = true;
        activeStep = editData.activeStepElement;
      }
      if (editData.functionElementIndex !== null) {
        showFunctions = false;
      }
    }
    return (
      <Dialog fullScreen open={open} onClose={onClose} closeAfterTransition>
        <Fade in={open}>
          <div className={'dialog-content'}>
            <h2 id="transition-modal-title">Selecciona un elemento</h2>
            {type === 'createFunction' ? (
              <Grid id="transition-modal-description" container spacing={3}>
                <Grid item xs>
                  <Button
                    className={'element-btn'}
                    variant="contained"
                    onClick={() => this.selectElement('parameterFunction')}
                    disabled={selected === 'parameterFunction'}
                  >
                    Parametro de Función
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid id="transition-modal-description" container spacing={3}>
                <Grid item xs>
                  {type !== 'KPI' && (
                    <Button
                      className={'element-btn'}
                      variant="contained"
                      onClick={() => this.selectElement('variable')}
                      disabled={selected === 'variable'}
                    >
                      Variable
                    </Button>
                  )}
                  {type === 'KPI' && (
                    <Button
                      className={'element-btn'}
                      variant="contained"
                      onClick={() => this.selectElement('areaIndicators')}
                      disabled={selected === 'areaIndicators'}
                    >
                      Indicador
                    </Button>
                  )}
                </Grid>
                <Grid item xs>
                  <Button
                    className={'element-btn'}
                    variant="contained"
                    onClick={() => this.selectElement('parameter')}
                    disabled={selected === 'parameter'}
                  >
                    Parametro
                  </Button>
                </Grid>
                {showFunctions !== false && (
                  <Grid item xs>
                    <Button
                      className={'element-btn'}
                      variant="contained"
                      onClick={() => this.selectElement('function')}
                      disabled={selected === 'function'}
                    >
                      Función
                    </Button>
                  </Grid>
                )}
                {showSteps === true && activeStep > 0 && (
                  <Grid item xs>
                    <Button
                      className={'element-btn'}
                      variant="contained"
                      onClick={() => this.selectElement('step')}
                      disabled={selected === 'step'}
                    >
                      Paso anterior
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
            {selected === 'variable' &&
              (isDatasourcesloading ? (
                <CircularProgress className={'loading-indicator'} />
              ) : isDatasourcesError === true ? (
                <span className={'error-txt'}>
                  Error: No se encotraron Fuentes de información
                </span>
              ) : (
                <AddVariable
                  datasources={datasourcesData}
                  setValue={value => this.setValue(value)}
                />
              ))}
            {selected === 'parameter' &&
              (loadingParameters ? (
                <CircularProgress className={'loading-indicator'} />
              ) : errorParameters === true ? (
                <span className={'error-txt'}>
                  Error: No se encotraron Parametros
                </span>
              ) : (
                <AddParameter
                  parameters={parameters}
                  setValue={value => this.setValue(value)}
                />
              ))}
            {selected === 'function' &&
              (loadingFunctions ? (
                <CircularProgress className={'loading-indicator'} />
              ) : errorFunctions === true ? (
                <span className={'error-txt'}>
                  Error: No se encotraron funciones
                </span>
              ) : (
                <AddFunction
                  functions={functions}
                  setValue={value => this.setValue(value)}
                />
              ))}
            {selected === 'areaIndicators' &&
              (loadingAreaIndicators ? (
                <CircularProgress className={'loading-indicator'} />
              ) : errorAreaIndicators === true ? (
                <span className={'error-txt'}>
                  Error: No se encotraron Indicadores
                </span>
              ) : (
                <AddAreaIndicator
                  areaIndicators={areaIndicators}
                  setValue={value => this.setValue(value)}
                />
              ))}
            {selected === 'step' && (
              <AddPreviousStep
                areaIndicators={areaIndicators}
                step={step}
                setValue={value => this.setValue(value)}
                screen={type}
                activeStep={activeStep}
              />
            )}
            {selected === 'parameterFunction' && (
              <AddParameterFunction
                functionParameters={functionParameters}
                setValue={value => this.setValue(value)}
              />
            )}
            <Grid className={'btn-wrapper'} container spacing={3}>
              <Grid item xs={12}>
                <Button
                  className={'btn accept-btn'}
                  variant="contained"
                  onClick={() => this.saveElement()}
                  disabled={value ? false : true}
                >
                  Aceptar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button className={'btn cancel-btn'} onClick={onClose}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  /** INDICADOR */
  indicatorArea: getIndicatorArea(state),
  indicatorDataSource: getIndicatorDatasource(state),
  indicatorOrganization: getIndicatorOrganization(state),
  /** KPI **/
  kpiArea: getKPIArea(state),
  /** GET PARAMETERS**/
  loadingParameters: isLoadingParameters(state),
  parameters: getParameters(state),
  errorParameters: isErrorGetParameters(state),
  /** GET FUNCTIONS**/
  loadingFunctions: isLoadingFunctions(state),
  functions: geFunctions(state),
  errorFunctions: isErrorGetFunctions(state),
  /** GET AREA INDICATORS**/
  loadingAreaIndicators: isLoadingAreaIndicators(state),
  areaIndicators: getAreaIndicators(state),
  errorAreaIndicators: isErrorGetAreaIndicators(state),
  /** GET FUNCTION PARAMETERS **/
  functionParameters: getCreateFunctionParameters(state),
  /** GET DATASOURCES **/
  isDatasourcesloading: isDatasourcesloading(state),
  datasourcesData: getDatasourcesdata(state),
  isDatasourcesError: isDatasourcesError(state),
});

const mapDispatchToProps = dispatch => ({
  fetchParameters: payload => dispatch(fetchParameters(payload)),
  fetchFunctions: () => dispatch(fetchFunctions()),
  fetchAreaIndicators: payload => dispatch(fetchAreaIndicators(payload)),
  /** INDICADOR */
  addIndicatorElement: payload => dispatch(addInicatorElement(payload)),
  addIndicatorElementFunction: payload =>
    dispatch(addIndicatorElementFunction(payload)),
  editIndicatorElement: payload => dispatch(editIndicatorElement(payload)),
  editIndicatorFunctionArgumentElement: payload =>
    dispatch(editIndicatorFunctionArgumentElement(payload)),
  /** KPI **/
  addKPIElement: payload => dispatch(addKPIElement(payload)),
  addKPIElementFunction: payload => dispatch(addKPIElementFunction(payload)),
  editKPIElement: payload => dispatch(editKPIElement(payload)),
  editKPIFunctionArgumentElement: payload =>
    dispatch(editKPIFunctionArgumentElement(payload)),
  /** FUNCTION PATAMETERS **/
  addCreateFunctionlelement: payload =>
    dispatch(addCreateFunctionlelement(payload)),
  editCreateFunctionElement: payload =>
    dispatch(editCreateFunctionElement(payload)),
  /**  GET DATASOURCES**/
  fetchDatasources: payload => dispatch(fetchDatasources(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddElement);
