import InitSearchDataSource from './DataSource/InitSearchDataSource';

import { connect } from 'react-redux';

import {
    VariableCommissions_onOrganization
} from '../redux/actions/VariableCommissionsAction';

const mapDispatchToProps = dispatch => ({
    VariableCommissions_onOrganization: data => {
        dispatch(VariableCommissions_onOrganization(data));
    }
});

const mapStateToProps = state => ({
    organization: state.organization
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitSearchDataSource);

