import React from "react";
import PropTypes from "prop-types";
import Chip from '@material-ui/core/Chip';
import "./styles.scss";


const HCStatus = ({status}) => {
  return (
    <div className="hc_status">
      {status === "high" ? ( <Chip label="Alto" className="--red"/>) : null}
      {status === "medium" ? ( <Chip label="Medio" className="--yellow"/>) : null}
      {status === "low" ? ( <Chip label="Bajo" className="--green"/>) : null}
    </div>
  );
};

HCStatus.propTypes = {
  status : PropTypes.string.isRequired,
};

HCStatus.defaultProps = {};

export default HCStatus;
