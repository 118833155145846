import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';

import "./styles.scss";

import HCStatus from '../HCStatus';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const HCTable = ({ columns, rows, showInfo }) => {

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const BodyTable = () => {
    return (
      rows.map((row) => (
        <TableRow key={row.id}>
          <TableCell align="center">{row.warehouse.warehouseId}</TableCell>
          <TableCell align="center">{row.warehouse.warehouseName}</TableCell>
          <TableCell align="center">{row.workTeamTemplate ? row.workTeamTemplate.workTeamTemplate : ''}</TableCell>
          <TableCell align="center">{row.teamPositionsCount}</TableCell>
          <TableCell align="center">{row.vacants}</TableCell>
          <TableCell align="center">
            {row.statusName ? <HCStatus status={row.statusName} /> : <span>Status not ready yet</span>}
          </TableCell>
          <TableCell align="center">
            <Link href="#" onClick={() => showInfo(row)}>
              Más Info
            </Link>
          </TableCell>
        </TableRow>
      ))
    );
  }

  const BodyEmpty = () => {
    return (
      <TableRow >
        <TableCell align="center" colSpan={6}>Sin Datos</TableCell>
      </TableRow>
    );
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? <BodyEmpty /> : <BodyTable />}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

HCTable.propTypes = {};

HCTable.defaultProps = {
  rows: []
};


export default HCTable;
