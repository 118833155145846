import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import {
  FETCH_DATASOURCES,
  SET_DATASOURCES,
  SET_DATASOURCES_ERROR,
} from './constants';

const fetchDatasources = (state, payload) => ({
  ...state,
  loading: true,
  error: false,
});

const setDatasources = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  data: action.payload,
});

const setDatasourcesError = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

export const reducer = handleActions(
  {
    [FETCH_DATASOURCES]: fetchDatasources,
    [SET_DATASOURCES]: setDatasources,
    [SET_DATASOURCES_ERROR]: setDatasourcesError,
  },
  INITIAL_STATE,
);
