import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  FormGroup,
  TextField,
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Container,
} from '@material-ui/core';
import ConstructorSelector from '../../../components/ConstructorSelector';
import Breadcrumbs from '../../../components/Breadcrumbs';

import {
  getKPIOrganization,
  getKPIArea,
  getKPITime,
  isKPIactive,
} from '../../../store/kpi/selectors';

import { setKPIName, setActiveKPI } from '../../../store/kpi/actions';

export class InitKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  handleChange = name => event => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  onSubmit = event => {
    event.preventDefault();
  };

  saveKPI() {
    const { name } = this.state;
    this.props.setKPIName(name);
    this.props.navigate('/prometheus/variableCommissions/comisiones-variables/crear/pasos');
  }

  render() {
    const { organization, area, period, active, setActiveKPI } = this.props;
    const { name } = this.state;
    return (
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs {...this.props}
              navigation={[
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                {
                  title: 'Buscar Comisiones Variables',
                  url: '/prometheus/variableCommissions/comisiones-variables',
                },
                { title: 'Crear Comision Variable', current: true },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              SELECCIONA LA ESTRUCTURA DE TU KPI (COMISIÓN VARIABLE)
          </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className="" onSubmit={this.onSubmit}>
              <div>
                <FormControl>
                  <FormGroup>
                    <TextField
                      className=""
                      type="text"
                      name="name"
                      variant="outlined"
                      placeholder="Nombre"
                      value={name}
                      onChange={this.handleChange('name')}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={() => setActiveKPI(!active)}
                    value="active"
                    color="primary"
                  />
                }
                label="Crear comisión variable lista para ejecutar"
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            <ConstructorSelector
              title={'Empresa'}
              modal={{
                fatherScreen: 'KPI',
                type: 'organizations',
              }}
              selected={organization}
              disabled={name.length >= 1 ? false : true}
            />
            <ConstructorSelector
              title={'Puesto'}
              modal={{
                fatherScreen: 'KPI',
                type: 'area',
                parameter: organization,
              }}
              disabled={organization ? false : true}
              selected={area}
            />
            <ConstructorSelector
              title={'Periodo'}
              modal={{
                fatherScreen: 'KPI',
                type: 'time',
              }}
              screen={'KPI'}
              disabled={area ? false : true}
              selected={period}
            />
          </Grid>
          <Grid item xs={12} className={'btn-wrapper'}>
            <Button
              className={'accept-btn'}
              onClick={() => this.saveKPI()}
              disabled={period || name ? false : true}
            >
              Continuar
          </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  organization: getKPIOrganization(state),
  area: getKPIArea(state),
  period: getKPITime(state),
  active: isKPIactive(state),
});

const mapDispatchToProps = dispatch => ({
  setKPIName: payload => dispatch(setKPIName(payload)),
  setActiveKPI: payload => dispatch(setActiveKPI(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InitKPI);
