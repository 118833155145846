import React from 'react';

import Grid from '@material-ui/core/Grid';
import UserPicture from './userPicture/UserPicture';
import Account from './account/Account';

function ProfileDashboard(props) {
  return (
    <Grid container spacing={1} alignItems="stretch">
      <Grid item xs={4}>
        <UserPicture
          handleSaveProfilePhoto={props.handleSaveProfilePhoto}
          handleUploadProfilePhoto={props.handleUploadProfilePhoto}
          user={props.user}
          translate={props.translate} />
      </Grid>
      <Grid item xs={8}>
        <Account
          userSignIn={props.userSignIn}
          handleSaveProfile={props.handleSaveProfile}
          user={props.user}
          translate={props.translate} />
      </Grid>
       {/* {JSON.stringify(props.user)} */}
    </Grid>
  )
}

export default ProfileDashboard;