import { takeLatest, all, call, put } from 'redux-saga/effects';
import { FETCH_DATASOURCES } from './constants';
import { setDatasources, setDatasourcesError } from './actions';
import API from '../../services/api';

export function* fetchDatasources(action) {
  const {
    payload: { id },
  } = action;
  try {
    if (id) {
      const response = yield call(API.getDataSource, id);
      const {
        data: { data_source },
      } = response;
      yield put(setDatasources(data_source));
    } else {
      yield put(setDatasourcesError(true));
    }
  } catch (error) {
    yield put(setDatasourcesError(true));
  }
}

export default function* datasourcesSaga() {
  yield all([takeLatest(FETCH_DATASOURCES, fetchDatasources)]);
}
