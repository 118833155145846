import { takeLatest, all, put } from 'redux-saga/effects';
import { OPEN_MODALSELECTOR } from './constants';
import { setModalselectorData } from './actions';

export function* openModalSelector(action) {
  const { payload } = action;
  yield put(setModalselectorData(payload));
}

export default function* modalselectorSaga() {
  yield all([takeLatest(OPEN_MODALSELECTOR, openModalSelector)]);
}
