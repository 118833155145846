import React, { useEffect, useRef, useState, useMemo } from 'react'
import { withSnackbar } from 'notistack';
import { Box, Button, Container, Divider, FormControl, Grid, InputLabel, LinearProgress, makeStyles, MenuItem, Paper, Select, TextField, Typography, Tooltip, InputAdornment,
  Dialog, DialogActions, DialogContent, DialogTitle, Slide, CircularProgress, } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BusinessIcon from '@material-ui/icons/Business';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { sra } from '../../graphql/mutations';
import { api } from '../../api/api';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CollaboratorSearch = (props) => {

  const classes = useStyles();
  const companys = [
    {
      company: 'BAMA',
      id: '01'
    },
    {
      company: 'TCO',
      id: '02'
    }
  ]
  const regex = /^[A-Za-z0-9]+$/;


  const [formTosend, setformTosend] = useState({
    userName: '',
    company: ''
  })
  const [open, setOpenDialog] = useState(false);
  const [infoDialog, setinfoDialog] = useState({
    title: '',
    body: '',
    buttonAccept: false,
    buttonCancel: false,
    buttonClose: true,
    type: 0
  })
  const [attendantCloud, setAttendantCloud] = useState({
    fullName: ''
  })
  const [warehouseCloud, setWarehouseCloud] = useState({
    warehouseName: ''
  })
  const [createWarehouse, setCreateWarehouse] = useState('')

  const { 
    title,
    body,
    buttonCancel,
    buttonAccept,
    buttonClose,
    buttonAcceptDialogSave,
    type } = infoDialog

  const validForm = useMemo(() => {
    for (const formValue of Object.keys(formTosend)) {
      if ( formTosend[formValue] === '') {
          return true
      }
    }
    return false
  }, [formTosend])

  const resetSearchInfo = () => {
    setAttendantCloud({
      fullName: ''
    })
    setWarehouseCloud({
      warehouseName: ''
    })
    setCreateWarehouse('')
  }

  const handleChangeUserName = ({ target }) => {
    const { value, name } = target
    setformTosend({
      ...formTosend,
      [name]: value.toUpperCase()
    })
  }

  const handleChangeCompany = (company) => {
    if (!company) {
      setformTosend({
        ...formTosend,
        company: ''
      })
      return
    }
    setformTosend({
      ...formTosend,
      company: company.id
    })
  }

  const handleChangeCreateWarehouse = ({ target }) => {
    const { value, name } = target
    if (regex.test(value) || value === '') {
      setCreateWarehouse(value.toUpperCase())
    }
  }

  const sendInfoToConsult = () => {
    resetSearchInfo()
    setinfoDialog({
      title: '',
      body: 'Consultando información del empleado, un momento...',
      buttonAccept: false,
      buttonCancel: false,
      buttonClose: false,
      type: 1
    });
    setOpenDialog(true)
    const { company, userName} = formTosend
    console.log({
      method: "warehouseAttendant",
      data: {
        method: 'getInfoWarehouseAttendant',
        userName: userName,
        id_compania: company
      },
    });
    api(sra, {
      params: JSON.stringify({
          method: "warehouseAttendant",
          data: {
            method: 'getInfoWarehouseAttendant',
            userName: userName,
            id_compania: company
          },
      })
    }).then(resp => {
      const respClean = JSON.parse(resp)
      console.log('RESPUESSTA', respClean);
      if (!respClean.complete) {
        setinfoDialog({
          title: 'Notificación',
          body: `${respClean.message}`,
          buttonAccept: false,
          buttonCancel: false,
          buttonClose: true,
          type: 3
        });
        return
      }

      setAttendantCloud(respClean.attendant)
      setWarehouseCloud(respClean.warehouse)
      setOpenDialog(false)

    })
  }

  const sendInfoToCreateWarehouseAttendant = () => {
    setinfoDialog({
      title: '',
      body: 'Consultando información del empleado, un momento...',
      buttonAccept: false,
      buttonCancel: false,
      buttonClose: false,
      type: 1
    });
    setOpenDialog(true)
    console.log({
      method: "warehouseAttendant",
      data: {
        method: 'postCreateWarehouseAttendant',
        attendat: attendantCloud,
        warehouse: createWarehouse
      },
    });
    api(sra, {
      params: JSON.stringify({
        method: "warehouseAttendant",
        data: {
          method: 'postCreateWarehouseAttendant',
          attendant: attendantCloud,
          tienda: createWarehouse
        },
      })
    }).then(resp => {
      const respClean = JSON.parse(resp)
      console.log('RESPUESSTA', respClean);
      if (!respClean.complete) {
        setinfoDialog({
          title: 'Notificación',
          body: `${respClean.message}`,
          buttonAccept: false,
          buttonCancel: false,
          buttonClose: true,
          type: 3
        });
        return
        
      }else{
        setinfoDialog({
          title: 'Notificación',
          body: `${respClean.message}`,
          buttonAccept: false,
          buttonCancel: false,
          buttonClose: true,
          type: 2
        });
        return 
      }
    })
  }

  const handleCancel = () => {
    setOpenDialog(false)  
  };

  const handleAccept = () => {
    setOpenDialog(false)
  };

  const handleClose = () => {
    setOpenDialog(false)
  };

  return (
    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='row' m={1} minWidth={'99%'} >
        <Container>
            <Grid container spacing={3} >
                <Grid item xs={12} >
                    <Paper elevation={2} >
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <Typography variant='h5' style={{ margin: '20px'}}>Relacion de cajeros con tienda</Typography>
                        </Grid>
                        <Grid container spacing={3} >
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div className={classes.margin}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                  <AccountCircle fontSize='large'/>
                                </Grid>
                                <Grid item>
                                  <TextField name='userName' id="input-with-icon-grid" label="UserName de cajero" variant='outlined' onChange={handleChangeUserName} value={formTosend.userName} />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.margin}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                  <BusinessIcon fontSize='large'/>
                                </Grid>
                                <Grid item>
                                  <Autocomplete
                                    id="combo-box-demo"
                                    options={companys}
                                    getOptionLabel={(option) => option.company}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(event, newValue) => {
                                      handleChangeCompany(newValue);
                                    }}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Empresas" variant="outlined" />}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>

                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
                            <Button variant='contained' color='primary' disabled={validForm} onClick={sendInfoToConsult}>
                              Consultar Empleado
                            </Button>
                          </Grid>
                          
                          {/* <Grid item xs={6} >
                            <h1>OLAAAAA</h1>
                          </Grid> */}
                        </Grid>
                    </Paper>           
                    {/* Mostrar info de cloud */}
                    {attendantCloud.fullName && warehouseCloud.warehouseName
                      ?
                      <Paper elevation={4} style={{ marginTop: '30px' }}>
                        <Grid container spacing={3} >
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div className={classes.margin}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                  <Typography variant='h5'>
                                    Cajero
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} alignItems="flex-end" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Grid item>
                                  <TextField inputProps={{
                                    readOnly: true
                                  }} name='' id="input-with-icon-grid" label="Cajero" variant='outlined' value={attendantCloud.fullName} />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.margin} style={{ marginLeft: '20px'}}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid item style={{ marginLeft: '30px'}}>
                                  <Typography variant='h5'>
                                    Tienda asignada en LKS
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} alignItems="flex-end" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Grid item>
                                  <TextField inputProps={{
                                    readOnly: true
                                  }} name='' id="input-with-icon-grid" label="Tienda" variant='outlined' value={warehouseCloud.warehouseName} />
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>

                          <Divider variant="fullWidth"/>

                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div className={classes.margin}>
                              <Grid container spacing={1} alignItems="flex-end" style={{ border: '2px solid #1F40B0', marginBottom: '10px'}}>
                                <Grid item>
                                  <Typography variant='h6' color='textPrimary'>
                                    <span style={{ color: 'black', fontWeight: 'bold' }}>Si quieres relacionar al cajero con alguna tienda favor de escribir la ClaUn.</span>
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} alignItems="flex-end" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Grid item>
                                  <TextField name='warehouse' 
                                  id="input-with-icon-grid" 
                                  label="Claun de tienda" 
                                  variant='outlined' 
                                  onChange={handleChangeCreateWarehouse} 
                                  value={createWarehouse} 
                                  inputProps={{
                                    pattern: "^[a-zA-Z0-9]+$"
                                  }}/>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>

                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
                            <Button variant='contained' color='primary' disabled={Boolean(!createWarehouse)} onClick={sendInfoToCreateWarehouseAttendant}>
                              Crear relacion de empleado
                            </Button>
                          </Grid>
                          
                          {/* <Grid item xs={6} >
                            <h1>OLAAAAA</h1>
                          </Grid> */}
                        </Grid>
                      </Paper>
                      : 
                      null
                    }
                </Grid>
            </Grid>
        </Container>
        {/* DIALOG */}
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          // fullWidth
          // className='shadow-drop-2-center'
          // className='scale-up-center'
          >
            {type === 0 ?
              // Messagge
              <>
                <DialogTitle color="primary" style={{ borderBottom: '1px solid #C1C1C1', backgroundColor: '#004E98', color: '#fff' }} variant='h5'>{title}</DialogTitle>
                <DialogContent
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '600px',
                    height: 'calc(300px - (64px + 60.5px))',
                    padding: '0px'
                  }}
                >
                  <div id="alert-dialog-slide-description">
                    <Typography variant='h6' style={{ fontWeight: 'lighter', padding: '0 56px', textAlign: 'center' }}>
                      {body}
                    </Typography>
                  </div>
                </DialogContent>
              </>

              : type === 1 ?
                // Loading
                <DialogContent
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '600px',
                    height: 'calc(300px - 16px)',
                    padding: '0px'
                  }}
                >
                  <div id="alert-dialog-slide-description" style={{ display: 'flex', flexDirection: 'column', gap: '23px' }}>
                    <CircularProgress size={90} style={{ width: '100%', justifyContent: 'center', display: 'flex' }}></CircularProgress> <br />
                  </div>
                  <Typography style={{ margin: '0px', padding: '0 56px', fontWeight: 'lighter' }}>
                    {body && body.length > 0 ? body : 'Espere un momento, por favor...'}
                  </Typography>

                </DialogContent>

                : type === 2 ?
                  // Success
                  <DialogContent
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '600px',
                      height: 'calc(300px - 52.5px)',
                      padding: '0px'
                    }}
                  >
                    <div id="alert-dialog-slide-description" style={{ display: 'flex', flexDirection: 'column', gap: '34px' }}>
                      <div style={{ width: '100%', justifyContent: 'center', display: 'flex', color: 'green' }}>
                        <CheckCircleOutlineIcon size={320} style={{ fontSize: '144px' }} /> <br />
                      </div>
                      <Typography variant='h6' style={{ fontWeight: 'lighter' }}>
                        {body}
                      </Typography>
                    </div>
                  </DialogContent>

                  : type === 3 ?
                    // Error
                    <DialogContent
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '600px',
                        height: 'calc(300px - 52.5px)',
                        padding: '0px'
                      }}
                    >
                      <div id="alert-dialog-slide-description" style={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
                        <div style={{ width: '100%', justifyContent: 'center', display: 'flex', color: 'orange' }}>
                          <ErrorOutlineIcon size={320} style={{ fontSize: '144px' }} /> <br />
                        </div>
                        <Typography variant='h6' style={{ padding: '0 34px', textAlign: 'center', fontWeight: 'lighter' }}>
                          {body}
                        </Typography>
                      </div>
                    </DialogContent>
                    : null
            }
            <DialogActions style={{ paddingBottom: '16px', paddingRight: '24px' }}>
              {buttonCancel ?
                <Button variant='contained' color='inherit' onClick={handleCancel}><strong>Cancelar</strong></Button>
                : null
              }
              {buttonAccept ?
                <Button variant='contained' color='primary' onClick={handleAccept}><strong>Aceptar</strong></Button>
                : null
              }
              {buttonClose ?
                <Button variant='contained' color='primary' onClick={handleClose}><strong>Aceptar</strong></Button>
                : null
              }
              {
                buttonAcceptDialogSave ?
                  <Button variant='contained' color='primary' onClick={buttonAcceptDialogSave.onClick}><strong>Guardar</strong></Button>
                  : null
              }

            </DialogActions>
          </Dialog>
        </div>
    </Box>
  )
}

export default withSnackbar(CollaboratorSearch)
