// React
import React, { Component } from 'react';

// Amplify I18
import { I18n } from 'aws-amplify';

// Localize for Multilanguaje
import i18n from "i18n-js";
import memoize from "lodash.memoize";
import { Button } from '@material-ui/core';

// fallback if no available language fits
const languageTag = "es";

export default class SupportMultiLanguaje extends Component {
  constructor(props) {
    super(props);
    this.setI18nConfig(languageTag); // set initial config
    // console.log(props)
    if (props.navigate)
      this.navigate = props.navigate

    this.showMessage = this.showMessage.bind(this);
  }

  handleLocalizationChange = () => {
    this.setI18nConfig(languageTag);
    this.forceUpdate();
  };

  go = (routeName) => {
    // console.log("go", routeName, this.props, this.navigate);
    // return routeName && this.props && this.props.navigation ? this.props.navigation.navigate(routeName) : null;

    // this.props.navigate(routeName);
    this.navigate(routeName);
  }

  translationGetters = {
    // lazy requires (metro bundler does not support symlinks)
    es: () => require("../i18n/es.json"),
    en: () => require("../i18n/en.json"),
  };

  translate = memoize(
    (key, config) => {
      if (key !== undefined) {
        if (String(key).lastIndexOf(".") !== -1) {
          key = key.substr(0, String(key).lastIndexOf("."));
        }
        // console.log(JSON.stringify(key.substr(0, key.lastIndexOf(".")) + ''), JSON.stringify(config));
        var _t = i18n.t(key + '', config);
        return _t;
      }
    },
    (key, config) => (config ? key + JSON.stringify(config) : key)
  );

  setI18nConfig = (languageTag) => {
    // clear translation cache
    this.translate.cache.clear();

    // set i18n-js config
    i18n.translations = { [languageTag]: this.translationGetters[languageTag]() };
    i18n.locale = languageTag;

    // I18
    I18n.setLanguage(languageTag);
    I18n.putVocabularies(this.translationGetters);
  };

  action = key => {
    return (<Button onClick={() => this.props.closeSnackbar(key)}>{this.translate("Dissmiss me")}</Button>);
  };

  showMessage(message, msg_type, msg_duration, btn_msg, msg_vertical, msg_horizontal, msg_content, msg_persist, msg_action, props) {
    this.props = {...this.props, ...props}
    return this.props && this.props.enqueueSnackbar ? this.props.enqueueSnackbar(message, {
      variant: msg_type,
      autoHideDuration: msg_duration ? msg_duration : 3000,
      anchorOrigin: {
        vertical: msg_vertical ? msg_vertical : 'bottom',
        horizontal: msg_horizontal ? msg_horizontal : 'left',
      },
      persist: msg_persist,
      content: (key) => (msg_content),
      action: msg_action ? msg_action : this.action,
    }) : (props && props.enqueueSnackbar ? props.enqueueSnackbar(message, {
      variant: msg_type,
      autoHideDuration: msg_duration ? msg_duration : 3000,
      anchorOrigin: {
        vertical: msg_vertical ? msg_vertical : 'bottom',
        horizontal: msg_horizontal ? msg_horizontal : 'left',
      },
      persist: msg_persist,
      content: (key) => (msg_content),
      action: msg_action ? msg_action : this.action,
    }) : null);
  }
}