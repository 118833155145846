import React, { Component } from 'react';
import { connect  } from 'react-redux';
import { Button, Grid, CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  isOrganizationsloading,
  getOrganizationsdata,
} from '../../../store/organizations/selectors';
import { getModalSelectorFatherScreen } from '../../../store/modalSelector/selectors';
import { setModalselectorShow } from '../../../store/modalSelector/actions';

import { setKPIorganization } from '../../../store/kpi/actions';
import { setIndicatorOrganization } from '../../../store/indicator/actions';
import { setReportsOrganization } from '../../../store/reports/actions';
import SessionControl from '../../../utils/SessionControl';

export class ModalSelectorOrganizations extends SessionControl {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      user: this.getSecureStore().UserProfileReducer &&
      this.getSecureStore().UserProfileReducer.user
      ? this.getSecureStore().UserProfileReducer.user
      : [],
      organizations:[]
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const { paramProfile, position } = this.state.user;
    let listPermisionOrganization = JSON.parse(JSON.parse(paramProfile))
    let listOrganization = []
    /// ** FILTRO POR EMPRESA QUE NO PUEDA ACCEDER
    data && data.forEach(organization => {
      listPermisionOrganization.filter(r => r.uuid === position.id).forEach(_e => {
        _e.organization.forEach(_a => {
          if (_a.uuid === organization.id) {
            listOrganization.push(organization)
          }
        });
      });
    });
    //console.log("ModalSelectorOrganizations",data,listOrganization,listOrganization,this.state,this.props)
    this.setState({
      organizations:listOrganization
    })
  }

  selectValue(payload) {
    this.setState({
      ...this.state,
      value: payload,
    });
  }

  setValue() {
    const {
      screen,
      modalasSelectorShow,
      setKPIorganization,
      setIndicatorOrganization,
      setReportsOrganization,
    } = this.props;
    const { value } = this.state;

    switch (screen) {
      case 'KPI':
        setKPIorganization({ name: value.name, id: value.id });
        break;
      case 'indicator':
        setIndicatorOrganization({ name: value.name, id: value.id });
        break;
      case 'reports':
        setReportsOrganization({ name: value.name, id: value.id });
        break;

      default:
        break;
    }

    modalasSelectorShow(false);
  }

  render() {
    const { loading, modalasSelectorShow } = this.props;
    const { value , organizations} = this.state;
    return (
      <div>
        <h2 id="transition-modal-title">Selecciona empresa:</h2>
        {loading ? (
          <CircularProgress className={'loading-indicator'} />
        ) : organizations.length === 0 ? (
          <span className={'error-txt'}>
            Error: No se encotraron resultados
          </span>
        ) : (
          <div className={'select-wrapper'}>
            <Autocomplete
              className={'selector'}
              id="combo-box-demo"
              options={organizations}
              getOptionLabel={organizations => organizations.name}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Empresa"
                  variant="outlined"
                  className={'selector'}
                />
              )}
              onChange={(event, newValue) => this.selectValue(newValue)}
            />
          </div>
        )}
        <Grid className={'btn-wrapper'} container spacing={3}>
          <Grid item xs={12}>
            <Button
              className={'btn accept-btn'}
              variant="contained"
              disabled={value ? false : true}
              onClick={() => this.setValue()}
            >
              Aceptar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={'btn cancel-btn'}
              onClick={() => modalasSelectorShow(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: isOrganizationsloading(state),
  data: getOrganizationsdata(state),
  screen: getModalSelectorFatherScreen(state),
});

const mapDispatchToProps = dispatch => ({
  modalasSelectorShow: payload => dispatch(setModalselectorShow(payload)),
  setKPIorganization: payload => dispatch(setKPIorganization(payload)),
  setIndicatorOrganization: payload =>
    dispatch(setIndicatorOrganization(payload)),
  setReportsOrganization: payload => dispatch(setReportsOrganization(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalSelectorOrganizations);
