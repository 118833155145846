import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Slide
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { last } from 'lodash';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
      '& > *': {
          margin: theme.spacing(1),
      },
  },
  fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
  },
  appBar: {
      position: 'relative',
  },
  title: {
      marginLeft: theme.spacing(2),
      flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StepsFooter(props) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [activeKPILocal, setactiveKPILocal] = React.useState(props.activeKPI);
  const [successSaveKPI, setSuccessSaveKPI] = React.useState(false);

  useEffect(() => {
    console.log(props)
    if(props && props.isSending){
      setTimeout(() => {
        setSuccessSaveKPI(true)
        setTimeout(() => {
          setSuccessSaveKPI(false)
        }, 2500);
      }, 2000);

     // props.showMessage('La consulta no devolvió datos... por favor verifique que los datos sean correctos', SNACKBAR_WARNING, 10000, null, null, null, null, null, null, props);
    }

    return function cleanup() {
    };
}, [props.isSending])

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const confirmDelete = itemID => {
    setOpenDialog(false);
    props.delete(itemID);
  };

  const isValidStep = (lastStep, isLastFunctionValid) => {
    let isValid = false;
    let lastItem = last(lastStep);

    if (typeof lastItem !== 'undefined') {
      if (lastStep.length === 1) {
        if (lastItem.value.selected === 'variable') {
          isValid = true;
        } else if (lastItem.value.selected === 'function') {
          if (isLastFunctionValid) {
            isValid = true;
          }
        }
      } else if (lastItem.type !== 'operator') {
        isValid = true;
      }
    }
    return isValid;
  };

  const deleteTitle = type => {
    let title;

    switch (type) {
      case 'createFunction':
        title = '¿Deseas eliminar está función?';
        break;

      case 'KPI':
        title = '¿Deseas eliminar está Comisión Variable?';
        break;

      case 'indicator':
        title = '¿Deseas eliminar este Indicador?';
        break;
      default:
        break;
    }

    return title;
  };

  return (
    
    <div className={'steps-footer'}>
      {props.isEditing && (
        <div>
          {props.isDeleting ? (
            <Button
              className={'btn delete-btn'}
              variant="contained"
              disabled={true}
            >
              <CircularProgress className={'loading'} />
            </Button>
          ) : (
            <Button
              variant="contained"
              className={'btn delete-btn'}
              onClick={handleOpenDialog}
            >
              Eliminar
            </Button>
          )}
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {deleteTitle(props.type)}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancelar
              </Button>
              <Button
                onClick={() => confirmDelete(props.editID)}
                color="primary"
                autoFocus
              >
                Si
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}

      <Grid className={'btn'} container spacing={3}>
        <Grid item xs={12}>
          <Button
            className={'btn accept-btn'}
            onClick={() => props.testIndicator()}
            disabled={
              isValidStep(props.lastStep, props.isLastFunctionValid)
                ? false
                : true
            }
          >
            Probar
          </Button>
        </Grid>
        <Grid item xs={12}>
          {props.isSending ? (
            <Button
              className={'btn save-btn'}
              variant="contained"
              disabled={true}
            >
              <CircularProgress className={'loading'} />
            </Button>
          ) : (
            <Button
              className={'btn save-btn'}
              variant="contained"
                onClick={() => {
                  console.log(props)
                  setactiveKPILocal(!activeKPILocal)
                  props.setKPIActive(!activeKPILocal)
                 }
                }
            >
             {activeKPILocal ? "KPI ACTIVADO": "KPI INACTIVO"} 
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          {props.isSending ? (
            <Button
              className={'btn save-btn'}
              variant="contained"
              disabled={true}
            >
              <CircularProgress className={'loading'} />
            </Button>
          ) : (
            <Button
              className={'btn save-btn'}
              variant="contained"
                onClick={() => {
                  console.log(props)
                  console.log(props.isEditing ? "edit" : "save")
                  console.log(props.editID)
                  props.isEditing ? props.edit(props.editID) : props.save()
                 }
                }
              disabled={props.testResult ? false : true}
            >
              Guardar
            </Button>
          )}
        </Grid>
        {successSaveKPI ?
          <Alert variant="outlined" severity="success">
            Tú KPI se guardo correctamente!
          </Alert> : ""}
      </Grid>
    </div>
  );
}
