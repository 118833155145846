import {
    USER_PROFILE
} from '../ActionTypes';

export const UserProfile_inProgress = data => ({
    type: USER_PROFILE.IN_PROGRESS,
    data
});

export const UserProfile_onSuccess = data => ({
    type: USER_PROFILE.SUCCESS,
    data
});

export const UserProfile_onError = data => ({
    type: USER_PROFILE.ERROR,
    data
});
