import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, AppBar, Paper, Typography, Dialog, Toolbar, Button, Slide, ListItemIcon, ListItemText } from '@material-ui/core';
import MaterialTable from "material-table";
import { tableIcons, localization } from '../../components/materialTable/MaterialTable';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';


import { listTeamPositions,listPositions } from '../../graphql/queries'
import { updateTeamPosition,createTeamPosition,deleteTeamPosition } from '../../graphql/mutations'
import { api, apiPartial } from "../../api/api";


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


function TempletePosition(props) {
    const firstRender = useRef(true)
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [Position, setPosition] = React.useState(null);
    const [NamePosition, setNamePosition] = React.useState(null);
    const [TeamPosition, setTeamPosition] = React.useState(null);

    

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render

            loadPositions()

            return // skip the code below
        }

        return function cleanup() {

        };
    }, []) // Only re-run the effect if name or email change

   
    const loadPositions = () => {
        api(listPositions, { limit: 300 })
          .then((res) => {

          setPosition(res)
          let listNamePositions=[]
          res.forEach(_e => {
            listNamePositions.push(_e.positionName)
          });
         
          setNamePosition(listNamePositions)
          loadTeamPositions()

            })
          .catch((err) => {
            console.log(err);
          });
      };

      const loadTeamPositions = () => {
        api(listTeamPositions, { limit: 300 })
          .then((res) => {

          let itemFilter = res.filter((pos) => pos.workTeamTemplate.id === props.WorkTeam.id && pos._deleted === null);
          setTeamPosition(itemFilter)

            })
          .catch((err) => {
            console.log(err);
          });
      };
    
    
      const createTeamPositionMethod = (param) => {
        api(createTeamPosition, { input: param })
          .then((res) => {

            loadPositions()
           // setPosition([...TeamPosition, res]);

            })
          .catch((err) => {
            console.log(err);
          });
      };
    

      const UpdTeamPositionMethod = (param) => {
        api(updateTeamPosition, { input: param })
          .then((res) => {

            loadPositions()

            })
          .catch((err) => {
            console.log(err);
          });
      };
    

      const DeletedTeamPositionMethod = (param) => {
        api(deleteTeamPosition, { input: param })
          .then((res) => {

            loadPositions()

            })
          .catch((err) => {
            console.log(err);
          });
      };

    return (
      <Box p={8}>
        <Typography variant="h6" color="textSecondary">
           {props.translate("Table Positions")}
        </Typography>
        <Grid item xs={12}>
          {TeamPosition !== null && (
            <Grid item xs={12}>
              <Paper>
                <MaterialTable
                  icons={tableIcons}
                  localization={localization(props)}
                  options={{
                    exportButton: true,
                  }}
                  columns={[
                    {
                      field: "position.positionName",
                      title: props.translate("Position"), 
                      lookup: {"Owner":props.translate("Owner"),"Representative Account":props.translate("Representative Account"),"Operations Assistant":props.translate("Operations Assistant"),"Floor Helper":props.translate("Floor Helper"),"Warehouse Assistant":props.translate("Warehouse Assistant"),"Sales":props.translate("Sales"), "IT":props.translate("IT"),"Attendant Manager":props.translate("Attendant Manager"), "Attendant": props.translate("Attendant") },
                    },
                    {
                      field: "quantity",
                      title: props.translate("Quantity"), 
                      type: 'numeric'

                    },
                    {
                      field: "required",
                      title: props.translate("Required"),
                      lookup: { "true": 'Si', "false": 'No' },
                    },
                    {
                      field: "observation",
                      title: props.translate("Observation"),
                    },
                  ]}
                  data={TeamPosition}
                  editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {

                            let itemFilter = Position.filter((pos) => pos.positionName === newData.position.positionName);
                            var isTrueSet = (newData.required === 'true');
                            let param = {
                                observation: newData.observation,
                                teamPositionPositionId: itemFilter[0].id,
                                quantity: newData.quantity,
                                required: isTrueSet,
                                teamPositionWorkTeamTemplateId:props.WorkTeam.id
                            }

                            createTeamPositionMethod(param)
                          resolve();
                        }, 1000)
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...TeamPosition];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;

                         // setPosition([...dataUpdate]);
                         let itemFilter = Position.filter((pos) => pos.positionName === newData.position.positionName);
                         var isTrueSet = (newData.required === 'true');
                         let param = {
                            id: newData.id,
                            observation: newData.observation,
                            teamPositionPositionId: itemFilter[0].id,
                            quantity: newData.quantity,
                            required: isTrueSet,
                            teamPositionWorkTeamTemplateId:props.WorkTeam.id,
                            _version:newData._version
                        }

                        UpdTeamPositionMethod(param)
            
                          resolve();
                        }, 1000)
                      }),
                      onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                          setTimeout(() => {
                              const dataDelete = [...TeamPosition];
                              const index = oldData.tableData.id;
                             
                              let params = {
                                id:TeamPosition[index].id,
                                _version:TeamPosition[index]._version
                              }

                              DeletedTeamPositionMethod(params)
          
                              resolve();
                          }, 1000);
                      })
                  }}
                  title={""} //
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    );
}
export default withSnackbar(TempletePosition);