import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import {
  FETCH_VARIABLES,
  SET_VARIABLES,
  SET_VARIABLES_ERROR,
} from './constants';

const fetVariables = (state, payload) => ({
  ...state,
  loading: true,
  error: false,
});

const setVariables = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  data: action.payload,
});

const setVariablesError = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

export const reducer = handleActions(
  {
    [FETCH_VARIABLES]: fetVariables,
    [SET_VARIABLES]: setVariables,
    [SET_VARIABLES_ERROR]: setVariablesError,
  },
  INITIAL_STATE,
);
