import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button, Typography, Container } from '@material-ui/core';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { tableESP } from '../../../helpers';
import { resetIndicatorData } from '../../../store/indicator/actions';
import Breadcrumbs from '../../../components/Breadcrumbs';
import API from '../../../services/api';
import SessionControlSupportMultiLanguaje from '../../../utils/SessionControl';

import './styles.scss';

export class ListIndicators extends SessionControlSupportMultiLanguaje {
  constructor(props) {
    super(props);
    this.state = {
      indicators: '',
      error: false,
      permision_read:false,
      permision_write:false,
    };
  }

  getIndicators = async areaId => {
    try {
      const response = await API.getIndicators(areaId);
      let { data } = response;
      this.setState({ indicators: data, error: false });
    } catch (error) {
      this.setState({ error: true });
    }
  };

  async componentDidMount() {
    const { params } = this.props;
    this.getIndicators(params.areaId);

    let jsonParamProfile = JSON.parse(JSON.parse(this.getSecureStore().UserProfileReducer.user.paramProfile))
    //console.log(jsonParamProfile)
    //console.log(this.getSecureStore().UserProfileReducer.user.position)
    /// ** FILTRO POR PERMISO DE LECTURA Y ESCRITURA
    jsonParamProfile.filter(r => r.uuid === this.getSecureStore().UserProfileReducer.user.position.id).forEach(_e => {
        // console.log(_e.path_permissions)
        _e.path_permissions.forEach(_p => {
            if (_p.name === "Indicators") {
                this.setState({
                    permision_read: _p.read,
                    permision_write: _p.write
                });
            }
        });

    });

    this.setState({
      indicator: this.getSecureStore().indicator
    })
  }

  createIndicator() {
    this.props.resetData();
    this.props.navigate(`/prometheus/variableCommissions/indicadores/crear/`);
  }

  render() {
    const { indicators, indicator, error } = this.state;

    return (
      <Container fixed>
        <div className={'indicators-list'}>
          <div className="breadcrumbs-container-wrapper">
            <Breadcrumbs {...this.props}
              navigation={[
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                { title: 'Buscar Indicadores', url: '/prometheus/variableCommissions/indicadores' },
                { title: 'Lista Indicadores', current: true },
              ]}
            />
          </div>
          {this.state.permision_write ?<Button
            className={'small-btn'}
            variant="contained"
            color="primary"
            onClick={() => this.createIndicator()}
          >
            Crear nuevo Indicador
        </Button> : ""}
          <Typography variant="h5" gutterBottom>
            {indicator && indicator.organization && indicator.organization.name}
          </Typography>
          {error === true ? (
            <div className={'error-msg'}>No se encontraron resultados</div>
          ) : isEmpty(indicators) ? (
            <LoadingIndicator />
          ) :(
                <MaterialTable
                  title={`${indicator && indicator.area && indicator.area.name}`}
                  data={indicators}
                  columns={[
                    /*{ title: 'Id', field: 'id' },*/
                    { title: 'Nombre', field: 'name' },
                    {
                      render: rowData => (
                        <Button
                          className={'small-btn'}
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={`/prometheus/variableCommissions/indicadores/detalle/${rowData.id}/${rowData.name}`}
                        >
                          Editar
                        </Button>
                      ),
                    },
                  ]}
                  localization={tableESP}
                />
              )}
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetData: () => dispatch(resetIndicatorData()),
});

export default connect(null, mapDispatchToProps)(ListIndicators);
