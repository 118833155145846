/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const mtcServicePayment = /* GraphQL */ `
  mutation MtcServicePayment(
    $service_config: AWSJSON
    $account_detail: AWSJSON
    $service_payment: AWSJSON
    $balance_inquiry: AWSJSON
    $query: AWSJSON
    $reference_query: AWSJSON
  ) {
    mtcServicePayment(
      service_config: $service_config
      account_detail: $account_detail
      service_payment: $service_payment
      balance_inquiry: $balance_inquiry
      query: $query
      reference_query: $reference_query
    )
  }
`;
export const mtcTelephonicRecharge = /* GraphQL */ `
  mutation MtcTelephonicRecharge(
    $service_config: AWSJSON
    $account_detail: AWSJSON
    $telephonic_recharge: AWSJSON
    $balance_inquiry: AWSJSON
    $recharge_query: AWSJSON
  ) {
    mtcTelephonicRecharge(
      service_config: $service_config
      account_detail: $account_detail
      telephonic_recharge: $telephonic_recharge
      balance_inquiry: $balance_inquiry
      recharge_query: $recharge_query
    )
  }
`;
export const bamaws = /* GraphQL */ `
  mutation Bamaws(
    $service_config: AWSJSON
    $update_database_size: AWSJSON
    $confirm_update: AWSJSON
    $get_sync: AWSJSON
    $get_sync_time: AWSJSON
    $get_status_daily_sync: AWSJSON
    $get_script: AWSJSON
    $sync: AWSJSON
    $sync_str: AWSJSON
    $check_version: AWSJSON
    $sync_sales_dailys_conviene: AWSJSON
    $params: AWSJSON
    $InsertBreakdownCash: AWSJSON
  ) {
    bamaws(
      service_config: $service_config
      update_database_size: $update_database_size
      confirm_update: $confirm_update
      get_sync: $get_sync
      get_sync_time: $get_sync_time
      get_status_daily_sync: $get_status_daily_sync
      get_script: $get_script
      sync: $sync
      sync_str: $sync_str
      check_version: $check_version
      sync_sales_dailys_conviene: $sync_sales_dailys_conviene
      params: $params
      InsertBreakdownCash: $InsertBreakdownCash
    )
  }
`;
export const cinepolis = /* GraphQL */ `
  mutation Cinepolis(
    $service_config: AWSJSON
    $signin: AWSJSON
    $saleVoucher: AWSJSON
    $listVoucherTypes: AWSJSON
  ) {
    cinepolis(
      service_config: $service_config
      signin: $signin
      saleVoucher: $saleVoucher
      listVoucherTypes: $listVoucherTypes
    )
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($email: AWSJSON) {
    sendEmail(email: $email)
  }
`;
export const sendSms = /* GraphQL */ `
  mutation SendSms($sms: AWSJSON) {
    sendSMS(sms: $sms)
  }
`;
export const forecast = /* GraphQL */ `
  mutation Forecast($forecast: AWSJSON) {
    forecast(forecast: $forecast)
  }
`;
export const athenaOperation = /* GraphQL */ `
  mutation AthenaOperation($params: AWSJSON) {
    athenaOperation(params: $params)
  }
`;
export const sra = /* GraphQL */ `
  mutation Sra($params: AWSJSON) {
    sra(params: $params)
  }
`;
export const teria = /* GraphQL */ `
  mutation Teria($params: AWSJSON) {
    teria(params: $params)
  }
`;
export const xadisws = /* GraphQL */ `
  mutation Xadisws($params: AWSJSON) {
    xadisws(params: $params)
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      productId
      productName
      sku
      description
      averageCost
      currentSellPrice
      maxSellingPrice
      inStock
      active
      marketReleaseDate
      marketFinalDate
      sellable
      purchable
      s3keyImage
      category {
        id
        categoryId
        categoryName
        parentCategoryId
        s3keyImage
        isNew
        categoryDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      brand {
        id
        brandId
        brandName
        parentBrandId
        s3keyImage
        brandDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleUnit {
        id
        saleUnitId
        saleUnitName
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sourcing {
        id
        sourcingId
        sourcingName
        parentSourcingId
        address
        location {
          coordinates
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productKitItems {
        items {
          id
          productOptions
          quantity
          currentSellPrice
          percentDiscount
          percentOfer
          amountDiscount
          amountOfer
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnables {
        items {
          id
          startValidity
          cancellationValidity
          returnableStatus
          received
          quantity
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnableContainerProductId
      serviceConfig {
        serviceIntegrationId
        params {
          param
          value
        }
      }
      mtcprops {
        code
        amount
        verification_code
        serviceIntegrationId
        operation
        params
        reference {
          minLength
          maxLength
          name
          s3keyImage
        }
      }
      barcodes
      taxes {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discounts {
        items {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      quantityPerBox
      pricings {
        items {
          id
          marketReleaseDate
          marketFinalDate
          amount
          pricingType
          pricingName
          priceId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      organizationId
      salesHours {
        startTime
        endTime
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      productId
      productName
      sku
      description
      averageCost
      currentSellPrice
      maxSellingPrice
      inStock
      active
      marketReleaseDate
      marketFinalDate
      sellable
      purchable
      s3keyImage
      category {
        id
        categoryId
        categoryName
        parentCategoryId
        s3keyImage
        isNew
        categoryDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      brand {
        id
        brandId
        brandName
        parentBrandId
        s3keyImage
        brandDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleUnit {
        id
        saleUnitId
        saleUnitName
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sourcing {
        id
        sourcingId
        sourcingName
        parentSourcingId
        address
        location {
          coordinates
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productKitItems {
        items {
          id
          productOptions
          quantity
          currentSellPrice
          percentDiscount
          percentOfer
          amountDiscount
          amountOfer
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnables {
        items {
          id
          startValidity
          cancellationValidity
          returnableStatus
          received
          quantity
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnableContainerProductId
      serviceConfig {
        serviceIntegrationId
        params {
          param
          value
        }
      }
      mtcprops {
        code
        amount
        verification_code
        serviceIntegrationId
        operation
        params
        reference {
          minLength
          maxLength
          name
          s3keyImage
        }
      }
      barcodes
      taxes {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discounts {
        items {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      quantityPerBox
      pricings {
        items {
          id
          marketReleaseDate
          marketFinalDate
          amount
          pricingType
          pricingName
          priceId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      organizationId
      salesHours {
        startTime
        endTime
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      productId
      productName
      sku
      description
      averageCost
      currentSellPrice
      maxSellingPrice
      inStock
      active
      marketReleaseDate
      marketFinalDate
      sellable
      purchable
      s3keyImage
      category {
        id
        categoryId
        categoryName
        parentCategoryId
        s3keyImage
        isNew
        categoryDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      brand {
        id
        brandId
        brandName
        parentBrandId
        s3keyImage
        brandDescription
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleUnit {
        id
        saleUnitId
        saleUnitName
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sourcing {
        id
        sourcingId
        sourcingName
        parentSourcingId
        address
        location {
          coordinates
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productKitItems {
        items {
          id
          productOptions
          quantity
          currentSellPrice
          percentDiscount
          percentOfer
          amountDiscount
          amountOfer
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnables {
        items {
          id
          startValidity
          cancellationValidity
          returnableStatus
          received
          quantity
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnableContainerProductId
      serviceConfig {
        serviceIntegrationId
        params {
          param
          value
        }
      }
      mtcprops {
        code
        amount
        verification_code
        serviceIntegrationId
        operation
        params
        reference {
          minLength
          maxLength
          name
          s3keyImage
        }
      }
      barcodes
      taxes {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discounts {
        items {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      quantityPerBox
      pricings {
        items {
          id
          marketReleaseDate
          marketFinalDate
          amount
          pricingType
          pricingName
          priceId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      organizationId
      salesHours {
        startTime
        endTime
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      categoryId
      categoryName
      parentCategoryId
      s3keyImage
      isNew
      categoryDescription
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      categoryId
      categoryName
      parentCategoryId
      s3keyImage
      isNew
      categoryDescription
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      categoryId
      categoryName
      parentCategoryId
      s3keyImage
      isNew
      categoryDescription
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      brandId
      brandName
      parentBrandId
      s3keyImage
      brandDescription
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      brandId
      brandName
      parentBrandId
      s3keyImage
      brandDescription
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      brandId
      brandName
      parentBrandId
      s3keyImage
      brandDescription
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSaleUnit = /* GraphQL */ `
  mutation CreateSaleUnit(
    $input: CreateSaleUnitInput!
    $condition: ModelSaleUnitConditionInput
  ) {
    createSaleUnit(input: $input, condition: $condition) {
      id
      saleUnitId
      saleUnitName
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSaleUnit = /* GraphQL */ `
  mutation UpdateSaleUnit(
    $input: UpdateSaleUnitInput!
    $condition: ModelSaleUnitConditionInput
  ) {
    updateSaleUnit(input: $input, condition: $condition) {
      id
      saleUnitId
      saleUnitName
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaleUnit = /* GraphQL */ `
  mutation DeleteSaleUnit(
    $input: DeleteSaleUnitInput!
    $condition: ModelSaleUnitConditionInput
  ) {
    deleteSaleUnit(input: $input, condition: $condition) {
      id
      saleUnitId
      saleUnitName
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider(
    $input: CreateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    createProvider(input: $input, condition: $condition) {
      id
      providerId
      providerName
      cronVisits
      phones {
        phoneType
        phone
      }
      address
      email
      giveCredit
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProvider = /* GraphQL */ `
  mutation UpdateProvider(
    $input: UpdateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    updateProvider(input: $input, condition: $condition) {
      id
      providerId
      providerName
      cronVisits
      phones {
        phoneType
        phone
      }
      address
      email
      giveCredit
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProvider = /* GraphQL */ `
  mutation DeleteProvider(
    $input: DeleteProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    deleteProvider(input: $input, condition: $condition) {
      id
      providerId
      providerName
      cronVisits
      phones {
        phoneType
        phone
      }
      address
      email
      giveCredit
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSourcing = /* GraphQL */ `
  mutation CreateSourcing(
    $input: CreateSourcingInput!
    $condition: ModelSourcingConditionInput
  ) {
    createSourcing(input: $input, condition: $condition) {
      id
      sourcingId
      sourcingName
      parentSourcingId
      address
      location {
        coordinates
      }
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSourcing = /* GraphQL */ `
  mutation UpdateSourcing(
    $input: UpdateSourcingInput!
    $condition: ModelSourcingConditionInput
  ) {
    updateSourcing(input: $input, condition: $condition) {
      id
      sourcingId
      sourcingName
      parentSourcingId
      address
      location {
        coordinates
      }
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSourcing = /* GraphQL */ `
  mutation DeleteSourcing(
    $input: DeleteSourcingInput!
    $condition: ModelSourcingConditionInput
  ) {
    deleteSourcing(input: $input, condition: $condition) {
      id
      sourcingId
      sourcingName
      parentSourcingId
      address
      location {
        coordinates
      }
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      id
      scheduleId
      entry
      exit
      scheduleName
      tolerance
      roles {
        items {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      scheduleType {
        id
        scheduleTypeName
        schedules {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      id
      scheduleId
      entry
      exit
      scheduleName
      tolerance
      roles {
        items {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      scheduleType {
        id
        scheduleTypeName
        schedules {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      scheduleId
      entry
      exit
      scheduleName
      tolerance
      roles {
        items {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      scheduleType {
        id
        scheduleTypeName
        schedules {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createScheduleType = /* GraphQL */ `
  mutation CreateScheduleType(
    $input: CreateScheduleTypeInput!
    $condition: ModelScheduleTypeConditionInput
  ) {
    createScheduleType(input: $input, condition: $condition) {
      id
      scheduleTypeName
      schedules {
        items {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateScheduleType = /* GraphQL */ `
  mutation UpdateScheduleType(
    $input: UpdateScheduleTypeInput!
    $condition: ModelScheduleTypeConditionInput
  ) {
    updateScheduleType(input: $input, condition: $condition) {
      id
      scheduleTypeName
      schedules {
        items {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteScheduleType = /* GraphQL */ `
  mutation DeleteScheduleType(
    $input: DeleteScheduleTypeInput!
    $condition: ModelScheduleTypeConditionInput
  ) {
    deleteScheduleType(input: $input, condition: $condition) {
      id
      scheduleTypeName
      schedules {
        items {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAttendanceRecord = /* GraphQL */ `
  mutation CreateAttendanceRecord(
    $input: CreateAttendanceRecordInput!
    $condition: ModelAttendanceRecordConditionInput
  ) {
    createAttendanceRecord(input: $input, condition: $condition) {
      id
      datetime
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      records {
        datetime
        attendanceRecordType
      }
      role {
        id
        roleId
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        day
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAttendanceRecord = /* GraphQL */ `
  mutation UpdateAttendanceRecord(
    $input: UpdateAttendanceRecordInput!
    $condition: ModelAttendanceRecordConditionInput
  ) {
    updateAttendanceRecord(input: $input, condition: $condition) {
      id
      datetime
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      records {
        datetime
        attendanceRecordType
      }
      role {
        id
        roleId
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        day
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttendanceRecord = /* GraphQL */ `
  mutation DeleteAttendanceRecord(
    $input: DeleteAttendanceRecordInput!
    $condition: ModelAttendanceRecordConditionInput
  ) {
    deleteAttendanceRecord(input: $input, condition: $condition) {
      id
      datetime
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      records {
        datetime
        attendanceRecordType
      }
      role {
        id
        roleId
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        day
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAttendant = /* GraphQL */ `
  mutation CreateAttendant(
    $input: CreateAttendantInput!
    $condition: ModelAttendantConditionInput
  ) {
    createAttendant(input: $input, condition: $condition) {
      id
      attendantId
      fullName
      userName
      hashPassword
      nipPassword
      tokenRequest
      profilePhoto
      limitEpoch
      managerAttendantId
      active
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      roles {
        items {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      phones {
        phoneType
        phone
      }
      emails {
        emailType
        email
      }
      fingers {
        fingerType
        fingerTemplate
      }
      position {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashRegisterShifts {
        items {
          id
          openCashRegisterShift
          closeCashRegisterShift
          cashRegisterShiftType
          cashRegisterShiftStatusType
          registerId
          sync
          sales
          cashRegisterShifts
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      cashDrawers {
        items {
          id
          datetime
          cashDrawerType
          cashFlowType
          amount
          registerId
          controlId
          sync
          file
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        id
        datetime
        day
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouseId
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentType {
          id
          incidentTypeId
          incidentTypeName
          parentIncidentTypeId
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        description
        headcountCount
        vacantLog {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discounts {
        items {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attendanceRecords {
        items {
          id
          datetime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      xadisEmptyContainers {
        items {
          id
          startDate
          endDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplanExecution {
        items {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      color
      organizationId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAttendant = /* GraphQL */ `
  mutation UpdateAttendant(
    $input: UpdateAttendantInput!
    $condition: ModelAttendantConditionInput
  ) {
    updateAttendant(input: $input, condition: $condition) {
      id
      attendantId
      fullName
      userName
      hashPassword
      nipPassword
      tokenRequest
      profilePhoto
      limitEpoch
      managerAttendantId
      active
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      roles {
        items {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      phones {
        phoneType
        phone
      }
      emails {
        emailType
        email
      }
      fingers {
        fingerType
        fingerTemplate
      }
      position {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashRegisterShifts {
        items {
          id
          openCashRegisterShift
          closeCashRegisterShift
          cashRegisterShiftType
          cashRegisterShiftStatusType
          registerId
          sync
          sales
          cashRegisterShifts
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      cashDrawers {
        items {
          id
          datetime
          cashDrawerType
          cashFlowType
          amount
          registerId
          controlId
          sync
          file
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        id
        datetime
        day
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouseId
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentType {
          id
          incidentTypeId
          incidentTypeName
          parentIncidentTypeId
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        description
        headcountCount
        vacantLog {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discounts {
        items {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attendanceRecords {
        items {
          id
          datetime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      xadisEmptyContainers {
        items {
          id
          startDate
          endDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplanExecution {
        items {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      color
      organizationId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttendant = /* GraphQL */ `
  mutation DeleteAttendant(
    $input: DeleteAttendantInput!
    $condition: ModelAttendantConditionInput
  ) {
    deleteAttendant(input: $input, condition: $condition) {
      id
      attendantId
      fullName
      userName
      hashPassword
      nipPassword
      tokenRequest
      profilePhoto
      limitEpoch
      managerAttendantId
      active
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      roles {
        items {
          id
          roleId
          day
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      phones {
        phoneType
        phone
      }
      emails {
        emailType
        email
      }
      fingers {
        fingerType
        fingerTemplate
      }
      position {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashRegisterShifts {
        items {
          id
          openCashRegisterShift
          closeCashRegisterShift
          cashRegisterShiftType
          cashRegisterShiftStatusType
          registerId
          sync
          sales
          cashRegisterShifts
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      cashDrawers {
        items {
          id
          datetime
          cashDrawerType
          cashFlowType
          amount
          registerId
          controlId
          sync
          file
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        id
        datetime
        day
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouseId
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentType {
          id
          incidentTypeId
          incidentTypeName
          parentIncidentTypeId
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          scheduleId
          entry
          exit
          scheduleName
          tolerance
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        description
        headcountCount
        vacantLog {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discounts {
        items {
          id
          startDate
          endDate
          discountType
          quantity
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attendanceRecords {
        items {
          id
          datetime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      xadisEmptyContainers {
        items {
          id
          startDate
          endDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplanExecution {
        items {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      color
      organizationId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSaleStatus = /* GraphQL */ `
  mutation CreateSaleStatus(
    $input: CreateSaleStatusInput!
    $condition: ModelSaleStatusConditionInput
  ) {
    createSaleStatus(input: $input, condition: $condition) {
      id
      saleStatusName
      parentSaleStatusId
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSaleStatus = /* GraphQL */ `
  mutation UpdateSaleStatus(
    $input: UpdateSaleStatusInput!
    $condition: ModelSaleStatusConditionInput
  ) {
    updateSaleStatus(input: $input, condition: $condition) {
      id
      saleStatusName
      parentSaleStatusId
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaleStatus = /* GraphQL */ `
  mutation DeleteSaleStatus(
    $input: DeleteSaleStatusInput!
    $condition: ModelSaleStatusConditionInput
  ) {
    deleteSaleStatus(input: $input, condition: $condition) {
      id
      saleStatusName
      parentSaleStatusId
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSale = /* GraphQL */ `
  mutation CreateSale(
    $input: CreateSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    createSale(input: $input, condition: $condition) {
      id
      createdDate
      paidDate
      amount
      amountPaid
      amountReturned
      cardAmount
      taxAmount
      discountAmount
      source
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      status {
        id
        saleStatusName
        parentSaleStatusId
        sales {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      customer {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleCanceledItems {
        productId
        quantity
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      serviceTransaction {
        items {
          id
          datetime
          sync
          startServiceTransaction
          sendServiceTransaction
          endServiceTransaction
          attendantId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnables {
        items {
          id
          startValidity
          cancellationValidity
          returnableStatus
          received
          quantity
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouseId
      registerId
      payment {
        paymentType
        amount
        cardTransactionId
      }
      sync
      token
      invoice
      files {
        fileName
        fileType
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSale = /* GraphQL */ `
  mutation UpdateSale(
    $input: UpdateSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    updateSale(input: $input, condition: $condition) {
      id
      createdDate
      paidDate
      amount
      amountPaid
      amountReturned
      cardAmount
      taxAmount
      discountAmount
      source
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      status {
        id
        saleStatusName
        parentSaleStatusId
        sales {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      customer {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleCanceledItems {
        productId
        quantity
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      serviceTransaction {
        items {
          id
          datetime
          sync
          startServiceTransaction
          sendServiceTransaction
          endServiceTransaction
          attendantId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnables {
        items {
          id
          startValidity
          cancellationValidity
          returnableStatus
          received
          quantity
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouseId
      registerId
      payment {
        paymentType
        amount
        cardTransactionId
      }
      sync
      token
      invoice
      files {
        fileName
        fileType
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSale = /* GraphQL */ `
  mutation DeleteSale(
    $input: DeleteSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    deleteSale(input: $input, condition: $condition) {
      id
      createdDate
      paidDate
      amount
      amountPaid
      amountReturned
      cardAmount
      taxAmount
      discountAmount
      source
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      status {
        id
        saleStatusName
        parentSaleStatusId
        sales {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      customer {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleCanceledItems {
        productId
        quantity
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      serviceTransaction {
        items {
          id
          datetime
          sync
          startServiceTransaction
          sendServiceTransaction
          endServiceTransaction
          attendantId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      returnables {
        items {
          id
          startValidity
          cancellationValidity
          returnableStatus
          received
          quantity
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouseId
      registerId
      payment {
        paymentType
        amount
        cardTransactionId
      }
      sync
      token
      invoice
      files {
        fileName
        fileType
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createZone = /* GraphQL */ `
  mutation CreateZone(
    $input: CreateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    createZone(input: $input, condition: $condition) {
      id
      zoneId
      zoneName
      zoneType
      points {
        coordinates
      }
      customers {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone(
    $input: UpdateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    updateZone(input: $input, condition: $condition) {
      id
      zoneId
      zoneName
      zoneType
      points {
        coordinates
      }
      customers {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone(
    $input: DeleteZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    deleteZone(input: $input, condition: $condition) {
      id
      zoneId
      zoneName
      zoneType
      points {
        coordinates
      }
      customers {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      customerId
      customerName
      birthdate
      phones {
        phoneType
        phone
      }
      emails
      address
      location {
        coordinates
      }
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      sellBanned
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      accountRepresentative {
        id
        accountRepresentativeId
        customer {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      zone {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      customerType {
        id
        customerTypeId
        customerTypeName
        customers {
          nextToken
          startedAt
        }
        defaultType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      legals {
        legalType
        datetime
      }
      files {
        fileName
        fileType
      }
      preregistrationCode
      fingers {
        fingerType
        fingerTemplate
      }
      audits {
        items {
          id
          auditType
          startAudit
          endAudit
          promoter
          auditor
          blacklist
          result
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      commercialBusiness
      salesHours {
        startTime
        endTime
      }
      enrrollmentWarehouse
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      customerId
      customerName
      birthdate
      phones {
        phoneType
        phone
      }
      emails
      address
      location {
        coordinates
      }
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      sellBanned
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      accountRepresentative {
        id
        accountRepresentativeId
        customer {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      zone {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      customerType {
        id
        customerTypeId
        customerTypeName
        customers {
          nextToken
          startedAt
        }
        defaultType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      legals {
        legalType
        datetime
      }
      files {
        fileName
        fileType
      }
      preregistrationCode
      fingers {
        fingerType
        fingerTemplate
      }
      audits {
        items {
          id
          auditType
          startAudit
          endAudit
          promoter
          auditor
          blacklist
          result
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      commercialBusiness
      salesHours {
        startTime
        endTime
      }
      enrrollmentWarehouse
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      customerId
      customerName
      birthdate
      phones {
        phoneType
        phone
      }
      emails
      address
      location {
        coordinates
      }
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      sellBanned
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      accountRepresentative {
        id
        accountRepresentativeId
        customer {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      zone {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      customerType {
        id
        customerTypeId
        customerTypeName
        customers {
          nextToken
          startedAt
        }
        defaultType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      legals {
        legalType
        datetime
      }
      files {
        fileName
        fileType
      }
      preregistrationCode
      fingers {
        fingerType
        fingerTemplate
      }
      audits {
        items {
          id
          auditType
          startAudit
          endAudit
          promoter
          auditor
          blacklist
          result
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      commercialBusiness
      salesHours {
        startTime
        endTime
      }
      enrrollmentWarehouse
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerType = /* GraphQL */ `
  mutation CreateCustomerType(
    $input: CreateCustomerTypeInput!
    $condition: ModelCustomerTypeConditionInput
  ) {
    createCustomerType(input: $input, condition: $condition) {
      id
      customerTypeId
      customerTypeName
      customers {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      defaultType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerType = /* GraphQL */ `
  mutation UpdateCustomerType(
    $input: UpdateCustomerTypeInput!
    $condition: ModelCustomerTypeConditionInput
  ) {
    updateCustomerType(input: $input, condition: $condition) {
      id
      customerTypeId
      customerTypeName
      customers {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      defaultType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerType = /* GraphQL */ `
  mutation DeleteCustomerType(
    $input: DeleteCustomerTypeInput!
    $condition: ModelCustomerTypeConditionInput
  ) {
    deleteCustomerType(input: $input, condition: $condition) {
      id
      customerTypeId
      customerTypeName
      customers {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      defaultType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerTypeProductKit = /* GraphQL */ `
  mutation CreateCustomerTypeProductKit(
    $input: CreateCustomerTypeProductKitInput!
    $condition: ModelCustomerTypeProductKitConditionInput
  ) {
    createCustomerTypeProductKit(input: $input, condition: $condition) {
      productKitId
      customerTypeId
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerTypeProductKit = /* GraphQL */ `
  mutation UpdateCustomerTypeProductKit(
    $input: UpdateCustomerTypeProductKitInput!
    $condition: ModelCustomerTypeProductKitConditionInput
  ) {
    updateCustomerTypeProductKit(input: $input, condition: $condition) {
      productKitId
      customerTypeId
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerTypeProductKit = /* GraphQL */ `
  mutation DeleteCustomerTypeProductKit(
    $input: DeleteCustomerTypeProductKitInput!
    $condition: ModelCustomerTypeProductKitConditionInput
  ) {
    deleteCustomerTypeProductKit(input: $input, condition: $condition) {
      productKitId
      customerTypeId
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWarehouseCustomer = /* GraphQL */ `
  mutation CreateWarehouseCustomer(
    $input: CreateWarehouseCustomerInput!
    $condition: ModelWarehouseCustomerConditionInput
  ) {
    createWarehouseCustomer(input: $input, condition: $condition) {
      customerId
      warehouseId
      marketReleaseDate
      marketFinalDate
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWarehouseCustomer = /* GraphQL */ `
  mutation UpdateWarehouseCustomer(
    $input: UpdateWarehouseCustomerInput!
    $condition: ModelWarehouseCustomerConditionInput
  ) {
    updateWarehouseCustomer(input: $input, condition: $condition) {
      customerId
      warehouseId
      marketReleaseDate
      marketFinalDate
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWarehouseCustomer = /* GraphQL */ `
  mutation DeleteWarehouseCustomer(
    $input: DeleteWarehouseCustomerInput!
    $condition: ModelWarehouseCustomerConditionInput
  ) {
    deleteWarehouseCustomer(input: $input, condition: $condition) {
      customerId
      warehouseId
      marketReleaseDate
      marketFinalDate
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWarehouseAttendant = /* GraphQL */ `
  mutation CreateWarehouseAttendant(
    $input: CreateWarehouseAttendantInput!
    $condition: ModelWarehouseAttendantConditionInput
  ) {
    createWarehouseAttendant(input: $input, condition: $condition) {
      attendantId
      warehouseId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWarehouseAttendant = /* GraphQL */ `
  mutation UpdateWarehouseAttendant(
    $input: UpdateWarehouseAttendantInput!
    $condition: ModelWarehouseAttendantConditionInput
  ) {
    updateWarehouseAttendant(input: $input, condition: $condition) {
      attendantId
      warehouseId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWarehouseAttendant = /* GraphQL */ `
  mutation DeleteWarehouseAttendant(
    $input: DeleteWarehouseAttendantInput!
    $condition: ModelWarehouseAttendantConditionInput
  ) {
    deleteWarehouseAttendant(input: $input, condition: $condition) {
      attendantId
      warehouseId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAccountRepresentative = /* GraphQL */ `
  mutation CreateAccountRepresentative(
    $input: CreateAccountRepresentativeInput!
    $condition: ModelAccountRepresentativeConditionInput
  ) {
    createAccountRepresentative(input: $input, condition: $condition) {
      id
      accountRepresentativeId
      customer {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAccountRepresentative = /* GraphQL */ `
  mutation UpdateAccountRepresentative(
    $input: UpdateAccountRepresentativeInput!
    $condition: ModelAccountRepresentativeConditionInput
  ) {
    updateAccountRepresentative(input: $input, condition: $condition) {
      id
      accountRepresentativeId
      customer {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccountRepresentative = /* GraphQL */ `
  mutation DeleteAccountRepresentative(
    $input: DeleteAccountRepresentativeInput!
    $condition: ModelAccountRepresentativeConditionInput
  ) {
    deleteAccountRepresentative(input: $input, condition: $condition) {
      id
      accountRepresentativeId
      customer {
        items {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSaleItem = /* GraphQL */ `
  mutation CreateSaleItem(
    $input: CreateSaleItemInput!
    $condition: ModelSaleItemConditionInput
  ) {
    createSaleItem(input: $input, condition: $condition) {
      id
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productId
      quantity
      pricePerUnit
      price
      taxAmount
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      kardex {
        id
        kardexId
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        datetime
        kardexOperationType
        kardexFlowType
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItem {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        quantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSaleItem = /* GraphQL */ `
  mutation UpdateSaleItem(
    $input: UpdateSaleItemInput!
    $condition: ModelSaleItemConditionInput
  ) {
    updateSaleItem(input: $input, condition: $condition) {
      id
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productId
      quantity
      pricePerUnit
      price
      taxAmount
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      kardex {
        id
        kardexId
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        datetime
        kardexOperationType
        kardexFlowType
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItem {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        quantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaleItem = /* GraphQL */ `
  mutation DeleteSaleItem(
    $input: DeleteSaleItemInput!
    $condition: ModelSaleItemConditionInput
  ) {
    deleteSaleItem(input: $input, condition: $condition) {
      id
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productId
      quantity
      pricePerUnit
      price
      taxAmount
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      kardex {
        id
        kardexId
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        datetime
        kardexOperationType
        kardexFlowType
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItem {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        quantity
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createEquivalenceProduct = /* GraphQL */ `
  mutation CreateEquivalenceProduct(
    $input: CreateEquivalenceProductInput!
    $condition: ModelEquivalenceProductConditionInput
  ) {
    createEquivalenceProduct(input: $input, condition: $condition) {
      productBaseId
      productEquivalenceId
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateEquivalenceProduct = /* GraphQL */ `
  mutation UpdateEquivalenceProduct(
    $input: UpdateEquivalenceProductInput!
    $condition: ModelEquivalenceProductConditionInput
  ) {
    updateEquivalenceProduct(input: $input, condition: $condition) {
      productBaseId
      productEquivalenceId
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteEquivalenceProduct = /* GraphQL */ `
  mutation DeleteEquivalenceProduct(
    $input: DeleteEquivalenceProductInput!
    $condition: ModelEquivalenceProductConditionInput
  ) {
    deleteEquivalenceProduct(input: $input, condition: $condition) {
      productBaseId
      productEquivalenceId
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWarehouseProductKit = /* GraphQL */ `
  mutation CreateWarehouseProductKit(
    $input: CreateWarehouseProductKitInput!
    $condition: ModelWarehouseProductKitConditionInput
  ) {
    createWarehouseProductKit(input: $input, condition: $condition) {
      productKitId
      warehouseId
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWarehouseProductKit = /* GraphQL */ `
  mutation UpdateWarehouseProductKit(
    $input: UpdateWarehouseProductKitInput!
    $condition: ModelWarehouseProductKitConditionInput
  ) {
    updateWarehouseProductKit(input: $input, condition: $condition) {
      productKitId
      warehouseId
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWarehouseProductKit = /* GraphQL */ `
  mutation DeleteWarehouseProductKit(
    $input: DeleteWarehouseProductKitInput!
    $condition: ModelWarehouseProductKitConditionInput
  ) {
    deleteWarehouseProductKit(input: $input, condition: $condition) {
      productKitId
      warehouseId
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createProductKit = /* GraphQL */ `
  mutation CreateProductKit(
    $input: CreateProductKitInput!
    $condition: ModelProductKitConditionInput
  ) {
    createProductKit(input: $input, condition: $condition) {
      id
      productKitId
      productKitName
      productKitType
      currentSellPrice
      marketReleaseDate
      marketFinalDate
      s3keyImage
      limitPerSale
      limitPerDay
      limitPerPos
      kitFormulaId
      productKitItems {
        items {
          id
          productOptions
          quantity
          currentSellPrice
          percentDiscount
          percentOfer
          amountDiscount
          amountOfer
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouseId {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      organizationId
      salesHours {
        startTime
        endTime
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProductKit = /* GraphQL */ `
  mutation UpdateProductKit(
    $input: UpdateProductKitInput!
    $condition: ModelProductKitConditionInput
  ) {
    updateProductKit(input: $input, condition: $condition) {
      id
      productKitId
      productKitName
      productKitType
      currentSellPrice
      marketReleaseDate
      marketFinalDate
      s3keyImage
      limitPerSale
      limitPerDay
      limitPerPos
      kitFormulaId
      productKitItems {
        items {
          id
          productOptions
          quantity
          currentSellPrice
          percentDiscount
          percentOfer
          amountDiscount
          amountOfer
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouseId {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      organizationId
      salesHours {
        startTime
        endTime
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductKit = /* GraphQL */ `
  mutation DeleteProductKit(
    $input: DeleteProductKitInput!
    $condition: ModelProductKitConditionInput
  ) {
    deleteProductKit(input: $input, condition: $condition) {
      id
      productKitId
      productKitName
      productKitType
      currentSellPrice
      marketReleaseDate
      marketFinalDate
      s3keyImage
      limitPerSale
      limitPerDay
      limitPerPos
      kitFormulaId
      productKitItems {
        items {
          id
          productOptions
          quantity
          currentSellPrice
          percentDiscount
          percentOfer
          amountDiscount
          amountOfer
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      saleItems {
        items {
          id
          productId
          quantity
          pricePerUnit
          price
          taxAmount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouseId {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      organizationId
      salesHours {
        startTime
        endTime
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createProductKitItem = /* GraphQL */ `
  mutation CreateProductKitItem(
    $input: CreateProductKitItemInput!
    $condition: ModelProductKitItemConditionInput
  ) {
    createProductKitItem(input: $input, condition: $condition) {
      id
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productOptions
      quantity
      currentSellPrice
      percentDiscount
      percentOfer
      amountDiscount
      amountOfer
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProductKitItem = /* GraphQL */ `
  mutation UpdateProductKitItem(
    $input: UpdateProductKitItemInput!
    $condition: ModelProductKitItemConditionInput
  ) {
    updateProductKitItem(input: $input, condition: $condition) {
      id
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productOptions
      quantity
      currentSellPrice
      percentDiscount
      percentOfer
      amountDiscount
      amountOfer
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductKitItem = /* GraphQL */ `
  mutation DeleteProductKitItem(
    $input: DeleteProductKitItemInput!
    $condition: ModelProductKitItemConditionInput
  ) {
    deleteProductKitItem(input: $input, condition: $condition) {
      id
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productOptions
      quantity
      currentSellPrice
      percentDiscount
      percentOfer
      amountDiscount
      amountOfer
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrderStatus = /* GraphQL */ `
  mutation CreateOrderStatus(
    $input: CreateOrderStatusInput!
    $condition: ModelOrderStatusConditionInput
  ) {
    createOrderStatus(input: $input, condition: $condition) {
      id
      orderStatusName
      parentOrderStatusId
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderStatus = /* GraphQL */ `
  mutation UpdateOrderStatus(
    $input: UpdateOrderStatusInput!
    $condition: ModelOrderStatusConditionInput
  ) {
    updateOrderStatus(input: $input, condition: $condition) {
      id
      orderStatusName
      parentOrderStatusId
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderStatus = /* GraphQL */ `
  mutation DeleteOrderStatus(
    $input: DeleteOrderStatusInput!
    $condition: ModelOrderStatusConditionInput
  ) {
    deleteOrderStatus(input: $input, condition: $condition) {
      id
      orderStatusName
      parentOrderStatusId
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      createdDate
      requestedDate
      recivedDate
      provider {
        id
        providerId
        providerName
        cronVisits
        phones {
          phoneType
          phone
        }
        address
        email
        giveCredit
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      orderStatus {
        id
        orderStatusName
        parentOrderStatusId
        orders {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          comment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      scheduledDate
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sync
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      createdDate
      requestedDate
      recivedDate
      provider {
        id
        providerId
        providerName
        cronVisits
        phones {
          phoneType
          phone
        }
        address
        email
        giveCredit
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      orderStatus {
        id
        orderStatusName
        parentOrderStatusId
        orders {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          comment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      scheduledDate
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sync
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      createdDate
      requestedDate
      recivedDate
      provider {
        id
        providerId
        providerName
        cronVisits
        phones {
          phoneType
          phone
        }
        address
        email
        giveCredit
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      orderStatus {
        id
        orderStatusName
        parentOrderStatusId
        orders {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          comment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      scheduledDate
      orderItems {
        items {
          id
          requestedQuantity
          pricePerUnit
          price
          taxAmount
          receivedQuantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sync
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      comment
      order {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      comment
      order {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      comment
      order {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrderItem = /* GraphQL */ `
  mutation CreateOrderItem(
    $input: CreateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    createOrderItem(input: $input, condition: $condition) {
      id
      order {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      requestedQuantity
      pricePerUnit
      price
      taxAmount
      receivedQuantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderItem = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      id
      order {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      requestedQuantity
      pricePerUnit
      price
      taxAmount
      receivedQuantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderItem = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      id
      order {
        id
        createdDate
        requestedDate
        recivedDate
        provider {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        orderStatus {
          id
          orderStatusName
          parentOrderStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          nextToken
          startedAt
        }
        scheduledDate
        orderItems {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sync
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productKit {
        id
        productKitId
        productKitName
        productKitType
        currentSellPrice
        marketReleaseDate
        marketFinalDate
        s3keyImage
        limitPerSale
        limitPerDay
        limitPerPos
        kitFormulaId
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        warehouseId {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      requestedQuantity
      pricePerUnit
      price
      taxAmount
      receivedQuantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWarehouse = /* GraphQL */ `
  mutation CreateWarehouse(
    $input: CreateWarehouseInput!
    $condition: ModelWarehouseConditionInput
  ) {
    createWarehouse(input: $input, condition: $condition) {
      id
      warehouseId
      warehouseRelatedId
      warehouseName
      warehouseType
      location {
        coordinates
      }
      address
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      phones {
        phoneType
        phone
      }
      settings {
        param
        value
      }
      attendants {
        items {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      productKits {
        items {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashRegisterShifts {
        items {
          id
          openCashRegisterShift
          closeCashRegisterShift
          cashRegisterShiftType
          cashRegisterShiftStatusType
          registerId
          sync
          sales
          cashRegisterShifts
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      zone {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      headcountReport {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      cashDrawers {
        items {
          id
          datetime
          cashDrawerType
          cashFlowType
          amount
          registerId
          controlId
          sync
          file
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWarehouse = /* GraphQL */ `
  mutation UpdateWarehouse(
    $input: UpdateWarehouseInput!
    $condition: ModelWarehouseConditionInput
  ) {
    updateWarehouse(input: $input, condition: $condition) {
      id
      warehouseId
      warehouseRelatedId
      warehouseName
      warehouseType
      location {
        coordinates
      }
      address
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      phones {
        phoneType
        phone
      }
      settings {
        param
        value
      }
      attendants {
        items {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      productKits {
        items {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashRegisterShifts {
        items {
          id
          openCashRegisterShift
          closeCashRegisterShift
          cashRegisterShiftType
          cashRegisterShiftStatusType
          registerId
          sync
          sales
          cashRegisterShifts
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      zone {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      headcountReport {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      cashDrawers {
        items {
          id
          datetime
          cashDrawerType
          cashFlowType
          amount
          registerId
          controlId
          sync
          file
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWarehouse = /* GraphQL */ `
  mutation DeleteWarehouse(
    $input: DeleteWarehouseInput!
    $condition: ModelWarehouseConditionInput
  ) {
    deleteWarehouse(input: $input, condition: $condition) {
      id
      warehouseId
      warehouseRelatedId
      warehouseName
      warehouseType
      location {
        coordinates
      }
      address
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      phones {
        phoneType
        phone
      }
      settings {
        param
        value
      }
      attendants {
        items {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      sales {
        items {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdDate
          requestedDate
          recivedDate
          scheduledDate
          sync
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      productKits {
        items {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashRegisterShifts {
        items {
          id
          openCashRegisterShift
          closeCashRegisterShift
          cashRegisterShiftType
          cashRegisterShiftStatusType
          registerId
          sync
          sales
          cashRegisterShifts
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      zone {
        id
        zoneId
        zoneName
        zoneType
        points {
          coordinates
        }
        customers {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      headcountReport {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      kardexs {
        items {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      cashDrawers {
        items {
          id
          datetime
          cashDrawerType
          cashFlowType
          amount
          registerId
          controlId
          sync
          file
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory(
    $input: CreateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    createInventory(input: $input, condition: $condition) {
      productId
      warehouseId
      inventoryAmount
      minExistence
      maxExistence
      erpAmount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory(
    $input: UpdateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    updateInventory(input: $input, condition: $condition) {
      productId
      warehouseId
      inventoryAmount
      minExistence
      maxExistence
      erpAmount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory(
    $input: DeleteInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    deleteInventory(input: $input, condition: $condition) {
      productId
      warehouseId
      inventoryAmount
      minExistence
      maxExistence
      erpAmount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTax = /* GraphQL */ `
  mutation CreateTax(
    $input: CreateTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    createTax(input: $input, condition: $condition) {
      id
      taxId
      taxName
      rate
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTax = /* GraphQL */ `
  mutation UpdateTax(
    $input: UpdateTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    updateTax(input: $input, condition: $condition) {
      id
      taxId
      taxName
      rate
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTax = /* GraphQL */ `
  mutation DeleteTax(
    $input: DeleteTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    deleteTax(input: $input, condition: $condition) {
      id
      taxId
      taxName
      rate
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      roleId
      schedule {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendanceRecords {
        items {
          id
          datetime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      day
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      roleId
      schedule {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendanceRecords {
        items {
          id
          datetime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      day
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      roleId
      schedule {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attendanceRecords {
        items {
          id
          datetime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      day
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCashDrawer = /* GraphQL */ `
  mutation CreateCashDrawer(
    $input: CreateCashDrawerInput!
    $condition: ModelCashDrawerConditionInput
  ) {
    createCashDrawer(input: $input, condition: $condition) {
      id
      datetime
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashDrawerType
      cashFlowType
      amount
      registerId
      controlId
      sync
      file
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCashDrawer = /* GraphQL */ `
  mutation UpdateCashDrawer(
    $input: UpdateCashDrawerInput!
    $condition: ModelCashDrawerConditionInput
  ) {
    updateCashDrawer(input: $input, condition: $condition) {
      id
      datetime
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashDrawerType
      cashFlowType
      amount
      registerId
      controlId
      sync
      file
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCashDrawer = /* GraphQL */ `
  mutation DeleteCashDrawer(
    $input: DeleteCashDrawerInput!
    $condition: ModelCashDrawerConditionInput
  ) {
    deleteCashDrawer(input: $input, condition: $condition) {
      id
      datetime
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      cashDrawerType
      cashFlowType
      amount
      registerId
      controlId
      sync
      file
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createKardex = /* GraphQL */ `
  mutation CreateKardex(
    $input: CreateKardexInput!
    $condition: ModelKardexConditionInput
  ) {
    createKardex(input: $input, condition: $condition) {
      id
      kardexId
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      datetime
      kardexOperationType
      kardexFlowType
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleItem {
        id
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productId
        quantity
        pricePerUnit
        price
        taxAmount
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardex {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateKardex = /* GraphQL */ `
  mutation UpdateKardex(
    $input: UpdateKardexInput!
    $condition: ModelKardexConditionInput
  ) {
    updateKardex(input: $input, condition: $condition) {
      id
      kardexId
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      datetime
      kardexOperationType
      kardexFlowType
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleItem {
        id
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productId
        quantity
        pricePerUnit
        price
        taxAmount
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardex {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteKardex = /* GraphQL */ `
  mutation DeleteKardex(
    $input: DeleteKardexInput!
    $condition: ModelKardexConditionInput
  ) {
    deleteKardex(input: $input, condition: $condition) {
      id
      kardexId
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      datetime
      kardexOperationType
      kardexFlowType
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      saleItem {
        id
        sale {
          id
          createdDate
          paidDate
          amount
          amountPaid
          amountReturned
          cardAmount
          taxAmount
          discountAmount
          source
          warehouseId
          registerId
          sync
          token
          invoice
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productId
        quantity
        pricePerUnit
        price
        taxAmount
        productKit {
          id
          productKitId
          productKitName
          productKitType
          currentSellPrice
          marketReleaseDate
          marketFinalDate
          s3keyImage
          limitPerSale
          limitPerDay
          limitPerPos
          kitFormulaId
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardex {
          id
          kardexId
          datetime
          kardexOperationType
          kardexFlowType
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAuthorization = /* GraphQL */ `
  mutation CreateAuthorization(
    $input: CreateAuthorizationInput!
    $condition: ModelAuthorizationConditionInput
  ) {
    createAuthorization(input: $input, condition: $condition) {
      id
      datetime
      applicantAttendantId
      authorizerAttendantId
      authorizationType
      authorized
      registerId
      discount {
        id
        startDate
        endDate
        authorizations {
          nextToken
          startedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discountType
        quantity
        amount
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAuthorization = /* GraphQL */ `
  mutation UpdateAuthorization(
    $input: UpdateAuthorizationInput!
    $condition: ModelAuthorizationConditionInput
  ) {
    updateAuthorization(input: $input, condition: $condition) {
      id
      datetime
      applicantAttendantId
      authorizerAttendantId
      authorizationType
      authorized
      registerId
      discount {
        id
        startDate
        endDate
        authorizations {
          nextToken
          startedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discountType
        quantity
        amount
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAuthorization = /* GraphQL */ `
  mutation DeleteAuthorization(
    $input: DeleteAuthorizationInput!
    $condition: ModelAuthorizationConditionInput
  ) {
    deleteAuthorization(input: $input, condition: $condition) {
      id
      datetime
      applicantAttendantId
      authorizerAttendantId
      authorizationType
      authorized
      registerId
      discount {
        id
        startDate
        endDate
        authorizations {
          nextToken
          startedAt
        }
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discountType
        quantity
        amount
        product {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCardTransaction = /* GraphQL */ `
  mutation CreateCardTransaction(
    $input: CreateCardTransactionInput!
    $condition: ModelCardTransactionConditionInput
  ) {
    createCardTransaction(input: $input, condition: $condition) {
      id
      datetime
      request
      response
      receipe
      receipeS3
      saleId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCardTransaction = /* GraphQL */ `
  mutation UpdateCardTransaction(
    $input: UpdateCardTransactionInput!
    $condition: ModelCardTransactionConditionInput
  ) {
    updateCardTransaction(input: $input, condition: $condition) {
      id
      datetime
      request
      response
      receipe
      receipeS3
      saleId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCardTransaction = /* GraphQL */ `
  mutation DeleteCardTransaction(
    $input: DeleteCardTransactionInput!
    $condition: ModelCardTransactionConditionInput
  ) {
    deleteCardTransaction(input: $input, condition: $condition) {
      id
      datetime
      request
      response
      receipe
      receipeS3
      saleId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIncident = /* GraphQL */ `
  mutation CreateIncident(
    $input: CreateIncidentInput!
    $condition: ModelIncidentConditionInput
  ) {
    createIncident(input: $input, condition: $condition) {
      id
      datetime
      day
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouseId
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentType {
        id
        incidentTypeId
        incidentTypeName
        parentIncidentTypeId
        headcountCount
        incidents {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentObservation {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      schedule {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      description
      headcountCount
      vacantLog {
        id
        vacantCounter
        sinceDate
        calculationDate
        statusName
        statusId
        incidents {
          nextToken
          startedAt
        }
        reportId
        report {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail {
          count
          id
          positionId
          positionName
          date
        }
        incidentPlanAction {
          id
          incidentPlanActionName
          parentIncidentPlanActionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIncident = /* GraphQL */ `
  mutation UpdateIncident(
    $input: UpdateIncidentInput!
    $condition: ModelIncidentConditionInput
  ) {
    updateIncident(input: $input, condition: $condition) {
      id
      datetime
      day
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouseId
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentType {
        id
        incidentTypeId
        incidentTypeName
        parentIncidentTypeId
        headcountCount
        incidents {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentObservation {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      schedule {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      description
      headcountCount
      vacantLog {
        id
        vacantCounter
        sinceDate
        calculationDate
        statusName
        statusId
        incidents {
          nextToken
          startedAt
        }
        reportId
        report {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail {
          count
          id
          positionId
          positionName
          date
        }
        incidentPlanAction {
          id
          incidentPlanActionName
          parentIncidentPlanActionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncident = /* GraphQL */ `
  mutation DeleteIncident(
    $input: DeleteIncidentInput!
    $condition: ModelIncidentConditionInput
  ) {
    deleteIncident(input: $input, condition: $condition) {
      id
      datetime
      day
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouseId
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentType {
        id
        incidentTypeId
        incidentTypeName
        parentIncidentTypeId
        headcountCount
        incidents {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentObservation {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      schedule {
        id
        scheduleId
        entry
        exit
        scheduleName
        tolerance
        roles {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        scheduleType {
          id
          scheduleTypeName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      description
      headcountCount
      vacantLog {
        id
        vacantCounter
        sinceDate
        calculationDate
        statusName
        statusId
        incidents {
          nextToken
          startedAt
        }
        reportId
        report {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail {
          count
          id
          positionId
          positionName
          date
        }
        incidentPlanAction {
          id
          incidentPlanActionName
          parentIncidentPlanActionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        incidentObservation {
          id
          incidentObservationName
          parentIncidentObservationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIncidentType = /* GraphQL */ `
  mutation CreateIncidentType(
    $input: CreateIncidentTypeInput!
    $condition: ModelIncidentTypeConditionInput
  ) {
    createIncidentType(input: $input, condition: $condition) {
      id
      incidentTypeId
      incidentTypeName
      parentIncidentTypeId
      headcountCount
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIncidentType = /* GraphQL */ `
  mutation UpdateIncidentType(
    $input: UpdateIncidentTypeInput!
    $condition: ModelIncidentTypeConditionInput
  ) {
    updateIncidentType(input: $input, condition: $condition) {
      id
      incidentTypeId
      incidentTypeName
      parentIncidentTypeId
      headcountCount
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncidentType = /* GraphQL */ `
  mutation DeleteIncidentType(
    $input: DeleteIncidentTypeInput!
    $condition: ModelIncidentTypeConditionInput
  ) {
    deleteIncidentType(input: $input, condition: $condition) {
      id
      incidentTypeId
      incidentTypeName
      parentIncidentTypeId
      headcountCount
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIncidentObservation = /* GraphQL */ `
  mutation CreateIncidentObservation(
    $input: CreateIncidentObservationInput!
    $condition: ModelIncidentObservationConditionInput
  ) {
    createIncidentObservation(input: $input, condition: $condition) {
      id
      incidentObservationName
      parentIncidentObservationId
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      headcountVacantLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIncidentObservation = /* GraphQL */ `
  mutation UpdateIncidentObservation(
    $input: UpdateIncidentObservationInput!
    $condition: ModelIncidentObservationConditionInput
  ) {
    updateIncidentObservation(input: $input, condition: $condition) {
      id
      incidentObservationName
      parentIncidentObservationId
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      headcountVacantLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncidentObservation = /* GraphQL */ `
  mutation DeleteIncidentObservation(
    $input: DeleteIncidentObservationInput!
    $condition: ModelIncidentObservationConditionInput
  ) {
    deleteIncidentObservation(input: $input, condition: $condition) {
      id
      incidentObservationName
      parentIncidentObservationId
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      headcountVacantLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIncidentPlanAction = /* GraphQL */ `
  mutation CreateIncidentPlanAction(
    $input: CreateIncidentPlanActionInput!
    $condition: ModelIncidentPlanActionConditionInput
  ) {
    createIncidentPlanAction(input: $input, condition: $condition) {
      id
      incidentPlanActionName
      parentIncidentPlanActionId
      headcountVacantLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIncidentPlanAction = /* GraphQL */ `
  mutation UpdateIncidentPlanAction(
    $input: UpdateIncidentPlanActionInput!
    $condition: ModelIncidentPlanActionConditionInput
  ) {
    updateIncidentPlanAction(input: $input, condition: $condition) {
      id
      incidentPlanActionName
      parentIncidentPlanActionId
      headcountVacantLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncidentPlanAction = /* GraphQL */ `
  mutation DeleteIncidentPlanAction(
    $input: DeleteIncidentPlanActionInput!
    $condition: ModelIncidentPlanActionConditionInput
  ) {
    deleteIncidentPlanAction(input: $input, condition: $condition) {
      id
      incidentPlanActionName
      parentIncidentPlanActionId
      headcountVacantLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createReturnable = /* GraphQL */ `
  mutation CreateReturnable(
    $input: CreateReturnableInput!
    $condition: ModelReturnableConditionInput
  ) {
    createReturnable(input: $input, condition: $condition) {
      id
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      startValidity
      cancellationValidity
      returnableStatus
      received
      quantity
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      observation
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateReturnable = /* GraphQL */ `
  mutation UpdateReturnable(
    $input: UpdateReturnableInput!
    $condition: ModelReturnableConditionInput
  ) {
    updateReturnable(input: $input, condition: $condition) {
      id
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      startValidity
      cancellationValidity
      returnableStatus
      received
      quantity
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      observation
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteReturnable = /* GraphQL */ `
  mutation DeleteReturnable(
    $input: DeleteReturnableInput!
    $condition: ModelReturnableConditionInput
  ) {
    deleteReturnable(input: $input, condition: $condition) {
      id
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      startValidity
      cancellationValidity
      returnableStatus
      received
      quantity
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      observation
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSystemRole = /* GraphQL */ `
  mutation CreateSystemRole(
    $input: CreateSystemRoleInput!
    $condition: ModelSystemRoleConditionInput
  ) {
    createSystemRole(input: $input, condition: $condition) {
      id
      systemRoleName
      parentSystemRoleId
      positions {
        items {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          systemMenuName
          menuType
          path
          component
          active
          icon
          platformType
          parentSystemMenuId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSystemRole = /* GraphQL */ `
  mutation UpdateSystemRole(
    $input: UpdateSystemRoleInput!
    $condition: ModelSystemRoleConditionInput
  ) {
    updateSystemRole(input: $input, condition: $condition) {
      id
      systemRoleName
      parentSystemRoleId
      positions {
        items {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          systemMenuName
          menuType
          path
          component
          active
          icon
          platformType
          parentSystemMenuId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystemRole = /* GraphQL */ `
  mutation DeleteSystemRole(
    $input: DeleteSystemRoleInput!
    $condition: ModelSystemRoleConditionInput
  ) {
    deleteSystemRole(input: $input, condition: $condition) {
      id
      systemRoleName
      parentSystemRoleId
      positions {
        items {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          systemMenuName
          menuType
          path
          component
          active
          icon
          platformType
          parentSystemMenuId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSystemMenu = /* GraphQL */ `
  mutation CreateSystemMenu(
    $input: CreateSystemMenuInput!
    $condition: ModelSystemMenuConditionInput
  ) {
    createSystemMenu(input: $input, condition: $condition) {
      id
      systemMenuName
      menuType
      path
      component
      active
      icon
      role {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      defaultScopes {
        scopeName
        active
      }
      platformType
      parentSystemMenuId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSystemMenu = /* GraphQL */ `
  mutation UpdateSystemMenu(
    $input: UpdateSystemMenuInput!
    $condition: ModelSystemMenuConditionInput
  ) {
    updateSystemMenu(input: $input, condition: $condition) {
      id
      systemMenuName
      menuType
      path
      component
      active
      icon
      role {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      defaultScopes {
        scopeName
        active
      }
      platformType
      parentSystemMenuId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystemMenu = /* GraphQL */ `
  mutation DeleteSystemMenu(
    $input: DeleteSystemMenuInput!
    $condition: ModelSystemMenuConditionInput
  ) {
    deleteSystemMenu(input: $input, condition: $condition) {
      id
      systemMenuName
      menuType
      path
      component
      active
      icon
      role {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      defaultScopes {
        scopeName
        active
      }
      platformType
      parentSystemMenuId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSystemAttendantMenuScope = /* GraphQL */ `
  mutation CreateSystemAttendantMenuScope(
    $input: CreateSystemAttendantMenuScopeInput!
    $condition: ModelSystemAttendantMenuScopeConditionInput
  ) {
    createSystemAttendantMenuScope(input: $input, condition: $condition) {
      systemMenuId
      attendantId
      scopes {
        scopeName
        active
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSystemAttendantMenuScope = /* GraphQL */ `
  mutation UpdateSystemAttendantMenuScope(
    $input: UpdateSystemAttendantMenuScopeInput!
    $condition: ModelSystemAttendantMenuScopeConditionInput
  ) {
    updateSystemAttendantMenuScope(input: $input, condition: $condition) {
      systemMenuId
      attendantId
      scopes {
        scopeName
        active
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystemAttendantMenuScope = /* GraphQL */ `
  mutation DeleteSystemAttendantMenuScope(
    $input: DeleteSystemAttendantMenuScopeInput!
    $condition: ModelSystemAttendantMenuScopeConditionInput
  ) {
    deleteSystemAttendantMenuScope(input: $input, condition: $condition) {
      systemMenuId
      attendantId
      scopes {
        scopeName
        active
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPosition = /* GraphQL */ `
  mutation CreatePosition(
    $input: CreatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    createPosition(input: $input, condition: $condition) {
      id
      positionId
      positionName
      parentPositionId
      attendants {
        items {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      teamPositions {
        items {
          id
          quantity
          required
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      systemRole {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePosition = /* GraphQL */ `
  mutation UpdatePosition(
    $input: UpdatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    updatePosition(input: $input, condition: $condition) {
      id
      positionId
      positionName
      parentPositionId
      attendants {
        items {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      teamPositions {
        items {
          id
          quantity
          required
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      systemRole {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePosition = /* GraphQL */ `
  mutation DeletePosition(
    $input: DeletePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    deletePosition(input: $input, condition: $condition) {
      id
      positionId
      positionName
      parentPositionId
      attendants {
        items {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      organization {
        id
        organizationId
        organizationName
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        address
        positions {
          nextToken
          startedAt
        }
        warehouses {
          nextToken
          startedAt
        }
        providers {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      teamPositions {
        items {
          id
          quantity
          required
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      systemRole {
        id
        systemRoleName
        parentSystemRoleId
        positions {
          nextToken
          startedAt
        }
        menus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      organizationId
      organizationName
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      address
      positions {
        items {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      providers {
        items {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      organizationId
      organizationName
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      address
      positions {
        items {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      providers {
        items {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      organizationId
      organizationName
      taxpayer {
        address
        identification
        taxpayerName
        keyTax
        detailTax
      }
      address
      positions {
        items {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      providers {
        items {
          id
          providerId
          providerName
          cronVisits
          address
          email
          giveCredit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          productId
          productName
          sku
          description
          averageCost
          currentSellPrice
          maxSellingPrice
          inStock
          active
          marketReleaseDate
          marketFinalDate
          sellable
          purchable
          s3keyImage
          returnableContainerProductId
          barcodes
          quantityPerBox
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCashRegisterShift = /* GraphQL */ `
  mutation CreateCashRegisterShift(
    $input: CreateCashRegisterShiftInput!
    $condition: ModelCashRegisterShiftConditionInput
  ) {
    createCashRegisterShift(input: $input, condition: $condition) {
      id
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      openCashRegisterShift
      closeCashRegisterShift
      openStocks {
        typeStock
        amount
      }
      arching {
        cash
        virtual
        cashDifference
        virtualDifference
        cashBalance
        virtualBalance
        startDate
        endDate
      }
      closeStocks {
        typeStock
        amount
      }
      openInventory {
        productId
        inventoryAmount
      }
      closeInventory {
        productId
        inventoryAmount
      }
      openReturnable {
        productId
        inventoryAmount
      }
      closeReturnable {
        productId
        inventoryAmount
      }
      cashRegisterShiftType
      cashRegisterShiftStatusType
      registerId
      sync
      sales
      cashRegisterShifts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCashRegisterShift = /* GraphQL */ `
  mutation UpdateCashRegisterShift(
    $input: UpdateCashRegisterShiftInput!
    $condition: ModelCashRegisterShiftConditionInput
  ) {
    updateCashRegisterShift(input: $input, condition: $condition) {
      id
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      openCashRegisterShift
      closeCashRegisterShift
      openStocks {
        typeStock
        amount
      }
      arching {
        cash
        virtual
        cashDifference
        virtualDifference
        cashBalance
        virtualBalance
        startDate
        endDate
      }
      closeStocks {
        typeStock
        amount
      }
      openInventory {
        productId
        inventoryAmount
      }
      closeInventory {
        productId
        inventoryAmount
      }
      openReturnable {
        productId
        inventoryAmount
      }
      closeReturnable {
        productId
        inventoryAmount
      }
      cashRegisterShiftType
      cashRegisterShiftStatusType
      registerId
      sync
      sales
      cashRegisterShifts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCashRegisterShift = /* GraphQL */ `
  mutation DeleteCashRegisterShift(
    $input: DeleteCashRegisterShiftInput!
    $condition: ModelCashRegisterShiftConditionInput
  ) {
    deleteCashRegisterShift(input: $input, condition: $condition) {
      id
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      openCashRegisterShift
      closeCashRegisterShift
      openStocks {
        typeStock
        amount
      }
      arching {
        cash
        virtual
        cashDifference
        virtualDifference
        cashBalance
        virtualBalance
        startDate
        endDate
      }
      closeStocks {
        typeStock
        amount
      }
      openInventory {
        productId
        inventoryAmount
      }
      closeInventory {
        productId
        inventoryAmount
      }
      openReturnable {
        productId
        inventoryAmount
      }
      closeReturnable {
        productId
        inventoryAmount
      }
      cashRegisterShiftType
      cashRegisterShiftStatusType
      registerId
      sync
      sales
      cashRegisterShifts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createServiceIntegration = /* GraphQL */ `
  mutation CreateServiceIntegration(
    $input: CreateServiceIntegrationInput!
    $condition: ModelServiceIntegrationConditionInput
  ) {
    createServiceIntegration(input: $input, condition: $condition) {
      id
      serviceName
      configurations {
        param
        value
      }
      serviceTransactions {
        items {
          id
          datetime
          sync
          startServiceTransaction
          sendServiceTransaction
          endServiceTransaction
          attendantId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateServiceIntegration = /* GraphQL */ `
  mutation UpdateServiceIntegration(
    $input: UpdateServiceIntegrationInput!
    $condition: ModelServiceIntegrationConditionInput
  ) {
    updateServiceIntegration(input: $input, condition: $condition) {
      id
      serviceName
      configurations {
        param
        value
      }
      serviceTransactions {
        items {
          id
          datetime
          sync
          startServiceTransaction
          sendServiceTransaction
          endServiceTransaction
          attendantId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteServiceIntegration = /* GraphQL */ `
  mutation DeleteServiceIntegration(
    $input: DeleteServiceIntegrationInput!
    $condition: ModelServiceIntegrationConditionInput
  ) {
    deleteServiceIntegration(input: $input, condition: $condition) {
      id
      serviceName
      configurations {
        param
        value
      }
      serviceTransactions {
        items {
          id
          datetime
          sync
          startServiceTransaction
          sendServiceTransaction
          endServiceTransaction
          attendantId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createServiceTransaction = /* GraphQL */ `
  mutation CreateServiceTransaction(
    $input: CreateServiceTransactionInput!
    $condition: ModelServiceTransactionConditionInput
  ) {
    createServiceTransaction(input: $input, condition: $condition) {
      id
      serviceIntegration {
        id
        serviceName
        configurations {
          param
          value
        }
        serviceTransactions {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      datetime
      params {
        param
        value
      }
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sync
      startServiceTransaction
      sendServiceTransaction
      endServiceTransaction
      attendantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateServiceTransaction = /* GraphQL */ `
  mutation UpdateServiceTransaction(
    $input: UpdateServiceTransactionInput!
    $condition: ModelServiceTransactionConditionInput
  ) {
    updateServiceTransaction(input: $input, condition: $condition) {
      id
      serviceIntegration {
        id
        serviceName
        configurations {
          param
          value
        }
        serviceTransactions {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      datetime
      params {
        param
        value
      }
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sync
      startServiceTransaction
      sendServiceTransaction
      endServiceTransaction
      attendantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteServiceTransaction = /* GraphQL */ `
  mutation DeleteServiceTransaction(
    $input: DeleteServiceTransactionInput!
    $condition: ModelServiceTransactionConditionInput
  ) {
    deleteServiceTransaction(input: $input, condition: $condition) {
      id
      serviceIntegration {
        id
        serviceName
        configurations {
          param
          value
        }
        serviceTransactions {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      datetime
      params {
        param
        value
      }
      sale {
        id
        createdDate
        paidDate
        amount
        amountPaid
        amountReturned
        cardAmount
        taxAmount
        discountAmount
        source
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          saleStatusName
          parentSaleStatusId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customer {
          id
          customerId
          customerName
          birthdate
          emails
          address
          sellBanned
          preregistrationCode
          commercialBusiness
          enrrollmentWarehouse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        saleCanceledItems {
          productId
          quantity
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        serviceTransaction {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        warehouseId
        registerId
        payment {
          paymentType
          amount
          cardTransactionId
        }
        sync
        token
        invoice
        files {
          fileName
          fileType
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      sync
      startServiceTransaction
      sendServiceTransaction
      endServiceTransaction
      attendantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount(
    $input: CreateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    createDiscount(input: $input, condition: $condition) {
      id
      startDate
      endDate
      authorizations {
        items {
          id
          datetime
          applicantAttendantId
          authorizerAttendantId
          authorizationType
          authorized
          registerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discountType
      quantity
      amount
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDiscount = /* GraphQL */ `
  mutation UpdateDiscount(
    $input: UpdateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    updateDiscount(input: $input, condition: $condition) {
      id
      startDate
      endDate
      authorizations {
        items {
          id
          datetime
          applicantAttendantId
          authorizerAttendantId
          authorizationType
          authorized
          registerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discountType
      quantity
      amount
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiscount = /* GraphQL */ `
  mutation DeleteDiscount(
    $input: DeleteDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    deleteDiscount(input: $input, condition: $condition) {
      id
      startDate
      endDate
      authorizations {
        items {
          id
          datetime
          applicantAttendantId
          authorizerAttendantId
          authorizationType
          authorized
          registerId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      discountType
      quantity
      amount
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createParam = /* GraphQL */ `
  mutation CreateParam(
    $input: CreateParamInput!
    $condition: ModelParamConditionInput
  ) {
    createParam(input: $input, condition: $condition) {
      id
      param
      value
      startDate
      endDate
      paramType
      keyFilter
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateParam = /* GraphQL */ `
  mutation UpdateParam(
    $input: UpdateParamInput!
    $condition: ModelParamConditionInput
  ) {
    updateParam(input: $input, condition: $condition) {
      id
      param
      value
      startDate
      endDate
      paramType
      keyFilter
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteParam = /* GraphQL */ `
  mutation DeleteParam(
    $input: DeleteParamInput!
    $condition: ModelParamConditionInput
  ) {
    deleteParam(input: $input, condition: $condition) {
      id
      param
      value
      startDate
      endDate
      paramType
      keyFilter
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createEmailSuscription = /* GraphQL */ `
  mutation CreateEmailSuscription(
    $input: CreateEmailSuscriptionInput!
    $condition: ModelEmailSuscriptionConditionInput
  ) {
    createEmailSuscription(input: $input, condition: $condition) {
      id
      email
      active
      origin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateEmailSuscription = /* GraphQL */ `
  mutation UpdateEmailSuscription(
    $input: UpdateEmailSuscriptionInput!
    $condition: ModelEmailSuscriptionConditionInput
  ) {
    updateEmailSuscription(input: $input, condition: $condition) {
      id
      email
      active
      origin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmailSuscription = /* GraphQL */ `
  mutation DeleteEmailSuscription(
    $input: DeleteEmailSuscriptionInput!
    $condition: ModelEmailSuscriptionConditionInput
  ) {
    deleteEmailSuscription(input: $input, condition: $condition) {
      id
      email
      active
      origin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPricing = /* GraphQL */ `
  mutation CreatePricing(
    $input: CreatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    createPricing(input: $input, condition: $condition) {
      id
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      marketReleaseDate
      marketFinalDate
      amount
      pricingType
      pricingName
      priceId
      salesRank {
        saleRankType
        quantity
        amount
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePricing = /* GraphQL */ `
  mutation UpdatePricing(
    $input: UpdatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    updatePricing(input: $input, condition: $condition) {
      id
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      marketReleaseDate
      marketFinalDate
      amount
      pricingType
      pricingName
      priceId
      salesRank {
        saleRankType
        quantity
        amount
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePricing = /* GraphQL */ `
  mutation DeletePricing(
    $input: DeletePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    deletePricing(input: $input, condition: $condition) {
      id
      product {
        id
        productId
        productName
        sku
        description
        averageCost
        currentSellPrice
        maxSellingPrice
        inStock
        active
        marketReleaseDate
        marketFinalDate
        sellable
        purchable
        s3keyImage
        category {
          id
          categoryId
          categoryName
          parentCategoryId
          s3keyImage
          isNew
          categoryDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brand {
          id
          brandId
          brandName
          parentBrandId
          s3keyImage
          brandDescription
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        saleUnit {
          id
          saleUnitId
          saleUnitName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sourcing {
          id
          sourcingId
          sourcingName
          parentSourcingId
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productKitItems {
          nextToken
          startedAt
        }
        orderItems {
          nextToken
          startedAt
        }
        saleItems {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        returnables {
          nextToken
          startedAt
        }
        returnableContainerProductId
        serviceConfig {
          serviceIntegrationId
        }
        mtcprops {
          code
          amount
          verification_code
          serviceIntegrationId
          operation
          params
        }
        barcodes
        taxes {
          id
          taxId
          taxName
          rate
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        quantityPerBox
        pricings {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        organizationId
        salesHours {
          startTime
          endTime
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      marketReleaseDate
      marketFinalDate
      amount
      pricingType
      pricingName
      priceId
      salesRank {
        saleRankType
        quantity
        amount
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAudit = /* GraphQL */ `
  mutation CreateAudit(
    $input: CreateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    createAudit(input: $input, condition: $condition) {
      id
      auditType
      startAudit
      endAudit
      promoter
      auditor
      blacklist
      customer {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      result
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAudit = /* GraphQL */ `
  mutation UpdateAudit(
    $input: UpdateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    updateAudit(input: $input, condition: $condition) {
      id
      auditType
      startAudit
      endAudit
      promoter
      auditor
      blacklist
      customer {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      result
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAudit = /* GraphQL */ `
  mutation DeleteAudit(
    $input: DeleteAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    deleteAudit(input: $input, condition: $condition) {
      id
      auditType
      startAudit
      endAudit
      promoter
      auditor
      blacklist
      customer {
        id
        customerId
        customerName
        birthdate
        phones {
          phoneType
          phone
        }
        emails
        address
        location {
          coordinates
        }
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        sellBanned
        sales {
          nextToken
          startedAt
        }
        accountRepresentative {
          id
          accountRepresentativeId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        customerType {
          id
          customerTypeId
          customerTypeName
          defaultType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        legals {
          legalType
          datetime
        }
        files {
          fileName
          fileType
        }
        preregistrationCode
        fingers {
          fingerType
          fingerTemplate
        }
        audits {
          nextToken
          startedAt
        }
        commercialBusiness
        salesHours {
          startTime
          endTime
        }
        enrrollmentWarehouse
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      result
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createXadisEmptyContainer = /* GraphQL */ `
  mutation CreateXadisEmptyContainer(
    $input: CreateXadisEmptyContainerInput!
    $condition: ModelXadisEmptyContainerConditionInput
  ) {
    createXadisEmptyContainer(input: $input, condition: $condition) {
      id
      startDate
      endDate
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      emptyContainers {
        sku
        sap
        quantity
        stockType
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateXadisEmptyContainer = /* GraphQL */ `
  mutation UpdateXadisEmptyContainer(
    $input: UpdateXadisEmptyContainerInput!
    $condition: ModelXadisEmptyContainerConditionInput
  ) {
    updateXadisEmptyContainer(input: $input, condition: $condition) {
      id
      startDate
      endDate
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      emptyContainers {
        sku
        sap
        quantity
        stockType
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteXadisEmptyContainer = /* GraphQL */ `
  mutation DeleteXadisEmptyContainer(
    $input: DeleteXadisEmptyContainerInput!
    $condition: ModelXadisEmptyContainerConditionInput
  ) {
    deleteXadisEmptyContainer(input: $input, condition: $condition) {
      id
      startDate
      endDate
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      emptyContainers {
        sku
        sap
        quantity
        stockType
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createRemoteRequest = /* GraphQL */ `
  mutation CreateRemoteRequest(
    $input: CreateRemoteRequestInput!
    $condition: ModelRemoteRequestConditionInput
  ) {
    createRemoteRequest(input: $input, condition: $condition) {
      id
      source
      startDate
      endDate
      status
      sync
      request
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRemoteRequest = /* GraphQL */ `
  mutation UpdateRemoteRequest(
    $input: UpdateRemoteRequestInput!
    $condition: ModelRemoteRequestConditionInput
  ) {
    updateRemoteRequest(input: $input, condition: $condition) {
      id
      source
      startDate
      endDate
      status
      sync
      request
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRemoteRequest = /* GraphQL */ `
  mutation DeleteRemoteRequest(
    $input: DeleteRemoteRequestInput!
    $condition: ModelRemoteRequestConditionInput
  ) {
    deleteRemoteRequest(input: $input, condition: $condition) {
      id
      source
      startDate
      endDate
      status
      sync
      request
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      notificationDates {
        datetime
        dateType
      }
      active
      title
      message
      chanel
      request
      response
      from
      to
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      notificationDates {
        datetime
        dateType
      }
      active
      title
      message
      chanel
      request
      response
      from
      to
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      notificationDates {
        datetime
        dateType
      }
      active
      title
      message
      chanel
      request
      response
      from
      to
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createForecast = /* GraphQL */ `
  mutation CreateForecast(
    $input: CreateForecastInput!
    $condition: ModelForecastConditionInput
  ) {
    createForecast(input: $input, condition: $condition) {
      id
      project
      method
      startDate
      endDate
      bucketName
      awsRegion
      domain
      datasetFrequency
      timestampFormat
      forecastHorizon
      autoML
      datasets {
        schema
        datasetType
        files
      }
      forecastQuerys {
        items {
          id
          method
          startDate
          endDate
          filters
          request
          response
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      datasetGroupArn
      datasetArns
      datasetImportJobArns
      predictorArns
      forecastArn
      datasource {
        historyStartDate
        frequency
        query
        server
        database
        mapping {
          column
          propertie
          cast
        }
      }
      update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateForecast = /* GraphQL */ `
  mutation UpdateForecast(
    $input: UpdateForecastInput!
    $condition: ModelForecastConditionInput
  ) {
    updateForecast(input: $input, condition: $condition) {
      id
      project
      method
      startDate
      endDate
      bucketName
      awsRegion
      domain
      datasetFrequency
      timestampFormat
      forecastHorizon
      autoML
      datasets {
        schema
        datasetType
        files
      }
      forecastQuerys {
        items {
          id
          method
          startDate
          endDate
          filters
          request
          response
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      datasetGroupArn
      datasetArns
      datasetImportJobArns
      predictorArns
      forecastArn
      datasource {
        historyStartDate
        frequency
        query
        server
        database
        mapping {
          column
          propertie
          cast
        }
      }
      update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteForecast = /* GraphQL */ `
  mutation DeleteForecast(
    $input: DeleteForecastInput!
    $condition: ModelForecastConditionInput
  ) {
    deleteForecast(input: $input, condition: $condition) {
      id
      project
      method
      startDate
      endDate
      bucketName
      awsRegion
      domain
      datasetFrequency
      timestampFormat
      forecastHorizon
      autoML
      datasets {
        schema
        datasetType
        files
      }
      forecastQuerys {
        items {
          id
          method
          startDate
          endDate
          filters
          request
          response
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      datasetGroupArn
      datasetArns
      datasetImportJobArns
      predictorArns
      forecastArn
      datasource {
        historyStartDate
        frequency
        query
        server
        database
        mapping {
          column
          propertie
          cast
        }
      }
      update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createForecastQuery = /* GraphQL */ `
  mutation CreateForecastQuery(
    $input: CreateForecastQueryInput!
    $condition: ModelForecastQueryConditionInput
  ) {
    createForecastQuery(input: $input, condition: $condition) {
      id
      method
      startDate
      endDate
      filters
      forecast {
        id
        project
        method
        startDate
        endDate
        bucketName
        awsRegion
        domain
        datasetFrequency
        timestampFormat
        forecastHorizon
        autoML
        datasets {
          schema
          datasetType
          files
        }
        forecastQuerys {
          nextToken
          startedAt
        }
        datasetGroupArn
        datasetArns
        datasetImportJobArns
        predictorArns
        forecastArn
        datasource {
          historyStartDate
          frequency
          query
          server
          database
        }
        update
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      request
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateForecastQuery = /* GraphQL */ `
  mutation UpdateForecastQuery(
    $input: UpdateForecastQueryInput!
    $condition: ModelForecastQueryConditionInput
  ) {
    updateForecastQuery(input: $input, condition: $condition) {
      id
      method
      startDate
      endDate
      filters
      forecast {
        id
        project
        method
        startDate
        endDate
        bucketName
        awsRegion
        domain
        datasetFrequency
        timestampFormat
        forecastHorizon
        autoML
        datasets {
          schema
          datasetType
          files
        }
        forecastQuerys {
          nextToken
          startedAt
        }
        datasetGroupArn
        datasetArns
        datasetImportJobArns
        predictorArns
        forecastArn
        datasource {
          historyStartDate
          frequency
          query
          server
          database
        }
        update
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      request
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteForecastQuery = /* GraphQL */ `
  mutation DeleteForecastQuery(
    $input: DeleteForecastQueryInput!
    $condition: ModelForecastQueryConditionInput
  ) {
    deleteForecastQuery(input: $input, condition: $condition) {
      id
      method
      startDate
      endDate
      filters
      forecast {
        id
        project
        method
        startDate
        endDate
        bucketName
        awsRegion
        domain
        datasetFrequency
        timestampFormat
        forecastHorizon
        autoML
        datasets {
          schema
          datasetType
          files
        }
        forecastQuerys {
          nextToken
          startedAt
        }
        datasetGroupArn
        datasetArns
        datasetImportJobArns
        predictorArns
        forecastArn
        datasource {
          historyStartDate
          frequency
          query
          server
          database
        }
        update
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      request
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWorkTeamTemplate = /* GraphQL */ `
  mutation CreateWorkTeamTemplate(
    $input: CreateWorkTeamTemplateInput!
    $condition: ModelWorkTeamTemplateConditionInput
  ) {
    createWorkTeamTemplate(input: $input, condition: $condition) {
      id
      workTeamTemplate
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      teamPositions {
        items {
          id
          quantity
          required
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      active
      minSalesAmount
      maxSalesAmount
      frecuencySales
      periodSales
      headcountReport {
        items {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      headcountStatus {
        items {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkTeamTemplate = /* GraphQL */ `
  mutation UpdateWorkTeamTemplate(
    $input: UpdateWorkTeamTemplateInput!
    $condition: ModelWorkTeamTemplateConditionInput
  ) {
    updateWorkTeamTemplate(input: $input, condition: $condition) {
      id
      workTeamTemplate
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      teamPositions {
        items {
          id
          quantity
          required
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      active
      minSalesAmount
      maxSalesAmount
      frecuencySales
      periodSales
      headcountReport {
        items {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      headcountStatus {
        items {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkTeamTemplate = /* GraphQL */ `
  mutation DeleteWorkTeamTemplate(
    $input: DeleteWorkTeamTemplateInput!
    $condition: ModelWorkTeamTemplateConditionInput
  ) {
    deleteWorkTeamTemplate(input: $input, condition: $condition) {
      id
      workTeamTemplate
      warehouses {
        items {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      teamPositions {
        items {
          id
          quantity
          required
          observation
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      active
      minSalesAmount
      maxSalesAmount
      frecuencySales
      periodSales
      headcountReport {
        items {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      headcountStatus {
        items {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTeamPosition = /* GraphQL */ `
  mutation CreateTeamPosition(
    $input: CreateTeamPositionInput!
    $condition: ModelTeamPositionConditionInput
  ) {
    createTeamPosition(input: $input, condition: $condition) {
      id
      quantity
      position {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      required
      observation
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamPosition = /* GraphQL */ `
  mutation UpdateTeamPosition(
    $input: UpdateTeamPositionInput!
    $condition: ModelTeamPositionConditionInput
  ) {
    updateTeamPosition(input: $input, condition: $condition) {
      id
      quantity
      position {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      required
      observation
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamPosition = /* GraphQL */ `
  mutation DeleteTeamPosition(
    $input: DeleteTeamPositionInput!
    $condition: ModelTeamPositionConditionInput
  ) {
    deleteTeamPosition(input: $input, condition: $condition) {
      id
      quantity
      position {
        id
        positionId
        positionName
        parentPositionId
        attendants {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        systemRole {
          id
          systemRoleName
          parentSystemRoleId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      required
      observation
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createProviderProduct = /* GraphQL */ `
  mutation CreateProviderProduct(
    $input: CreateProviderProductInput!
    $condition: ModelProviderProductConditionInput
  ) {
    createProviderProduct(input: $input, condition: $condition) {
      providerId
      productId
      purchasePrice
      unitsPerPackage
      taxes {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProviderProduct = /* GraphQL */ `
  mutation UpdateProviderProduct(
    $input: UpdateProviderProductInput!
    $condition: ModelProviderProductConditionInput
  ) {
    updateProviderProduct(input: $input, condition: $condition) {
      providerId
      productId
      purchasePrice
      unitsPerPackage
      taxes {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProviderProduct = /* GraphQL */ `
  mutation DeleteProviderProduct(
    $input: DeleteProviderProductInput!
    $condition: ModelProviderProductConditionInput
  ) {
    deleteProviderProduct(input: $input, condition: $condition) {
      providerId
      productId
      purchasePrice
      unitsPerPackage
      taxes {
        id
        taxId
        taxName
        rate
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createHeadcountStatus = /* GraphQL */ `
  mutation CreateHeadcountStatus(
    $input: CreateHeadcountStatusInput!
    $condition: ModelHeadcountStatusConditionInput
  ) {
    createHeadcountStatus(input: $input, condition: $condition) {
      id
      vacantNumber
      assignedDays
      statusName
      workTeamTemplateID
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      headcountReports {
        items {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateHeadcountStatus = /* GraphQL */ `
  mutation UpdateHeadcountStatus(
    $input: UpdateHeadcountStatusInput!
    $condition: ModelHeadcountStatusConditionInput
  ) {
    updateHeadcountStatus(input: $input, condition: $condition) {
      id
      vacantNumber
      assignedDays
      statusName
      workTeamTemplateID
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      headcountReports {
        items {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteHeadcountStatus = /* GraphQL */ `
  mutation DeleteHeadcountStatus(
    $input: DeleteHeadcountStatusInput!
    $condition: ModelHeadcountStatusConditionInput
  ) {
    deleteHeadcountStatus(input: $input, condition: $condition) {
      id
      vacantNumber
      assignedDays
      statusName
      workTeamTemplateID
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      headcountReports {
        items {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createHeadcountVacantLog = /* GraphQL */ `
  mutation CreateHeadcountVacantLog(
    $input: CreateHeadcountVacantLogInput!
    $condition: ModelHeadcountVacantLogConditionInput
  ) {
    createHeadcountVacantLog(input: $input, condition: $condition) {
      id
      vacantCounter
      sinceDate
      calculationDate
      statusName
      statusId
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      reportId
      report {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      detail {
        count
        id
        positionId
        positionName
        date
      }
      incidentPlanAction {
        id
        incidentPlanActionName
        parentIncidentPlanActionId
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentObservation {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateHeadcountVacantLog = /* GraphQL */ `
  mutation UpdateHeadcountVacantLog(
    $input: UpdateHeadcountVacantLogInput!
    $condition: ModelHeadcountVacantLogConditionInput
  ) {
    updateHeadcountVacantLog(input: $input, condition: $condition) {
      id
      vacantCounter
      sinceDate
      calculationDate
      statusName
      statusId
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      reportId
      report {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      detail {
        count
        id
        positionId
        positionName
        date
      }
      incidentPlanAction {
        id
        incidentPlanActionName
        parentIncidentPlanActionId
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentObservation {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteHeadcountVacantLog = /* GraphQL */ `
  mutation DeleteHeadcountVacantLog(
    $input: DeleteHeadcountVacantLogInput!
    $condition: ModelHeadcountVacantLogConditionInput
  ) {
    deleteHeadcountVacantLog(input: $input, condition: $condition) {
      id
      vacantCounter
      sinceDate
      calculationDate
      statusName
      statusId
      incidents {
        items {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      reportId
      report {
        id
        managerAttendantId
        vacants
        vacantsChangedAt
        status {
          id
          vacantNumber
          assignedDays
          statusName
          workTeamTemplateID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplateName
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        statusId
        statusName
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        vacantsLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      detail {
        count
        id
        positionId
        positionName
        date
      }
      incidentPlanAction {
        id
        incidentPlanActionName
        parentIncidentPlanActionId
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      incidentObservation {
        id
        incidentObservationName
        parentIncidentObservationId
        incidents {
          nextToken
          startedAt
        }
        headcountVacantLogs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createHeadcountReport = /* GraphQL */ `
  mutation CreateHeadcountReport(
    $input: CreateHeadcountReportInput!
    $condition: ModelHeadcountReportConditionInput
  ) {
    createHeadcountReport(input: $input, condition: $condition) {
      id
      managerAttendantId
      vacants
      vacantsChangedAt
      status {
        id
        vacantNumber
        assignedDays
        statusName
        workTeamTemplateID
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReports {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplateName
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      statusId
      statusName
      warehouseId
      warehouseRelatedId
      warehouseName
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      vacantsLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateHeadcountReport = /* GraphQL */ `
  mutation UpdateHeadcountReport(
    $input: UpdateHeadcountReportInput!
    $condition: ModelHeadcountReportConditionInput
  ) {
    updateHeadcountReport(input: $input, condition: $condition) {
      id
      managerAttendantId
      vacants
      vacantsChangedAt
      status {
        id
        vacantNumber
        assignedDays
        statusName
        workTeamTemplateID
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReports {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplateName
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      statusId
      statusName
      warehouseId
      warehouseRelatedId
      warehouseName
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      vacantsLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteHeadcountReport = /* GraphQL */ `
  mutation DeleteHeadcountReport(
    $input: DeleteHeadcountReportInput!
    $condition: ModelHeadcountReportConditionInput
  ) {
    deleteHeadcountReport(input: $input, condition: $condition) {
      id
      managerAttendantId
      vacants
      vacantsChangedAt
      status {
        id
        vacantNumber
        assignedDays
        statusName
        workTeamTemplateID
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReports {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workTeamTemplateName
      workTeamTemplate {
        id
        workTeamTemplate
        warehouses {
          nextToken
          startedAt
        }
        teamPositions {
          nextToken
          startedAt
        }
        active
        minSalesAmount
        maxSalesAmount
        frecuencySales
        periodSales
        headcountReport {
          nextToken
          startedAt
        }
        headcountStatus {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      statusId
      statusName
      warehouseId
      warehouseRelatedId
      warehouseName
      warehouse {
        id
        warehouseId
        warehouseRelatedId
        warehouseName
        warehouseType
        location {
          coordinates
        }
        address
        taxpayer {
          address
          identification
          taxpayerName
          keyTax
          detailTax
        }
        phones {
          phoneType
          phone
        }
        settings {
          param
          value
        }
        attendants {
          nextToken
          startedAt
        }
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        productKits {
          nextToken
          startedAt
        }
        organization {
          id
          organizationId
          organizationName
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        incidents {
          nextToken
          startedAt
        }
        zone {
          id
          zoneId
          zoneName
          zoneType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workTeamTemplate {
          id
          workTeamTemplate
          active
          minSalesAmount
          maxSalesAmount
          frecuencySales
          periodSales
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        headcountReport {
          id
          managerAttendantId
          vacants
          vacantsChangedAt
          workTeamTemplateName
          statusId
          statusName
          warehouseId
          warehouseRelatedId
          warehouseName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      vacantsLogs {
        items {
          id
          vacantCounter
          sinceDate
          calculationDate
          statusName
          statusId
          reportId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWorkplan = /* GraphQL */ `
  mutation CreateWorkplan(
    $input: CreateWorkplanInput!
    $condition: ModelWorkplanConditionInput
  ) {
    createWorkplan(input: $input, condition: $condition) {
      id
      workplanName
      description
      workplanSection {
        items {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkplan = /* GraphQL */ `
  mutation UpdateWorkplan(
    $input: UpdateWorkplanInput!
    $condition: ModelWorkplanConditionInput
  ) {
    updateWorkplan(input: $input, condition: $condition) {
      id
      workplanName
      description
      workplanSection {
        items {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkplan = /* GraphQL */ `
  mutation DeleteWorkplan(
    $input: DeleteWorkplanInput!
    $condition: ModelWorkplanConditionInput
  ) {
    deleteWorkplan(input: $input, condition: $condition) {
      id
      workplanName
      description
      workplanSection {
        items {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWorkplanSection = /* GraphQL */ `
  mutation CreateWorkplanSection(
    $input: CreateWorkplanSectionInput!
    $condition: ModelWorkplanSectionConditionInput
  ) {
    createWorkplanSection(input: $input, condition: $condition) {
      id
      sectionName
      description
      workplanActivity {
        items {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplan {
        id
        workplanName
        description
        workplanSection {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkplanSection = /* GraphQL */ `
  mutation UpdateWorkplanSection(
    $input: UpdateWorkplanSectionInput!
    $condition: ModelWorkplanSectionConditionInput
  ) {
    updateWorkplanSection(input: $input, condition: $condition) {
      id
      sectionName
      description
      workplanActivity {
        items {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplan {
        id
        workplanName
        description
        workplanSection {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkplanSection = /* GraphQL */ `
  mutation DeleteWorkplanSection(
    $input: DeleteWorkplanSectionInput!
    $condition: ModelWorkplanSectionConditionInput
  ) {
    deleteWorkplanSection(input: $input, condition: $condition) {
      id
      sectionName
      description
      workplanActivity {
        items {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplan {
        id
        workplanName
        description
        workplanSection {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWorkplanActivity = /* GraphQL */ `
  mutation CreateWorkplanActivity(
    $input: CreateWorkplanActivityInput!
    $condition: ModelWorkplanActivityConditionInput
  ) {
    createWorkplanActivity(input: $input, condition: $condition) {
      id
      activityName
      description
      marketReleaseDate
      marketFinalDate
      temporality {
        cron
        frecuency
        frecuencyType
      }
      score
      workplanComponents {
        items {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplanSection {
        id
        sectionName
        description
        workplanActivity {
          nextToken
          startedAt
        }
        workplan {
          id
          workplanName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkplanActivity = /* GraphQL */ `
  mutation UpdateWorkplanActivity(
    $input: UpdateWorkplanActivityInput!
    $condition: ModelWorkplanActivityConditionInput
  ) {
    updateWorkplanActivity(input: $input, condition: $condition) {
      id
      activityName
      description
      marketReleaseDate
      marketFinalDate
      temporality {
        cron
        frecuency
        frecuencyType
      }
      score
      workplanComponents {
        items {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplanSection {
        id
        sectionName
        description
        workplanActivity {
          nextToken
          startedAt
        }
        workplan {
          id
          workplanName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkplanActivity = /* GraphQL */ `
  mutation DeleteWorkplanActivity(
    $input: DeleteWorkplanActivityInput!
    $condition: ModelWorkplanActivityConditionInput
  ) {
    deleteWorkplanActivity(input: $input, condition: $condition) {
      id
      activityName
      description
      marketReleaseDate
      marketFinalDate
      temporality {
        cron
        frecuency
        frecuencyType
      }
      score
      workplanComponents {
        items {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      workplanSection {
        id
        sectionName
        description
        workplanActivity {
          nextToken
          startedAt
        }
        workplan {
          id
          workplanName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWorkplanComponent = /* GraphQL */ `
  mutation CreateWorkplanComponent(
    $input: CreateWorkplanComponentInput!
    $condition: ModelWorkplanComponentConditionInput
  ) {
    createWorkplanComponent(input: $input, condition: $condition) {
      id
      label
      order
      required
      visible
      enable
      componentType
      properties
      quantificationType
      workplanActivity {
        id
        activityName
        description
        marketReleaseDate
        marketFinalDate
        temporality {
          cron
          frecuency
          frecuencyType
        }
        score
        workplanComponents {
          nextToken
          startedAt
        }
        workplanSection {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanExecutionDetail {
        id
        workplanExecution {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanComponent {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkplanComponent = /* GraphQL */ `
  mutation UpdateWorkplanComponent(
    $input: UpdateWorkplanComponentInput!
    $condition: ModelWorkplanComponentConditionInput
  ) {
    updateWorkplanComponent(input: $input, condition: $condition) {
      id
      label
      order
      required
      visible
      enable
      componentType
      properties
      quantificationType
      workplanActivity {
        id
        activityName
        description
        marketReleaseDate
        marketFinalDate
        temporality {
          cron
          frecuency
          frecuencyType
        }
        score
        workplanComponents {
          nextToken
          startedAt
        }
        workplanSection {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanExecutionDetail {
        id
        workplanExecution {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanComponent {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkplanComponent = /* GraphQL */ `
  mutation DeleteWorkplanComponent(
    $input: DeleteWorkplanComponentInput!
    $condition: ModelWorkplanComponentConditionInput
  ) {
    deleteWorkplanComponent(input: $input, condition: $condition) {
      id
      label
      order
      required
      visible
      enable
      componentType
      properties
      quantificationType
      workplanActivity {
        id
        activityName
        description
        marketReleaseDate
        marketFinalDate
        temporality {
          cron
          frecuency
          frecuencyType
        }
        score
        workplanComponents {
          nextToken
          startedAt
        }
        workplanSection {
          id
          sectionName
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanExecutionDetail {
        id
        workplanExecution {
          id
          startDate
          endDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanComponent {
          id
          label
          order
          required
          visible
          enable
          componentType
          properties
          quantificationType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        detail
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWorkplanAssignment = /* GraphQL */ `
  mutation CreateWorkplanAssignment(
    $input: CreateWorkplanAssignmentInput!
    $condition: ModelWorkplanAssignmentConditionInput
  ) {
    createWorkplanAssignment(input: $input, condition: $condition) {
      workplanId
      assignmentId
      workplanAssignmentType
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkplanAssignment = /* GraphQL */ `
  mutation UpdateWorkplanAssignment(
    $input: UpdateWorkplanAssignmentInput!
    $condition: ModelWorkplanAssignmentConditionInput
  ) {
    updateWorkplanAssignment(input: $input, condition: $condition) {
      workplanId
      assignmentId
      workplanAssignmentType
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkplanAssignment = /* GraphQL */ `
  mutation DeleteWorkplanAssignment(
    $input: DeleteWorkplanAssignmentInput!
    $condition: ModelWorkplanAssignmentConditionInput
  ) {
    deleteWorkplanAssignment(input: $input, condition: $condition) {
      workplanId
      assignmentId
      workplanAssignmentType
      marketReleaseDate
      marketFinalDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWorkplanExecution = /* GraphQL */ `
  mutation CreateWorkplanExecution(
    $input: CreateWorkplanExecutionInput!
    $condition: ModelWorkplanExecutionConditionInput
  ) {
    createWorkplanExecution(input: $input, condition: $condition) {
      id
      startDate
      endDate
      score
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanExecutionDetails {
        items {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkplanExecution = /* GraphQL */ `
  mutation UpdateWorkplanExecution(
    $input: UpdateWorkplanExecutionInput!
    $condition: ModelWorkplanExecutionConditionInput
  ) {
    updateWorkplanExecution(input: $input, condition: $condition) {
      id
      startDate
      endDate
      score
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanExecutionDetails {
        items {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkplanExecution = /* GraphQL */ `
  mutation DeleteWorkplanExecution(
    $input: DeleteWorkplanExecutionInput!
    $condition: ModelWorkplanExecutionConditionInput
  ) {
    deleteWorkplanExecution(input: $input, condition: $condition) {
      id
      startDate
      endDate
      score
      attendant {
        id
        attendantId
        fullName
        userName
        hashPassword
        nipPassword
        tokenRequest
        profilePhoto
        limitEpoch
        managerAttendantId
        active
        sales {
          nextToken
          startedAt
        }
        orders {
          nextToken
          startedAt
        }
        warehouse {
          id
          warehouseId
          warehouseRelatedId
          warehouseName
          warehouseType
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        roles {
          nextToken
          startedAt
        }
        phones {
          phoneType
          phone
        }
        emails {
          emailType
          email
        }
        fingers {
          fingerType
          fingerTemplate
        }
        position {
          id
          positionId
          positionName
          parentPositionId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        cashRegisterShifts {
          nextToken
          startedAt
        }
        cashDrawers {
          nextToken
          startedAt
        }
        kardexs {
          nextToken
          startedAt
        }
        incidents {
          id
          datetime
          day
          warehouseId
          description
          headcountCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        discounts {
          nextToken
          startedAt
        }
        attendanceRecords {
          nextToken
          startedAt
        }
        xadisEmptyContainers {
          nextToken
          startedAt
        }
        workplanExecution {
          nextToken
          startedAt
        }
        color
        organizationId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanExecutionDetails {
        items {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWorkplanExecutionDetail = /* GraphQL */ `
  mutation CreateWorkplanExecutionDetail(
    $input: CreateWorkplanExecutionDetailInput!
    $condition: ModelWorkplanExecutionDetailConditionInput
  ) {
    createWorkplanExecutionDetail(input: $input, condition: $condition) {
      id
      workplanExecution {
        id
        startDate
        endDate
        score
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetails {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanComponent {
        id
        label
        order
        required
        visible
        enable
        componentType
        properties
        quantificationType
        workplanActivity {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetail {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      detail
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkplanExecutionDetail = /* GraphQL */ `
  mutation UpdateWorkplanExecutionDetail(
    $input: UpdateWorkplanExecutionDetailInput!
    $condition: ModelWorkplanExecutionDetailConditionInput
  ) {
    updateWorkplanExecutionDetail(input: $input, condition: $condition) {
      id
      workplanExecution {
        id
        startDate
        endDate
        score
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetails {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanComponent {
        id
        label
        order
        required
        visible
        enable
        componentType
        properties
        quantificationType
        workplanActivity {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetail {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      detail
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkplanExecutionDetail = /* GraphQL */ `
  mutation DeleteWorkplanExecutionDetail(
    $input: DeleteWorkplanExecutionDetailInput!
    $condition: ModelWorkplanExecutionDetailConditionInput
  ) {
    deleteWorkplanExecutionDetail(input: $input, condition: $condition) {
      id
      workplanExecution {
        id
        startDate
        endDate
        score
        attendant {
          id
          attendantId
          fullName
          userName
          hashPassword
          nipPassword
          tokenRequest
          profilePhoto
          limitEpoch
          managerAttendantId
          active
          color
          organizationId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetails {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      workplanComponent {
        id
        label
        order
        required
        visible
        enable
        componentType
        properties
        quantificationType
        workplanActivity {
          id
          activityName
          description
          marketReleaseDate
          marketFinalDate
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        workplanExecutionDetail {
          id
          detail
          date
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      detail
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createRemoteCommand = /* GraphQL */ `
  mutation CreateRemoteCommand(
    $input: CreateRemoteCommandInput!
    $condition: ModelRemoteCommandConditionInput
  ) {
    createRemoteCommand(input: $input, condition: $condition) {
      id
      remoteCommandName
      remoteCommandDescription
      command
      enable
      remoteControl {
        items {
          id
          warehouseId
          params
          response
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRemoteCommand = /* GraphQL */ `
  mutation UpdateRemoteCommand(
    $input: UpdateRemoteCommandInput!
    $condition: ModelRemoteCommandConditionInput
  ) {
    updateRemoteCommand(input: $input, condition: $condition) {
      id
      remoteCommandName
      remoteCommandDescription
      command
      enable
      remoteControl {
        items {
          id
          warehouseId
          params
          response
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRemoteCommand = /* GraphQL */ `
  mutation DeleteRemoteCommand(
    $input: DeleteRemoteCommandInput!
    $condition: ModelRemoteCommandConditionInput
  ) {
    deleteRemoteCommand(input: $input, condition: $condition) {
      id
      remoteCommandName
      remoteCommandDescription
      command
      enable
      remoteControl {
        items {
          id
          warehouseId
          params
          response
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createRemoteControl = /* GraphQL */ `
  mutation CreateRemoteControl(
    $input: CreateRemoteControlInput!
    $condition: ModelRemoteControlConditionInput
  ) {
    createRemoteControl(input: $input, condition: $condition) {
      id
      remoteCommand {
        id
        remoteCommandName
        remoteCommandDescription
        command
        enable
        remoteControl {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouseId
      params
      remoteControlStatus {
        startDatetime
        endDatetime
        remoteControlStatusType
        attendantId
      }
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRemoteControl = /* GraphQL */ `
  mutation UpdateRemoteControl(
    $input: UpdateRemoteControlInput!
    $condition: ModelRemoteControlConditionInput
  ) {
    updateRemoteControl(input: $input, condition: $condition) {
      id
      remoteCommand {
        id
        remoteCommandName
        remoteCommandDescription
        command
        enable
        remoteControl {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouseId
      params
      remoteControlStatus {
        startDatetime
        endDatetime
        remoteControlStatusType
        attendantId
      }
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRemoteControl = /* GraphQL */ `
  mutation DeleteRemoteControl(
    $input: DeleteRemoteControlInput!
    $condition: ModelRemoteControlConditionInput
  ) {
    deleteRemoteControl(input: $input, condition: $condition) {
      id
      remoteCommand {
        id
        remoteCommandName
        remoteCommandDescription
        command
        enable
        remoteControl {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      warehouseId
      params
      remoteControlStatus {
        startDatetime
        endDatetime
        remoteControlStatusType
        attendantId
      }
      response
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
