import React from "react";

// Localize for Multilanguaje
import SupportMultiLanguaje from './SupportMultiLanguaje'

import { Auth } from 'aws-amplify';

import Button from '@material-ui/core/Button';
import { store } from "../App";

export const SNACKBAR_DEFAULT = "default";
export const SNACKBAR_SUCCESS = "success";
export const SNACKBAR_WARNING = "warning";
export const SNACKBAR_ERROR = "error";
export const SNACKBAR_INFO = "info";

export default class SessionControlSupportMultiLanguaje extends SupportMultiLanguaje {
  constructor(props) {
    super(props);

    this.state = {
      user: null
    }

    this.signOut = this.signOut.bind(this);
    this.getSecureStore = this.getSecureStore.bind(this);
    this.isUserSignedIn = this.isUserSignedIn.bind(this);
    this.handleSingInError = this.handleSingInError.bind(this);
    // this.showMessage = this.showMessage.bind(this);
    this.action = this.action.bind(this);
  }

  signOut = () => Auth.signOut({ global: true })
    .then(data => {
      // console.log("Auth.signOut", data)
      this.isUserSignedIn();
      return data;
    })
    .catch(err => {
      throw err;
    })

  getSecureStore = () => store.getState()

  isUserSignedIn = async () => {
    this.setState({ checkingSignedInStatus: true });

    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      // We accept the user session, Nothing for do
      // console.log('Auth.currentAuthenticatedUser', user);
      this.setState({ user: user });
    })
      .catch(err => {
        this.handleSingInError(err, { username: '', password: '' })
      });
  };

  handleSingInError(err) {
    // console.log('Auth.currentAuthenticatedUser.Err.handleSingInError', err, this.props);
    this.showMessage(this.translate(err.message ? err.message : (err.log ? err.log : err)), SNACKBAR_ERROR, 5000);

    var secureStore = this.getSecureStore();

    if (err !== "not authenticated") {
      if (typeof secureStore.UserSignInReducer.signInFailedReason !== 'undefined'
        && typeof secureStore.UserSignInReducer.signInFailedReason.data !== 'undefined') {
        this.handleSingInError(secureStore.UserSignInReducer.signInFailedReason.data);
      }
    } else {
      this.go('/userSignIn');
    }
  }

  action = key => {
    return (<Button onClick={() => this.props.closeSnackbar(key)}>{this.translate("Dissmiss me")}</Button>);
  };

  showMessage(message, msg_type, msg_duration, btn_msg, msg_vertical, msg_horizontal, msg_content, msg_persist, msg_action) {
    console.log("showMessage", message, msg_type, msg_duration, btn_msg, this.props);

    return this.props.enqueueSnackbar(message, {
      variant: msg_type,
      autoHideDuration: msg_duration ? msg_duration : 3000,
      anchorOrigin: {
        vertical: msg_vertical ? msg_vertical : 'bottom',
        horizontal: msg_horizontal ? msg_horizontal : 'left',
      },
      persist: msg_persist,
      content: (key) => (msg_content),
      action: msg_action ? msg_action : this.action,
    });
  }
}