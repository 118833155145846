import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import {
  FETCH_FUNCTIONS,
  SET_FUNCTIONS,
  SET_FUNCTIONS_ERROR,
} from './constants';

const fetchFunctions = (state, payload) => ({
  ...state,
  loading: true,
  error: false,
});

const setFunctions = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  data: action.payload,
});

const setFunctionsError = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

export const reducer = handleActions(
  {
    [FETCH_FUNCTIONS]: fetchFunctions,
    [SET_FUNCTIONS]: setFunctions,
    [SET_FUNCTIONS_ERROR]: setFunctionsError,
  },
  INITIAL_STATE,
);
