import { createAction } from 'redux-actions';
import {
  FETCH_VARIABLES,
  SET_VARIABLES,
  SET_VARIABLES_ERROR,
} from './constants';

export const fetchVariables = createAction(FETCH_VARIABLES);
export const setVariables = createAction(SET_VARIABLES);
export const setVariablesError = createAction(SET_VARIABLES_ERROR);
