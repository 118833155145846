import React from 'react';
import { Box } from "@material-ui/core";

import { withSnackbar } from 'notistack';

function CustomerProfile(props) {
    return <Box>

    </Box>
}

export default withSnackbar(CustomerProfile);