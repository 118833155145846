import { createAction } from 'redux-actions';
import {
  SET_VARIABLE_NAME,
  SET_VARIABLE_ID,
  SET_VARIABLE_ORGANIZATION,
  SET_VARIABLE_DATASOURCE,
  SET_VARIABLE_TAG_NAME,
  SET_VARIABLE_TAG_DATE,
  SET_VARIABLE_TAG_ESTATUS_EDIT,
  RESET_VARIABLE_TAG
} from './constants';

export const setVariableName = createAction(SET_VARIABLE_NAME);
export const setVariableId = createAction(SET_VARIABLE_ID);
export const setVariableOrganization = createAction(SET_VARIABLE_ORGANIZATION);
export const setVariableDataSource = createAction(SET_VARIABLE_DATASOURCE);
export const setVariableTagName = createAction(SET_VARIABLE_TAG_NAME);
export const setVariableTagDate = createAction(SET_VARIABLE_TAG_DATE);
export const setVariableTagEdit = createAction(SET_VARIABLE_TAG_ESTATUS_EDIT);
export const resetVariable = createAction(RESET_VARIABLE_TAG);

