import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getCreateFunctionState = state => get(state, 'createFunction');

export const getCreateFunctionName = createSelector(
  [getCreateFunctionState],
  createFunction => get(createFunction, 'name'),
);

export const getCreateFunctionDescription = createSelector(
  [getCreateFunctionState],
  createFunction => get(createFunction, 'description'),
);

export const getCreateFunctionParameters = createSelector(
  [getCreateFunctionState],
  createFunction => get(createFunction, 'parameters'),
);

export const getCreateFunctionSteps = createSelector(
  [getCreateFunctionState],
  createFunction => get(createFunction, 'steps'),
);

export const getCreateFunctionActiveStep = createSelector(
  [getCreateFunctionSteps],
  steps => steps.length - 1,
);

export const isCreateFunctionTested = createSelector(
  [getCreateFunctionState],
  createFunction => get(createFunction, 'tested'),
);

export const isCreateFunctionSending = createSelector(
  [getCreateFunctionState],
  createFunction => get(createFunction, 'sending'),
);

export const getCreateFunctionExpression = createSelector(
  [getCreateFunctionState],
  createFunction => get(createFunction, 'expression'),
);

export const isDeletingCreateFunction = createSelector(
  [getCreateFunctionState],
  createFunction => get(createFunction, 'isDeleting'),
);
