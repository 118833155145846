import React, { Component } from 'react';
import { Button, Typography, Container } from '@material-ui/core';
import LodingIndicator from '../../../components/LoadingIndicator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  geFunctions,
  isErrorGetFunctions,
  isLoadingFunctions,
} from '../../../store/functions/selectors';
import { fetchFunctions } from '../../../store/functions/actions';
import MaterialTable from 'material-table';
import { tableESP } from '../../../helpers';
import Breadcrumbs from '../../../components/Breadcrumbs';

import './styles.scss';

export class ListFunctions extends Component {
  componentDidMount() {
    this.props.fetchFunctions();
  }

  render() {
    const { functions, loading } = this.props;
    return (
      <Container fixed>
        <div className={'functions-list'}>
          <div className="breadcrumbs-container-wrapper">
            <Breadcrumbs {...this.props}
              navigation={[
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                {
                  title: 'Buscar funciones',
                  current: true,
                },
              ]}
            />
          </div>
          <Button
            className={'small-btn'}
            color="primary"
            component={Link}
            to={`crear/`}
            variant="contained"
          >
            Crear nueva Funcion
        </Button>
          <Typography variant="h5" gutterBottom>
            Funciones registradas
        </Typography>
          {loading ? (
            <LodingIndicator />
          ) : (
              <MaterialTable
                title={' '}
                data={functions}
                columns={[
                  { title: 'Nombre', field: 'name' },
                  { title: 'Descripción', field: 'description' },
                  {
                    title: 'Argumentos',
                    render: (rowData, index) => (
                      <div key={index}>
                        {rowData.arguments.map((e, i) => (
                          <span key={i}>{e} </span>
                        ))}
                      </div>
                    ),
                  },
                  {
                    render: rowData => (
                      <Button
                        className={'small-btn'}
                        color="primary"
                        component={Link}
                        to={`detalle/${rowData.id}`}
                        variant="contained"
                      >
                        Editar
                      </Button>
                    ),
                  },
                ]}
                localization={tableESP}
              />
            )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  functions: geFunctions(state),
  error: isErrorGetFunctions(state),
  loading: isLoadingFunctions(state),
});

const mapDispatchToProps = dispatch => ({
  fetchFunctions: payload => dispatch(fetchFunctions(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListFunctions);
