import React , { useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import "./styles.scss";

const CVTagVariableValue = ({
        catalog,
        editTagVariable,
        valueNumberTag,
        changeValueTag,
        changeEmployeeTag,
        addValueTable,
        addValueEmployeeValue        
    }) => {

    const[disabled , setNotDisabled] = useState(true);
    
    return (
        <div className="formAdd">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h3>Buscar por nombre del empleado : </h3>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                        options={catalog}
                        getOptionLabel={(option) => { return (`${option.name} - ${option.job}`)}}
                        renderInput={(params) => <TextField {...params} label="Nombre Empleado" variant="outlined" />}
                        onChange={(event, newValue) => {                            
                            if(newValue){
                                setNotDisabled(false);
                                changeEmployeeTag(newValue)
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField 
                        className={'nameInput'} 
                        label="Valor" 
                        type="number" 
                        value={valueNumberTag}
                        onChange={(event) => changeValueTag(event.target.value)}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    { editTagVariable ? 
                        <Button 
                            variant="contained"
                            className={'h56 btn accept-btn'}
                            onClick={addValueEmployeeValue}
                            disabled={disabled ||  valueNumberTag === ''}
                        >
                        Agregar Valor al Tag
                        </Button> 
                        : 
                        <Button 
                            variant="contained" color="primary"
                            className={'h56 btn accept-btn'}
                            onClick={addValueTable}
                            disabled={disabled ||  valueNumberTag === ''}
                        >
                        Agregar
                        </Button>   
                    }                    
                </Grid>
            </Grid>            
        </div>
        
    );
};

CVTagVariableValue.propTypes = {
    
};


export default CVTagVariableValue;


