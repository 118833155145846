import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


const HCHistoryChart = ({ history, dataKey }) => {
  // console.log(dataReports)

  const HistoryChart = ({ data, dataKey }) => {
    console.log(data, dataKey)
    return (
      <LineChart width={600} height={300} data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="calculationDate" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" activeDot={{ r: 8 }} dataKey={"vacants"} fill="#a9a9a9" />
      </LineChart>
    )
  }

  return (
    <div>
      <HistoryChart
        data={history}
        dataKey={dataKey}
      ></HistoryChart>
    </div>
  );

};

HCHistoryChart.propTypes = {
  dataReports: PropTypes.array.isRequired,
};

HCHistoryChart.defaultProps = {

};

export default HCHistoryChart;
