import React from "react";
import SessionControl from '../../utils/SessionControl';
import { Box } from "@material-ui/core";

class SignOut extends SessionControl {
    constructor(props) {
        super(props);

        this.handleSignOut = this.handleSignOut.bind(this);
    }

    componentDidMount() {
        this.handleSignOut();
    }

    handleSignOut = (event) => this.signOut().then(data => {
        this.props.UserSignIn_logOut({});
        return data;
    }).catch(err => {
        throw err;
    })

    render() {
        return <Box />;
    }
}

export default SignOut;