import { createAction } from 'redux-actions';
import {
  FETCH_EMPLOYEES,
  SET_EMPLOYEES,
  SET_EMPLOYEES_ERROR,
} from './constants';

export const fetchEmployees = createAction(FETCH_EMPLOYEES);
export const setEmployees = createAction(SET_EMPLOYEES);
export const setEmployeesError = createAction(SET_EMPLOYEES_ERROR);
