import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getEmployeeCatalogState = state => get(state, 'employeeCatalog');

export const isLoadingEmployeeCatalog = createSelector(
  [getEmployeeCatalogState],
  employeeCatalog => get(employeeCatalog, 'loading'),
);

export const getEmployeeCatalog = createSelector(
  [getEmployeeCatalogState],
  employeeCatalog => get(employeeCatalog, 'data'),
);

export const isErrorGetEmployeeCatalog = createSelector(
  [getEmployeeCatalogState],
  employeeCatalog => get(employeeCatalog, 'error'),
);
