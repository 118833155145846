import React, { Component } from 'react';
import AceEditor from 'react-ace';
/*import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-github';*/

import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import './styles.scss';

export class StepsAdvancedMode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
    };
  }

  expressionChange = e => {
    this.props.setExpression(e);
  };

  confirmDelete = editID => {
    this.setState({
      ...this.state,
      openDialog: false,
    });
    this.props.delete(editID);
  };

  render() {
    const {
      expression,
      isEditing,
      isDeleting,
      testIndicator,
      editID,
      edit,
      save,
      testResult,
      isSending,
    } = this.props;
    const { openDialog } = this.state;
    return (
      <div>
        <AceEditor
          mode="java"
          theme="github"
          onChange={this.expressionChange}
          value={expression}
          name="text_editor"
          editorProps={{ $blockScrolling: true }}
          width="100%"
        />
        <div className={'steps-footer'}>
          {isEditing && (
            <div>
              {isDeleting ? (
                <Button
                  className={'btn delete-btn'}
                  variant="contained"
                  disabled={true}
                >
                  <CircularProgress className={'loading'} />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={'btn delete-btn'}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      openDialog: true,
                    })
                  }
                >
                  Eliminar
                </Button>
              )}
              <Dialog
                open={openDialog}
                onClose={() =>
                  this.setState({
                    ...this.state,
                    openDialog: false,
                  })
                }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'¿Deseas eliminar está función?'}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        openDialog: false,
                      })
                    }
                    color="primary"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => this.confirmDelete(editID)}
                    color="primary"
                    autoFocus
                  >
                    Si
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}

          <Grid className={'btn'} container spacing={3}>
            <Grid item xs={12}>
              <Button
                className={'btn accept-btn'}
                onClick={() => testIndicator()}
              >
                Probar
              </Button>
            </Grid>
            <Grid item xs={12}>
              {isSending ? (
                <Button
                  className={'btn save-btn'}
                  variant="contained"
                  disabled={true}
                >
                  <CircularProgress className={'loading'} />
                </Button>
              ) : (
                <Button
                  className={'btn save-btn'}
                  variant="contained"
                  onClick={() => (isEditing ? edit(editID) : save())}
                  disabled={testResult ? false : true}
                >
                  Guardar
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default StepsAdvancedMode;
