import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';
import {
  Drawer, Typography, List, ListItem, ListItemIcon, Divider,
  Avatar, Box, Zoom
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { ColorsCatalog } from '../../../schema/enums/schemaEnums';

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

export default function ProfileMenu(props) {
  // console.log("ProfileMenu", props)
  const [profileMenuState, setProfileMenuState] = useState({
    userSignIn: props.userSignIn,
    user: "user" in props ? props.user : null,
    userName: props.userSignIn ? "attributes" in props.userSignIn && "custom:login_name" in props.userSignIn.attributes ? props.userSignIn.attributes['custom:login_name'] : props.userSignIn.fullName : "",
    profileMenuListItems: "profileMenuListItems" in props ? props.profileMenuListItems : [],
    positions: props.positions,
    right: false
  })

  useEffect(() => {
    setProfileMenuState({
      userSignIn: props.userSignIn,
      userName: "attributes" in props.userSignIn && "custom:login_name" in props.userSignIn.attributes ? props.userSignIn.attributes['custom:login_name'] : props.userSignIn.username,
      profileMenuListItems: "profileMenuListItems" in props ? props.profileMenuListItems : [],
      user: props.user
    })
  }, [props.user, props.userSignIn, props.profileMenuListItems]);

  const classes = useStyles();

  const handleLogOut = event => {
    props.handleSignOut(event);
  }

  const handleGo = (path, event) => {
    toggleDrawer('right', true)
    props.go(path);
  }

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setProfileMenuState({ ...profileMenuState, [side]: open });
  };

  const renderProfileMenuItems = menuItem => {
    if ((menuItem.hasOwnProperty('active') && menuItem.active) || !menuItem.hasOwnProperty('active'))
      return (<ListItem button
        key={menuItem.id}
        onClick={event => handleGo(`prometheus/${menuItem.path.replace(":id", profileMenuState.user.id)}`)}>
        <ListItemIcon>
          <Icon fontSize="small">{menuItem.icon}</Icon>
        </ListItemIcon>
        <Typography noWrap>
          {props.translate(menuItem.systemMenuName)}
        </Typography>
      </ListItem>);
  }

  return (
    <Grid container justify="flex-end" alignItems="center">
      <Grid item xs={8}>
        {profileMenuState.user ?
          <Zoom in={Boolean(profileMenuState.user)} style={{ transitionDelay: '500ms' }}>
            <Grid container spacing={0} direction="column" justify="center" alignItems="flex-end">
              <Grid item>
                <Typography noWrap
                  color="textSecondary"
                  component="span"
                  variant="caption">
                  {profileMenuState.user.position ? props.translate(profileMenuState.user.position.positionName) : null}
                </Typography>
              </Grid>
              <Grid item>
                <Typography noWrap
                  variant="h6">
                  {profileMenuState.user.fullName}
                </Typography>
              </Grid>
            </Grid>
          </Zoom>
          :
          <Grid container spacing={0} direction="column">
            <Zoom in={!Boolean(profileMenuState.user)}>
              <Typography noWrap
                color="textSecondary"
                variant="body2">
                {profileMenuState.userName}
              </Typography>
            </Zoom>
          </Grid>}
      </Grid>
      <Grid item xs={4}>
        <Button
          onClick={toggleDrawer('right', true)}>
          <Avatar
            style={{ backgroundColor: profileMenuState.user && profileMenuState.user.color ? ColorsCatalog[profileMenuState.user.color][500] : '' }}
            alt={profileMenuState.userName}
            src={profileMenuState.user && profileMenuState.user.profilePhotoData ? profileMenuState.user.profilePhotoData : profileMenuState.user && profileMenuState.user.profilePhotoUrl} />
        </Button>
        <Drawer anchor="right" open={profileMenuState.right} onClose={toggleDrawer('right', false)}>
          <Box my={2}>
            <Zoom in={Boolean(profileMenuState.user)}>
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <Avatar
                    variant="rounded"
                    className={classes.large}
                    style={{ backgroundColor: profileMenuState.user && profileMenuState.user.color ? ColorsCatalog[profileMenuState.user.color][500] : '' }}
                    alt={profileMenuState.userName}
                    src={profileMenuState.user && profileMenuState.user.profilePhotoData ? profileMenuState.user.profilePhotoData : profileMenuState.user && profileMenuState.user.profilePhotoUrl} />
                </Grid>
                <Grid item xs={12} />
                <Grid item>
                  <Grid container spacing={0} alignItems="flex-end" direction="column">
                    <Grid item>
                      <Typography noWrap
                        component="h1"
                        variant="subtitle1">
                        {profileMenuState.user && profileMenuState.user.fullName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography noWrap
                        component="span"
                        color="textSecondary"
                        variant="caption">
                        {profileMenuState.user && profileMenuState.user.position ? props.translate(profileMenuState.user.position.positionName) : null}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography noWrap
                        component="span"
                        color="textSecondary"
                        variant="caption">
                        {profileMenuState.user && profileMenuState.userName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Zoom>
          </Box>
          <Divider variant="middle" />
          <List>
            {profileMenuState.profileMenuListItems && profileMenuState.profileMenuListItems.map(function (item) {
              return renderProfileMenuItems(item)
            })}
            <Divider variant="middle" />
            <ListItem button onClick={handleLogOut}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <Typography noWrap>
                {props.translate("Close Session")}
              </Typography>
            </ListItem>
          </List>
        </Drawer>
      </Grid>
    </Grid>
  );
}
