import React, { useState, useEffect, useRef } from 'react';
import { Paper, Typography, Grid, Grow, Container, Button, Box, Tabs, Tab, makeStyles, LinearProgress } from '@material-ui/core';
import TabPanel from '../../components/tabPanel/TabPanel';
import { withSnackbar } from 'notistack';

import WarehouseHome from "./systemWarehouse/Warehousehome"
import ConstructorSelector from '../../components/ConstructorSelector';

import { listWarehouses } from '../../graphql/queries'
import { updateWarehouse } from '../../graphql/mutations'

import { api, apiPartial } from "../../api/api";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        overflow: "inherit"
    },
    progress: {
        width: `100%`
    }
}));

function Warehause(props) {
    const classes = useStyles();
    const firstRender = useRef(true);
    const [searching, setSearching] = useState({
        ERP: false,
        Pinpad: false
    })
    const [selectedTab, setSelectedTab] = useState(0);
    const [warehouse, setWarehouse] = useState(null);
    const [erp, setErp] = useState(null);
    const [pinpad, setPinpad] = useState(null);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render

            loadWarehouse()

            return // skip the code below
        }

        return function cleanup() {

        };
    }, []) // Only re-run the effect if name or email change


    const loadWarehouse= () => {    
        api(listWarehouses, { limit: 300 })
        .then((res) => {
            setWarehouse(res)
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };


    return <Grow in={true}>
    <Container fixed>
        <Box my={2}>
            <Grid
                container
                alignItems="center">
                <Grid item>
                    <Typography variant="h5" color="textSecondary">
                        {props.translate("System Settings")}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        <Paper>
            <Box className={classes.root}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={selectedTab}
                    onChange={handleChangeTab}
                    className={classes.tabs}
                >
                    <Tab
                        icon={searching.ERP ? <LinearProgress color="secondary" className={classes.progress} /> : ''}
                        label={props.translate("ERP")}
                        />
                    <Tab
                        icon={searching.Pinpad ? <LinearProgress color="secondary" className={classes.progress} /> : ''}
                        label={props.translate("PINPAD")}
                         />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                      <WarehouseHome
                        {...props}
                        warehouse={warehouse}
                        loadWarehouse={loadWarehouse}
                         />  
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    {/* <SystemScope
                        {...props}
                        scopes={scopes} /> */}
                </TabPanel>
            </Box>
        </Paper>
    </Container>
</Grow >
}
export default withSnackbar(Warehause);