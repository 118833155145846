import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid, Typography, Divider, TextField, withStyles, FormHelperText, FormControl, InputAdornment, IconButton, CircularProgress, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import MailIcon from '@material-ui/icons/Mail';
import SendIcon from '@material-ui/icons/Send';

import overlay from '../../assets/wallpapers/overlay.png'
import coatza from '../../assets/wallpapers/barrillas.jpg'

// Amplify 
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { createEmailSuscription } from '../../graphql/mutations';
import { NavLink } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Footer from './Footer';

// Amplify init
Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
    overlay: {
        background: "rgba(0,0,0,0.5)",
        backgroundImage: `url(${overlay})`,
        backgroundRepeat: `repeat`,
        height: "100%",
        left: "0",
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: "-1"
    },
    backgroundImageBama: {
        backgroundImage: `url(${coatza})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat center',
        width: "100%",
        height: "100%",
        zIndex: "-999999",
        position: "fixed"
    },
}));

const ValidationTextField = withStyles({
    root: {
        background: 'linear-gradient(45deg, #ffc10a55 30%, #17529C55 90%);',
        '& label': {
            color: 'white',
        },
        '& label.Mui-focused': {
            color: '#ffc10a',
        },
        '& .MuiFilledInput-input': {
            color: 'white',
        }
    },
})(TextField);

function LandingPage(props) {
    const firstRender = useRef(true)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(null)
    const [remainigTime, setRemainigTime] = useState(0)
    const [sending, setSending] = useState(false)
    const [timer, setTimer] = useState(null)

    const releaseTimeStamp = 1596240000000;

    useEffect(() => {
        if (firstRender.current) {
            setRemainigTime(releaseTimeStamp - new Date().getTime())
            firstRender.current = false  // it's no longer the first render
            return // skip the code below
        }

        releaseTimeStamp > remainigTime && setTimer(setTimeout(() => setRemainigTime(remainigTime - 1000), 1000));

        formValidation()

        return function cleanup() {
            clearTimeout(timer);
        };
    }, [email, remainigTime]) // Only re-run the effect if name or email change

    const classes = useStyles();

    const defaultLogo = require('../../assets/images/bama_icon.png');

    const formValidation = () => {
        if (email === "") {
            setEmailError('Ingresa tu e-m@il')
            return true
        } if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setEmailError('Formato incorrecto de e-m@il')
            return true
        } else {
            setEmailError(null)
            return false
        }
    }

    const getRemainingDatePart = (datePart) => {
        var remaining = 0,
            d = parseInt(remainigTime / (1000 * 60 * 60 * 24)),
            h = parseInt(remainigTime / (1000 * 60 * 60)),
            m = parseInt(remainigTime / (1000 * 60)),
            s = parseInt(remainigTime / (1000))

        switch (datePart) {
            case "days":
                remaining = d
                break
            case "hours":
                remaining = h - (d * 24)
                break
            case "minutes":
                remaining = m - (h * 60)
                break
            case "seconds":
                remaining = s - (m * 60)
                break
            default:
                break
        }

        return remaining
    }

    const submitEmailSuscription = event => {
        if (!emailError) {
            setSending(true)

            var emailSuscription = {
                email: email,
                active: true,
                origin: "bamaglobal.landingpage"
            }
            API.graphql(graphqlOperation(createEmailSuscription, { input: emailSuscription }))
                .then(allTodos => {
                    var res = allTodos.data.createEmailSuscription;

                    console.log("createEmailSuscription", res)

                    setSending(false)

                    props.enqueueSnackbar("Pronto recibirás promociones y excelentes noticias", {
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        }
                    });

                    setEmail('')
                })
                .catch(err => {
                    setSending(false)

                    console.log(err);

                    err && err.errors && err.errors.map(_error => {

                        props.enqueueSnackbar(_error.message, {
                            variant: "error",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            }
                        });
                    })

                    setEmail('')
                });
        }
        event.preventDefault();
    }

    return (
        <Box>
            <Box className={classes.backgroundImageBama}></Box>
            <Box className={classes.overlay}></Box>
            <Container fixed>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    style={{ minHeight: '100vh', color: "white" }}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center">
                        <Grid item>
                            <Link
                                href="/userSignIn">
                                <img src={defaultLogo} style={{ "maxHeight": "10em" }} alt="Bama POS" />
                            </Link>
                        </Grid>
                        <Grid item>
                            <Box my={2}>
                                <Typography variant="h3">
                                    <Box
                                        display="inline"
                                        fontWeight="fontWeightBold"
                                        style={{ color: "#ffc10a" }}>
                                        {"Tiendas BAMA "}
                                    </Box>
                                    <Box display="inline">
                                        <NavLink
                                            className="MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                                            activeClassName="MuiLink-underlineHover:hover"
                                            style={{ textShadow: "0px 0px 3px white" }}
                                            to="/customerRegistration">{" Conviene"}</NavLink>
                                    </Box>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box mt={2}>
                                {`
                        Nos reinventamos con mucho más para tí
                        `}
                            </Box>
                        </Grid>
                        <Grid item>
                            {`
                        Más productos, mejor atención y una experiencia de compra única
                        `}
                        </Grid>
                        {/* <Grid item>
                            <Box my={2}>
                                <Typography variant="h3">
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Box my={2} mx={6}>
                                                <Grid container
                                                    direction="column"
                                                    alignItems="center">
                                                    <Grid item>
                                                        {getRemainingDatePart("days")}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {"Días"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Divider
                                            style={{ backgroundColor: "white", height: "2em" }}
                                            orientation="vertical" />
                                        <Grid item>
                                            <Box my={2} mx={6}>
                                                <Grid container
                                                    direction="column"
                                                    alignItems="center">
                                                    <Grid item>
                                                        {getRemainingDatePart("hours")}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {"Horas"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Divider
                                            style={{ backgroundColor: "white", height: "2em" }}
                                            orientation="vertical" />
                                        <Grid item>
                                            <Box my={2} mx={6}>
                                                <Grid container
                                                    direction="column"
                                                    alignItems="center">
                                                    <Grid item>
                                                        {getRemainingDatePart("minutes")}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {"Minutos"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Divider
                                            style={{ backgroundColor: "white", height: "2em" }}
                                            orientation="vertical" />
                                        <Grid item>
                                            <Box my={2} mx={6}>
                                                <Grid container
                                                    direction="column"
                                                    alignItems="center">
                                                    <Grid item>
                                                        {getRemainingDatePart("seconds")}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {"Segundos"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Box>
                        </Grid> */}
                        <Grid item>
                            <Box mt={1}>
                                {"Suscríbete y sabrás de nuestras promociones y ofertas"}
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box my={2}>
                                <form autoComplete="off" onSubmit={submitEmailSuscription}>
                                    <FormControl error={Boolean(emailError)}>
                                        <Grid container
                                            spacing={1}
                                            alignItems="center"
                                            justify="center">
                                            <Grid item>
                                                <ValidationTextField
                                                    id="email"
                                                    disabled={Boolean(sending)}
                                                    onChange={e => setEmail(e.target.value)}
                                                    label="Suscríbete"
                                                    variant="filled"
                                                    aria-describedby="email-text"
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <MailIcon fontSize="large" color="action" />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                {Boolean(emailError) ?
                                                    <Alert severity="error">
                                                        <FormHelperText
                                                            id="email-text">
                                                            {emailError}
                                                        </FormHelperText>
                                                    </Alert>
                                                    : Boolean(sending) ?
                                                        <CircularProgress />
                                                        : <IconButton
                                                            type="submit">
                                                            <SendIcon
                                                                color="primary"
                                                                fontSize="large" />
                                                        </IconButton>
                                                }
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                    <Footer />
                </Box>
            </Container>
        </Box >
    )
}

export default withSnackbar(LandingPage);