import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import MaterialTable from 'material-table';
import { Box } from "@material-ui/core";
import { tableIcons, localization } from "../materialTable/MaterialTable";

const HCMaterialTable = ({ title, columns, data, search, translate }) => {
  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        columns={columns}
        data={data}
        title={title}
        icons={tableIcons}
        localization={localization({ translate: translate })}
        options={{
          search: search
        }}
        detailPanel={rowData => {
          console.log(rowData)
          return (<Box m={1}>
            {rowData.teamAssignedCount && rowData.teamAssignedCount.length > 0 ?
              <MaterialTable
                icons={tableIcons}
                localization={localization({ translate: translate })}
                columns={[
                  {
                    field: 'userName', title: 'Usuario'
                  },
                  {
                    field: 'fullName', title: 'Personal'
                  },
                  {
                    field: 'position', title: 'Puesto',
                    render: row => row.position && row.position.positionName ? translate(row.position.positionName) : ''
                  }]}
                data={rowData.teamAssignedCount}
                title={'Personal Asignado'}
                options={{
                  search: search
                }}
              /> : null}
          </Box>
          )
        }}
      />
    </div>
  );
};

HCMaterialTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

HCMaterialTable.defaultProps = {};

export default HCMaterialTable;
