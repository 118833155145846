import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';

import MaterialTable from 'material-table';
import { tableIcons, localization } from '../../../materialTable/MaterialTable';
import { SNACKBAR_INFO } from '../../../../utils/SessionControl';

import AddBoxIcon from '@material-ui/icons/AddBox';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PhoneIcon from '@material-ui/icons/Phone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';
import EditUserModal from './EditUserModal';
import CreateUser from './CreateUserModal'

export default function SystemUser(props) {
    const [selectedUsers, setSelectedUsers] = useState(null)
    const [userSelected, setUserSelected] = useState(null)
    const [openEditUserModal, setOpenEditUserModal] = useState(false)
    const [searching, setSearching] = useState(false)

    const handleSelectionChange = rows => {
        props.showMessage(`${rows.length} ${props.translate("Selected Rows")}`, SNACKBAR_INFO, 5000, null, null, null, null, null, null, props);
        setSelectedUsers(rows)
    }

    const handleEditUser = (event, rowData) => {
        console.log(event, rowData)

        setOpenEditUserModal(true)
        setUserSelected(rowData)

        event.preventDefault();
    }

    const handleNewUser = event => {
        handleEditUser(event, {})
    }

    const handleCloseEditUserModal = () => {
        setOpenEditUserModal(false)
    }

    return (
        <Box p={2}>
            <Grid
                container
                justify="space-between"
                alignItems="center">
                <Grid item>
                    <Typography variant="h6" color="textSecondary">
                        {props.translate("Users")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        justify="flex-end"
                        alignItems="center">
                        <Grid item>
                            <Button
                                onClick={handleNewUser}
                                variant="contained"
                                color="secondary"
                                startIcon={<AddBoxIcon />}>
                                {`${props.translate("Add")} ${props.translate("User")}`}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {props.users && props.users.length > 0 ?
                <Box py={2}>
                    <MaterialTable
                        icons={tableIcons}
                        localization={localization(props)}
                        options={{
                            exportButton: true
                        }}
                        columns={[
                            { title: props.translate("Id"), field: "attendantId" },
                            { title: props.translate("Full Name"), field: "fullName" },
                            { title: props.translate("Username"), field: "userName" },
                            { title: props.translate("Password"), field: "hashPassword" },
                            { title: props.translate("Nip Password"), field: "nipPassword" },
                            { title: props.translate("Photo"), field: "profilePhoto" },
                            { title: props.translate("Manager"), field: "managerAttendantId" },
                            { title: props.translate("Active"), field: "active" },
                            { title: props.translate("Warehouse"), field: "warehouse.warehouseName" },
                            { title: props.translate("Role"), field: "role.systemRoleName" },
                            {
                                title: props.translate("Phone"), field: "phones", render: rowData => {
                                    if (rowData.phones) {
                                        return <Grid
                                            container
                                            spacing={1}
                                            justify="center"
                                            alignItems="center">
                                            <Grid item>
                                                <PhoneIcon color="action" fontSize="small" />
                                            </Grid>
                                            <Grid item>
                                                {rowData.phones.length}
                                            </Grid>
                                        </Grid>
                                    } else {
                                        return ''
                                    }
                                }
                            },
                            {
                                title: props.translate("Email"), field: "emails", render: rowData => {
                                    if (rowData.emails) {
                                        return <Grid
                                            container
                                            spacing={1}
                                            justify="center"
                                            alignItems="center">
                                            <Grid item>
                                                <EmailIcon color="action" fontSize="small" />
                                            </Grid>
                                            <Grid item>
                                                {rowData.emails.length}
                                            </Grid>
                                        </Grid>
                                    } else {
                                        return ''
                                    }
                                }
                            },
                            {
                                title: props.translate("Fingers"), field: "fingers", render: rowData => {
                                    if (rowData.fingers) {
                                        var fc = 0

                                        rowData.fingers.map(f => {
                                            if (f.fingerTemplate)
                                                fc++
                                        })

                                        return <Grid
                                            container
                                            spacing={1}
                                            justify="center"
                                            alignItems="center">
                                            <Grid item>
                                                <FingerprintIcon color="action" fontSize="small" />
                                            </Grid>
                                            <Grid item>
                                                {fc}
                                            </Grid>
                                        </Grid>
                                    } else {
                                        return ''
                                    }
                                }
                            },
                            {
                                title: props.translate("Position"), field: "position", render: rowData => {
                                    if (rowData && rowData.position && rowData.position.positionName)
                                        return props.translate(rowData.position.positionName)
                                }
                            },
                            { title: props.translate("Color"), field: "color" },
                            {
                                title: props.translate("Last Update"), field: "_lastChangedAt",
                                render: rowData => {
                                    return rowData._lastChangedAt ? new Date(rowData._lastChangedAt).toLocaleString() : ''
                                }
                            }
                        ]}
                        data={props.users}
                        title={`${props.users && props.users.length} ${props.translate("Users")}`}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: props.translate("Edit"),
                                onClick: handleEditUser
                            }/*,
                        {
                          icon: 'delete',
                          tooltip: 'Delete User',
                          onClick: (event, rowData) => confirm("You want to delete " + rowData.name)
                        }*/
                        ]}
                    /></Box>
                : ''}

            <CreateUser
                searching={searching}
                user={userSelected}
                open={openEditUserModal}
                translate={props.translate}
                showMessage={props.showMessage}
                handleClose={handleCloseEditUserModal}
            />
        </Box>
    );
}