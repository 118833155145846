import React, { Component } from 'react';
import { connect } from 'react-redux';
import { last } from 'lodash';
import {
  getIndicatorName,
  getIndicatorSteps,
  getIndicatorActiveStep,
  isIndicatorTested,
  isLastFunctionIndicatorValid,
  isIndicatorSending,
  isEditLoading,
  isIndicatorHaveStructure,
  isDeleting,
  getIndicatorArea,
  getNoexistIdsIndicator,
} from '../../../store/indicator/selectors';
import {
  createIndicatorStep,
  testIndicator,
  addInicatorElement,
  saveIndicator,
  setLastFunctionIndicatorValidation,
  deleteIndicatorStep,
  editIndicator,
  saveEditIndicator,
  deleteIndicator,
  resetIndicatorDataSet,
} from '../../../store/indicator/actions';
import LoadingIndicator from '../../../components/LoadingIndicator';

import StepsHeader from '../../../components/Steps/StepsHeader';
import StepsStack from '../../../components/Steps/StepsStack';
import StepsFooter from '../../../components/Steps/StepsFooter';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { Container } from '@material-ui/core';
import SessionControlSupportMultiLanguaje from '../../../utils/SessionControl';

export class StepsIndicator extends SessionControlSupportMultiLanguaje {
  componentDidMount() {
    const {
      params,
      editIndicator,
      isIndicatorHaveStructure,
    } = this.props;

    this.setState({
      orgName: this.getSecureStore().organizations.data.find(org => org.id === this.getSecureStore().indicator.organization.id).name,
      areaName: this.getSecureStore().areas.data.find(area => area.id === this.getSecureStore().indicator.area.id).name
    })

    if (params.indicatorId) {
      editIndicator(params.indicatorId);
    } else {
      if (!isIndicatorHaveStructure) {
        this.props.navigate(`/prometheus/variableCommissions/indicadores/crear/`);
      }
    }
  }

  componentWillUnmount = () => this.props.resetSteps();

  lastStepItem(steps, activeStep) {
    let item = '';
    const lastElement = last(steps[activeStep]);

    if (typeof lastElement === 'undefined') {
      item = '';
    } else {
      switch (lastElement.type) {
        case 'element':
          item = 'element';
          break;
        case 'step':
          item = 'element';
          break;
        case 'operator':
          item = 'operator';
          break;
        default:
          item = '';
          break;
      }
    }

    return item;
  }

  render() {
    let breadcrumbsLinks;
    const {
      indicatorName,
      createStep,
      steps,
      addElement,
      testIndicator,
      activeStep,
      saveIndicator,
      testedResult,
      isLastFunctionValid,
      setLastFunctionValidation,
      isSending,
      deleteStep,
      saveEditIndicator,
      deleteIndicator,
      isEditLoading,
      isDeleting,
      indicatorArea,
      noExistElements,
      params
    } = this.props;

    const {
      orgName,
      areaName
    } = this.state;

    if (params.indicatorId) {
      breadcrumbsLinks = [
        { title: "Dashboard", url: "/prometheus/variableCommissions" },
        { title: 'Buscar Indicadores', url: '/prometheus/variableCommissions/indicadores' },
        {
          title: 'Lista de Indicadores',
          url: `/prometheus/variableCommissions/indicadores/lista/${indicatorArea.id}`,
        },
        {
          title: 'Editar pasos',
          current: true,
        },
      ];
    } else {
      breadcrumbsLinks = [
        { title: "Dashboard", url: "/prometheus/variableCommissions" },
        { title: 'Buscar Indicadores', url: '/prometheus/variableCommissions/indicadores' },
        { title: 'Crear Indicador', url: '/prometheus/variableCommissions/indicadores/crear' },
        {
          title: 'Crear pasos',
          current: true,
        },
      ];
    }

    return (
      <Container fixed>
        <div className="steps-creator">
          <div className="breadcrumbs-container-wrapper">
            <Breadcrumbs {...this.props} navigation={breadcrumbsLinks} />
          </div>
          {params.indicatorId && isEditLoading ? (
            <LoadingIndicator />
          ) : (
              <div>
                <StepsHeader
                  orgName={orgName}
                  areaName={areaName}
                  name={indicatorName}
                  type={'indicator'} />
                <StepsStack
                  type={'indicator'}
                  steps={steps}
                  addElement={payload => addElement(payload)}
                  createStep={payload => createStep(payload)}
                  deleteStep={payload => deleteStep(payload)}
                  activeStep={activeStep}
                  lastStepItem={this.lastStepItem(steps, activeStep)}
                  isLastFunctionValid={payload =>
                    setLastFunctionValidation(payload)
                  }
                  noExistElements={noExistElements}
                />
                <StepsFooter
                  type={'indicator'}
                  testIndicator={payload => testIndicator(payload)}
                  lastStep={steps[activeStep]}
                  save={() => saveIndicator()}
                  edit={payload => saveEditIndicator(payload)}
                  delete={payload => deleteIndicator(payload)}
                  testResult={testedResult}
                  isLastFunctionValid={isLastFunctionValid}
                  isSending={isSending}
                  isEditing={params.indicatorId ? true : false}
                  editID={params.indicatorId}
                  isDeleting={isDeleting}
                />
              </div>
            )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  activeStep: getIndicatorActiveStep(state),
  indicatorName: getIndicatorName(state),
  steps: getIndicatorSteps(state),
  testedResult: isIndicatorTested(state),
  isLastFunctionValid: isLastFunctionIndicatorValid(state),
  isSending: isIndicatorSending(state),
  isEditLoading: isEditLoading(state),
  isIndicatorHaveStructure: isIndicatorHaveStructure(state),
  isDeleting: isDeleting(state),
  indicatorArea: getIndicatorArea(state),
  noExistElements: getNoexistIdsIndicator(state),
});

const mapDispatchToProps = dispatch => ({
  addElement: payload => dispatch(addInicatorElement(payload)),
  createStep: payload => dispatch(createIndicatorStep(payload)),
  deleteStep: payload => dispatch(deleteIndicatorStep(payload)),
  testIndicator: payload => dispatch(testIndicator(payload)),
  saveIndicator: payload => dispatch(saveIndicator(payload)),
  setLastFunctionValidation: payload =>
    dispatch(setLastFunctionIndicatorValidation(payload)),
  editIndicator: payload => dispatch(editIndicator(payload)),
  saveEditIndicator: payload => dispatch(saveEditIndicator(payload)),
  deleteIndicator: payload => dispatch(deleteIndicator(payload)),
  resetSteps: () => dispatch(resetIndicatorDataSet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepsIndicator);
