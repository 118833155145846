import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Paper,
    Grid,
    Typography,
  } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function InfoEmptyFortia(props) {
  const classes = useStyles();
  const { ClaArti,NomArti,ClaFam,Existencia } = props;

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justify="lefth"
        style={{ marginTop: "2px", marginBottom: "2px" }}
      >
        <Grid item xs={3}>
          <Typography variant="subtitle2" align="center">
            {ClaArti}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" align="center">
            {NomArti}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" align="center">
            {ClaFam}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" align="right">
            {Existencia}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
