import {
    VARIABLE_COMMISSIONS
} from '../ActionTypes';

export const VariableCommissions_onOrganization = data => ({
    type: VARIABLE_COMMISSIONS.ORGANIZATION,
    data
});


export const VariableCommissions_onVariable = data => ({
    type: VARIABLE_COMMISSIONS.VARIABLE,
    data
});

export const VariableCommissions_onDataSource = data => ({
    type: VARIABLE_COMMISSIONS.DATA_SOURCE,
    data
});

export const VariableCommissions_onArea = data => ({
    type: VARIABLE_COMMISSIONS.AREA,
    data
});

export const VariableCommissions_onIndicator = data => ({
    type: VARIABLE_COMMISSIONS.INDICATOR,
    data
});

export const VariableCommissions_onCommission = data => ({
    type: VARIABLE_COMMISSIONS.COMMISSION,
    data
});