import { takeLatest, all, put, select, call } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import { isEmpty, indexOf } from 'lodash';
import {
  ADD_CREATEFUNCTION_ELEMENT,
  TEST_CREATEFUNCTION,
  SAVE_CREATEFUNCTION,
  EDIT_CREATEFUNCTION,
  DELETE_CREATEFUNCTION_ELEMENT,
  EDIT_CREATEFUNCTION_ELEMENT,
  SAVE_EDIT_CREATEFUNCTION,
  DELETE_CREATEFUNCTION,
  TEST_ADVACED_CREATEFUNCTION_EXPRESSION,
  SAVE_EDIT_ADVANCED_CREATEFUNCTION,
  SAVE_ADVANCED_CREATEFUNCTION,
} from './constants';
import {
  pushElementCreateFunctionStep,
  setTestCreateFunction,
  setCreateFunctioSending,
  setSaveCreateFunction,
  resetCreateFunctionData,
  rehydrateCreateFunction,
  popElementCreateFunction,
  replaceElementCreateFunction,
  setExpressionCreateFunction,
  setCreateFunctionDeleting,
} from './actions';
import {
  getCreateFunctionSteps,
  getCreateFunctionParameters,
  getCreateFunctionName,
  getCreateFunctionDescription,
  getCreateFunctionExpression,
} from './selectors';
import API from '../../services/api';
import {
  createBase64Expression,
  convertExpressionToBase64,
} from '../../helpers';
import { setNotificationMessage } from '../notification/actions';

export function* addCreateFunctionElement(action) {
  const {
    payload: { step, type, object, name },
  } = action;
  const functionParameters = yield select(getCreateFunctionParameters);
  const indexofFunction = indexOf(functionParameters, object.value);

  const element = {
    step: step,
    object: {
      name: isEmpty(name)
        ? indexofFunction !== -1
          ? indexofFunction
          : object.selected + '_item_' + Date.now()
        : name,
      type: type,
      value: object,
    },
  };

  yield put(pushElementCreateFunctionStep(element));
}

export function* testCreateFunction() {
  const steps = yield select(getCreateFunctionSteps);
  const expression = yield createBase64Expression(steps);
  try {
    const response = yield call(API.compilerCodeScan, {
      expression: expression,
    });

    const { data: evaluation_result } = response;

    if (evaluation_result) {
      yield put(setTestCreateFunction(true));
      yield put(
        setNotificationMessage({
          type: 'success',
          message: `La construcción de tu Función es correcta`,
        }),
      );
      yield put(setExpressionCreateFunction(atob(expression)));
    } else {
      yield put(setTestCreateFunction(false));
      yield put(
        setNotificationMessage({
          type: 'error',
          message: `Verifica la construcción de tu Función`,
        }),
      );
    }
  } catch (error) {
    yield put(setTestCreateFunction(false));
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `Verifica la construcción de tu Función`,
      }),
    );
  }
}

export function* createPayload() {
  const name = yield select(getCreateFunctionName);
  const description = yield select(getCreateFunctionDescription);
  const functionParameters = yield select(getCreateFunctionParameters);
  const steps = yield select(getCreateFunctionSteps);
  const expression = yield createBase64Expression(steps);

  return {
    name: name,
    description: description,
    html_reference: JSON.stringify(steps),
    arguments: functionParameters,
    expression: expression,
  };
}

export function* saveFunction() {
  const name = yield select(getCreateFunctionName);
  const payload = yield createPayload();

  yield put(setCreateFunctioSending(true));
  try {
    yield call(API.createFunction, payload);
    yield put(setSaveCreateFunction(true));
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se guardo con éxito la Función ${name}`,
      }),
    );
    yield put(resetCreateFunctionData());
    yield put('/funciones');
  } catch (error) {
    let errorMessage;
    const errorResponse = error.response;
    const {
      data: { errors },
    } = errorResponse;

    if (typeof errors[name] === 'undefined') {
      errorMessage = 'No se pudo guardar tu Función, intenta con otro nombre';
    } else {
      errorMessage = 'No se pudo guardar tu Función, intenta más tarde';
    }

    yield put(setSaveCreateFunction(false));
    yield put(
      setNotificationMessage({
        type: 'error',
        message: errorMessage,
      }),
    );
  } finally {
    yield put(setCreateFunctioSending(false));
  }
}

export function* editFunction(action) {
  const { payload } = action;
  let steps;

  try {
    const response = yield call(API.getHelperFunction, payload);
    const { data } = response;

    const {
      helper_function: { name, description, html_reference },
      expression,
    } = data;

    try {
      steps = JSON.parse(html_reference);
    } catch (error) {
      steps = [[]];
    }

    yield put(
      rehydrateCreateFunction({
        name,
        description,
        parameters: data.helper_function.arguments,
        steps: steps,
        expression,
      }),
    );
  } catch (error) {
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se pudo cargar la información de tu Función, intenta más tarde`,
      }),
    );
  }
}

export function* deleteFunctionElement(action) {
  const { payload } = action;

  yield put(popElementCreateFunction(payload));
}

export function* editFunctionElement(action) {
  const {
    payload: { payload, editData },
  } = action;

  const functionParameters = yield select(getCreateFunctionParameters);
  const indexofFunction = indexOf(functionParameters, payload.object.value);

  const element = {
    step: payload.step,
    object: {
      name: isEmpty(payload.name)
        ? indexofFunction !== -1
          ? indexofFunction
          : payload.object.selected + '_item_' + Date.now()
        : payload.name,
      type: payload.type,
      value: payload.object,
    },
  };
  yield put(replaceElementCreateFunction({ element, editData }));
}

export function* saveEditFunction(action) {
  const functionHelperId = action.payload;
  const name = yield select(getCreateFunctionName);
  const payload = yield createPayload();

  yield put(setCreateFunctioSending(true));
  try {
    yield call(API.updateFunction, functionHelperId, payload);
    yield put(setSaveCreateFunction(true));
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se edito con éxito la Función ${name}`,
      }),
    );
    yield put(resetCreateFunctionData());
    yield put('/funciones');
  } catch (error) {
    yield put(setSaveCreateFunction(false));
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se pudo editar tu Función, intenta más tarde`,
      }),
    );
  } finally {
    yield put(setCreateFunctioSending(false));
  }
}

export function* deleteFunction(action) {
  const functionHelperId = action.payload;
  const name = yield select(getCreateFunctionName);

  yield put(setCreateFunctionDeleting(true));
  try {
    yield call(API.deleteFunction, functionHelperId);
    yield put(resetCreateFunctionData());
    yield put('/funciones');
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se borro con éxito tu Comisión Variable ${name}`,
      }),
    );
  } catch (error) {
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se puedo eliminar tu Comisión Variable, intenta más tarde`,
      }),
    );
  } finally {
    yield put(setCreateFunctionDeleting(false));
  }
}

export function* testAdvancedExpression() {
  const expression = yield select(getCreateFunctionExpression);
  const expressionB64 = yield convertExpressionToBase64(expression);

  try {
    const response = yield call(API.compilerCodeScan, {
      expression: expressionB64,
    });

    const { data: evaluation_result } = response;
    if (evaluation_result) {
      yield put(setTestCreateFunction(true));
      yield put(
        setNotificationMessage({
          type: 'success',
          message: `La construcción de tu Función es correcta`,
        }),
      );
    } else {
      yield put(setTestCreateFunction(false));
      yield put(
        setNotificationMessage({
          type: 'error',
          message: `Verifica la construcción de tu Función`,
        }),
      );
    }
  } catch (error) {
    yield put(setTestCreateFunction(false));
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `Verifica la construcción de tu Función`,
      }),
    );
  }
}

export function* saveEditAdvancedFunction(action) {
  const functionHelperId = action.payload;
  const name = yield select(getCreateFunctionName);
  const expression = yield select(getCreateFunctionExpression);
  const expressionB64 = yield convertExpressionToBase64(expression);
  let payload = yield createPayload();

  payload.expression = expressionB64;
  payload.html_reference = '-';

  yield put(setCreateFunctioSending(true));
  try {
    yield call(API.updateFunction, functionHelperId, payload);
    yield put(setSaveCreateFunction(true));
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se edito con éxito la Función ${name}`,
      }),
    );
    yield put(resetCreateFunctionData());
    yield put('/funciones');
  } catch (error) {
    yield put(setSaveCreateFunction(false));
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se pudo editar tu Función, intenta más tarde`,
      }),
    );
  } finally {
    yield put(setCreateFunctioSending(false));
  }
}

export function* saveAdvancedFunction() {
  const name = yield select(getCreateFunctionName);
  const expression = yield select(getCreateFunctionExpression);
  const expressionB64 = yield convertExpressionToBase64(expression);
  let payload = yield createPayload();

  payload.expression = expressionB64;
  payload.html_reference = '-';

  yield put(setCreateFunctioSending(true));
  try {
    yield call(API.createFunction, payload);
    yield put(setSaveCreateFunction(true));
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se guardo con éxito la Función ${name}`,
      }),
    );
    yield put(resetCreateFunctionData());
    yield put('/funciones');
  } catch (error) {
    let errorMessage;
    const errorResponse = error.response;
    const {
      data: { errors },
    } = errorResponse;

    if (typeof errors[name] === 'undefined') {
      errorMessage = 'No se pudo guardar tu Función, intenta con otro nombre';
    } else {
      errorMessage = 'No se pudo guardar tu Función, intenta más tarde';
    }

    yield put(setSaveCreateFunction(false));
    yield put(
      setNotificationMessage({
        type: 'error',
        message: errorMessage,
      }),
    );
  } finally {
    yield put(setCreateFunctioSending(false));
  }
}

export default function* createFunctionSaga() {
  yield all([
    takeLatest(ADD_CREATEFUNCTION_ELEMENT, addCreateFunctionElement),
    takeLatest(TEST_CREATEFUNCTION, testCreateFunction),
    takeLatest(SAVE_CREATEFUNCTION, saveFunction),
    takeLatest(EDIT_CREATEFUNCTION, editFunction),
    takeLatest(DELETE_CREATEFUNCTION_ELEMENT, deleteFunctionElement),
    takeLatest(EDIT_CREATEFUNCTION_ELEMENT, editFunctionElement),
    takeLatest(SAVE_EDIT_CREATEFUNCTION, saveEditFunction),
    takeLatest(DELETE_CREATEFUNCTION, deleteFunction),
    takeLatest(TEST_ADVACED_CREATEFUNCTION_EXPRESSION, testAdvancedExpression),
    takeLatest(SAVE_EDIT_ADVANCED_CREATEFUNCTION, saveEditAdvancedFunction),
    takeLatest(SAVE_ADVANCED_CREATEFUNCTION, saveAdvancedFunction),
  ]);
}
