import React from 'react';

export default function StepItemStep(props) {
  let name = '';
  if (props.functionElement === true) {
    name = props.item.value;
  } else {
    name = props.item.name;
  }

  return (
    <div
      className={`step-item step-step`}
      aria-describedby={props.index}
      data-step={props.step}
      data-screen={props.screen}
      data-name={props.elementName}
      data-type={'step'}
      data-function-name={props.functionName}
      data-function-eind={props.functionElementIndex}
      data-element-active-step={props.activeStep}
      onClick={e => props.openPopover(e)}
    >
      <span className={'MuiButton-label'}>{name}</span>
    </div>
  );
}
