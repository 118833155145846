import { connect } from 'react-redux';
import SignOut from './SignOut';
import { UserSignIn_logOut } from '../../redux/actions/UserSignInAction';

const mapDispatchToProps = dispatch => ({
  UserSignIn_logOut: data => {
    dispatch(UserSignIn_logOut(data));
  }
});

const mapStateToProps = state => ({
  user_signin: state.user_signin
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignOut);