import React from 'react';
import { CircularProgress } from '@material-ui/core';

import './styles.scss';

export default function LoadingIndicator() {
  return (
    <div className={'loading-indicator-container'}>
      <CircularProgress className={'loading-indicator'} />
    </div>
  );
}
