import { connect } from 'react-redux';
import Navbar from './Navbar';
import { Navbar_onSystemMenuUpdate, Navbar_onNotFound, Navbar_onProfileMenu, Navbar_onNavigationMenu, Navbar_onNoPermission } from '../redux/actions/NavbarAction';
import { UserSignIn_logOut, UserSignIn_position, UserSignIn_systemRole } from '../redux/actions/UserSignInAction';
import { UserProfile_onSuccess } from '../redux/actions/UserProfileAction';

const mapDispatchToProps = dispatch => ({
  Navbar_onSystemMenuUpdate: data => {
    dispatch(Navbar_onSystemMenuUpdate(data));
  },
  Navbar_onNavigationMenu: data => {
    dispatch(Navbar_onNavigationMenu(data));
  },
  Navbar_onNotFound: data => {
    dispatch(Navbar_onNotFound(data));
  },
  Navbar_onProfileMenu: data => {
    dispatch(Navbar_onProfileMenu(data));
  },
  Navbar_onNoPermission: data => {
    dispatch(Navbar_onNoPermission(data));
  },
  UserSignIn_logOut: data => {
    dispatch(UserSignIn_logOut(data));
  },
  UserProfile_onSuccess: data => {
    dispatch(UserProfile_onSuccess(data));
  },
  UserSignIn_position: data => {
    dispatch(UserSignIn_position(data))
  },
  UserSignIn_systemRole: data => {
    dispatch(UserSignIn_systemRole(data))
  }
});

const mapStateToProps = state => ({
  systemProfileMenuList: state.profileMenuListItems,
  navigationMenuList: state.navigationMenuListItems,
  systemMenuListItems: state.systemMenuList,
  attendant: state.attendant
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);