import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

export class AddPreviousStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  selectStep(step) {
    this.setState({
      ...this.state,
      value: step,
    });
    this.props.setValue(step);
  }

  render() {
    const { value } = this.state;
    const { activeStep } = this.props;
    const items = [];

    for (let index = 0; index < activeStep; index++) {
      items.push(
        <MenuItem key={index} value={'Resultado_Paso_' + (index + 1)}>
          Resultado_Paso_{index + 1}
        </MenuItem>,
      );
    }

    return (
      <div className={'select-wrapper'}>
        <FormControl className={'selector'}>
          <InputLabel>Buscar paso anterior...</InputLabel>
          <Select
            value={value}
            onChange={event => this.selectStep(event.target.value)}
          >
            {items}
          </Select>
        </FormControl>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddPreviousStep);
