import React, { useState, useEffect } from 'react';
import { Slide, Dialog, Toolbar, IconButton, Typography, Container, AppBar, makeStyles, Grid, Box, Paper, Button, LinearProgress, ButtonGroup, TextField, InputAdornment } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import DialpadIcon from '@material-ui/icons/Dialpad';

import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify';
import awsconfig from '../../../../aws-exports';
import { Auth } from 'aws-amplify';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));


export default function EditUserModal(props) {
    const [user, setUser] = useState(props.user)
    const [sending, setSending] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        if (user !== props.user)
            setUser(props.user)
        return function cleanup() {

        };
    }, [
        props.user
    ])

    
    async function signUp() {
        try {
            const userResult = await Auth.signUp({
                username:user.userName,
                password:user.hashPassword,
                attributes: {
                    email:"generic@bamaglobal.com",          // optional
                    phone_number:"+525531343217"
                    // other custom attributes 
                }
            });
            console.log({ userResult });
        } catch (error) {
            console.log('error signing up:', error);
        }
    }

    async function confirmSignUp() { 
        try {
          await Auth.confirmSignUp("centralizacion", "d651055c-ba76-486d-a93e-061babf54ea7");
        } catch (error) {
            console.log('error confirming sign up', error);
        }
    }

    // const createUsercognito = () =>{

    //     async function signUp() {
    //         try {
    //             const userResult = await Auth.signUp({
    //                 username:user.userName,
    //                 password:user.hashPassword,
    //                 attributes: {
    //                     email:"centralizacion@bamaglobal.com",          // optional
                       
    //                     // other custom attributes 
    //                 }
    //             });
    //             console.log({ userResult });
    //         } catch (error) {
    //             console.log('error signing up:', error);
    //         }
    //     }
    // }


    return (
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar color="secondary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              CREAR NUEVO USUARIO EN COGNITO
            </Typography>
          </Toolbar>
        </AppBar>
        {sending && <LinearProgress color="primary" />}
        <Container fixed>
          <Box my={2}>
            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid item xs={12}>
                <TextField
                  type="text"
                  fullWidth
                  disabled={sending}
                  variant="outlined"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      // getCustomer(searchText);
                    }
                  }}
                  onChange={(event) => {
                    setUser({
                      ...user,
                      userName: event.target.value,
                    });
                  }}
                  label={props.translate("Username")}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  type="text"
                  fullWidth
                  disabled={sending}
                  variant="outlined"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      // getCustomer(searchText);
                    }
                  }}
                  onChange={(event) => {
                    setUser({
                      ...user,
                      hashPassword: event.target.value,
                    });
                  }}
                  label={props.translate("Password")}
                />
              </Grid>
              <Grid item xs={5}>
                <Button variant="contained" 
                color="secondary"
                onClick={signUp}
                >
                  Crear usuario
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Dialog>
    );
}