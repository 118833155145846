import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, LinearProgress, Grow, Typography, Zoom, Fab, MenuItem, Menu, ListItemIcon, ListItemText } from '@material-ui/core';

import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';
import NewForecastModal from '../../components/forecast/NewForecastModal';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

function Forecast(props) {
    const firstRender = useRef(true)
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searching, setSearching] = useState(false)
    const [openNewForecast, setOpenNewForecast] = useState(false)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render
            return // skip the code below
        }

        return function cleanup() {

        };
    }, [searching]) // Only re-run the effect if name or email change

    const handleNewForecast = () => {
        handleCloseFloatMenu()
        setOpenNewForecast(true)
        return
    }

    const handleCloseFloatMenu = () => {
        setAnchorEl(null)
        return
    }

    const handleOpenFloatMenu = event => {
        setAnchorEl(event.currentTarget);
        return
    }

    return (
        <Grow in={true}>
            <Box>
                {searching && <LinearProgress color="secondary" />}
                <Container fixed>
                    <Box my={2}>
                        <Typography variant="h5" color="textSecondary">
                            {props.translate("Forecast")}
                        </Typography>
                    </Box>
                </Container>
                <Box>
                    <Zoom
                        in={true}
                        unmountOnExit>
                        <Fab className={classes.fab}
                            onClick={handleOpenFloatMenu}
                            color="secondary">
                            <AddIcon />
                        </Fab>
                    </Zoom>
                    <Menu
                        keepMounted
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseFloatMenu} >
                        <MenuItem onClick={handleNewForecast}>
                            <ListItemIcon>
                                <AddBoxIcon />
                            </ListItemIcon>
                            <ListItemText primary={`${props.translate("Add")} ${props.translate("Forecast")}`} />
                        </MenuItem>
                    </Menu>
                </Box>
                <NewForecastModal
                    {...props}
                    handleCloseModal={event => setOpenNewForecast(false)}
                    open={openNewForecast} />
            </Box>
        </Grow>
    )
}
export default withSnackbar(Forecast);