import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';

import {
    SET_VARIABLE_NAME,
    SET_VARIABLE_ID,
    SET_VARIABLE_ORGANIZATION,
    SET_VARIABLE_DATASOURCE,
    SET_VARIABLE_TAG_NAME,
    SET_VARIABLE_TAG_DATE,
    SET_VARIABLE_TAG_ESTATUS_EDIT,
    RESET_VARIABLE_TAG
} from './constants';

const setName = (state, action) => ({
    ...state,
    name: action.payload,
});

const setOrganization = (state, action) => ({
    ...state,
    organization: action.payload,
});

const setDataSource = (state, action) => ({
    ...state,
    dataSource: action.payload,
}); 

const setEditVariableTag = (state, action) => ({
    ...state,
    edit: action.payload
});

const setVariableId = (state, action) => ({
    ...state,
    id: action.payload
});

const resetVariableTagData = () => INITIAL_STATE;

export const reducer = handleActions(
    {
        [SET_VARIABLE_ID]: setVariableId,
        [SET_VARIABLE_NAME]: setName,
        [SET_VARIABLE_ORGANIZATION]: setOrganization,
        [SET_VARIABLE_DATASOURCE]: setDataSource,  
        [SET_VARIABLE_TAG_ESTATUS_EDIT]: setEditVariableTag,  
        [RESET_VARIABLE_TAG]: resetVariableTagData  
    },
    INITIAL_STATE,
);
  