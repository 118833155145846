import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { NotFoundPage } from '../screens/not-found/notFoundPage';
import Home from '../screens/home/Home';
import LandingPage from '../screens/landing/LandingPage';
import UserSignIn from '../screens/authentication/UserAuthenticationConnected';
import UserSignOut from '../screens/signOut/SignOutConnected';
import UserProfile from '../screens/profile/UserProfileConnected';
import Navbar from './NavbarConnected';
import CustomerRegistration from '../screens/customers/CustomerRegistration';
import SupportMultiLanguaje from '../utils/SupportMultiLanguaje';
import NoticePrivacy from '../screens/legal/tiendasBama/NoticePrivacy';
import TermsAndConditions from '../screens/legal/tiendasBama/TermsAndConditions';
import CustomerIndex from '../screens/customers/CustomerIndex';
import CustomerProfile from '../screens/customers/CustomerProfile';
import CustomerEnrollment from '../screens/customers/CustomerEnrollment';
import CustomerSetting from '../screens/customers/CustomerSetting';
import EmptyContainer from '../screens/xadis/EmptyContainer';
import InfoXadis from '../screens/xadis/infoXadis/infoXadis';
import Xadis from '../screens/xadis/Xadis';
import Forecast from '../screens/forecast/Forecast';
import OrderPruchase from '../screens/forecast/OrderPurchase';
import Settings from '../screens/system/Settings';
import Warehause from '../screens/system/Warehouse';
import Products from '../screens/system/Products';
import Roles from '../screens/system/Roles';
import Chat from '../screens/chat/Chat';
import Support from '../screens/support/Support';
import Users from '../screens/system/Users';
import TemplateAssignment from '../screens/humanResource/templateAssignment'

// Variable Commisions
import VariableCommissions from '../containers/VariableCommissions';
import VariableCommissionsHome from '../containers/VariableCommissionsHome';
import Notification from '../components/Notification';
import ModalSelector from '../containers/ModalSelector';
import InitSearchVariable from '../containers/variables';
import ListVariable from '../containers/Variables/ListVariable';
import ListaValuesVariable from '../containers/Variables/ListaValuesVariable';
import DetailValuesVariable from '../containers/Variables/DetailValuesVariable';
import InitSearchParameter from '../containers/parametros';
import ListParameter from '../containers/Parameters/ListParameter';
import ListValues from '../containers/Parameters/ListValues';
import InitSearchDataSource from '../containers/finformacion';
import ListaDataSource from '../containers/DataSource/ListaDataSource';
import InitIndicator from '../containers/Indicadores/InitIndicator';
import InitSearchIndicator from '../containers/indicadores';
import ListIndicators from '../containers/Indicadores/ListIndicators';
import StepsIndicator from '../containers/Indicadores/StepsIndicator';
import Reportes from '../containers/reportes';
import ListReports from '../containers/Reports/ListReports';
import ReportDetail from '../containers/Reports/ReportDetail';
import InitKPI from '../containers/KPIs/InitKPI';
import ListKPIS from '../containers/KPIs/ListKPIs';
import SearchKPIs from '../containers/KPIs/SearchKPIs';
import SetpsKPI from '../containers/KPIs/SetpsKPI';
import Organizaciones from '../containers/organizaciones';
import InitSearchEmployee from '../containers/empleados';
import ListaEmployee from '../containers/Employee/ListaEmployee';
import Areas from '../containers/areas';
import ListFunctions from '../containers/CreateFunction/ListFunctions';
import InitCreateFunction from '../containers/CreateFunction/InitCreateFunction';
import StepsCreateFunction from '../containers/CreateFunction/StepsCreateFunction';
import Documentacion from '../containers/documentacion';
import WrapContainer from '../containers/WrapContainer';

// Headcount
import Headcount from '../containers/headcount';

//Operation
import ListPrice from '../screens/operation/listprice';

//WorkPlan
// import WorkPlan from '../screens/workplan/listworkplan';
import WorkPlan from '../screens/workplan/listWorkPlans';

//Billing
import Billing from '../screens/BillingPortal/Billing';

// comandos
import Commands from '../screens/commands/commands'

// collaboratorSearch
import CollaboratorSearch from '../screens/commands/CollaboratorSearch'

class AppRouter extends SupportMultiLanguaje {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box>
                <Navbar {...this.props} />
                <ModalSelector {...this.props} />
                <Routes>
                    {/** Landing Pages */}
                    <Route path="/" exact element={<LandingPage translate={this.translate} showMessage={this.showMessage} />} />
                    <Route path="/customerRegistration" element={<CustomerRegistration translate={this.translate} showMessage={this.showMessage} />} />

                    {/** Billing */}
                    <Route path="/cfdi" element={<Billing translate={this.translate} showMessage={this.showMessage} />} />

                    {/** User */}
                    <Route path="/userSignIn" element={<UserSignIn {...this.props} />} />
                    <Route path="/userSignOut" element={<UserSignOut {...this.props} />} />

                    {/** Legals */}
                    <Route path="/noticePrivacy" element={<NoticePrivacy />} />
                    <Route path="/termsAndConditions" element={<TermsAndConditions />} />

                    {/** Error */}
                    <Route path="/not-found" element={<NotFoundPage />} />
                    <Route path="*" element={<NotFoundPage />} />

                    {/** Cloud Session */}
                    <Route path="prometheus">
                        <Route path="/" element={<Home {...this.props} />} />

                        {/** Profile verificar con omar*/}
                        <Route path="system">
                            <Route path="settings" element={<Settings {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="users" element={<Users {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                        </Route>

                        {/** Profile */}
                        <Route path="user">
                            <Route path=":id" element={<UserProfile />} />
                            <Route path="chat" element={<Chat />} />
                            <Route path="support" element={<Support />} />
                        </Route>

                        {/** Customer */}
                        <Route path="customer">
                            <Route path="/" element={<CustomerIndex {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path=":id" element={<CustomerProfile {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="enrollment" element={<CustomerEnrollment {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="setting" element={<CustomerSetting {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                        </Route>


                        {/** Xadis */}
                        <Route path="xadis">
                            <Route path="/" element={<Xadis {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="emptyContainer" element={<EmptyContainer {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="infoXadis" element={<InfoXadis {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                        </Route>

                        {/** Settings */}
                        <Route path="settings">
                            <Route path="/" element={<Settings {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="users" element={<Users {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="warehouse" element={<Warehause {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="products" element={<Products {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="roles" element={<Roles {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                        </Route>

                        {/** Forecast */}
                        <Route path="purchases">
                            <Route path="forecast" element={<OrderPruchase {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                        </Route>

                        {/** Operation */}
                        <Route path="operation">
                            <Route path="price" element={<ListPrice {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                        </Route>

                        {/** Workplan */}
                        <Route path="workplan">
                            {<Route path="/" element={<WorkPlan {...this.props} translate={this.translate} showMessage={this.showMessage} />} />}
                        </Route>

                        {/** Billing */}
                        <Route path="BillingPortal">
                            <Route path="BillingPortal" element={<Billing {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                        </Route>

                        {/** Headcount */}
                        <Route path="humanResources">
                            <Route path="headcount" element={<Headcount {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="templateAssignment" element={<TemplateAssignment {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                        </Route>

                        {/** Support */}
                        < Route path="support">
                            <Route path="remoteCommands" element={<Commands {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                            <Route path="collaboratorSearch" element={<CollaboratorSearch {...this.props} translate={this.translate} showMessage={this.showMessage} />} />
                        </Route>

                        {/** Variable Commissions */}
                        <Route path="variableCommissions">
                            {/*this.state && this.state.path !== '/' && <LeftBar {...this.props} />*/}
                            <Route path="/" element={<VariableCommissionsHome showMessage={this.showMessage} />} />
                            <Route path="empresas" exact={true} element={<Organizaciones {...this.props} showMessage={this.showMessage} />} />
                            <Route path="areas" exact={true} element={<Areas {...this.props} showMessage={this.showMessage} />} />
                            <Route
                                path="empleados"
                            >
                                <Route
                                    path="/"
                                    element={<InitSearchEmployee {...this.props} showMessage={this.showMessage} />}
                                />
                                <Route
                                    path="lista"
                                >
                                    <Route
                                        path=":areaId"
                                    >
                                        <Route
                                            path=":areaName"
                                            exact={true}
                                            element={<WrapContainer><ListaEmployee {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                        ></Route>
                                    </Route>
                                </Route>
                            </Route>
                            <Route
                                path="variables"
                            >
                                <Route
                                    path="/"
                                    element={<InitSearchVariable {...this.props} showMessage={this.showMessage}  />}
                                />
                                <Route
                                    path="lista"
                                >
                                    <Route
                                        path=":dataSourceId"
                                        exact={true}
                                        element={<WrapContainer><ListVariable {...this.props} showMessage={this.showMessage}  /></WrapContainer>}
                                    ></Route>
                                </Route>
                                <Route
                                    path="detalle"
                                >
                                    <Route
                                        path=":variableId"
                                    >
                                        <Route
                                            path=":variableName"
                                            exact={true}
                                            element={<WrapContainer><ListaValuesVariable {...this.props} showMessage={this.showMessage}  /></WrapContainer>}
                                        />
                                    </Route>
                                </Route>
                                <Route
                                    path="detalle-valor-tag"
                                    exact={true}
                                    element={<WrapContainer><DetailValuesVariable {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                >
                                    <Route
                                        path=":variableDetail"
                                        exact={true}
                                        element={<WrapContainer><DetailValuesVariable {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="parametros"
                            >
                                <Route
                                    path="/"
                                    element={<InitSearchParameter {...this.props} showMessage={this.showMessage} />}
                                />
                                <Route
                                    path="lista"
                                >
                                    <Route
                                        path=":areaId"
                                        exact={true}
                                        element={<WrapContainer><ListParameter {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                    />
                                </Route>
                                <Route
                                    path="detalle"
                                >
                                    <Route
                                        path=":parameterId"
                                    >
                                        <Route
                                            path=":parameterName"
                                            exact={true}
                                            element={<WrapContainer><ListValues {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                        />
                                    </Route>
                                </Route>
                            </Route>
                            <Route
                                path="fuentes-de-informacion"
                            >
                                <Route
                                    path="/"
                                    element={<InitSearchDataSource {...this.props} showMessage={this.showMessage} />}
                                />
                                <Route
                                    path="lista"
                                >
                                    <Route
                                        path=":organizationId"
                                        exact={true}
                                        element={<WrapContainer><ListaDataSource {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="indicadores"
                            >
                                <Route
                                    path="/"
                                    element={<InitSearchIndicator {...this.props} showMessage={this.showMessage} />}
                                />
                                <Route
                                    path="lista"
                                >
                                    <Route
                                        path=":areaId"
                                        exact={true}
                                        element={<WrapContainer><ListIndicators {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                    />
                                </Route>
                                <Route
                                    path="detalle"
                                >
                                    <Route
                                        path=":indicatorId"
                                    >
                                        <Route
                                            path=":indicatorName"
                                            exact={true}
                                            element={<WrapContainer><StepsIndicator {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                        />
                                    </Route>
                                </Route>
                                <Route
                                    path="crear"
                                >
                                    <Route
                                        path="/"
                                        element={<InitIndicator {...this.props}  showMessage={this.showMessage} />}
                                    />
                                    <Route
                                        path="pasos"
                                        exact={true}
                                        element={<StepsIndicator {...this.props} showMessage={this.showMessage} />}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="comisiones-variables"
                            >
                                <Route
                                    path="/"
                                    element={<SearchKPIs {...this.props}  showMessage={this.showMessage} />}
                                />
                                <Route
                                    path="lista"
                                >
                                    <Route
                                        path=":areaId"
                                        exact={true}
                                        element={<WrapContainer><ListKPIS {...this.props} showMessage={this.showMessage}  /></WrapContainer>}
                                    />
                                </Route>
                                <Route
                                    path="crear"
                                >
                                    <Route
                                        path="/"
                                        element={<InitKPI {...this.props} showMessage={this.showMessage}  />}
                                    />
                                    <Route
                                        path="pasos"
                                        exact={true}
                                        element={<SetpsKPI {...this.props} showMessage={this.showMessage}  />}
                                    />
                                </Route>
                                <Route
                                    path="detalle" 
                                >
                                    <Route
                                        path=":comisionId"
                                        exact={true}
                                        element={<WrapContainer><SetpsKPI {...this.props} showMessage={this.showMessage}  /></WrapContainer>}
                                    />
                                </Route>
                            </Route>
                            <Route path="reportes"
                            >
                                <Route
                                    path="/"
                                    element={<Reportes {...this.props} showMessage={this.showMessage} />}
                                />
                                <Route
                                    path=":organizationId"
                                    exact={true}
                                    element={<WrapContainer><ListReports {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                />
                                <Route
                                    path=":employeeId"
                                    exact={true}
                                    element={<WrapContainer><ListReports {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                />
                                <Route
                                    path="detalle"
                                >
                                    <Route
                                        path=":reportId"
                                        exact={true}
                                        element={<WrapContainer><ReportDetail {...this.props} showMessage={this.showMessage} /></WrapContainer>}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="funciones"
                            >
                                <Route
                                    path="/"
                                    element={<ListFunctions {...this.props} />}
                                />

                                <Route
                                    path="detalle"
                                >
                                    <Route
                                        path=":functionId"
                                        exact={true}
                                        element={<WrapContainer><StepsCreateFunction {...this.props} /></WrapContainer>}
                                    />
                                </Route>
                                <Route
                                    path="crear"
                                    exact={true}
                                    element={<InitCreateFunction {...this.props} />}
                                >
                                    <Route
                                        path="pasos"
                                        exact={true}
                                        element={<StepsCreateFunction {...this.props} />}
                                    />
                                </Route>
                            </Route>
                            <Route path="documentacion" exact={true}>
                                <Documentacion />
                            </Route>
                        </Route>
                        {/*</VariableCommissions>*/}
                    </Route>
                </Routes>
            </Box >
        );
    }
}

export default AppRouter;