import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  isLoadingVariables,
  getVariables,
  isErrorGetVariables,
} from '../../../../../../store/variables/selectors';
import { fetchVariables } from '../../../../../../store/variables/actions';

export class AddVariable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      variable: '',
      tag: '',
    };
  }

  selectDatasource(datasource) {
    if (datasource) {
      this.setState({
        ...this.state,
        datasource: datasource.id,
      });
      this.props.fetchVariables(datasource.id);
    } else {
      this.setState({
        ...this.state,
        datasource: '',
      });
    }
  }

  selectVariable(variable) {
    if (variable) {
      this.setState({
        ...this.state,
        value: variable,
        variable: variable.id,
        tag: '',
      });
    } else {
      this.setState({
        ...this.state,
        value: '',
        variable: '',
        tag: '',
      });
    }
    this.props.setValue('');
  }

  render() {
    const {
      datasources,
      loadingVariables,
      variables,
      errorVariables,
    } = this.props;
    const { value, variable, datasource } = this.state;

    return (
      <div className={'select-wrapper'}>
        {datasources.length > 0 && (
          <div className={'select-wrapper'}>
            <Autocomplete
              className={'selector'}
              id="combo-box-demo"
              options={datasources}
              getOptionLabel={data => data.name}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Fuente de Información"
                  variant="outlined"
                  className={'selector'}
                />
              )}
              onChange={(event, newValue) => this.selectDatasource(newValue)}
            />
          </div>
        )}
        {datasource !== '' && (
          <div>
            {loadingVariables ? (
              <CircularProgress className={'loading-indicator'} />
            ) : errorVariables === true ? (
              <span className={'error-txt'}>
                Error: No se encotraron Fuentes de información
              </span>
            ) : (
              <div>
                {variables.length > 0 && (
                  <div className={'select-wrapper'}>
                    <Autocomplete
                      className={'selector'}
                      id="combo-box-demo"
                      options={variables}
                      getOptionLabel={data => data.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Variables"
                          variant="outlined"
                          className={'selector'}
                        />
                      )}
                      onChange={(event, newValue) =>
                        this.selectVariable(newValue)
                      }
                    />
                  </div>
                )}
                {variable && (
                  <div className={'select-wrapper'}>
                    <Autocomplete
                      className={'selector'}
                      id="combo-box-demo"
                      options={value.tags}
                      getOptionLabel={data => data}
                      noOptionsText={'Esta variable no cuenta con tags'}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Tags"
                          variant="outlined"
                          className={'selector'}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          this.props.setValue({ id: variable, tag: newValue });
                          this.setState({
                            ...this.state,
                            tag: newValue,
                          });
                        } else {
                          this.props.setValue('');
                          this.setState({
                            ...this.state,
                            tag: '',
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  /** GET VARIABLES**/
  loadingVariables: isLoadingVariables(state),
  variables: getVariables(state),
  errorVariables: isErrorGetVariables(state),
});

const mapDispatchToProps = dispatch => ({
  fetchVariables: payload => dispatch(fetchVariables(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVariable);
