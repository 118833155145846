import { VARIABLE_COMMISSIONS } from '../ActionTypes.js'

const defaultState = {
};

export default function VariableCommissionsReducer(state = defaultState, action) {
  switch (action.type) {
    case VARIABLE_COMMISSIONS.ORGANIZATION:
      return {
        ...state,
        organization: action.data
      };
    case VARIABLE_COMMISSIONS.VARIABLE:
      return {
        ...state,
        variable: action.data
      };
    case VARIABLE_COMMISSIONS.DATA_SOURCE:
      return {
        ...state,
        data_source: action.data
      };
    case VARIABLE_COMMISSIONS.AREA:
      return {
        ...state,
        area: action.data
      };
    case VARIABLE_COMMISSIONS.INDICATOR:
      return {
        ...state,
        indicator: action.data
      };
    case VARIABLE_COMMISSIONS.COMMISSION:
      return {
        ...state,
        commission: action.data
      };
    default:
      return state;
  }
}