import React, { Component } from 'react';
import { Button } from '@material-ui/core';

import AddElement from '../../StepsStack/StepItem/AddElement';
import AddOperator from '../../StepsStack/StepItem/AddOperator';
import StepItemElement from '../StepItemElement';
import StepItemStep from '../StepItemStep';

export default class StepItemFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalElement: false,
      openModalOperator: false,
    };
  }

  showModalElement(visible) {
    const {
      item: { value, functionArguments },
      isValidFunction,
    } = this.props;

    const validfunction =
      value.value.arguments.length === functionArguments.length;

    this.setState({
      ...this.state,
      openModalElement: visible,
    });

    isValidFunction(validfunction);
  }

  showModalOperator(visible) {
    this.setState({
      ...this.state,
      openModalOperator: visible,
    });
  }

  render() {
    const {
      item: { name, value, functionArguments },
      elementName,
      index,
      step,
      screen,
      stepPosition,
      activeStep,
      openPopover,
      isReport,
      isLastElement,
      isDeleted,
      noExistElements,
    } = this.props;
    const { openModalElement, openModalOperator } = this.state;

    const validfunction =
      value.value.arguments.length === functionArguments.length;

    return (
      <div className={`step-function`}>
        <div
          className={`step-item step-element ${isDeleted && 'step-deleted'}`}
          aria-describedby={index}
          data-step={step}
          data-screen={screen}
          data-name={elementName}
          data-type={'function'}
          onClick={e => openPopover(e)}
        >
          <div
            className={'MuiButton-label'}
            dangerouslySetInnerHTML={{ __html: 'función:' + value.value.name }}
          />
        </div>
        <div className={`step-item step-operator`}>
          <span className={'MuiButton-label'}>(</span>
        </div>
        <div className={'step-functions-arguments'}>
          {value.value.arguments.map((arg, index) => (
            <div key={index} className={'step-functions-arguments-wrapper'}>
              {typeof functionArguments[index] === 'undefined' ? (
                <div className={'function-element-wrapper'}>
                  <Button
                    key={index}
                    className={'step-item dashed-button'}
                    disabled={index === functionArguments.length ? false : true}
                    onClick={() => this.showModalElement(true)}
                  >
                    <span className={'title'}>+ Elemento</span>
                    <span className={'small-txt'}>{arg}</span>
                  </Button>
                </div>
              ) : functionArguments[index].type === 'step' ? (
                <div className={'function-element-wrapper'}>
                  <StepItemStep
                    item={functionArguments[index].value}
                    functionElement={true}
                    functionName={name}
                    functionElementIndex={index}
                    index={this.props.index}
                    step={step}
                    screen={screen}
                    activeStep={activeStep}
                    elementName={functionArguments[index].name}
                    openPopover={e => openPopover(e)}
                  />
                </div>
              ) : (
                <div className={'function-element-wrapper'}>
                  <StepItemElement
                    item={functionArguments[index].value}
                    functionElement={true}
                    functionName={name}
                    functionElementIndex={index}
                    index={this.props.index}
                    step={step}
                    screen={screen}
                    elementName={functionArguments[index].name}
                    openPopover={e => openPopover(e)}
                    isDeleted={
                      typeof noExistElements !== 'undefined'
                        ? noExistElements.indexOf(
                            functionArguments[index].value.value.id,
                          ) !== -1
                        : null
                    }
                  />
                </div>
              )}
              {index !== value.value.arguments.length - 1 && (
                <span className={'function-comma'}>,</span>
              )}
            </div>
          ))}
        </div>
        <div className={`step-item step-operator`}>
          <span className={'MuiButton-label'}>)</span>
        </div>
        {isLastElement && (
          <div className={'next-element'}>
            {isReport !== true && (
              <Button
                className={'step-item dashed-button'}
                disabled={validfunction ? false : true}
                onClick={() => this.showModalOperator(true)}
              >
                + Operador
              </Button>
            )}
          </div>
        )}
        <AddElement
          open={openModalElement}
          onClose={() => this.showModalElement(false)}
          isFunction={{ compiler_tag: name, stepPosition: stepPosition }}
          step={step}
          showFunctions={validfunction ? true : false}
          showSteps={true}
          type={screen}
          activeStep={activeStep}
        />
        <AddOperator
          open={openModalOperator}
          onClose={() => this.showModalOperator(false)}
          savedCallback={payload =>
            this.setState({
              ...this.state,
              openModalOperator: false,
            })
          }
          step={step}
          type={screen}
        />
      </div>
    );
  }
}
