import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getParametersState = state => get(state, 'parameters');

export const isLoadingParameters = createSelector(
  [getParametersState],
  parameters => get(parameters, 'loading'),
);

export const getParameters = createSelector([getParametersState], parameters =>
  get(parameters, 'data'),
);

export const isErrorGetParameters = createSelector(
  [getParametersState],
  parameters => get(parameters, 'error'),
);
