import React, { useState } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';

import AddElement from './AddElement';
import AddOperator from './AddOperator';

import StepItemOperator from '../../StepItem/StepItemOperator';
import StepItemElement from '../../StepItem/StepItemElement';
import StepItemStep from '../../StepItem/StepItemStep';
import StepItemFunction from '../../StepItem/StepItemFunction';
import StepItemEdit from '../../StepItem/StepItemEdit';

import './styles.scss';

export default function StepItem(props) {
  const [openElementModal, setOpenElementModal] = useState(false);
  const [openOperatorModal, setOpenOperatorModal] = useState(false);
  const [editOperatorData, setEditOperatorData] = useState(null);
  const [editElementData, setEditElementData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenElementModal = payload => {
    setOpenElementModal(true);
    setEditElementData(payload);
  };

  const handleCloseElementModal = () => {
    setOpenElementModal(false);
  };

  const handleOpenOperatorModal = payload => {
    setOpenOperatorModal(true);
    setEditOperatorData(payload);
  };

  const handleCloseOperatorModal = () => {
    setOpenOperatorModal(false);
  };

  const openPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={'step-item-wrapper'}>
      <Typography variant="h5">Paso {props.step + 1} :</Typography>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={props.report ? 12 : 9}
          className={'step-container'}
        >
          {props.elements.map((element, index) => (
            <div key={index} className={`item-container`}>
              {element.type === 'element' &&
                (element.value.selected === 'function' ? (
                  <StepItemFunction
                    index={index}
                    item={element}
                    step={props.step}
                    screen={props.type}
                    stepPosition={index}
                    elementName={element.name}
                    activeStep={props.activeStep}
                    isValidFunction={payload =>
                      props.isLastFunctionValid(payload)
                    }
                    openPopover={e => props.report !== true && openPopover(e)}
                    isReport={props.report}
                    isLastElement={props.elements.length === index + 1}
                    noExistElements={props.noExistElements}
                    isDeleted={
                      typeof props.noExistElements !== 'undefined'
                        ? props.noExistElements.indexOf(
                            element.value.value.id,
                          ) !== -1
                        : null
                    }
                  />
                ) : (
                  <StepItemElement
                    index={index}
                    step={props.step}
                    screen={props.type}
                    item={element.value}
                    elementName={element.name}
                    openPopover={e => props.report !== true && openPopover(e)}
                    isDeleted={
                      typeof props.noExistElements !== 'undefined'
                        ? props.noExistElements.indexOf(
                            element.value.value.id,
                          ) !== -1
                        : null
                    }
                  />
                ))}
              {element.type === 'operator' && (
                <StepItemOperator
                  index={index}
                  step={props.step}
                  screen={props.type}
                  item={element.value}
                  elementName={element.name}
                  openPopover={e => props.report !== true && openPopover(e)}
                />
              )}
              {element.type === 'step' && (
                <StepItemStep
                  index={index}
                  item={element}
                  step={props.step}
                  screen={props.type}
                  elementName={element.name}
                  activeStep={props.activeStep}
                  openPopover={e => props.report !== true && openPopover(e)}
                />
              )}
              <StepItemEdit
                index={index}
                open={open}
                anchorEl={anchorEl}
                closePopover={closePopover}
                editOperator={payload => handleOpenOperatorModal(payload)}
                editElement={payload => handleOpenElementModal(payload)}
              />
            </div>
          ))}
          <div className={'next-element'}>
            {props.nextItem === 'element' && (
              <Button
                className={'step-item dashed-button'}
                disabled={false}
                onClick={() => handleOpenElementModal()}
              >
                + Elemento
              </Button>
            )}
            {props.nextItem === 'operator' && (
              <Button
                className={'step-item dashed-button'}
                disabled={false}
                onClick={() => handleOpenOperatorModal()}
              >
                + Operador
              </Button>
            )}
          </div>
        </Grid>
        {props.report !== true && (
          <Grid item xs={12} md={3} className={'result-step'}>
            {props.step === 0 && (
              <span className={'result-step-title'}>
                Resultados de pasos disponibles
              </span>
            )}
            <Button
              className={'step-item dashed-button'}
              disabled={
                props.step === props.activeStep
                  ? true
                  : props.lastStepItem === 'element'
                  ? true
                  : false
              }
              onClick={() =>
                props.addElement({
                  step: props.activeStep,
                  type: 'step',
                  name: 'Resultado_Paso_' + (props.step + 1),
                  object: {
                    selected: 'step',
                  },
                })
              }
            >
              Resultado_Paso_{props.step + 1}
            </Button>
          </Grid>
        )}
      </Grid>
      <AddElement
        open={openElementModal}
        onClose={handleCloseElementModal}
        step={props.step}
        type={props.type}
        editData={editElementData}
      />
      <AddOperator
        open={openOperatorModal}
        onClose={handleCloseOperatorModal}
        step={props.step}
        type={props.type}
        editData={editOperatorData}
      />
      {props.isLastStep && (
        <div className={'steps-manager'}>
          <Button
            className={'btn'}
            disabled={
              props.nextItem === 'element' || props.elements.length < 3
                ? true
                : false
            }
            onClick={() => props.createStep()}
          >
            Agregar otro paso
          </Button>
          {/*props.step > 0 && (
            <Button
              className={'btn delete-step'}
              onClick={() => props.deleteStep()}
            >
              Eliminar paso
            </Button>
          )*/}
        </div>
      )}
    </div>
  );
}
