import React from 'react';

import MenuButton from './MenuButton/MenuButton';
import Notifications from './Notifications/Notifications';
import ProfileMenu from './ProfileMenu/ProfileMenu';

import Grid from '@material-ui/core/Grid';

function CustomNavbar(props) {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={1}>
        <MenuButton {...props} />
      </Grid>
      <Grid item xs={8}>
        <Notifications {...props} />
      </Grid>
      <Grid item xs={3}>
        <ProfileMenu {...props} />
      </Grid>
    </Grid>
  )
}

export default CustomNavbar;