import { createAction } from 'redux-actions';
import {
  OPEN_MODALSELECTOR,
  SET_MODALSELECTOR_DATA,
  SET_MODALSELECTOR_SHOW,
} from './constants';

export const openModalselector = createAction(OPEN_MODALSELECTOR);
export const setModalselectorData = createAction(SET_MODALSELECTOR_DATA);
export const setModalselectorShow = createAction(SET_MODALSELECTOR_SHOW);
