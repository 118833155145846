import { createAction } from 'redux-actions';
import {
  FETCH_FUNCTIONS,
  SET_FUNCTIONS,
  SET_FUNCTIONS_ERROR,
} from './constants';

export const fetchFunctions = createAction(FETCH_FUNCTIONS);
export const setFunctions = createAction(SET_FUNCTIONS);
export const setFunctionsError = createAction(SET_FUNCTIONS_ERROR);
