import React from "react";
import SessionControl, { SNACKBAR_SUCCESS, SNACKBAR_WARNING, SNACKBAR_ERROR, SNACKBAR_INFO } from '../../utils/SessionControl';
import LoginForm from '../../components/authentication/LoginForm';
import ForgotPasswordForm from '../../components/authentication/ForgotPasswordForm';
import RegisterForm from '../../components/authentication/RegisterForm';
import Loader from '../../components/loader/Loader';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Auth } from 'aws-amplify';

import { withSnackbar } from 'notistack';
import { Paper, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import NewPasswordForm from "../../components/authentication/NewPasswordForm";

const PasswordResetRequiredException = "PasswordResetRequiredException";
const UserNotFoundException = "UserNotFoundException";
const UserNotConfirmedException = "UserNotConfirmedException";
const NotAuthorizedException = "NotAuthorizedException";
const AttemptLimitException = "AttemptLimitException";
const InvalidParameterException = "InvalidParameterException";

const SIX_HOURS_IN_MILLISECONDS = 21600000;

class UserAuthentication extends SessionControl {
    constructor(props) {
        super(props);
        this.state = {
            isSignIn: true,
            isSignUp: false,
            isSignUpRequested: false,
            isForgotPassword: false,
            isForgotPasswordRequested: false,

            isSigninInProgress: false,
            isSignUpInProgress: false,
            isSignUpRequestedInProgress: false,
            isForgotPasswordProgress: false,
            isForgotPasswordRequestedProgress: false,

            isNewPasswordRequired: false,

            checkingSignedInStatus: false,

            signInAttempts: 0,

            loaderMsg: this.translate('Loading'),
        }

        this.image = require('../../assets/images/watermark.png');
        this.loader = require('../../assets/loaders/ajax-loader.gif');
        this.defaultLogo = require('../../assets/images/watermark_color.png');

        this.signIn = this.signIn.bind(this);
        this.signUp = this.signUp.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    componentDidMount() {
        this.isUserSignedIn();
    }
    componentDidUpdate(prevProps) {
        console.log(prevProps)
    }

    isUserSignedIn = async () => {
        this.setState({
            checkingSignedInStatus: true,
            loaderMsg: this.translate('Validating User')
        });

        // Get data user from Secure Store
        var secureStore = this.getSecureStore();
        console.log("secureStore.UserSignInReducer", secureStore);

        if (typeof secureStore.UserSignInReducer !== 'undefined'
            && typeof secureStore.UserSignInReducer.signInFailedReason !== 'undefined'
            && typeof secureStore.UserSignInReducer.signInFailedReason.data !== 'undefined') {
            this.handleSingInError(secureStore.UserSignInReducer.signInFailedReason.data, { username: '', password: '' });
        } else {

            Auth.currentAuthenticatedUser({
                bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(user => {
                console.log("isUserSignedIn", secureStore, user);
                this.showMessage(this.translate("Greetings") + " " + ("attributes" in secureStore.UserSignInReducer.userSignIn && "custom:login_name" in secureStore.UserSignInReducer.userSignIn.attributes ? secureStore.UserSignInReducer.userSignIn.attributes['custom:login_name'] : secureStore.UserSignInReducer.userSignIn.username), SNACKBAR_SUCCESS, 5000);

                setTimeout(() => this.go('prometheus/'), 1000);
            })
                .catch(err => {
                    console.log('Auth.currentAuthenticatedUser', err);

                    this.showMessage(this.translate(err), SNACKBAR_ERROR, 5000)

                    // validate secureStore
                    try {
                        if (typeof secureStore.UserSignInReducer.userSignIn !== 'undefined'
                            && typeof secureStore.UserSignInReducer.userSignIn.username !== 'undefined'
                            && typeof secureStore.UserSignInReducer.userSignIn.password !== 'undefined'
                            && typeof secureStore.UserSignInReducer.userSignIn.time <= SIX_HOURS_IN_MILLISECONDS) {
                            // Silent Login
                            this.signIn(secureStore.UserSignInReducer.userSignIn);
                        }
                    } catch (err) { }
                }).finally(this.setState({ checkingSignedInStatus: false }));
        }
    };

    signOut = async () => {
        // Keyboard.dismiss();
        super.signOut();
        this.props.UserSignIn_logOut({});
    };

    newPassword = async (user, newPassword, optional) => {
        this.signIn({
            username: user.username,
            password: user.password,
            newPassword: newPassword,
            optional: optional
        })
    }

    forgotPasswordRequest = async (user) => {
        // Keyboard.dismiss();
        this.setState({
            isForgotPasswordProgress: true,
            signInAttempts: 0,
            isForgotPasswordRequested: false,
            loaderMsg: this.translate('Reset Password')
        });

        Auth.forgotPassword(user.username.trim())
            .then(data => this.showMessage(this.translate("You will receive a Code via SMS at") + " " + data.CodeDeliveryDetails.Destination, SNACKBAR_SUCCESS, 5000))
            .catch(err => {
                console.log("Auth.forgotPassword.Error", err);
                this.showMessage(this.translate(err.message), SNACKBAR_ERROR, 5000);
                this.handleSingInError(err, user);
            }).finally(setTimeout(() => this.setState({ isForgotPasswordProgress: false }), 1000));
    }

    forgotPassword = async (user) => {
        // Keyboard.dismiss();
        this.setState({
            isForgotPasswordProgress: true,
            loaderMsg: this.translate('Reseting password')
        });

        Auth.forgotPasswordSubmit(user.username, user.code, user.password)
            .then(data => {
                console.log("forgotPasswordSubmit", data);
                this.props.UserSignIn_logOut({});

                this.setState({ user: user });
                this.handleGo(false, true, false);

                this.showMessage(this.translate("Password Reseted Succesfully"), SNACKBAR_SUCCESS, 5000);
            })
            .catch(err => {
                console.log("Auth.forgotPasswordSubmit.Error", err);
                this.showMessage(this.translate(err.message), SNACKBAR_ERROR, 5000);
                this.handleSingInError(err, user);
            }).finally(setTimeout(() => this.setState({ isForgotPasswordProgress: false }), 1000));
    }

    signUp = async (user) => {
        console.log(user);
        this.setState({
            isSignUpInProgress: true,
            loaderMsg: this.translate('Creating a New User')
        });

        var _signUp = {
            username: user.username.trim(),
            password: user.password.trim(),
            attributes: {
                phone_number: user.phonenumber.trim(),
            }
        }

        Auth.signUp(_signUp)
            .then(data => {
                console.log("Auth.signUp", data);
                this.showMessage(this.translate("User created successfully"), SNACKBAR_SUCCESS, 5000)
                this.showMessage(this.translate("You will receive a Code via SMS at") + " " + data.codeDeliveryDetails.Destination, SNACKBAR_INFO, 5000);
            })
            .catch(err => {
                console.log("Auth.signUp.Error", err);
                this.showMessage(this.translate(err.message ? err.message : (err.log ? err.log : err)), SNACKBAR_ERROR, 5000);
                this.setState({ isSignUpRequested: false });
                this.handleSingInError(err, user);
            })
            .finally(setTimeout(() => this.setState({ isSignUpInProgress: false }), 1000));
    }

    confirmSignUp = async (user) => {
        this.setState({
            isSignUpRequestedInProgress: true,
            loaderMsg: this.translate('Confirmating new User')
        });

        // After retrieving the confirmation code from the user
        Auth.confirmSignUp(user.username, user.code, {
            // Optional. Force user confirmation irrespective of existing alias. By default set to True.
            forceAliasCreation: true
        }).then(data => {
            console.log(data);
            this.showMessage(this.translate("User confirmed"), SNACKBAR_SUCCESS, 5000);
            this.handleGo(false, true, false);
        })
            .catch(err => {
                console.log("Auth.confirmSignUp.Error", err);
                this.showMessage(this.translate(err.message ? err.message : (err.log ? err.log : err)), SNACKBAR_ERROR, 5000);
                this.handleSingInError(err, user);
            })
            .finally(setTimeout(() => this.setState({ isSignUpRequestedInProgress: false }), 1000));
    }

    resendSignUp = async (user) => {
        this.setState({
            isSignUpRequestedInProgress: true,
            loaderMsg: this.translate("Resending SignUp code")
        });

        Auth.resendSignUp(user.username)
            .then(() => this.showMessage(this.translate("Code resent successfully"), SNACKBAR_INFO, 5000))
            .catch(err => {
                console.log("Auth.resendSignUp.Error", err);
                this.showMessage(this.translate(err.message ? err.message : (err.log ? err.log : err)), SNACKBAR_ERROR, 5000);
                this.handleSingInError(err, user);
            })
            .finally(setTimeout(() => this.setState({ isSignUpRequestedInProgress: false }), 1000));
    }

    signIn = async (_user) => {
        // Keyboard.dismiss();
        this.setState({
            isSigninInProgress: true,
            loaderMsg: this.translate('Validating User')
        });

        console.log("Auth.signIn", _user);

        Auth.signIn(_user.username.trim(), _user.password.trim().padStart(6, "0"))
            .then(user => {
                // console.log("Auth.signIn", user);
                if (user.challengeName === 'SMS_MFA' ||
                    user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    // You need to get the code from the UI inputs
                    // and then trigger the following function with a button click
                    const code = 'n0m3l0s3'; // getCodeFromUserInput();
                    // If MFA is enabled, sign-in should be confirmed with the confirmation code
                    Auth.confirmSignIn(
                        user,   // Return object from Auth.signIn()
                        code,   // Confirmation code  
                        user.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
                    ).then(loggedUser => console.log("loggedUser", loggedUser));
                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                    console.log('NEW_PASSWORD_REQUIRED', requiredAttributes);
                    this.showMessage(this.translate(user.challengeName), SNACKBAR_WARNING, 5000)
                    // You need to get the new password and required attributes from the UI inputs
                    // and then trigger the following function with a button click
                    // For example, the email and phone_number are required attributes
                    // You need to get the new password and required attributes from the UI inputs
                    // and then trigger the following function with a button click
                    // For example, the email and phone_number are required attributes
                    // const { username, email, phone_number } = getInfoFromUserInput();
                    /*const loggedUser = await */
                    if (_user.newPassword) {
                        Auth.completeNewPassword(
                            user,              // the Cognito User Object
                            _user.newPassword.padStart(6, "0"),       // the new password
                            // OPTIONAL, the required attributes
                            _user.optional
                        ).then(user => {
                            this.showMessage(this.translate("Password Reseted Successfully"), SNACKBAR_INFO, 5000)

                            _user.password = _user.newPassword
                            // at this time the user is logged in if no MFA required
                            this.signIn(_user)

                        }).catch(err => {
                            this.handleSingInError(err, _user);
                        });
                    } else
                        this.setState({
                            isNewPasswordRequired: true,
                            isSignIn: false,
                            user: {
                                ..._user,
                                ...user,
                                requiredAttributes: requiredAttributes
                            }
                        })
                } else if (user.challengeName === 'MFA_SETUP') {
                    // This happens when the MFA method is TOTP
                    // The user needs to setup the TOTP before using it
                    // More info please check the Enabling MFA part
                    Auth.setupTOTP(user);
                } else {
                    let _u = {
                        ..._user,
                        ...user,
                    };
                    this.props.UserSignIn_onSuccess(_u);
                    this.isUserSignedIn();
                }
            }).then(() => {
                setTimeout(() => this.setState({ isSigninInProgress: false }), 1000);
            })
            .catch(err => {
                this.handleSingInError(err, _user);
            });
    }

    handleSingInError(err, _user) {
        console.log('handleSingInError', err, _user);

        this.props.UserSignIn_onError({ ...err, user: _user });

        switch (err.code) {
            case PasswordResetRequiredException:
                this.showMessage(this.translate(err.message), SNACKBAR_WARNING, 5000)

                this.setState({ user: _user });

                this.handleGo(false, false, true);

                break;
            case AttemptLimitException:
                _user = err.user;
                this.showMessage(this.translate(err.message ? err.message : (err.log ? err.log : err)), SNACKBAR_WARNING, 5000)
                this.setState({
                    signInAttempts: err.signInAttempts,
                    isForgotPasswordRequested: (err.signInAttempts >= 2 ? true : false),
                    isSignIn: (err.signInAttempts >= 2 ? false : true),
                    user: _user
                });
                break;
            case UserNotConfirmedException:
                _user = err.user;
                this.showMessage(this.translate(err.message ? err.message : (err.log ? err.log : err)), SNACKBAR_WARNING, 5000)
                this.setState({
                    isSignIn: false,
                    isSignUp: true,
                    isSignUpRequested: true,
                    user: _user
                });
                break;
            case InvalidParameterException:
            case UserNotFoundException:
            case NotAuthorizedException:
            default:
                this.showMessage(this.translate(err.message ? err.message : (err.log ? err.log : err)), SNACKBAR_ERROR, 5000)
                this.setState({
                    signInAttempts: this.state.signInAttempts + 1,
                    isForgotPasswordRequested: (this.state.signInAttempts === 2 ? true : false),
                    isSignIn: (this.state.signInAttempts === 2 ? false : true),
                    user: _user
                });

                if (this.state.signInAttempts === 3) {
                    this.props.UserSignIn_onError({
                        code: "AttemptLimitException",
                        message: "Attempt limit exceeded",
                        name: "AttemptLimitException",
                        user: _user,
                        signInAttempts: this.state.signInAttempts
                    });
                }

                break;
        }

        this.setState({ user: _user });
        setTimeout(() => {
            this.setState({ isSigninInProgress: false, checkingSignedInStatus: false });
        }, 1000);
    }

    handleGo(_signIn, _signUp, _forgotPassword, _forgotPasswordRequested) {
        // console.log("handleGo", _signIn, _signUp, _forgotPassword, _forgotPasswordRequested);
        if (_signIn) {
            this.setState({
                isSignIn: false,
                isForgotPassword: false,
                isForgotPasswordRequested: false,
                isSignUp: _signIn,
                isSignUpRequested: false,
            });
        } else if (_signUp) {
            this.setState({
                isSignUp: false,
                isSignUpRequested: false,
                isForgotPassword: false,
                isForgotPasswordRequested: false,
                isSignIn: _signUp,
            });
        } else if (_forgotPassword || _forgotPasswordRequested) {
            this.setState({
                isSignIn: false,
                isSignUp: false,
                isSignUpRequested: false,
                isForgotPasswordRequested: false,
                isForgotPassword: _forgotPassword || _forgotPasswordRequested,
            });
        }
    }

    /**
     
     */
    render() {
        const {
            isSigninInProgress,
            isSignUpInProgress,
            checkingSignedInStatus,
            isSignIn,
            isSignUp,
            isSignUpRequested,
            isSignUpRequestedInProgress,
            isForgotPassword,
            isForgotPasswordProgress,
            isForgotPasswordRequested,
            isForgotPasswordRequestedProgress,
            isNewPasswordRequired,
            signInAttempts,
            loaderMsg } = this.state;

        return (
            <Container
                style={{ height: '100vh' }}
                maxWidth="sm">
                <Box
                    style={{ height: '100vh' }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column">
                    <Paper>
                        <Container>
                            <Box my={2}
                                justifyContent="center"
                                alignItems="center">
                                <Box
                                    py={1}>
                                    <img src={this.props.imageSource ? this.props.imageSource : this.defaultLogo} style={{ "maxHeight": "10em", margin: "0 auto", display: "flex" }} alt="Bama POS" />
                                </Box>
                                <Box
                                    style={{ fontSize: 8 }}
                                    display="none">
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center">
                                        <FormControlLabel disabled control={<Checkbox checked={isSigninInProgress} />} label="isSigninInProgress" />
                                        <FormControlLabel disabled control={<Checkbox checked={isSignUpInProgress} />} label="isSignUpInProgress" />
                                        <FormControlLabel disabled control={<Checkbox checked={isSignUpRequestedInProgress} />} label="isSignUpRequestedInProgress" />
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center">
                                        <FormControlLabel disabled control={<Checkbox checked={isForgotPasswordProgress} />} label="isForgotPasswordProgress" />
                                        <FormControlLabel disabled control={<Checkbox checked={isForgotPasswordRequestedProgress} />} label="isForgotPasswordRequestedProgress" />
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center">
                                        <FormControlLabel disabled control={<Checkbox checked={isSignIn} />} label="isSignIn" />
                                        <FormControlLabel disabled control={<Checkbox checked={isSignUp} />} label="isSignUp" />
                                        <FormControlLabel disabled control={<Checkbox checked={isForgotPassword} />} label="isForgotPassword" />
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center">
                                        <FormControlLabel disabled control={<Checkbox checked={checkingSignedInStatus} />} label="checkingSignedInStatus" />
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center">
                                        <FormControlLabel disabled control={<Checkbox checked={isForgotPasswordRequested} />} label="isForgotPasswordRequested" />
                                        <FormControlLabel disabled control={<Checkbox checked={isSignUpRequested} />} label="isSignUpRequested" />
                                    </Box>
                                    <Box display="none"
                                        justifyContent="center"
                                        alignItems="center">
                                        {"signInAttempts: "} {signInAttempts}
                                    </Box>
                                </Box>

                                <Box
                                    py={2}>
                                    {signInAttempts > 0 ?
                                        <Alert severity="error" variant="filled">
                                            {3 - signInAttempts} {this.translate("Remaining attempts")}
                                            <LinearProgress variant="determinate" value={((signInAttempts * 100) / 3)} color="secondary" />
                                        </Alert>
                                        : null}
                                    {isSignIn ?
                                        <LoginForm
                                            handleSubmit={this.signIn}
                                            user={this.state.user} />
                                        : null}
                                    {isSignUp ?
                                        <RegisterForm
                                            handleSubmit={this.signUp}
                                            handleConfirmSignUp={this.confirmSignUp}
                                            handleResendSignUp={this.resendSignUp}
                                            confirmRequested={isSignUpRequested}
                                            user={this.state.user} />
                                        : null}
                                    {isForgotPassword ?
                                        <ForgotPasswordForm
                                            handleSubmit={this.forgotPassword}
                                            handleRequest={this.forgotPasswordRequest}
                                            user={this.state.user} />
                                        : null}
                                    {isNewPasswordRequired ?
                                        <NewPasswordForm
                                            showMessage={this.showMessage}
                                            handleSubmit={this.newPassword}
                                            user={this.state.user} />
                                        : null}
                                </Box>
                                <Box
                                    width={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="column">
                                    {/*isSignIn ?
                                        this.translate('New in Bama PoS')
                                    : null*/}
                                    {isSignUp ?
                                        this.translate("Already have an Account")
                                        : null}
                                    {isForgotPassword ?
                                        this.translate("Already have an Account")
                                        : null}
                                    {isForgotPasswordRequested ?
                                        this.translate("Forgot password?")
                                        : null}
                                    <Link href="#"
                                        onClick={() => this.handleGo(isSignIn, isSignUp, isForgotPassword, isForgotPasswordRequested)}>
                                        {/*isSignIn ?
                                            this.translate('Create a New Account')
                                        : null*/}
                                        {isSignUp ?
                                            this.translate("Sign In")
                                            : null}
                                        {isForgotPassword ?
                                            this.translate("Sign In")
                                            : null}
                                        {isForgotPasswordRequested ?
                                            this.translate("Reset Password")
                                            : null}
                                    </Link>
                                </Box>

                                <Loader
                                    showLoader={checkingSignedInStatus || isSigninInProgress || isSignUpInProgress || isForgotPasswordProgress || isForgotPasswordRequestedProgress || isSignUpRequestedInProgress}
                                    imageSource={this.image}
                                    loaderSource={null}
                                    backgroundStyle={null}
                                    textStyle={null}
                                    textMessage={loaderMsg}
                                />
                            </Box>
                        </Container>
                    </Paper>
                </Box>
            </Container>
        );
    }
}

export default withSnackbar(UserAuthentication);