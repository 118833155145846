import React, { Component } from 'react';

export class StepItemElement extends Component {
  render() {
    const {
      index,
      step,
      screen,
      elementName,
      item: { selected, value },
      openPopover,
      functionName,
      functionElementIndex,
      disableEdit,
      isDeleted,
    } = this.props;
    let name = '';
    let val = '';
    let type = '';

    switch (selected) {
      case 'variable':
        name = 'Var:';
        val = value.tag;
        type = 'variable';
        break;

      case 'parameter':
        name = 'Par:';
        val = value.name;
        type = 'parameter';
        break;

      case 'areaIndicators':
        name = 'Ind:';
        val = value.name;
        type = 'indicators';
        break;

      case 'parameterFunction':
        name = '';
        val = value;
        type = 'parameterFunction';
        break;

      default:
        break;
    }
    let elementId = this.props.item.value.id;
    let element = name + val;
    return (
      <div
        className={`step-item step-${selected} ${isDeleted && 'step-deleted'}`}
        aria-describedby={index}
        data-step={step}
        data-screen={screen}
        data-name={elementName}
        data-type={type}
        data-function-name={functionName}
        data-function-eind={functionElementIndex}
        data-element-nodeid={elementId}
        onClick={e => !disableEdit && openPopover(e)}
      >
        <div
          className={'MuiButton-label'}
          dangerouslySetInnerHTML={{ __html: element }}
        />
      </div>
    );
  }
}

export default StepItemElement;
