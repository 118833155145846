import React from "react";
import SessionControl, { SNACKBAR_SUCCESS, SNACKBAR_WARNING, SNACKBAR_ERROR, SNACKBAR_INFO } from '../../utils/SessionControl';
import Loader from '../../components/loader/Loader';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { listAttendants } from '../../graphql/queries';
import { updateAttendant } from '../../graphql/mutations';

import { withSnackbar } from 'notistack';
import ProfileDashboard from "../../components/profile/ProfileDashboard";

class UserProfile extends SessionControl {
    constructor(props) {
        super(props);
        this.state = {
            user_profile: this.getSecureStore().UserProfileReducer.user,
            positions: this.getSecureStore().NavbarReducer && this.getSecureStore().NavbarReducer.positions ? this.getSecureStore().NavbarReducer.positions : []
        }

        this.defaultLogo = require('../../assets/images/bama_icon.png');
        this.graphqlQuery = this.graphqlQuery.bind(this);
        this.saveProfilePhoto = this.saveProfilePhoto.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.uploadProfilePhoto = this.uploadProfilePhoto.bind(this);
    }

    componentDidMount() {
        this.isUserSignedIn();
    }

    graphqlQuery = (query, params) => new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(query, params)).then(allTodos => {
            var user = null;

            switch (query) {
                case listAttendants:
                    user = allTodos.data.listAttendants.items[0]
                    break;
                case updateAttendant:
                    user = allTodos.data.updateAttendant
                    break;
                default:
                    break;
            }

            resolve(user);
        }).catch(err => {
            err.errors.map(error => this.showMessage(this.translate(error.message), SNACKBAR_ERROR, 5000))
            reject(err)
        });
    });

    saveProfilePhoto = (keyS3, base64Image) => new Promise((resolve, reject) => {
        var params = {
            "input": {
                "id": this.state.user_profile.id,
                "profilePhoto": keyS3
            }
        };

        console.log(this.state)

        this.graphqlQuery(updateAttendant, params).then(user => {
            Storage.get(user.profilePhoto, { level: 'protected' })
                .then(result => {
                    console.log(result)
                    this.showMessage(this.translate("Profile") + " " + this.translate("Updated"), SNACKBAR_SUCCESS, 5000);
                    user.profilePhotoUrl = result
                    this.props.UserProfile_onSuccess(user)
                    this.setState({
                        user_profile: user
                    })

                    if (result.Body) {
                    }

                    // Update Attendant
                    /*ipcRenderer.send('rethinkdb-updateObject-name', {
                        "table": "Attendant",
                        "db": "bama",
                        "id": this.getSecureStore().UserSignInReducer.user.id,
                        "object": {
                            profilePhoto: keyS3,
                            profilePhotoData: base64Image
                        },
                        "limit": null,
                        "orderBy": null,
                    })*/

                    resolve(user)
                })
                .catch(err => this.showMessage(this.translate(err), SNACKBAR_ERROR, 5000));
        }).catch(err => {
            err.errors.map(error => this.showMessage(this.translate(error.message), SNACKBAR_ERROR, 5000))
            reject(err)
        });
    })

    saveProfile = (user_update) => new Promise((resolve, reject) => {
        console.log(user_update)
        var params = {
            "input": {
                "id": this.state.user_profile.id
            }
        };

        if (user_update.attendant) {
            if (user_update.attendant.nipPassword)
                params.input.nipPassword = user_update.attendant.nipPassword
            if (user_update.attendant.phones)
                params.input.phones = user_update.attendant.phones
            if (user_update.attendant.password)
                params.input.hashPassword = user_update.attendant.password
        }

        // Update Attendant
        /*ipcRenderer.send('rethinkdb-updateObject-name', {
            "table": "Attendant",
            "db": "bama",
            "id": this.getSecureStore().UserSignInReducer.user.id,
            "object": params.input,
            "limit": null,
            "orderBy": null,
        })*/


        this.graphqlQuery(updateAttendant, params).then(user => {

            this.showMessage(this.translate("Profile") + " " + this.translate("Updated"), SNACKBAR_SUCCESS, 5000);
            this.setState({
                user_profile: user
            })
            resolve(user)
        }).catch(err => {
            err.errors.map(error => console.log(error.message))
            reject(err)
        });
        resolve(true)
    })

    uploadProfilePhoto = (file) => new Promise((resolve, reject) => {
        Storage.put(file.name, file.file, {
            level: 'protected',
            contentType: file.type
        })
            .then(result => {
                this.showMessage(this.translate("The image was uploaded successfully"), SNACKBAR_SUCCESS, 5000);
                resolve(result)
            })
            .catch(err => {
                this.showMessage(this.translate(err), SNACKBAR_ERROR, 5000);
                reject(err)
            });
    })

    render() {
        const { user_profile, positions } = this.state;

        return (
            <Container fixed>
                <Box my={2}>
                    <ProfileDashboard
                        handleSaveProfilePhoto={this.saveProfilePhoto}
                        handleUploadProfilePhoto={this.uploadProfilePhoto}
                        handleSaveProfile={this.saveProfile}
                        user={user_profile}
                        positions={positions}
                        translate={this.translate} />
                </Box>
            </Container >
        );
    }
}

export default withSnackbar(UserProfile);