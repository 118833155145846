import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function StepsHeader(props) {
  let title = '';
  let desc = '';

  switch (props.type) {
    case 'KPI':
      title = 'Construye tu KPI (Comisión Variable)';
      desc =
        'Selecciona los elementos y operaciones necesarias para construir el KPI (Comisión Variable). Puedes agregar los pasos que desees.';
      break;

    case 'indicator':
      title = 'Construye tu Indicador';
      desc =
        'Selecciona los elementos y operaciones necesarias para construir tu Indicador. Puedes agregar los pasos que desees.';
      break;

    case 'createFunction':
      title = 'Construye tu Función';
      desc =
        'Selecciona los elementos y operaciones necesarias para construir tu Función. Puedes agregar los pasos que desees.';
      break;

    default:
      break;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1" gutterBottom>
          {desc}
        </Typography>
        <Typography variant="h5" fontWeight={600}>
          {`${props.orgName} / ${props.areaName}`}
        </Typography>
        <Typography variant="h4" fontWeight={600}>
          {`${props.name}`}
        </Typography>
      </Grid>
    </Grid>
  );
}
