import React, { useEffect, useState } from 'react';

import { Dialog, Toolbar, Typography, Slide, makeStyles, Box, Fab, DialogContentText, Button, Grid, IconButton, Container } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import ForecastChart from './ForecastChart';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ChartModal(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open !== props.open) {
            setOpen(props.open)
        }

        return function cleanup() {

        };
    }, [props.open])

    const handleCloseModal = () => {
        setOpen(false)
        props.handleCloseModal()
    }

    return (<Dialog fullScreen
        open={props.open}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
    >
        <AppBar
            color="secondary"
            className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.handleCloseModal}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {`${props.translate("Forecast")}`}
                </Typography>
            </Toolbar>
        </AppBar>
        {loading ? <LinearProgress color="secondary" /> : ""}
        <Container fixed>
            <Box my={2}>
                <ForecastChart
                    {...props}
                    showProgress={setLoading} />
            </Box>
        </Container>
    </Dialog>)
}

export default ChartModal;
