import React, { useState, useEffect } from 'react';
import { Slide, Dialog, Toolbar, IconButton, Typography, Container, AppBar, makeStyles, Grid, Box, Paper, Button, LinearProgress, ButtonGroup } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import BlockIcon from '@material-ui/icons/Block';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TransferCustomer from "../audit/TransferListCustomer"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));


export default function MassiveUpdateModal(props) {
    const [customers, setCustomers] = useState(null)
    const [audits, setAudits] = useState(null)
    const [sending, setSending] = useState(false)
    const [statusCustomer, setstatusCustomer] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        if (customers !== props.customers)
            buildAudit(props.customers)
        return function cleanup() {

        };
    }, [
        customers,
        props.customers
    ]) // Only re-run the effect if name or email change

    const buildAudit = customers => {
        setCustomers(customers)

        var _audits = []

        customers.forEach(c => {
            // console.log("buildAudit", c)
            if (c.audits && c.audits.items && Array.isArray(c.audits.items) && c.audits.items.filter(a => Boolean(!a.endAudit).length > 0))
                _audits.push(c)
        })

        // console.log("buildAudit", customers, _audits)
        if (_audits.length > 0)
            setAudits(_audits)
    }

    const handleUpdateCustomer = (event, customerType) => {
        customers.forEach(c => {
            setSending(true)
            props.handleUpdateCustomer({
                customerCustomerTypeId: customerType.id,
                id: c.id
            }).then(res => {
                c = res
                // console.log(res)

                // Send Notifications Change of Type of Client
                var sms = {
                    message: `${props.translate("upgradeCustomerType_sms")} ${customerType.customerTypeName}`,
                }

                var email = {
                    sender: "clubconviene@bamaglobal.com.mx",
                    subject: props.translate("upgradeCustomerTypeTitle_email"),
                    title: props.translate("upgradeCustomerTypeTitle_email"),
                    content: `
    <div style="width: 100%">
    <div style="text-align: right;">
    ${new Date(res._lastChangedAt).toLocaleString()}
    </div>
    <div style="margin: 0 auto; text-align: center;">
    <h2>
    ${props.translate("Hello")} ${res.customerName}!
    </h2>
    </div>
    <div style="width: 100%">
    ${props.translate("upgradeCustomerTypeLegend_email")}
    <h2>${customerType.customerTypeName}</h2>
    </div>
    </div>
    `}
                props.sendNotifications(res, sms, email).then(res => {
                    setSending(false)
                    props.handleClose()
                })
                    .catch(err => {
                        setSending(false)
                    })

                setSending(false)
            })
        })
    }

    const handleUpdateAudit = (params) => {
        // console.log(params)
        customers.forEach(c => {
            if (c.audit && !c.audit.endAudit) {
                setSending(true)
                if (params.sellBanned) {
                    props.handleUpdateCustomer({
                        id: c.id,
                        ...params
                    }).then(res => {
                        c = res
                        props.handleUpdateAudit({
                            id: c.audit.id,
                            blacklist: true,
                            endAudit: parseInt(new Date().getTime() / 1000),
                            auditor: props.idUser
                        }).then(_res => {

                            // Send Notifications Blacklist
                            var sms = {
                                message: `${props.translate("blacklistLegend_sms")}`
                            }

                            var email = {
                                sender: "clubconviene@bamaglobal.com.mx",
                                subject: props.translate("blacklistTitle_email"),
                                title: props.translate("blacklistTitle_email"),
                                content: `
                <div style="width: 100%">
                <div style="text-align: right;">
                ${new Date(res._lastChangedAt).toLocaleString()}
                </div>
                <div style="margin: 0 auto; text-align: center;">
                <h2>
                ${props.translate("Hello")} ${res.customerName}!
                </h2>
                </div>
                <div style="width: 100%">
                ${props.translate("blacklistLegend_email")}
                </div>
                </div>
                `}
                            props.sendNotifications(res, sms, email).then(res => {
                                setSending(false)
                                props.handleClose()
                            })
                                .catch(err => {
                                    setSending(false)
                                })
                        }).catch(err => {
                            setSending(false)
                        })
                    }).catch(err => {
                        setSending(false)
                    })  // Blacklist
                } else {
                    props.handleUpdateCustomer({
                        id: c.id,
                        sellBanned: false,
                        customerCustomerTypeId: "c32b3f5e-5de3-4954-96e2-a87a29d89fcd"
                    }).then(res => {
                        c = res
                        props.handleUpdateAudit({
                            id: c.audit.id,
                            blacklist: false,
                            endAudit: parseInt(new Date().getTime() / 1000),
                            auditor: props.idUser
                        }).then(_res => {
                            // Send Notifications Activation Successfully
                            var sms = {
                                message: `${props.translate("activationSuccessfullyLegend_sms")}`
                            }

                            var email = {
                                sender: "clubconviene@bamaglobal.com.mx",
                                subject: props.translate("Activation Successfully"),
                                title: props.translate("Activation Successfully"),
                                content: `
                <div style="width: 100%">
                <div style="text-align: right;">
                ${new Date(res._lastChangedAt).toLocaleString()}
                </div>
                <div style="margin: 0 auto; text-align: center;">
                <h2>
                ${props.translate("Hello")} ${res.customerName}!
                </h2>
                </div>
                <div style="width: 100%">
                ${props.translate("activationSuccessfullyLegend")}
                </div>
                </div>
                `}
                            props.sendNotifications(res, sms, email).then(res => {
                                setSending(false)
                                props.handleClose()
                            })
                                .catch(err => {
                                    setSending(false)
                                })
                        }).catch(err => {
                            setSending(false)
                        })
                    }).catch(err => {
                        setSending(false)
                    })
                }
            }
        })
    }

    const handleUpdateAuditCustomer = (event, params) => {
        if (!statusCustomer) {
            customers.forEach(c => {
                setSending(true)
                if (params.sellBanned) {
                    props.handleUpdateCustomer({
                        id: c.id,
                        _version: c._version,
                        ...params
                    }).then(res => {
                        c = res
                        // props.handleUpdateAudit({
                        //     id: c.audit.id,
                        //     blacklist: true,
                        //     endAudit: parseInt(new Date().getTime() / 1000),
                        //     auditor: props.idUser
                        // }).then(_res => {

                        //             // Send Notifications Blacklist
                        //             var sms = {
                        //                 message: `${props.translate("blacklistLegend_sms")}`
                        //             }

                        //             var email = {
                        //                 sender: "clubconviene@bamaglobal.com.mx",
                        //                 subject: props.translate("blacklistTitle_email"),
                        //                 title: props.translate("blacklistTitle_email"),
                        //                 content: `
                        // <div style="width: 100%">
                        // <div style="text-align: right;">
                        // ${new Date(res._lastChangedAt).toLocaleString()}
                        // </div>
                        // <div style="margin: 0 auto; text-align: center;">
                        // <h2>
                        // ${props.translate("Hello")} ${res.customerName}!
                        // </h2>
                        // </div>
                        // <div style="width: 100%">
                        // ${props.translate("blacklistLegend_email")}
                        // </div>
                        // </div>
                        // `}
                        //             props.sendNotifications(res, sms, email).then(res => {
                        //                 setSending(false)
                        //                 props.handleClose()
                        //             })
                        //                 .catch(err => {
                        //                     setSending(false)
                        //                 })
                        // }).catch(err => {
                        setSending(false)
                        setTimeout(function () {
                            setstatusCustomer(true)
                        }, 1000);

                        // })
                    }).catch(err => {
                        setSending(false)
                    })  // Blacklist
                }

            })
        }
    }


    function RowOne() {
        return (
            <React.Fragment>
                <Grid item xs={4}>
                    {customers ?
                        <Grid container
                            spacing={3}
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ minHeight: '30vh' }}>
                            <Grid item>
                                <Paper>
                                    <Typography variant="h5">
                                        <Box m={3}>
                                            <Grid container
                                                spacing={3}
                                                justify="center"
                                                alignItems="lefth">
                                                <Grid item>
                                                    <AccountCircleIcon style={{ fontSize: "4em" }} color="action" />
                                                </Grid>
                                                <Grid item>
                                                    <Grid container
                                                        spacing={3}
                                                        direction="column"
                                                        justify="center"
                                                        alignItems="center">
                                                        <Grid item>
                                                            {customers.length}
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                {props.translate("Selected Customers")}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </Paper>
                            </Grid>
                            {/* CAMBIO DE BOTON A INACTIVOS */}
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={9} spacing={3}>
                                    <Button
                                        disabled={sending}
                                        variant="contained"
                                        color={statusCustomer ? "primary" : "secondary"}
                                        startIcon={<BlockIcon />}
                                        onClick={(event) =>
                                            handleUpdateAuditCustomer(event, {
                                                sellBanned: true
                                            })
                                        }
                                    >
                                        {statusCustomer ? props.translate("List of added files") : props.translate("Send customer inactive")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        : ''}
                    {audits ?
                        <Grid container
                            spacing={3}
                            direction="column"
                            justify="center"
                            alignItems="center">
                            <Grid item>
                                <Paper>
                                    <Typography variant="h5">
                                        <Box m={3}>
                                            <Grid container
                                                spacing={3}
                                                justify="center"
                                                alignItems="center">
                                                <Grid item>
                                                    <FileCopyIcon style={{ fontSize: "4em" }} color="action" />
                                                </Grid>
                                                <Grid item>
                                                    <Grid container
                                                        spacing={3}
                                                        direction="column"
                                                        justify="center"
                                                        alignItems="center">
                                                        <Grid item>
                                                            {audits.length}
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                {props.translate("Pending Audits")}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                        : ''}
                </Grid>
                <Grid item xs={8}>
                    <Box display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        style={{ minHeight: "30vh", minWidth: "100%" }}>
                        {customers ?
                            <Grid container
                                spacing={3}
                                direction="column"
                                justify="center"
                                alignItems="center">
                                {/* <Grid item>
                            <Paper>
                                <Typography variant="h5">
                                    <Box m={3}>
                                        <Grid container
                                            spacing={3}
                                            justify="center"
                                            alignItems="center">
                                            <Grid item>
                                                <AccountCircleIcon style={{ fontSize: "4em" }} color="action" />
                                            </Grid>
                                            <Grid item>
                                                <Grid container
                                                    spacing={3}
                                                    direction="column"
                                                    justify="center"
                                                    alignItems="center">
                                                    <Grid item>
                                                        {customers.length}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {props.translate("Selected Customers")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Typography>
                            </Paper>
                        </Grid> */}
                                <Grid item>
                                    {props.translate("customerMassiveUpdateLegend")}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container
                                        spacing={3}
                                        justify="center"
                                        alignItems="center">
                                        <Grid item>
                                            <ButtonGroup variant="contained" >
                                                {props.customerTypes && props.customerTypes.map(ct => {
                                                    return <Button item key={`ctb-${ct.id}`}
                                                        disabled={sending}
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<VerifiedUserIcon />}
                                                        onClick={event => handleUpdateCustomer(event, ct)}>
                                                        {ct.customerTypeName}
                                                    </Button>
                                                })}
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''}
                        {audits ?
                            <Grid container
                                spacing={3}
                                direction="column"
                                justify="center"
                                alignItems="center">
                                {/* <Grid item>
                            <Paper>
                                <Typography variant="h5">
                                    <Box m={3}>
                                        <Grid container
                                            spacing={3}
                                            justify="center"
                                            alignItems="center">
                                            <Grid item>
                                                <FileCopyIcon style={{ fontSize: "4em" }} color="action" />
                                            </Grid>
                                            <Grid item>
                                                <Grid container
                                                    spacing={3}
                                                    direction="column"
                                                    justify="center"
                                                    alignItems="center">
                                                    <Grid item>
                                                        {audits.length}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {props.translate("Pending Audits")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Typography>
                            </Paper>
                        </Grid> */}
                                <Grid item>
                                    {props.translate("customerMassiveUpdateAuditLegend")}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container
                                        spacing={3}
                                        justify="center"
                                        alignItems="center">
                                        <Grid item>
                                            <ButtonGroup variant="contained" >
                                                <Button
                                                    disabled={sending}
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<VerifiedUserIcon />}
                                                    onClick={event => handleUpdateAudit(event, {
                                                        sellBanned: false
                                                    })}>
                                                    {props.translate("Authorize Registry")}
                                                </Button>
                                                <Button
                                                    disabled={sending}
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={<BlockIcon />}
                                                    onClick={event => handleUpdateAudit(event, {
                                                        customerId: "blacklist",
                                                        sellBanned: true
                                                    })}>
                                                    {props.translate("Send to Blacklist")}
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''}
                    </Box>
                </Grid>
                {/* Seccion para integrar */}
                {/* <Grid item xs={4}>
              <Paper className={classes.paper}>item 3</Paper>
            </Grid> */}
            </React.Fragment>
        );
    }

    function RowTwo() {
        return (
            <React.Fragment>
                <Grid item xs={4}>
                    {/* {customers ? (
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Paper>
                      <Typography variant="h5">
                        <Box m={3}>
                          <Grid
                            container
                            spacing={3}
                            justify="center"
                            alignItems="lefth"
                          >
                            <Grid item>
                              <AccountCircleIcon
                                style={{ fontSize: "4em" }}
                                color="action"
                              />
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                spacing={3}
                                direction="column"
                                justify="center"
                                alignItems="center"
                              >
                                <Grid item>{customers.length}</Grid>
                                <Grid item>
                                  <Typography variant="body1">
                                    {props.translate("Selected Customers")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              ) : (
                ""
              )} */}
                </Grid>
                <Grid item xs={8}>
                    <TransferCustomer
                        customers={customers} />

                </Grid>
            </React.Fragment>
        );
    }


    return <Dialog fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}>
        <AppBar
            color="secondary"
            className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {props.title}
                </Typography>
            </Toolbar>
        </AppBar>
        {sending && <LinearProgress color="primary" />}
        <Container spacing={3}
            direction="column"
            justify="center"
            alignItems="center">
            <Grid container item xs={12} spacing={3}>
                <RowOne />
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <RowTwo />
            </Grid>
            {/*<Typography variant="body2" color="textSecondary">
                {JSON.stringify(props.customers)}
</Typography>*/}
        </Container>
    </Dialog>
}