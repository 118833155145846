import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, AppBar, Paper, Typography, Dialog, Toolbar, Button, Slide, ListItemIcon, ListItemText } from '@material-ui/core';
import MaterialTable from "material-table";
import { tableIcons, localization } from '../../components/materialTable/MaterialTable';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';


import { FrequencyType } from '../../schema/enums/schemaEnums';
import { listWorkTeamTemplates,listPositions } from '../../graphql/queries'
import { updateWorkTeamTemplate,createWorkTeamTemplate,deleteWorkTeamTemplate } from '../../graphql/mutations'
import { api, apiPartial } from "../../api/api";


import ViewPosition from '../../screens/humanResource/templetePosition'
import ViewStatusHeadCount from '../../screens/humanResource/templeteStatusHeadCount'


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


function TempleteAssingment(props) {
    const firstRender = useRef(true)
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [WorkTeamTemplates, setWorkTeamTemplates] = React.useState(null);
    const [itemWorkTeam, setItemWorkTeam] = React.useState(null);

    const [openEditPosition, setOpenEditPosition] = React.useState(false);

    const [openEditStatusHeadCount, setOpenEditStatusHeadCount] = React.useState(false);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render

            loadWorkTeamTemplates()

            return // skip the code below
        }

        return function cleanup() {

        };
    }, []) // Only re-run the effect if name or email change

   
    const loadWorkTeamTemplates = () => {
        api(listWorkTeamTemplates, { limit: 300 })
          .then((res) => {
            let itemFilter = res.filter((pos) => pos._deleted === null);
             setWorkTeamTemplates(itemFilter)
            })
          .catch((err) => {
            console.log(err);
          });
      };
    

      const UpdWorkTeamTemplates = (data) => {

        let params ={
            id:data.id,
            active:!data.active,
            _version:data._version
        }
        api(updateWorkTeamTemplate, { input: params })
          .then((res) => {

            loadWorkTeamTemplates()
            // const temp = [...WorkTeamTemplates]
            // temp.forEach((_e,i) => {
            //   if (_e.id === res.id ) {
            //     _e.active = !_e.active;
            //   }
            // });
            // setWorkTeamTemplates(temp)

            })

          .catch((err) => {
            console.log(err);
          });
      };

      const CreateWorkTeamTemplates = (params) => {
        api(createWorkTeamTemplate, { input: params })
          .then((res) => {

            loadWorkTeamTemplates()

            })
          .catch((err) => {
            console.log(err);
          });
      };

      const UpdateWorkTeamTemplates = (params) => {
        api(updateWorkTeamTemplate, { input: params })
          .then((res) => {

            loadWorkTeamTemplates()

            })
          .catch((err) => {
            console.log(err);
          });
      };

      const DeleteWorkTeamTemplates = (params) => {
        api(deleteWorkTeamTemplate, { input: params })
          .then((res) => {

            loadWorkTeamTemplates()

            })
          .catch((err) => {
            console.log(err);
          });
      };

    const handleChangeActive = (row) => {
        UpdWorkTeamTemplates(row)
    };
    
    const handleChangeDialogPosition = (row) => {
        setOpenEditPosition(true)
        setItemWorkTeam(row)
    };
    
    const handleCloseDialogPosition = () => {
      setOpenEditPosition(false);
    };

    const handleChangeDialogStatusHeadCount = (row) => {
        setOpenEditStatusHeadCount(true)
        setItemWorkTeam(row)
    };
    

    const handleCloseDialogStatusHeadCount = () => {
        setOpenEditStatusHeadCount(false);
    };


    return (
      <Box p={2}>
        <Typography variant="h6" color="textSecondary">
           {props.translate("Templete WorkedTeam")}
        </Typography>
        <Grid item xs={12}>
          {WorkTeamTemplates !== null && (
            <Grid item xs={12}>
              <Paper>
                <MaterialTable
                  icons={tableIcons}
                  localization={localization(props)}
                  options={{
                    exportButton: true,
                  }}
                  columns={[
                    {
                      field: "workTeamTemplate",
                      title: props.translate("Template"),
                    },
                    {
                      field: "minSalesAmount",
                      title: props.translate("Sales Min Amount"),
                      type: 'numeric'
                    },
                    {
                      field: "maxSalesAmount",
                      title: props.translate("Sales Max Amount"),
                      type: 'numeric'
                    },
                    {
                      field: "frecuencySales",
                      title: props.translate("Sales frecuency"),
                      lookup: FrequencyType
                    },
                    {
                      field: "periodSales",
                      title: props.translate("Sales period"),
                      type: 'numeric'
                    },
                    {
                        field: "active",
                        title: props.translate("Active"),
                        lookup: { "true": 'Si', "false": 'No' },
                        render: (rowData) => {
                          return (
                            <Grid
                              container
                              spacing={2}
                              direction="row"
                              alignItems="center"
                              justify="center"
                              className={classes.root}
                            >
                              <Grid item xs={12}>
                                <Button variant="contained" 
                                color={rowData.active ?"primary":""}
                                 onClick={() => {
                                    handleChangeActive(rowData);
                                  }}>
                                  {rowData.active ?"Activado":"Desactivado"}
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        },
                      },
                    {
                    field: "",
                    title: props.translate("Edit Position"),
                      render: (rowData) => {
                        return (
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            alignItems="center"
                            justify="center"
                            className={classes.root}
                          >
                            <Grid item xs={12}>
                              <Button variant="contained" color="secondary"
                               onClick={() => {
                                handleChangeDialogPosition(rowData);
                              }}>
                                {props.translate("Edit")}
                              </Button>
                            </Grid>
                          </Grid>
                        );
                      },
                    },
                    {
                        field: "",
                        title: props.translate("Rule HeadCount"),
                          render: (rowData) => {
                            return (
                              <Grid
                                container
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                justify="center"
                                className={classes.root}
                              >
                                <Grid item xs={12}>
                                  <Button variant="contained" color="secondary"
                                   onClick={() => {
                                    handleChangeDialogStatusHeadCount(rowData);
                                  }}>
                                    {props.translate("Edit")}
                                  </Button>
                                </Grid>
                              </Grid>
                            );
                          },
                        },
                  ]}
                  data={WorkTeamTemplates}
                  editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {

                            var isTrueSet = (newData.active === 'true');
                            let param = {
                              workTeamTemplate: newData.workTeamTemplate,
                              active: isTrueSet,
                              minSalesAmount: parseFloat(newData.minSalesAmount),
                              maxSalesAmount: parseFloat(newData.maxSalesAmount),
                              frecuencySales: newData.frecuencySales,
                              periodSales: parseInt(newData.periodSales),
                              _version: newData._version,
                            };

                            CreateWorkTeamTemplates(param)
                          resolve();
                        }, 1000)
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...WorkTeamTemplates];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;

                          var isTrueSet = (newData.active === 'true');
                          let param = {
                            id:newData.id,
                            workTeamTemplate: newData.workTeamTemplate,
                            active: isTrueSet,
                            minSalesAmount: parseFloat(newData.minSalesAmount),
                            maxSalesAmount: parseFloat(newData.maxSalesAmount),
                            frecuencySales: newData.frecuencySales,
                            periodSales: parseInt(newData.periodSales),
                            _version: newData._version,
                          };

                           UpdateWorkTeamTemplates(param)
            
                          resolve();
                        }, 1000)
                      }),
                      onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                          setTimeout(() => {
                              const dataDelete = [...WorkTeamTemplates];
                              const index = oldData.tableData.id;
                             
                              let params = {
                                id:WorkTeamTemplates[index].id,
                                _version:WorkTeamTemplates[index]._version
                              }

                              DeleteWorkTeamTemplates(params)
          
                              resolve();
                          }, 1000);
                      })
                  }}
                  title={""} //
                />
              </Paper>
            </Grid>
          )}
        </Grid>
        <Dialog
        fullScreen
        open={openEditPosition}
        onClose={handleCloseDialogPosition}
        TransitionComponent={Transition}
      >
        <AppBar color="secondary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialogPosition}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box p={2}>
          <ViewPosition
            {...props}
            WorkTeam={itemWorkTeam}
          ></ViewPosition> 
        </Box>
      </Dialog>

      <Dialog
        fullScreen
        open={openEditStatusHeadCount}
        onClose={handleCloseDialogStatusHeadCount}
        TransitionComponent={Transition}
      >
        <AppBar color="secondary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialogStatusHeadCount}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box p={2}>
          <ViewStatusHeadCount
            {...props}
            WorkTeam={itemWorkTeam}
          ></ViewStatusHeadCount> 
        </Box>
      </Dialog>
      </Box>
    );
}
export default withSnackbar(TempleteAssingment);