import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getModalSelectorFatherScreen } from '../../../store/modalSelector/selectors';
import { setModalselectorShow } from '../../../store/modalSelector/actions';

import {
  isEmployeesloading,
  getEmployeesdata,
} from '../../../store/employees/selectors';
import { setReportsEmployee } from '../../../store/reports/actions';

export class ModalSelectorEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  selectValue(payload) {
    this.setState({
      ...this.state,
      value: payload,
    });
  }

  setValue() {
    const { screen, modalasSelectorShow, setReportsEmployee } = this.props;
    const { value } = this.state;

    switch (screen) {
      case 'reports':
        setReportsEmployee({
          name:
            value.last_name +
            ' ' +
            value.second_last_name +
            ' ' +
            value.first_name,
          id: value.id,
        });
        break;

      default:
        break;
    }
    modalasSelectorShow(false);
  }

  render() {
    const { loading, data, modalasSelectorShow } = this.props;
    const { value } = this.state;
    return (
      <div>
        <h2 id="transition-modal-title">Selecciona un Empleado:</h2>
        {loading ? (
          <CircularProgress className={'loading-indicator'} />
        ) : data.length === 0 ? (
          <span className={'error-txt'}>
            Error: No se encotraron resultados
          </span>
        ) : (
          <div className={'select-wrapper'}>
            <Autocomplete
              className={'selector'}
              id="combo-box-demo"
              options={data}
              getOptionLabel={data => data.fullname}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Empleado"
                  variant="outlined"
                  className={'selector'}
                />
              )}
              onChange={(event, newValue) => this.selectValue(newValue)}
            />
          </div>
        )}
        <Grid className={'btn-wrapper'} container spacing={3}>
          <Grid item xs={12}>
            <Button
              className={'btn accept-btn'}
              variant="contained"
              disabled={value ? false : true}
              onClick={() => this.setValue()}
            >
              Aceptar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={'btn cancel-btn'}
              onClick={() => modalasSelectorShow(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: isEmployeesloading(state),
  data: getEmployeesdata(state),
  screen: getModalSelectorFatherScreen(state),
});

const mapDispatchToProps = dispatch => ({
  modalasSelectorShow: payload => dispatch(setModalselectorShow(payload)),
  setReportsEmployee: payload => dispatch(setReportsEmployee(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalSelectorEmployee);
