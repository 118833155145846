import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getDatasourcesState = state => get(state, 'datasources');

export const isDatasourcesloading = createSelector(
  [getDatasourcesState],
  datasources => get(datasources, 'loading'),
);

export const getDatasourcesdata = createSelector(
  [getDatasourcesState],
  datasources => get(datasources, 'data'),
);

export const isDatasourcesError = createSelector(
  [getDatasourcesState],
  datasources => get(datasources, 'error'),
);
