// Amplify 
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../aws-exports';

// Amplify init
Amplify.configure(awsconfig);

export const api = (operation, params, response) => new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(operation, params))
        .then(allTodos => {
            const _key = Object.keys(allTodos.data)

            if (allTodos.data[_key] && typeof allTodos.data[_key] !== "string" && "items" in allTodos.data[_key])
                if (Array.isArray(response))
                    response = response.concat(allTodos.data[_key].items)
                else
                    response = allTodos.data[_key].items
            else
                response = allTodos.data[_key]
            // console.log(`api.${_key}`, response)

            if (allTodos.data[_key] && typeof allTodos.data[_key] !== "string" && allTodos.data[_key].nextToken)
                resolve(api(operation, {
                    ...params,
                    nextToken: allTodos.data[_key].nextToken
                }, response))
            else
                resolve(response)
        }).catch(error => reject(error));
})

export const apiPartial = (operation, params) => API.graphql(graphqlOperation(operation, params))
    .then(allTodos => {
        const _key = Object.keys(allTodos.data)
        return allTodos.data[_key]
    }).catch(error => { return error });

export const subscribe = (operation, callback) => API.graphql(
    graphqlOperation(operation)
).subscribe({
    next: callback
});

export const callApiPartial = (query, params, response, callback) => new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(query, params))
        .then(allTodos => {
            const _key = Object.keys(allTodos.data)

            if (allTodos.data[_key] && typeof allTodos.data[_key] !== "string" && "items" in allTodos.data[_key])
                if (Array.isArray(response))
                    response = response.concat(allTodos.data[_key].items)
                else
                    response = allTodos.data[_key].items
            else
                response = allTodos.data[_key]

                callback(response)
            // console.log(`api.${_key}`, response)

            if (allTodos.data[_key] && typeof allTodos.data[_key] !== "string" && allTodos.data[_key].nextToken)
                resolve(callApiPartial(query, {
                    ...params,
                    nextToken: allTodos.data[_key].nextToken
                }, response, callback))
            else
                resolve(response)
        }).catch(err => {
            reject(err)
        })
})