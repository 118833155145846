// React components
import React, { useEffect } from 'react';

import './App.css';

// Router
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// Amplify 
import Amplify, { I18n } from 'aws-amplify';
import awsconfig from './aws-exports';

// App views and components
import AppLoading from './components/loader/AppLoading';
import { SnackbarProvider } from 'notistack';

// Navigation
import AppRouter from './navigation/AppRouter'

// Material UI CSS Base Line
import CssBaseline from '@material-ui/core/CssBaseline';

// Redux 
import { Provider } from "react-redux";
import { persistStore } from 'redux-persist'

import createStore from './store';

import { pushRoute } from './store/navigation/actions'
import { history } from './store/createStore';
import { PersistGate } from 'redux-persist/integration/react';

// Redux setup
export const store = createStore();
export const persistor = persistStore(store)

// Amplify setup
Amplify.configure(awsconfig)
I18n.setLanguage("es");
I18n.putVocabularies({
    // lazy requires (metro bundler does not support symlinks)
    es: require("./i18n/es.json"),
    en: require("./i18n/en.json"),
});

function App() {
    let navigate = useNavigate();
    let location = useLocation();
    let params = useParams();
    
    useEffect(() => {
        handleRouteChange(location, history.action)

        return function cleanup() {
        };
    }, [location])

    const handleRouteChange = (location, action) => {
        store.dispatch(pushRoute(location));
        return
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Provider store={store}>
                <PersistGate loading={<AppLoading />} persistor={persistor}>
                    <SnackbarProvider dense preventDuplicate>
                        <AppRouter
                            navigate={navigate}
                            location={location}
                            params={params} />
                    </SnackbarProvider>
                </PersistGate>
            </Provider>
        </React.Fragment>
    );
}

export default App;
