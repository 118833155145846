import {
  NAVIGATION,
  USER_SIGNIN
} from '../ActionTypes.js'

const defaultState = {
  systemMenuList: [],
  notFound: [],
  systemProfileMenuList: [],
  systemNavigationMenuList: [],
  attendant: {},
  cashdrawer: {},
  syncMidnightTime: [],
  syncEveryFour: []
};

export default function NavbarReducer(state = defaultState, action) {
  // console.log("NavbarReducer", action);
  switch (action.type) {
    case NAVIGATION.SYSTEM_MENU_UPDATE:
      return {
        ...state,
        systemMenuListItems: action.data
      };
    case NAVIGATION.NOT_FOUND:
      return {
        ...state,
        notFound: state.notFount.concat(action.data)
      };
    case NAVIGATION.PROFILE_MENU:
      return {
        ...state,
        systemProfileMenuList: action.data,
      };
    case NAVIGATION.SYSTEM_MENU:
      return {
        ...state,
        systemMenuList: action.data
      };
    case NAVIGATION.NAVIGATION_MENU:
      return {
        ...state,
        systemNavigationMenuList: action.data
      };
    case NAVIGATION.NO_PERMISSION:
      return {
        ...state,
        data: action.data
      };
    case USER_SIGNIN.LOGOUT:
      return { ...defaultState };
    default:
      return state;
  }
}