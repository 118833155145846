export const SET_REPORTS_ORGANIZATION = 'reports/SET_REPORTS_ORGANIZATION';
export const SET_REPORTS_AREA = 'reports/SET_REPORTS_AREA';
export const SET_REPORTS_EMPLOYEE = 'reports/SET_REPORTS_EMPLOYEE';
export const FETCH_REPORTS_EMPLOYEE = 'reports/FETCH_REPORTS_EMPLOYEE';
export const SET_EMPLOYEE_REPORTS = 'reports/SET_EMPLOYEE_REPORTS';
export const SET_EMPLOYEE_REPORTS_ERROR = 'reports/SET_EMPLOYEE_REPORTS_ERROR';
export const RUN_COMMISSION = 'reports/RUN_COMMISSION';
export const SET_COMMISION_SENDING = 'reports/SET_COMMISION_SENDING';
export const RESET_EMPLOYEE_REPORTS_DATA =
  'reports/RESET_EMPLOYEE_REPORTS_DATA';
  export const SET_REPORTS_BY_ORGANIZATION = 'reports/SET_REPORTS_BY_ORGANIZATION';
  export const FETCH_REPORTS_BY_ORGANIZATION = 'reports/FETCH_REPORTS_BY_ORGANIZATION';
  