import React , { useState }from "react";

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import Grid from '@material-ui/core/Grid';

import HCSelect from '../HCSelect';

import "./styles.scss";


const HCForm = ({getFilter, loading , search , status }) => {

  const [searchInput , setSearchInput] = useState('');
  const [searchStatus , setSearchStatus] = useState('');
  const [seachOption, setSeachOption] = useState('');

  const changeInput = (event) => {
    const search = event.target.value;
    setSearchInput(search);
  }

  const handleChange = (event) => {
    setSeachOption(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setSearchStatus(event.target.value);
  };

  return (
    
      <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3>Busqueda de datos:</h3>            
          </Grid>
          
          <Grid item xs={12}>
            <form autoComplete="off">
              <HCSelect
                change={handleChange}
                search={search}    
                label={"Busqueda"}   
                value={seachOption}         
              />
              {seachOption === "statusName" ? 
                (<HCSelect
                  change={handleChangeStatus}
                  search={status}   
                  label={"Selecciona"}             
                  value={searchStatus}    
                />) : (
                  <TextField 
                    id="searchInput"  
                    variant="outlined" 
                    value={searchInput} 
                    onChange={changeInput}
                    className="hc_form_textfield"
                    required 
                  />
                )
              }
              <div className="hc_div">
               { loading ? 
                (
                  <span className="hc_form_search">Buscando ...</span>
                ) : (          
                  <IconButton color="primary"  className="hc_form_search" aria-label="search" onClick={() => {getFilter( seachOption , searchInput  , searchStatus); setSearchInput('')}}>
                    <SearchIcon />
                  </IconButton>
                )} 
              </div>
            </form>
          </Grid>
          
      </Grid>
      
    
    
  );
};


HCForm.defaultProps = {
  search: '',
  status: ''
};

export default HCForm;
