import React, { useState, useEffect } from 'react';
import '../../theme/headcount.scss';

import { API, graphqlOperation } from 'aws-amplify';
import Link from '@material-ui/core/Link';

//Import GRAPHQL FUNCTION

import {
  listHeadcountReports,
  getHeadcountReport,
  listIncidentObservations,
  listIncidentPlanActions,
  listHeadcountVacantLogs,
  listWorkTeamTemplates,
  listWarehouses,
  listTeamPositions,
  reportLogsByCalculationDate,
  listParams,
  listWarehouseAttendants,
  listAttendants
} from '../../graphql/queries';

// import { 
//   listHeadcountReports,
//   getHeadcountReport
// } from './graphqlQ/queries';

//Material 
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import HCTable from '../../components/HCTable';
import HCForm from '../../components/HCForm';
import HCDrawer from '../../components/HCDrawer';
import HCChart from '../../components/HCChart';
import HCStatus from '../../components/HCStatus';
import HCMaterialTable from '../../components/HCMaterialTable';

//HELPERS SEARCH FILTER FORM 
import { search, status, columns } from '../../helpers/headcount';
import { HeadcountStatusType } from '../../schema/enums/schemaEnums';

// API
import { api } from '../../api/api'
import { updateHeadcountVacantLog } from '../../graphql/mutations';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LinearProgress } from '@material-ui/core';
import { listAttendantsLite } from '../../graphql/bamaQueries';

function Headcount(props) {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataHeadCount, setDataHeadCount] = useState([]);
  const [detailReport, setDetailReport] = useState();
  const [dataChart, setDataChart] = useState([]);
  const [observations, setObservations] = useState(null);
  const [planActions, setPlanActions] = useState(null);
  const [warehouses, setWarehouses] = useState(null)
  const [workTeamTemplates, setWorkTeamTemplates] = useState(null)
  const [teamPositions, setTeamPositions] = useState(null)
  const [vacantLogs, setVacantLogs] = useState([])
  const [attendants, setAttendants] = useState(null)
  const [calculationDate, setCalculationDate] = useState(null)
  const [headcountVacantSemaphore, setHeadcountVacantSemaphore] = useState(null)
  const [globalVacants, setGlobalVacants] = useState(null)
  const [totalVacantCount, setTotalVacantCount] = useState({})
  const [history, setHistory] = useState(null)
  const [historyDataKey, setDataKey] = useState(null)
  const [warehouseAttendants, setWarehouseAttendans] = useState(null)

  const columns = [
    {
      field: 'warehouse.organization.organizationName', title: 'Empresa',
      render: row => row.warehouse.organization.organizationName
    },
    {
      field: 'warehouse.warehouseId', title: 'Clave Sucursal',
      render: row => row.warehouse.warehouseId
    },
    {
      field: 'warehouse.warehouseName', title: 'Sucursal',
      render: row => row.warehouse.warehouseName
    },
    {
      field: 'warehouse.zone.zoneName', title: 'Zona',
      render: row => row.warehouse && row.warehouse.zone ? row.warehouse.zone.zoneName : ''
    },
    {
      field: 'workTeamTemplate.workTeamTemplate', title: 'Tipo',
      render: row => row.workTeamTemplate ? row.workTeamTemplate.workTeamTemplate : ''
    },
    {
      field: 'teamPositionsCount', title: 'Plazas',
      render: row => row.teamPositionsCount,
    },
    {
      field: 'teamAssignedCount', title: 'Asignado',
      render: row => row.teamAssignedCount.length
    },
    { field: 'vacants', title: 'Vacante' },
    { field: 'extras', title: 'Excedentes' },
    {
      field: 'rowData.statusName',
      title: 'Estatus',
      render: rowData => <div>{rowData.statusName ? <HCStatus status={rowData.statusName} /> : <span>Status not ready yet</span>}</div>,
    },
    {
      field: '',
      title: 'Acciones',
      render: rowData => <Link href="#" onClick={() => showInfo(rowData)}>Más Info</Link>,
    },
  ];

  useEffect(() => {
    if (!headcountVacantSemaphore)
      api(listParams, { filter: { param: { eq: "headcountVacantSemaphore" } } }).then(p => setHeadcountVacantSemaphore(JSON.parse(p[0].value)))

    if (!warehouses)
      api(listWarehouses).then(w => setWarehouses(w))

    if (!attendants)
      api(listAttendants).then(w => setAttendants(w))

    if (!warehouseAttendants)
      api(listWarehouseAttendants).then(w => setWarehouseAttendans(w))

    if (!workTeamTemplates)
      api(listWorkTeamTemplates).then(w => setWorkTeamTemplates(w))

    if (!teamPositions)
      api(listTeamPositions).then(w => setTeamPositions(w))

    if (warehouses && workTeamTemplates && listTeamPositions && dataHeadCount.length === 0 && attendants && warehouseAttendants)
      API.graphql(graphqlOperation(listHeadcountReports, { limit: 200 }))
        .then(resp => {
          const { items } = resp.data.listHeadcountReports;

          setDataHeadCount(buildWarehouseHeadcount(items));
          buildVacants(items)

          let h = 0, m = 0, l = 0;

          h = items.filter(r => r.statusName === HeadcountStatusType.HIGH)
          m = items.filter(r => r.statusName === HeadcountStatusType.MEDIUM)
          l = items.filter(r => r.statusName === HeadcountStatusType.LOW)

          console.log(h, m, l)

          setDataChart([
            { name: "Nivel Alto", alto: h.length },
            { name: "Nivel Medio", medio: m.length },
            { name: "Nivel Bajo", bajo: l.length }
          ]);

          setLoading(false)
        })
        .catch(err => console.log(err));

    if (!observations)
      api(listIncidentObservations, {
        filter: {
          incidentObservationName: {
            eq: "Cloud Incident Observations"
          }
        }
      }).then(cio => {
        cio.forEach(_cio => {
          api(listIncidentObservations, {
            filter: {
              parentIncidentObservationId: {
                eq: _cio.id
              }
            }
          }).then(io => {
            console.log(io)
            setObservations(io)
          })
        })
      })

    if (!planActions)
      api(listIncidentPlanActions).then(pa => {
        console.log(pa)
        setPlanActions(pa)
      })

    if (warehouses && listTeamPositions && vacantLogs.length > 0 && headcountVacantSemaphore)
      buildGlobalVacants(vacantLogs)
  }, [warehouses, workTeamTemplates, teamPositions, attendants, vacantLogs, warehouseAttendants]);


  const getFilter = (searchOption, searchText, searchStatus) => {
    setLoading(true);
    let objFilter = {};

    switch (searchOption) {
      case "warehouseId":
        objFilter = {
          [searchOption]: {
            contains: searchText
          }
        };
        break;
      case "warehouseName":
        objFilter = {
          [searchOption]: {
            contains: searchText
          }
        };
        break;
      case "vacants":
        objFilter = {
          [searchOption]: {
            eq: searchText
          }
        };
        break;
      case "statusName":
        objFilter = {
          [searchOption]: {
            eq: searchStatus
          }
        };
        break;
      case "workTeamTemplateName":
        objFilter = {
          [searchOption]: {
            contains: searchText
          }
        };
        break;
      default:
    }
    API.graphql(graphqlOperation(listHeadcountReports, {
      filter: objFilter,
      limit: 200
    }))
      .then(resp => {
        const { items } = resp.data.listHeadcountReports;
        setDataHeadCount(buildWarehouseHeadcount(items));
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setDataHeadCount([]);
        setLoading(false);
      });
  }

  const buildWarehouseHeadcount = listWarehouses => {
    let _warehouses = [], _totalVacantCount = {}

    listWarehouses.forEach(w => {
      let _w = warehouses.find(warehouse => warehouse.id === w.warehouse.id)
      console.log(_w)
      let _wtt = null
      let _teamPositionsCount = 0
      let _tp = null
      try {
        if (_w && _w.workTeamTemplate)
          _wtt = workTeamTemplates.find(wtt => wtt.id === _w.workTeamTemplate.id)


        if (_wtt)
          _tp = teamPositions.filter(tp => tp.workTeamTemplate.id === _wtt.id)


        if (_tp)
          _tp.forEach(tp => {
            if (tp.required)
              _teamPositionsCount += tp.quantity
          })
      } catch (error) {

      }

      let teamAssignedCount = []
      /*let _workTeamTemplate = warehouseAttendants.filter(w => w.warehouseId === _w.id)
      _workTeamTemplate.forEach(w => {
        teamAssignedCount = teamAssignedCount.concat(attendants.filter(a => a.id === w.attendantId && a.active))
      })*/
      teamAssignedCount = attendants.filter(a => a.warehouse && a.warehouse.id === _w.id && a.active)

      _warehouses.push({
        ...w,
        warehouse: _w,
        workTeamTemplate: {
          ..._wtt,
          teamPositions: _tp
        },
        teamPositionsCount: _teamPositionsCount,
        teamAssignedCount: teamAssignedCount,
        extras: w.vacants === 0 && teamAssignedCount.length > _teamPositionsCount ? (teamAssignedCount.length - _teamPositionsCount) : 0
      })

      if (!(_w.organization.id in _totalVacantCount)) {
        _totalVacantCount[_w.organization.id] = _w.organization
        _totalVacantCount[_w.organization.id].vacants = 0
        _totalVacantCount[_w.organization.id].teamPositionsCount = 0
        _totalVacantCount[_w.organization.id].teamAssignedCount = 0
      }

      _totalVacantCount[_w.organization.id].vacants = _totalVacantCount[_w.organization.id].vacants + w.vacants
      _totalVacantCount[_w.organization.id].teamPositionsCount = _totalVacantCount[_w.organization.id].teamPositionsCount + _teamPositionsCount
      _totalVacantCount[_w.organization.id].teamAssignedCount = _totalVacantCount[_w.organization.id].teamAssignedCount + teamAssignedCount
    })

    setTotalVacantCount(_totalVacantCount)
    // console.log(_warehouses)
    return _warehouses
  }


  const buildVacants = async (reports) => {
    let calculationDate = new Date(new Date().getTime() - 1000 * 60 * 60 * 24).toISOString().split('T')[0]
    let v = []
    setCalculationDate(calculationDate)
    reports.forEach((r, idx) => {
      if (r.vacants > 0) {
        console.log(r)
        let call = api(reportLogsByCalculationDate, {
          reportId: r.id,
          calculationDate: {
            eq: calculationDate
          }
        })

        v.push(call)
      }
    })

    Promise.all(v).then(values => {
      let responses = []
      values.forEach(v => {
        responses = responses.concat(v)
      })

      console.log(responses)
      setVacantLogs(responses)
    })
    return
  }

  const buildGlobalVacants = (vacants) => {
    console.log("buildGlobalVacants", vacants)
    let _globalVacants = {}
    vacants.forEach(w => {
      let d = w.detail.filter(d => d.date === calculationDate)
      let _w = warehouses.find(warehouse => warehouse.warehouseId === w.report.warehouseId)

      if (!(_w.organization.id in _globalVacants))
        _globalVacants[_w.organization.id] = {}

      if (!('stores' in _globalVacants[_w.organization.id]))
        _globalVacants[_w.organization.id].stores = []
      if (!('vacants' in _globalVacants[_w.organization.id]))
        _globalVacants[_w.organization.id].vacants = {}

      let store = _globalVacants[_w.organization.id].stores.find(s => s.id === _w.id)
      if (!store)
        _globalVacants[_w.organization.id].stores.push(_w)

      d.forEach(_d => {
        if (_d.count > 0) {
          let position = _globalVacants[_w.organization.id].vacants[_d.id]
          if (!position)
            _globalVacants[_w.organization.id].vacants[_d.id] = { id: _d.id, count: _d.count, positionName: _d.positionName }
          else
            _globalVacants[_w.organization.id].vacants[_d.id].count = position.count + _d.count
        }
      })
    })

    setGlobalVacants(_globalVacants)
    return
  }

  // DRAWER MOORE INFO ABOUT REPORT
  const showInfo = (data) => {
    console.log(data)
    setOpen(true);
    setLoading(true);
    API.graphql(graphqlOperation(getHeadcountReport, { id: data.id }))
      .then(resp => {
        const { getHeadcountReport } = resp.data;
        api(listHeadcountVacantLogs, {
          filter: {
            reportId: {
              eq: data.id
            }
          }
        }).then(vl => {
          console.log(vl)

          let _hist = []
          let _dataKey = [{ dataKey: "vacants" }]


          vl.forEach(_l => {
            _hist.push({
              calculationDate: _l.calculationDate,
              vacants: _l.vacantCounter
            })
          })

          setHistory(_hist.sort((a, b) => {
            if (a.calculationDate < b.calculationDate) { return -1; }
            if (a.calculationDate > b.calculationDate) { return 1; }
            return 0;
          }))
          setDataKey(_dataKey)

          setDetailReport({
            ...data,
            ...getHeadcountReport,
            vacantsLogs: {
              items: vl.sort((a, b) => { return a.sinceDate <= b.sinceDate })
            }
          });
          setLoading(false);
        })
      })
      .catch(err => {
        console.log(err)
        setLoading(false);
      });
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open)
  };


  const updateHeadcountVacantLogs = async (hcvl) => {
    let updateHCVL = {
      id: hcvl.id
    }

    if (hcvl.headcountVacantLogIncidentObservationId)
      updateHCVL.headcountVacantLogIncidentObservationId = hcvl.headcountVacantLogIncidentObservationId

    if (hcvl.headcountVacantLogIncidentPlanActionId)
      updateHCVL.headcountVacantLogIncidentPlanActionId = hcvl.headcountVacantLogIncidentPlanActionId

    api(updateHeadcountVacantLog, { input: updateHCVL }).then(uhcvl => {
      console.log("updateHeadcountVacantLog", uhcvl)
    })
    return
  }

  return (
    <div style={{ width: '100%' }}>
      {loading ? <LinearProgress color="secondary" /> : null}
      <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
        <Box p={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Grid
                direction="column"
                container spacing={3}>
                <Grid item xs={12}>
                  <h2>Head Count</h2>
                  {/*<HCForm
                    getFilter={getFilter}
                    loading={loading}
                    search={search}
                    status={status}
                  />*/}
                </Grid>
                <Grid xs={12}>
                  <Grid
                    container spacing={3}>
                    {totalVacantCount && Object.keys(totalVacantCount).map(t => {
                      try {
                        let vacantsOrganization = totalVacantCount[t]
                        let param = headcountVacantSemaphore[vacantsOrganization.id]
                        if (globalVacants && vacantsOrganization.id in globalVacants) {
                          let positions = globalVacants[vacantsOrganization.id].vacants
                          let stores = globalVacants[vacantsOrganization.id].stores
                          let alert = "info"
                          Object.keys(param).forEach(p => {
                            let percent = (vacantsOrganization.teamPositionsCount / vacantsOrganization.vacants)
                            if (percent >= param[p][0] && percent <= param[p][1])
                              alert = p
                          })
                          return <Grid item xs={6}>
                            <Alert severity={alert}>
                              <AlertTitle>{vacantsOrganization.organizationName}</AlertTitle>
                              <Grid container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={3}>
                                <Grid item>
                                  {`Plantilla: `} <strong>{vacantsOrganization.teamPositionsCount}</strong>
                                </Grid>
                                <Grid item>
                                  {`Vacantes: `} <strong>{vacantsOrganization.vacants}</strong>
                                </Grid>
                                <Grid item>
                                  {`Posiciones Vacantes`}
                                  <Grid container
                                    justify="space-evenly"
                                    alignItems="center"
                                    spacing={3}>
                                    {positions && Object.keys(positions).map(p => {
                                      return <Grid item xs={10}>
                                        <strong>{positions[p].count}</strong> {props.translate(positions[p].positionName)}
                                      </Grid>
                                    })}
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  {`Sucursales`}
                                  <Grid container
                                    justify="center"
                                    alignItems="center"
                                    spacing={3}>
                                    {stores && stores.map(s => {
                                      return <Grid item>
                                        {s.warehouseId}
                                      </Grid>
                                    })}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Alert>
                          </Grid>
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <h3>Gráfica de Estatus de Sucursales</h3>
              <HCChart
                dataReports={dataChart}
              />
            </Grid>
            <Grid item xs={12}>
              <HCMaterialTable
                title={"Reportes"}
                columns={columns}
                data={dataHeadCount}
                translate={props.translate}
              >
              </HCMaterialTable>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <HCDrawer
        open={open}
        loading={loading}
        toggleDrawer={toggleDrawer}
        detailReport={detailReport}
        planActions={planActions}
        observations={observations}
        history={history}
        dataKey={historyDataKey}
        updateHeadcountVacantLogs={updateHeadcountVacantLogs}
        translate={props.translate}
      />
    </div>
  );
}

export default Headcount;

