import {
    NAVIGATION,
    USER_SIGNIN
} from '../ActionTypes';

export const Navbar_onSystemMenuUpdate = data => ({
    type: NAVIGATION.SYSTEM_MENU_UPDATE,
    data
});

export const Navbar_onNotFound = data => ({
    type: NAVIGATION.NOT_FOUND,
    data
});

export const Navbar_onProfileMenu = data => ({
    type: NAVIGATION.PROFILE_MENU,
    data
});

export const Navbar_onSystemMenu = data => ({
    type: NAVIGATION.SYSTEM_MENU,
    data
});

export const Navbar_onNavigationMenu = data => ({
    type: NAVIGATION.NAVIGATION_MENU,
    data
});

export const Navbar_onNoPermission = data => ({
    type: NAVIGATION.NO_PERMISSION,
    data
});

export const Navbar_logOut = () => ({
    type: USER_SIGNIN.LOGOUT
});