import React, { useState, useEffect } from 'react';
import { Slide, Dialog, Toolbar, IconButton, Typography, Container, AppBar, makeStyles, Grid, Box, Paper, Button, LinearProgress, ButtonGroup, TextField, InputAdornment } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import DialpadIcon from '@material-ui/icons/Dialpad';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));


export default function EditUserModal(props) {
    const [user, setUser] = useState(props.user)
    const [sending, setSending] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        if (user !== props.user)
            setUser(props.user)
        return function cleanup() {

        };
    }, [
        props.user
    ])

    return <Dialog fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}>
        <AppBar
            color="secondary"
            className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {`${props.translate('Edit')} ${props.translate('User')} ${user && user.userName ? user.userName : props.translate("New")}`}
                </Typography>
            </Toolbar>
        </AppBar>
        {sending && <LinearProgress color="primary" />}
        <Container fixed>
            <Box my={2}>
                <Grid container spacing={1}
                    alignItems="center"
                    justify="center">
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            fullWidth
                            autoFocus
                            disabled={sending}
                            variant="outlined"
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    // getCustomer(searchText);
                                }
                            }}
                            onChange={(event) => {
                                setUser({
                                    ...user,
                                    attendantId: event.target.value
                                })
                            }}
                            label={`${props.translate("Id")} ERP`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            fullWidth
                            disabled={sending}
                            variant="outlined"
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    // getCustomer(searchText);
                                }
                            }}
                            onChange={(event) => {
                                setUser({
                                    ...user,
                                    userName: event.target.value
                                })
                            }}
                            label={props.translate("Username")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            fullWidth
                            disabled={sending}
                            variant="outlined"
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    // getCustomer(searchText);
                                }
                            }}
                            onChange={(event) => {
                                setUser({
                                    ...user,
                                    fullName: event.target.value
                                })
                            }}
                            label={props.translate("Full Name")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            type="number"
                            fullWidth
                            disabled={sending}
                            variant="outlined"
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    // getCustomer(searchText);
                                }
                            }}
                            onChange={(event) => {
                                setUser({
                                    ...user,
                                    nipPassword: event.target.value
                                })
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <DialpadIcon fontSize="large" color="action" />
                                    </InputAdornment>
                                )
                            }}
                            label={props.translate("Nip Password")}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            type="text"
                            fullWidth
                            disabled={sending}
                            variant="outlined"
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    // getCustomer(searchText);
                                }
                            }}
                            onChange={(event) => {
                                setUser({
                                    ...user,
                                    hashPassword: event.target.value
                                })
                            }}
                            label={props.translate("Password")}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            type="password"
                            fullWidth
                            disabled={sending}
                            variant="outlined"
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    // getCustomer(searchText);
                                }
                            }}
                            onChange={(event) => {
                                setUser({
                                    ...user,
                                    hashPassword: event.target.value
                                })
                            }}
                            label={props.translate("New Password")}
                        />
                    </Grid>
                </Grid>



                {/*
  id: ID!
  
  profilePhoto: String
  limitEpoch: AWSTimestamp
  managerAttendantId: ID
  active: Boolean
  sales: [Sale] @connection(name: "AttendantSales")
  orders: [Order] @connection(name: "AttendantOrders")
  warehouse: Warehouse @connection(name: "AttendantWarehouses")
  roles: [Role] @connection(name: "AttendantRoles")
  phones: [Phone]
  emails: [Email]
  fingers: [Finger]
  position: Position @connection(name: "PositionAttendants")
  cashRegisterShifts: [CashRegisterShift] @connection(name: "AttendantCashRegisterShifts")
  cashDrawers: [CashDrawer] @connection(name: "CashDrawerAttendants")
  kardexs: [Kardex] @connection(name: "AttendantKardexs")
  incidents: Incident @connection(name: "AttendantIncidents")
  discounts: [Discount] @connection(name: "AttendantDiscounts")
  attendanceRecords: [AttendanceRecord] @connection(name: "AttendantAttendanceRecords")
  xadisEmptyContainers: [XadisEmptyContainer] @connection(name: "AttendantXadisEmptyContainers")
  color: String
                */}
            </Box>
        </Container>
    </Dialog>
}