import React from 'react';
import SessionControl from '../../utils/SessionControl';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import DialpadIcon from '@material-ui/icons/Dialpad';

class ForgotPasswordForm extends SessionControl {

    constructor(props) {
        super(props);
        this.state = {
            username: !props.user ? '' : props.user.username,
            code: '',
            password: '',
            isReady: false,
            forgotRequested: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getDisabled() {
        let disabled = false;
        if (!this.state.forgotRequested) {
            if (!this.state.username || this.state.username.length <= 4)
                disabled = true;
        } else {
            if (!this.state.password || this.state.password.length <= 5)
                disabled = true;
            if (!this.state.code || this.state.code.length <= 4)
                disabled = true;
        }
        return disabled;
    }

    handleSubmit(event) {
        const { password, code, username } = this.state;
        if (!this.state.forgotRequested) {
            this.props.handleRequest({ username });
            this.setState({ forgotRequested: true });
        } else {
            this.props.handleSubmit({ username, password, code });
        }

        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Box
                    py={2}>
                    <Box
                        display="flex"
                        justifyContent='center'
                        flexDirection="column"
                        alignItems="center"
                        py={2}>
                        <Box>
                            {this.translate(this.state.forgotRequested ? "Reseting password" : "Reset Password")}
                        </Box>
                        {this.state.forgotRequested ?
                            <Box>
                                <h6>
                                    {this.state.username}
                                </h6>
                            </Box>
                            : null}
                    </Box>
                    <Box
                        py={2}>
                        {!this.state.forgotRequested ?
                            <TextField
                                label={this.translate('User')}
                                variant="outlined"
                                required
                                fullWidth
                                type="text"
                                onChange={(username) => this.setState({ username: username.target.value })}
                                value={this.state.username}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon color="action" />
                                        </InputAdornment>
                                    ),
                                }} />
                            : <TextField
                                name="password"
                                label={this.translate('New Password')}
                                variant="outlined"
                                required
                                fullWidth
                                type="password"
                                onChange={(password) => this.setState({ password: password.target.value })}
                                value={this.state.password}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon color="action" />
                                        </InputAdornment>
                                    ),
                                }} />
                        }
                    </Box>
                    {this.state.forgotRequested ?
                        <Box
                            py={2}>
                            <TextField
                                name="code"
                                label={this.translate('Code')}
                                variant="outlined"
                                required
                                fullWidth
                                type="number"
                                onChange={(code) => this.setState({ code: code.target.value })}
                                value={this.state.code}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <DialpadIcon color="action" />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Box>
                        : null}
                    <Box
                        pt={1}
                        display="flex"
                        justifyContent='center'
                        alignItems='stretch'>
                        <Button
                            type="submit"
                            disabled={this.getDisabled()}
                            variant="contained"
                            color="primary">
                            {this.translate("Reset Password")}
                        </Button>
                    </Box>
                </Box>
            </form>
        );
    }
}

export default ForgotPasswordForm;