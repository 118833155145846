// User SignIn
export const USER_SIGNIN = {
    IN_PROGRESS: 'USER_SIGNIN_IN_PROGRESS',
    SUCCESS: 'USER_SIGNIN_SUCCESS',
    COGNITO_USER: 'COGNITO_USER',
    ERROR: 'USER_SIGNIN_ERROR',
    LOGOUT: 'USER_SIGNIN_LOGOUT',
    POSITION: 'POSITION',
    SYSTEM_ROLE: "SYSTEM_ROLE"
};

// User Profile
export const USER_PROFILE = {
    IN_PROGRESS: 'USER_PROFILE_IN_PROGRESS',
    SUCCESS: 'USER_PROFILE_SUCCESS',
    ERROR: 'USER_PROFILE_ERROR'
};

// Languaje Management
export const LANGUAJE = {
    IN_PROGRESS: 'LANGUAJE_IN_PROGRESS',
    SUCCESS: 'LANGUAJE_SUCCESS',
    ERROR: 'LANGUAJE_ERROR'
}

// Navbar
export const NAVIGATION = {
    SYSTEM_MENU_UPDATE: 'NAVIGATION_SYSTEM_MENU_UPDATE',
    NOT_FOUND: 'NAVIGATION_NOT_FOUND',
    PROFILE_MENU: 'NAVIGATION_PROFILE_MENU',
    SYSTEM_MENU: 'NAVIGATION_SYSTEM_MENU',
    NAVIGATION_MENU: 'NAVIGATION_MENU',
    NO_PERMISSION: 'NAVIGATION_NO_PERMISSION',
    ATTENDANTS: 'ATTENDANTS',
    WAREHOUSE: 'WAREHOUSE',
    HOSTNAME_ID: 'HOSTNAME_ID',
    PRODUCTS: 'PRODUCTS',
    PRODUCT_KITS: 'PRODUCT_KITS',
    POSITIONS: 'POSITIONS',
    SYNCSHEME: 'SYNCSHEME',
    CASHDRAWER: 'CASHDRAWER',
    SERVICE_INTEGRATION: 'SERVICE_INTEGRATION',
    SCHEDULES: 'SCHEDULES'
}

// Variable Commissions
export const VARIABLE_COMMISSIONS = {
    ORGANIZATION: 'ORGANIZATION',
    DATA_SOURCE: 'DATA_SOURCE',
    VARIABLE: 'VARIABLE',
    AREA: 'AREA',
    INDICATOR: 'INDICATOR',
    COMMISSION: 'COMMISSION'
}