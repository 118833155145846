import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Skeleton from '@material-ui/lab/Skeleton';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Box, Zoom, ListItemIcon, ListItemText, Menu, MenuItem, Dialog, AppBar, Toolbar, IconButton, Typography, Button, Container, Slide, Grid } from '@material-ui/core';
import esLocale from "date-fns/locale/es";

import Alert from '@material-ui/lab/Alert';
import { SNACKBAR_ERROR } from '../../utils/SessionControl';

import MaterialTable from 'material-table';
import { tableIcons, localization } from '../materialTable/MaterialTable';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function HistoricalEmptyContainers(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [xadisEmptyContainers, setXadisEmptyContainers] = useState(null)
    const [rows, setRows] = useState(null)
    const [columns, setColumns] = useState([
        { title: props.translate("NomArti"), field: "description" },
        { title: props.translate("Sku"), field: "sku" },
        { title: props.translate("Sap"), field: "sap" },
    ])
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setLoading(false)
        setXadisEmptyContainers(null)
        setSelectedDate(new Date())
    };

    const handleClick = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (xadisEmptyContainers !== props.selectedXadisEmptyContainers) {
            console.log(props.selectedXadisEmptyContainers)
            if (props.selectedXadisEmptyContainers)
                buildHistoricalTable(props.selectedXadisEmptyContainers)
            setLoading(false)
        }
        return function cleanup() {

        };
    }, [selectedDate, xadisEmptyContainers, loading, props.selectedXadisEmptyContainers]); // Only re-run the effect if name or email change

    const handleDateChange = (date) => {
        setSelectedDate(date);
    }

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
        setLoading(true);
        props.loadHistorical(selectedDate, date)
    }

    const buildHistoricalTable = (rows) => {
        console.log(rows)
        setXadisEmptyContainers(rows)

        var _rows = []
        var _columns = columns
        rows.forEach(item => {
            var _title = new Date(item.startDate * 1000).toLocaleDateString()
            var _field = String(item.startDate)

            var existDate = false;

            _columns.forEach(_r => {
                if (_r.field === _field)
                    existDate = true;
            })

            if (!existDate) {
                _columns.push({
                    title: _title,
                    field: _field
                })
            }

            item.emptyContainers.forEach(r => {
                var existRow = false;
                _rows.forEach(_r => {
                    if (r.sku === _r.sku) {
                        existRow = true
                        _r[_field] = r.quantity
                    }
                })

                if (!existRow) {
                    var description = props.productEmpty.filter(
                        (product) =>
                            product.sku.includes(r.sku)
                    )

                    var _newRow = {
                        sku: r.sku,
                        sap: r.sap,
                        description: description[0].description,
                    }

                    _newRow[_field] = r.quantity

                    _rows.push(_newRow)
                }
            })
        })

        setRows(_rows)
        setColumns(_columns)
        console.log(_rows)
        console.log(_columns)
    }
    return (
        <Box className={classes.root}>
            <Zoom in unmountOnExit>
                <Fab className={classes.fab} color="secondary"
                    onClick={handleClick}>
                    <DateRangeIcon />
                </Fab>
            </Zoom>
            <Dialog fullScreen
                open={open}
                onClose={handleClose}
                keepMounted
                TransitionComponent={Transition}>
                <AppBar
                    color="secondary"
                    className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {`${props.translate("Historical")} - ${props.translate("Empty Container")}`}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container>
                    <Box my={3}>
                        {loading ?
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                spacing={4}>
                                <Grid item>
                                    {props.translate("Loading")}
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Skeleton variant="text" width="50px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="120px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="80px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="40px" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Skeleton variant="text" width="50px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="120px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="80px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="40px" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Skeleton variant="text" width="50px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="120px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="80px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="40px" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Skeleton variant="text" width="50px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="120px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="80px" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width="40px" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                spacing={4}>
                                <Grid item>
                                    {props.translate("Select a Day")}
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={4}>
                                        <Grid item>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                                locale={esLocale}>
                                                <DatePicker
                                                    value={selectedDate}
                                                    disableFuture
                                                    onChange={handleDateChange}
                                                    autoOk
                                                    minDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365)}
                                                    orientation="landscape"
                                                    variant="static" />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                                locale={esLocale}>
                                                <DatePicker
                                                    value={selectedEndDate}
                                                    disableFuture
                                                    onChange={handleEndDateChange}
                                                    autoOk
                                                    minDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365)}
                                                    orientation="landscape"
                                                    variant="static" />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Box my={3}>
                            {!loading && rows ?
                                <MaterialTable
                                    icons={tableIcons}
                                    localization={localization(props)}
                                    options={{
                                        exportButton: true
                                    }}
                                    columns={columns}
                                    data={rows}
                                    title={`${new Date(selectedDate).toLocaleDateString()} - ${new Date(selectedEndDate).toLocaleDateString()}`}
                                />
                                : <Alert variant="filled" severity={SNACKBAR_ERROR}>
                                    {props.translate("No records to display")}
                                </Alert>}
                        </Box>
                    </Box>
                </Container>
            </Dialog>
        </Box>
    );
}
