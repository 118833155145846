import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getNotificationState = state => get(state, 'notification');

export const isNotificationShow = createSelector(
  [getNotificationState],
  notification => get(notification, 'show'),
);

export const getNotificationType = createSelector(
  [getNotificationState],
  notification => get(notification, 'type'),
);

export const getNotificationMessage = createSelector(
  [getNotificationState],
  notification => get(notification, 'message'),
);
