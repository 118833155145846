import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


const HCChart = ({dataReports}) => {
  // console.log(dataReports)

  const ChartHC = ({data}) => {
    return (
      <BarChart width={600} height={300} data={data}
        margin={{top: 20, right: 30, left: 20, bottom: 5}}>
        <CartesianGrid strokeDasharray="1 1"/>
        <XAxis dataKey="name"/>
        <YAxis/>
        <Tooltip/>
        <Legend />
        <Bar dataKey="alto" stackId="a" fill="#f50057" />
        <Bar dataKey="medio" stackId="a" fill="#ffeb3b" />
        <Bar dataKey="bajo" stackId="a" fill="#4caf50" />
      </BarChart>
    )      
  }

  return (
    <div>
      <ChartHC
        data={dataReports}  
      ></ChartHC>
    </div>
  );

};

HCChart.propTypes = {
  dataReports: PropTypes.array.isRequired,
};

HCChart.defaultProps = {
  
};

export default HCChart;
