import React from 'react';
import { Box, Grid, FormControlLabel, Checkbox, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NoticePrivacy from '../../../screens/legal/tiendasBama/NoticePrivacy';
import TermsAndConditions from '../../../screens/legal/tiendasBama/TermsAndConditions';
import { LegalType } from '../../../schema/enums/schemaEnums';

function RegistryConfirmation(props) {
    const checkLegalChange = (event, legalType) => {
        if (event.target.checked !== undefined) {
            let _legals = props.customer.legals

            if (!_legals)
                _legals = []

            let exist = false;
            _legals && _legals.forEach(l => {
                if (l.legalType === legalType) {
                    l.datetime = event.target.checked ? parseInt(new Date().getTime() / 1000) : 0
                    exist = true
                }
            })

            if (!exist)
                _legals.push({ legalType: legalType, datetime: event.target.checked ? parseInt(new Date().getTime() / 1000) : 0 })

            props.setCustomer({ ...props.customer, legals: _legals })
        }
        event.stopPropagation()
    }
    return (<Box my={2}>
        <Grid container alignItems="center" justify="center" spacing={2} direction="row">
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-label="Expand" >
                        <FormControlLabel onClick={(event) => checkLegalChange(event, LegalType.TERMS_AND_CONDITIONS)} onFocus={(event) => event.stopPropagation()} control={<Checkbox onChange={event => checkLegalChange(event, LegalType.TERMS_AND_CONDITIONS)} />} label={props.translate("Acept Terms and Conditions")} />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <TermsAndConditions />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                        <FormControlLabel onClick={(event) => checkLegalChange(event, LegalType.NOTICE_PRIVACY)} onFocus={(event) => event.stopPropagation()} control={<Checkbox onChange={event => checkLegalChange(event, LegalType.NOTICE_PRIVACY)} />} label={props.translate("Acept Notice Privacy")} />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <NoticePrivacy />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        </Grid>
    </Box>)
}

export default RegistryConfirmation;