import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


export default class Modal extends Component {

    render() {
        const { open, handleClose, title } = this.props;

        return (
            <Dialog fullScreen open={open} onClose={handleClose} closeAfterTransition>
                <Fade in={open}>
                    <div className={'dialog-content'}>
                        <h2 id="transition-modal-title">{title}</h2>
                        <Grid className={'btn-wrapper'} container spacing={3}>
                            <Grid item xs={12} >
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <Box>
                                        {this.props.children}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className={'btn accept-btn'}
                                    variant="contained"
                                    onClick={(event) => this.props.onContinue(event)}
                                    disabled={this.props.isContinueDisabled}
                                >
                                    Aceptar
                            </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className={'btn cancel-btn'} onClick={event => this.props.onCancel(event)}>
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Dialog>
        );
    }

}

export class Select extends React.Component {
    render() {
        return (
            (!this.props.loading) ? (
                <div className={'select-wrapper'}>
                    <Autocomplete
                        onChange={(event, value) => this.props.onChange(value)}
                        options={this.props.elements}
                        getOptionLabel={option => option.name}
                        style={{ width: 300 }}
                        noOptionsText={"No se encontraron elementos"}
                        renderInput={params => <TextField {...params} label={this.props.label} variant="outlined" style={{ width: 300 }} />}
                    />
                </div>
            ) : (<CircularProgress />)
        )
    }
}