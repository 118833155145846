import React, { Component } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  isNotificationShow,
  getNotificationType,
  getNotificationMessage,
} from '../../store/notification/selectors';
import { setNotificationShow } from '../../store/notification/actions';

export class Notification extends Component {
  render() {
    const { show, type, message, setShow } = this.props;

    return (
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => setShow(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={type}>{message}</Alert>
      </Snackbar>
    );
  }
}

const mapStateToProps = state => ({
  show: isNotificationShow(state),
  type: getNotificationType(state),
  message: getNotificationMessage(state),
});

const mapDispatchToProps = dispatch => ({
  setShow: payload => dispatch(setNotificationShow(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
