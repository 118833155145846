import { LANGUAJE } from '../ActionTypes.js'

const defaultState = {
  langugeTag: "es"
};

export default function LanguajeReducer(state = defaultState, action) {
  // console.log("LanguajeReducer", action);
  switch (action.type) {
    case LANGUAJE.IN_PROGRESS:
      return {
        ...state,
        data: action.data
      };
    case LANGUAJE.SUCCESS:
      return {
        ...state,
        data: action.data
      };
    case LANGUAJE.ERROR:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}