import { takeLatest, all, call, put } from 'redux-saga/effects';
import { FETCH_ORGANIZATIONS } from './constants';
import { setOrganizations, setOrganizationsError } from './actions';
import API from '../../services/api';

export function* fetchOrganizations() {
  try {
    const response = yield call(API.getOrganizations);
    const {
      data: { organization },
    } = response;
console.log("fetchOrganizations", organization)
    yield put(setOrganizations(organization));
  } catch (error) {
    yield put(setOrganizationsError(true));
  }
}

export default function* organizationsSaga() {
  yield all([takeLatest(FETCH_ORGANIZATIONS, fetchOrganizations)]);
}
