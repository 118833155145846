export const INITIAL_STATE = {
  name: '',
  description: '',
  parameters: [],
  steps: [[]],
  expression: '',
  tested: false,
  saved: false,
  sending: false,
  isDeleting: false,
};
