import React from 'react';
import { FormControl , FormGroup, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ModalCreate(props) {    

    const { open  , name, changeName , loading , onCloseModal  , sendForm , btnAct } = props;

    return (

        <div>
            <Dialog fullScreen open={open} closeAfterTransition>
            <Fade in={open}>
              <div className={'dialog-content'}>
                <h2 id="transition-modal-title">Crear Puesto nuevo:</h2>
                <Grid className={'btn-wrapper'} container spacing={3}>
                  <Grid className={'padmodal'} item xs={12}>
                    <FormControl>
                      <FormGroup>
                        <TextField 
                          className="inputSelect"
                          type="text"
                          name="name"
                          variant="outlined"
                          label="Nombre Puesto"
                          value= {name}
                          onChange={ e => changeName(e.target.value)}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {loading ? 
                      (
                        <CircularProgress />
                      ) :( 
                      <Button
                        className={'btn accept-btn'}
                        variant="contained"
                        onClick={() => {
                            sendForm(name);
                          }}
                        disabled={ btnAct && name.length >= 2 ? false : true}
                      >
                        Aceptar
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button className={'btn cancel-btn'} onClick={onCloseModal}>
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Fade>
          </Dialog>
        </div>
    );
}

