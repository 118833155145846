import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import {
    Grid,
    Typography,
    MenuItem,
    TextField,
  } from "@material-ui/core";
import { XadisStockType } from '../../schema/enums/schemaEnums';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
 
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const options = [XadisStockType.SELECTABLE, XadisStockType.FREETOUSE];


export default function Empty(props) {
  const classes = useStyles();
  const [product, setProduct] = React.useState(props.itemProduct); 
  const { sku,description,sap,stockType,boxProduct,stage } = props;
  const firstRender = useRef(true);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);


  useEffect(() => {
    if (firstRender.current) {
      if (firstRender.current) {
        firstRender.current = false; // it's no longer the first render

        if (stockType === XadisStockType.SELECTABLE) {
          setSelectedIndex(0);
        } else {
          setSelectedIndex(1);
        }
        return; // skip the code below
      }
    }
  }, []); // Only re-run the effect if name or email change


  const handleClick = () => {
   // console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  const handleChangeBox = productId => event => {
     setProduct({ ...product, [productId]: event.target.value }); 
     props.handleChangeBox(productId,event.target.value,product);
  };

  const handleChangeStock = productId => event => {
     setProduct({ ...product, [productId]: event.target.value }); 
     props.handleChangeStock(productId,event.target.value,product);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justify="center"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={2}>
          <Typography variant="subtitle2" align="center">
            {sku}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2" align="center">
            {description}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2" align="center">
            {sap}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            disabled={!Boolean(stage)}
            id="box_field"
            value={boxProduct}
            onChange={handleChangeBox("boxProduct")}
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={2}>
          {/* <TextField
            id="stock_field"
            select
            variant="outlined"
            fullWidth
            disabled={true}
            value={stockType}
            onChange={handleChangeStock("stockType")}
            >
            {Object.keys(XadisStockType).map((option) => {
              if (
                XadisStockType[option] === XadisStockType.SELECTABLE ||
                XadisStockType[option] === XadisStockType.FREETOUSE
              )
                return (
                  <MenuItem key={XadisStockType[option]} value={XadisStockType[option]}>
                    {props.translate(XadisStockType[option])}
                  </MenuItem>
                );
            })}
          </TextField> */}
          <ButtonGroup
            variant="contained"
            disabled={true}
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button onClick={handleClick}>{options[selectedIndex]}</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {/* {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))} */}
                      {Object.keys(XadisStockType).map((option, index) => {
                        if (
                          XadisStockType[option] ===XadisStockType.SELECTABLE ||
                          XadisStockType[option] === XadisStockType.FREETOUSE
                        ) {
                          return (
                            <MenuItem
                              key={XadisStockType[option]}
                              disabled={index === 2}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {XadisStockType[option]}
                            </MenuItem>
                          );
                        }
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </div>
  );
}
