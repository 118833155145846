import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import {
  FETCH_EMPLOYEES,
  SET_EMPLOYEES,
  SET_EMPLOYEES_ERROR,
} from './constants';

const fetchEmployees = (state, payload) => ({
  ...state,
  loading: true,
  error: false,
});

const setEmployees = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  data: action.payload,
});

const setEmployeesError = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

export const reducer = handleActions(
  {
    [FETCH_EMPLOYEES]: fetchEmployees,
    [SET_EMPLOYEES]: setEmployees,
    [SET_EMPLOYEES_ERROR]: setEmployeesError,
  },
  INITIAL_STATE,
);
