import React from 'react';
import SessionControl, { SNACKBAR_ERROR } from '../../utils/SessionControl';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import DialpadIcon from '@material-ui/icons/Dialpad';

class NewPasswordForm extends SessionControl {

    constructor(props) {
        super(props);
        this.state = {
            username: props.user && props.user.username,
            user: props.user,
            password: '',
            newPassword: '',
            isReady: false,
            newRequested: false,
            requiredAttributes: props.user && props.user.requiredAttributes
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getDisabled() {
        let disabled = false;
        if (!this.state.password || this.state.password.length <= 5)
            disabled = true;
        if (!this.state.newPassword || this.state.newPassword.length <= 5)
            disabled = true;

        if (this.state.requiredAttributes)
            this.state.requiredAttributes.forEach(req => {
                if (req === "phone_number") {
                    if (!this.state.phone_number || this.state.phone_number.length < 10)
                        disabled = true
                }
            })

        return disabled;
    }

    handleSubmit(event) {
        const { user, password, newPassword, phone_number } = this.state;

        // console.log(user.password, password, newPassword, phone_number)
        if (user.password !== password)
            this.props.showMessage(this.translate("Password Error"), SNACKBAR_ERROR, 5000)
        else {
            var _opt = {}

            if (phone_number)
                _opt = { phone_number: `+521${phone_number}` }

            this.props.handleSubmit(user, newPassword, _opt);
        }
        event.preventDefault();
    }

    render() {
        const { requiredAttributes } = this.state

        return (
            <form onSubmit={this.handleSubmit}>
                <Box
                    py={2}>
                    <Box
                        display="flex"
                        justifyContent='center'
                        flexDirection="column"
                        alignItems="center"
                        py={2}>
                        <Box>
                            {this.translate(this.state.newRequested ? "Reseting Password" : "New Password Required")}
                        </Box>
                        {this.state.newRequested ?
                            <Box>
                                <h6>
                                    {this.state.username}
                                </h6>
                            </Box>
                            : null}
                    </Box>
                    <Box
                        py={2}>
                        <TextField
                            name="password"
                            label={this.translate('Current Password')}
                            variant="outlined"
                            required
                            fullWidth
                            type="password"
                            onChange={(password) => this.setState({ password: password.target.value })}
                            value={this.state.password}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon color="action" />
                                    </InputAdornment>
                                ),
                            }} />
                    </Box>
                    <Box
                        py={2}>
                        <TextField
                            name="newPassword"
                            label={this.translate('New Password')}
                            variant="outlined"
                            required
                            fullWidth
                            type="password"
                            onChange={(newPassword) => this.setState({ newPassword: newPassword.target.value })}
                            value={this.state.newPassword}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon color="action" />
                                    </InputAdornment>
                                ),
                            }} />
                    </Box>
                    {requiredAttributes.map(req => <Box py={2}>
                        {req === "phone_number" ?
                            <TextField
                                name="phone_number"
                                label={this.translate('Phone Number')}
                                helperText={this.translate("Phone Number length")}
                                variant="outlined"
                                required
                                fullWidth
                                type="tel"
                                onChange={(phone_number) => this.setState({ phone_number: phone_number.target.value })}
                                value={this.state.phone_number}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <DialpadIcon color="action" />
                                        </InputAdornment>
                                    ),
                                }} />
                            : ''}
                        {/*JSON.stringify(req)*/}
                    </Box>)}
                    <Box
                        pt={1}
                        display="flex"
                        justifyContent='center'
                        alignItems='stretch'>
                        <Button
                            type="submit"
                            onClick={this.handleSubmit}
                            disabled={this.getDisabled()}
                            variant="contained"
                            color="primary">
                            {this.translate("Reset Password")}
                        </Button>
                    </Box>
                </Box>
            </form>
        );
    }
}

export default NewPasswordForm;