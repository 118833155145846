import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';

export const getKPIState = state => get(state, 'kpi');

export const getKPIName = createSelector([getKPIState], kpi =>
  get(kpi, 'name'),
);

export const getKPIOrganization = createSelector([getKPIState], kpi =>
  get(kpi, 'organization'),
);

export const getKPIArea = createSelector([getKPIState], kpi =>
  get(kpi, 'area'),
);

export const getKPITime = createSelector([getKPIState], kpi =>
  get(kpi, 'time'),
);

export const isKPIHaveStructure = createSelector(
  [getKPIName, getKPIOrganization, getKPIArea, getKPITime],
  (name, organization, area, time) =>
    !isEmpty(name) &&
    !isEmpty(organization) &&
    !isEmpty(area) &&
    !isEmpty(time),
);

export const getKPISteps = createSelector([getKPIState], kpi =>
  get(kpi, 'steps'),
);

export const getKPIDictionary = createSelector([getKPIState], kpi =>
  get(kpi, 'dictionary'),
);

export const getKPIActiveStep = createSelector(
  [getKPISteps],
  steps => steps.length - 1,
);

export const isKPITested = createSelector([getKPIState], kpi =>
  get(kpi, 'tested'),
);

export const isKPISaved = createSelector([getKPIState], kpi =>
  get(kpi, 'saved'),
);

export const isLastFunctionKPIValid = createSelector([getKPIState], kpi =>
  get(kpi, 'isLastFunctionValid'),
);

export const isKPIsending = createSelector([getKPIState], kpi =>
  get(kpi, 'sending'),
);

export const isKPIactive = createSelector([getKPIState], kpi =>
  get(kpi, 'active'),
);

export const isKPIdeleting = createSelector([getKPIState], kpi =>
  get(kpi, 'isDeleting'),
);

export const getNoexistIds = createSelector([getKPIState], kpi =>
  get(kpi, 'noExistingIds'),
);
