import React from 'react';
import StepItem from './StepItem';
import { last } from 'lodash';

import './styles.scss';

export default function StepsStack(props) {
  const getNextItem = step => {
    let item = '';
    const lastElement = last(step);

    if (typeof lastElement === 'undefined') {
      item = 'element';
    } else {
      switch (lastElement.type) {
        case 'element':
          if (lastElement.value.selected === 'function') {
            item = '';
          } else {
            item = 'operator';
          }
          break;
        case 'step':
          item = 'operator';
          break;
        case 'operator':
          item = 'element';
          break;
        default:
          item = '';
          break;
      }
    }

    return item;
  };

  return (
    <div className={'indicator-steps-stack'}>
      {props.steps.map((step, index) => (
        <StepItem
          type={props.type}
          key={index}
          step={index}
          elements={step}
          nextItem={getNextItem(step)}
          createStep={payload => props.createStep(payload)}
          deleteStep={payload => props.deleteStep(payload)}
          isLastStep={props.steps.length === index + 1}
          addElement={payload => props.addElement(payload)}
          activeStep={props.activeStep}
          lastStepItem={props.lastStepItem}
          isLastFunctionValid={payload => props.isLastFunctionValid(payload)}
          noExistElements={props.noExistElements}
        />
      ))}
    </div>
  );
}
