import { createAction } from 'redux-actions';
import {
  SET_INDICATOR_STRUCTURE,
  SET_INDICATOR_NAME,
  SET_INDICATOR_AREA,
  SET_INDICATOR_ORGANIZATION,
  SET_INDICATOR_DATASOURCE,
  FETCH_INDICATOR_VARIABLES,
  CREATE_INDICATOR_STEP,
  ADD_INDICATOR_ELEMENT,
  PUSH_ELEMENT_INTICATOR_STEP,
  PUSH_ELEMENT_INDICATOR_DICTIONARY,
  TEST_INDICATOR,
  SAVE_INDICATOR,
  SET_TEST_INDICATOR,
  SET_SAVE_INDICATOR,
  RESET_INDICATOR_DATA,
  ADD_INDICATOR_FUNCTION_ELEMENT,
  PUSH_FUNCTION_ELEMENT_INDICATOR,
  SET_LAST_FUNCTION_VALIDATION,
  SET_INDICATOR_SENDING,
  DELETE_INDICATOR_ELEMENT,
  POP_INDICATOR_ELEMENT,
  POP_DICTIONARY_ELEMENT_INDICATOR,
  EDIT_INDICATOR_ELEMENT,
  REPLACE_INDICATOR_ELEMENT,
  DELETE_INDICATOR_STEP,
  POP_FUNCTION_ARGUMENT_ELEMENT_INDICATOR,
  POP_ALL_FUNCTION_ARGUMENTS_INDICATOR_DICTIONARY,
  EDIT_INDICATOR,
  REHYDRATE_INDICATOR,
  SAVE_EDIT_INDICATOR,
  DELETE_INDICATOR,
  RESET_INDICATOR_DATASET,
  SET_LOADING_EDIT,
  SET_DELETING_INDICATOR,
  EDIT_INDICATOR_FUNCTION_ARGUMENT_ELEMENT,
  REPLACE_FUNCTION_ARGUMENT_ELEMENT,
  SET_NO_EXIST_IDS_INDICATOR,
} from './constants';

export const setIndicatorStructure = createAction(SET_INDICATOR_STRUCTURE);
export const setIndicatorName = createAction(SET_INDICATOR_NAME);
export const setIndicatorOrganization = createAction(
  SET_INDICATOR_ORGANIZATION,
);
export const setIndcatorDatasource = createAction(SET_INDICATOR_DATASOURCE);
export const setIndicatorArea = createAction(SET_INDICATOR_AREA);
export const fetchIndicatorVariables = createAction(FETCH_INDICATOR_VARIABLES);
export const createIndicatorStep = createAction(CREATE_INDICATOR_STEP);
export const addInicatorElement = createAction(ADD_INDICATOR_ELEMENT);
export const pushElementIndicatorStep = createAction(
  PUSH_ELEMENT_INTICATOR_STEP,
);
export const pushElementDictionaryIndicator = createAction(
  PUSH_ELEMENT_INDICATOR_DICTIONARY,
);
export const testIndicator = createAction(TEST_INDICATOR);
export const setTestIndicator = createAction(SET_TEST_INDICATOR);
export const saveIndicator = createAction(SAVE_INDICATOR);
export const setSaveIndicator = createAction(SET_SAVE_INDICATOR);
export const resetIndicatorData = createAction(RESET_INDICATOR_DATA);
export const addIndicatorElementFunction = createAction(
  ADD_INDICATOR_FUNCTION_ELEMENT,
);
export const pushFunctionElementIndicator = createAction(
  PUSH_FUNCTION_ELEMENT_INDICATOR,
);
export const setLastFunctionIndicatorValidation = createAction(
  SET_LAST_FUNCTION_VALIDATION,
);
export const setIndicatorSending = createAction(SET_INDICATOR_SENDING);
export const deleteIndicatorElement = createAction(DELETE_INDICATOR_ELEMENT);
export const popElementIndicator = createAction(POP_INDICATOR_ELEMENT);
export const popElementDictionaryIndicator = createAction(
  POP_DICTIONARY_ELEMENT_INDICATOR,
);
export const editIndicatorElement = createAction(EDIT_INDICATOR_ELEMENT);
export const replaceElementIndicator = createAction(REPLACE_INDICATOR_ELEMENT);
export const deleteIndicatorStep = createAction(DELETE_INDICATOR_STEP);
export const popFunctionArgumentElementIndicator = createAction(
  POP_FUNCTION_ARGUMENT_ELEMENT_INDICATOR,
);
export const popAllFunctionArgumentsIndicatorDictionary = createAction(
  POP_ALL_FUNCTION_ARGUMENTS_INDICATOR_DICTIONARY,
);
export const editIndicator = createAction(EDIT_INDICATOR);
export const rehydrateIndicator = createAction(REHYDRATE_INDICATOR);
export const saveEditIndicator = createAction(SAVE_EDIT_INDICATOR);
export const deleteIndicator = createAction(DELETE_INDICATOR);
export const resetIndicatorDataSet = createAction(RESET_INDICATOR_DATASET);
export const isLoadingEdit = createAction(SET_LOADING_EDIT);
export const setIndicatorDeleting = createAction(SET_DELETING_INDICATOR);
export const editIndicatorFunctionArgumentElement = createAction(
  EDIT_INDICATOR_FUNCTION_ARGUMENT_ELEMENT,
);
export const replaceFunctionElementIndicator = createAction(
  REPLACE_FUNCTION_ARGUMENT_ELEMENT,
);
export const setNoExistIdsIndicator = createAction(SET_NO_EXIST_IDS_INDICATOR);
