import React, { useRef, useEffect, useState } from 'react';

import { withSnackbar } from 'notistack';
import { Paper, Typography, Grid, Grow, Container, Button, Box, Tabs, Tab, makeStyles, LinearProgress } from '@material-ui/core';
import TabPanel from '../../components/tabPanel/TabPanel';
import { callApiPartial } from '../../api/api';
import { listSystemRoles, listSystemMenus, listSystemAttendantMenuScopes } from '../../graphql/queries';
import { SNACKBAR_ERROR, SNACKBAR_INFO } from '../../utils/SessionControl';
import SystemRole from '../../components/system/settings/role/SystemRole';
import SystemMenu from '../../components/system/settings/menu/SystemMenu';
import SystemScope from '../../components/system/users/scope/SystemScope';
import  { PlatformType } from '../../schema/enums/schemaEnums';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    progress: {
        width: `100%`
    }
}));

function Roles(props) {
    const classes = useStyles();
    const firstRender = useRef(true);
    const [searching, setSearching] = useState({
        roles: false,
        menus: false
    })
    const [selectedTab, setSelectedTab] = useState(0);
    const [roles, setRoles] = useState(null);
    const [menus, setMenus] = useState(null);
    const [filtermenus, setFilterMenus] = useState(null);
    const [typeMenu, setTypeMenu] = useState([])


    useEffect(() => {
        if (firstRender.current) {
            if (firstRender.current) {
                firstRender.current = false; // it's no longer the first render

                loadSystemRoles();
                loadSystemMenus();
                TypeMenu()
                return; // skip the code below
            }
        }


        if(menus.length > 0){
            let item =menus.filter((menu) =>menu.platformType.includes(typeMenu[0].toLowerCase()))
            setFilterMenus(item)
        }
        return function cleanup() {

        };
    }, [menus]);

    const loadSystemRoles = async () => {
        setSearching({
            ...searching,
            roles: true
        })
        callApiPartial(listSystemRoles, { limit: 300 }, [], setRoles).then(res => {
            setSearching({
                ...searching,
                roles: false
            })
            props.showMessage(`${res.length} ${props.translate("System Roles")} ${props.translate("Loaded")}`, SNACKBAR_INFO, 5000, null, null, null, null, null, null, props);
        }).catch(err => {
            setSearching({
                ...searching,
                roles: false
            })

            console.log(err);

            err && err.errors && err.errors.forEach(_error => {
                props.showMessage(_error.message, SNACKBAR_ERROR, 5000, null, null, null, null, null, null, props);
            })
        })
    }

    const loadSystemMenus = async () => {
        setSearching({
            ...searching,
            menus: true
        })
        callApiPartial(listSystemMenus, { limit: 300 }, [], setMenus).then(res => {
            setSearching({
                ...searching,
                menus: false
            })
            props.showMessage(`${res.length} ${props.translate("System Menus")} ${props.translate("Loaded")}`, SNACKBAR_INFO, 5000, null, null, null, null, null, null, props);
        }).catch(err => {
            setSearching({
                ...searching,
                menus: false
            })

            console.log(err);

            err && err.errors && err.errors.forEach(_error => {
                props.showMessage(_error.message, SNACKBAR_ERROR, 5000, null, null, null, null, null, null, props);
            })
        })
    }


 function TypeMenu() {
   let itEmpty = [];
   for (const Platform in PlatformType) {
     if (!Number(Platform)) {
       if (Platform === "POS" || Platform === "CLOUD") {
         itEmpty.push(Platform);
       }
     }
   }
   setTypeMenu(itEmpty);
   return itEmpty;
 }

 const handleChangeTab = (event, newValue) => {
   setSelectedTab(newValue);
 };


    return (
      <Grow in={true}>
        <Container fixed>
          <Box my={2}>
            <Typography variant="h5" color="textSecondary">
              {props.translate("System Settings")}
            </Typography>
          </Box>
          <Paper>
            <Box className={classes.root}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedTab}
                onChange={handleChangeTab}
                className={classes.tabs}
              >
                <Tab
                  icon={
                    searching.roles ? (
                      <LinearProgress
                        color="secondary"
                        className={classes.progress}
                      />
                    ) : (
                      ""
                    )
                  }
                  label={props.translate("System Roles")}
                  disabled={!Boolean(roles)}
                />
                <Tab
                  icon={
                    searching.menus ? (
                      <LinearProgress
                        color="secondary"
                        className={classes.progress}
                      />
                    ) : (
                      ""
                    )
                  }
                  label={props.translate("System Menus")}
                  disabled={!Boolean(filtermenus)}
                />
              </Tabs>
              <TabPanel value={selectedTab} index={0}>
                <SystemRole {...props} roles={roles} menus={filtermenus} />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Typography variant="h6" color="textSecondary">
                      {props.translate("System Menus")}
                    </Typography>
                  </Grid>
                  {typeMenu.map((filter, idx) => (
                    <Grid item xs={3}>
                        <Button 
                        variant="contained" 
                        color="secondary"
                        onClick={() => { 
                            let item =menus.filter((menu) =>menu.platformType.includes(typeMenu[idx].toLowerCase()))
                            setFilterMenus(item) 
                        }}
                        >
                           {filter}
                        </Button>
                    </Grid>
                  ))}
                </Grid>
                <SystemMenu {...props} roles={roles} menus={filtermenus} />
              </TabPanel>
            </Box>
          </Paper>
        </Container>
      </Grow>
    );
}

export default withSnackbar(Roles);