import React from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function MessageIcon() {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="notifications-row">
      <IconButton
        aria-controls="menu1"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ChatBubbleIcon className="msg-icon" />
      </IconButton>
      <StyledMenu
        id="menu1"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemText
            primary="Pedido"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className="inline"
                  color="textPrimary"
                >
                  Lala
                  </Typography>
                {" — El pedido se envía mañana..."}
              </React.Fragment>
            }
          />
          <small className="timestamp">14/12/2019, 15:00</small>
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemText
            primary="Traspaso"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className="inline"
                  color="textPrimary"
                >
                  Sucursal 2
                  </Typography>
                {" — Solicitud de traspaso…"}
              </React.Fragment>
            }
          />
          <small className="timestamp">14/12/2019, 15:00</small>
        </StyledMenuItem>
      </StyledMenu>
      <span className="not-label"> 2 </span>
    </Box>
  );

}