import React, { Component } from 'react';
import { APIBAMASTAGE } from "../../../env/constants";
import MaterialTable from 'material-table';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { tableESP } from '../../../helpers';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Lead from '../../../components/Lead';
import ModalCreateVariable from '../ModalCreateVariable';
import SessionControl from '../../../utils/SessionControl';
import { connect } from 'react-redux';

class ListVariable extends SessionControl {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            idDataSource: '',
            variables: [],
            permision_read:false,
            permision_write:false,
            nameVariable: '',
            breadCrumbs: [
                { title: "Dashboard", url: "/prometheus/variableCommissions/" },
                { title: "Variables", url: "/prometheus/variableCommissions/variables" },
                { title: "Lista", current: true },
            ],
            user: this.getSecureStore().UserProfileReducer &&
            this.getSecureStore().UserProfileReducer.user
            ? this.getSecureStore().UserProfileReducer.user
            : [],
            btnDis: true,
            lead: { show: false, type: "success", message: "", close: true }
        }
    }


    componentDidMount() {
        const { params } = this.props;
        let jsonParamProfile = JSON.parse(JSON.parse(this.getSecureStore().UserProfileReducer.user.paramProfile))
        //console.log(jsonParamProfile)
        //console.log(this.getSecureStore().UserProfileReducer.user.position)
        /// ** FILTRO POR PERMISO DE LECTURA Y ESCRITURA
        jsonParamProfile.filter(r => r.uuid === this.getSecureStore().UserProfileReducer.user.position.id).forEach(_e => {
            // console.log(_e.path_permissions)
            _e.path_permissions.forEach(_p => {
                if (_p.name === "Variables") {
                    this.setState({
                        permision_read: _p.read,
                        permision_write: _p.write
                    });
                }
            });

        });
        fetch(`${APIBAMASTAGE}/data-source/${params.dataSourceId}/variable`)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    variables: data.variable,
                    readyOrg: true,
                    loading: false,
                    idDataSource: params.dataSourceId
                });
            })
    }

    handleClose = () => {
        this.setState({ open: false });
    }


    handleDeleteVariable(oldData, id) {

        const { variables } = this.state;

        this.setState({
            loading: true
        });

        fetch(`${APIBAMASTAGE}/variable/${id}`, { method: 'DELETE' })
            .then(response => response.json())
            .then(data => {
                let prov = variables;
                prov.splice(prov.indexOf(oldData), 1);
                this.setState({
                    variables: prov,
                    loading: false,
                    lead: { show: true, type: "success", message: "Se eliminó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });

    }


    handleEditVariable(oldData, newData, id, name) {

        const { variables } = this.state;

        this.setState({
            loading: true
        });

        let body = {
            name: `${name}`,
        }

        fetch(`${APIBAMASTAGE}/variable/${id}`, { method: 'PATCH', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(data => {
                let prov = variables;
                prov[prov.indexOf(oldData)] = newData;
                this.setState({
                    variables: prov,
                    loading: false,
                    lead: { show: true, type: "success", message: "Se modificó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });
    }


    //Modal Create

    handleChangeNameVariable = (e) => {
        this.setState({
            nameVariable: e
        });
    }

  

    handleSaveVariableName = (name) =>{

        const {variables , idDataSource } = this.state;

        this.setState({
            btnActive: false,
            loading: true,
            readyData: false
        });

        let body = {
            name : `${name}`
        }

        fetch(`${APIBAMASTAGE}/data-source/${idDataSource}/variable`  , { method: 'post' , body: JSON.stringify(body)})
            .then(response => response.json())
            .then(data => { 
                let ar = variables;
                ar.push({id: data.variable.id , name: data.variable.name });
                this.setState({
                    open: false,
                    btnActive: true,
                    readyData: true,
                    loading: false,
                    nameVariable: '',
                    variables: ar,
                    lead: { show: true , type: "success" , message: "Se creó correctamente" , close: false}
                });
        })
        .catch(error => { console.log(error)});

    }


    handleDeleteRow = (row) => {
        const { valoresParamaters } = this.state;
        let ar = valoresParamaters;
        ar.splice(ar.indexOf(row), 1);
        this.setState({
            valoresParamaters: ar
        });
    }

    render() {

        const {
            breadCrumbs,
            btnDis,
            lead,
            loading,
            nameVariable,
            open,
            variables
        } = this.state;


        return (
            <div>
                <Lead
                    lead={lead}
                    handleClose={() => { this.setState({ lead: { show: false, type: "success", message: "", close: true } }) }}
                /> 
                <ModalCreateVariable
                    open={open}
                    nameVariable={nameVariable}
                    loading={loading}
                    changeName={this.handleChangeNameVariable}
                    handleClose={this.handleClose}
                    saveVariable={this.handleSaveVariableName}
                    btnDis={btnDis}
                    deleteRow={this.handleDeleteRow}
                ></ModalCreateVariable>    
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs navigation={breadCrumbs} />
                    </Grid>
                    <Grid
                        className={'btn-wrapper'}
                        item xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-start"
                    >
                        {this.state.permision_write?<Button
                            className={'btn accept-btn'}
                            type="submit"
                            onClick={() => this.setState({ open: true, nameVariable: '', dateParameter: '', valorParameter: '', valoresParamaters: [] })}
                        >
                            CREAR VARIABLE
                        </Button>:""}
                    </Grid>
                    <Grid className={'btn-wrapper'} item xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : this.state.permision_write ?(
                                <MaterialTable
                                    title=' '
                                    columns={[
                                        /*{ title: 'ID', field: 'id', editable: 'never' },*/
                                        { title: 'Nombre', field: 'name' },
                                        {
                                            render: rowData => (
                                                <Button
                                                    className={'accept-btn'}
                                                    component={Link}
                                                    to={`/prometheus/variableCommissions/variables/detalle/${rowData.id}/${rowData.name}`}
                                                    variant="contained"
                                                >
                                                    Ver Valores
                                                </Button>
                                            ),
                                            width: 200
                                        },
                                    ]}
                                    data={variables}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.handleEditVariable(oldData, newData, oldData.id, newData.name);
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.handleDeleteVariable(oldData, oldData.id);
                                                }, 600);
                                            }),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    localization={tableESP}
                                />
                            ):(
                                <MaterialTable
                                    title=' '
                                    columns={[
                                        /*{ title: 'ID', field: 'id', editable: 'never' },*/
                                        { title: 'Nombre', field: 'name' },
                                        {
                                            render: rowData => (
                                                <Button
                                                    className={'accept-btn'}
                                                    component={Link}
                                                    to={`/prometheus/variableCommissions/variables/detalle/${rowData.id}/${rowData.name}`}
                                                    variant="contained"
                                                >
                                                    Ver Valores
                                                </Button>
                                            ),
                                            width: 200
                                        },
                                    ]}
                                    data={variables}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    localization={tableESP}
                                />
                            )}
                    </Grid>
                </Grid>
            </div>
        );
    }

};


const mapStateToProps = state => ({
    history: state.router,
    navigation: state.navigation.navigation
});

export default connect(mapStateToProps)(ListVariable);