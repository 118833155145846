import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  FETCH_REPORTS_EMPLOYEE,
  FETCH_REPORTS_BY_ORGANIZATION,
  RUN_COMMISSION } from './constants';
// import { push } from 'connected-react-router';
import {
  setEmployeeReports,
  setEmployeeReportsError,
  setCommisionsending,
  setReportsByOrganization,
} from './actions';
import { setNotificationMessage } from '../notification/actions';
import API from '../../services/api';

export function* fetchReportsEmployee(action) {
  const {
    payload: { id },
  } = action;
  try {
    if (id) {
      const response = yield call(API.searchEmployeeReport, {
        employee_id: id,
      });
      const { data } = response;
      yield put(setEmployeeReports(data.results));
    }
  } catch (error) {
    yield put(setEmployeeReportsError(true));
  }
}


export function* fetchReportsOrganization(action) {

  const {
    payload: { type, organization_id , hierarchical_node_id , start_date , end_date},
  } = action;
  try {

    switch (type) {
      case 'organization':
        if (organization_id) {
          const response = yield call(API.searchOrganizationReport, {
            organization_id: organization_id,
          });
          const { data } = response;
          yield put(setReportsByOrganization(data.results));
        }
        break;
  
      case 'organization_hierarchical':
        if (organization_id && hierarchical_node_id) {
          const response = yield call(API.searchOrganizationReport, {
            organization_id: organization_id,
            hierarchical_node_id: hierarchical_node_id
          });
          const { data } = response;
          yield put(setReportsByOrganization(data.results));
        }
        break; 
  
      case 'organization_dates':
        if (organization_id && start_date && end_date) {
          const response = yield call(API.searchOrganizationReport, {
            organization_id: organization_id,
            start_date: start_date, 
            end_date: end_date
          });
          const { data } = response;
          yield put(setReportsByOrganization(data.results));          
        }
        break;
      
      case 'organization_hierarchical_dates':
        if (organization_id && start_date && end_date && hierarchical_node_id) {
          const response = yield call(API.searchOrganizationReport, {
            organization_id: organization_id,
            hierarchical_node_id: hierarchical_node_id,
            start_date: start_date, 
            end_date: end_date
          });
          const { data } = response;
          yield put(setReportsByOrganization(data.results));          
        }
        break;
  
      default:
        break;
    }

  } catch (error) {
    yield put(setEmployeeReportsError(true));
    yield put(setReportsByOrganization([]));    
  }
}

export function* runCommission(action) {
  const { payload } = action;
  yield put(setCommisionsending(true));
  try {
    yield call(API.runCommission, payload);
    yield put(`/reportes/${payload.employee_id}`);
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se programo con éxito tu Comisión Variable `,
      }),
    );
  } catch (error) {
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se pudo correr tu comisión variable, intenta más tarde`,
      }),
    );
  } finally {
    yield put(setCommisionsending(false));
  }
}

export default function* reportsSaga() {
  yield all([
    takeLatest(FETCH_REPORTS_EMPLOYEE, fetchReportsEmployee),
    takeLatest(FETCH_REPORTS_BY_ORGANIZATION, fetchReportsOrganization),
    takeLatest(RUN_COMMISSION, runCommission),
  ]);
}
