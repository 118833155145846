import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import {
  FETCH_EMPLOYEE_CATALOG,
  SET_EMPLOYEE_CATALOG,
  SET_EMPLOYEE_CATALOG_ERROR,
} from './constants';

const fetchEmployeeCatalog = (state, payload) => ({
  ...state,
  loading: true,
  error: false,
});

const setEmployeeCatalog = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  data: action.payload,
});

const setEmployeeCatalogError = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

export const reducer = handleActions(
  {
    [FETCH_EMPLOYEE_CATALOG]: fetchEmployeeCatalog,
    [SET_EMPLOYEE_CATALOG]: setEmployeeCatalog,
    [SET_EMPLOYEE_CATALOG_ERROR]: setEmployeeCatalogError,
  },
  INITIAL_STATE,
);
