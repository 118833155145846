import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getAreasState = state => get(state, 'areas');

export const isAreasloading = createSelector([getAreasState], areas =>
  get(areas, 'loading'),
);

export const getAreasdata = createSelector([getAreasState], areas =>
  get(areas, 'data'),
);
