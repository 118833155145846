import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getEmployeesState = state => get(state, 'employees');

export const isEmployeesloading = createSelector(
  [getEmployeesState],
  employees => get(employees, 'loading'),
);

export const getEmployeesdata = createSelector([getEmployeesState], employees =>
  get(employees, 'data'),
);
