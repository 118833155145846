import React, { Component } from 'react';
import { APIBAMASTAGE } from "../../../env/constants";
import MaterialTable from 'material-table';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import { tableESP } from '../../../helpers';
import { Button, Typography, Container } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalCreate from '../ModalCreate';
import Lead from '../../../components/Lead';
import { connect } from 'react-redux';
import SessionControlSupportMultiLanguaje from '../../../utils/SessionControl';

class ListaEmployee extends SessionControlSupportMultiLanguaje {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            idArea: '',
            nameArea: '',
            employee: [],
            editEmployee: false,
            nameE: '',
            fLastName: '',
            sLastName: '',
            idE: '',
            subordinates: [],
            errorAddSubordinate: false,
            breadCrumbs: [
                { title: "Dashboard", url: "/prometheus/variableCommissions" },
                { title: "Empleados", url: "/prometheus/variableCommissions/empleados" },
                { title: "Lista", current: true },
            ],
            permision_read:false,
            permision_write:false,
            readyData: false,
            valorParameter: '',
            dateParameter: '',
            valoresParamaters: [],
            btnDis: true,
            lead: { show: false, type: "success", message: "", close: true }
        }

    }


    componentDidMount() {
        const { params } = this.props;

        var area = this.getSecureStore().areas.data.find(area => area.id === params.areaId);

        let jsonParamProfile = JSON.parse(JSON.parse(this.getSecureStore().UserProfileReducer.user.paramProfile))
        //console.log(jsonParamProfile)
        //console.log(this.getSecureStore().UserProfileReducer.user.position)
        /// ** FILTRO POR PERMISO DE LECTURA Y ESCRITURA
        jsonParamProfile.filter(r => r.uuid === this.getSecureStore().UserProfileReducer.user.position.id).forEach(_e => {
            // console.log(_e.path_permissions)
            _e.path_permissions.forEach(_p => {
                if (_p.name === "Employees") {
                    this.setState({
                        permision_read: _p.read,
                        permision_write: _p.write
                    });
                }
            });

        });

        this.setState({
            organization: this.getSecureStore().VariableCommissionsReducer.organization
        })
        fetch(`${APIBAMASTAGE}/area/${params.areaId}/employee`)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    employee: data.employee,
                    readyOrg: true,
                    loading: false,
                    idArea: params.areaId,
                    nameArea: params.areaName
                });
            })
    }

    handleClose = () => {
        this.setState({ open: false });
    }



    handleOnEditEmployee = (row) => {
        this.setState({
            open: true,
            editEmployee: true,
            nameE: row.first_name,
            fLastName: row.last_name,
            sLastName: row.second_last_name,
            idE: row.id,
            subordinates: row.subordinates
        });
    }

    handleOnDeleteValue = (oldData, areaId) => {

        const { employee } = this.state;

        this.setState({
            ready: false,
            loading: true
        });

        let prov = employee;

        fetch(`${APIBAMASTAGE}/employee/${oldData.id}`, { method: 'DELETE' })
            .then(response => response.json())
            .then(() => {
                prov.splice(prov.indexOf(oldData), 1);
                this.setState({
                    employee: prov,
                    loading: false,
                    ready: true,
                    lead: { show: true, type: "success", message: "Se eliminó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });


    }




    //Modal Create

    handlechangeName = (props) => {
        this.setState({
            nameE: props
        });
    }

    handlechangeFLName = (props) => {
        this.setState({
            fLastName: props
        });
    }

    handlechangeSLName = (props) => {
        this.setState({
            sLastName: props
        });
    }

    handlechangeIdE = (props) => {
        this.setState({
            idE: props
        });
    }



    handleAddSubordinate = (idSubordinate) => {

        const { subordinates } = this.state;

        this.setState({
            loading: true,
            errorAddSubordinate: false
        });

        fetch(`${APIBAMASTAGE}/employee/${idSubordinate}`)
            .then(response => response.json())
            .then(data => {
                const employe = data.employee
                if (typeof employe !== 'undefined') {
                    let ar = subordinates;
                    if (ar.indexOf(idSubordinate) === -1) {
                        ar.push(idSubordinate);
                    }
                    this.setState({
                        subordinates: ar,
                        loading: false,
                        lead: { show: true, type: "success", message: "Se agregó subordinado correctamente", close: false }
                    });
                } else {
                    this.setState({
                        errorAddSubordinate: true,
                        loading: false,
                        lead: { show: true, type: "error", message: "Subordinado no encontrado", close: false }
                    });
                }
            })
            .catch(error => { console.log(error) });
    }

    handleDeleteSubordinate = (idSubordinate) => {
        const { subordinates } = this.state;
        let ar = subordinates;
        const index = ar.indexOf(idSubordinate);
        ar.splice(index, 1);
        this.setState({
            subordinates: ar
        });
    }

    handleSaveEmploye = () => {

        const { idE, idArea, nameE, fLastName, sLastName, subordinates, employee } = this.state;

        this.setState({
            btnDis: false,
            loading: true
        });

        let body;

        if (typeof subordinates !== 'undefined' && subordinates.length > 0) {
            body = {
                "employee": [
                    {
                        "id": idE,
                        "first_name": nameE,
                        "last_name": fLastName,
                        "second_last_name": sLastName,
                        "subordinates": subordinates
                    }
                ]
            }
        } else {
            body = {
                "employee": [
                    {
                        "id": idE,
                        "first_name": nameE,
                        "last_name": fLastName,
                        "second_last_name": sLastName,
                    }
                ]
            }
        }

        fetch(`${APIBAMASTAGE}/area/${idArea}/employee`, { method: 'post', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(data => {


                let ar = [];

                if (employee) {
                    ar = employee;
                }

                const obj = {
                    "id": idE,
                    "first_name": nameE,
                    "last_name": fLastName,
                    "second_last_name": sLastName
                };

                ar.push(obj);

                this.setState({
                    open: false,
                    nameE: '',
                    fLastName: '',
                    sLastName: '',
                    idE: '',
                    employee: ar,
                    subordinates: [],
                    btnDis: true,
                    loading: false,
                    lead: { show: true, type: "success", message: "Se creó correctamente", close: false }
                });
            })
            .catch(error => { console.log(error) });

    }

    handleSendEditEmployee = () => {
        const { nameE, fLastName, sLastName, subordinates, idE } = this.state;
        this.setState({
            btnDis: false,
            loading: true
        });
        let body = {
            "first_name": nameE,
            "last_name": fLastName,
            "second_last_name": sLastName,
            "subordinates": subordinates
        }
        fetch(`${APIBAMASTAGE}/employee/${idE}`, { method: 'PATCH', body: JSON.stringify(body) })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    open: false,
                    nameE: '',
                    fLastName: '',
                    sLastName: '',
                    idE: '',
                    subordinates: [],
                    btnDis: true,
                    loading: false,
                    lead: { show: true, type: "success", message: "Se modificó correctamente", close: false }
                });

                fetch(`${APIBAMASTAGE}/area/${this.state.idArea}/employee`)
                    .then(response => response.json())
                    .then(data => {
                        this.setState({
                            employee: data.employee,
                        });
                    })
                    .catch(error => { console.log(error) });


            })
            .catch(error => { console.log(error) });
    }


    render() {

        const {
            breadCrumbs,
            btnDis,
            lead,
            loading,
            nameE,
            open,
            employee,
            organization
        } = this.state;


        return (
            <Container fixed>
                <Lead
                    lead={lead}
                    handleClose={() => { this.setState({ lead: { show: false, type: "success", message: "", close: true } }) }}
                />

                <ModalCreate
                    open={open}
                    nameE={nameE}
                    loading={loading}
                    changeName={this.handlechangeName}
                    fLastName={this.state.fLastName}
                    changeFLName={this.handlechangeFLName}
                    sLastName={this.state.sLastName}
                    changeSLName={this.handlechangeSLName}
                    idE={this.state.idE}
                    changeIdE={this.handlechangeIdE}
                    editEmployee={this.state.editEmployee}
                    sendEditEmployee={this.handleSendEditEmployee}
                    handleClose={this.handleClose}
                    saveEmploye={this.handleSaveEmploye}
                    subordinates={this.state.subordinates}
                    addSubordinateList={this.handleAddSubordinate}
                    deleteSubordinateList={this.handleDeleteSubordinate}
                    btnDis={btnDis}
                    errorAddSubordinate={this.state.errorAddSubordinate}
                ></ModalCreate>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs {...this.props} navigation={breadCrumbs} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom>
                            {organization && organization.name}
                        </Typography>
                    </Grid>
                    <Grid
                        className={'btn-wrapper'}
                        item xs={6}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-start"
                    >
                        {this.state.permision_write?<Button
                            className={'btn accept-btn'}
                            type="submit"
                            onClick={() => this.setState({ ...this.state, open: true, nameE: '', fLastName: '', sLastName: '', idE: '', editEmployee: false, subordinates: [], errorAddSubordinate: false })}
                        >
                            CREAR EMPLEADO
                        </Button>:""}
                    </Grid>
                    <Grid className={'btn-wrapper'} item xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : this.state.permision_write ? (
                                <MaterialTable
                                    title={this.state.nameArea}
                                    columns={[
                                        /*{ title: 'ID', field: 'id' , editable: 'never' },*/
                                        { title: 'Nombre', field: 'first_name' },
                                        { title: 'Apellido Paterno', field: 'last_name' },
                                        { title: 'Apellido Materno', field: 'second_last_name' }
                                    ]}
                                    data={employee}
                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: 'Editar Valores',
                                            onClick: (event, rowData) => this.handleOnEditEmployee(rowData)
                                        }
                                    ]}
                                    editable={{
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.handleOnDeleteValue(oldData, oldData.id);
                                                }, 600);
                                            }),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    localization={tableESP}
                                />
                            ):(
                                <MaterialTable
                                    title={this.state.nameArea}
                                    columns={[
                                        /*{ title: 'ID', field: 'id' , editable: 'never' },*/
                                        { title: 'Nombre', field: 'first_name' },
                                        { title: 'Apellido Paterno', field: 'last_name' },
                                        { title: 'Apellido Materno', field: 'second_last_name' }
                                    ]}
                                    data={employee}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    localization={tableESP}
                                />
                            )}
                    </Grid>
                </Grid>
            </Container>
        );
    }

};


const mapStateToProps = state => ({
    history: state.router,
    navigation: state.navigation ? state.navigation.navigation : null
});

export default connect(mapStateToProps)(ListaEmployee);