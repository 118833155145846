import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField } from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddWorkPlan = ({ open, onClose, onSave, data, translate }) => {
    const firstRender = useRef(true)
    const [newData, setNewData] = useState({
        id: '',
        workplanName: '',
        description: '',
        _version: 0
    })

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        setNewData({
            id: data.id,
            workplanName: data.workplanName,
            description: data.description,
            _version: data._version
        })

        const cleanup = () => { }
        return cleanup
    }, [data])

    const handleCancel = () => onClose();

    const handleSave = () => {
        onSave(newData);
    }

    return (
        <Box display='flex' justifyContent='right' alignItems='right' flexDirection='row' m={1} minWidth={'95%'} >
            <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'md'} disableBackdropClick disableEscapeKeyDown >
                <DialogTitle>{translate('workplan')}</DialogTitle>
                <DialogContentText></DialogContentText>
                <DialogContent>
                    <form autoComplete='off'>
                        <Container>
                            <Grid item xs={12} m={1} >
                                <FormControl component='fieldset' error={true} align='center' fullWidth >
                                    <TextField id="planName" label={translate('WorkPlan Name')} defaultValue={data.workplanName} helperText={translate('Required')} margin="dense" size="small" required onBlur={(ev) => setNewData({...newData, workplanName: ev.target.value }) } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} m={1}>
                                <FormControl component='fieldset' error={true} align='center' fullWidth >
                                    <CKEditor data={data.description != '' ? JSON.parse(data.description).DescrtiptionWorkplan : ''} editor={ClassicEditor} config={{ ckfinder: { uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json' } }} onBlur={ ( event, editor ) => setNewData({...newData, description: {DescrtiptionWorkplan: editor.getData()}})} ></CKEditor>
                                </FormControl>
                            </Grid>
                        </Container>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button size="small" color='primary' variant='contained' onClick={handleSave} >{translate('Save')}</Button>
                    <Button size="small" color='default' variant='contained' autoFocus onClick={handleCancel} >{translate('Cancel')}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

AddWorkPlan.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
}

AddWorkPlan.defaultProps = {
    data: {}
}

export default AddWorkPlan

