import React , { useState, useEffect} from 'react';
import { connect , useSelector } from 'react-redux';

import { APIBAMASTAGE } from "../../../env/constants";
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CVTable from '../../../components/CVTable';

import { 
    setVariableId,
    setVariableTagEdit
} from '../../../store/variablestag/actions';


const ListaValuesVariable = ({
        params,
        setVariableTagEdit,
        setVariableId
    }) => {


    const[variablesValues , setVariablesValues] = useState([]);
    const[loading , setLoading] = useState(true);
    const[edit , setEdit] = useState(false);
    const position = useSelector(store => store.UserProfileReducer.user.position)
    const paramsPosition = useSelector(store => JSON.parse(JSON.parse(store.UserProfileReducer.user.paramProfile)))
    
    useEffect(() => {
        fetch(`${APIBAMASTAGE}/variable/${params.variableId}/values`)
            .then(response => response.json())
            .then( ({variable_value}) => { 
                setVariablesValues(variable_value);
                setLoading(false);
            })

            paramsPosition.filter(r => r.uuid === position.id).forEach(_e => {
                // console.log(_e.path_permissions)
                _e.path_permissions.forEach(_p => {
                    if (_p.name === "Variables") {
                        setEdit(_p.write)
                    }
                });
    
            });
    }, []);

    const DeleteValueTagEdit = (index, oldData ) => {
        console.log(index , oldData)
        setLoading(true);
        let arr = [...variablesValues];        
        fetch(`${APIBAMASTAGE}/variable-value/${oldData.id}`, {method: 'DELETE'})
        .then(response => response.json())
        .then(data => { 
            arr.splice(index, 1);
            setVariablesValues(arr)
            setLoading(false);
        })
        .catch(error => { console.log(error)});
    }


    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {/*<Breadcrumbs navigation={breadCrumbs} />*/}
                </Grid>
                <Grid
                    className={'btn-wrapper'}
                    item xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-start"
                >
                   {edit? <Button
                        className={'btn accept-btn'}
                        component={Link}
                        to={`/prometheus/variableCommissions/variables/detalle-valor-tag`}
                        variant="contained"
                        onClick={() => {
                            setVariableId(params.variableId)
                            setVariableTagEdit(false)
                        }}
                    >
                        CREAR TAG
                    </Button> :""}
                </Grid>
                <Grid item xs={12}>
                    {loading ? 
                        <div>Cargando</div>
                        :<CVTable
                            columns={[
                                { title: 'Tag', field: 'tag' , editable: 'never'},
                                { title: 'Fecha', field: 'date' },
                                {
                                    render: rowData => (
                                        <Button
                                            className={'btn accept-btn'}
                                            component={Link}
                                            to={`/prometheus/variableCommissions/variables/detalle-valor-tag/${rowData.id}`}
                                            variant="contained"
                                            onClick={() => {
                                                setVariableId(rowData.id)
                                                setVariableTagEdit(true)
                                            }}
                                        >
                                            Editar Valores
                                        </Button>
                                    ),
                                    width: 200
                                },
                            ]}
                            data={variablesValues}
                            onEdit={edit}
                            edit={false}
                            onDeleteValue={DeleteValueTagEdit}
                        ></CVTable>
                    }
                </Grid>
                
            </Grid>
        </div>
    );
};

const mapStateToProps = state => ({
    history: state.router,
    navigation: state.navigation.navigation
});

const mapDispatchToProps = dispatch => ({
    setVariableTagEdit: payload => dispatch(setVariableTagEdit(payload)),
    setVariableId: payload => dispatch(setVariableId(payload))    
});

export default connect(mapStateToProps,mapDispatchToProps)(ListaValuesVariable);