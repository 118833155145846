import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, AppBar, Paper, Typography, Dialog, Toolbar, Button, Slide, ListItemIcon, ListItemText } from '@material-ui/core';
import MaterialTable from "material-table";
import { tableIcons, localization } from '../../components/materialTable/MaterialTable';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';


import { listHeadcountStatuss } from '../../graphql/queries'
import { updateHeadcountStatus,createHeadcountStatus, deleteHeadcountStatus } from '../../graphql/mutations'
import { api, apiPartial } from "../../api/api";


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


function StatusHeadCount(props) {
    const firstRender = useRef(true)
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [Position, setPosition] = React.useState(null);
    const [HeadcountStatuss, setHeadcountStatuss] = React.useState(null);

    

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false  // it's no longer the first render

            loadHeadcountStatuss()

            return // skip the code below
        }

        return function cleanup() {

        };
    }, []) // Only re-run the effect if name or email change

   
    const loadHeadcountStatuss = () => {
        api(listHeadcountStatuss, { limit: 300 })
          .then((res) => {
            try {
                let itemFilter = res.filter((pos) => pos.workTeamTemplateID === props.WorkTeam.id && pos._deleted === null);
                setHeadcountStatuss(itemFilter)
            } catch (error) {
                
            }
            })
          .catch((err) => {
            console.log(err);
          });
      };
    
      const createHeadcountStatusMethod = (param) => {
        api(createHeadcountStatus, { input: param })
          .then((res) => {

           
            loadHeadcountStatuss()

            })
          .catch((err) => {
            console.log(err);
          });
      };
    

      const UpdTeamHeadcountStatusMethod = (param) => {
        api(updateHeadcountStatus, { input: param })
          .then((res) => {

            loadHeadcountStatuss()

            })
          .catch((err) => {
            console.log(err);
          });
      };

      const DeleteTeamHeadcountStatusMethod = (param) => {
        api(deleteHeadcountStatus, { input: param })
          .then((res) => {

            loadHeadcountStatuss()

            })
          .catch((err) => {
            console.log(err);
          });
      };
    
    
    
    return (
      <Box p={8}>
        <Typography variant="h6" color="textSecondary">
          {props.translate("Rules StatusHeadcount")}
        </Typography>
        <Grid item xs={12}>
          {HeadcountStatuss !== null && (
            <Grid item xs={12}>
              <Paper>
                <MaterialTable
                  icons={tableIcons}
                  localization={localization(props)}
                  options={{
                    exportButton: true,
                  }}
                  columns={[
                    {
                      field: "statusName",
                      title: props.translate("Name status"), 
                      lookup: { "high":props.translate("high"), "low": props.translate("low"), "medium": props.translate("medium") },
                    },
                    {
                      field: "vacantNumber",
                      title: props.translate("vacant"), 
                      type: 'numeric'

                    },
                    {
                      field: "assignedDays",
                      title: props.translate("Assigned Days"), 
                      type: 'numeric'
                    },
                  ]}
                  data={HeadcountStatuss}
                  editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {

                            let param = {
                                assignedDays: newData.assignedDays,
                                workTeamTemplateID:props.WorkTeam.id,
                                vacantNumber :newData.vacantNumber,
                                statusName: newData.statusName,
                                headcountStatusWorkTeamTemplateId: props.WorkTeam.id
                            }

                            createHeadcountStatusMethod(param)
                          resolve();
                        }, 1000)
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...HeadcountStatuss];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;

                          let param = {
                            id:newData.id,
                            assignedDays: newData.assignedDays,
                            workTeamTemplateID:props.WorkTeam.id,
                            headcountStatusWorkTeamTemplateId: props.WorkTeam.id,
                            vacantNumber :newData.vacantNumber,
                            statusName: newData.statusName,
                            _version: newData._version
                        }

                         UpdTeamHeadcountStatusMethod(param)
            
                          resolve();
                        }, 1000)
                      }),
                      onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                          setTimeout(() => {
                              const dataDelete = [...HeadcountStatuss];
                              const index = oldData.tableData.id;
                             
                              let params = {
                                id:HeadcountStatuss[index].id,
                                _version:HeadcountStatuss[index]._version
                              }

                              DeleteTeamHeadcountStatusMethod(params)
          
                              resolve();
                          }, 1000);
                      })
                  }}
                  title={""} //
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    );
}
export default withSnackbar(StatusHeadCount);