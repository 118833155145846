import React, { useRef, useEffect, useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, Button, Paper, DialogActions } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import reactHtmlPrse from 'react-html-parser';

import AddSectionWP from './AddSectionWorPlan';
import ListActions from './ListActivSectionWorkPlan';
import { api } from '../../api/api';
import { listWorkplanSections } from '../../graphql/queries';
import { createWorkplanSection, updateWorkplanSection } from '../../graphql/mutations';
import { SNACKBAR_ERROR, SNACKBAR_INFO, SNACKBAR_SUCCESS } from '../../utils/SessionControl';

const ShowSections = (props) => {
    let { open, onClose, sections, workplanId, translate, showMessage, tableLocation } = props;
    const firstRender = useRef(true);
    const [showLoader, setShowLoader] = useState(false);
    const [secciones, setSecciones] = useState([]);
    const [showAddSection, setShowAddSection] = useState(false)
    const [dataSection, setDataSection] = useState({
        id: '',
        description: '',
        sectionName: '',
        workplan: workplanId,
        _version: 0
    });
    const [currentWorkPlanId, setCurrentWorkPlanId] = useState({});
    const [isNewSection, setIsNewSection] = useState(true);
    const [openActivitiesDialog, setOpenActivitiesDialog] = useState(false)

    const columnasSectionsTable = [
        {
            field: 'sectionName',
            title: translate('Section Name')
        },
        {
            field: 'description',
            title: translate('Description'),
            render: el => {
                let desc = JSON.parse(el.description);
                return reactHtmlPrse(desc.DescrtiptionNuevaSeccion)
            }
        },
        {
            field: 'createdAt',
            title: translate('Create date'),
            type: 'date'
        },
        {
            field: 'updatedAt',
            title: translate('Updated'),
            type: 'date'
        }
    ]
    const actionsSectionTable = [
        {
            icon: 'edit',
            tooltip: translate('Edit'),
            onClick: (evtEd, rowEdit) => {
                setIsNewSection(false)
                setDataSection(rowEdit);
                handleToggleAddSectionWorkPlan();
            }
        },
        {
            icon: Visibility,
            tooltip: translate('View Activities'),
            onClick: (evtView, rowView) => {
                setDataSection(rowView);
                handleToggleAvtivitiesDialog()
            }
        }
    ]

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return
        }
        setSecciones(sections);
        setCurrentWorkPlanId(workplanId)
        const cleanup = () => { }
        return cleanup;
    }, [sections, workplanId]);

    const handleToggleAddSectionWorkPlan = () => {
        setShowAddSection(!showAddSection);
    }

    const handleCloseSection = () => {
        if(!isNewSection){
            setIsNewSection(true)
            setDataSection({
                id: '',
                description: '',
                sectionName: '',
                workplan: currentWorkPlanId,
                _version: 0
            })
        } else {
            setDataSection({...dataSection, workplan: workplanId});
        }
        handleToggleAddSectionWorkPlan();
    }

    const handleSaveSectionWorkPlan = async (data) => {
        setShowAddSection(false);
        setShowLoader(true)
        if(isNewSection){
            // console.log('add Section!', data);
            let params = {
                sectionName: data.sectionName,
                description: data.description,
                workplanSectionWorkplanId: data.workplan.id
            }
            await api(createWorkplanSection, {input: params}).then(res => {
                // console.log('guardado!...', res);
                showMessage('Nueva sección guardada correctamente!...', SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            }).catch(err => {
                console.log('error al guardar sección: ', err);
                showMessage('error al intentar agregar la sección...', SNACKBAR_ERROR, null, null, null, null, null, null, null, props);
            }).finally(()=>{
                setShowLoader(false)
            })
        } else {
            let params = {
                id: data.id,
                sectionName: data.sectionName,
                description: data.description,
                workplanSectionWorkplanId: data.workplan.id,
                _version: data._version
            }
            await api(updateWorkplanSection, {input: params}).then(res => {
                showMessage('Sección actualizada correctamente!...', SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            }).catch(err => {
                console.log('error al actualizar: ', err);
                showMessage('error al intentar modificar la Sección...', SNACKBAR_ERROR, null, null, null, null, null, null, null, props);
            }).finally(() => {
                setShowLoader(false)
            })
        }
        updateListSections().then(res => setSecciones(res));
    }

    const handleToggleAvtivitiesDialog = () => {
        setOpenActivitiesDialog(!openActivitiesDialog);
    }

    const updateListSections = async () => {
        setShowLoader(true);
        let sectionsUpdated = []
        await api(listWorkplanSections). then(sect => {
            if (sect.length == undefined || sect.length == 0) {
                // console.log('La consulta no obtuvo resultados');
                showMessage('La consulta no devolvió resultados...', SNACKBAR_INFO, null, null, null, null, null, null, null, props);
            }
            else {
                sectionsUpdated = sect.filter((el, ide) => el.workplan.id == workplanId.id);
            }
        }).catch(err => {
            console.log('error al actualizar la lista de secciones: ', err);
            showMessage('error al intentar actualizar la lista de secciones!...', SNACKBAR_ERROR, null, null, null, null, null, null, null, props);
        }).finally(() => {
            setShowLoader(false);
        })
        return sectionsUpdated;
    }

    return (
        <>
            <Box display='flex' justifyContent='right' alignItems='right' flexDirection='row' m={1} minWidth={'99%'} >
                <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={false} >
                    <DialogTitle m={0} >Secciones del plan de trabajo</DialogTitle>
                    <DialogContent>
                        <DialogContentText></DialogContentText>
                        <Paper elevation={4}>
                            <MaterialTable title={translate('Sections')} isLoading={showLoader} columns={columnasSectionsTable} data={secciones} actions={actionsSectionTable} options={{ actionsColumnIndex: -1 }} localization={{header: { actions: translate('Actions')}}} localization={tableLocation} />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" color='primary' variant='contained' onClick={handleCloseSection} >{translate('Add')}</Button>
                        <Button size="small" color='default' variant='contained' onClick={onClose} >{translate('Close')}</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <AddSectionWP onOpen={showAddSection} onClose={handleCloseSection} onSave={handleSaveSectionWorkPlan} data={dataSection} translate={translate} />
            <ListActions onOpen={openActivitiesDialog} onClose={handleToggleAvtivitiesDialog} section={dataSection} translate={translate} showMessage={showMessage} tableLocation={tableLocation} />
        </>
    )
}

ShowSections.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    workplanId: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    tableLocation: PropTypes.object.isRequired
}

ShowSections.defaultProps = {
    section: []
}

export default ShowSections