import React from "react";
import PropTypes from "prop-types";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import "./styles.scss";

const HCSelect = ({value , change , search , label}) => {
  return (
    <FormControl variant="filled" className="hc_form">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={change}
        style={{width:"200px"}}
        className="hc_form_select"
      >
      {search.map((option , index )=>(
        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
      ))}
      </Select>
    </FormControl>
  );
};

HCSelect.propTypes = {
  search: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};

HCSelect.defaultProps = {
  search: [],
  value: ''
};

export default HCSelect;
