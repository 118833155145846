import { takeLatest, all, put, select, call } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import {
  ADD_KPI_ELEMENT,
  TEST_KPI_EXPRESSION,
  SAVE_KPI,
  ADD_KPI_FUNCTION_ELEMENT,
  DELETE_KPI_ELEMENT,
  EDIT_KPI_ELEMENT,
  EDIT_KPI,
  EDIT_SAVE_KPI,
  DELETE_KPI,
  EDIT_KPI_FUNCTION_ELEMENT,
} from './constants';
import {
  pushElementKPIStep,
  pushElementDictionaryKPI,
  pushFunctionElementKPI,
  setTestKPI,
  setSaveKPI,
  resetKPIData,
  setKPIsending,
  popElementDictionaryKPI,
  popAllFunctionArgumentsKPIDictionary,
  popElementKPI,
  popFunctionArgumentElementKPI,
  replaceElementKPI,
  rehydrateKPI,
  setKPIDeleting,
  replaceFunctionElementKPI,
  setNoExistIdsKPI,
} from './actions';
import {
  getKPISteps,
  getKPIName,
  getKPIArea,
  getKPIDictionary,
  getKPITime,
} from './selectors';
import { setNotificationMessage } from '../notification/actions';
import { createBase64Expression, errorMessage } from '../../helpers';
import API from '../../services/api';

export function* addElementToDictionary(element) {
  const {
    object: {
      name,
      value: { selected, value },
    },
  } = element;

  switch (selected) {
    case 'parameter':
      yield put(
        pushElementDictionaryKPI({
          type: 'parameters',
          value: { compiler_tag: name, id: value.id },
        }),
      );
      break;

    case 'areaIndicators':
      yield put(
        pushElementDictionaryKPI({
          type: 'indicators',
          value: { compiler_tag: name, id: value.id },
        }),
      );
      break;

    case 'function':
      yield put(
        pushElementDictionaryKPI({
          type: 'functions',
          value: { compiler_tag: name, id: value.id },
        }),
      );
      break;

    default:
      break;
  }
}

export function* addKPIElement(action) {
  const {
    payload: { step, type, object, name },
  } = action;
  const element = {
    step: step,
    object: {
      name: isEmpty(name) ? object.selected + '_item_' + Date.now() : name,
      type: type,
      value: object,
    },
  };

  yield put(pushElementKPIStep(element));

  if (element.object.type === 'element') {
    if (element.object.value.selected === 'function') {
      element.object.functionArguments = [];
    }
    yield addElementToDictionary(element);
  }
}

export function* addKPIFunctionElement(action) {
  const {
    payload: { payload, isFunction },
  } = action;

  const elementName = payload.object.selected + '_item_' + Date.now();
  const element = {
    step: payload.step,
    object: {
      name: isEmpty(payload.name) ? elementName : payload.name,
      type: payload.type,
      value: payload.object,
    },
  };

  const elementFunction = {
    step: payload.step,
    stepIndex: isFunction.stepPosition,
    object: {
      name: isEmpty(payload.name) ? elementName : payload.name,
      type: payload.type,
      value: payload.object,
    },
  };

  yield put(pushFunctionElementKPI(elementFunction));
  yield addElementToDictionary(element);
}

export function* editKPUFunctionElement(action) {
  const {
    payload: { payload, editData },
  } = action;

  switch (editData.type) {
    case 'parameter':
      editData.type = 'parameters';
      break;
    case 'function':
      editData.type = 'functions';
      break;
    case 'indicators':
      editData.type = 'indicators';
      break;
    default:
      editData.type = '';
      break;
  }

  if (!isEmpty(editData.type)) {
    yield put(popElementDictionaryKPI(editData));
  }

  const elementName = payload.object.selected + '_item_' + Date.now();
  const elementFunction = {
    step: editData.step,
    stepIndex: editData.index,
    object: {
      name: isEmpty(payload.name) ? elementName : payload.name,
      type: payload.type,
      value: payload.object,
    },
  };
  yield put(replaceFunctionElementKPI({ elementFunction, editData }));
  yield addElementToDictionary(elementFunction);
}

export function* testKPI() {
   //** MODULE EVALUATE KPI CONFIGURATE */
  const steps = yield select(getKPISteps);
  const expression = yield createBase64Expression(steps);
  try {
    const response = yield call(API.compilerCodeScan, {
      expression: expression,
    });

    const { data: evaluation_result } = response;

    if (evaluation_result) {
      yield put(setTestKPI(true));
      yield put(
        setNotificationMessage({
          type: 'success',
          message: `La construcción de tu comisión variable es correcta`,
        }),
      );
    } else {
      yield put(setTestKPI(false));
      yield put(
        setNotificationMessage({
          type: 'error',
          message: `Verifica la construcción de tu comisión variable`,
        }),
      );
    }
  } catch (error) {
    yield put(setTestKPI(false));
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `Verifica la construcción de tu comisión variable`,
      }),
    );
  }
}

export function* createKPICompletePayload() {
  const name = yield select(getKPIName);
  const dictionary = yield select(getKPIDictionary);
  const steps = yield select(getKPISteps);
  const time = yield select(getKPITime);
  const expression = yield createBase64Expression(steps);

  return {
    name: name,
    active: true,
    period: time.period,
    start_date: time.start_date,
    parameters: dictionary.parameters,
    indicators: dictionary.indicators,
    functions: dictionary.functions,
    expression: expression,
    html_reference: JSON.stringify(steps),
  };
}

export function* saveKPI() {
  const name = yield select(getKPIName);
  const area = yield select(getKPIArea);
  const payload = yield createKPICompletePayload();

  yield put(setKPIsending(true));
  try {
    yield call(API.createComission, area.id, payload);
    yield put(setSaveKPI(true));
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se guardo con éxito tu Comisión Variable ${name}`,
      }),
    );
    yield put(resetKPIData());
    yield put(`/comisiones-variables/lista/${area.id}`);
  } catch (error) {
    yield put(setSaveKPI(false));
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se pudo guardar tu Comisión Variable, intenta más tarde`,
      }),
    );
  } finally {
    yield put(setKPIsending(false));
  }
}

export function* deleteKPIElement(action) {
  const { payload } = action;

  switch (payload.type) {
    case 'parameter':
      payload.type = 'parameters';
      break;
    case 'function':
      payload.type = 'functions';
      break;
    case 'indicators':
      payload.type = 'indicators';
      break;
    default:
      payload.type = '';
      break;
  }

  if (!isEmpty(payload.type)) {
    yield put(popElementDictionaryKPI(payload));
    if (payload.type === 'functions') {
      //let functionName = payload.name;
      yield put(popAllFunctionArgumentsKPIDictionary(payload));
    }
  }

  if (payload.functionName === null) {
    yield put(popElementKPI(payload));
  } else {
    yield put(popFunctionArgumentElementKPI(payload));
  }
}

export function* editKPIEelement(action) {
  const {
    payload: { payload, editData },
  } = action;

  const element = {
    step: payload.step,
    object: {
      name: isEmpty(payload.name)
        ? payload.object.selected + '_item_' + Date.now()
        : payload.name,
      type: payload.type,
      value: payload.object,
    },
  };

  yield put(replaceElementKPI({ element, editData }));
  if (element.object.type === 'element') {
    if (element.object.value.selected === 'function') {
      element.object.functionArguments = [];
    }
    switch (editData.type) {
      case 'parameter':
        editData.type = 'parameters';
        break;
      case 'indicators':
        editData.type = 'indicators';
        break;
      case 'function':
        editData.type = 'functions';
        break;
      default:
        editData.type = '';
        break;
    }
    if (!isEmpty(editData.type)) {
      yield put(popElementDictionaryKPI(editData));
    }
    yield addElementToDictionary(element);
  }
}

export function* editKPI(action) {
  const { payload } = action;

  try {
    const response = yield call(API.getVariableCommission, payload);
    const {
      data: { commission },
    } = response;

    const {
      name,
      html_reference,
      parameter_id,
      indicator_id,
      function_id,
      hierarchical_node,
      run_date,
      period_span_days,
    } = commission;
    yield put(
      rehydrateKPI({
        name,
        steps: JSON.parse(html_reference),
        parameters: parameter_id,
        indicators: indicator_id,
        functions: function_id,
        area: { id: hierarchical_node.id },
        organization: { id: hierarchical_node.organization_id },
        time: {
          start_date: run_date,
          period: period_span_days,
        },
      }),
    );
  } catch (error) {
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se pudo cargar la información de tu Comisión Variable, intenta más tarde`,
      }),
    );
  }
}

export function* editSaveKPI(action) {
  //console.log("editSaveKPI action",action)

  const commisionId = action.payload.id;
  const active = action.payload.active;
  const name = yield select(getKPIName);
  const area = yield select(getKPIArea);
  const payload = yield createKPICompletePayload();
 
  payload.hierarchical_node_id = area.id;
  payload.active = active ;
  console.log("editSaveKPI payload",payload , active)
  yield put(setKPIsending(true));
  try {
    yield call(API.updateComission, commisionId, payload);
    yield put(setSaveKPI(true));
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se edito con éxito tu Comisión Variable ${name}`,
      }),
    );
    //yield put(resetKPIData());
  //  console.log(payload)
    //yield put(`/comisiones-variables/lista/${"1139aa15-6c48-4e33-979c-351e2898eef2"}`);
  } catch (error) {
    let errorTxt;
    const {
      data: { errors },
    } = error.response;

    const [noExistingIds, errorType] = errorMessage(errors);

    yield put(setNoExistIdsKPI(noExistingIds));
    yield put(setSaveKPI(false));

    switch (errorType) {
      case 'function':
        errorTxt =
          'No se pudo guardar tu Comisión Variable, Está función ya no existe.';
        break;

      case 'parameter':
        errorTxt =
          'No se pudo guardar tu Comisión Variable, Este parametro ya no existe.';
        break;

      case 'indicator':
        errorTxt =
          'No se pudo guardar tu Comisión Variable, Este Indicador ya no existe.';
        break;

      default:
        errorTxt = 'No se pudo guardar tu Comisión Variable, intenta más tarde';
        break;
    }

    yield put(
      setNotificationMessage({
        type: 'error',
        message: errorTxt,
      }),
    );
  } finally {
    yield put(setKPIsending(false));
  }
}

export function* deleteKPI(action) {
  const commisionId = action.payload;
  const area = yield select(getKPIArea);
  const name = yield select(getKPIName);

  yield put(setKPIDeleting(true));
  try {
    yield call(API.deleteComission, commisionId);
    yield put(resetKPIData());
    yield put(`/comisiones-variables/lista/${area.id}`);
    yield put(
      setNotificationMessage({
        type: 'success',
        message: `Se borro con éxito tu Comisión Variable ${name}`,
      }),
    );
  } catch (error) {
    yield put(
      setNotificationMessage({
        type: 'error',
        message: `No se puedo eliminar tu Comisión Variable, intenta más tarde`,
      }),
    );
  } finally {
    yield put(setKPIDeleting(true));
  }
}

export default function* kpiSaga() {
  yield all([
    takeLatest(ADD_KPI_ELEMENT, addKPIElement),
    takeLatest(TEST_KPI_EXPRESSION, testKPI),
    takeLatest(SAVE_KPI, saveKPI),
    takeLatest(ADD_KPI_FUNCTION_ELEMENT, addKPIFunctionElement),
    takeLatest(DELETE_KPI_ELEMENT, deleteKPIElement),
    takeLatest(EDIT_KPI_ELEMENT, editKPIEelement),
    takeLatest(EDIT_KPI, editKPI),
    takeLatest(EDIT_SAVE_KPI, editSaveKPI),
    takeLatest(DELETE_KPI, deleteKPI),
    takeLatest(EDIT_KPI_FUNCTION_ELEMENT, editKPUFunctionElement),
  ]);
}
