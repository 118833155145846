import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import { SET_NOTIFICATION_MESSAGE, SET_NOTIFICATION_SHOW } from './constants';

const setNotificationMessage = (state, action) => ({
  ...state,
  show: true,
  type: action.payload.type,
  message: action.payload.message,
});

const setNotificationShow = (state, action) => ({
  ...state,
  show: action.payload,
});

export const reducer = handleActions(
  {
    [SET_NOTIFICATION_MESSAGE]: setNotificationMessage,
    [SET_NOTIFICATION_SHOW]: setNotificationShow,
  },
  INITIAL_STATE,
);
