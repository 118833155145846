import { USER_SIGNIN } from '../ActionTypes.js'

const defaultState = {
  userSignIn: null,
  signInFailedReason: ''
};

export default function UserSignInReducer(state = defaultState, action) {
  // console.log("UserSignInReducer", action);
  switch (action.type) {
    case USER_SIGNIN.SUCCESS:
      return {
        ...state,
        /*userSignInSession: action.data.signInUserSession,*/
        userSignIn: {
          username: action.data.username,
          password: action.data.password,
          attributes: action.data.attributes,
          time: Date.now()
        },
        signInFailedReason: {}
      };
    case USER_SIGNIN.ERROR:
      return {
        ...state,
        userSignIn: {},
        signInFailedReason: action
      };
    case USER_SIGNIN.COGNITO_USER:
      return {
        ...state,
        cognitoUser: {
          ...action.data,
          time: Date.now()
        }
      };
    case USER_SIGNIN.POSITION:
      return {
        ...state,
        position: action
      }
    case USER_SIGNIN.SYSTEM_ROLE:
      return {
        ...state,
        systemRole: action
      }
    case USER_SIGNIN.LOGOUT:
      return { ...defaultState }; // reset to default state on logout
    default:
      return state;
  }
}