import { takeLatest, all, call, put } from 'redux-saga/effects';
import { FETCH_AREA_INDICATORS } from './constants';
import { setAreaIndicators, setAreaIndicatorsError } from './actions';
import API from '../../services/api';

export function* fetchAreaIndicators(action) {
  const { payload } = action;

  try {
    const response = yield call(API.getAreaIndicators, payload);
    const { data } = response;
    yield put(setAreaIndicators(data));
  } catch (error) {
    yield put(setAreaIndicatorsError(true));
  }
}

export default function* areaIndicatorsSaga() {
  yield all([takeLatest(FETCH_AREA_INDICATORS, fetchAreaIndicators)]);
}
