import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';

import {
  SET_REPORTS_ORGANIZATION,
  SET_REPORTS_AREA,
  SET_REPORTS_EMPLOYEE,
  FETCH_REPORTS_EMPLOYEE,
  SET_EMPLOYEE_REPORTS,
  SET_EMPLOYEE_REPORTS_ERROR,
  SET_COMMISION_SENDING,
  RESET_EMPLOYEE_REPORTS_DATA,
  SET_REPORTS_BY_ORGANIZATION,
  FETCH_REPORTS_BY_ORGANIZATION,
} from './constants';

const setOrganization = (state, action) => ({
  ...state,
  organization: action.payload,
});

const setArea = (state, action) => ({
  ...state,
  area: action.payload,
});

const setEmployee = (state, action) => ({
  ...state,
  employee: action.payload,
});

const fetchReportsEmployee = (state, payload) => ({
  ...state,
  loadingReports: true,
  errorLoadingReports: false,
});

const setEmployeesReports = (state, action) => ({
  ...state,
  loadingReports: false,
  errorLoadingReports: false,
  reports: action.payload,
});

const setEmployeeReportsError = (state, action) => ({
  ...state,
  loadingReports: false,
  errorLoadingReports: action.payload,
});

const setCommissionSending = (state, action) => ({
  ...state,
  isSendingCommision: action.payload,
});

const fetchReportsOrganization = (state, payload) => ({
  ...state,
  loadingReports: true,
  errorLoadingReports: false,
});

const setReportsOrganization = (state, action) => ({
  ...state,
  loadingReports: false,
  errorLoadingReports: false,
  reports: action.payload,
});

const resetEmployeesRreports = () => INITIAL_STATE;

export const reducer = handleActions(
  {
    [SET_REPORTS_ORGANIZATION]: setOrganization,
    [SET_REPORTS_AREA]: setArea,
    [SET_REPORTS_EMPLOYEE]: setEmployee,
    [FETCH_REPORTS_EMPLOYEE]: fetchReportsEmployee,
    [SET_EMPLOYEE_REPORTS]: setEmployeesReports,
    [SET_EMPLOYEE_REPORTS_ERROR]: setEmployeeReportsError,
    [SET_COMMISION_SENDING]: setCommissionSending,
    [RESET_EMPLOYEE_REPORTS_DATA]: resetEmployeesRreports,
    [FETCH_REPORTS_BY_ORGANIZATION]: fetchReportsOrganization,
    [SET_REPORTS_BY_ORGANIZATION]: setReportsOrganization,
  },
  INITIAL_STATE,
);
