export default {
  areas: require('./icons/areas.svg'),
  documentacion: require('./icons/documentacion.svg'),
  empleados: require('./icons/empleados.svg'),
  empresas: require('./icons/empresas.svg'),
  fuentes: require('./icons/fuentes.svg'),
  indicadores: require('./icons/indicadores.svg'),
  kpis: require('./icons/kpis.svg'),
  parametros: require('./icons/parametros.svg'),
  reportes: require('./icons/reportes.svg'),
  variables: require('./icons/variables.svg'),
  areasWhite: require('./icons/areas-white.svg'),
  documentacionWhite: require('./icons/documentacion-white.svg'),
  empleadosWhite: require('./icons/empleados-white.svg'),
  empresasWhite: require('./icons/empresas-white.svg'),
  fuentesWhite: require('./icons/fuentes-white.svg'),
  indicadoresWhite: require('./icons/indicadores-white.svg'),
  kpisWhite: require('./icons/kpis-white.svg'),
  parametrosWhite: require('./icons/parametros-white.svg'),
  reportesWhite: require('./icons/reportes-white.svg'),
  variablesWhite: require('./icons/variables-white.svg'),
  funciones: require('./icons/function.svg'),
  funcionesWhite: require('./icons/function-white.svg')
};