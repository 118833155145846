import { INITIAL_STATE } from './initialState';
import { handleActions } from 'redux-actions';
import { PUSH_ROUTE } from './constants';

const pushRoute = (state, action) => {
  let { navigation } = state
  navigation.push(action.payload);

  return {
    ...state,
    ...{navigation}
  }
};

export const reducer = handleActions(
  {
    [PUSH_ROUTE]: pushRoute
  },
  INITIAL_STATE,
);
