import InitSearchParameter from './Parameters/InitSearchParameter';

import { connect } from 'react-redux';

import {
    VariableCommissions_onOrganization,
    VariableCommissions_onArea
} from '../redux/actions/VariableCommissionsAction';

const mapDispatchToProps = dispatch => ({
    VariableCommissions_onOrganization: data => {
        dispatch(VariableCommissions_onOrganization(data));
    },
    VariableCommissions_onArea: data => {
        dispatch(VariableCommissions_onArea(data));
    }
});

const mapStateToProps = state => ({
    organization: state.organization,
    area: state.area
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitSearchParameter);

