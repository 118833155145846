import React , { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import {FormControl , FormGroup, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export class ModalCreateVariable extends Component {

    
    render(){

    const { open  , loading , nameVariable , changeName , handleClose  , saveVariable   , btnDis } = this.props;
   
    return (
        <div>
            <Dialog fullScreen open={open}  closeAfterTransition>
                <Fade in={open}>
                <div className={'dialog-content'}>
                    <h2 id="transition-modal-title">Crear variable nueva</h2>
                    <Grid className={'btn-wrapper'} container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormGroup>
                                    <TextField 
                                        className="inputSelect"
                                        type="text"
                                        name="nameVariable"
                                        variant="outlined"
                                        label="Nombre"
                                        onChange={e => changeName(e.target.value)}
                                        value= {nameVariable}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {loading ? 
                            (
                                <CircularProgress />
                            ) :( 
                            <Button
                                className={'btn accept-btn'}
                                variant="contained"
                                onClick={() => {saveVariable(nameVariable)}}
                                disabled = { btnDis && nameVariable.length > 1  ? false : true}
                                >
                            Aceptar
                            </Button>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={'btn cancel-btn'} onClick={handleClose}>
                            Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                </Fade>
            </Dialog>
        </div>
    );
    }
  }

  export default ModalCreateVariable;
