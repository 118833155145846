
import React, { Component } from 'react';
import { APIBAMASTAGE } from "../../env/constants";
import Lista from './Lista';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ModalSelect from './ModalSelect';
import ModalCreate from './ModalCreate';
import Breadcrumbs from '../../components/Breadcrumbs';
import Lead from '../../components/Lead';
import { Container } from '@material-ui/core';
import SessionControl from '../../utils/SessionControl';
export class Areas extends SessionControl {
//** */
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            openC: false,
            organizations: [],
            user: this.getSecureStore().UserProfileReducer &&
            this.getSecureStore().UserProfileReducer.user
            ? this.getSecureStore().UserProfileReducer.user
            : [],
            permision_read:false,
            permision_write:false,
            idOrg: null,
            nameOrg: '',
            nameArea: '',
            areas: [],
            loading: false,
            ready: false,
            btnAct: true,
            breadCrumbs: [
                { title: "Dashboard", url: "prometheus/variableCommissions" },
                { title: "Puestos", current: true },
            ],
            lead: { show: false, type: "success", message: "", close: true }
        }
    }

    componentDidMount() {
        // fetch(`${APIBAMASTAGE}/organization`)
        //     .then(response => response.json())
        //     .then(data => {
        //         this.setState({
        //             ...this.state,
        //             organizations: data.organization,
        //             ready: true
        //         });
        //     })
        //     .catch(error => { console.log(error) });
        const { paramProfile, position } = this.state.user;
        let listPermisionOrganization = JSON.parse(JSON.parse(paramProfile))
        fetch(`${APIBAMASTAGE}/organization`)
          .then(response => response.json())
          .then(data => {
            let listOrganization =[]
            /// ** FILTRO POR EMPRESA QUE NO PUEDA ACCEDER
            data && data.organization.forEach(organization => {
              listPermisionOrganization.filter(r=> r.uuid === position.id).forEach(_e => {
               // console.log(_e.path_permissions)
                _e.path_permissions.forEach(_p => {
                  if(_p.name === "Employees"){
                    this.setState({
                      permision_read: _p.read,
                      permision_write: _p.write
                    });
                  }
                });
    
                _e.organization.forEach(_a => {
                  if(_a.uuid === organization.id){
                    listOrganization.push(organization)
                  }
                });
                
              });
            });
    
            this.setState({
              organizations: listOrganization,
              ready: true
            });
          })
          .catch(error => { console.log(error) });

    }


    render() {

        const { open, openC, breadCrumbs, idOrg, nameArea, organizations, loading, areas, btnAct, lead } = this.state;

        const handleSaveElection = (props) => {

            const { id, name } = props;

            this.setState({
                open: false,
                ready: false,
                idOrg: id,
                nameOrg: name
            });

            fetch(`${APIBAMASTAGE}/organization/${id}/area`)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        areas: data.node,
                        ready: true,
                    });
                })
                .catch(error => { console.log(error) });

        }

        const handleClose = () => {
            this.setState({
                open: false,
                openC: false
            });
        }

        const handleChangeName = (e) => {
            this.setState({
                nameArea: `${e}`
            });
        }



        const sendForm = (name) => {

            this.setState({
                btnAct: false, loading: true
            });

            let body;

            //Validaciónes 
            if (name !== ' ') {
                body = {
                    name: `${name}`
                }
            }

            fetch(`${APIBAMASTAGE}/organization/${idOrg}/area`, { method: 'post', body: JSON.stringify(body) })
                .then(response => response.json())
                .then(() => {
                    this.setState({
                        ...this.state,
                        openC: false,
                        btnAct: true,
                        loading: false,
                        nameArea: '',
                        lead: { show: true, type: "success", message: "Se creó correctamente", close: false }
                    });
                    handleSaveElection({ id: idOrg, name: name });
                })
                .catch(error => { console.log(error) });

        }


        const handleOnUpdateValue = (oldData, newData, name) => {

            this.setState({
                ready: false
            });

            let body = {
                name: `${name}`
            }

            let prov = areas;
            fetch(`${APIBAMASTAGE}/area/${oldData.id}`, { method: 'PATCH', body: JSON.stringify(body) })
                .then(response => response.json())
                .then(() => {
                    prov[prov.indexOf(oldData)] = newData;
                    this.setState({
                        areas: prov,
                        ready: true,
                        lead: { show: true, type: "success", message: "Se modificó correctamente", close: false }
                    });
                })
                .catch(error => { console.log(error) })
        }


        const handleOnDeleteValue = (oldData, areaId) => {

            this.setState({
                ready: false
            });

            let prov = areas;

            fetch(`${APIBAMASTAGE}/area/${areaId}`, { method: 'DELETE' })
                .then(response => response.json())
                .then(data => {
                    prov.splice(prov.indexOf(oldData), 1);
                    this.setState({
                        areas: prov,
                        ready: true,
                        lead: { show: true, type: "success", message: "Se eliminó correctamente", close: false }
                    });
                })
                .catch(error => { console.log(error) });
        }


        const ListAreas = () => {
            const { ready } = this.state;
            return (
                <Lista
                    {...this.state}
                    data={areas}
                    ready={ready}
                    edit ={this.state.permision_write}
                    onUpdateValue={handleOnUpdateValue}
                    onDeleteValue={handleOnDeleteValue}
                ></Lista>
            );
        }


        return (
            <Container fixed>
                <Lead
                    lead={lead}
                    handleClose={() => { this.setState({ lead: { show: false, type: "success", message: "", close: true } }) }}
                />
                <ModalSelect
                    open={open}
                    handleClose={handleClose}
                    organizations={organizations}
                    saveElection={handleSaveElection}
                ></ModalSelect>
                <ModalCreate
                    open={openC}
                    loading={loading}
                    name={nameArea}
                    changeName={handleChangeName}
                    sendForm={sendForm}
                    onCloseModal={handleClose}
                    btnAct={btnAct}>
                </ModalCreate>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs {...this.props} navigation={breadCrumbs} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1">
                            <strong>{this.state.nameOrg}</strong>
                        </Typography>
                    </Grid>
                    <Grid
                        className={'btn-wrapper'}
                        item xs={6}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-start"
                    >
                        {this.state.permision_write ?<Button
                            className={'btn accept-btn'}
                            type="submit"
                            onClick={() => this.setState({ ...this.state, openC: true })}
                        >
                            CREAR NUEVO PUESTO
                        </Button>:""}
                    </Grid>
                    <Grid item xs={12}>
                        <ListAreas></ListAreas>
                    </Grid>
                </Grid>
            </Container>
        );

    }
}

export default Areas;

